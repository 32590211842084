import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { MasterService } from 'src/app/master/master.service';
import { CustomerService } from '../customer.service';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {
  isLoading = false;
  button = 'Save';
  @ViewChild('userPhoto') userPhoto: ElementRef;
   attachName: any;
   public active1 = 1;
  customerCreateForm: FormGroup;
  listProject: any;
  path: any;
  selectedOthers: any;
  accessSuccess: string;
  image: string;
  pathImage: any;
  othersSelectDic: boolean = false;
  othersSelectAccessUpsi: boolean  = false;
  othersSelectconnectPerson: boolean  = false;
  othersSelectFiduciary: boolean  = false;
  othersSelectInter: boolean  = false;
  othersInsiderNationSelect:boolean = false;
  d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ];
  
  p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ];
  constructor(private fb: FormBuilder, private customerService:CustomerService, private router:Router, private toastr: ToastrService, private masterService: MasterService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }
  submitted:boolean = false;
  attachmentPath:boolean = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  relationshipDropdown = []
  othersSelect:boolean = false;
  othersInsiderSelect:boolean = false;
  othersInsiderSelectCin:boolean = false;
   regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
  ngOnInit(): void {
    this.customerCreateForm = this.fb.group({
      // project: new FormControl ('', Validators.required),
      customer_name: new FormControl ('',  [Validators.required, Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),  
      company_name: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),  
      isinnumber: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),  
      designation: new FormControl ('', [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      email: new FormControl('',  [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      mobileno: new FormControl ('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      panno: new FormControl ('',  [Validators.required]),
      documenttype : new FormControl ('',  [Validators.required]),     
      address: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')]),
      nationality: new FormControl ('', Validators.required),  
      remarks: new FormControl('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
      others_nationality: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
      company_email: new FormControl('',  [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      company_pan: new FormControl('',  Validators.required),
      company_contact_no: new FormControl('',  [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      company_address: new FormControl('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')]),
      company_cin: new FormControl('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')])
    });

    this.masterService.projectList(null).subscribe(
      res => {
      this.listProject = res.body['data']     
        },
        err => {
        }
    );
 
  //Multi select box
  // this.dropdownList = [
  //   // { id: 1, itemName: 'Project 1' },
  //   // { id: 2, itemName: 'Project 2' },
  //   // { id: 3, itemName: 'Project 3' },
  //   // { id: 4, itemName: 'Project 4' },
  //   // { id: 5, itemName: 'Project 5' }
  // ];
  this.selectedItems = [
    // { id: 2, itemName: 'Singapore' },
    // { id: 3, itemName: 'Australia' },
    // { id: 4, itemName: 'Canada' },
    // { id: 5, itemName: 'South Korea' }
  ];
  this.dropdownSettings = {
    singleSelection: false,
    text: 'Select the Project Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };
  
  //Multi select box
//Relationship with Company
this.relationshipDropdown = [
  { listItem: 'AMC of mutual fund'  },
  { listItem: 'Asset Management Company'  },
  { listItem: 'Associate Company ' },
  { listItem: 'Auditor'  },
  { listItem: 'Banker'  },
  { listItem: 'Clearing House'  },
  { listItem: 'Director'},
  { listItem: 'Director of insider company'  },
  { listItem: 'Employee'  },
  { listItem: 'Entity with 10%+ investment from director'},
  { listItem: 'Holding Company' },
  { listItem: 'Immediate Relative of Connected Person' },
  { listItem: 'Investment Company'  },
  { listItem: 'KMP'  },
  { listItem: 'Legal Firm ' },
  { listItem: 'Liquidator'  },
  { listItem: 'Mutual Fund'  },
  { listItem: 'Official of Stock Exchange'  },
  { listItem: 'Other Connected Person'  },
  { listItem: 'Other Fiduciary'  },
  { listItem: 'Other Intermediary'  },
  { listItem: 'Other Person with access to UPSI' } ,
  { listItem: 'Promoter'  },  
  { listItem: 'Public Financial Institution'  },
  { listItem: 'Stock Broker'  },  
  { listItem: 'Subsidiary Company'  },   
  { listItem: 'Trustee Company'  }  
  ]
 
  }
  base64Output : string;

  filePath(){
    if(this.customerCreateForm.get('fileName').value != ''){
    this.attachName = this.customerCreateForm.get('fileName').value;
    
    // this.path =  this.attachName.split("\\").pop();
    this.path =  this.attachName[0].name;
  
    }
    this.attachmentPath = true;
  }
  removeFile(){
    this.userPhoto.nativeElement.value = "";

    this.customerCreateForm.get('fileName').setValue("");
    this.path = "";
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;
    
  }
  changeListener($event) : void {   
    this.readThis($event?.target);
    this.filePath()
  //  this.customerCreateForm.get('fileName')?.setValue(File);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];
   
    var myReader:FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());   
      this.customerCreateForm.value.image=myReader.result;
      this.pathImage = this.customerCreateForm.value.image;
    }
  }
  onFileSelected(event) {
    this.filePath()
      this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;     
    });
  }

  convertFile(file : File) : Observable<string> {
    this.filePath()
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    // reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  relationshipPersonChanged() {
    this.selectedOthers = this.customerCreateForm.get('relationship_with_company')
    if(this.selectedOthers?.value == "Immediate Relative of Connected Person"){
      this.othersSelect = true;
    }else{
      this.othersSelect = false;
    }
    //Director of insider company
    if(this.selectedOthers?.value == "Director of insider company"){
      this.othersSelectDic = true;
    }else{
      this.othersSelectDic = false;
      this.customerCreateForm.get('others').setValue("");

    }
      //Other Intermediary
      if(this.selectedOthers?.value == "Other Intermediary"){
        this.othersSelectInter = true;
      }else{
        this.othersSelectInter = false;
        this.customerCreateForm.get('others').setValue("");
      }
        //Other Fiduciary
    if(this.selectedOthers?.value == "Other Fiduciary"){
      this.othersSelectFiduciary = true;
    }else{
      this.othersSelectFiduciary = false;
      this.customerCreateForm.get('others').setValue("");
    }
      //Other Connected Person
      if(this.selectedOthers?.value == "Other Connected Person"){
        this.othersSelectconnectPerson = true;
      }else{
        this.othersSelectconnectPerson = false;
        this.customerCreateForm.get('others').setValue("");
      }
        //Other Person with access to UPSI
    if(this.selectedOthers?.value == "Other Person with access to UPSI"){
      this.othersSelectAccessUpsi = true;
    }else{
      this.othersSelectAccessUpsi = false;
      this.customerCreateForm.get('others').setValue("");
    }
  }
  //Individual
  insiderTypeChanged() {
    this.selectedOthers = this.customerCreateForm.get('insiderType')    
    if(this.selectedOthers?.value == "Individual"){
      this.othersInsiderSelect = true;
      this.othersInsiderSelectCin = false;
    }else if(this.selectedOthers?.value == "Company"){
      this.othersInsiderSelectCin = true;
      this.othersInsiderSelect = false;
    }else if(this.selectedOthers?.value == "LLP"){
      this.othersInsiderSelectCin = true;
      this.othersInsiderSelect = false;
    }else{
      this.othersInsiderSelectCin = false;
      this.othersInsiderSelect = false;
    }
  }
    // nationality
    insiderNationalityChanged() {
      this.selectedOthers = this.customerCreateForm.get('nationality')    
      if(this.selectedOthers?.value == "Others"){
        this.othersInsiderNationSelect = true;
        }else{
          this.othersInsiderNationSelect = false;
      }
    }
  //Cin Number
  insiderTypeChangedCin() {
    this.selectedOthers = this.customerCreateForm.get('insiderType')    
   
  }
  // insiderTypeChangedLlp() {
  //   this.selectedOthers = this.customerCreateForm.get('insiderType')    
  //   if(this.selectedOthers?.value == "Company"){
  //     this.othersInsiderSelectCin = true;
  //   }else{
  //     this.othersInsiderSelectCin = false;
  //   }
  // }
  cancelInsider(){
    this.router.navigate(['/customer/list-customer']);
    this.customerCreateForm.reset();
  }
//Multi select box
onItemSelect(item: any) {

}
OnItemDeSelect(item: any) {

}
onSelectAll(items: any) {
}
onDeSelectAll(items: any) {
}
//multi select box
    //select radio button
 selectedButton: string="No";        

 radioSelect(e: string): void {
     this.selectedButton = e; 
     if(this.selectedButton !== "Yes"){
      this.customerCreateForm.get('fileName').setValue("");
      this.customerCreateForm.get('confidentiality_agreement_date').setValue("");
      this.customerCreateForm.get('filePath').setValue("");
      this.attachmentPath = false;
    }
 }  
 
 isSelected(name: string): boolean {  
 
     if (!this.selectedButton) {   
         return false;  
     }    
 
     return (this.selectedButton === name); 
 } 
 onBlur(): void {
}
//AAdharValidation
validate(addharNumber) {
  let c = 0;
  let invertedArray = addharNumber
    .split('')
    .map(Number)
    .reverse();

  invertedArray.forEach((val, i) => {
    c = this.d[c][this.p[i % 8][val]];
  });

  return c === 0;
}
    onSubmit(){
      let idendiferCard = this.customerCreateForm.get('panno').value;
      let panCardNo = this.customerCreateForm.get('company_pan').value;
      if(panCardNo != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(panCardNo)) {
          this.toastr.warning("Please Enter the Valid Company PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }
      if(this.customerCreateForm.get('documenttype').value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.customerCreateForm.get('documenttype').value == 'Aadhar card'){
        
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
          
        } else {
          this.toastr.warning('Your Aadhar Number is InValid')
          return false;      
        }
      }

      }else if(this.customerCreateForm.get('documenttype').value == 'Voter ID'){
        let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Voter ID');
          return false;
        }
    }else if(this.customerCreateForm.get('documenttype').value == 'Driving License'){
      let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Driving License');
          return false;
        }
    }
      this.submitted = true;
      const emptyFileds = Object.keys(this.customerCreateForm.controls).filter(key =>this.customerCreateForm.controls[key].errors !== null)

     if(this.customerCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
        this.customerService.addcustomer(this.customerCreateForm.value).subscribe(data =>{
          
         if(data.success == '1'){
            this.toastr.success("Customer Details Successfully created");
            this.isLoading = false;
            this.button = 'Save';
            this.router.navigate(['/customer/list-customer']);
          }else{
            this.toastr.warning(data?.message);
            this.isLoading = false;
            this.button = 'Save';
          }
        })
      }else if(emptyFileds.length > 1){
        this.toastr.warning("Please fill all Mandatory fileds.");
        return false;
      } else{
        if(this.customerCreateForm.get('customer_name').value == ''){
          this.toastr.warning("Please fill the Name of Customer.");
        }
        else if(this.customerCreateForm.get('designation').value == ''){
          this.toastr.warning("Please fill the Designation.");
        }       
        else if(this.customerCreateForm.get('email').value == ''){
          this.toastr.warning("Please fill the Email ID.");
        }
        else if(this.customerCreateForm.get('mobileno').value == ''){
          this.toastr.warning("Please fill the Contact Number.");
        }
        else if(this.customerCreateForm.get('documenttype').value == ''){
          this.toastr.warning("Please fill the ID Proof Type.");
        }
        else if(this.customerCreateForm.get('panno').value == ''){
          this.toastr.warning("Please fill the ID Proof No.");
        }
        else if(this.customerCreateForm.get('address').value == ''){
          this.toastr.warning("Please fill the Address.");
        }
        else if(this.customerCreateForm.get('nationality').value == ''){
          this.toastr.warning("Please fill the Nationality.");
        }else if(this.customerCreateForm.get('company_name').value == ''){
          this.toastr.warning("Please fill the Company Name.");
        }else if(this.customerCreateForm.get('company_email').value == ''){
          this.toastr.warning("Please fill the Company Email ID.");
        }else if(this.customerCreateForm.get('company_pan').value == ''){
          this.toastr.warning("Please fill the Company PAN No.");
        }else if(this.customerCreateForm.get('company_contact_no').value == ''){
          this.toastr.warning("Please fill the Company Contact Number.");
        }else if(this.customerCreateForm.get('company_address').value == ''){
          this.toastr.warning("Please fill the Company Address.");
        }
                
      }
           
    }

}