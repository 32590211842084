import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';
import { environment } from 'src/environments/environment';
import { MasterService } from '../master.service';
import { DpActivityLogComponent } from '../view-activity-logs/dp-activity-log/dp-activity-log.component';
import { InsiderActivityLogComponent } from '../view-activity-logs/insider-activity-log/insider-activity-log.component';
import { ProjectActivityLogsComponent } from '../view-activity-logs/project-activity-logs/project-activity-logs.component';
import { UpsiActivityLogComponent } from '../view-activity-logs/upsi-activity-log/upsi-activity-log.component';
import { NonUpsiActivityLogComponent } from '../view-activity-logs/non-upsi-activity-log/non-upsi-activity-log.component';
import { CustomerService } from 'src/app/customer/customer.service';
import { CustomerActivityLogComponent } from '../view-activity-logs/customer-activity-log/customer-activity-log.component';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  baseUrl = environment.apiUrl;
  activityUpsiList: any;
  activityinsider: any;
  activityDpModule: any;
  public active1 = 1;
  public selected = [];
  page = 1;
  pageSize = 10;
  user_roles: any;
  user_roles1: any;
  user_roles2: any;
  role_type: any;
  user_access: boolean = false;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  is_access_read1=false;
  is_access_write1=false;
  is_access_no1=false;
  is_access_read2=false;
  is_access_write2=false;
  is_access_no2=false;
  is_access_read3=false;
  is_access_write3=false;
  is_access_no3=false;
  is_access_read4=false;
  is_access_write4=false;
  is_access_no4=false;
  filterString = "";
  filterString2 = "";
  filterString3 = "";
  filterString5 = "";
  filterString6 = "";
  filtered = [];
  filtered2: any;
  filtered3: any;
  filterString4 = "";
  filtered4: any;
  filtered5: any;
  activityProjectModule: any;
  activityNoUpsiList: any;
  openNoUpsi: boolean = false;
  openCustomer: boolean = false;

  activityCustomerList: any;
  filtered6: any;
  upsiDisclaimer: boolean;
  otherDisclaimer: boolean;
  constructor(private masterService: MasterService,private dpService:DpModuleService, public router: Router, private insiderService:InsiderServiceService, private modalService: NgbModal, private activateRouter:ActivatedRoute, private upsiService: UpsiService, private http:HttpClient,
    private customerService: CustomerService) {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')))
    this.role_type = this.user_roles.roles_name;
    if(this.user_roles.roles_name !== "Admin"){
      this.user_access = false;
    }else{
      this.user_access = true;
    }
   }

  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if(this.user_roles.upsi_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.upsi_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }
//insider
    if (this.user_roles.insider_read == '2') {
      this.is_access_write1 = true;
      this.is_access_read1 = true;
      this.is_access_no1 = true;
    } else if (this.user_roles.insider_read == '1') {
      this.is_access_write1 = false;
      this.is_access_read1 = true;
      this.is_access_no1 = true;
    }
//dp
if(this.user_roles.dpname_read == '2'){
  this.is_access_write2=true;
  this.is_access_read2=true;
  this.is_access_no2=true;
}else if(this.user_roles.dpname_read == '1'){
  this.is_access_write2=false;
  this.is_access_read2=true;
  this.is_access_no2=true;
}
//Project
if(this.user_roles.project_read == '2'){
  this.is_access_write3=true;
  this.is_access_read3=true;
  this.is_access_no3=true;
}else if(this.user_roles.project_read == '1'){
  this.is_access_write3=false;
  this.is_access_read3=true;
  this.is_access_no3=true;
}
//Customer
if(this.user_roles.customer_read == '2'){
  this.is_access_write4=true;
  this.is_access_read4=true;
  this.is_access_no4=true;
}else if(this.user_roles.customer_read == '1'){
  this.is_access_write4=false;
  this.is_access_read4=true;
  this.is_access_no4=true;
}
let accessData = sessionStorage.getItem('model_access_controller');    
let accessModelController = JSON.parse(accessData);
if(accessModelController.noupsi_access == true || accessModelController.noupsi_access == 1){
   this.openNoUpsi = true;
}else{
   this.openNoUpsi = false;
}
if(accessModelController.customer_access == true || accessModelController.customer_access == 1){
   this.openCustomer = true;
}else{
   this.openCustomer = false;
}

this.upsiData();
  }
  upsiData(){
    this.upsiDisclaimer = true;
    this.otherDisclaimer = false;
    this.upsiService.upsiActivity(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.activityUpsiList = res.body['data'];
      this.onFilterChange();
        }
    })
  }
  insiderData(){
    this.upsiDisclaimer = false;
    this.otherDisclaimer = true;
    //insider Activity
    this.insiderService.insiderActivity(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.activityinsider = res.body['data'];
      this.onFilterChange2();
      
        }
        },
        err => {
            console.log(err);
        }
    );
  }
  dpData(){
    this.upsiDisclaimer = false;
    this.otherDisclaimer = true;
    //Dp Activity
    this.dpService.dpModuleActivity(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.activityDpModule = res.body['data'];
      this.onFilterChange3();
        }
        },
        err => {
            console.log(err);
        }
    );
  }
  projectData(){
    this.upsiDisclaimer = false;
    this.otherDisclaimer = true;
    //Project Activity
    this.masterService.ProjectActivity(null).subscribe(
      res => {
        
      this.activityProjectModule = res.data;

      this.onFilterChange4();
      
        },
        err => {
            console.log(err);
        }
    );
  }
  nonUpsiData(){
    this.upsiDisclaimer = true;
    this.otherDisclaimer = false;
    //NON UPSI Activity Log
    this.upsiService.noupsiActivity(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.activityNoUpsiList = res.body['data'];
      this.onFilterChange5();
        }
    })
  }
  customerData(){    
    this.upsiDisclaimer = false;
    this.otherDisclaimer = true;
    //CUSTOMER Activity Log
    this.customerService.customerActivity(null).subscribe(
      res => {       
      this.activityCustomerList = res.body['data'];
      this.onFilterChange6();
        
    })
  }
  ngOnDestroy(){
    
  }
  onFilterChange() {
  
    this.filtered = this.activityUpsiList.filter(res =>{     
     
     return res.project_name?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) || res.purpose_of_sharing?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) || res.created_by?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) || res.action_flag?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) || res.created_on?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
     || res.createddpname?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) || res.createddppanno?.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
    }) 
    
  }
  onFilterChange5() {
  
    this.filtered5 = this.activityNoUpsiList.filter(res =>{     
     
      return res.project_name?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase()) 
      || res.purpose_of_sharing?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase()) 
      || res.created_by?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase()) 
      || res.action_flag?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase()) 
      || res.created_on?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase())
      || res.createddpname?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase()) 
      || res.createddppanno?.toLocaleLowerCase().match(this.filterString5?.toLocaleLowerCase())   
     }) 
  }
  onFilterChange6() {
  
    this.filtered6 = this.activityCustomerList.filter(res =>{     
     
     return res.customer_name?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase()) || res.reasonforchange?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase()) || res.ActionBy?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase()) || res.Activity?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase()) || res.ActionOn?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase()) || res.Record?.toLocaleLowerCase().match(this.filterString6?.toLocaleLowerCase())
    }) 
  }
  onFilterChange2() {
  
    this.filtered2 = this.activityinsider.filter(res =>{     
     
     return res.Activity?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.ActionBy?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.ActionOn?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.name?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.Record?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase())
    }) 
    
  }
  onFilterChange3() {
  
    this.filtered3 = this.activityDpModule.filter(res =>{     
     
      return res.dpName?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase()) || res.reasonforchange?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase()) || res.ActionBy?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase()) || res.Activity?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase()) || res.ActionOn?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase()) || res.Record?.toLocaleLowerCase().match(this.filterString3?.toLocaleLowerCase())
    }) 
    
  }
  onFilterChange4() {
  
    this.filtered4 = this.activityProjectModule.filter(res =>{     
     
     return res.Activity?.toLocaleLowerCase().match(this.filterString4?.toLocaleLowerCase()) || res.ActionBy?.toLocaleLowerCase().match(this.filterString4?.toLocaleLowerCase()) || res.ActionOn?.toLocaleLowerCase().match(this.filterString4?.toLocaleLowerCase()) || res.Record?.toLocaleLowerCase().match(this.filterString4?.toLocaleLowerCase()) || res.project_name?.toLocaleLowerCase().match(this.filterString4?.toLocaleLowerCase())
    }) 
    
  }

  onFilter(){
    let queryString = '';
    let url = this.baseUrl + 'upsiactivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'upsiactivityreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  onFilter5(){
    let queryString = '';
    let url = this.baseUrl + 'noupsiactivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'noupsiactivityreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  onFilter2(){
    let queryString = '';
    let url = this.baseUrl + 'insideractivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'insideractivityreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  onFilter3(){
    let queryString = '';
    let url = this.baseUrl + 'dpactivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'dpactivityreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  onFilter4(){
    let queryString = '';
    let url = this.baseUrl + 'projectactivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'dpactivityreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  onFilter6(){
    let queryString = '';
    let url = this.baseUrl + 'customeractivityexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'customeractivityreport.pdf');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  openModal(id:any) {
    if (id) {
      let upsiId = {'id': id};
      this.upsiService.upsiViewActivity(upsiId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(UpsiActivityLogComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewUpsiActivityList = data[0];
          modalRef.componentInstance.title = 'UPSI';
          modalRef.result.then((result) => {
           // console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }
  openModal5(id:any) {
    if (id) {
      let upsiId = {'id': id};
      this.upsiService.noupsiViewActivity(upsiId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(NonUpsiActivityLogComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewUpsiActivityList = data[0];
          modalRef.componentInstance.title = 'Non UPSIs';
          modalRef.result.then((result) => {
          //  console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }
  openModal2(id:any) {
    if (id) {
      let insiderId = {'id': id};
      this.insiderService.viewActivityInsider(insiderId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(InsiderActivityLogComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewInsiderActivityList = data[0];
          modalRef.result.then((result) => {
            //console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }

  openModal3(id:any) {
    if (id) {
      let dpId = {'id': id};
      this.dpService.viewActivitydp(dpId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(DpActivityLogComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewDpList = data[0];
          modalRef.result.then((result) => {
            //console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }
  openModal4(id:any) {
    if (id) {
      let projectId = {'id': id};
      this.masterService.viewActivityProject(projectId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(ProjectActivityLogsComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewProjectList = data[0];
          modalRef.result.then((result) => {
           // console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }
  openModal6(id:any) {
    if (id) {
      let customerId = {'id': id};
      this.customerService.viewCustomerActivity(customerId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(CustomerActivityLogComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewCustomerList = data[0];
          modalRef.result.then((result) => {
            //console.log(result);
          }, (reason) => {
          });
      })
  
      }    
  }

}
