import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';
import { CompanyService } from 'src/app/master/company/company.service';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';

@Component({
  selector: 'app-form-c-and-form-d',
  templateUrl: './form-c-and-form-d.component.html',
  styleUrls: ['./form-c-and-form-d.component.scss']
})
export class FormCAndFormDComponent implements OnInit {
  @ViewChild('sQty') sQty!: ElementRef;
  @ViewChild('sQty2') sQty2!: ElementRef;
  @ViewChild('sQty3') sQty3!: ElementRef;
  @ViewChild('sQty4') sQty4!: ElementRef;
  hideFormC:boolean = false;
  hideFormD:boolean = false;
  formCCreateForm: FormGroup;
  formDCreateForm: FormGroup;
  isLoading = false;
  button = 'Save';
  isLoading2 = false;
  button2 = 'Save';
  submitted:boolean = false;
  formTypes: any;
  exchangeTradeData:any;
  selectedStockExchange = [];
  exchangeTradeFilter: any[];
  responseDate: Object;
  isinNumber: any;
  companyName: any;
  selectedOthers: any;
  othersModeofAcquisition:boolean = false;
  selectedOthersformD: any;
  othersModeofAcquisitionFormD:boolean = false
  selectedTransactionTypeFormC: any;
  selectedTransactionTypeFormD: any;
  othersTransactionTypeFormC:boolean = false;
  othersTransactionTypeFormD:boolean = false;
  perShareHoldingFormC: any;
  perShareHoldingFormD: any;
  perShareHolding2FormD: any;
  perShareHolding2FormC: any;
  companyshares: any;
  otherscategoryPersonFormC:boolean = false;
  selectedcategoryPersonFormC: any;
  pcId: any;
  PcEditId: any;
  name: any;
  dpPan: any;
  cin_din: any;
  dpContactNumber: any;
  dpAddress: any;
  namesplit: any;
  typeOfSecuritiesNo: any;
  typeOfSecuritiesValue: any;
  previousShareHolding: any;
  priorShareNumber: any;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private activateRouter: ActivatedRoute,
    private pitService: PitComplianceService, private dpService:DpModuleService, private companyService: CompanyService) { }

  ngOnInit(): void {
    // this.activateRouter.params.subscribe(data => {   
    //   this.formTypes = data['name']      
     
  
    // })

    this.activateRouter.params.subscribe(data =>{
      this.pcId = data['id'] 
      this.formTypes = data['name']     
    })
if(this.pcId && this.formTypes ){
    if(this.pcId !==''){
      this.pitService.preClearanceView(this.pcId)
      .toPromise().then(result =>{ 
        this.PcEditId = result.data[0].id;
       this.namesplit = result.data[0].dpname;
       this.typeOfSecuritiesNo = result.data[0].Estimatednumbersecur;
       this.typeOfSecuritiesValue = result.data[0].transactionisproposed;
       this.previousShareHolding = result.data[0].securities_transacted;
       this.priorShareNumber = result.data[0].number_of_shares;
        let Name = result.data[0].dpname?.split('- ')
          this.name = Name[0];
          this.dpPan = result.data[0].dppan;

          this.changeFn(result.data[0].dpname);
          
          this.onSelectedQty2C();
          this.onSelectedQty1C();
      })
    }
  }
    this.formCCreateForm = this.fb.group({
      companyName: new FormControl (''),
      isinNumber: new FormControl (''),
      name: new FormControl ('', Validators.required),
      panNo: new FormControl ('', Validators.required),
      cin_din: new FormControl ('', Validators.required),
      address: new FormControl ('', Validators.required),
      mobNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      categoryPerson: new FormControl ('', Validators.required),
      typeOfSecurities: new FormControl ('', Validators.required),
      perShareNumber: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHolding: new FormControl (''),
      typeOfSecurities2: new FormControl ('', Validators.required),
      typeOfSecuritiesNo: new FormControl ('', Validators.required),
      typeOfSecuritiesValue: new FormControl ('', Validators.required),
      transactionType: new FormControl ('', Validators.required),
      typeOfSecuritiespost: new FormControl ('', Validators.required),
      perShareHoldingNopost: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHoldingpost: new FormControl (''),
      fromDate: new FormControl ('', Validators.required),
      toDate: new FormControl ('', [Validators.required, this.dateRangeValidator]),
      intimateDate: new FormControl ('', Validators.required),
      modeOfAcquisition: new FormControl ('', Validators.required),
      othersModeofAcquisition: new FormControl(''),
      otherstransactiontype: new FormControl(''),
      otherscategoryofPerson: new FormControl(''),
      exchangeTrade: new FormControl ('', Validators.required)
    });
    //Form D 
    this.formDCreateForm = this.fb.group({          
      name: new FormControl ('', Validators.required),
      panNo: new FormControl ('', Validators.required),
      cin_din: new FormControl ('', Validators.required),
      address: new FormControl ('', Validators.required),
      mobNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      companyName: new FormControl (''), 
      typeOfSecurities: new FormControl ('', Validators.required),
      perShareNumber: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHolding: new FormControl (''),
      typeOfSecurities2: new FormControl ('', Validators.required),
      typeOfSecuritiesNo: new FormControl ('', Validators.required),
      typeOfSecuritiesValue: new FormControl ('', Validators.required),
      transactionType: new FormControl ('', Validators.required),
      typeOfSecuritiespost: new FormControl ('', Validators.required),
      perShareHoldingNopost: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHoldingpost: new FormControl (''),
      fromDate: new FormControl ('', Validators.required),
      toDate: new FormControl ('', [Validators.required,  this.dateRangeValidator2]),
      intimateDate: new FormControl ('', Validators.required),
      modeOfAcquisition: new FormControl ('', Validators.required),
      othersModeofAcquisition: new FormControl(''),
      otherstransactiontype: new FormControl(''),
      exchangeTrade: new FormControl ('', Validators.required)
      
    });
  
    if(this.formTypes == 'Form C' ){
      this.hideFormC = true;
      this.hideFormD = false;
    }else{
      this.hideFormD = true;
      this.hideFormC = false;
    }

//Exchange on which the trade
    this.exchangeTradeData = [
      "BSE Ltd.",
      "Calcutta Stock Exchange Ltd.",
      "Metropolitan Stock Exchange of India Ltd.",
      "Multi Commodity Exchange of India Ltd.",
      "National Commodity & Derivatives Exchange Ltd.",
      "Indian Commodity Exchange Limited",
      "National Stock Exchange of India Ltd."      
    ]
    // this.exchangeTradeData = [
    //   "The Hyderabad Securities and Enterprises Ltd ",
    //   "Coimbatore Stock Exchange Ltd",
    //   "Saurashtra Kutch Stock Exchange Ltd",
    //   "Mangalore Stock Exchange",
    //   "Inter-Connected Stock Exchange of India Ltd",
    //   "Cochin Stock Exchange Ltd",
    //   "Bangalore Stock Exchange Ltd",
    //   "Ludhiana Stock exchange Ltd",
    //   "Gauhati Stock Exchange Ltd",
    //   "Bhubaneswar Stock Exchange Ltd",
    //   "Jaipur Stock Exchange Ltd",
    //   "OTC Exchange of India",
    //   "Pune Stock Exchange Ltd",
    //   "Madras Stock Exchange Ltd",
    //   "Uttar Pradesh Stock Exchange Ltd",
    //   "Madhya Pradesh Stock Exchange Ltd",
    //   "Vadodara Stock Exchange Ltd",
    //   "Delhi Stock Exchange Ltd",
    //   "Ahmedabad Stock Exchange Ltd",
    //   "Magadh Stock Exchange",
    //   "Ace Derivatives and Commodity Exchange Limited, Ahmedabad",
    //   "Hapur Commodity Exchange LImited",
    //   "Universal Commodity Exchange Limited (UCX)",
    //   "Rajkot Commodity Exchange limited",
    //   "The Spice and Oilseeds Exchange Limited, Sangli",
    //   "Cotton Association of India (CAI)",
    //   "Bombay Commodity Exchange Limited (BCEL)",
    //   "India Pepper and Spice Trade Association, Kochi (IPSTA)"
    // ]
    this.exchangeTradeFilter = this.exchangeTradeData.map(x => x);
  
      //company Profile
  this.companyService.CompanyList(null).subscribe(
    res => {
      if(res.body['message'] == 'Invalid User.'){
        this.router.navigate(['/auth/login']);
      }else{
    let listCompany = res.body['data']     
  
    this.isinNumber = listCompany[0].isin;
    this.companyName = listCompany[0].company_name;
    this.companyshares = listCompany[0].companyshares;
    this.cin_din = listCompany[0].cin;
      }
      },
      err => {
          console.log(err);
      }
  );

  //dp
  
  }
  changeFn(val) {
    let items = this.namesplit?.split('- ');
    
    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      let listDpModule = res['data'];  
      
      let listDpArrayItem = listDpModule.map((data) => {
       
        if(items != undefined){
          if(data.email == items[1]){
              let dpdatalist = data;    
                      
              this.dpContactNumber = dpdatalist.dpContactNo;
              this.dpAddress = dpdatalist.dpAddress;
               
          }
        }
      })
    
      
        
        },
        err => {
            console.log(err);
        }
    );
   
}
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.formCCreateForm && this.formCCreateForm.get("fromDate").value;
    const to = this.formCCreateForm && this.formCCreateForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  private dateRangeValidator2: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.formDCreateForm && this.formDCreateForm.get("fromDate").value;
    const to = this.formDCreateForm && this.formDCreateForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  formTypeChoose(e){     
    if(e.value == 'Form C' ){
      this.hideFormC = true;
      this.hideFormD = false;
      this.formDCreateForm.reset();
    }else{
      this.hideFormD = true;
      this.hideFormC = false;
      this.formCCreateForm.reset();
    }
  }
  onSelectedQty1C(){
   // const quantity3 = this.sQty3.nativeElement.value;
   const quantity3 = this.priorShareNumber;
    this.perShareHoldingFormC = quantity3 / this.companyshares * 100;
  }
  onSelectedQty2C(){
    //const quantity4 = this.sQty4.nativeElement.value;
    const quantity4 = this.previousShareHolding;
    this.perShareHolding2FormC = quantity4 / this.companyshares * 100;
  }
  onSelectedQty1D(){
    const quantity1 = this.sQty.nativeElement.value;
    this.perShareHoldingFormD = quantity1 / this.companyshares * 100;
  }
  onSelectedQty2D(){
    const quantity2 = this.sQty2.nativeElement.value;
    this.perShareHolding2FormD = quantity2 / this.companyshares * 100 ;
  }
  modeofAcquisitionTypeChanged() {
    this.selectedOthers = this.formCCreateForm.get('modeOfAcquisition')    
    if(this.selectedOthers?.value == "Others"){
      this.othersModeofAcquisition = true;
    }
  }
  modeofAcquisitionTypeChangedformD() {
    this.selectedOthersformD = this.formDCreateForm.get('modeOfAcquisition')    
    if(this.selectedOthersformD?.value == "Others"){
      this.othersModeofAcquisitionFormD = true;
    }
  }
  otherTransactionTypeFormC() {
    this.selectedTransactionTypeFormC = this.formCCreateForm.get('transactionType')    
    if(this.selectedTransactionTypeFormC?.value == "Others"){
      this.othersTransactionTypeFormC = true;
    }
  }
  otherTransactionTypeFormD() {
    this.selectedTransactionTypeFormD = this.formDCreateForm.get('transactionType')    
    if(this.selectedTransactionTypeFormD?.value == "Others"){
      this.othersTransactionTypeFormD = true;
    }
  }
  otherCategoryOfPersonFormC() {
    this.selectedcategoryPersonFormC = this.formCCreateForm.get('categoryPerson')    
    if(this.selectedcategoryPersonFormC?.value == "Others"){
      this.otherscategoryPersonFormC = true;
    }
  }
  resetForm(){
    this.formCCreateForm.reset()
    this.formDCreateForm.reset();
    this.submitted = false;
    this.router.navigate(['/pit-compliance/list-trading-report']); 
  }
  onSubmitFormC(){
    let idendiferCard = this.formCCreateForm.get('panNo').value; 
    
    if(this.formCCreateForm.get('panNo').value != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       return false;
      }
    }
      this.submitted = true;
     this.formCCreateForm.value.formTypes = this.formTypes;
     const emptyFileds = Object.keys(this.formCCreateForm.controls).filter(key =>this.formCCreateForm.controls[key].errors !== null)

     if(this.formCCreateForm.status !== 'INVALID'){
    this.button = 'Processing...';
    this.isLoading = true;
    this.pitService.createFormCandD(this.formCCreateForm.value).subscribe(
      res => {
        this.responseDate = res['data'];
       
        if(res['success'] == 1){
          this.toastr.success(res['message']) 
          this.isLoading = false;
          this.button = 'Submit';
          
          this.router.navigate(['/pit-compliance/list-trading-report']); 
         
        }else{
          this.isLoading = false;
          this.button = 'Submit';         
          this.toastr.warning(res['message']) 
        }
                  
      });
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.formCCreateForm.get('name').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.formCCreateForm.get('panNo').value == ''){
        this.toastr.warning("Please fill the PAN Number.");
      }else if(this.formCCreateForm.get('cin_din').value == ''){
        this.toastr.warning("Please fill the CIN/DIN Number.");
      }else if(this.formCCreateForm.get('address').value == ''){
        this.toastr.warning("Please fill the Address.");
      }else if(this.formCCreateForm.get('mobNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }else if(this.formCCreateForm.get('categoryPerson').value == ''){
        this.toastr.warning("Please fill the  Category of Person.");
      }else if(this.formCCreateForm.get('typeOfSecurities').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal Type of securities.");
      }else if(this.formCCreateForm.get('perShareNumber').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal No. of share holding.");
      }else if(this.formCCreateForm.get('typeOfSecurities2').value == ''){
        this.toastr.warning("Please fill the Securities acquired/Disposed Type of securities.");
      }else if(this.formCCreateForm.get('typeOfSecuritiesNo').value == ''){
        this.toastr.warning("Please fill the No. of securities acquired/ disposed.");
      }else if(this.formCCreateForm.get('typeOfSecuritiesValue').value == ''){
        this.toastr.warning("Please fill the securities acquired/ disposed Value of Securities.");
      }else if(this.formCCreateForm.get('transactionType').value == ''){
        this.toastr.warning("Please fill the Transaction Type.");
      }else if(this.formCCreateForm.get('typeOfSecuritiespost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed Type of securities.");
      }else if(this.formCCreateForm.get('perShareHoldingNopost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed No. of share holding.");
      }else if(this.formCCreateForm.get('fromDate').value == ''){
        this.toastr.warning("Please fill the From Date.");
      }else if(this.formCCreateForm.get('toDate').value == ''){
        this.toastr.warning("Please fill the To Date.");
      }else if(this.formCCreateForm.get('intimateDate').value == ''){
        this.toastr.warning("Please fill the Date of intimation to company.");
      }else if(this.formCCreateForm.get('modeOfAcquisition').value == ''){
        this.toastr.warning("Please fill the Mode of acquisition /disposal.");
      }else if(this.formCCreateForm.get('exchangeTrade').value == ''){
        this.toastr.warning("Please fill the Exchange on which the trade.");
      }
    }
  }
  onSubmitFormD(){   
    let idendiferCard = this.formDCreateForm.get('panNo').value; 
    
    if(this.formDCreateForm.get('panNo').value != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       return false;
      }
    }
      this.submitted = true;
    this.formDCreateForm.value.formTypes = this.formTypes;
    const emptyFileds = Object.keys(this.formDCreateForm.controls).filter(key =>this.formDCreateForm.controls[key].errors !== null)

     if(this.formDCreateForm.status !== 'INVALID'){
    this.button2 = 'Processing...';
    this.isLoading2 = true;
    this.pitService.createFormCandD(this.formDCreateForm.value).subscribe(
      res => {
        this.responseDate = res['data'];
        
        if(res['success'] == 1){
          this.toastr.success(res['message']) 
          this.isLoading2 = false;
          this.button2 = 'Submit';         
           this.router.navigate(['/pit-compliance/list-trading-report']); 
      
        }else{
          this.isLoading2 = false;
          this.button2 = 'Submit';         
          this.toastr.warning(res['message']) 
        }
      });
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.formDCreateForm.get('name').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.formDCreateForm.get('panNo').value == ''){
        this.toastr.warning("Please fill the PAN Number.");
      }else if(this.formDCreateForm.get('cin_din').value == ''){
        this.toastr.warning("Please fill the CIN/DIN Number.");
      }else if(this.formDCreateForm.get('address').value == ''){
        this.toastr.warning("Please fill the Address.");
      }else if(this.formDCreateForm.get('mobNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }else if(this.formDCreateForm.get('typeOfSecurities').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal Type of securities.");
      }else if(this.formDCreateForm.get('perShareNumber').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal No. of share holding.");
      }else if(this.formDCreateForm.get('typeOfSecurities2').value == ''){
        this.toastr.warning("Please fill the Securities acquired/Disposed Type of securities.");
      }else if(this.formDCreateForm.get('typeOfSecuritiesNo').value == ''){
        this.toastr.warning("Please fill the No. of securities acquired/ disposed.");
      }else if(this.formDCreateForm.get('typeOfSecuritiesValue').value == ''){
        this.toastr.warning("Please fill the securities acquired/ disposed Value of Securities.");
      }else if(this.formDCreateForm.get('transactionType').value == ''){
        this.toastr.warning("Please fill the Transaction Type.");
      }else if(this.formDCreateForm.get('typeOfSecuritiespost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed Type of securities.");
      }else if(this.formDCreateForm.get('perShareHoldingNopost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed No. of share holding.");
      }else if(this.formDCreateForm.get('fromDate').value == ''){
        this.toastr.warning("Please fill the From Date.");
      }else if(this.formDCreateForm.get('toDate').value == ''){
        this.toastr.warning("Please fill the To Date.");
      }else if(this.formDCreateForm.get('intimateDate').value == ''){
        this.toastr.warning("Please fill the Date of intimation to company.");
      }else if(this.formDCreateForm.get('modeOfAcquisition').value == ''){
        this.toastr.warning("Please fill the Mode of acquisition /disposal.");
      }else if(this.formDCreateForm.get('exchangeTrade').value == ''){
        this.toastr.warning("Please fill the Exchange on which the trade.");
      }
    }
  }
}
