import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upsi-mail-template',
  templateUrl: './upsi-mail-template.component.html',
  styleUrls: ['./upsi-mail-template.component.scss']
})
export class UpsiMailTemplateComponent implements OnInit {
  @Input() viewUpsimailTemplate;
  sendermailTemplate: any;
  recipientmailTemplate: any;
  constructor(public activeModal: NgbActiveModal,  private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {
    this.sendermailTemplate = this.viewUpsimailTemplate.senderMailTemplate;
    this.recipientmailTemplate = this.viewUpsimailTemplate.recipientMailTemplate;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }
}
