import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PasswordStrengthValidator } from 'src/app/password-strength.validators';
import{ ProfileService } from '../../services/profile.service'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public newUser = false;
  public changePasswordForm: FormGroup;
  paramQuery: any;
  @Input() name;
  isLoading = false;
  button = 'Submit';
  @Input() msg = '';
  passwordIsValid = false;
   fieldTextType: boolean;
   fieldTextType2: boolean;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toastr: ToastrService, public router: Router, private activatedRoute: ActivatedRoute, private profileService:ProfileService) {
   
   }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],
      conformPassword: ['', Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])],      
    },
    // {
    //   validators: this.MustMatch('password', 'conformPassword')
    // }
    );
  }
get f(){
  return this.changePasswordForm.controls
}
  // MustMatch(controlName:string, matchingControlName: string){
  //   return(formGroup:FormGroup) =>{
  //     const control = formGroup.controls[controlName]
  //     const matchingControl = formGroup.controls[matchingControlName];
  //     if(matchingControl.errors && !matchingControl.errors.MustMatch){
  //       return;
  //     }
  //     if(control.value !== matchingControl.value ){
  //       matchingControl.setErrors({MustMatch:true});
  //     }else{
  //       matchingControl.setErrors(null);
  //     }
  //   }

  // }
onSubmit(){
  const emptyFileds = Object.keys(this.changePasswordForm.controls).filter(key =>this.changePasswordForm.controls[key].errors !== null)
  
  if (this.changePasswordForm.valid){
  if(this.changePasswordForm.get('password').value === this.changePasswordForm.get('conformPassword').value ){
    this.isLoading = true;
  this.button = 'Processing';
    this.profileService.ChangePasswordUser(this.changePasswordForm.value).subscribe(data =>{
      this.toastr.success("New password created", "success"); 
      this.isLoading = false;
    this.button = 'Submit';
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('sdd_user_permission');
      sessionStorage.removeItem('sdd_user_type');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('sdd_user_type');
      sessionStorage.removeItem('access_success');
      sessionStorage.removeItem('sdd_access_roles'); 
      this.activeModal.close(event);
      let self = this;
        setTimeout(() => {
          self.router.navigate(['auth/login']);
        }, 100);
      //window.location.reload();
    })
  }else{
    this.toastr.warning("Password Didn't Match");
    this.isLoading = false;
    this.button = 'Submit';
}
  }else if(emptyFileds.length > 1){
    this.isLoading = false;
    this.button = 'Submit';
    window.alert("Please fill all Mandatory fileds.");
    return false;
    
  }else{
    if(this.changePasswordForm.get('password').value == ''){
      window.alert("Please fill the Password.");
      this.isLoading = false;
    this.button = 'Submit';
    }else if(this.changePasswordForm.get('conformPassword').value == ''){
      window.alert("Please fill the Confirm Password.");
      this.isLoading = false;
    this.button = 'Submit';
    }
  }
}
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}
toggleFieldTextType2() {
  this.fieldTextType2 = !this.fieldTextType2;
}
closeModal(sendData) {
  this.activeModal.close(sendData);
}
passwordValid(event) {
  this.passwordIsValid = event;
}
}
