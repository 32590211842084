<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/sdd-insidder-login.png" alt=""></div>
			<div class="card mt-4">
				<div class="card-body">
					<div *ngIf="hideForm">
						<div class="text-center">
							<h4>Change Password</h4>						
						</div>
						<form class="theme-form change" [formGroup]="forgetPasswordForm" *ngIf="!newUser">
							<div class="form-group">
								<label class="col-form-label">New Password</label>
								<div class="hidePassword">
									<div class="input-group-append pass-right">
										<span class="input-group-text pass-right">
											<i
											class="fa"
											[ngClass]="{
											'fa-eye-slash': !fieldTextType,
											'fa-eye': fieldTextType
											}"
											(click)="toggleFieldTextType()"
										></i>
										</span>
									</div>
								<input class="form-control" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" required="" placeholder="Enter the New Password">
								<!-- <div *ngIf="forgetPasswordForm.controls.password.touched && forgetPasswordForm.controls.password.errors?.required"
									class="text text-danger mt-1">
									Password is required
								</div> -->
								<div class="errors" *ngIf="forgetPasswordForm.get('password').invalid && (forgetPasswordForm.get('password').touched || forgetPasswordForm.get('password').dirty)">

									<div *ngIf="forgetPasswordForm.get('password').hasError('required')">
										Password is required
									</div>
							
									<div *ngIf="forgetPasswordForm.get('password').errors.minlength">
										Password must be at least 8 characters
									</div>
							
									<div class="error-text" *ngIf="forgetPasswordForm.get('password').hasError('passwordStrength')">
										{{forgetPasswordForm.get('password').errors['passwordStrength']}}
									</div>
								</div>
								<div *ngIf="forgetPasswordForm.value.password != '' ">
									<app-strength-checker barLabel="Password Strength: " [passwordToVerify]="forgetPasswordForm.value.password" (passwordStrength)="passwordValid($event)" ></app-strength-checker>
								</div>	
								</div>				
							</div>
							<div class="form-group">
								<label class="col-form-label">Confirm New Password</label>
								<div class="hidePassword">
									<div class="input-group-append pass-right">
										<span class="input-group-text pass-right">
											<i
											class="fa"
											[ngClass]="{
											'fa-eye-slash': !fieldTextType2,
											'fa-eye': fieldTextType2
											}"
											(click)="toggleFieldTextType2()"
										></i>
										</span>
									</div>
								<input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'" formControlName="conformPasswords" required placeholder="Enter the Confirm New Password">
								<!-- <div *ngIf="forgetPasswordForm.controls.conformPasswords.touched && forgetPasswordForm.controls.conformPasswords.errors?.required"
									class="text text-danger mt-1">
									Conform Password is required
								</div> -->
								<div class="errors" *ngIf="forgetPasswordForm.get('conformPasswords').invalid && (forgetPasswordForm.get('conformPasswords').touched || forgetPasswordForm.get('conformPasswords').dirty)">

									<div *ngIf="forgetPasswordForm.get('conformPasswords').hasError('required')">
										Conform password is required
									</div>
							
									<div *ngIf="forgetPasswordForm.get('conformPasswords').errors.minlength">
										Password must be at least 8 characters
									</div>
							
									<div class="error-text" *ngIf="forgetPasswordForm.get('conformPasswords').hasError('passwordStrength')">
										{{forgetPasswordForm.get('conformPasswords').errors['passwordStrength']}}
									</div>
								</div>
								<!-- <div *ngIf="forgetPasswordForm.controls.errors?.MustMatch"
									class="text text-danger mt-1">
									Conform Password Didn't Match
								</div> -->
								<div *ngIf="forgetPasswordForm.value.conformPasswords != '' ">
									<app-strength-checker barLabel="Confirm Password Strength: " [passwordToVerify]="forgetPasswordForm.value.conformPasswords" (passwordStrength)="passwordValid($event)" ></app-strength-checker>
								</div>	
								</div>					
							</div>
							<div class="form-group" style="display: none;">
								<label class="col-form-label">Auth_Token</label>
								<input class="form-control" type="text" formControlName="auth_token" ([ngModel])="paramQuery">
								
							</div>
							
		<!-- <app-strength-checker-component barLabel="Password Strength: "
		[passwordToVerify]="forgetPasswordForm.value.password"
		(passwordStrength)="passwordValid($event)"></app-strength-checker-component> -->
							<div class="form-group row mt-3 mb-0">
								<button class="btn btn-primary btn-block btn-lg" [disabled]="isLoading" [disabled]="!forgetPasswordForm.valid" (click)="onSubmit(forgetPasswordForm.value)"
									type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></i> {{button}}
									<!-- <span>{{ authService.showLoader ? '' : 'Login' }}</span> -->
								</button>
							</div>
							
						</form>
					</div>
					<div class="successPage" *ngIf="successMessage">
						<svg version="1.1" xmlns="" viewBox="0 0 130.2 130.2">
							<circle class="path circle" fill="none" stroke="#eb7808" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
							<polyline class="path check" fill="none" stroke="#eb7808" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
						  </svg>
						  <h6>Password Changed Successfully!</h6>
						  <p>Please Log In With Your New Password.</p>
						  <div class="button">
						  	<a routerLink="/auth/login"><button class="btn btn-primary btn-block btn-lg" type="button">Login now</button></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


