import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-delete-company-rules',
  templateUrl: './delete-company-rules.component.html',
  styleUrls: ['./delete-company-rules.component.scss']
})
export class DeleteCompanyRulesComponent implements OnInit {
  companyId: any;
  accessSuccess: string;
  rulesReasonDeleteForm: FormGroup;
  @Input() viewCompanyRules;
  isLoading = false;
  button = 'Delete';
  getId: any;
  submitted = false;
  constructor(public activeModal: NgbActiveModal,private masterService: MasterService,private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
  //   this.activateRouter.params.subscribe(data =>{
  //     this.companyId = data['id']
  //   })
  //   if(this.companyId){
  //     this.masterService.deleteCompanyRules(this.companyId).subscribe(data =>{
  //     // console.log(agenda Details deleted )
  //     this.toastr.success("Company Rules Successfully Deleted");
  //     this.router.navigate(['/setup/rules-list']);
  //   })
  // }

  this.rulesReasonDeleteForm = this.fb.group({
    id: new FormControl(''),
    reasonfordelete : new FormControl('', Validators.required)
  });
 this.getId = this.viewCompanyRules.id;


  }
  onSubmit(){
    this.submitted = true;
    this.rulesReasonDeleteForm.value.id = this.getId;
    if(this.rulesReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
        this.button = 'Processing';
    this.masterService.deleteCompanyRules(this.rulesReasonDeleteForm.value).subscribe(data =>{
      console.log(this.rulesReasonDeleteForm.value )
      this.activeModal.close(event);
      this.toastr.success("Company Rules Successfully Deleted");
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      window.location.reload();
      
    })
  }else{
    if(this.rulesReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Rules.");
    }
  }
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
