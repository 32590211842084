<div class="modal-header">
    <h5 class="modal-title">Do you wish to delete this Trading Window Closure?</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <form [formGroup]="twcReasonDeleteForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-sm-12 col-xl-12">
                                   
                                        <div class="col-md-12 mb-3">
                                            <label for="nameOfInsider">Reason to Delete Trading Window Closure</label>
                                            <input type="text" class="form-control"  id="reasonfordelete" placeholder="Reason to Delete Trading Window Closure" formControlName="reasonfordelete" autocomplete="off" 
                                            [ngClass]="{ 'is-invalid': (submitted || twcReasonDeleteForm.controls.reasonfordelete.touched) && twcReasonDeleteForm.controls.reasonfordelete.errors }"> 
                                            <div *ngIf="(submitted || twcReasonDeleteForm.controls.reasonfordelete.touched) && twcReasonDeleteForm.controls.reasonfordelete.errors?.required"
                                            class="text text-danger">
                                            Please mention the reason to Delete Trading Window Closure
                                            </div>                                       
                                        </div>
                                        <div class="submit-btn">
                                            <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                            <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>                    
                                         </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
