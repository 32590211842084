import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { ViewCustomerComponent } from './view-customer/view-customer.component';
import { ListCustomerComponent } from './list-customer/list-customer.component';
import { DeleteCustomerComponent } from './delete-customer/delete-customer.component';
import { DeletedCustomerModuleComponent } from './deleted-customer-module/deleted-customer-module.component';

const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: ListCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "Customers List"
        }
      }, 
      {
        path: 'list-customer',
        component: ListCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "Customers List"
        }
      },  
      {
        path: 'add-customer',
        component: AddCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "New"
        }
      },
      {
        path: 'edit-customer/:id',
        component: EditCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "Update"
        }
      },
      {
        path: 'view-customer',
        component: ViewCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "View"
        }
      },
      {
        path: 'delete-customer',
        component: DeleteCustomerComponent,
        data: {
          title: "Customers",
          breadcrumb: "Delete"
        }
      },
      {
        path: 'deleted-customer',
        component: DeletedCustomerModuleComponent,
        data: {
          title: "Customers",
          breadcrumb: "Deleted Customers List"
        }
      },
   
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class customerRoutingModule { }
