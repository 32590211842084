import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
// import * as authentication from '../authentication';
import{ GlobalComponent } from '../authentication';

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
import { AnnualConformModuleComponent } from 'src/app/shared/components/annual-conform-module/annual-conform-module.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { ModelControlService } from 'src/app/model-access-control/model-control.service';
import { CompanyService } from 'src/app/master/company/company.service';
import { EntrymessageComponent } from 'src/app/master/popup/entrymessage/entrymessage.component';
export class User {
  _id!: String;
  username!: String;
  password!: String;
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {   
  showLoader: boolean = false;
  button = 'Login';
  endpoint: string = environment.apiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};
  tokenSubscription: any;
  listDpModule: any;
  annualConformationData: any;
  financialyear: string;
  last: string;
  company_name:any;
  company_role: string;
  financialYearEntry: any;
  editReason: any;
  complianceofficeremail: any;
  constructor(private http: HttpClient, public router: Router, private toastr: ToastrService, private modalService: NgbModal,
    private dpService:DpModuleService,private companyService: CompanyService, private modelService: ModelControlService) { }
  // Sign-up
  // signUp(user: User): Observable<any> {
  //   let api = `${this.endpoint}/register-user`;
  //   return this.http.post(api, user).pipe(catchError(this.handleError));
  // }
  // Sign-in
  signIn(user: User) {   
    this.showLoader = true;
    this.button = 'Processing';
    return this.http
      .post<any>(`${this.endpoint}user-login`, user)
      .subscribe((res: any) => {
        if(res.success == 1){          
          GlobalComponent.module_name = res.permissionSettings[0].module_name;
          
          sessionStorage.setItem('temp_token', res.access_token);
          sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
          if(res.permissionSettings && res.permissionSettings[0].role_type){
            sessionStorage.setItem('sdd_user_type', res.permissionSettings[0].role_type);
            sessionStorage.setItem('access_user', res.permissionSettings[0].username);
            sessionStorage.setItem('sdd_access_roles', btoa(res.permissionSettings[0].module_name));
            sessionStorage.setItem('access_success', res.success);
            let accessUser = sessionStorage.getItem('access_user')

            sessionStorage.setItem('model_access_controller', res.modelPermission[0].module_controller);
            
            let accessObj = res.permissionSettings;
          
            // let userPermission = accessObj.no_access == 1 ? 'none': accessObj.write_access == 1 ? 'write' : accessObj.read_access == 1 ? 'read' : 'none';
            // console.log(userPermission);
            // sessionStorage.setItem('sdd_user_permission', userPermission);
          }
          this.companyService.CompanyList(null).subscribe(
            res => {
            let listCompany = res.body['data']  
       
            sessionStorage.setItem('complianceofficeremail',listCompany[0].complianceofficeremail);
            sessionStorage.setItem('companyName',listCompany[0].company_name);
            });
          // this.getUserProfile(res._id).subscribe((res) => {
          //   this.currentUser = res;
          //   this.router.navigate(['upsi/upsi-list/']);
          // });
        //   let otpVerify:any;
        //   otpVerify = JSON.parse(sessionStorage.getItem('model_access_controller'));        
        //   this.button = 'Login';
        //  // this.getAccess();
        // // this.getAccessprofile();
        //  this.showLoader = false;
        //  if(otpVerify.otp_access != true){
        //   this.router.navigate(['/dashboard']);
        //  }else{
        //   if(sessionStorage.getItem('access_user') != "sdd@legalitysimplified.com"){
            
        //     this.router.navigate(['/auth/otp-authentication']);  
        //     }else{
        //       sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
        //       this.router.navigate(['/dashboard']); 
        //     }
        // }
        this.showLoader = false;
        this.button = 'Login';
         this.router.navigate(['/dashboard']);
          //window.location.reload();
          // if(sessionStorage.getItem('access_user') != "sdd@legalitysimplified.com"){
          // this.router.navigate(['/auth/otp-authentication']);  
          // }else{
          //   sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
          //   this.router.navigate(['/dashboard']); 
          // }
        //this.getdpdetails()

          // this.open();        
   this.annualconfirmationEntry();
          //this.toastr.success("Login Successful!"); 
          //   history.pushState(null, '', location.href);
          // window.onpopstate = function () {
          //   history.go(1);
          // };     
          // this.expirationCounter(res.access_token);
        
        }else if(res.success == 2){
           this.showLoader = false;
          this.button = 'Login';
          this.toastr.error(res.message);
        }else if(res.success == 3){
         // let otpVerify:any;
         // otpVerify = JSON.parse(sessionStorage.getItem('model_access_controller'));        
         // this.button = 'Login';
         // this.getAccess();
        // this.getAccessprofile();
        //  this.showLoader = false;
        //  if(otpVerify.otp_access != true){
        //   this.router.navigate(['/dashboard']);
        //  }else{
          sessionStorage.setItem('access_tokens', res.access_token);
          sessionStorage.setItem('access_user', res.username);
          
          this.router.navigate(['/auth/otp-authentication']);  
          // if(sessionStorage.getItem('access_user') != "sdd@legalitysimplified.com"){
            this.showLoader = false;
            this.button = 'Login';
          //   this.router.navigate(['/auth/otp-authentication']);  
          //   }else{
          //     sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
          //     this.router.navigate(['/dashboard']); 
          //   }
          //}
        } else {
          this.showLoader = false;
          this.button = 'Login';
          this.toastr.error(res.message);
        }
        
      });
  }
  getToken() {
    return sessionStorage.getItem('access_token');
  }
  getUser() {
    return sessionStorage.getItem('sdd_user_type');
  }
  getUserName() {
    return sessionStorage.getItem('access_user');
  }
  // get isLoggedIn(): boolean {
  //   let authToken = sessionStorage.getItem('access_token');
  //   return authToken !== null ? true : false;
  // }
  // expirationCounter(timeout) {
  //   // this.tokenSubscription.unsubscribe();
  //   this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
  //     console.log('EXPIRED!!');

  //     this.doLogout();
  //     this.router.navigate(["auth/login"]);
  //   });
  // }
  // getAccess(){
  //   //model Access
  //   this.modelService.moduleControlList(null).subscribe(
  //     res => {
  //     console.log(res.data[0]);
  //     let resp_data = res.data[0];
  //     console.log(resp_data);
  //     sessionStorage.setItem('model_access_controller', resp_data.module_controller);
  //     this.showLoader = false;
  //     this.router.navigate(['/dashboard']);
  //     })
    
  // }
  getAccessprofile(){
    //model Access
    // this.companyService.CompanyList(null).subscribe(
    //   res => {        
    //   let listCompany = res.body['data']     
    //   sessionStorage.setItem('predaycount', listCompany[0].predaycount);
    //   //  if(listCompany[0].company_name == 'Arpwood'){
    //   //   this.company_name = 'Admin';
    //   //   this.company_role = 'CFO';
    //   //  }else{
    //   //   this.company_name = 'Admin';
    //   //  }
       
    //   // sessionStorage.setItem('companyname', this.company_name); 
    //   // sessionStorage.setItem('companyCFO', this.company_role); 
    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    
  }
  doLogout(e:any) {
  
    this.http
      .post<any>(`${this.endpoint}user-logout`,e, {
        headers: {'Authorization': sessionStorage.getItem('access_token')},
      })
      .subscribe((res: any) => {       
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('sdd_user_permission');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('access_success');
        sessionStorage.removeItem('sdd_access_roles');
        sessionStorage.removeItem('isinNumber');
        sessionStorage.removeItem('model_access_controller');
        sessionStorage.removeItem('predaycount'); 
        let self = this;
        window.location.reload();
        setTimeout(() => {
          this.showLoader = false;
          self.router.navigate(['auth/login']);
        }, 100);
      });
  }
  // User profile
  // getUserProfile(id: any): Observable<any> {
  //   let api = `${this.endpoint}user-login/${id}`;
  //   return this.http.get(api, { headers: this.headers }).pipe(
  //     map((res) => {
  //       return res || {};
  //     }),
  //     catchError(this.handleError)
  //   );
  // }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

  open() {
		const modalRef = this.modalService.open(AnnualConformModuleComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.name = 'Annual Confirmation Details';
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
	}
 
  annualconfirmationEntry() {
    let accessUser = sessionStorage.getItem('access_user');
    let d = new Date();
    this.financialYearEntry = d.getMonth() < 3 ? d.getFullYear() - 1 : d.getFullYear();
    var fiscalyear_ = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear_ = ((today.getFullYear() - 1) + "") + "-" + (today.getFullYear() + "").slice(-2)
    } else {
      fiscalyear_ = (today.getFullYear() + "") + "-" + ((today.getFullYear() + 1) + "").slice(-2)
    }
    this.financialYearEntry = fiscalyear_
    this.companyService.CompanyList(null).subscribe(
        res => {
        let listCompany = res.body['data']  
   
          this.complianceofficeremail = listCompany[0].complianceofficeremail
          
       if(this.complianceofficeremail == accessUser) {
        this.dpService.annualconfirmpopupentrydetails(null).subscribe(
          res =>{
            let listDpACModule = res['data'];
            let listofEntry = listDpACModule[0];

            if(listDpACModule.length > 0){
            //listDpACModule.map(item =>{
              if(listofEntry?.financialyear != this.financialYearEntry || listofEntry?.status != 'Yes'){
                  this.openEntry(this.financialYearEntry);
              }
            }else{   
                  this.openEntry(this.financialYearEntry); 
            }
           // })
    
          }
        )
       }

        });

   
    //return fiscalyear_
		
	}
  openEntry(financialYearEntry) {
		const modalRef = this.modalService.open(EntrymessageComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.name = 'Annual Confirmation';
    modalRef.result.then((result) => {
      this.editReason=result;
      if(this.editReason != 'dismiss'){       
        let ItemArray = {'type': this.editReason,'status':'Yes', 'financialyear': financialYearEntry};
        if(this.editReason == 'Draft'){
          this.router.navigate(['/setup/annual-confirmation-email']);  
        }else if(this.editReason == 'Send'){          
          this.dpService.annualconfirmpopupentryupdate(ItemArray).subscribe(data =>{
            this.toastr.success("Annual Confirmation Email Successfully Send");
          })
        }else if(this.editReason == 'View'){
          this.router.navigate(['/setup/list-mail-template']);  
        }
        // this.dpService.annualconfirmpopupentrycreate(ItemArray).subscribe(
        //   res =>{
        //     this.router.navigate(['/setup/annual-confirmation-email']);  

        //   });  
      
        }      
    }, (reason) => {
    });
	}
  //Mail Config
subscriptionCheck(userObj:any):Observable<any>{
  return this.http.post(this.endpoint + "subscriptionCheck", userObj);
}
getCurrentFinancialYear() {
  this.financialyear = "";
  var today = new Date();
  if ((today.getMonth() + 1) <= 3) {

    this.financialyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
  } else {
    this.financialyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
  }
  
  return this.financialyear

}
getdpdetails(){
  this.getCurrentFinancialYear();
  let listDpannualReport = [];
 let accessUser = sessionStorage.getItem('access_user')
  this.dpService.dpModuleList(null).subscribe(
    res => {
     
    this.listDpModule = res['data'];
   this.listDpModule.map((item) =>{
      
      let otherDpannualReport = [];
      if(accessUser == item.email){
        listDpannualReport =item;
              
      }else{
        otherDpannualReport.push(item);
      }
    })
    
    this.annualConformationData = listDpannualReport; 
    //console.log("annualConformationData", this.annualConformationData)
    let annualconfirmentry = this.annualConformationData['annualConfirmation'];
    if(this.annualConformationData != ''){
     
        if(annualconfirmentry.length > 0 ){       
          this.last = annualconfirmentry.slice(-1)[0].financialYear;   
                    
          }else{             
            this.last = '' ;
          } 
         
          //check Financial Year
          if(this.financialyear != this.last ){
            this.open();
          }
    
    }

      });
}
}
