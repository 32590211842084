import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelControlService {
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl
  
 moduleControlList(userObj:any):Observable<any> {
   
    return this.http.post(this.baseUrl + 'role/modelcontrolaccesslist', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      // observe: 'response'
    });
  }
 moduleControlUpdate(userObj:any):Observable<any> {
   
    return this.http.post(this.baseUrl + 'role/modelcontrolaccessUpdate', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      // observe: 'response'
    });
  }
 moduleControlCreate(userObj:any):Observable<any> {
   
    return this.http.post(this.baseUrl + 'role/modelcontrolaccess', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      // observe: 'response'
    });
  }
  moduleControlEdit(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "role/modelcontrolaccessedit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
}
