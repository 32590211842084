import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-add-mail-template',
  templateUrl: './add-mail-template.component.html',
  styleUrls: ['./add-mail-template.component.scss']
})
export class AddMailTemplateComponent implements OnInit {
  mailCreateForm: FormGroup;
  htmlContent = '';
  isLoading = false;
  button = 'Save';
  submitted: boolean = false; 
  image: string;
  attachName: any;
  path: any;
  attachmentPath: boolean;
  @ViewChild('userPhoto') userPhoto: ElementRef;
  pathImage: any;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private masterService: MasterService) { }

  ngOnInit(): void {
    this.mailCreateForm = this.fb.group({
      templatename: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      subject: new FormControl('',[Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      bodycontent: new FormControl ('',[Validators.required,Validators.pattern(/^(\\'|[^'])*$/)]),
      
    });
  }
  
  cancelMailTemplate(){
    this.router.navigate(['/setup/list-mail-template']);
    this.mailCreateForm.reset();
  }
  onSubmit(){
  
    this.submitted = true;    
    const emptyFileds = Object.keys(this.mailCreateForm.controls).filter(key =>this.mailCreateForm.controls[key].errors !== null)
    if(this.mailCreateForm.status !== 'INVALID'){
      this.isLoading = true;
    this.button = 'Processing';
    this.masterService.mailTemplateCreate(this.mailCreateForm.value).subscribe(data =>{   
      this.toastr.success("Mail Template successfully created");
      this.isLoading = false;
      this.button = 'Save';
        this.router.navigate(['/setup/list-mail-template']);
      
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fields.");
    return false;
  }else{
    if(this.mailCreateForm.get('templatename').value == ''){
      this.toastr.warning("Please fill the Template Name.");
    }else if(this.mailCreateForm.get('subject').value == ''){
      this.toastr.warning("Please fill the Subject.");
    }else if(this.mailCreateForm.get('bodycontent').value == ''){
      this.toastr.warning("Please fill the Body Content.");
    }
  }
  }


  //Angular Editor Setting
   config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    toolbarHiddenButtons: [
      []
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  // toolbarHiddenButtons: [
  //   [
  //     'undo',
  //     'redo',
  //     'bold',
  //     'italic',
  //     'underline',
  //     'strikeThrough',
  //     'subscript',
  //     'superscript',
  //     'justifyLeft',
  //     'justifyCenter',
  //     'justifyRight',
  //     'justifyFull',
  //     'indent',
  //     'outdent',
  //     'insertUnorderedList',
  //     'insertOrderedList',
  //     'heading',
  //     'fontName'
  //   ],
  //   [
  //     'fontSize',
  //     'textColor',
  //     'backgroundColor',
  //     'customClasses',
  //     'link',
  //     'unlink',
  //     'insertImage',
  //     'insertVideo',
  //     'insertHorizontalRule',
  //     'removeFormat',
  //     'toggleEditorMode'
  //   ]
  // ]
}
