<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD RULES</h5>
                    <a routerLink="/setup/rules-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="createCompanyRulesForm" class="needs-validation" (ngSubmit)="onSubmit()">
                   <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="companyName">Rule Name <span class="manditary-filed">*</span></label>
                            <div class="form-group">
                                <input type="text" class="form-control" id="companyName" placeholder="Enter the Rule Name" autocomplete="off" formControlName="rule_name" required [ngClass]="{ 'is-invalid': (submitted || createCompanyRulesForm.controls.rule_name.touched) && createCompanyRulesForm.controls.rule_name.errors }"> 
                                <div *ngIf="(submitted || createCompanyRulesForm.controls.rule_name?.touched) && createCompanyRulesForm.controls.rule_name.errors?.required"
                                    class="text text-danger">
                                    Rule Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3 rules_details">
                            <div class="col-sm-12">                                    
                                  <div class="dprelative-details annual-conformation">                                                                       
                                      <div formArrayName="rules_model">
                                       
                                            <div *ngFor="let hobby of createCompanyRulesForm.get('rules_model')['controls']; first as isFirst; index as idx">
                                               
                                                <div [formGroupName]="idx" class="input-row">
                                                
                                                    <div class="inline-box">
                                                        <label>Rule Condition</label>
                                                        <div class="form-group">
                                                            <!-- <input type="date" class="form-control" id="{{'date_of_trade' + idx}}"  autocomplete="off"
                                                                placeholder="Date of Trade" formControlName="date_of_trade" > -->
                                                                <select class="form-select" class="form-control form-select" id="{{'rules_condition' + idx}}" formControlName="rules_condition">
                                                                    <option value="" disabled selected>--Choose One --</option>
                                                                    <option value="Value">Value</option>
                                                                    <option value="Share">Share</option>                                                                    
                                                                </select> 
                                                        </div>
                                                    </div>
                                                
                                                    <div class="inline-box">
                                                        <label>Setting</label>
                                                        <div class="form-group">                                                                                                                                      
                                                            <select class="form-select" class="form-control form-select" id="{{'rules_setting' + idx}}" formControlName="rules_setting">
                                                                <option value="" disabled selected>--Choose One --</option>
                                                                <option value="Greater than">Greater than</option>
                                                                <option value="Less than">Less than</option>
                                                                <option value="Equal to">Equal to</option>
                                                                <option value="Greater than or equal to">Greater than or equal to</option>
                                                                <option value="Less than or equal to">Less than or equal to</option>
                                                            </select>    
                                                        </div>
                                                    </div>
                                            
                                                
                                                    <div class="inline-box">
                                                        <label >Value</label>
                                                        <div class="form-group">
                                                            <input class="form-control" id="{{'rules_value' + idx}}" placeholder="Enter the Value" type="text" formControlName="rules_value" >                                                                       
                                                        </div>
                                                    </div>
                                                

                                                
                                                    <span style="min-width:28px;"><span (click)="addInputControlsRules()"
                                                        *ngIf="idx === 0"><i class="fa fa-plus-circle"
                                                            aria-hidden="true"></i></span></span>
                                                <span (click)="removeInputControlsRules(idx)" *ngIf="createCompanyRulesForm.get('rules_model').length > 1"><i
                                                        class="fa fa-minus-circle" aria-hidden="true"></i></span>
                                                

                                              </div>                                           
                                            
                                            </div>                                       
                                         
                                  </div>
                                  
                              </div>
                        </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="rules_status">Rule Approved/rejected  </label>
                            <div class="form-group">
                                <select class="form-select" class="form-control form-select"  formControlName="rule_status" required [ngClass]="{ 'is-invalid': (submitted || createCompanyRulesForm.controls.rule_status.touched) && createCompanyRulesForm.controls.rule_status.errors }">
                                    <option value="" disabled selected>--Choose One --</option>
                                    <option value="Approved">Approved</option>
                                    <option value="rejected">rejected</option>                                                                    
                                </select> 
                                <div *ngIf="(submitted || createCompanyRulesForm.controls.rule_status?.touched) && createCompanyRulesForm.controls.rule_status.errors?.required"
                                class="text text-danger">
                                    Rule Approved/rejected is required.
                                </div>
                            </div>
                        </div>

                        <div class="submit-btn">
                            <button class="btn btn-primary" type="submit" [disabled]="isLoading" ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                            <button class="btn btn-warning" type="button" (click)="cancelrules()" >Cancel</button>                    
                         </div>
                   </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->