import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-activity-logs',
  templateUrl: './project-activity-logs.component.html',
  styleUrls: ['./project-activity-logs.component.scss']
})
export class ProjectActivityLogsComponent implements OnInit {
  user_roles: any;
  role_type: any;
  user_access: boolean = false;
  dpId: any;
  //viewDpList;
  constructor(private activateRouter:ActivatedRoute,  public activeModal: NgbActiveModal, private http:HttpClient) {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')))
    this.role_type = this.user_roles.roles_name;
    if(this.user_roles.roles_name !== "Admin"){
      this.user_access = false;
    }else{
      this.user_access = true;
    }
   }

   @Input() viewProjectList;



   ngOnInit() {
     // console.log("HI2", this.viewDpList);
     
   }
 
   closeModal(sendData) {
     this.activeModal.close(sendData);
   }

}
