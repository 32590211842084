import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { distinct, from, tap } from 'rxjs';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';
import { PitComplianceService } from '../../pit-compliance.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/customer/customer.service';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-add-trading-window-closure',
  templateUrl: './add-trading-window-closure.component.html',
  styleUrls: ['./add-trading-window-closure.component.scss']
})
export class AddTradingWindowClosureComponent implements OnInit {
  submitted:boolean = false;
  isLoading = false;
  button = 'Save';
  twcCreateForm: FormGroup;
  htmlContent = '';
  selected_sender:any = [];
  selected_receiver:any= [];
  selected_both:any = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  dropdownSettings2:IDropdownSettings;
  selectedItems = [];
  listDpModule: any;
  senderList:any = [];
  selectedSenderItems = [];
  selectedRecipentDpItems = [];
  recipientDpList:any = [];
  listinsider: any;
  recipentInsiderList:any = [];
  selectedRecipentInsiderItems = [];
  selectedRecipentbothItems = [];
  recipientBothList:any = [];
  recipientBothList1 = [];
  dropdownListRep:any = [];
  dropdownListInsider:any = [];
  dropdownListRepAll = [];
  selectedFile:any = [];
  pathImage: any[];
  selectedFileName: any[] = [];
  openTwcAttachment: boolean = false;
  tradingCompanyNameList: any;
  selectedtradingCompanyNameList = [];
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  companyApplication: any;
  constructor(private fb: FormBuilder, private router:Router, private companyService: CompanyService,private customerService: CustomerService,private dpService:DpModuleService, private insiderService:InsiderServiceService, private toastr: ToastrService, private pitService: PitComplianceService) { }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.twcCreateForm && this.twcCreateForm.get("start_date")?.value;
    const to = this.twcCreateForm && this.twcCreateForm.get("end_date")?.value;
  
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
     
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  ngOnInit(): void {
    let accessData:any = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.twcattachment_access == true || accessModelController.twcattachment_access == 1){
       this.openTwcAttachment = true;
    }else{
       this.openTwcAttachment = false;
    }
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.customer_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.customer_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    this.twcCreateForm = this.fb.group({
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', [Validators.required,this.dateRangeValidator]),
      sender:new FormControl ('', Validators.required),
      recipientvalue:new FormControl ('Insider'),
      recipient: new FormControl('', Validators.required),
      tradingcompanyname: new FormControl(''),
      period: new FormControl(''),
      subject: new FormControl('', Validators.required),
      htmlcontents: new FormControl (''),
      agreement_attachment_file: new FormControl(),
      agreement_attachment: new FormControl(),

    });

    //multi select
    this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 2,
        allowSearchFilter: true,
        defaultOpen: false,
      };
      this.dropdownSettings1 = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 2,
        allowSearchFilter: true
      };

      this.companyService.CompanyList(null).subscribe(
        res => {
          if (res.body['message'] == 'Invalid User.') {
            this.router.navigate(['/auth/login']);
          } else {
            let listCompany = res.body['data']
            this.companyApplication = listCompany[0].customertype;
            console.log("companyApplication", this.companyApplication)
          }
        },
        err => {
          console.log(err);
        }
      );

      this.selectedItems = [];
      this.dropdownListRepAll = [];
      //DP Api Call
      this.dpService.dpModuleList(null).subscribe(
        res => {
          this.senderList = [];
          this.recipientDpList = [];
          
          // this.selectedRecipentbothItems = [];
        this.listDpModule = res['data']
        // const senderList2 = this.listDpModule.map(res =>res.email);
        // const senderList3 = senderList2.filter(result => result)
          const insiderList = this.listDpModule.map(({id,email}) =>({item_id: id,item_text: email}));
      this.dropdownListRep = [];
      insiderList.forEach(result =>this.dropdownListRep.push(result))
       
        let dropdownListSender:any = [];
        let senderList = this.listDpModule.map(item =>dropdownListSender.push(item.email));
   
    //insiderList.forEach(result =>this.dropdownListRep.push(result))
      from(dropdownListSender)
      .pipe(
        tap(item => item),
        distinct(d => d)
      )
      .subscribe(r => this.senderList.push(r));
         //recipent Dp
         from(this.dropdownListRep)
         .pipe(
           tap(item => item),
           distinct((d:any) => d.item_text)
         )
         .subscribe(r => this.recipientDpList.push(r));
        //  this.recipientDpList.forEach(result =>this.dropdownListRepAll.push(result))
         //Dp Both
        //  from(this.dropdownListRep)
        //  .pipe(
        //    tap(item => item),
        //    distinct(d => d.item_text)
        //  )
        //  .subscribe(r => this.recipientBothList.push(r));
          },
        //recipent Dp
          err => {
              console.log(err);
          }
      );

      //Insider Api
    this.insiderService.insiderList(null).subscribe(
      res => {
        this.recipentInsiderList = [];
        // this.selectedRecipentbothItems = []
        
      this.listinsider = res.data
      // const recipentList2 = this.listinsider.map(res =>res.email);
      //   const recipentList3 = recipentList2.filter(result => result)
      const insiderList = this.listinsider.map(({id,email}) =>({item_id: id,item_text: email}));
      this.dropdownListInsider = [];
      insiderList.forEach(result =>this.dropdownListInsider.push(result))
       
        
        from(this.dropdownListInsider)
        .pipe(
          tap(item => item),
          distinct((d:any) => d.item_text)
        )
        .subscribe(r => this.recipentInsiderList.push(r));
        // this.selectedRecipentbothItems.push(this.recipentInsiderList);
        //Insider Both
        // this.recipentInsiderList.forEach(result =>this.dropdownListRepAll.push(result))
        // this.dropdownListRepAll.forEach((item, index) => item.item_id = index + 1);
        // from(this.dropdownListRepAll)
        // .pipe(
        //   tap(item => item),
        //   distinct(d => d.item_text)
        // )
        // .subscribe(r => this.recipientBothList.push(r));
        },
        err => {
            console.log(err);
        }
    );
//Both Api

this.pitService.dpInsiderrecipient(null).subscribe(
  res => {
    const bothListEmail = res.data;

  const bothList = bothListEmail.map(({dpemail}) =>({item_text: dpemail}));      
  bothList.forEach((item, index) => item.item_id = index + 1);
  
  this.recipientBothList = [];
  from(bothList)
  .pipe(
    tap(item => item),
    distinct(d => d)
  )
  .subscribe(r => this.recipientBothList.push(r));   
  
 
    },
    err => {
        console.log(err);
    }
);
//  this.selectedRecipentbothItems =  this.recipientBothList;
this.selectedRecipentbothItems = []
this.onFileChanged(event);
  }
   //attachement file
   onFileChanged(event: any) {
    
    if (event?.target.files && event?.target.files[0]) {
      var filesAmount = event?.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        //this.selectedFileName.push(event?.target.files[i].name)
        this.selectedFileName.push(event?.target.files[i].name);
        //this.fileList.push(event?.target.files[i].name);

        reader.onload = (event: any) => {
          this.selectedFile?.push(event.target.result);

        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }
//customer
this.customerService.customerTradingAllowedList(null).subscribe(
  res => {
    let allowedCompanyList = [];
    let allowedCompanyLists = [];
  let listcompanyList = res.body['data'];  

  let dropdownListCompany:any = [];
    const tradingCompanyList = listcompanyList?.map(({id,company_name}) =>({item_id: id,item_text: company_name}));      
    tradingCompanyList.forEach(result =>dropdownListCompany.push(result))
    this.tradingCompanyNameList = [];
    from(dropdownListCompany)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.tradingCompanyNameList.push(r));
  listcompanyList.map(item =>{
      // if(item.tradingpermission == 'Allowed'){
      //     allowedCompanyList.push(item);  
      //     allowedCompanyList.map(item => allowedCompanyLists.push(item.company_name) ) 
      //     this.allowedCompanyList = [];
      //     from(allowedCompanyLists)
      //     .pipe(
      //       tap(item => item),
      //       distinct(d => d)
      //     )
      //     .subscribe(r => this.allowedCompanyList.push(r));
      //   }else{
      //   this.regstrictedCompanyList.push(item);
      // }
  })   
 
    },
    err => {
        console.log(err);
    }
);

  }
  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.selectedFile?.splice(index, 1);
    // delete file from FileList
    this.selectedFileName?.splice(index, 1);
  }
  cancelTwcTemplate(){
    this.router.navigate(['/pit-compliance/list-twc']);
    this.twcCreateForm.reset();
    this.submitted = false;
  }
  onSubmit(){
    this.submitted = true;
    const emptyFileds = Object.keys(this.twcCreateForm.controls).filter(key =>this.twcCreateForm.controls[key].errors !== null)
    this.twcCreateForm.value.agreement_attachment = this.selectedFile;
    this.twcCreateForm.value.attachmentfilename = this.selectedFileName;
    console.log(this.twcCreateForm.value);
 
    if(this.twcCreateForm.status !== 'INVALID'){
    this.isLoading = true;
      this.button = 'Processing';
    this.pitService.tradingWindowClosureCreate(this.twcCreateForm.value).subscribe(data =>{  
      this.isLoading = false;
      this.button = 'Save'; 
      this.toastr.success("Trading Window Closure details created", "Success!");
        this.router.navigate(['pit-compliance/list-twc']);
      
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    this.isLoading = false;
      this.button = 'Save';
    return false;
  }else if(this.twcCreateForm.get('start_date')?.value > this.twcCreateForm.get('end_date')?.value ){
    this.toastr.warning("Please add a valid from and To date.");
  }else{
    if(this.twcCreateForm.get('sender')?.value == ''){
      this.toastr.warning("Please fill the Sender Name.");
    }else if(this.twcCreateForm.get('recipient')?.value == ''){
      this.toastr.warning("Please fill the Recipient Name.");
    }else if(this.twcCreateForm.get('subject')?.value == ''){
      this.toastr.warning("Please fill the Subject.");
    } else if(this.twcCreateForm.get('start_date')?.value == ''){
      this.toastr.warning("Please fill the Start Date.");
    } else if(this.twcCreateForm.get('end_date')?.value == ''){
      this.toastr.warning("Please fill the End Date.");
    }  
    
  }
  }


  //Angular Editor Setting
   config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    defaultFontSize: '',
    toolbarHiddenButtons: [
      []
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

// Multi Select
onItemSelect(item: any,page) {
  
   if(page == "sender"){
    if(!this.selected_sender.includes(item)){
      this.selected_sender.push(item);
    }
  }else if(page == "receiver"){
    if(!this.selected_receiver?.includes(item)){
      this.selected_receiver.push(item);
    }
  }else if(page == "both"){
    if(!this.selected_both?.includes(item)){
      this.selected_both.push(item);
    }
  }
}

onItemDeSelect(item: any,page) {
 
  if(page == "sender"){
    if(this.selected_sender.includes(item)){
      this.selected_sender.splice(this.selected_sender.indexOf(item), 1);
    }
  }else if(page == "receiver"){
    if(this.selected_receiver.includes(item)){
      this.selected_receiver.splice(this.selected_receiver.indexOf(item), 1);
    }
  }else if(page == "both"){
    if(this.selected_both.includes(item)){
      this.selected_both.splice(this.selected_both.indexOf(item), 1);
    }
  }
}
onSelectAll(items: any,page) {
 if(page == "sender"){
    this.selected_sender=items;
  }else if(page == "receiver"){
    this.selected_receiver=items;
  }
  else if(page == "both"){
    this.selected_both=items;
  }
  
}
onDeSelectAll(items: any,page) {
 if(page == "sender"){
      this.selected_sender=[];
    }else if(page == "receiver"){
      this.selected_receiver=[];
    }
    else if(page == "both"){
      this.selected_both=[];
    }
}
onDropDownClose(item:any) {
  
  if (this.senderList.length>1) {
   
    }
}
onFilterChange(item: any) {
  console.log(item);
}
closeDropdown(item){
 
  if(this.dropdownSettings['defaultOpen'] = false){}
  this.dropdownSettings['defaultOpen'] = false;
  
  // if (this.senderList.length>1) {
  //   const element = document.getElementsByClassName("dropdown-list");
  //   for(var e = 0; e < element.length; e++){
  //     var elt = element[e];
  //     elt.setAttribute("hidden", "");
     
  //   }
  //   const elementClass = document.querySelector(".dropdown-multiselect--active");

  //   elementClass?.classList.remove("dropdown-multiselect--active");
  // }
}
onmouseleave(){

}
blur(){
  
}
closeDropDownOnSelection(){
 
}
      //select radio recipient button
      selectedButtonRep: string="Insider";        

      radioSelectRep(e: string): void {
          this.selectedButtonRep = e;  
          if(this.selectedButtonRep == "Insider"){
            this.selectedRecipentDpItems = [];
            this.selectedRecipentbothItems = []
          }else if(this.selectedButtonRep == "DP"){
            this.selectedRecipentInsiderItems = [];
            this.selectedRecipentbothItems = []
          }else{
            this.selectedRecipentInsiderItems = [];
            this.selectedRecipentDpItems = [];
          }
      }  
      
      isSelectedRep(name: string): boolean {  
      
          if (!this.selectedButtonRep) {   
              return false;  
          }    
      
          return (this.selectedButtonRep === name); 
      } 
  //select radio recipient button


}
