import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsiderServiceService } from '../insider-service.service';

@Component({
  selector: 'app-insider-view',
  templateUrl: './insider-view.component.html',
  styleUrls: ['./insider-view.component.scss']
})
export class InsiderViewComponent implements OnInit {
  insiderId: any;
  viewInsiderList: any;

  constructor(private activateRouter: ActivatedRoute, private insiderService:InsiderServiceService, public router: Router) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data => {
      this.insiderId = {'id': data['id']}
    })
    if (this.insiderId !== '') {
      this.insiderService.viewInsider(this.insiderId).subscribe(
        data =>{
        this.viewInsiderList = data;
       
      })

      }
  }

}
