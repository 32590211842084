<div #popup class="model-popup">
<div class="modal-header">
    <h5 class="modal-title">Label</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
 
      <div class="row">
        <div class="col-md-12 mb-3">
            <form action="" class="uk-form-horizontal needs-validation" [formGroup]="dbLabelCreateForm" (ngSubmit)="ngSubmit()">
                <label for="label name">Label Name</label>
                <div class="form-group">
                  <input type = "text" id ="" formControlName="dp_labelname" placeholder="Label" name = "txtTodo" [(ngModel)] = "tasks" class="form-control" autocomplete="off"
                  [ngClass]="{ 'is-invalid': (submitted || dbLabelCreateForm.controls.dp_labelname.touched) && dbLabelCreateForm.controls.dp_labelname.errors }">
                  <div *ngIf="(submitted || dbLabelCreateForm.controls.dp_labelname.touched) && dbLabelCreateForm.controls.dp_labelname.errors?.required"
                      class="text text-danger">
                     Label Name is required.
                  </div>
                </div>
                <button class="btn btn-primary btn-block" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
              </form>
        </div>
        <!-- <div class="col-md-6 offset-md-1">
             <form action="" class="uk-form-horizontal">
                <div class="form-group">
                  <input type = "text" id ="" name = "txtTodo" [(ngModel)] = "tasks" class="form-control">
                </div>
                <button class="btn btn-primary btn-block" (click)="addTask()" >Add Task</button>
              </form>
          
        </div> -->
        <!-- <div class="col-md-12 offsaet-md-1">
          <h4>Total Task ({{ countTask }})</h4>
          <ol>
           
            <li *ngFor="let t of TaskList; let i = index"> {{ t }}<a href="#" (click) = "removeTask(i)">X</a></li>
          </ol>
        </div> -->
      </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>

</div>