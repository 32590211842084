import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../master.service';
import { ViewMailTemplateComponent } from '../view-mail-template/view-mail-template.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteMailTempateComponent } from '../delete-mail-tempate/delete-mail-tempate.component';

@Component({
  selector: 'app-list-mail-template',
  templateUrl: './list-mail-template.component.html',
  styleUrls: ['./list-mail-template.component.scss']
})
export class ListMailTemplateComponent implements OnInit {
  listMailTemplate: any;
  filtered: any;
  page = 1;
  pageSize = 10;
  filterString = "";
  constructor(private masterService: MasterService, private modalService: NgbModal) { }

  ngOnInit(): void {

    this.masterService.mailTemplateList(null).subscribe(
      res => {
     
      this.listMailTemplate = res;

      this.filtered = this.listMailTemplate?.data;
        },
        err => {
            console.log(err);
        }
    );
  }
//Filter
onFilterChange() {
  this.filtered = this.listMailTemplate.data?.filter(res =>{    
   return res.subject.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.templatename.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
  }) 
 
}

isMatch(item) {
  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString) > -1
  }
}
  openModal(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewMailTemplateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
  openModalDelete(id:any) {
    if (id) {
      let mailId = {'id' : id};
      this.masterService.mailTemplateView(mailId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeleteMailTempateComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewMailTemplateList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}

}
