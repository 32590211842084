import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {insiderRoutingModule} from '../insider/insider-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InsiderAddComponent } from './insider-add/insider-add.component';
import { InsiderEditComponent } from './insider-edit/insider-edit.component';
import { InsiderListComponent } from './insider-list/insider-list.component';
import { InsiderViewComponent } from './insider-view/insider-view.component';
import { InsiderDeleteComponent } from './insider-delete/insider-delete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { PopupModelInsiderComponent } from './popup-model-insider/popup-model-insider.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BulkActionPopupComponent } from './bulk-action-popup/bulk-action-popup.component';
import { FileDirective } from './bulk-action-popup/file.directive';
import { FileDropDirective } from './bulk-action-popup/file-drop.directive';
import { DeletedInsiderModuleComponent } from './deleted-insider-module/deleted-insider-module.component';
import { RevertInsiderComponent } from './revert-insider/revert-insider.component';
import { ErrorInsiderComponentComponent } from './error-insider-component/error-insider-component.component';
@NgModule({
  declarations: [
    InsiderAddComponent,
    InsiderEditComponent,
    InsiderListComponent,
    InsiderViewComponent,
    InsiderDeleteComponent,
    PopupModelInsiderComponent,
    BulkActionPopupComponent,
    FileDirective,
    FileDropDirective,
    DeletedInsiderModuleComponent,
    RevertInsiderComponent,
    ErrorInsiderComponentComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    insiderRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule
  ],
  entryComponents:[
    PopupModelInsiderComponent
  ],
})
export class InsiderModule { }
