<div class="page-wrapper" *ngIf="hideForm">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/sdd-insidder-login.png" alt=""></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>Reset Your Password</h4>
						<p>Please enter your InSiDDer Username email ID.</p>
					</div>
					<form class="theme-form" [formGroup]="emailVerifiedForm" *ngIf="!newUser" (ngSubmit)="emailVerified()">
						<div class="form-group">
							<label class="col-form-label pt-0">Email ID</label>
							<input class="form-control" formControlName="username" type="email" required="" placeholder="example@gmail.com">
							<div *ngIf="emailVerifiedForm.controls.username.touched && emailVerifiedForm.controls.username.errors?.required"
								class="text text-danger mt-1">
								Email is required
							</div>
							<!-- <div *ngIf="emailVerifiedForm.controls.username.touched && emailVerifiedForm.controls.username.errors?.email"
								class="text text-danger mt-1">
								Invalid email
							</div> -->
						</div>
						
						
						<div class="form-group row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg" [disabled]="isLoading"								
								[disabled]="!emailVerifiedForm.valid" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading}"></i> {{button}}
							</button>
						</div>
						
					</form>
					<a routerLink="/auth/login"><p class="text-center" style="padding: 2rem 0 1rem;	font-size: 1rem;">Back to Login</p></a>
				</div>
				
			</div>
		</div>
	</div>
</div>

<div class="container-fluid" *ngIf="emptyMessage">
	<div class="row content-details" *ngIf="forgetPasswordLink" >
		<p>Reset Link Successfully Sent to your email.<br>Please check your inbox or spam folder. <a routerLink="/auth/login">Click Back to Login...</a></p>
		<!-- <p>Successfully link sent via email, Please check your inbox<br> or spam section. <a routerLink="/auth/login">Click Back to Home...</a></p> -->
		<!-- <a href="{{forgetPasswordLink.data}}">Successfully Created a link.<br> Click Here ...</a> -->
		<!-- <a href="http://sdd.legalitygoal.com/auth/change-password?authToken={{authGuard}}">Click Here</a> -->
		<!-- <div *ngIf="forgetPasswordLink.success == 1">
		<p >Link has been Successfully Created.<br> <a href="{{forgetPasswordLink.data}}"> Click Here ...</a></p>
	</div>
	<div *ngIf="forgetPasswordLink.success == 0 ">
		<p>Email Id is Wrong. Check Email ID  ...</p>
	</div> -->
	</div>
</div>