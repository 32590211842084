import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { PitComplianceService } from '../../pit-compliance.service'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/master/company/company.service';
import { MasterService } from 'src/app/master/master.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-duplicate-preclearance',
  templateUrl: './duplicate-preclearance.component.html',
  styleUrls: ['./duplicate-preclearance.component.scss']
})
export class DuplicatePreclearanceComponent implements OnInit {
  @ViewChild('sQty1') sQty1!: ElementRef;
  @ViewChild('sQty2') sQty2!: ElementRef;
  @ViewChild('sQty3') sQty3!: ElementRef;
  @ViewChild('excute') excute!: ElementRef;
  @ViewChild('dpname') dpname!: ElementRef;
  pitClearanceduplicateForm: FormGroup;
  //checkboxForm: FormGroup;
  quantity: any;
  percentage: number;
  dpNameFilter = [];
  immediateRelativeList = [];
  selectedimmediateRelativeList = [];
  selectedDpName = [];
  listDpModule: any;
  dpnamelist = [];
  listDpArray = [];
  isLoading = false;
  button = 'Save';
  isLoading2 = false;
  button2 = 'Add Previous Transaction';
  submitted:boolean = false;
  selected_receiver= [];
  dropdownSettings = {};
  recipientList = [];
  selectedRecipientItems = [];
  listDpEmail = [];
  closeResult = '';
  yesTransactionField = false;
  noTransactionField = false;
  listDpArrayItem: any;
  panItem: any;
  dpDesignation: any;
  dpdatalist:any;
  immediatePan: any;
  immediateRelative: any;
  accessUserControl: string;
  natureTransaction: any;
  dateransaction: any;
  noOfSecurities: any;
  addpreviousbtn:boolean;
  PcEditId: any;
  pcId: any;
  date_proposed: any;
  complianceOfficerEmail: any;
  complianceOfficerName: any;
  complianceOfficerDesignation: any;
  companyName: any;
  sharesConditions = [];
  valuesConditions = [];
  lastTwc: any;
  showPreclearance = true;
  hidePreclearance = false;
  currentDateTime: string;
  hideDialogBox = true;
  showDuplicateData = false;
  items: any;
  dateoftransaction: any;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private pitService: PitComplianceService,
    private dpService:DpModuleService, private modalService: NgbModal, private activateRouter:ActivatedRoute, private companyService: CompanyService,
    private masterService: MasterService, public datepipe: DatePipe) {
      this.accessUserControl = sessionStorage.getItem('access_user');
      this.currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd');
      
     }


//Annual Confiremation
createDetailOfTrade(){
  return this.fb.group({
    date_of_trade: new FormControl(''),
    type_of_trade: new FormControl(''),
    shared_traded: new FormControl(''),
  })
}
addInputControlsAnnual(){
  // this.procedureCont = true;
  const add = this.pitClearanceduplicateForm.get('detailOfTrade') as FormArray;
  add.push(this.createDetailOfTrade())  
 
}
removeInputControlsAnnual(idx: number){
  const add = this.pitClearanceduplicateForm.get('detailOfTrade') as FormArray;  
  add.removeAt(idx);   
}
private dateRangeValidator: ValidatorFn = (): {
  [key: string]: any;
} | null => {
  let invalid = false;
  const from = this.pitClearanceduplicateForm && this.pitClearanceduplicateForm.get("from_Date").value;
  const to = this.pitClearanceduplicateForm && this.pitClearanceduplicateForm.get("to_Date").value;

  if (from && to) {
    invalid = new Date(from).valueOf() > new Date(to).valueOf();
   
  }
  return invalid ? { invalidRange: { from, to } } : null;
};
  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.pcId = data['id']      
    })
    if(this.pcId !==''){
      this.pitService.preClearanceView(this.pcId)
      .toPromise().then(result =>{ 
        this.PcEditId = result.data[0].id;
        this.selectedDpName = result.data[0].dpname;
        this.selectedButton = result.data[0].application_for;
        if(this.selectedButton =='Relative'){
         
        this.selectedimmediateRelativeList = result.data[0].relativename;
        this.immediateRelative = result.data[0].dpRelationship;
        this.immediatePan = result.data[0].relativepan;
        }
        this.items = result.data[0].dpname;
        this.panItem = result.data[0].dppan;
        this.dpDesignation = result.data[0].dpdesignation;
        this.dateransaction = result.data[0].date_of_transaction;
        //this.date_proposed = result.data[0].proposeddate;
        const transaction_executed = result.data[0].transaction_executed;
        this.pitClearanceduplicateForm?.patchValue(result.data[0]); 
        this.pitClearanceduplicateForm?.get('checkboxgroup').patchValue(JSON.parse(result.data[0].checkboxgroup)); 
       if(transaction_executed != ''){
          if(transaction_executed == 'Yes'){
            this.yesTransactionField = true;
            this.noTransactionField = false;
          }else{
            this.yesTransactionField = false;
            this.noTransactionField = true;
          }
      }
      this.changeFn(result.data[0].dpname);
      this.radioSelect(result.data[0].application_for);
      })
      this.pitClearanceduplicateForm = this.fb.group({
        dpName: new FormControl ('', Validators.required),
        dppan: new FormControl ('', Validators.required),
        dpdesignation: new FormControl ('', Validators.required),     
        application_for: new FormControl ('Self'),
        relativename: new FormControl (''),
        dpRelationship: new FormControl (''),
        relativepan: new FormControl (''),
        dp_client_id: new FormControl ('', Validators.required),
        nameofdepository: new FormControl ('', Validators.required),
        noofsecurities: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        typeofsecurity: new FormControl ('', Validators.required),
        proposeddate: new FormControl ('', Validators.required),
        nature_of_proposed: new FormControl ('', Validators.required),
        Estimatednumbersecur: new FormControl ('',[Validators.required, Validators.pattern("^[0-9]*$")]),
        currentmarketprice: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        transactionisproposed: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        whethertransactionisproposed: new FormControl ('', Validators.required),
        proposedbuyer: new FormControl ('', Validators.required),
        last_transaction: new FormControl ('', Validators.required),
        nature_of_transaction: new FormControl ('', Validators.required),
        date_of_transaction: new FormControl ('', Validators.required),
        securities_transacted: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        number_of_shares: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        Date_when_approval: new FormControl ('', Validators.required),
        transaction_executed: new FormControl ('', Validators.required),
        details_of_yes: new FormControl (''),
        details_of_no: new FormControl (''),
        checkboxgroup : this.fb.group({
        checkbox: [false, Validators.required],
        checkbox1: [false, Validators.required],
        checkbox2: [false, Validators.required],
        checkbox3: [false, Validators.required],
        checkbox4: [false, Validators.required],
        checkbox5: [false, Validators.required],
        checkbox6: [false, Validators.required],
        checkbox7: [false, Validators.required],
        checkbox8: [false, Validators.required],
      })
    });
  }
  this.relativeBtn();
    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      this.listDpModule = res['data'];  
      
       this.listDpModule.map(item => {
        if(item.email == this.accessUserControl){
          this.listDpArray.push( item.dpName + ' - ' + item.email)
        }
      })
      this.dpNameFilter = [];
     
      from(this.listDpArray)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dpNameFilter.push(r));
    
    //Get email
    this.listDpModule.map((role,email) => {
     
     
       if(role.role == 'Admin'){
        this.listDpEmail.push({item_id:role.id, item_text:role.email});
       
       }
    
      
    })
    
    this.recipientList = [];
    from(this.listDpEmail)
    .pipe(
    tap(item => item),
    distinct(d => d.item_text)
    )
    .subscribe(r => this.recipientList.push(r));
        
        },
        err => {
            console.log(err);
        }
    );
    
    //this.relativeBtn();
    
    // this.selectedDpName = [this.accessUserControl]
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      defaultOpen: false,
    };
    
    //previous trading
    this.pitService.listPreviousPreClear(null).subscribe(
      res => {
            const listPreviousPreClear = res['data'];
             if(listPreviousPreClear.length > 0){
              this.addpreviousbtn = false;
              this.natureTransaction = listPreviousPreClear[0].bought_sold_subscribed;
             this.dateransaction = listPreviousPreClear[0].to_date;
             this.noOfSecurities = listPreviousPreClear[0].no_of_securirities;
             }else{
              this.addpreviousbtn = true;
             }
             
      });
      this.masterService.listCompanyRules(null).subscribe(
        res => {
          this.sharesConditions = [];
          this.valuesConditions = [];
        let listCompanyRules = res.data;
          if(listCompanyRules[0]?.rule_status){
          listCompanyRules[0]?.rules_model.map(item =>{
           
            if(item.rules_condition == "Share"){
              this.sharesConditions.push(item);
             
            }else{
              this.valuesConditions.push(item);        
            }    
           
          })
        }else{
          this.sharesConditions = [];
          this.valuesConditions = [];
        }
          },
          err => {
              console.log(err);
          }
      );   
  //company details
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
    }
    },
    err => {
        console.log(err);
    }
);  

//twc
this.pitService.tradingWindowClosureList(null).subscribe(
  res => {
    let tradingWindowList = res;
    this.lastTwc = tradingWindowList?.data[0]?.end_date;  
    if(this.lastTwc < this.currentDateTime){
      this.hidePreclearance = true;
      this.showPreclearance = false;
    }else{
      this.showPreclearance = true;
      this.hidePreclearance = false;
    }
    
  });
}
changeFn(val) {
  this.items = val?.split('- ');
  
  this.dpService.dpModuleList(null).subscribe(
    res => {
      
    this.listDpModule = res['data'];  
    
    this.listDpArrayItem = this.listDpModule.map((data) => {
     
      if(this.items != undefined){
        if(data.email == this.items[1]){
            this.dpdatalist = data;             
            this.panItem = this.dpdatalist.dpPanNo;
            this.dpDesignation = this.dpdatalist.dpDesignation;
             
        }
      }else{
        this.panItem = '';
        this.dpDesignation = '';        
        this.immediateRelativeList = [];
        this.selectedimmediateRelativeList = [];
        this.immediatePan = "";
        this.immediateRelative = "";
      }
    })
  
    
      
      },
      err => {
          console.log(err);
      }
  );
 
}

// onRemove(item){
//   this.selectedDpName.splice(this.selectedDpName.indexOf(item), 1);
//   this.dpNameFilter = this.listDpArray;
// }
//select radio recipient button
// Multi Select
onItemSelect(item: any) {
this.selected_receiver.push(item);  
}
onItemDeSelect(item: any) {
this.selected_receiver.splice(this.selected_receiver.indexOf(item), 1);
}
onSelectAll(items: any) {
 this.selected_receiver=items; 
}
onDeSelectAll(items: any) {
this.selected_receiver=[];   
}
cancelPreClearanceTemplate(){
  this.router.navigate(['/pit-compliance/list-pre-clearance']);
  this.pitClearanceduplicateForm.reset();
  this.submitted = false;
}
addPreviousPreClear(){
  this.submitted = false;
  this.isLoading2 = true;
  this.button2 = 'Processing';
  this.pitClearanceduplicateForm.value.insertStatus = "Incompleted"
    this.pitService.preClearanceCreate(this.pitClearanceduplicateForm.value).subscribe(data =>{   
      this.isLoading = false;
      this.button = 'Update';
      this.isLoading2 = false;
      this.button2 = 'Add Previous Transaction';
      this.router.navigate(['pit-compliance/add-previous-transacrion']);
  });
}
duplicateUpsi(){
  this.hideDialogBox = false;
  this.showDuplicateData = true;
}
closeModal(){
  this.router.navigate(['/pit-compliance/list-pre-clearance']);
  this.hideDialogBox = true;
  this.showDuplicateData = false;
}
  onSubmit(){
    this.pitClearanceduplicateForm.value.complianceofficeremail =this.complianceOfficerEmail;
    this.pitClearanceduplicateForm.value.complianceofficername =this.complianceOfficerName;
    this.pitClearanceduplicateForm.value.complianceofficerdesignation =this.complianceOfficerDesignation;
    this.pitClearanceduplicateForm.value.companyname =this.companyName;
    this.pitClearanceduplicateForm.value.id = this.PcEditId;

    if(this.pitClearanceduplicateForm.get('Estimatednumbersecur').value != ''){
      if(this.sharesConditions.length > 0){
        for(let i=0; i<this.sharesConditions.length; i++){            
              if(this.sharesConditions[i].rules_setting == "Greater than" && this.sharesConditions[i].rules_value > this.pitClearanceduplicateForm.get('Estimatednumbersecur').value){
                this.toastr.warning("Your Shares Less than Company Rules. Please Check company allotment shares.");
                 return false;
              }else if(this.sharesConditions[i].rules_setting == "Less than" && this.sharesConditions[i].rules_value < this.pitClearanceduplicateForm.get('Estimatednumbersecur').value ){
                this.toastr.warning("Your Shares Greater than Company Rules. Please Check company allotment shares.");
               return false;
              }else if(this.sharesConditions[i].rules_setting == "Equal to" && this.sharesConditions[i].rules_value != this.pitClearanceduplicateForm.get('Estimatednumbersecur').value ){
                this.toastr.warning("Your Shares Not Equal to Company Rules. Please Check company allotment shares.");
               return false;
              } else if(this.sharesConditions[i].rules_setting == "Greater than or equal to" && this.sharesConditions[i].rules_value >= this.pitClearanceduplicateForm.get('Estimatednumbersecur').value ){
                this.toastr.warning("Your Shares Less than or equal to Company Rules. Please Check company allotment shares.");
               return false;
              }else if(this.sharesConditions[i].rules_setting == "Less than or equal to" && this.sharesConditions[i].rules_value <= this.pitClearanceduplicateForm.get('Estimatednumbersecur').value ){
                this.toastr.warning("Your Shares Greater than or equal to Company Rules. Please Check company allotment shares.");
                return false;
              }           
            
        }
      }
    }
    
    if(this.pitClearanceduplicateForm.get('transactionisproposed').value != ''){
      if(this.valuesConditions.length > 0){
               for(let i=0; i<this.valuesConditions.length; i++){ 
                 
                   if(this.valuesConditions[i].rules_setting == "Greater than" && this.valuesConditions[i].rules_value > this.pitClearanceduplicateForm.get('transactionisproposed').value){
                     this.toastr.warning("Your Values Less than Company Rules. Please Check company allotment Values.");
                     return false;
                   }else if(this.valuesConditions[i].rules_setting == "Less than" && this.valuesConditions[i].rules_value < this.pitClearanceduplicateForm.get('transactionisproposed').value ){
                    this.toastr.warning("Your Values Greater than Company Rules. Please Check company allotment Values.");
                     return false;
                   }else if(this.valuesConditions[i].rules_setting == "Equal to" && this.valuesConditions[i].rules_value != this.pitClearanceduplicateForm.get('transactionisproposed').value ){
                     this.toastr.warning("Your Values Not Equal to Company Rules. Please Check company allotment Values.");
                    return false;
                   }else if(this.valuesConditions[i].rules_setting == "Greater than or equal to" && this.valuesConditions[i].rules_value >= this.pitClearanceduplicateForm.get('transactionisproposed').value ){
                     this.toastr.warning("Your Values Less than or equal to Company Rules. Please Check company allotment Values.");
                    return false;
                   }else if(this.valuesConditions[i].rules_setting == "Less than or equal to" && this.valuesConditions[i].rules_value <= this.pitClearanceduplicateForm.get('transactionisproposed').value ){
                     this.toastr.warning("Your Values Greater than or equal to Company Rules. Please Check company allotment Values.");
                    return false;
                   }
                                             
               }
       
     }
   }

    this.submitted = true;
    this.pitClearanceduplicateForm.value.insertStatus = "";
    const emptyFileds = Object.keys(this.pitClearanceduplicateForm.controls).filter(key =>this.pitClearanceduplicateForm.controls[key].errors !== null)

    if(this.pitClearanceduplicateForm.status !== 'INVALID'){
    this.isLoading = true;
      this.button = 'Processing';
    this.pitService.preClearanceduplicate(this.pitClearanceduplicateForm.value).subscribe(data =>{  
      this.isLoading = false;
      this.button = 'Save'; 
      this.toastr.success("Pre Clearance details Updated", "Success!");
        this.router.navigate(['pit-compliance/list-pre-clearance']);
     
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    this.isLoading = false;
      this.button = 'Update';
    return false;
  }else{
    if(this.pitClearanceduplicateForm.get('dpName').value == ''){
      this.toastr.warning("Please fill the Name of designated person.");
    }else if(this.pitClearanceduplicateForm.get('dppan').value == ''){
      this.toastr.warning("Please fill the PAN Number.");
    }else if(this.pitClearanceduplicateForm.get('dpdesignation').value == ''){
      this.toastr.warning("Please fill the Designation.");
    }else if(this.pitClearanceduplicateForm.get('dp_client_id').value == ''){
      this.toastr.warning("Please fill the Folio No. / DP ID / Client ID No.");
    }else if(this.pitClearanceduplicateForm.get('nameofdepository').value == ''){
      this.toastr.warning("Please fill the Name of Depository.");
    }else if(this.pitClearanceduplicateForm.get('noofsecurities').value == ''){
      this.toastr.warning("Please fill the Number of securities held as on date.");
    }else if(this.pitClearanceduplicateForm.get('typeofsecurity').value == ''){
      this.toastr.warning("Please fill the Type of Security.");
    }else if(this.pitClearanceduplicateForm.get('proposeddate').value == ''){
      this.toastr.warning("Please fill the Proposed date of dealing in securities.");
    }else if(this.pitClearanceduplicateForm.get('nature_of_proposed').value == ''){
      this.toastr.warning("Please fill the Nature of proposed transaction.");
    }else if(this.pitClearanceduplicateForm.get('Estimatednumbersecur').value == ''){
      this.toastr.warning("Please fill the Estimated number of securities proposed.");
    }else if(this.pitClearanceduplicateForm.get('currentmarketprice').value == ''){
      this.toastr.warning("Please fill the Current market price .");
    }else if(this.pitClearanceduplicateForm.get('transactionisproposed').value == ''){
      this.toastr.warning("Please fill the Price at which transaction is proposed.");
    }else if(this.pitClearanceduplicateForm.get('whethertransactionisproposed').value == ''){
      this.toastr.warning("Please fill the Whether the proposed transaction .");
    }else if(this.pitClearanceduplicateForm.get('proposedbuyer').value == ''){
      this.toastr.warning("Please fill the Name of the Proposed Buyer/ Seller.");
    }else if(this.pitClearanceduplicateForm.get('last_transaction').value == ''){
      this.toastr.warning("Please fill the Details of last transaction.");
    }else if(this.pitClearanceduplicateForm.get('nature_of_transaction').value == ''){
      this.toastr.warning("Please fill the Nature of transaction.");
    }else if(this.pitClearanceduplicateForm.get('date_of_transaction').value == ''){
      this.toastr.warning("Please fill the Date of transaction.");
    }else if(this.pitClearanceduplicateForm.get('securities_transacted').value == ''){
      this.toastr.warning("Please fill the No. of securities transacted.");
    }else if(this.pitClearanceduplicateForm.get('number_of_shares').value == ''){
      this.toastr.warning("Please fill the Number of Shares for which approval was granted.");
    }else if(this.pitClearanceduplicateForm.get('Date_when_approval').value == ''){
      this.toastr.warning("Please fill the Date when the approval was granted.");
    }else if(this.pitClearanceduplicateForm.get('transaction_executed').value == ''){
      this.toastr.warning("Please fill the Whether the transaction was executed or not?.");
    }else if(this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox1').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox2').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox3').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox4').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox5').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox6').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox7').value == false || 
    this.pitClearanceduplicateForm.get('checkboxgroup').get('checkbox8').value == false ){
      this.toastr.warning("Please Accept the Declaration.");
    }
    
    
  }
  }
  onSelectedQty(){
    const quantity1 = this.sQty1.nativeElement.value;
    const quantity2 = this.sQty2.nativeElement.value;
    const quantity3 = this.sQty3.nativeElement.value;
    this.quantity = quantity1 / quantity2 ;
    this.percentage = quantity3 / quantity2 ;
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  changeFnImmediate(val){ 
    let immediateRelative = this.dpdatalist.immediateDpRelative;
    
    if(val != undefined){
        immediateRelative.map(item => {
          
         if(item.name === val){
        
            let immediateArr = item;
            this.immediatePan = immediateArr.pan;
            this.immediateRelative = immediateArr.relationship;
         }
        })
      }else{
        this.immediatePan = '';
        this.immediateRelative = '';
       
      }
  }
//new changes radio btn
selectedButton: string;        

radioSelect(e: string): void {

    this.selectedButton = e;  
    this.relativeBtn();
    // if(this.selectedButton == "Relative"){
    // let dpname = this.pitClearanceduplicateForm.get('dpName')?.value;
   
    //  if(dpname != null || dpname != ''){ 
    //   let immediateRelative = this.dpdatalist?.immediateDpRelative;         
    //   immediateRelative?.map(item => {
    //     this.immediateRelativeList.push(item.name);
    //   })
    // }else{
    //   this.immediateRelativeList = [];
    //   this.selectedimmediateRelativeList = [];
    //   this.immediatePan = "";
    //   this.immediateRelative = "";
    // }
    // }else if(this.selectedButton == "Self"){
      
    //   this.immediateRelativeList = [];
    //   this.selectedimmediateRelativeList = [];
    //   this.immediatePan = "";
    //   this.immediateRelative = "";
    // }
}  

isSelected(name: string): boolean {  

    if (!this.selectedButton) {   
        return false;  
    }    

    return (this.selectedButton === name); 
} 
relativeBtn(){
  let dpname = this.pitClearanceduplicateForm.get('dpName')?.value;
  let dpapplication = this.pitClearanceduplicateForm.get('application_for')?.value;
   
    if(dpname != null){ 

    if(this.selectedButton == "Relative"){
     // let dpname = this.pitClearanceduplicateForm.get('dpName')?.value;
            
        let immediateRelative = this.dpdatalist?.immediateDpRelative;         
        immediateRelative?.map(item => {
          this.immediateRelativeList.push(item.name);
        })
        
      
      }else if(this.selectedButton == "Self"){
        
        this.immediateRelativeList = [];
        this.selectedimmediateRelativeList = [];
        this.immediatePan = "";
        this.immediateRelative = "";
      }
    }else if(dpname == null && this.selectedButton == "Relative"){ 
        
      this.immediateRelativeList = [];
      this.selectedimmediateRelativeList = [];
      this.immediatePan = "";
      this.immediateRelative = "";
    }
}
transactionExcute(){
  const valueExcute = this.excute.nativeElement.value;
  if(valueExcute == 'Yes'){
        this.yesTransactionField = true;
        this.noTransactionField = false;
  }else{
    this.yesTransactionField = false;
    this.noTransactionField = true;
  }
}
}
