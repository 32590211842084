<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Roles & Permissions</h5>
                    <div class="list-btn">  
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
                        <a routerLink="/setup/role-permission-add"><button class="btn btn-primary me-1" >New</button></a>
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="name">S.No.</th>
                                    <th scope="col" sortable="name">Roles</th>
                                    <th scope="col" sortable="name">Projects</th>
                                    <th scope="col" sortable="name">Actions</th>
                                    <th scope="col" sortable="name">Project Access</th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let data of roleData | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="Roles">{{data.role_name}}</td>
                                    <td scope="row" data-content="Projects">{{data.proCountVal}}</td>                                
                                    <td data-content="Actions">
                                        <a routerLink="/setup/role-permission-edit/{{data.id}}"><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>                               
                                     </td>
                                     <td data-content="Project Access">
                                       <button class="btn btn-primary me-1 edit-icon" (click)="openModal(data.id)">Modify</button>                               
                                     </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                        <div class="list-btn2" *ngIf="roleData?.length =='' ">
                            <p>No Data Found</p>
                        </div>

                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="roleData?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->




