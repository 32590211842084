import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { window } from 'rxjs';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-revert-customer',
  templateUrl: './revert-customer.component.html',
  styleUrls: ['./revert-customer.component.scss']
})
export class RevertCustomerComponent implements OnInit {
  @Input() viewCustomerList;
  @Input() title;
  getIdCustomer: any;
  revetCustomerForm:FormGroup;
  getEmailCustomer: any;
  isLoading = false;
  button = 'Restore';
  submitted = false;
  constructor(
    public activeModal: NgbActiveModal,private router:Router, private fb: FormBuilder, private toastr: ToastrService, private activateRouter: ActivatedRoute, private customerService: CustomerService,
  ) { }

  ngOnInit() {
    
    this.revetCustomerForm = this.fb.group({
      id: new FormControl(''),
      email: new FormControl(''),
      reasonforrestore: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])
    });
   this.getIdCustomer = this.viewCustomerList.id;
   this.getEmailCustomer = this.viewCustomerList.email;

  }
  revertCustomerId(){
    this.submitted = true;
    this.revetCustomerForm.value.id = this.getIdCustomer;
    this.revetCustomerForm.value.email = this.getEmailCustomer;
    if(this.revetCustomerForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.customerService.revertCustomer(this.revetCustomerForm.value).subscribe(data =>{
        if(data.success == 2){
          this.toastr.warning(data.message);
          this.isLoading = false;
          this.button = 'Restore';
          this.activeModal.close(event);

        }else{
        this.toastr.success("Customer Successfully Restored");
        this.isLoading = false;
          this.button = 'Restore';
        this.activeModal.close(event);
        this.router.navigate(['/customer/list-customer']);
        }
      });
    }else{
      if(this.revetCustomerForm.get('reasonforrestore').value == ''){
        this.toastr.warning("Please Mention the Reason for Restore DP.");
      }
    }

  }
  cancelRevertData(){
    this.activeModal.close(event);
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }


}
