<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
<!--       
        <div class="card bp-list">
            <div class="card-header">
                <h5>Download Help Manual</h5>               
                      <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
            </div>
          <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" sortable="s.no" >S.No</th>
                            <th scope="col" sortable="dpName" >List</th>
                            <th scope="col" sortable="pan" >Action</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td scope="row">1</td>
                            <td scope="row">Help Manual</td>                    
                            <td scope="row"><a href="/assets/help-manual/HELP MANUAL INSIDDER PREMIUM.pptx" target="_blank" title="Download Help Manual"><img src="/assets/images/ppt.png" class="pdf-icon" style="cursor: pointer;">   
                            </a></td>
                                            
                            
                        </tr>
                        </tbody>
                </table>
              

          
            </div>
        </div> -->

        <div class="card bp-list">
            <div class="card-header">
                <h5>Contact Us</h5> 
                <div class="btn-head">
                    <!-- <a href="/assets/help-manual/HELP MANUAL INSIDDER PREMIUM.pptx" target="_blank" title="Download Help Manual"><img src="/assets/images/ppt.png" class="pdf-icon" style="cursor: pointer;"></a> -->
                    <a href="javascript:void(0)" target="_blank" title="Download Help Manual"><img src="/assets/images/pdf-icon.png" class="pdf-icon" style="cursor: pointer;"></a>

                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>  
            </div>
          <div class="card-body contact">
               <p class="heading">For further assistance, help or other queries related to the InSiDDer Premium Application, please reach out to our support team at</p>
               <p><i class="fa fa-envelope-o" aria-hidden="true"></i><a href="mailto:sdd@legalitysimplified.com"> sdd@legalitysimplified.com</a></p>
               <p><i class="fa fa-phone" aria-hidden="true"></i>+917539901222 / +917539908222 / +917539909222</p>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

