import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { CustomerService } from 'src/app/customer/customer.service';
import { CompanyService } from '../../company/company.service';

@Component({
  selector: 'app-add-restricted-company',
  templateUrl: './add-restricted-company.component.html',
  styleUrls: ['./add-restricted-company.component.scss']
})
export class AddRestrictedCompanyComponent implements OnInit {
  @Input() title;
  addRestrictedCompanyform: FormGroup;
  dropdownSettings = {};
  selected_restrict_company = [];
  restrict_company = [];
  button = 'Save';
  isLoading = false;
  submitted = false;
  constructor(private customerService: CustomerService, public activeModal: NgbActiveModal,private fb: FormBuilder,private masterService: MasterService, public router: Router, private toastr: ToastrService, private activateRouter:ActivatedRoute,
    ) { }

  ngOnInit(): void {

    this.addRestrictedCompanyform = this.fb.group({      
      companyname : new FormControl('', Validators.required),
      permission : new FormControl('', Validators.required)
    });

    this.selected_restrict_company = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select the Restricted Company Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
    this.customerService.customerList(null).subscribe(
      res => {
        let dropdownListcustomerlist = [];
          
        if(res.message == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          let listCustomer = res.data
          //name
          let customerName = listCustomer.map(({id,company_name}) => ({id,itemName: company_name}));
          customerName.forEach(result =>dropdownListcustomerlist.push(result))
          this.restrict_company = [];
          from(dropdownListcustomerlist)
          .pipe(
          tap(item => item),
          distinct(d => d.itemName)
          )
          .subscribe(r => this.restrict_company.push(r));    

        }
      },
      err => {
        console.log(err);
      }
    );
  }

 //Multi select box
 onItemSelect(item: any) {
  console.log(item);
 }
OnItemDeSelect(item: any) {
  console.log(item);
}
onSelectAll(items: any) {
  console.log(items);
}
onDeSelectAll(items: any) {
  console.log(items);
}

onSubmit(){
  this.submitted = true;
  const emptyFileds = Object.keys(this.addRestrictedCompanyform.controls).filter(key =>this.addRestrictedCompanyform.controls[key].errors !== null)

  if(this.addRestrictedCompanyform.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';
    this.masterService.addPermissionTradingCompany(this.addRestrictedCompanyform.value).subscribe(data =>{
      this.toastr.success("Permission of Trading for Company Successfully created");
      this.isLoading = false;
      this.button = 'Save';
      window.location.reload();
    
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    return false;
  }else{
    if(this.addRestrictedCompanyform.get('companyname').value == ''){
      this.toastr.warning("Please fill the Company Name.");
    }
    if(this.addRestrictedCompanyform.get('permission').value == ''){
      this.toastr.warning("Please fill the Permission of Trading.");
    } 
  }
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
