
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCJi0cQ866lzPR8hYJUs77JUmD4DBpswl8",
    authDomain: "angular-deomo.firebaseapp.com",
    databaseURL: "https://angular-deomo.firebaseio.com",
    projectId: "angular-deomo",
    storageBucket: "angular-deomo.appspot.com",
    messagingSenderId: "175907956497"
  },
   
  mailPopupModel: "BUILD",
  headerLogoPath: "lsa-100x100.jpg",
  sideMenuLogoPath: "lsa-200x200.jpg",
  href :"https://sdd.legalitygoal.com",
  mail: "lsinsidder@gmail.com",
  companyName: "Legality Simplified",
  url:"https://sdd.legalitygoal.com",
  apiUrl: "https://sdd.legalitygoal.com/apisdd/public/api/"
   //apiUrl: "http://localhost:5039/public/index.php/api/"

 

};
