<div class="modal-header">
    <h5 class="modal-title">VIEW SYSTEM INFO DETAILS</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body custom-datatable noscroll">
                        <div class="custom-datatable">
                            <table class="table">                            
                                    <tbody *ngIf="viewsystemInfoHistory">
                                        <tr>
                                            <td scope="row">User Name</td>
                                            <td scope="row">{{viewsystemInfoHistory.username}}</td>                                         
                                         </tr> 
                                        <tr>
                                            <td scope="row">Access Info</td>
                                            <td scope="row">{{viewsystemInfoHistory.access_info}}</td>                                         
                                         </tr> 
                                        <tr>
                                            <td scope="row">Action</td>
                                            <td scope="row">{{viewsystemInfoHistory.action}}</td>                                         
                                         </tr> 
                                        <tr>
                                            <td scope="row">Component</td>
                                            <td scope="row">{{viewsystemInfoHistory.component}}</td>                                         
                                         </tr> 
                                        <tr>
                                            <td scope="row">Status</td>
                                            <td scope="row">{{viewsystemInfoHistory.status}}</td>                                         
                                         </tr> 
                                         <tr>
                                            <td scope="row">Activity On</td>
                                            <td scope="row">{{viewsystemInfoHistory.timestamplog | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                         </tr>                                                            
                                         <tr *ngIf="viewsystemInfoHistory.reason != ''">
                                            <td scope="row">Reason for Change</td>
                                            <td scope="row">{{viewsystemInfoHistory.reason}}</td>                                         
                                         </tr>                     
                                         <tr *ngIf="viewsystemInfoHistory.changedetails != ''">
                                            <td scope="row">Change Details</td>
                                            <td scope="row">{{viewsystemInfoHistory.changedetails}}</td>                                         
                                         </tr>                     
                                   
                                </tbody>
                            </table>

                             
                            </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
