

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">                             
                  <a routerLink="/project/activity-log"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewDpList">
                                    <tr>
                                        <td scope="row">Action Flag</td>
                                        <td scope="row">{{viewDpList.action_flag}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Created By</td>
                                        <td scope="row">{{viewDpList.created_by}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Project Name</td>
                                        <td scope="row">{{viewDpList.project_name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewDpList.purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     
                            </tbody>
                        </table>
                       
                </div>
            </div>
        </div>
    </div>
 </div> -->

 <div class="modal-header">
    <h5 class="modal-title">View DP Change log</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body custom-datatable noscroll">
                        <div class="custom-datatable">
                            <table class="table">                            
                                    <tbody *ngIf="viewDpList">
                                        <tr>
                                            <td scope="row">Record</td>
                                            <td scope="row">{{viewDpList.Record}}</td>                                         
                                         </tr>
                                        <tr>
                                            <td scope="row">Activity</td>
                                            <td scope="row">{{viewDpList.Activity}}</td>                                         
                                         </tr> 
                                         <tr>
                                            <td scope="row">DP Name</td>
                                            <td scope="row">{{viewDpList.dpName}}</td>                                         
                                         </tr> 
                                         <tr >
                                            <td scope="row">Action By (Username)</td>
                                            <td scope="row">{{viewDpList.ActionBy}} </td>                                         
                                         </tr>                                          
                                         <tr>
                                            <td scope="row">Action Date</td>
                                            <td scope="row">{{viewDpList.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Reason for Change</td>
                                            <td scope="row">{{viewDpList.reason}}</td>                                         
                                         </tr> 
                                         <tr>
                                            <td scope="row">Change Details</td>
                                            <td scope="row">{{viewDpList.changedetails}}</td>                                         
                                         </tr> 
                                        
                                         
                                </tbody> 
                            </table>
                           
                    </div>
                </div>
            </div>
        </div>
     </div>
<div class="modal-footer">
    <!-- <div class="text-muted">
        <b>Note:</b> This section only respresent audit trail information.<br><br>
        <b>For Complete Details of the entry</b><br> Please navigate to respective section in the software and view the full details (<i class="fa fa-eye" ></i>).
    </div> -->
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>