import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/report/report.service';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import { DpModuleService } from '../dp-module.service';
import { ErrorDpComponentComponent } from '../error-dp-component/error-dp-component.component';
import * as moment from 'moment';
import { saveAs } from "file-saver/dist/FileSaver";
import FileSaver from "file-saver";
type AOA = any[][];

@Component({
  selector: 'app-bulk-action-popupdp',
  templateUrl: './bulk-action-popupdp.component.html',
  styleUrls: ['./bulk-action-popupdp.component.scss']
})
export class BulkActionPopupdpComponent implements OnInit {
  button = 'Upload';
  isLoading = false;
  title = "FileUp";
  form: FormGroup;
  @Input() name;
  private stepper: Stepper;
  filePathName: string;
  fileData: any;
  excelFile: any;
  excelData: any;
  responseDate: any;

  next() {
    this.stepper.next();
  }
  constructor(
    public activeModal: NgbActiveModal, private toastr: ToastrService, private builder: FormBuilder, private router:Router, private reportService: ReportService, private dpService:DpModuleService,
    private modalService: NgbModal
  ) { 
    this.form = this.builder.group({
      files: ['']
    });
  }
  

  ngOnInit() {
    
    
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
   
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
//read excel
getMenuItem(){  
  const pdfUrl ="/assets/help-manual/InSiDDer Premium Help Manual.pdf";
    const pdfName = "InSiDDer Premium Help Manual.pdf";
    FileSaver.saveAs(pdfUrl, pdfName);
  }

data: AOA = [];
wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
fileName: string = 'SheetJS.xlsx';
submitData() {
  this.isLoading = true;
  this.button = 'Processing';
  let reqData: any = {excel: this.excelData};
  if(this.fileData) {
    reqData.file = this.fileData;
  }
  this.dpService.sendJSONToServerDp(reqData).subscribe(
    res => {
      // console.log(res);
      this.closeModal(event);
      //this.router.navigate(['/dp/dp-list']);
      this.responseDate = res.body;
      // console.log("responseDate", this.responseDate)
      if(this.responseDate.duplicates == ''){
        // window.alert(this.responseDate.message + ". " + "Please Refresh the page")  
        this.toastr.success("Dp Successfully Updated");     
        window.location.reload();
        this.isLoading = false;
        this.button = 'Upload';
       }else{
        //this.toastr.error(this.responseDate.message)
        const modalRef = this.modalService.open(ErrorDpComponentComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewDpErrorList = this.responseDate;
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
        this.isLoading = false;
        this.button = 'Upload';
      }
      //else  if(this.responseDate.success == '2'){
      //   this.toastr.warning(this.responseDate.message + ". " + "Please check your Email!")    
      //   this.isLoading = false;
      //   this.button = 'Upload';
      // } 
      

    },
    err => {
      console.log(err);
    }
  )
}

onFileChange(evt: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(evt.target);
  if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    /* read workbook */
    
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    this.data =  <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, dateNF: "yyyy-mm-dd" }));

    let reqArr = [];
    if(this.data.length > 1) {
      let keys = this.data[0];
      for(let i=1;i< this.data.length;i++) {
        let reqObj = {};
        this.data[i].map((valStr, index) => {
          // if(keys[index] === 'Confidentiality_Agreement_Date') {
          //   valStr = moment(valStr, 'yyyy-MM-DD');
          //   // console.log(valStr.format(`yyyy-MM-DD hh:mm`));
          //   valStr = valStr.format(`yyyy-MM-DD`);
          //   this.data[i][index] = valStr;
          // }else 
          if(keys[index] === 'DP_Name'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'DP_Address'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'ID_Proof_No'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Educational_Institute_Name'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Past_Employment'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Remarks'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'DP_Designation'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }
          
          reqObj[keys[index]] = valStr});
        
        reqArr.push(reqObj);
      }
      
    }
    this.excelData = reqArr;
    // console.log(JSON.stringify(reqArr));
  };
  reader.readAsBinaryString(target.files[0]);
  this.next();
}


//drag and drop
saveFile(files: FileList) {
  this.filePathName = files[0].name;
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    const bstr: string = e.target.result;
    this.fileData = bstr;
  };
  reader.readAsBinaryString(files[0]);
}

export1(): void {
  /* generate worksheet */
  let anchorTag = document.createElement("a");
  anchorTag.setAttribute('type', 'hidden');
  anchorTag.href = "/assets/excel/dpdetails.xlsx";
  anchorTag.download = "dpdetails.xlsx";
  anchorTag.click();
  anchorTag.remove();
  // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.excelFile);

  // /* generate workbook and add the worksheet */
  // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // /* save to file */
  // XLSX.writeFile(wb, this.fileName);
}
export2(): void {
  /* generate worksheet */
  let anchorTag = document.createElement("a");
  anchorTag.setAttribute('type', 'hidden');
  anchorTag.href = "/assets/excel/immediaterelativedp.xlsx";
  anchorTag.download = "immediaterelativedp.xlsx";
  anchorTag.click();
  anchorTag.remove();
  // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.excelFile);

  // /* generate workbook and add the worksheet */
  // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // /* save to file */
  // XLSX.writeFile(wb, this.fileName);
}
export(){
  this.export1();
  // this.onFilter2();
  var self = this;
   setTimeout(function(){
    self.export2();
  },3000);
}
}
