import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-non-upsi-activity-log',
  templateUrl: './non-upsi-activity-log.component.html',
  styleUrls: ['./non-upsi-activity-log.component.scss']
})
export class NonUpsiActivityLogComponent implements OnInit {

  user_roles: any;
  role_type: any;
  user_access: boolean = false;
  upsiId: any;
  // viewUpsiActivityList: any; 
  Id: any;
  InsiderId: any;
  attachmentFiles: any;
  timelineActivity: any;
  confidencialUrl: any;
  modelAccess: any;
  constructor(private activateRouter:ActivatedRoute, private upsiService: UpsiService, private http:HttpClient, public activeModal: NgbActiveModal) {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')))
    this.role_type = this.user_roles.roles_name;
    if(this.user_roles.roles_name !== "Admin"){
      this.user_access = false;
    }else{
      this.user_access = true;
    }
    this.modelAccess = JSON.parse(sessionStorage.getItem('model_access_controller'));
    this.confidencialUrl = environment.url
   }
 
  @Input() viewUpsiActivityList;



  ngOnInit() {
    let fileItem = this.viewUpsiActivityList?.confidentiality_agreement_attachment; 
    this.attachmentFiles = fileItem?.split(',');   
    this.timelineActivity = this.viewUpsiActivityList.timelineActivity; 

  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
