  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="row">
           <div class="col-xl-12 xl-100">
        <div class="card">
          <div class="card-header">
            <h5>Change Logs</h5>
          </div>
          <div class="container-fluid">
            <div class="row" id="project">
              <div class="col-sm-12">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs">
                  <li [ngbNavItem]="1" (click)="upsiData()">
                    <a ngbNavLink>UPSI Transfers</a>
                    <ng-template ngbNavContent>
                    <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                        <div class="row" *ngIf = "is_access_no">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">                             
                                      <div class="col-md-7 mb-3 search-bar">                      
                                          <div class="form-group">
                                            <input type="text" class="form-control" id="search" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search Activity by Project/Event Name or Username" >
                                          </div>
                                        </div>
                                        <img src="assets/images/excel.png" title="Download UPSIs Change log List" class="img-responsive excel-data" (click)="onFilter()">
                                    </div>
                                    <div class="card-body custom-datatable noscroll">
                                        <div class="custom-datatable">
                                          <table class="table">
                                            <thead>
                                                <tr>
                                                  <th scope="col" sortable="sNo">S.No.</th>
                                                  <th scope="col" sortable="id">ID No.</th>
                                                  <th scope="col" sortable="action">Activity</th>
                                                  <th scope="col" sortable="createdBy">Action By (Username)</th>
                                                  <th scope="col" sortable="projectName" class="columnwidth">Project/Event</th>
                                                  <!-- <th scope="col" sortable="purposeOfSharing">Purpose</th> -->
                                                  <th scope="col" sortable="createdOn">Action Date</th>
                                                  <th scope="col" sortable="view">View Details</th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                  <tr *ngFor="let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">                                            
                                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                    <td scope="row" data-content="ID No.">UPSI - {{items.upsi_id}}</td> 
                                                    <td scope="row" data-content="Activity">UPSI {{items.action_flag}}</td>    
                                                    <td scope="row" data-content="Action By &#xa; (Username)">{{items.createddpname}} - {{items.createddpemail}} - {{items.createddppanno}}</td>  
                                                    <td scope="row" data-content="Project/Event">{{items.project_name}}</td>  
                                                    <!-- <td scope="row" data-content="Actions">{{items.purpose_of_sharing}}</td>   -->
                                                    <td scope="row" data-content="Action Date">{{items?.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</td> 
                                                    <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal(items.id)"><i class="fa fa-eye"></i></button></td>                             
                                                  </tr>                                          
                                               
                                            </tbody>
                                        </table>
                                            <div class="list-btn2" *ngIf="filtered?.length ==''">
                                              <p>No Data Found</p>
                                          </div>
                                           <div class="d-flex justify-content-between p-2">
                                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div *ngIf = "!is_access_no">
                          <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                      </div>
                    </div>
                    </ng-template>
                    </li>
                    <li [ngbNavItem]="2" (click)="insiderData()">
                      <a ngbNavLink>Insiders</a>
                        <ng-template ngbNavContent>
                          <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                            <div class="row" *ngIf = "is_access_no1">
                                <div class="col-sm-12">
                                    <div class="card">
                                      <div class="card-header">                             
                                        <div class="col-md-7 mb-3 search-bar">                      
                                            <div class="form-group">
                                              <input type="text" class="form-control" id="search" [(ngModel)]="filterString2" autocomplete="off"  (ngModelChange)="onFilterChange2()"  placeholder="Search Activity by Username or Insider Name" >
                                            </div>
                                          </div>
                                          <img src="assets/images/excel.png" title="Download Insiders Change log List" class="img-responsive excel-data" (click)="onFilter2()">
                                      </div>
                                        <div class="card-body custom-datatable noscroll">
                                            <div class="custom-datatable">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" sortable="sNo">S.No.</th>
                                                    <th scope="col" sortable="id">ID No.</th>
                                                    <th scope="col" sortable="name">Activity</th>
                                                    <th scope="col" sortable="name">Action By (Username)</th>
                                                    <th scope="col" sortable="name">Insider Name</th>
                                                    <th scope="col" sortable="name">Action Date</th>
                                                    <th scope="col" sortable="view">View Details</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody >
                                                    <tr *ngFor="let items of (filtered2 | slice: (page-1) * pageSize : page * pageSize); let i=index">                                            
                                                      <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                      <td scope="row" data-content="ID No.">{{items.Record}}</td> 
                                                      <td scope="row" data-content="Activity" >{{items.Activity}}</td>    
                                                      <td scope="row" data-content="Action By &#xa; (Username)">{{items.ActionBy}}</td>  
                                                      <td scope="row" data-content="Insider Name">{{items.name}}</td>  
                                                      <td scope="row" data-content="Action Date">{{items?.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td> 
                                                      <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal2(items.id)"><i class="fa fa-eye"></i></button></td>                              
                                                    </tr>                                          
                                                 
                                                  </tbody>
                                              </table>
                                                <div class="list-btn2" *ngIf="filtered2?.length ==''">
                                                  <p>No Data Found</p>
                                              </div>
                                               <div class="d-flex justify-content-between p-2">
                                                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered2?.length"></ngb-pagination>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div *ngIf = "!is_access_no1">
                              <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                          </div>
                          </div>
                        </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="3" *ngIf="user_access"> -->
                    <li [ngbNavItem]="3" (click)="dpData()">
                      <a ngbNavLink>DPs</a>
                        <ng-template ngbNavContent>
                          <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                            <div class="row" *ngIf = "is_access_no2">
                                <div class="col-sm-12">
                                    <div class="card">
                                      <div class="card-header">                             
                                        <div class="col-md-7 mb-3 search-bar">                      
                                            <div class="form-group">
                                              <input type="text" class="form-control" id="search" [(ngModel)]="filterString3" autocomplete="off" (ngModelChange)="onFilterChange3()"  placeholder="Search Activity by Username or DP Name" >
                                            </div>
                                          </div>
                                          <img src="assets/images/excel.png" title="Download DPs Change log List" class="img-responsive excel-data" (click)="onFilter3()">
                                      </div>
                                        <div class="card-body custom-datatable noscroll">
                                            <div class="custom-datatable">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" sortable="sNo">S.No.</th>
                                                  <th scope="col" sortable="id">ID No.</th>
                                                    <th scope="col" sortable="name">Activity</th>
                                                    <th scope="col" sortable="name">Action By (Username)</th>
                                                    <th scope="col" sortable="dp">DP Name</th>
                                                    <th scope="col" sortable="name">Action Date</th>
                                                    <th scope="col" sortable="view">View Details</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody >
                                                    <tr *ngFor="let items of filtered3 | slice: (page-1) * pageSize : page * pageSize; let i=index">                                            
                                                      <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                      <td scope="row" data-content="ID No.">{{items.Record}}</td> 
                                                      <td scope="row" data-content="Activity">{{items.Activity}}</td>    
                                                      <td scope="row" data-content="Action By &#xa; (Username)">{{items.ActionBy}}</td>  
                                                      <td scope="row" data-content="DP Name">{{items.dpName}}</td>  
                                                      <td scope="row" data-content="Action Date">{{items?.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td>   
                                                      <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal3(items.id)"><i class="fa fa-eye"></i></button></td>                             
                                                    </tr>                                          
                                                 
                                                  </tbody>
                                              </table>
                                                <div class="list-btn2" *ngIf="filtered3?.length =='' ">
                                                  <p>No Data Found</p>
                                              </div>
                                               <div class="d-flex justify-content-between p-2">
                                                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered3?.length"></ngb-pagination>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div *ngIf = "!is_access_no2">
                              <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                          </div>
                          </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" (click)="projectData()">
                      <a ngbNavLink>Projects/Events</a>
                        <ng-template ngbNavContent>
                          <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                            <div class="row" *ngIf = "is_access_no3">
                                <div class="col-sm-12">
                                    <div class="card">
                                      <div class="card-header">                             
                                        <div class="col-md-7 mb-3 search-bar">                      
                                            <div class="form-group">
                                              <input type="text" class="form-control" id="search" [(ngModel)]="filterString4" autocomplete="off" (ngModelChange)="onFilterChange4()"  placeholder="Search Activity by Project/Event Name or Username" >
                                            </div>
                                          </div>
                                          <img src="assets/images/excel.png" title="Download Projects Change log List" class="img-responsive excel-data" (click)="onFilter4()">
                                      </div>
                                        <div class="card-body custom-datatable noscroll">
                                            <div class="custom-datatable">
                                              <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th scope="col" sortable="sNo">S.No.</th>
                                                    <th scope="col" sortable="id">ID No.</th>
                                                    <th scope="col" sortable="name">Activity</th>
                                                    <th scope="col" sortable="name">Action By (Username)</th>
                                                    <th scope="col" sortable="dp">Project/Event</th>
                                                    <th scope="col" sortable="name">Action Date</th>
                                                    <th scope="col" sortable="view">View Details</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody >
                                                    <tr *ngFor="let items of filtered4 | slice: (page-1) * pageSize : page * pageSize; let i=index">                                            
                                                      <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                      <td scope="row" data-content="ID No.">{{items.Record}}</td> 
                                                      <td scope="row" data-content="Activity" >{{items.Activity}}</td>    
                                                      <td scope="row" data-content="Action By &#xa; (Username)">{{items.ActionBy}}</td>  
                                                      <td scope="row" data-content="Project/Event">{{items.project_name}}</td>  
                                                      <td scope="row" data-content="Action Date">{{items?.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td>   
                                                      <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal4(items.id)"><i class="fa fa-eye"></i></button></td>                                                    </tr>                                          
                                                 
                                                  </tbody>
                                              </table>
                                                <div class="list-btn2" *ngIf="filtered4?.length =='' ">
                                                  <p>No Data Found</p>
                                              </div>
                                               <div class="d-flex justify-content-between p-2">
                                                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered4?.length"></ngb-pagination>
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div *ngIf = "!is_access_no3">
                              <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                          </div>
                          </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" *ngIf="openNoUpsi == true" (click)="nonUpsiData()">
                      <a ngbNavLink>Non UPSI Communication</a>
                      <ng-template ngbNavContent>
                      <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                          <div class="row" *ngIf = "is_access_no">
                              <div class="col-sm-12">
                                  <div class="card">
                                      <div class="card-header">                             
                                        <div class="col-md-7 mb-3 search-bar">                      
                                            <div class="form-group">
                                              <input type="text" class="form-control" id="search" [(ngModel)]="filterString5" autocomplete="off" (ngModelChange)="onFilterChange5()"  placeholder="Search Activity by Username" >
                                            </div>
                                          </div>
                                          <img src="assets/images/excel.png" title="Download Non UPSIs Change log List" class="img-responsive excel-data" (click)="onFilter5()">
                                      </div>
                                      <div class="card-body custom-datatable noscroll">
                                          <div class="custom-datatable">
                                            <table class="table">
                                              <thead>
                                                  <tr>
                                                    <th scope="col" sortable="sNo">S.No.</th>
                                                    <th scope="col" sortable="id">ID No.</th>
                                                    <th scope="col" sortable="action">Activity</th>
                                                    <th scope="col" sortable="createdBy">Action By (Username)</th>
                                                    <th scope="col" sortable="projectName" class="columnwidth">Project/Event</th>
                                                    <!-- <th scope="col" sortable="purposeOfSharing">Purpose</th> -->
                                                    <th scope="col" sortable="createdOn">Action Date</th>
                                                    <th scope="col" sortable="view">View Details</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody >
                                                    <tr *ngFor="let items of filtered5 | slice: (page-1) * pageSize : page * pageSize; let i=index">                                            
                                                      <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                      <td scope="row" data-content="ID No.">UPSI - {{items.upsi_id}}</td> 
                                                      <td scope="row" data-content="Activity">{{items.action_flag}}</td>    
                                                      <td scope="row" data-content="Action By &#xa; (Username)">{{items.createddpname}} - {{items.createddpemail}} - {{items.createddppanno}}</td>  
                                                      <td scope="row" data-content="Project/Event">{{items.project_name}}</td>
                                                      <!-- <td scope="row">{{items.purpose_of_sharing}}</td>   -->
                                                      <td scope="row" data-content="Action Date">{{items?.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</td> 
                                                      <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal5(items.id)"><i class="fa fa-eye"></i></button></td>                             
                                                    </tr>                                          
                                                 
                                              </tbody>
                                          </table>
                                              <div class="list-btn2" *ngIf="filtered5?.length ==''">
                                                <p>No Data Found</p>
                                            </div>
                                             <div class="d-flex justify-content-between p-2">
                                              <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered5?.length"></ngb-pagination>
                                              </div>
                                          </div>
                                      </div>
                                      
                                  </div>
                              </div>
                          </div>
                          <div *ngIf = "!is_access_no">
                            <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                        </div>
                      </div>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="6" *ngIf="is_access_no4 == true && openCustomer == true" (click)="customerData()">
                      <a ngbNavLink>Customers</a>
                      <ng-template ngbNavContent>
                      <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                          <div class="row" *ngIf = "is_access_no4">
                              <div class="col-sm-12">
                                  <div class="card">
                                      <div class="card-header">                             
                                        <div class="col-md-7 mb-3 search-bar">                      
                                            <div class="form-group">
                                              <input type="text" class="form-control" id="search" [(ngModel)]="filterString6" autocomplete="off" (ngModelChange)="onFilterChange6()"  placeholder="Search Activity by Customer Name or Username" >
                                            </div>
                                          </div>
                                          <img src="assets/images/excel.png" title="Download Non UPSIs Change log List" class="img-responsive excel-data" (click)="onFilter6()">
                                      </div>
                                      <div class="card-body custom-datatable noscroll">
                                          <div class="custom-datatable">
                                            <table class="table">
                                              <thead>
                                                  <tr>
                                                    <th scope="col" sortable="sNo">S.No.</th>
                                                    <th scope="col" sortable="id">ID No.</th>
                                                      <th scope="col" sortable="name">Activity</th>
                                                      <th scope="col" sortable="name">Action By (Username)</th>
                                                      <th scope="col" sortable="customer">customer Name</th>
                                                      <th scope="col" sortable="name">Action Date</th>
                                                      <th scope="col" sortable="view">View Details</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody >
                                                    <tr *ngFor="let items of filtered6 | slice: (page-1) * pageSize : page * pageSize; let i=index">                                            
                                                      <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                      <td scope="row" data-content="ID No.">{{items.Record}}</td> 
                                                      <td scope="row" data-content="Activity">{{items.Activity}}</td>    
                                                      <td scope="row" data-content="Action By &#xa; (Username)">{{items.ActionBy}}</td>  
                                                      <td scope="row" data-content="customer Name">{{items.customer_name}}</td>  
                                                      <td scope="row" data-content="Action Date">{{items?.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td>   
                                                      <td data-content="View Details"><button class="edit-icon" title="View Log" (click)="openModal6(items.id)"><i class="fa fa-eye"></i></button></td>                               
                                                    </tr>                                          
                                                 
                                              </tbody>
                                          </table>
                                              <div class="list-btn2" *ngIf="filtered6?.length ==''">
                                                <p>No Data Found</p>
                                            </div>
                                             <div class="d-flex justify-content-between p-2">
                                              <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered6?.length"></ngb-pagination>
                                              </div>
                                          </div>
                                      </div>
                                      
                                  </div>
                              </div>
                          </div>
                          <div *ngIf = "!is_access_no4">
                            <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;margin-bottom: 15%;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                        </div>
                      </div>
                      </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
          </div>
          <div class="card-footer text-muted" *ngIf="upsiDisclaimer == true">
            <b>Note:</b> <br>1. Limited information is show on screen considering screen size constraints. For complete details click on log or eye icon (<i class="fa fa-eye" ></i>).<br> 2. Data is displayed in reverse chronological order, i.e. most recent updates first.
        </div>
        <div class="card-footer text-muted" *ngIf="otherDisclaimer == true">
          <b>Note:</b><br> 1. This section only represents the audit trail information.<br> 2. Data is displayed in reverse chronological order, i.e. most recent updates first.<br><br>
          <b>For Complete Entry Details:</b><br>  Navigate to the respective List sections in the software to click on 'view' icon (<i class="fa fa-eye" ></i>).
      </div>

          
        </div>
      </div>
   

    </div>
  </div>
  <!-- Container-fluid Ends-->
