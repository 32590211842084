import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { window } from 'rxjs';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-revert-project',
  templateUrl: './revert-project.component.html',
  styleUrls: ['./revert-project.component.scss']
})
export class RevertProjectComponent implements OnInit {

  @Input() viewProjectList;
  @Input() title;
  getIdProject: any;
  revetProjectForm:FormGroup;
  getProjectName: any;
  isLoading = false;
  button = 'Restore';
  submitted = false;
  constructor(
    public activeModal: NgbActiveModal,private router:Router, private fb: FormBuilder, private toastr: ToastrService, private activateRouter: ActivatedRoute,private masterService: MasterService,
  ) { }

  ngOnInit() {
    
    this.revetProjectForm = this.fb.group({
      id: new FormControl(''),
      project_name: new FormControl(''),
      reasonforrestore: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])
    });
   this.getIdProject = this.viewProjectList.id;
   this.getProjectName = this.viewProjectList.project_name;

  }
  revertProjectId(){
    this.submitted = true;
    this.revetProjectForm.value.id = this.getIdProject;
    this.revetProjectForm.value.project_name = this.getProjectName;
    if(this.revetProjectForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
         
    this.masterService.revertProject(this.revetProjectForm.value).subscribe(data =>{
      if(data.success == 0){
        this.toastr.warning(data.message);
        this.isLoading = false;
        this.button = 'Restore';
        this.activeModal.close(event);
      }else{
      this.toastr.success("Project Successfully Restored");
      this.isLoading = false;
      this.button = 'Restore';
      this.activeModal.close(event);
      this.router.navigate(['/setup/project-list']);
      }
    });
  }else{
      if(this.revetProjectForm.get('reasonforrestore').value == ''){
        this.toastr.warning("Please Mention the Reason for Restore Project/Event.");
      }
    }
  }
  cancelRevertData(){
    this.activeModal.close(event);
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
