  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 xl-100">
        <div class="row countNumber">
          <div class="col-sm-12 col-md-4 col-lg-2 countList">
            <div class="card">
              <div class="card-body">
                  <div class="chart-widget-dashboard">
                    <div class="d-flex">
                      <div class="flex-grow-1" *ngIf="totalNoofTrades">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="totalNoofTrades" [from]="0" [duration]="2"></span></h5>                        
                        <p>Total Number of trades</p>
                      </div>
                      <div class="flex-grow-1" *ngIf="!totalNoofTrades">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="0" [from]="0" [duration]="2">0</span></h5>
                        <p>Total Number of trades</p>
                      </div>
                     
                    </div>
                    <div class="dashboard-chart-container">
                     
                      <img src="assets/images/benpos/benpos-dashboard-icon-05.png" alt="Total Number of trades" class="img-responsive icons">
                    </div>
                  </div>
              </div>
            </div>
          </div> 
          <div class="col-sm-12 col-md-4 col-lg-2 countList" >
            <div class="card">
              <div class="card-body">               
                  <div class="chart-widget-dashboard">
                    <div class="d-flex">
                      <div class="flex-grow-1" *ngIf="benposDpTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600" ><span class="counter" [CountTo]="benposDpTradeCount" [from]="0" [duration]="2"></span></h5>                       
                        <p>No of trades by Designated Persons</p>
                      </div>
                      <div class="flex-grow-1" *ngIf="!benposDpTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="0" [from]="0" [duration]="2">0</span></h5>
                        <p>No of trades by Designated Persons</p>
                      </div>
                      <!-- <app-feather-icons [icon]="'tag'"></app-feather-icons> -->
                    </div>
                    <div class="dashboard-chart-container">
                      <!-- <x-chartist class="small-chart-gradient-1" [data]="chart1.data" [type]="chart1.type" [options]="chart1.options" [events]="chart1.events">
                      </x-chartist> -->
                      <img src="assets/images/benpos/benpos-dashboard-icon-1.png" alt="No of trades by Designated Persons" class="img-responsive icons">
                    </div>
                  </div>              

              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-2 countList">
            <div class="card">
              <div class="card-body">                
                  <div class="chart-widget-dashboard">
                    <div class="d-flex">
                      <div class="flex-grow-1" *ngIf="benposInsiderTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="benposInsiderTradeCount" [from]="0" [duration]="2"></span></h5>                       
                        <p>No of Trades by Insider</p>
                      </div>
                      <div class="flex-grow-1" *ngIf="!benposInsiderTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="0" [from]="0" [duration]="2">0</span></h5>
                        <p>No of Trades by Insider</p>
                      </div>
                      
                      <!-- <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons> -->
                    </div>
                    <div class="dashboard-chart-container">
                      <!-- <x-chartist class="small-chart-gradient-2" [data]="chart2.data" [type]="chart2.type" [options]="chart2.options" [events]="chart2.events">
                      </x-chartist> -->
                      <img src="assets/images/benpos/benpos-dashboard-icon-02.png" alt="No of Trades by Insider" class="img-responsive icons">
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-2 countList">
            <div class="card">
              <div class="card-body">
                  <div class="chart-widget-dashboard">
                    <div class="d-flex">
                      <div class="flex-grow-1" *ngIf="volumeOfDpTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600" ><span class="counter" [CountTo]="volumeOfDpTradeCount | number:'1.0-0'" [from]="0" [duration]="2"></span></h5>                       
                        <p>Volume of shares traded by Designated persons</p>
                      </div>
                      <div class="flex-grow-1" *ngIf="!volumeOfDpTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600" ><span class="counter" [CountTo]="0" [from]="0" [duration]="2">0</span></h5>
                        <p>Volume of shares traded by Designated persons</p>
                      </div>
                      <!-- <app-feather-icons [icon]="'sun'"></app-feather-icons> -->
                    </div>
                    <div class="dashboard-chart-container">
                      <!-- <x-chartist class="small-chart-gradient-3" [data]="chart3.data" [type]="chart3.type" [options]="chart3.options" [events]="chart3.events">
                      </x-chartist> -->
                      <img src="assets/images/benpos/benpos-dashboard-icon-03.png" alt="Volume of shares traded by Designated persons" class="img-responsive icons">
                    </div>
                  </div>
              </div>
            </div>
          </div>
           <div class="col-sm-12 col-md-4 col-lg-2 countList">
            <div class="card">
              <div class="card-body">
                  <div class="chart-widget-dashboard">
                    <div class="d-flex">
                      <div class="flex-grow-1" *ngIf="volumeOfInsiderTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="volumeOfInsiderTradeCount | number:'1.0-0'" [from]="0" [duration]="2"></span></h5>                        
                        <p>Volume of shares traded by Insiders</p>
                      </div>
                      <div class="flex-grow-1" *ngIf="!volumeOfInsiderTradeCount">
                        <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="0" [from]="0" [duration]="2">0</span></h5>
                        <p>Volume of shares traded by Insiders</p>
                      </div>
                     
                    </div>
                    <div class="dashboard-chart-container">
                     
                      <img src="assets/images/benpos/benpos-dashboard-icon-04.png" alt="Volume of shares traded by Insiders" class="img-responsive icons">
                    </div>
                  </div>
              </div>
            </div>
          </div> 
         
         
        </div>
      </div>
      

    </div>
  </div>
  <!-- Container-fluid Ends-->



<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
   
        <div class="card">
            <div class="card-header">
                <h5>Benpos Comparison Table</h5>
                <div class="filterdataclass">
                  <div class="col-md-6 mb-3">                      
                      <div class="form-group">
                          <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                            >                                                                    
                      </div>
                    </div>
                  </div>
                    <a routerLink="/pit-compliance/benpos-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>

                <div class="excel-report">

                    <div class="listCheckbox">
                        <ul>
                            <li *ngFor="let item of tradingSharedList">
                                <input type="checkbox" [checked]="getSelection(item)" (change)="changeHandler(item, $event)">
                                <label>{{item.name}}</label>
                              </li>
                        </ul>
                    </div>
                    
                   
                    <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="onFilter()" alt="Download Overview Excel Report" title="Download Overview Excel Report">
                </div>
         </div>
          <div class="card-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" sortable="s.no" >S.No</th>
                            <th scope="col" sortable="dpName" >Name of the designation person</th>
                            <th scope="col" sortable="pan" >PAN</th>
                            <th scope="col" sortable="presharehold" *ngIf="benposspreDate" >Shareholding as on {{benposspreDate | date:'dd-MM-yyyy'}} </th>
                            <th scope="col" sortable="currsharehold" *ngIf="benposscurDate">Shareholding as on {{benposscurDate | date:'dd-MM-yyyy'}}</th>
                            <th scope="col" sortable="difference" >Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor =" let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                            <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                            <td scope="row" data-content="Name of the &#xa;designation person">{{item.dpName }}</td>                    
                            <td scope="row" data-content="pan">{{item.pan }}</td>
                            <td scope="row" data-content="Shareholding as on {{benposspreDate | date:'dd-MM-yyyy'}}">{{item.preTotShare | number:'1.0-0' }}</td>
                            <td scope="row" data-content="Shareholding as on {{benposscurDate | date:'dd-MM-yyyy'}}">{{item.tot_shares | number:'1.0-0' }}</td>
                            <td scope="row" data-content="Difference">{{item.share_diff | number:'1.0-0' }}</td>                   
                            
                        </tr>
                        </tbody>
                </table>
                <div class="list-btn2" *ngIf="filtered?.length =='' ">
                    <p>No previous week data to compare for this date - {{this.benf_item.benpos_id | date:'dd-MM-yyyy'}}</p>
                </div> 

                <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                </div>
            </div>
        </div>

       
    </div>
</div>  
<!-- Container-fluid Ends -->

