<!-- Container-fluid starts -->
<div class="container-fluid">
  <div class="row">
      <div class="col-sm-12 ">
          <div class="card">
              <div class="card-header">
                  <h5>Add New Roles & Permissions</h5>
                  <a routerLink="/setup/role-permission"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
              </div>
              <div class="card-body">
                  <form  [formGroup]="rolesCreateForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                      <div class="row" >
                          <div class="col-md-6 mb-3">
                              <label for="roles">Roles</label>
                              <div class="form-group">
                                  <input type="text" class="form-control" id="roles" placeholder="Enter the Role" formControlName="roles_name"
                                  [ngClass]="{ 'is-invalid': (submitted || rolesCreateForm.controls.roles_name.touched) && rolesCreateForm.controls.roles_name.errors }"> 
                                  <div *ngIf="(submitted || rolesCreateForm.controls.roles_name?.touched) && rolesCreateForm.controls.roles_name.errors?.required"
                                  class="text text-danger">
                                  Role Name is required.
                              </div>             
                              </div>
                          </div>                          
                         <h5>Permission Controls</h5> 
                          <div class="col-md-12 mb-3">
                              <div class="row">
                              <div class="col-md-4 mb-3">                                    
                                  <div class="form-group">
                                      <input type="text" class="form-control" id="upsiName" placeholder="Log UPSI Transfer Name" formControlName="upsi_name" required disabled>                                                                   
                                      
                                    </div>
                              </div>
                              <div class="col-md-8 mb-3">
                                  <div class="form-group row mb-0">                                       
                                      <div class="col-sm-12">
                                        <div class="form-group m-checkbox-inline mb-0 ms-1">
                                          <label class="container">Read
                                            <input formControlName="upsi_read" type="radio" value="1" name="radio1" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">Write
                                            <input formControlName="upsi_read" type="radio" value="2" name="radio1" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">No-Access
                                            <input formControlName="upsi_read" type="radio" value="3" name="radio1" />
                                            <span class="checkmark"></span>
                                          </label>
                                           
                                        </div>
                                        
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.upsi_read?.touched) && rolesCreateForm.controls.upsi_read.errors?.required"
                                            class="text text-danger">
                                            Log UPSI Transfer Permission is required.
                                        </div>
                              </div>
                          </div>
                          </div>
                           
                          <div class="col-md-12 mb-3">
                              <div class="row">
                              <div class="col-md-4 mb-3">                                    
                                  <div class="form-group">
                                      <input type="text" class="form-control" id="dpName" placeholder="DP List" formControlName="dp_name" required disabled>                                                                   
                                  </div>
                              </div>
                              <div class="col-md-8 mb-3">
                                  <div class="form-group row mb-0">                                       
                                      <div class="col-sm-12">
                                        <div class="form-group m-checkbox-inline mb-0 ms-1">
                                          <label class="container">Read
                                            <input formControlName="dpname_read" type="radio" value="1" name="radio2" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">Write
                                            <input formControlName="dpname_read" type="radio" value="2" name="radio2" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">No-Access
                                            <input formControlName="dpname_read" type="radio" value="3" name="radio2" />
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.dpname_read?.touched) && rolesCreateForm.controls.dpname_read.errors?.required"
                                            class="text text-danger">
                                            DP Permission is required.
                                        </div>
                              </div>
                          </div>
                          </div>

                          <div class="col-md-12 mb-3">
                              <div class="row">
                              <div class="col-md-4 mb-3">                                    
                                  <div class="form-group">
                                      <input type="text" class="form-control" id="insider" placeholder="Insider" formControlName="insider" required disabled>                                                                   
                                  </div>
                              </div>
                              <div class="col-md-8 mb-3">
                                  <div class="form-group row mb-0">                                       
                                      <div class="col-sm-12">
                                        <div class="form-group m-checkbox-inline mb-0 ms-1">
                                          <label class="container">Read
                                            <input formControlName="insider_read" type="radio" value="1" name="radio3" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">Write
                                            <input formControlName="insider_read" type="radio" value="2" name="radio3" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">No-Access
                                            <input formControlName="insider_read" type="radio" value="3" name="radio3" />
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.insider_read?.touched) && rolesCreateForm.controls.insider_read.errors?.required"
                                            class="text text-danger">
                                            Insider Permission is required.
                                        </div>
                              </div>
                          </div>
                          </div>

                          <div class="col-md-12 mb-3">
                              <div class="row">
                              <div class="col-md-4 mb-3">                                    
                                  <div class="form-group">
                                      <input type="text" class="form-control" id="project" placeholder="Project" formControlName="project" required disabled>                                                                   
                                  </div>
                              </div>
                              <div class="col-md-8 mb-3">
                                  <div class="form-group row mb-0">                                       
                                      <div class="col-sm-12">
                                        <div class="form-group m-checkbox-inline mb-0 ms-1">
                                          <label class="container">Read
                                            <input formControlName="project_read" type="radio" value="1" name="radio4" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">Write
                                            <input formControlName="project_read" type="radio" value="2" name="radio4" />
                                            <span class="checkmark"></span>
                                          </label>
                                          <label class="container">No-Access
                                            <input formControlName="project_read" type="radio" value="3" name="radio4" />
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                      <div *ngIf="(submitted || rolesCreateForm.controls.project_read?.touched) && rolesCreateForm.controls.project_read.errors?.required"
                                            class="text text-danger">
                                            Project Permission is required.
                                        </div>
                                    </div>
                              </div>
                              
                          </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="row">
                            <div class="col-md-4 mb-3">                                    
                                <div class="form-group">
                                    <input type="text" class="form-control" id="twc" placeholder="Trading Window Closure" formControlName="twc" required disabled>                                                                   
                                </div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="form-group row mb-0">                                       
                                    <div class="col-sm-12">
                                      <div class="form-group m-checkbox-inline mb-0 ms-1">
                                        <label class="container">Read
                                          <input formControlName="twc_read" type="radio" value="1" name="radio5" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">Write
                                          <input formControlName="twc_read" type="radio" value="2" name="radio5" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">No-Access
                                          <input formControlName="twc_read" type="radio" value="3" name="radio5" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.twc_read?.touched) && rolesCreateForm.controls.twc_read.errors?.required"
                                          class="text text-danger">
                                          Trading Window Closure Permission is required.
                                      </div>
                                  </div>
                            </div>
                            
                        </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="row">
                            <div class="col-md-4 mb-3">                                    
                                <div class="form-group">
                                    <input type="text" class="form-control" id="preclearance" placeholder="Pre Clearance" formControlName="preclearance" required disabled>                                                                   
                                </div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="form-group row mb-0">                                       
                                    <div class="col-sm-12">
                                      <div class="form-group m-checkbox-inline mb-0 ms-1">
                                        <label class="container">Read
                                          <input formControlName="preclearance_read" type="radio" value="1" name="radio6" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">Write
                                          <input formControlName="preclearance_read" type="radio" value="2" name="radio6" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">No-Access
                                          <input formControlName="preclearance_read" type="radio" value="3" name="radio6" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.preclearance_read?.touched) && rolesCreateForm.controls.preclearance_read.errors?.required"
                                          class="text text-danger">
                                          Pre Clearance Permission is required.
                                      </div>
                                  </div>
                            </div>
                            
                        </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="row">
                            <div class="col-md-4 mb-3">                                    
                                <div class="form-group">
                                    <input type="text" class="form-control" id="benpos" placeholder="BENPOS Report" formControlName="benpos" required disabled>                                                                   
                                </div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="form-group row mb-0">                                       
                                    <div class="col-sm-12">
                                      <div class="form-group m-checkbox-inline mb-0 ms-1">
                                        <label class="container">Read
                                          <input formControlName="benpos_read" type="radio" value="1" name="radio7" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">Write
                                          <input formControlName="benpos_read" type="radio" value="2" name="radio7" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">No-Access
                                          <input formControlName="benpos_read" type="radio" value="3" name="radio7" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.benpos_read?.touched) && rolesCreateForm.controls.benpos_read.errors?.required"
                                          class="text text-danger">
                                          BENPOS Permission is required.
                                      </div>
                                  </div>
                            </div>
                            
                        </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="row">
                            <div class="col-md-4 mb-3">                                    
                                <div class="form-group">
                                    <input type="text" class="form-control" id="benpos" placeholder="Customers" formControlName="customers" required disabled>                                                                   
                                </div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="form-group row mb-0">                                       
                                    <div class="col-sm-12">
                                      <div class="form-group m-checkbox-inline mb-0 ms-1">
                                        <label class="container">Read
                                          <input formControlName="customer_read" type="radio" value="1" name="radio8" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">Write
                                          <input formControlName="customer_read" type="radio" value="2" name="radio8" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">No-Access
                                          <input formControlName="customer_read" type="radio" value="3" name="radio8" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.customer_read?.touched) && rolesCreateForm.controls.customer_read.errors?.required"
                                          class="text text-danger">
                                          Customers Permission is required.
                                      </div>
                                  </div>
                            </div>
                            
                        </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <div class="row">
                            <div class="col-md-4 mb-3">                                    
                                <div class="form-group">
                                    <input type="text" class="form-control" id="companylist" placeholder="Company List" formControlName="companylist" required disabled>                                                                   
                                </div>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="form-group row mb-0">                                       
                                    <div class="col-sm-12">
                                      <div class="form-group m-checkbox-inline mb-0 ms-1">
                                        <label class="container">Read
                                          <input formControlName="companylist_read" type="radio" value="1" name="radio9" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">Write
                                          <input formControlName="companylist_read" type="radio" value="2" name="radio9" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <label class="container">No-Access
                                          <input formControlName="companylist_read" type="radio" value="3" name="radio9" />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </div>
                                    <div *ngIf="(submitted || rolesCreateForm.controls.customer_read?.touched) && rolesCreateForm.controls.customer_read.errors?.required"
                                          class="text text-danger">
                                          Company List Permission is required.
                                      </div>
                                  </div>
                            </div>
                            
                        </div>
                          </div>
                            <div class="submit-btn">
                              <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                              <button class="btn btn-warning" type="btn" (click)="cancelRoles()">Cancel</button>
                           </div>
                      </div>
                  </form>
              </div>
          </div>                       
      </div>
  </div>
</div>
<!-- Container-fluid Ends -->
