import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventTypes } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-restorereason',
  templateUrl: './restorereason.component.html',
  styleUrls: ['./restorereason.component.scss']
})
export class RestorereasonComponent implements OnInit {

  projectId: any;
  accessSuccess: string;
  insiderId: any;
  isLoading = false;
  isValid = true;
  button = 'Ok';
  reason = "";
  projectReasonDeleteForm: FormGroup;
  getId: any;
  submitted = false;
  constructor(public activeModal: NgbActiveModal,private fb: FormBuilder, public router: Router, private toastr: ToastrService, private activateRouter:ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    
  }

  closeModal(sendData) {
    if(sendData == ''){
      this.isValid=false;
    }else{
      this.activeModal.close(sendData);
    }
    
  }

}
