import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-previous-trading',
  templateUrl: './view-previous-trading.component.html',
  styleUrls: ['./view-previous-trading.component.scss']
})
export class ViewPreviousTradingComponent implements OnInit {

  @Input() viewPreviousTradingList;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {    
    
  }
  
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
