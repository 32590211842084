<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header btnlist">
                    <div class="filterFormSection" >
                        <form class="date-block" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="row date-block__list">
                                    <div class="col-md-4 mb-3">
                                        <label for="fromDate">From Date</label>
                                        <div class="form-group input-group">
                                        <input class="form-control " placeholder="yyyy-mm-dd"  type="date" formControlName="fromDate" min="1997-01-01" max="2050-12-31">                                  
                                        </div>                   
                                    </div>
                                    <div class="col-md-4 mb-3 list" style="position: relative;">
                                        <label for="fromDate">To Date</label>
                                        <div class="form-group input-group">
                                        <input class="form-control " placeholder="yyyy-mm-dd" type="date" formControlName="toDate" min="1997-01-01" max="2050-12-31">                                  
                                        </div>                 
                                        <p *ngIf="filterForm.touched && filterForm.invalid" style="color:red;position: absolute;bottom: -1.5rem;">Please add a valid from and To date </p>
                                    </div>
                                                                        
                                    <div class="col-md-4 mb-3">
                                        <div class="btn-list">
                                            <button class="btn btn-primary me-3">Search</button>
                                            <button type="button" class="btn btn-primary me-3" (click)="resetForm()">Reset</button> 
                                            <img src="/assets/images/pdf-icon.png" class="pdf-icon" (click)="downloadpdfreport()" title="Download PDF" style="cursor: pointer;"> 
                                            <img src="assets/images/excel.png" class="pdf-icon" (click)="downloadexcelsheet()" title="Download Excel" style="cursor: pointer;">                       
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                
                            </div>
                            
                            
                        </form>
                        <div class="back-btn">
                            <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                        </div>
                    </div>
                    <!-- <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a> -->
                                    
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="userid">User Id</th>
                                    <th scope="col" sortable="username">User Name</th>
                                    <th scope="col" sortable="mobile">Log In</th>
                                    <th scope="col" sortable="address">Log Out</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of userLogHistory | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                <td scope="row">User - {{items.user_id}}</td>
                                <td scope="row">{{items.username}}</td>
                                <td scope="row">{{items.logged_on}}</td>
                                <td scope="row">{{items.logged_out}}</td>                                 
                                  
                            </tr>
                            </tbody>
                        </table>

                        <div class="list-btn2" *ngIf="userLogHistory?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        
                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="userLogHistory?.length"></ngb-pagination>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

