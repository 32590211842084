import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {  
  today = new Date();
  formattedDate: string;

  constructor() {   
    this.formattedDate=this.today.toISOString().slice(0,4);
  
   }

  ngOnInit() { }

}
