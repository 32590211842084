import { Component, OnInit, ElementRef } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { PitComplianceService } from '../pit-compliance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BenposMailConfigComponent } from '../benpos-mail-config/benpos-mail-config.component';

// AOA : array of array
type AOA = any[][];

@Component({
  selector: 'app-excel-multi-report',
  templateUrl: './excel-multi-report.component.html',
  styleUrls: ['./excel-multi-report.component.scss']
})
export class ExcelMultiReportComponent implements OnInit {
  page = 1;
  pageSize = 10;
  
  benpossComparison: any;

  benf_date: any;
  filtered: any;
  filterString= "";
  tradingWindowList: any;
  lastTwc: any;
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
 constructor(private fb: FormBuilder, private pitService: PitComplianceService, private companyService: CompanyService, private router:Router,
  private toastr: ToastrService, private modalService: NgbModal){}
 ngOnInit(): void {
  this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.benpos_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.benpos_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
this.pitService.benposdatelist(null).subscribe(
  res => {
  
    this.benpossComparison = res['data'];
    this.filtered = res['data'];
  });

//TWC
this.pitService.tradingWindowClosureList(null).subscribe(
  res => {
    let tradingWindowList = res;
    this.lastTwc = tradingWindowList?.data[0]?.end_date;   
    
  });
 }
 camparisonBenpos(id){

   if(id){
    this.benpossComparison.map(item =>{
      if(item.id == id){
        this.benf_date = {'benf_date' : item.benf_date }        
      }
    })
    this.pitService.comparisonbenpass(this.benf_date).subscribe(
      res => {
        console.log("Data Successfully Send!..");
      });
  }
 }

 onFilterChange() {
    
  this.filtered = this.benpossComparison?.filter(res =>{    
   return res.benf_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
  }) 
 
}

openModal(id:any) {   
     
  if (id) {
    let benposId = {'id' : id};
    this.pitService.benposTradesMail(benposId).subscribe(
      ({data, MailTemplate}) =>{
       
      const modalRef = this.modalService.open(BenposMailConfigComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      modalRef.componentInstance.mailTemplate = MailTemplate;
      modalRef.componentInstance.benf_date = id;
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
    })
  }    
  
} 

}
