<div class="modal-header">
    <h5 class="modal-title">Bulk Upload Error List</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table" id="formTable">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="sno">Sheet Row No.</th>
                                    <th scope="col" sortable="name">Name</th>
                                    <th scope="col" sortable="email">Email ID</th>
                                    <th scope="col" sortable="proofType" >ID Proof Type</th>
                                    <th scope="col" sortable="proofNo" >ID Proof No.</th>
                                    <th scope="col" sortable="error" >Error</th>
                                </tr>                                
                            </thead>
                            <tbody>
                                <tr *ngFor = "let items of viewCustomerErrorList | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row">{{items.sheet_no}}</td>
                                    <td scope="row">{{items.Name}}</td>
                                    <td scope="row">{{items.Email_ID}}</td>
                                    <td scope="row">{{items.ID_Proof_Type}}</td>
                                    <td scope="row">{{items.ID_Proof_No}}</td>
                                    <td scope="row" style="color:red;">{{items.message}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="viewCustomerErrorList?.length">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
