<div class="container-fluid">
   
    <div class="row" *ngIf="is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="serach" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                             required >
                                                                         
                    </div>
                        </div>
                             
                    <div class="list-btn"> 
                        <!-- <div class="columnHide mb-3">
                            <div class="filter-group">
                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                    [settings]="dropdownSettings1"
                                    [data]="hidelist"
                                    [(ngModel)]="selectedhideItems"
                                    (onSelect)="onItemSelect($event,'column')"
                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                    (onSelectAll)="onSelectAll($event,'column')" 
                                    >
                                </ng-multiselect-dropdown>
                            </div>
                        </div> -->
                        <a routerLink="/pit-compliance/add-pre-clearance" *ngIf="is_access_write"><button class="btn btn-primary me-1" >New</button></a>
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>  
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table center" id="formTable">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="Id.no" >ID No.</th>
                                    <th scope="col" sortable="dpName" class="dpName" >Dp Name </th>
                                    <th scope="col" sortable="PAN" >PAN</th>
                                    <!-- <th scope="col" sortable="Designation" >Designation</th>  -->
                                    <th scope="col" sortable="Status" >No Trade</th>                                   
                                    <th scope="col" sortable="Designation" >Status</th>                                    
                                    <th scope="col" sortable="action" >Actions</th>                                    
                                    <th scope="col" sortable="Status" ></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID No.">PC - {{item.id}}</td>
                                    <td scope="row" data-content="Dp Name">{{item.dpname}}</td> 
                                    <td scope="row" data-content="PAN">{{item.dppan}}</td> 
                                    <!-- <td scope="row" >{{item.dpdesignation}}</td>  -->

                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == false && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.dpname != nameEmailmerge && item.notrade =='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.dpname === nameEmailmerge && item.notrade =='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">--</td> 
                                    <!-- <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess && item.dpname === nameEmailmerge && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">--</td>  -->
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == false && item.previouslistcountval == 0">--</td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Pending'">--</td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == true && item.previouslistcountval != 0">--</td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == false && item.previouslistcountval != 0">--</td>  
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Rejected'">--</td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Pending'"><span class="badge badge-warning">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Approved'"><span class="badge badge-success">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Rejected'"><span class="badge badge-danger">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Incomplete'"><span class="badge badge-info">{{item.status}}</span></td> 
                                    <td data-content="Actions">
                                        <!-- <button class="btn btn-primary me-1">Send Mail</button> -->
                                        <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
                                        <!-- <button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button> -->
                                        <button class="edit-icon" *ngIf="is_access_write" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button>
                                        <button class="edit-icon" title="Download Application Form" (click)="openModalPdf(item.id)"><i class="fa fa-file-pdf-o"></i></button> 
                                        <!-- <a [routerLink]="['/pit-compliance/duplicate-pre-clearance', item.id]"><button class="edit-icon" title="Duplicate"><i class="fa fa-files-o"></i></button></a> -->
                                        <!-- <a [routerLink]="['/pit-compliance/add-trading-report', item.id, 'Form C']" *ngIf="item.status == 'Approved'"><button class="edit-icon" title="Download Form C PDF"><i class="fa fa-plus-square-o"></i></button></a> -->
                                    </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Pending' || item.status == null" class="statusItem">                                       
                                        <button class="btn me-1 ls"  title="Approval" value="Approved"  (click)="openModalApproval(item.id)" ><img src="assets/images/check-mark.png"></button>
                                        <button class="btn me-1 ls"  title="Reject" value="Rejected"   (click)="openModalReject(item.id)" ><img src="assets/images/cross-button.png"></button>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls redirect" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                        <!-- <button class="btn me-1 ls redirect" title="Pending"><img src="assets/images/pendingImg.png"></button> -->
                                    </td>   
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Pending' || item.status == null" class="statusItem">  
                                        <button class="btn me-1 ls redirect" title="Pending"><img src="assets/images/pendingImg.png"></button>
                                     </td>   
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Approved'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Approval" ><img src="assets/images/check-mark.png"></button> -->
                                        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
                                        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
                                        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
                                        <button class="btn me-1 ls" title="Approval" ><img src="assets/images/check-mark.png"></button>
                                        <!-- <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Rejected'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Reject"><img src="assets/images/cross-button.png"></button> -->
                                        <button class="btn me-1 ls re-app" title="Re-Approval" value="Approved" (click)="openModalReapproval(item.id)"><img src="assets/images/approve.png"></button>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Rejected'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Reject"><img src="assets/images/cross-button.png"></button> -->
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                        <button class="btn me-1 ls" title="Rejected Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                    </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Incomplete'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Incomplete'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
                                     </td>  
                                     
                            </tr> 
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div> 

                        <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>


<!-- Container-fluid starts -->
<!-- <div class="container-fluid">
   
    <div class="row" >
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                             required >
                                                                         
                    </div>
                        </div>
                             
                    <div class="list-btn"> 
                        <div class="columnHide mb-3">
                            <div class="filter-group">
                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                    [settings]="dropdownSettings1"
                                    [data]="hidelist"
                                    [(ngModel)]="selectedhideItems"
                                    (onSelect)="onItemSelect($event,'column')"
                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                    (onSelectAll)="onSelectAll($event,'column')" 
                                    >
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
                        <a routerLink="/pit-compliance/add-pre-clearance"><button class="btn btn-primary me-1" >New</button></a>
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table" id="formTable">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="Id.no" *ngIf="show_id">ID No.</th>
                                    <th scope="col" sortable="dpName" class="dpName" *ngIf="show_dpName">Dp Name <i class="fa fa-filter" (click)="filterData()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="dpNameFilter"
                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                    (selected)='selectEvent($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="dpNameFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="startDate" *ngIf="show_from_date">From Date <i class="fa fa-filter" (click)="filterData2()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="fromDateNameFilter"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="fromDateFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item | date:'dd-MM-yyyy'"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="endDate" *ngIf="show_to_date">End Date <i class="fa fa-filter" (click)="filterData3()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible3">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="toDateNameFilter"
                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                    (selected)='selectEvent3($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="toDateFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item | date:'dd-MM-yyyy'"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="startDate" *ngIf="show_quantity_traded">Quantity <i class="fa fa-filter" (click)="filterData4()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible4">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="quantityTradedNameFilter"
                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                    (selected)='selectEvent4($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="quantityFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="endDate" *ngIf="show_paid_company">Paid Company <i class="fa fa-filter" (click)="filterData5()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible5">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="paidComNameFilter"
                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                    (selected)='selectEvent5($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="paidComFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="startDate" *ngIf="show_traded_dealt">Traded Dealt <i class="fa fa-filter" (click)="filterData6()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible6">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="tradedDealtNameFilter"
                                                    [searchKeyword]="keyword6" placeholder="Search ..."
                                                    (selected)='selectEvent6($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="tradeDealFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="endDate" *ngIf="show_post_trade">Post Trade <i class="fa fa-filter" (click)="filterData7()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible7">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="postTradeNameFilter"
                                                    [searchKeyword]="keyword7" placeholder="Search ..."
                                                    (selected)='selectEvent7($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="postTradeFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="startDate" *ngIf="show_trade_percentage">Trade Percentage <i class="fa fa-filter" (click)="filterData8()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible8">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="tradePerNameFilter"
                                                    [searchKeyword]="keyword8" placeholder="Search ..."
                                                    (selected)='selectEvent8($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="tradePerFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="endDate" *ngIf="show_trade_type">Trade Type <i class="fa fa-filter" (click)="filterData9()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible9">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="tradeTypeNameFilter"
                                                    [searchKeyword]="keyword9" placeholder="Search ..."
                                                    (selected)='selectEvent9($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="tradeTypeFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="startDate" *ngIf="show_trading_plan">Trading Plan <i class="fa fa-filter" (click)="filterData10()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible10">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="tradePlanNameFilter"
                                                    [searchKeyword]="keyword10" placeholder="Search ..."
                                                    (selected)='selectEvent10($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="tradePlanFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="endDate" *ngIf="show_value_preposed">Value Preposed <i class="fa fa-filter" (click)="filterData11()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible11">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="valuePropNameFilter"
                                                    [searchKeyword]="keyword11" placeholder="Search ..."
                                                    (selected)='selectEvent11($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="valuePrepFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>                                    
                                    <th scope="col" sortable="ctratedOn" *ngIf="show_created_on">Created On</th>
                                    <th scope="col" sortable="action" >Action</th>
                                </tr>
                                </thead>
                                <tbody *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                <tr>
                                    <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" *ngIf="show_id">PC - {{item.id}}</td>
                                    <td scope="row" *ngIf="show_dpName">{{item.dpname}} </td> 
                                    <td scope="row" *ngIf="show_from_date">{{item.from_date | date:'dd-MM-yyyy'}} </td> 
                                    <td scope="row" *ngIf="show_to_date">{{item.to_date | date:'dd-MM-yyyy'}}</td> 
                                    <td scope="row" *ngIf="show_quantity_traded">{{item.quantity_traded}}</td> 
                                    <td scope="row" *ngIf="show_paid_company">{{item.paid_company}}</td> 
                                    <td scope="row" *ngIf="show_traded_dealt">{{item.traded_dealt}} %</td> 
                                    <td scope="row" *ngIf="show_post_trade">{{item.post_trade}}</td> 
                                    <td scope="row" *ngIf="show_trade_percentage">{{item.trade_percentage}} %</td> 
                                    <td scope="row" *ngIf="show_trade_type">{{item.trade_type}}</td> 
                                    <td scope="row" *ngIf="show_trading_plan">{{item.trading_plan}}</td> 
                                    <td scope="row" *ngIf="show_value_preposed">{{item.value_preposed}}</td> 
                                    <td scope="row" *ngIf="show_created_on">{{item.created_on}}</td>                                    
                                    <td>
                                       
                                        <button class="edit-icon" title="View Log" (click)="openModal(item.id)" ><i class="fa fa-eye"></i></button>
                                        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]"><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                        <button class="edit-icon" (click)="openModalDelete(item.id)" title="Delete"><i class="fa fa-trash"></i></button>
                                        
                                    </td>  
                            </tr>
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div> 

                        <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div> -->
<!-- Container-fluid Ends -->





