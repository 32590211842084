<div class="modal-header">
    <h5 class="modal-title">Annual Confirmation Changes</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body custom-datatable noscroll">
                                      <form [formGroup]="rejectAnnualConfirmForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                                            <div class="sellected-option">
                                              <label for="projectAccess" class="mb-0">Select Admin</label>
                                              <div class="bottom-field" >
                                                  <div class="form-group">                              
                                                      <angular2-multiselect [data]="rejectAnnualConfirmFilter" [(ngModel)]="selectedItems" 
                                                          [settings]="dropdownSettings" 
                                                          (onSelect)="onItemSelect($event)" 
                                                          (onDeSelect)="OnItemDeSelect($event)"
                                                          (onSelectAll)="onSelectAll($event)"
                                                          (onDeSelectAll)="onDeSelectAll($event)" formControlName="role_name"
                                                          [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.role_name.touched) && rejectAnnualConfirmForm.controls.role_name.errors }">
                                                      </angular2-multiselect>
                                                      <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.role_name.touched) && rejectAnnualConfirmForm.controls.role_name.errors?.required"
                                                            class="text text-danger">
                                                            Admin Details is required.
                                                        </div>
                                                      
                                                  </div>
                                              </div>
                  
                                          </div>
                                          <div class="col-md-12 mb-3">
                                            <label for="content_text">Mail Content</label>
                                            <div class="form-group">
                                                <textarea class="form-control" id="content_text" rows="5" formControlName="content_text"
                                                    placeholder="Enter the Message..." [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.content_text.touched) && rejectAnnualConfirmForm.controls.content_text.errors }"></textarea>
                                                    <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.content_text.touched) && rejectAnnualConfirmForm.controls.content_text.errors?.required"
                                                            class="text text-danger">
                                                            Enter the Message Details.
                                                        </div>
                                            </div>
                                            
                                        </div>
                                        <div class="custom-datatable1">
                                          
                                            <div  style="margin-top: 1.5rem;">
                                            <h6>Dp Basic Details</h6>
                                            <table class="table" >                            
                                                <tbody >
                                                    <tr>
                                                        <td scope="row">Name</td>
                                                        <td scope="row"><input type="text" class="form-control" id="name" placeholder="Name" autocomplete="off"
                                                            formControlName="dpName" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpName.touched) && rejectAnnualConfirmForm.controls.dpName.errors }">
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpName.touched) && rejectAnnualConfirmForm.controls.dpName.errors?.required"
                                                            class="text text-danger">
                                                            Name is required.
                                                        </div></td>                                         
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">Designation</td>
                                                        <td scope="row"><input type="text" class="form-control" id="designationOfDb" autocomplete="off"
                                                            placeholder="Designation" formControlName="dpDesignation" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpDesignation.touched) && rejectAnnualConfirmForm.controls.dpDesignation.errors }">
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpDesignation.touched) && rejectAnnualConfirmForm.controls.dpDesignation.errors?.required"
                                                            class="text text-danger">
                                                            Designation is required.
                                                        </div></td>                                         
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">Email ID</td>
                                                        <td scope="row"> <input type="text" class="form-control" id="email" placeholder="Email ID" autocomplete="off"
                                                            formControlName="email">
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.email.touched) && rejectAnnualConfirmForm.controls.email.errors?.required"
                                                            class="text text-danger" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.email.touched) && rejectAnnualConfirmForm.controls.email.errors }">
                                                            Email ID is required.
                                                        </div> </td>  
                                                                                                  
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">Contact Number</td>
                                                        <td scope="row"><input type="text" class="form-control" id="contactNo" placeholder="Contact Number" autocomplete="off"
                                                            formControlName="dpContactNo" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpContactNo.touched) && rejectAnnualConfirmForm.controls.dpContactNo.errors }">
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpContactNo.touched) && rejectAnnualConfirmForm.controls.dpContactNo.errors?.required"
                                                            class="text text-danger">
                                                            Contact Number is required.
                                                        </div></td>                                         
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">ID Proof Type </td>
                                                        <td scope="row"><select class="form-select" class="form-control form-select"
                                                            required id="DocumentTypes"
                                                            formControlName="DocumentTypes" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.DocumentTypes.touched) && rejectAnnualConfirmForm.controls.DocumentTypes.errors }">
                                                            <option value="" disabled selected>-- Choose One --</option>
                                                            <option value="PAN">PAN</option>
                                                            <option value="Aadhar card">Aadhar card</option>
                                                            <option value="Driving License">Driving License</option>
                                                            <option value="Passport">Passport</option>
                                                            <option value="Voter ID">Voter ID</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.DocumentTypes.touched) && rejectAnnualConfirmForm.controls.DocumentTypes.errors?.required"
                                                        class="text text-danger">
                                                        ID Proof Type is required.
                                                    </div></td>                                         
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">ID Proof Type</td>
                                                        <td scope="row"><input type="text" class="form-control" id="panNo" placeholder="ID Proof No." autocomplete="off"
                                                            formControlName="dpPanNo" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpPanNo.touched) && rejectAnnualConfirmForm.controls.dpPanNo.errors }">
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpPanNo.touched) && rejectAnnualConfirmForm.controls.dpPanNo.errors?.required"
                                                            class="text text-danger">
                                                            ID Proof No. is required.
                                                        </div></td>                                         
                                                     </tr> 
                                                     <tr>
                                                        <td scope="row">Address</td>
                                                        <td scope="row"><textarea class="form-control" id="address" rows="3" formControlName="dpAddress"
                                                            placeholder="Address" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpAddress.touched) && rejectAnnualConfirmForm.controls.dpAddress.errors }"></textarea>
                                                            <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpAddress.touched) && rejectAnnualConfirmForm.controls.dpAddress.errors?.required"
                                                            class="text text-danger">
                                                            Address is required.
                                                        </div></td>                                         
                                                     </tr>
                                                  
                                                  
                                            </tbody>
                                        </table>
                                        <h6>Historical Details</h6>
                                        <table class="table education-report">                            
                                            <tbody >
                                                <tr>
                                                    <td scope="row" class="cd0">Educational Institute Name, Last Graduated From</td>
                                                    <td scope="row"><input type="text" class="form-control" id="dpEducationalInstitute" autocomplete="off"
                                                        placeholder="Educational Institution Name" formControlName="dpEducationalInstitute" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpEducationalInstitute.touched) && rejectAnnualConfirmForm.controls.dpEducationalInstitute.errors }">
                                                        <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpEducationalInstitute.touched) && rejectAnnualConfirmForm.controls.dpEducationalInstitute.errors?.required"
                                                        class="text text-danger">
                                                        Educational Institute Name, Last Graduated From is required.
                                                    </div></td>                                         
                                                </tr> 
                                                <tr>
                                                    <td scope="row" class="cd0">Company Name of Previous Job</td>
                                                    <td scope="row"><input type="text" class="form-control" id="employersDp" autocomplete="off"
                                                        placeholder="Company Name of Previous Job" formControlName="dpPastEmp" [ngClass]="{ 'is-invalid': (submitted || rejectAnnualConfirmForm.controls.dpPastEmp.touched) && rejectAnnualConfirmForm.controls.dpPastEmp.errors }">
                                                        <div *ngIf="(submitted || rejectAnnualConfirmForm.controls.dpPastEmp.touched) && rejectAnnualConfirmForm.controls.dpPastEmp.errors?.required"
                                                        class="text text-danger">
                                                        Company Name of Previous Job is required.
                                                    </div></td>                                         
                                                </tr>                                                
                                            
                                        </tbody>
                                    </table>
                                    <h6>Name of Immediate Relative / Person Sharing Financial Material Relationship With DP</h6>
                                    <div class="dprelative-details" formArrayName="immediateDpRelative">
                                        <table class="table immediate-report"> 
                                            <thead>
                                                <tr>
                                                    <th style="display:none">ID No.</th>
                                                    <th>Name</th>
                                                    <th>Relationship</th>
                                                    <th>Contact Number</th>
                                                    <th>ID Proof Type</th>
                                                    <th>ID Proof No.</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>                           
                                            <tbody >
                                                <tr [formGroupName]="idx" *ngFor="let hobby of rejectAnnualConfirmForm.get('immediateDpRelative')['controls']; first as isFirst; index as idx">
                                                    <td scope="row" style="display:none">
                                                        <input type="text" class="form-control" id="{{'imr_id' + idx}}" autocomplete="off" placeholder="name" formControlName="imr_id" > 
                                                                                                                 
                                                    </td>
                                                    <td scope="row" >
                                                        <input type="text" class="form-control" id="{{'name' + idx}}" autocomplete="off" placeholder="name" formControlName="name" [ngClass]="{ 'is-invalid': (submitted || hobby['controls'].name?.touched) && hobby['controls'].name?.errors }">                                                       
                                                        <div class="text text-danger" *ngIf="(submitted || hobby['controls'].name?.touched) && hobby['controls'].name?.errors?.required">
                                                            Immediate Relative Name is required</div>
                                                            
                                                    </td>
                                                    <td scope="row"> 
                                                            <select class="form-control form-select" id="{{'relationship' + idx}}" formControlName="relationship" [ngClass]="{ 'is-invalid': (submitted || hobby['controls'].relationship?.touched) && hobby['controls'].relationship?.errors }">
                                                                <option value="" disabled selected>-- Choose One --</option>
                                                                <option value="Father">Father</option>
                                                                <option value="Mother">Mother</option>
                                                                <option value="Brother">Brother</option>
                                                                <option value="Sister">Sister</option>
                                                                <option value="Spouse">Spouse</option>
                                                                <option value="Father In Law">Father In Law</option>
                                                                <option value="Mother in Law">Mother in Law</option>
                                                                <option value="Son">Son</option>
                                                                <option value="Daughter">Daughter</option>
                                                                <option value="Step-son">Step-son</option>
                                                                <option value="Step-daughter">Step-daughter</option>
                                                                <option value="Material Relationship">Material Relationship</option>
                                                                <option value="Brother in Law">Brother in Law</option>
                                                                <option value="Sister in Law">Sister in Law</option>
                                                                <option value="Son in law">Son in law</option>
                                                                <option value="Daughter in law">Daughter in law</option>
                                                        </select>
                                                        <div class="text text-danger" *ngIf="(submitted || hobby['controls'].relationship?.touched) && hobby['controls'].relationship?.errors?.required">
                                                            Immediate Relative Name is required</div>
                                                    </td>
                                                    <td><input type="phone" class="form-control" id="{{'phone_no' + idx}}" autocomplete="off" placeholder="Contact Number" formControlName="phone_no"  [ngClass]="{ 'is-invalid': (submitted || hobby['controls'].phone_no?.touched) && hobby['controls'].phone_no?.errors }">
                                                        <div class="text text-danger" *ngIf="(submitted || hobby['controls'].phone_no?.touched) && hobby['controls'].phone_no?.errors?.required">
                                                            Immediate Relative Name is required</div>
                                                    </td>                                     
                                                    <td>
                                                        <select class="form-control form-select" id="{{'DocumentType + idx'}}" formControlName="DocumentType" [ngClass]="{ 'is-invalid': (submitted || hobby['controls'].DocumentType?.touched) && hobby['controls'].DocumentType?.errors }">
                                                            <option value="" disabled selected>-- Choose One --</option>
                                                            <option value="PAN">PAN</option>
                                                            <option value="Aadhar card">Aadhar card</option>
                                                            <option value="Driving License">Driving License</option>
                                                            <option value="Passport">Passport</option>
                                                            <option value="Voter ID">Voter ID</option>
                                                            <option value="Others">Others</option>
                                                        </select>         
                                                        <div class="text text-danger" *ngIf="(submitted || hobby['controls'].DocumentType?.touched) && hobby['controls'].DocumentType?.errors?.required">
                                                            Immediate Relative ID Proof Type is required</div>
                                                     </td>                                     
                                                    <td> <input type="text" class="form-control" id="{{'pan + idx'}}" autocomplete="off"
                                                        placeholder="Doument Number" formControlName="pan" [ngClass]="{ 'is-invalid': (submitted || hobby['controls'].pan?.touched) && hobby['controls'].pan?.errors }">
                                                        <div class="text text-danger" *ngIf="(submitted || hobby['controls'].pan?.touched) && hobby['controls'].pan?.errors?.required">
                                                            Immediate Relative ID Proof No. is required</div></td>                                     
                                                    <td class="action-button">
                                                        <span style="min-width:28px;"><span (click)="addInputControls()"
                                                        *ngIf="idx === 0"><i class="fa fa-plus-circle"
                                                            aria-hidden="true"></i></span></span>
                                                        <span (click)="removeInputControls(idx)"
                                                            *ngIf="rejectAnnualConfirmForm.get('immediateDpRelative').length > 1"><i
                                                                class="fa fa-minus-circle" aria-hidden="true"></i></span>
                                                    </td>                                     
                                                </tr> 
                                                                                             
                                            
                                        </tbody>
                                    </table>                    
                                       
                                    </div>
                                      
                                        </div>
                                       
                                        </div>
                                          <div class="btn-bottom">
                                            <button class="btn btn-primary me-1" type="submit" [disabled]="isLoading" ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                            <button class="btn btn-primary me-1" type="button" (click)="cancelProject()">Cancel</button>                    
                                         </div>
                                        </form>
                  
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
