<div class="modal-header">
    <h5 class="modal-title">View {{formType}} Trading Details</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewTradingFormList">
                                    <tr>
                                        <td scope="row"> Name</td>
                                        <td scope="row">{{viewTradingFormList.name}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Name of Company</td>
                                        <td scope="row">{{viewTradingFormList.companyName}}</td>                                         
                                     </tr> 
                                    <tr *ngIf="viewTradingFormList.isinNumber != ''">
                                        <td scope="row"> ISIN of the company</td>
                                        <td scope="row">{{viewTradingFormList.isinNumber}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> PAN</td>
                                        <td scope="row">{{viewTradingFormList.panNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> CIN/DIN Number</td>
                                        <td scope="row">{{viewTradingFormList.cin_din}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Contact Number</td>
                                        <td scope="row">{{viewTradingFormList.mobNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Address</td>
                                        <td scope="row">{{viewTradingFormList.address}}</td>                                         
                                     </tr> 
                                    <tr *ngIf="viewTradingFormList.categoryPerson != ''">
                                        <td scope="row"> Category of Person</td>
                                        <td scope="row">{{viewTradingFormList.categoryPerson}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row" colspan="2">Securities held prior to acquisition/ disposal</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewTradingFormList.typeOfSecurities}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No. of share holding</td>
                                        <td scope="row">{{viewTradingFormList.perShareNumber}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) % of share holding</td>
                                        <td scope="row">{{viewTradingFormList.perShareHolding}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2">Securities acquired/Disposed</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewTradingFormList.typeOfSecurities2}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No.</td>
                                        <td scope="row">{{viewTradingFormList.typeOfSecuritiesNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) Value</td>
                                        <td scope="row">{{viewTradingFormList.typeOfSecuritiesValue}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">d) Transaction Type</td>
                                        <td scope="row">{{viewTradingFormList.transactionType}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2">Securities held post acquisition/ disposal</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewTradingFormList.typeOfSecuritiespost}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No. of share holding</td>
                                        <td scope="row">{{viewTradingFormList.perShareHoldingNopost}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) % of share holding</td>
                                        <td scope="row">{{viewTradingFormList.perShareHoldingpost}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2">Date of allotment advice/ acquisition of shares/ disposal of shares, specify</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) From Date</td>
                                        <td scope="row">{{viewTradingFormList.fromDate}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) To Date</td>
                                        <td scope="row">{{viewTradingFormList.toDate}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">Date of intimation to company</td>
                                        <td scope="row">{{viewTradingFormList.intimateDate}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Mode of acquisition /disposal</td>
                                        <td scope="row">{{viewTradingFormList.modeOfAcquisition}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Exchange on which the trade was executed:</td>
                                        <td scope="row">{{viewTradingFormList.exchangeTrade}}</td>                                         
                                     </tr> 
                                     <tr>
                                       <td scope="row">Created By</td>
                                       <td scope="row">{{viewTradingFormList.created_by}}</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Created On</td>
                                       <td scope="row">{{viewTradingFormList.created_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                    </tr>                                
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
