import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './shared/components/layout/full-layout/full-layout.component';
import { content } from "./shared/routes/content-routes";
import { full } from './shared/routes/full.routes';
// import { AdminGuard } from './shared/guard/admin.guard';
import { AuthGuard } from "./auth/auth.guard";
import { ConfirmPasswordComponent } from './auth/confirm-password/confirm-password.component'
import {EmailVerifiedComponent} from './auth/email-verified/email-verified.component'
import { Error404Component } from './pages/error-pages/error404/error404.component';
import { OptAuthComponent } from './auth/opt-auth/opt-auth.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
    
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path: 'auth/change-password',
    component: ConfirmPasswordComponent
  },
  {
    path: 'auth/forgot_password',
    component: EmailVerifiedComponent
  },
  {
    path: 'auth/otp-authentication',
    component: OptAuthComponent
  },
 
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: content
  },
  {
    path: '',
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    children: full
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
