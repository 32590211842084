<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">	
			<div class="card-header1">
				<p class="expiryAlert blink">{{subscriptionCheck}}</p>
			</div>		
			<div class="card mt-4">
				
				<div class="card-body">
					<div class="text-center">
						<div class="text-center"><img src="assets/images/sdd-insidder-login.png" alt="SDD"></div>
						<h4>Sign In</h4>
						<!-- <h6>Enter your username and Password </h6> -->
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="!newUser">
						<div class="form-group">
							<label class="col-form-label pt-0">Username</label>
							<div class="hodePassword">
							<div class="input-group-prepend">
								<span class="input-group-text">
								   <i class="fa fa-envelope" aria-hidden="true"></i>
									  </span>
										  </div>
							<input class="form-control" formControlName="username" type="email" required="" style="padding-left: 3rem;" placeholder="example@gmail.com">
						
							<div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"
								class="text text-danger mt-1">
								Username is required
							</div>
							<!-- <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.email"
								class="text text-danger mt-1">
								Invalid username
							</div> -->
						</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Password</label>
							<div class="hodePassword">
							<div class="input-group-append pass-right">
								<span class="input-group-text pass-right">
									<i
									class="fa"
									[ngClass]="{
									  'fa-eye-slash': !fieldTextType,
									  'fa-eye': fieldTextType
									}"
									(click)="toggleFieldTextType()"
								  ></i>
								</span>
							 </div>
							<input class="form-control" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" required="" style="padding-right: 3rem;" placeholder="Password">
							
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Password is required
							</div>
						</div>
						</div>
						<div class="checkbox p-0">
							<input id="checkbox1" type="checkbox" class="checkbox" formControlName="checkbox">
							<label for="checkbox1">I have read and accept the <a href="javascript:void(0)" (click)="open(content)">terms and conditions</a>. </label>
							<div *ngIf="loginForm.controls.checkbox.touched && loginForm.controls.checkbox.errors?.required"
								class="text text-danger mt-1">
								Please Accept terms and Condition
							</div>
						</div>
						<div class="form-group row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg mt-5"								
								 (click)="login(loginForm.value)" [disabled]="loginService.showLoader" type="submit">
								<i class="fa" [ngClass]="{'fa-spin fa-spinner': loginService.showLoader, 'fa-check': !loginService.showLoader}"></i> {{loginService.button}}
							</button>
						</div>
						
					</form>
					
				</div>
				<div class="card-footer">
					<a routerLink="/auth/forgot_password">Forgot Your Password?</a>
				</div>
			</div>


			<ng-template #content let-modal>
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Terms and Conditions</h4>
					<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
				</div>
				<div class="modal-body">
					<h4>SOFTWARE LICENSE AGREEMENT FOR INSIDDER</h4>

					<p>The software is the sole property of Legality Simplified LLP ('Legality Simplified'). Please read the bellow mentioned conditions carefully. You shall comply with the terms and conditions bellow mentioned in letter and spirit while using the software.</p>

					<h4>Description of Service</h4>

					<p>We provide an application (Also referred to as “Software”, “application”, “Tool”, “Platform”) to listed entities to be used to maintain a Structured Digital Database as per SEBI (Prevention of Insider Trading) Regulations, 2015. The software shall be used to store and track all Unpublished Price Sensitive Information shared by the organization internally or to third party insiders/ its fiduciaries.</p>

					<p>The software is created to facilitate maintaining the Structured Digital Database as per legal and stand practice requirements.</p>

					<h4>Modification of Service (and Terms)</h4>

					<p>We may update the Software from time to time according to changing requirements, laws or standard practices or in order to improve or enhance our Services. We may also revise the Terms of Service from time to time as per any changes in law or new regulatory requirements, and in order to improve or enhance our Services.</p>

					<h4>Payments and Renewal</h4>

					<p>The Services are available under subscription plans.  Payments for subscription can be made only by cheque or by Bank transfer to any account as Legality Simplified LLP may specify in writing. No cash will be accepted. Subscription can be renewed on request and payment of charges before the expiry of period of subscription. User account will be deactivated on the expiry of validity period of subscription. Charge for use of Services are subject to change. Any increase in charges will not apply until the expiry of period of subscription. However, the services will need to be renewed as per the latest pricing plans and any additional feature will be made available only after making additional payment.</p>

					<p>Upon payment of subscription fees, the customer shall get license to use the software as per this terms and conditions.</p>

					<h4>User Sign up Obligations</h4>

					<p>You need to sign up for a user account by providing all required information in order to access or use the Services.  If you represent an organization, all users from your organization, can sign up for user accounts by providing your corporate information. You shall determine who shall be allowed to access the software and update information.</p>

					<h4>Personal Information and Privacy</h4>

					<p>The software allows you to upload, submit, and store content. You and your organization are responsible for maintaining confidentiality of your information. You are responsible for all activities that occur in the software uploaded in your server/ system.</p>

					<p>We are not responsible for any loss or damage to you or to any third party incurred as a result of any unauthorized access and/or use of the software uploaded in your computer resource.</p>

					<h4>Disclaimer of Warranties</h4>

					<p>You expressly understand and agree that no advice or information, whether written or oral, obtained by you from Legality Simplified, its employees or representatives shall create any warranty not expressly stated in the terms. material or information contained on or made available through the product, is not intended to and does not constitute legal advice nor does it, in any manner establish a client – advocate relationship.</p>

					<h4>Use</h4>

					<p>You shall upload true and accurate information in the software. The information uploaded shall be consistent with each other and with the certificates held by, the filings and disclosures made by and the records maintained by you.</p>

					<p>Legality Simplified advices to use the software to comply with the SEBI Prevention of Insider Trading Regulations, 2015 in the letter and the spirit. Legality Simplified strictly warns against the use of the software to violate any laws in any manner. The software is designed in a manner that facilitates its use to maintain a Structured Digital Database as per SEBI Prevention of Insider Trading Regulations, 2015. Legality Simplified strictly warns against any attempt by you to modify the software to tamper with the facility to ensure audit trail, time stamping and non-tamperability of the structured digital database provided by the software.</p>

					<p>During the term, LegalitySimplified may, at LegalitySimplified's sole option and discretion, provide the Customer with maintenance Release and updates for the software.</p>

					<p>For high priority bugs/ errors reported, which could result in delays and reasonable financial losses to the Customer, solely because of non-availability of information from the software application, the time to fix the issue shall be within 2 business days from the date of reporting of the same or at a reasonable time therefrom depending on the issue reported.</p>

					<p>For all other errors reported, Legality Simplified LLP would provide the resolutions as part of monthly releases (if any) at a schedule mutually agreed upon by both the Parties.</p>

					<p>The customer shall allow officers of LegalitySimplified to visit the premises to update the software as per maintenance releases including but not limited to updates in software required by changes in laws.<br>
					The  license to use the software shall under no circumstances be treated as an 'Assignment of Intellectual Property Rights Agreement' between Legality Simplified and the Customer. This payment of subscription fees only grants the LICENSE TO USE the Software to the Customer and nothing in excess. Customer does not, has not, and will not acquire any ownership interest in the Software, Documentation, or any related Intellectual Property Rights through this Agreement.</p>

					<p>The software can be installed and used in multiple computers but it can be used only to maintain SDD of the customer entity. The SDD of no other entity shall be maintained in the software.</p>

					<p>The customer shall be responsible to protect the software installed in their computer resource from hacking, viruses, physical intrusions and other threats. The computer resource in which software is installed shall be protected from online threats and physical intrusions.</p>

					<h4>Customer shall during the term,</h4>
					<ul>
					<li>a.	Secure and protect the Software and documentation from infringement, misappropriation, misuse, theft, or other unauthorized access through all commercially reasonable measures and precautions similar to those Customer would employ to secure and protect its own intellectual property;</li>
					<li>b.	Take all reasonable steps that LegalitySimplified may require and request to maintain the validity, enforceability, and ownership of all LegalitySimplified's Intellectual Property Rights herein;</li>
					<li>c.	Promptly notify LegalitySimplified in writing if Customer becomes aware of any actual or suspected infringement, misappropriation, misuse, theft, unauthorized access, or violation of any rights, including intellectual property rights in or relating to the Software or documentations.</li>
					<li>d.	Promptly notify LegalitySimplified in writing of any claim that the Software or documentation, in whole or in part, infringes, misappropriates, or otherwise violates any rights, including intellectual property rights, of other persons or entities;</li>
					<li>e.	Fully cooperate with and assist LegalitySimplified in all commercially reasonable ways, including but not limited to providing records, information, depositions, and testimonies and at LegalitySimplified's sole expense in any claim, suit, action, or proceeding to prosecute or defend LegalitySimplified's rights in the Software, documentation and any Intellectual Property Rights herein in any third party claims against which legality simplified has indemnified the customer; and</li>
					<li>f.	Prevent use of the Software for any illegal purposes or in any manner contrary to the terms and conditions of use including but not limited to communication or storage of any information that constitutes as defamatory, harassing, seditious, blasphemous, violative of privacy, inciting an offence or obscene by any of the users authorized to access the Software by the customer. If informed of any such use or content, the Customer shall notify the LegalitySimplified in the earliest and remove such content without destroying evidence.</li>
					</ul>

					<h4>The Customer will not, and shall not permit any third party to:</h4>

					<p>Reproduce any portion of the Software in totality or in portion, for any purpose
					Decode, disassemble, reverse engineer, or otherwise attempt to derive or gain access to the Software's source code, in totality or in portion.
					Adopt, Alter, build upon, correct modify, translate, or otherwise improve or create derivative works of the Software.
					Lend, publish, rent, lease, sell, sublicense, assign, transfer, share or otherwise make available to any third party not authorized within this Agreement the Software in any manner, including, but not limited to access to the Software on the internet or any time sharing, service bureau as a service, cloud, or similar technology or service.
					Breach or circumvent any disclosed security device or intended protection used for or contained in the Software or documentation.
					Efface, alter, obscure, translate, combine, or otherwise change any trademarks, disclaimers, warranties, documentation terms Intellectual Property Rights, proprietary rights, or any symbols, notices, marks, serial numbers, or identification on or relating to any copy of the Software or Documentation
					Use the Software in any manner or for any purpose that infringes, misappropriates, or otherwise violates any Intellectual Property Rights or any applicable law.
					Use the Software for the purpose of (i) comparative or competitive analysis of the Software; (ii) developing, using or providing a competing software product or service; or (iii) any other purpose that is detrimental to LegalitySimplified's, tangible or intangible, commercial or personal gains or reputation.
					Use the Software, Documentation for any purpose not expressly permitted under permitted use or in any manner not expressly permitted by this agreement.<p>

					<p>It is warranted that the Software will be free from all malicious programs and other contaminants including but not limited to any codes or instructions that may or will be used to access, modify, delete or damage any data files or other computer programs used by the Customer;
					LegalitySimplified will indemnify, defend, and hold harmless Customer, its officers, directors, employees, agents, affiliates, and other representatives from and against any third-party action, suit, or claim including but not limited to claims of intellectual property violation that arises out of any use of the Software in accordance with this terms and conditions. </p>

					<h4>LegalitySimplified indemnification does not apply to the extent that such actions or losses arise from any allegations of or relating to any:</h4>

					<ul>
					<li>a.	Unauthorized, unlicensed, and unpermitted modification of the Software without LegalitySimplified's express knowledge, written consent, and in direct contradiction to LegalitySimplified's Documentation specifications;</li>
					<li>b.	Unauthorized, unlicensed, and unpermitted use of the Software outside the purpose, scope, or manner authorized by Agreement or in any manner contrary to LegalitySimplified's instructions;</li>
					<li>c.	Failure to allow prompt installation and implementation of any Maintenance Release or Software replacement made available to Customer by LegalitySimplified;</li>
					<li>d.	Software use after Customer's receipt of LegalitySimplified's written notice that such continued use may be alleged to or actually infringe upon, misappropriate, or otherwise violate a third party's rights;</li>
					<li>e.	Negligence, abuse, misapplication, or misuse of the Software by or on behalf of Customer, its representatives, or a third-party; or
					<li>f.	Causes or conditions outside LegalitySimplified's commercially reasonable control, including, but not limited to, any third-party equipment error or Customer's own system bugs, defects, or malfunctions.</li>
					</ul>

					<p>The Customer shall not compromise or settle any claim or make any admission fixing liability on the LegalitySimplified without the consent of LegalitySimplified unless such compromise or settlement has the effect of relieving legalitySimplified from all claims made against it.</p>

					<p>If any terms mentioned herein or the application thereof is held to be invalid or unenforceable for any reason and to any extent, then the provision will be considered removed from this Agreement. However, the remaining provisions will continue to be valid and enforceable according to the intention of all Parties and to the maximum extent permitted by Law. If it is held that any provision of this Agreement is invalid or unenforceable, but that by limiting such provision it would become valid and enforceable, then such provision will be deemed to be written, construed, and enforced as so limited.</p>

					<h4>Arbitration</h4>

					<p>Any controversy or claim arising out of or relating to the Terms shall be settled through mandatory binding arbitration administered under the Arbitration and Conciliation Act, 1996 vide a sole arbitrator being mutually appointed by the parties. The arbitration shall be at Chennai and the courts at Chennai shall have the exclusive jurisdiction to try any matters arising from the arbitration and all matters arising in connection with license to use software given to customer. Notwithstanding anything to the contrary, Legality Simplified LLP may at any time seek injunctions or other forms of equitable relief from any court of competent jurisdiction.</p>

					<h4>END OF TERMS OF SERVICE</h4>

					<p>If you have any questions or concerns regarding this Terms of Service, please contact us at HYPERLINK <a href="mailto:{{mailId}}" >{{mailId}}</a>.</p>

					<p>Please click accept to proceed to use the software.</p>
				</div>
				
			</ng-template>

		</div>
	</div>
</div>