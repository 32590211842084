<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row" *ngIf = "is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="Search" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search Deleted DP by Name or Email ID" 
                             required >
                             <!-- <input type="text" class="form-control" id="mobileNo" [(ngModel)]="searchValue"  (ngModelChange)="search()" placeholder="Search ..." 
                             required > -->
                                                
                    </div>
                        </div>
                    <!-- <h5>Deleted DP List History</h5> -->
                    <!-- <div class="list-btn">
                        <a *ngIf="userPermission === 'write'" routerLink="/dp/dp-add"><button class="btn btn-primary me-1" >New</button></a>
                        <a *ngIf="userPermission !== 'write'"><button disabled class="btn btn-primary me-1" >New</button></a>
                    </div> -->
                    <a routerLink="/dp/dp-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                    <div class="columnHide mb-3">
                        <div class="form-group">
                            <label for="hideShow">Filter DP List Columns</label>
                            <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                [settings]="dropdownSettings1"
                                [data]="hidelist"
                                [(ngModel)]="selectedhideItems"
                                (onSelect)="onItemSelect($event,'column')"
                                (onDeSelect)="onItemDeSelect($event,'column')"
                                (onDeSelectAll)="onDeSelectAll($event,'column')"
                                (onSelectAll)="onSelectAll($event,'column')" 
                                >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table" id="formTable">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="sNo">S.No.</th>
                                    <th scope="col" sortable="name" *ngIf="show_id">ID No.</th>
                                    <th scope="col" sortable="name" *ngIf="show_dpName">Name <i class="fa fa-filter" title="Search DP" (click)="filterData()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listDpModule"
                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                    (selected)='selectEvent($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.dpName"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="name" *ngIf="show_dpDesignation">Designation <i class="fa fa-filter" title="Search DP" (click)="filterData2()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listDpModule"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="designationFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.dpDesignation"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="cae" *ngIf="show_confidentiality_agreement_entered">Confidentiality Agreement </th>
                                    <th scope="col" sortable="email" *ngIf="show_email">Email ID <i class="fa fa-filter" title="Search DP" (click)="filterData3()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible3">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listDpModule"
                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                    (selected)='selectEvent3($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="emailFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.email"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_contact">Contact Number <i class="fa fa-filter" title="Search DP" (click)="filterData4()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible4">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="dpContactNoFilter"
                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                    (selected)='selectEvent4($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="contactFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_address">Address <i class="fa fa-filter" title="Search DP" (click)="filterData5()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible5">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="dpAddressFilter"
                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                    (selected)='selectEvent5($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="addressFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_identifier">ID Proof No. <i class="fa fa-filter" title="Search DP" (click)="filterData6()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible6">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="documentTypeFilter"
                                                    [searchKeyword]="keyword6" placeholder="Search ..."
                                                    (selected)='selectEvent6($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="identifierFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_dpEducationalInstitute">Educational Institution <i class="fa fa-filter" title="Search DP" (click)="filterData7()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible7">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="dpEducationalInstituteFilter"
                                                    [searchKeyword]="keyword7" placeholder="Search ..."
                                                    (selected)='selectEvent7($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="educationalFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_dpPastEmp">Past Employment <i class="fa fa-filter" title="Search DP" (click)="filterData8()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible8">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="dpPastEmpFilter"
                                                    [searchKeyword]="keyword8" placeholder="Search ..."
                                                    (selected)='selectEvent8($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="pastEmpFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="gender" *ngIf="show_remarks">Remarks</th>
                                    <th scope="col" sortable="action">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID No." *ngIf="show_id">{{items.Record}}</td>
                                    <td scope="row" data-content="Name" *ngIf="show_dpName">{{items.dpName}}</td>
                                    <td scope="row" data-content="Designation" *ngIf="show_dpDesignation">{{items.dpDesignation}}</td>
                                    <td scope="row" data-content="Confidentiality &#xa; Agreement" *ngIf="show_confidentiality_agreement_entered">{{items.confidentiality_agreement_entered}}</td>
                                    <td scope="row" data-content="Email ID" *ngIf="show_email">{{items.email}}</td> 
                                    <td scope="row" data-content="Contact Number" *ngIf="show_contact">{{items.dpContactNo}}</td> 
                                    <td scope="row" data-content="Address" *ngIf="show_address">{{items.dpAddress}}</td>
                                    <td scope="row" data-content="ID Proof No." *ngIf="show_identifier">{{items.DocumentType}} - {{items.dpPanNo}}</td> 
                                    <td scope="row" data-content="Educational &#xa; Institution" *ngIf="show_dpEducationalInstitute">{{items.dpEducationalInstitute}}</td> 
                                    <td scope="row" data-content="Past &#xa; Employment" *ngIf="show_dpPastEmp">{{items.dpPastEmp}}</td> 
                                    <td scope="row" data-content="Remarks" *ngIf="show_remarks">{{items.remarks}}</td> 
                                    <td data-content="Actions">
                                        <button class="edit-icon" title="View Log" (click)="openModal(items.id)"><i class="fa fa-eye"></i></button>
                                        <button class="btn btn-primary me-1" (click)="openModalRevert(items.id)">Restore</button>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>

                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf = "!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>
<!-- Container-fluid Ends -->

