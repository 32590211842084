<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Add New Previous Transaction</h5>
                    <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                
                </div>
                <div class="card-body">
                    
                    <div class="classFormC">
                        <form [formGroup]="previouspreClearanceForm" class="needs-validation" (ngSubmit)="onSubmitPreviousTrade()">
                            <div class="row">   
                                <div class="col-md-6">
                                    <div class="row mb-3 rowflex">
                                        <div class="col-md-5">
                                            <label for="name">a) Name of Person: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <!-- <input type="text" class="form-control" id="name" placeholder="Enter the Name" autocomplete="off" formControlName="name"
                                                required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.name.touched) && previouspreClearanceForm.controls.name.errors }"> -->
                                                <ng-select [items]="dpNameFilter"
                                                    bindLabel="" #dpname
                                                    placeholder="Select Name of person"
                                                    [(ngModel)]="selectedDpName" formControlName="name"
                                                    (change)="changeFn($event)"                                         
                                                    [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm.controls.name.touched) && previouspreClearanceForm.controls.name.errors }" required>
                                                </ng-select>
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.name.touched) && previouspreClearanceForm.controls.name.errors?.required"
                                                class="text text-danger">
                                                Name is required.
                                            </div> 
                                            </div>
                                         
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">b) PAN: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="panNo" placeholder="Enter the PAN" autocomplete="off" formControlName="panNo" [(ngModel)]="panItem" disabled
                                                required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.panNo.touched) && previouspreClearanceForm.controls.panNo.errors }"> 
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.panNo.touched) && previouspreClearanceForm.controls.panNo.errors?.required"
                                                    class="text text-danger">
                                                    PAN Number is required.
                                                </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="cin_din">c) CIN/DIN Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="cin_din" placeholder="Enter the CIN/DIN Number" autocomplete="off" formControlName="cin_din"
                                                required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.cin_din.touched) && previouspreClearanceForm.controls.cin_din.errors }">
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.cin_din.touched) && previouspreClearanceForm.controls.cin_din.errors?.required"
                                                    class="text text-danger">
                                                    CIN/DIN Number is required.
                                                </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="mobNo">d) Contact Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="mobNo" placeholder="Enter the Contact Number" autocomplete="off" formControlName="mobNo" [(ngModel)]="dpContactNo" disabled
                                                required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.mobNo.touched) && previouspreClearanceForm.controls.mobNo.errors }">
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.mobNo.touched) && previouspreClearanceForm.controls.mobNo.errors?.required"
                                                    class="text text-danger">
                                                    Contact Number is required.
                                                </div> 
                                                <div class="text text-danger" *ngIf="previouspreClearanceForm.controls.mobNo.errors?.pattern">Please, Enter 10 digit Contact Number.</div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="address">e) Address: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <textarea class="form-control" id="address" rows="3" formControlName="address" [(ngModel)]="dpaddress" disabled
                                                placeholder="Enter the Address" required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.address.touched) && previouspreClearanceForm.controls.address.errors }"></textarea>
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.address.touched) && previouspreClearanceForm.controls.address.errors?.required"
                                                    class="text text-danger">
                                                    Address is required.
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="categoryPerson">f) Category of Person: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <select class="form-select" class="form-control form-select" id="categoryPerson" formControlName="categoryPerson" (click)="otherCategoryOfPersonFormC()" required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.categoryPerson.touched) && previouspreClearanceForm.controls.categoryPerson.errors }">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Promoter">Promoter</option>
                                                    <option value="Member of the promoter group">Member of the promoter group</option>                                    
                                                    <option value="Designated person">Designated person</option>                                    
                                                    <option value="Directors">Directors</option>                                    
                                                    <option value="Immediate relative of">Immediate relative of</option>                                    
                                                    <option value="Others">Others</option>                                    
                                                </select>
                                                <div *ngIf="(submitted || previouspreClearanceForm.controls.categoryPerson.touched) && previouspreClearanceForm.controls.categoryPerson.errors?.required"
                                                class="text text-danger">Category of Person is required. </div>
                                            </div>
                                                                                  
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="otherscategoryPersonFormC">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="typeOfSecuritiesValue">Others Category of Person: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Category of Person" autocomplete="off" formControlName="otherscategoryofPerson"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-md-12">
                                    <h4>g) Securities held prior to acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecurities" required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.typeOfSecurities.touched) && previouspreClearanceForm.controls.typeOfSecurities.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.typeOfSecurities.touched) && previouspreClearanceForm.controls.typeOfSecurities.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding">                    
                                                        <input type="text" #sQty3 class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareNumber" (change)="onSelectedQty1C()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.perShareNumber.touched) && previouspreClearanceForm.controls.perShareNumber.errors }">
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.perShareNumber.touched) && previouspreClearanceForm.controls.perShareNumber.errors?.required"
                                                class="text text-danger">No. of share holding is required. </div>
                                                <div class="text text-danger" *ngIf="previouspreClearanceForm.controls.perShareNumber.errors?.pattern">Please, Enter Numbers only.</div>

                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">c) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage"> 
                                                        <input type="text" class="form-control" id="perShareHolding"  placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHolding"> <span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12">
                                    <div class="col-md-6">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="transactionType">h) Transaction Type: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="transactionType" formControlName="transactionType" (click)="otherTransactionTypeFormC()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.transactionType.touched) && previouspreClearanceForm.controls.transactionType.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <!-- <option value="Purchase">Purchase</option>
                                                        <option value="sale Pledge">sale Pledge</option>                                    
                                                        <option value="Revocation">Revocation</option>                                    
                                                        <option value="Invocation">Invocation</option>                           
                                                        <option value="Others">Others</option>                            -->
                                                        <option value="Sale">Sale</option>
                                                        <option value="Purchase">Purchase</option>                                    
                                                        <option value="Subscription">Subscription</option>                            
                                                    </select>
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.transactionType.touched) && previouspreClearanceForm.controls.transactionType.errors?.required"
                                                    class="text text-danger">Transaction Type is required. </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                        <div class="col-md-6" *ngIf="othersTransactionTypepre">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">Others Transaction Type: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Transaction Type" autocomplete="off" formControlName="otherstransactiontype"> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                                <div class="col-md-12">
                                    <h4>i) Securities acquired/Disposed</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities2">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities2" formControlName="typeOfSecurities2" required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.typeOfSecurities2.touched) && previouspreClearanceForm.controls.typeOfSecurities2.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.typeOfSecurities2.touched) && previouspreClearanceForm.controls.typeOfSecurities2.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 rowflex">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesNo">b) No. of securities acquired/ disposed: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="number" class="form-control" id="typeOfSecuritiesNo" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesNo"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.typeOfSecuritiesNo.touched) && previouspreClearanceForm.controls.typeOfSecuritiesNo.errors }">
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.typeOfSecuritiesNo.touched) && previouspreClearanceForm.controls.typeOfSecuritiesNo.errors?.required"
                                                        class="text text-danger">No. of securities acquired/ disposed is required. </div> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">c) Value of Securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="text" class="form-control" id="typeOfSecuritiesValue" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesValue"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.typeOfSecuritiesValue.touched) && previouspreClearanceForm.controls.typeOfSecuritiesValue.errors }">
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.typeOfSecuritiesValue.touched) && previouspreClearanceForm.controls.typeOfSecuritiesValue.errors?.required"
                                                        class="text text-danger">Value of Securities is required. </div> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <h4>j) Securities held post acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecuritiespost"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.typeOfSecuritiespost.touched) && previouspreClearanceForm.controls.typeOfSecuritiespost.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.typeOfSecuritiespost.touched) && previouspreClearanceForm.controls.typeOfSecuritiespost.errors?.required"
                                                    class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding ">                    
                                                        <input type="text" #sQty4 class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareHoldingNopost" (change)="onSelectedQty2C()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.perShareHoldingNopost.touched) && previouspreClearanceForm.controls.perShareHoldingNopost.errors }"> 
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.perShareHoldingNopost.touched) && previouspreClearanceForm.controls.perShareHoldingNopost.errors?.required"
                                                    class="text text-danger">No. of share holding is required. </div>
                                                    <div class="text text-danger" *ngIf="previouspreClearanceForm.controls.perShareHoldingNopost.errors?.pattern">Please, Enter Numbers only.</div>

                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">c) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage">                    
                                                        <input type="text" class="form-control" id="perShareHoldingpost" placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHoldingpost"><span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>k) Date of allotment advice/ acquisition of shares/ disposal of shares, specify</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="formDate">a) From: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="fromDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="fromDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.fromDate.touched) && previouspreClearanceForm.controls.fromDate.errors }"> 
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.fromDate.touched) && previouspreClearanceForm.controls.fromDate.errors?.required"
                                                    class="text text-danger">From Date is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="toDate">b) To: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="toDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="toDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.toDate.touched) && previouspreClearanceForm.controls.toDate.errors }">
                                                        <div *ngIf="(submitted || previouspreClearanceForm.controls.toDate.touched) && previouspreClearanceForm.controls.toDate.errors?.required"
                                                    class="text text-danger">To Date is required. </div>
                                                        <div *ngIf="previouspreClearanceForm.controls.toDate.touched && previouspreClearanceForm.controls.toDate.invalid" class="text text-danger" >Please add a valid from and To date </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="modeOfAcquisition">l) Mode of acquisition /disposal: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="modeOfAcquisition" formControlName="modeOfAcquisition" (click)="modeofAcquisitionTypeChanged()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || previouspreClearanceForm?.controls.modeOfAcquisition.touched) && previouspreClearanceForm.controls.modeOfAcquisition.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="On market">On market</option>
                                                        <option value="Public">Public</option>                                    
                                                        <option value="Rights">Rights</option>                                    
                                                        <option value="Preferential offer">Preferential offer</option>                                    
                                                        <option value="Off market">Off market</option>                                    
                                                        <option value="Inter-se transfer">Inter-se transfer</option>                                    
                                                        <option value="ESOPs">ESOPs</option>                                    
                                                        <option value="Others">Others</option>                                    
                                                    </select>      
                                                    <div *ngIf="(submitted || previouspreClearanceForm.controls.modeOfAcquisition.touched) && previouspreClearanceForm.controls.modeOfAcquisition.errors?.required"
                                                    class="text text-danger">Mode of acquisition /disposal is required. </div>                                     
                                                </div> 
                                        </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-12" *ngIf="othersModeofAcquisition">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="typeOfSecuritiesValue">Others Mode of acquisition /disposal: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="othersModeofAcquisition" placeholder="Enter the Others Mode of acquisition /disposal" autocomplete="off" formControlName="othersModeofAcquisition"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               
                                
    
                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="resetForm()" >Cancel</button>
                                 </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->