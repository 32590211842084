import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pdf-view-format',
  templateUrl: './pdf-view-format.component.html',
  styleUrls: ['./pdf-view-format.component.scss']
})
export class PdfViewFormatComponent implements OnInit {
  @Input() pdfTradingFormList;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getIdDp: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService ) { }

  ngOnInit() {
  //  console.log(this.pdfTradingFormList);
  // this.pdfFormTrading = this.fb.group({
  //   id: new FormControl(''),
  //   formTypes: new FormControl('')
  // });
 this.getIdDp = this.pdfTradingFormList.id;
 this.getformTypes = this.pdfTradingFormList.formTypes;
    
  }
  // revertInsiderId(){
  //   this.pdfFormTrading.value.id = this.getIdDp;
  //   this.pdfFormTrading.value.formTypes = this.getformTypes;
  //   this.dpService.revertDpModule(this.pdfFormTrading.value).subscribe(data =>{
  //     // if(data.message = "formTypes exists!"){
  //     //   this.toastr.warning("Already Exist the formTypes!");
  //     //   this.activeModal.close(event);
  //     // }else{
  //     this.toastr.success("DP Deleted data Updated ", "Success!");
  //     this.activeModal.close(event);
  //     this.router.navigate(['/dp/dp-list']);
  //     //}
  //   });
  // }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onFilterPdf() {
    
    let queryString = '';
    let url = this.baseUrl + 'pdfFormcandD?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.getIdDp || this.getformTypes )  {
      queryString = 'postId=' + this.getIdDp + '&formType=' + this.getformTypes ;
      queryString = queryString + '&authToken='+btoa(tokenStr);
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    this.activeModal.close(event);
     
  }

}
