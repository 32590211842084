<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header btnlist">
                    <!-- <h5>Basic Table</h5> -->
                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                    <div class="list-btn" *ngIf="listCompany?.length =='' ">
                        <a routerLink="/setup/company-add"><button class="btn btn-primary me-1" >New</button></a>
                    </div>
                    <div class="columnHide mb-3">
                        <label for="hideShow">Filter List Columns</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                [settings]="dropdownSettings1"
                                [data]="hidelist"
                                [(ngModel)]="selectedhideItems"
                                (onSelect)="onItemSelect($event,'column')"
                                (onDeSelect)="onItemDeSelect($event,'column')"
                                (onDeSelectAll)="onDeSelectAll($event,'column')"
                                (onSelectAll)="onSelectAll($event,'column')" 
                                >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="company name">Company Name</th>
                                    <th scope="col" sortable="email">Email ID</th>
                                    <th scope="col" sortable="mobile">Designation</th>
                                    <th scope="col" sortable="address">Registered Office</th>
                                    <th scope="col" sortable="mobile" *ngIf="show_contact">Contact Number</th>
                                    <th scope="col" sortable="address" *ngIf="show_cin">CIN Number</th>
                                    <th scope="col" sortable="action">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of listCompany; let i=index">
                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                <td scope="row" data-content="Company Name">{{items.company_name}}</td>
                                <td scope="row" data-content="Email ID">{{items.email}}</td>
                                <td scope="row" data-content="Designation">{{items.designation}}</td>
                                <td scope="row" data-content="Registered Office">{{items.registered_office}}</td>  
                                <td scope="row" data-content="Contact Number" *ngIf="show_contact">{{items.mobileno}}</td> 
                                <td scope="row" data-content="CIN Number" *ngIf="show_cin">{{items.cin}}</td> 
                                <td data-content="Actions"> 
                                    <button class="edit-icon" (click)="openModal(items.id)" title="View log"><i class="fa fa-eye"></i></button>
                                    <a [routerLink]="['/setup/company-edit', items.id]" title="Update"><button class="edit-icon"><i class="fa fa-pencil-square-o"></i></button></a>
                                </td>
                                  
                            </tr>
                            </tbody>
                        </table>

                        <div class="list-btn2" *ngIf="listCompany?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        
                       <!-- <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="listCompany?.length"></ngb-pagination>
                        </div> -->

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

