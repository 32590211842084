import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-trading-window-closure',
  templateUrl: './view-trading-window-closure.component.html',
  styleUrls: ['./view-trading-window-closure.component.scss']
})
export class ViewTradingWindowClosureComponent implements OnInit {
  @Input() viewTradingWindowList;
  html: any;


  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.html = this.viewTradingWindowList.htmlcontents
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
