

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">                             
                  <a routerLink="/upsi/upsi-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewUpsiList?.data[0]">
                                    <tr>
                                        <td scope="row">Project/Event Name</td>
                                        <td scope="row">{{viewUpsiList?.data[0].project_name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Name of Sender</td>
                                        <td scope="row">{{viewUpsiList?.data[0].sender}} - {{viewUpsiList?.data[0].SenderDPPanno}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Name of Receiver</td>
                                        <td scope="row">{{viewUpsiList?.data[0].recipient}} - {{viewUpsiList?.data[0].RecepientDPPanno}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Details of UPSI</td>
                                        <td scope="row">{{viewUpsiList?.data[0].remarks}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Mode of Sharing</td>
                                        <td scope="row">{{viewUpsiList?.data[0].mode_of_sharing}}</td>                                         
                                     </tr>
                                    
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewUpsiList?.data[0].purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Details of UPSI</td>
                                        <td scope="row">{{viewUpsiList?.data[0].nature_of_upsi}} <div *ngIf="viewUpsiList?.data[0].nature_of_upsi !== 'Others'" > {{viewUpsiList?.data[0].others_nature_upsi}}</div></td>                                         
                                     </tr>
                                       
                                     <!-- <tr>
                                        <td scope="row">Type of Sharing</td>
                                        <td scope="row">{{viewUpsiList?.data[0].type_of_sharing}}</td>                                         
                                     </tr>  -->
                                     <tr>
                                        <td scope="row">Date of Sharing</td>
                                        <td scope="row">{{viewUpsiList?.data[0].date_of_sharing | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">From Date</td>
                                        <td scope="row">{{viewUpsiList?.data[0].from_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Date when made public</td>
                                        <td scope="row">{{viewUpsiList?.data[0].to_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>  
                                     <!-- <tr>
                                        <td scope="row">Confidentiality Agreement entered</td>
                                        <td scope="row">{{viewUpsiList?.data[0].confidentiality_agreement_entered}}</td>                                         
                                     </tr>                                         
                                     <tr *ngIf="viewUpsiList?.data[0].confidentiality_agreement_entered == 'Yes' ">
                                        <td scope="row">Confidentiality Agreement date</td>
                                        <td scope="row">{{viewUpsiList?.data[0].confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>                                    -->
                                     <tr>
                                       <td scope="row">Username</td>
                                       <td scope="row">{{viewUpsiList.created_by}}</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Activity Date</td>
                                       <td scope="row">{{viewUpsiList.created_on}}</td>                                         
                                    </tr> 
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>