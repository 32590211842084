import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-roles',
  templateUrl: './delete-roles.component.html',
  styleUrls: ['./delete-roles.component.scss']
})
export class DeleteRolesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
