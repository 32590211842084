import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { ReportChartsComponent } from './report-charts/report-charts.component';
import { ViewReportComponent } from './view-report/view-report.component';
const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: ReportChartsComponent,
        data: {
          title: "Reports",
          breadcrumb: "Reports"
        }
      },
      {
        path: 'report',
        component: ReportChartsComponent,
        data: {
          title: "Reports",
          breadcrumb: "Reports"
        }
      },
      {
        path: 'view-report/:id',
        component: ViewReportComponent,
        data: {
          title: "Reports",
          breadcrumb: "Reports / View"
        }
      },  
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class reportRoutingModule { }
