import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { MasterService } from 'src/app/master/master.service';
import { InsiderServiceService } from '../insider-service.service';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { EditReasonComponent } from '../../master/project/edit-reason/edit-reason.component';

@Component({
  selector: 'app-insider-edit',
  templateUrl: './insider-edit.component.html',
  styleUrls: ['./insider-edit.component.scss']
})
export class InsiderEditComponent implements OnInit {
  isLoading = false;
  showButton = false;
  button = 'Update';
  insiderEditForm: FormGroup;
  insiderOldEditForm: FormGroup;
  insiderId: any;
  attachment: any;
  editReason = '';
  regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
  submitted:boolean = false;
  attachmentUrl: any;
  path: any;
  attachName: any;
  attachmentPath: boolean;
  relationshipDropdown =[];
  listProject: any;
  public active1 = 1;
  selectedOthers: any;
  othersSelect:boolean = false;
  othersInsiderSelect:boolean = false;
  othersInsiderSelectCin:boolean = false;
  relativeCompany: any;
  accessSuccess: string;
  image: string;
  urlLink: any;
  attachmentId: any;
  pathImage: any;
  othersSelectDic: boolean= false;
  othersSelectAccessUpsi: boolean  = false;
  othersSelectconnectPerson: boolean  = false;
  othersSelectFiduciary: boolean  = false;
  othersSelectInter: boolean  = false;
  othersInsiderNationSelect:boolean = false;
  d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ];
  
  p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ];
  baseUrl = environment.url;
  companyDetailsSelect: boolean;
  accessUser: string;
  constructor(private fb: FormBuilder, private insiderService:InsiderServiceService, private modalService: NgbModal, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService, private masterService: MasterService) {
    this.accessSuccess = sessionStorage.getItem('access_success');

    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    this.accessUser = sessionStorage.getItem('access_user');

   }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.insiderId = {'id' : data['id']}      
    })
    if(this.insiderId['id'] !==''){
      this.insiderService.editInsider(this.insiderId)
      .toPromise().then(result =>{ 
        this.attachmentId = result.data[0].id;
        
        this.selectedButton = result.data[0].confidentiality_agreement_entered;
        this.relativeCompany = result.data[0].relationship_with_company;
      this.urlLink = result.data[0]?.confidentiality_agreement_attachment;
     

      // if(this.accessUser == 'sdd@legalitysimplified.com'){         
      //   var eleman2 = document.getElementById('email');
      //   eleman2?.removeAttribute("disabled");   
      // }else{      
      //   var eleman2 = document.getElementById('email');
      //   eleman2.setAttribute("disabled", '');
      // }
        this.insiderEditForm?.patchValue(result.data[0]); 
        this.insiderOldEditForm?.patchValue(result.data[0]); 

        this.insiderEditForm.statusChanges.subscribe(status => {
          const isEqual = this.areObjectsEqual(this.insiderEditForm.value, this.insiderOldEditForm.value);
          if(!isEqual){
            this.showButton = true;
          }else{
            this.showButton = false;
          }
        });
        this.path = result.data[0].filePath;
        if(this.path ==''){
          this.attachmentPath = false;
        }else if(this.path == 'NULL'){
          this.attachmentPath = false;
        }else{
          this.attachmentPath = true;
        }
        this.relationshipPersonChanged(event);
        this.insiderTypeChanged();
        this.insiderNationalityChanged();
        //  this.changeListener(event);
        // this.filePath();
      })

    this.insiderEditForm = this.fb.group({
      // project: new FormControl ('', Validators.required),
      id: new FormControl (''),
      name: new FormControl ('',  [Validators.required, Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')]),
      insiderType: new FormControl ('',  Validators.required),
      cin: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      designation: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      relationship_with_company: new FormControl ('',  Validators.required),
      others : new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      // otherdic: new FormControl (''),
      // othersintermediary:  new FormControl (''),
      // othersfiduciary:  new FormControl (''),
      // othersconnectedperson:  new FormControl (''),
      // othersaccesstoupsi:  new FormControl (''),
      realationship_with_connected_person: new FormControl (''),
      email: new FormControl ('',  [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      //email: new FormControl ('',  [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      mobileNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      // phoneNo: ['',  Validators.required],
      panNo: new FormControl ('',  [Validators.required]),
      DocumentType : new FormControl ('',  [Validators.required]),
      fileName: new FormControl (''),
      filePath: new FormControl(this.filePath),
      image:new FormControl (''),   
      address: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')]),
      nationality: new FormControl (''),
      others_nationality: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      // designated_person:new FormControl ('No', Validators.required),
      confidentiality_agreement_entered: new FormControl ('Yes',  Validators.required),
      confidentiality_agreement_date: new FormControl (''),
      remarks: new FormControl('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
      company_name: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),  
      isinnumber: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')), 
      company_email: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')),
      company_pan: new FormControl(''),
      company_contact_no: new FormControl('', Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")),
      company_address: new FormControl('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&]+$')),
      company_cin: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$'))

    });
    this.insiderOldEditForm = this.fb.group({
      // project: new FormControl ('', Validators.required),
      id: new FormControl (''),
      name: new FormControl ('',  [Validators.required, Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')]),
      insiderType: new FormControl ('',  Validators.required),
      cin: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      designation: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      relationship_with_company: new FormControl ('',  Validators.required),
      others : new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      // otherdic: new FormControl (''),
      // othersintermediary:  new FormControl (''),
      // othersfiduciary:  new FormControl (''),
      // othersconnectedperson:  new FormControl (''),
      // othersaccesstoupsi:  new FormControl (''),
      realationship_with_connected_person: new FormControl (''),
      email: new FormControl ('',  [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      //email: new FormControl ('',  [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      mobileNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      // phoneNo: ['',  Validators.required],
      panNo: new FormControl ('',  [Validators.required]),
      DocumentType : new FormControl ('',  [Validators.required]),
      fileName: new FormControl (''),
      filePath: new FormControl(this.filePath),
      image:new FormControl (''),   
      address: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&]+$')]),
      nationality: new FormControl (''),
      others_nationality: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      // designated_person:new FormControl ('No', Validators.required),
      confidentiality_agreement_entered: new FormControl ('Yes',  Validators.required),
      confidentiality_agreement_date: new FormControl (''),
      remarks: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),
      company_name: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')),  
      isinnumber: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$')), 
      company_email: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')),
      company_pan: new FormControl(''),
      company_contact_no: new FormControl('', Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")),
      company_address: new FormControl('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&]+$')),
      company_cin: new FormControl('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&]+$'))

    });
    this.masterService.projectList(null).subscribe(
      res => {
     
      this.listProject = res.body['data']     
        },
        err => {
            console.log(err);
        }
    );
  }
  //Relationship with Company
this.relationshipDropdown = [
  { listItem: 'AMC of mutual fund'  },
  { listItem: 'Asset Management Company'  },
  { listItem: 'Associate Company' },
  { listItem: 'Auditor'  },
  { listItem: 'Statutory Auditor'  },
  { listItem: 'Internal Auditor'  },
  { listItem: 'Cost Auditor'  },
  { listItem: 'Secretarial Auditor'  },
  { listItem: 'Banker'  },
  { listItem: 'Clearing House'  },
  { listItem: 'Director'},
  { listItem: 'Director of insider company'  },
  { listItem: 'Employee'  },
  { listItem: 'Entity with 10%+ investment from director'},
  { listItem: 'Holding Company' },
  { listItem: 'Immediate Relative of Connected Person' },
  { listItem: 'Investment Company'  },
  { listItem: 'KMP'  },
  { listItem: 'Legal Firm ' },
  { listItem: 'Liquidator'  },
  { listItem: 'Mutual Fund'  },
  { listItem: 'Official of Stock Exchange'  },
  { listItem: 'Other Connected Person'  },
  { listItem: 'Other Fiduciary'  },
  { listItem: 'Other Intermediary'  },
  { listItem: 'Other Person with access to UPSI' } ,
  { listItem: 'Promoter'  },  
  { listItem: 'Public Financial Institution'  },
  { listItem: 'Stock Broker'  },  
  { listItem: 'Subsidiary Company'  },   
  { listItem: 'Trustee Company'  }  
  ]
  }
  base64Output : string;

  filePath(){
    if(this.insiderEditForm.get('fileName').value != ''){
    this.attachName = this.insiderEditForm.get('fileName').value;
   
    this.path =  this.attachName[0].name;
    
    // const Path = this.insiderEditForm.get('filePath').value;
    // Path.push(this.path)
    // console.log("Path", Path)
    }
    this.attachmentPath = true;
  }
  removeFile(){
    this.insiderEditForm.get('fileName').setValue("");
    this.path = "";
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;
    
  }
  changeListener($event) : void {   
    this.readThis($event?.target);
    this.filePath()
    this.insiderEditForm.get('fileName')?.setValue(File);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue?.files[0];
   
    var myReader:FileReader = new FileReader();
    // 
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());   
      this.insiderEditForm.value.image=myReader.result;
      this.pathImage = this.insiderEditForm.value.image;
    }
  }
  onFileSelected(event) {
    this.filePath();
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    // reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }
     //select radio button
 selectedButton: string;        

 radioSelect(e: string): void {
     this.selectedButton = e; 
  if(this.selectedButton !== "Yes"){
    this.insiderEditForm.get('fileName').setValue("");
    this.insiderEditForm.get('confidentiality_agreement_date').setValue("");
    this.insiderEditForm.get('filePath').setValue("");
    this.attachmentPath = false;
  }
 }  
 
 isSelected(name: string): boolean {  
 
     if (!this.selectedButton) {   
         return false;  
     }    
 
     return (this.selectedButton === name); 
 }
 hideValues(){
 
 }

insiderTypeChanged() {
  let selectedOthers = this.insiderEditForm.get('insiderType') 
  let insidertypename =  selectedOthers.value;   
  if(selectedOthers?.value == "Individual"){
    this.othersInsiderSelect = true;
    this.othersInsiderSelectCin = false;
    this.insiderEditForm.get('cin').setValue("");
  }else if(selectedOthers?.value == "Company"){
    this.othersInsiderSelectCin = true;
    this.othersInsiderSelect = false;
    this.insiderEditForm.get('designation').setValue("");
    this.insiderEditForm.get('nationality').setValue("");
  }else if(selectedOthers?.value == "LLP"){
    this.othersInsiderSelectCin = true;
    this.othersInsiderSelect = false;
    this.insiderEditForm.get('designation').setValue("");
    this.insiderEditForm.get('nationality').setValue("");
  }else{
    this.othersInsiderSelectCin = false;
    this.othersInsiderSelect = false;
    this.insiderEditForm.get('designation').setValue("");
    this.insiderEditForm.get('nationality').setValue("");
    this.insiderEditForm.get('cin').setValue("");
  }
  this.relationshipPersonChanged(insidertypename)
}
 // nationality
 insiderNationalityChanged() {
  let selectedOthers = this.insiderEditForm.get('nationality');    
  if(selectedOthers?.value == "Others"){
    this.othersInsiderNationSelect = true;
    }else{
      this.othersInsiderNationSelect = false;
      this.insiderEditForm.get('others_nationality').setValue('');
  }
}
relationshipPersonChanged(e) {
  
  this.selectedOthers = this.insiderEditForm.get('relationship_with_company')
  let insiderType = this.insiderEditForm.get('insiderType');
  if((insiderType?.value == "Individual" && (this.selectedOthers?.value == "Associate Company" || this.selectedOthers?.value == "Auditor" 
  || this.selectedOthers?.value == "Statutory Auditor" || this.selectedOthers?.value == "Internal Auditor" || this.selectedOthers?.value == "Cost Auditor" || this.selectedOthers?.value == "Secretarial Auditor" || this.selectedOthers?.value == "Banker"))){
    this.companyDetailsSelect = true;
  }else{
    this.companyDetailsSelect = false;
    this.insiderEditForm.get('company_name').setValue('');
    this.insiderEditForm.get('company_email').setValue('');
    this.insiderEditForm.get('isinnumber').setValue('');
    this.insiderEditForm.get('company_pan').setValue('');
    this.insiderEditForm.get('company_contact_no').setValue('');
    this.insiderEditForm.get('company_address').setValue('');

  }
  if(this.selectedOthers.value == "Immediate Relative of Connected Person"){
    this.othersSelect = true;
  }else{
    this.othersSelect = false;
    this.insiderEditForm.get('realationship_with_connected_person').setValue('');

  }
 //Director of insider company
 if(this.selectedOthers?.value == "Director of insider company" ||this.selectedOthers?.value == "Other Intermediary" 
 || this.selectedOthers?.value == "Other Fiduciary" || this.selectedOthers?.value == "Other Connected Person" ||
 this.selectedOthers?.value == "Other Person with access to UPSI"){
  this.othersSelectDic = true;
}else{
  this.othersSelectDic = false;
  this.insiderEditForm.get('others').setValue('');
  
}
  //Other Intermediary
  // if(this.selectedOthers?.value == "Other Intermediary"){
  //   this.othersSelectInter = true;
  // }else{
  //   this.othersSelectInter = false;
  //   this.insiderEditForm.get('others').setValue('');

  // }
    //Other Fiduciary
// if(this.selectedOthers?.value == "Other Fiduciary"){
//   this.othersSelectFiduciary = true;
//   console.log("Hi",this.selectedOthers?.value);

// }else{
//   console.log("Hello",this.selectedOthers?.value);

//   this.othersSelectFiduciary = false;
//   this.insiderEditForm.get('others').setValue('');

// }
  //Other Connected Person
  // if(this.selectedOthers?.value == "Other Connected Person"){
  //   this.othersSelectconnectPerson = true;
  // }else{
  //   this.othersSelectconnectPerson = false;
  //   this.insiderEditForm.get('others').setValue('');

  // }
    //Other Person with access to UPSI
// if(this.selectedOthers?.value == "Other Person with access to UPSI"){
//   this.othersSelectAccessUpsi = true;
// }else{
//   this.othersSelectAccessUpsi = false;
//   this.insiderEditForm.get('others').setValue('');

// }
}
//AAdharValidation
validate(addharNumber) {
  let c = 0;
  let invertedArray = addharNumber
    .split('')
    .map(Number)
    .reverse();

  invertedArray.forEach((val, i) => {
    c = this.d[c][this.p[i % 8][val]];
  });

  return c === 0;
}
  cancelInsider(){
    this.router.navigate(['/insider/insider-list']);
    this.insiderEditForm.reset();
    this.submitted = false;
  }
  areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);
  
    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};
  
    for (const key in objA) {
      if(key != 'id'){
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {
          
            resultA[key] = objA[key];
          
        }
      }
    }
  
    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }
  
    return {
      "oldData": resultA,
      "newData": resultB
    };
  }
  openModalEditReason() {
    if(this.insiderEditForm.controls.email.errors?.pattern != undefined){
      this.toastr.warning('Your Email Id is InValid')
      return false;
    }else if(this.insiderEditForm.controls.mobileNo?.errors?.pattern != undefined){
      this.toastr.warning('Enter Contact Number accept number format only')
      return false;
    }
       let idendiferCard = this.insiderEditForm.get('panNo').value;
      if(this.insiderEditForm.get('DocumentType').value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.insiderEditForm.get('DocumentType').value == 'Aadhar card'){
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
          
        } else {
          this.toastr.warning('Your Aadhar Number is InValid')
          return false;      
        }
      }

      }else if(this.insiderEditForm.get('DocumentType').value == 'Voter ID'){
        let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Voter ID');
          return false;
        }
      }else if(this.insiderEditForm.get('DocumentType').value == 'Driving License'){
        let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
          if(!regex.test(idendiferCard)){
            this.toastr.warning('Please Enter Valid Driving License');
            return false;
          }
      }
      if(this.insiderEditForm.get('confidentiality_agreement_entered').value == 'Yes'){
        if(this.insiderEditForm.get('confidentiality_agreement_date').value == null || this.insiderEditForm.get('confidentiality_agreement_date').value == ''){
          this.toastr.warning('Please Enter the Confidentiality Agreement Date');
          return false;
        }
      }
      let insiderType = this.insiderEditForm.get('insiderType');
      let selectedOthers = this.insiderEditForm.get('relationship_with_company')
      if(insiderType?.value == "Individual"){
        if(this.insiderEditForm.get('designation').value == ''){
          this.toastr.warning('Please Enter the Designation');
          return false;
        }else if(this.insiderEditForm.get('nationality').value == ''){
          this.toastr.warning('Please Enter the Nationality');
          return false;
        }
      }else if(insiderType?.value == "Company" || insiderType?.value == "LLP"){
        if(this.insiderEditForm.get('cin').value == ''){
          this.toastr.warning('Please Enter the CIN Number');
          return false;
        }
      }

      if((insiderType?.value == "Individual" && (selectedOthers?.value == "Associate Company" || selectedOthers?.value == "Auditor"   || selectedOthers?.value == "Statutory Auditor" || 
      selectedOthers?.value == "Internal Auditor" || selectedOthers?.value == "Cost Auditor" || selectedOthers?.value == "Secretarial Auditor" || selectedOthers?.value == "Banker"))){
        if(this.insiderEditForm.get('company_name').value == ''){
          this.toastr.warning("Please fill the Entity Name.");
          return false;
        }else if(this.insiderEditForm.get('company_email').value == ''){
          this.toastr.warning("Please fill the Entity Email ID.");
          return false;
        }else if(this.insiderEditForm.get('company_pan').value == ''){
          this.toastr.warning("Please fill the Entity PAN No.");
          return false;
        }else if(this.insiderEditForm.get('company_contact_no').value == ''){
          this.toastr.warning("Please fill the Entity Contact Number.");
          return false;
        }else if(this.insiderEditForm.get('company_address').value == ''){
          this.toastr.warning("Please fill the Entity Address.");
          return false;
        }else if(this.insiderEditForm.controls.company_email.errors?.pattern != undefined){
          this.toastr.warning('Your Entity Email Id is InValid')
          return false;
        }else if(this.insiderEditForm.controls.company_contact_no?.errors?.pattern != undefined){
          this.toastr.warning('Enter Entity Contact Number accept number format only')
          return false;
        }
      }
      const emptyFileds = Object.keys(this.insiderEditForm.controls).filter(key =>this.insiderEditForm.controls[key].errors !== null)
      if(emptyFileds.length > 1){
        this.toastr.warning("Please fill all Mandatory fields.");
        return false;
      } else{
         if(this.insiderEditForm.get('name').value == ''){
          this.toastr.warning("Please fill the Name of Insider.");
          return false;
        }
        else if(this.insiderEditForm.get('insiderType').value == ''){
          this.toastr.warning("Please fill the Insider Type.");
          return false;
        }
        else if(this.insiderEditForm.get('relationship_with_company').value == ''){
          this.toastr.warning("Please fill the Relationship with Company.");
          return false;
        }
        else if(this.insiderEditForm.get('email').value == ''){
          this.toastr.warning("Please fill the Email ID.");
          return false;
        }
        else if(this.insiderEditForm.get('mobileNo').value == ''){
          this.toastr.warning("Please fill the Contact Number.");
          return false;
        }
        else if(this.insiderEditForm.get('DocumentType').value == ''){
          this.toastr.warning("Please fill the  ID Proof Type.");
          return false;
        }
        else if(this.insiderEditForm.get('panNo').value == ''){
          this.toastr.warning("Please fill the ID Proof No.");
          return false;
        }
        else if(this.insiderEditForm.get('address').value == ''){
          this.toastr.warning("Please fill the Address.");
          return false;
        }
      }
    const isEqual = this.areObjectsEqual(this.insiderEditForm.value, this.insiderOldEditForm.value);
    if(!isEqual){
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        // console.log("result", result);
        this.editReason=result;
        if(this.editReason != 'dismiss'){
          this.onSubmit();
        }
      }, (reason) => {
      });
    }
    
  }
    onSubmit(){
      const changedKeyValues = this.findChangedKeyValues(this.insiderOldEditForm.value,this.insiderEditForm.value);
        
      let reqObj = {
        data:changedKeyValues,
        id:this.insiderEditForm.value.id,
        editReason:this.editReason
      }
   
      
      this.submitted = true;  

      this.insiderEditForm.value.id = this.attachmentId;
      if(this.insiderEditForm.status !== 'INVALID'){
        this.isLoading = true;
        this.button = 'Processing';
      this.insiderService.updateInsider(reqObj).subscribe(data =>{
        if(data.success == '1'){
            this.toastr.success("Insider Successfully Updated")
            this.isLoading = false;
            this.button = 'Update';
            this.router.navigate(['/insider/insider-list']);
        }else{
          this.toastr.warning(data?.message);
            this.isLoading = false;
            this.button = 'Update';
        }
      })
    }else{
      this.toastr.warning("Invalid Field....");
    }
  }
}
