<div class="modal-header">
    <h5 class="modal-title">Declaration of Trade</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1" *ngIf="showAllDetails">
                        <h4 style="font-size: 19px;">Confirm to send email?</h4>
                        <div class="custom-datatable">
                            <table class="table">                            
                                    <tbody *ngIf="sendMailTradingWindowList">
                                        <tr>
                                            <td scope="row">ID</td>
                                            <td scope="row">DoT - {{sendMailTradingWindowList.id}}</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Start Date</td>
                                            <td scope="row">{{sendMailTradingWindowList.start_date | date:'dd-MM-yyyy'}}</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">End Date</td>
                                            <td scope="row">{{sendMailTradingWindowList.end_date | date:'dd-MM-yyyy'}}</td>                                         
                                         </tr>
                                        <tr>
                                        <tr>
                                            <td scope="row">Sender Name</td>
                                            <td scope="row">{{senderItem}}</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Recipient Type</td>
                                            <td scope="row">{{sendMailTradingWindowList.recipient_type}}</td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Recipient Name</td>
                                            <td scope="row" ><p style="text-align: left;" *ngFor = "let item of recipientItem">{{item}}</p></td>                                         
                                         </tr>
                                         <tr>
                                            <td scope="row">Period</td>
                                            <td scope="row">{{sendMailTradingWindowList.period}}</td>                                         
                                         </tr> 
                                        <tr>
                                            <td scope="row">Subject</td>
                                            <td scope="row">{{sendMailTradingWindowList.subject}}</td>                                         
                                         </tr> 
                                         <tr>
                                            <td scope="row">Body Content</td>
                                            <td scope="row" [innerHTML]="html"></td>                                         
                                         </tr> 
                                                                                                                                            
                                   
                                </tbody>
                            </table>
                        </div>
                        <div class="btn-bottom">
                            <button class="btn btn-primary me-1" (click)="sendMailTwc()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>
                        </div>
                    </div>

                    <div class="custom-datatable1" *ngIf="showMailDetails">
                        <h4 style="font-size: 19px;">Confirmation Report for Emails</h4>
                        <div class="custom-datatable">
                            <svg version="1.1" xmlns="" viewBox="0 0 130.2 130.2">
                                <circle class="path circle" fill="none" stroke="#eb7808" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                <polyline class="path check" fill="none" stroke="#eb7808" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                              </svg>
                              <h6>Declaration of Trade Email Sent Successfully!</h6>
                                <table class="table">                            
                                    <tbody>
                                        
                                        <tr *ngIf="senderMailStatus">
                                            <td scope="row">Sender Mail ID</td>
                                            <td scope="row">{{senderMailStatus}}</td>                                         
                                         </tr>
                                       
                                        </tbody>
                                    </table><br>
                                    <div class="custom-datatable">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="s.no" >S.No.</th>
                                                    <th scope="col" sortable="id.no">Recipient Mail ID</th>
                                                    <th scope="col" sortable="id.no">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor =" let item of mailStatuslist | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                                    <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                                    <td scope="row">{{item.recipient}}</td>
                                                    <td scope="row">{{item.mail_status}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            
                        </div>
                        <br /><br />
                        <!-- <div>
                            <h6 style="text-decoration: underline;text-align: left;">Trading Window Mail Template</h6>
                            <div [innerHTML]="twcMailTemplate"></div>
                        </div> -->

                        <div class="btn-bottom" *ngIf="showAllDetails">
                            <button class="btn btn-primary me-1" (click)="sendMailTwc()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer" *ngIf="showMailDetails">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
