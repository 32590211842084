import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }

  insiderListCount(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + 'insider/insiderCount', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }

  dpListCount(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + 'dp/dpCount', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }

  upsiListCount(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + 'upsi/upsiCount',userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  projectListCount(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseUrl + 'project/projectCount', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
//upsi
  upsiDashboardActivity(userObj:any):Observable<any>{
    // return this.http.get(this.baseUrl + "upsiActivity", {
    //   headers: {'Authorization': sessionStorage.getItem('access_token')},
      
    // });
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
    return this.http.post(this.baseUrl + 'upsi/upsiActivity', userObj, {
  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  noupsiDashboardActivity(userObj:any):Observable<any>{   
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
    return this.http.post(this.baseUrl + 'upsi/noupsiActivity', userObj, {
  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
//Insider
insiderDashboardActivity(userObj:any) {
  // return this.http.get(this.baseUrl + 'upsi/list')
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'insider/insiderActivity', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
//Dp
dpModuleDashboardActivity(userObj:any) {
  // return this.http.get(this.baseUrl + 'upsi/list')
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'dp/dpActivity', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
projectModuleDashboardActivity(userObj:any) {
  // return this.http.get(this.baseUrl + 'upsi/list')
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'project/dashboardprojectactivity', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}

}
