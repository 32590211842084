import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportChartsComponent } from './report-charts/report-charts.component';
import { reportRoutingModule } from './report-routing.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewReportComponent } from './view-report/view-report.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PopupModelReportComponent } from './popup-model-report/popup-model-report.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { DisclaimerpdfComponent } from './disclaimerpdf/disclaimerpdf.component';
@NgModule({
  declarations: [
    ReportChartsComponent,
    ViewReportComponent,
    PopupModelReportComponent,
    DisclaimerpdfComponent
  ],
  imports: [
    CommonModule,
    reportRoutingModule,
    Ng2GoogleChartsModule,
    NgChartsModule,
    ChartistModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    AutocompleteLibModule,
    NgSelectModule
  ]
})
export class ReportModule { }
