<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card">
                                        
                                       <!-- <h4 class="head" *ngIf="viewNoUpsi == 'Non UPSI Communication'">This communication is determined to be Not Unpublished Price Sensitive Information (Not UPSI) for the following reason:</h4> -->
                                       
                                            <div class="card-body custom-datatable noscroll">
                                                <div class="custom-datatable">
                                                    <form [formGroup]="noUpsiCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                                                        <div class="row">
                                                            <div class="col-sm-12 col-xl-12">
                                                               
                                                                    <div class="col-md-12 mb-3">
                                                                        <label for="nameOfInsider" *ngIf="viewNoUpsi == 'Non UPSI Communication'">Reason for marking communication as <b>Not UPSI</b></label>
                                                                        <label for="nameOfInsider" *ngIf="viewNoUpsi != 'Non UPSI Communication'">Reason for marking communication as <b>UPSI</b></label>
                                                                        <!-- <input type="text" class="form-control"  id="reasonformove" placeholder="Reason to Move {{confupsilist}}" formControlName="reasonformove" autocomplete="off" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || noUpsiCreateForm.controls.reasonformove.touched) && noUpsiCreateForm.controls.reasonformove.errors }">  -->
                                                                        <textarea class="form-control" id="reasonformove" rows="5" formControlName="reasonformove" autocomplete="off" required
                                                                        placeholder="{{confupsilist}}" [ngClass]="{ 'is-invalid': (submitted || noUpsiCreateForm.controls.reasonformove.touched) && noUpsiCreateForm.controls.reasonformove.errors }"></textarea>
                                                                        <div *ngIf="(submitted || noUpsiCreateForm.controls.reasonformove.touched) && noUpsiCreateForm.controls.reasonformove.errors?.required"
                                                                        class="text text-danger">
                                                                        Please mention the {{confupsilist}}
                                                                        </div>                                      
                                                                    </div>
                                                                    <div class="submit-btn">
                                                                        <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                                                        <!-- <button class="btn btn-warning" type="button" (click)="closeModal('close')">No</button>                     -->
                                                                     </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                        
                        
                                        </div>
                                    
                                </div>
                            </div>
                         </div>
                        <div *ngIf="itemSelected?.length > 0">
                            <!-- <h4 style="font-size: 19px;margin-bottom: 2rem;line-height: 1.4;">We Found There are {{itemSelected.length}} More UPSI's logs Associted to the same communication. Kindly confirm to proceed further? </h4> -->
                            <h4 style="font-size: 17px;line-height: 1.4;font-weight: 400;"><b>Please note:</b></h4>
                            <p style="margin-bottom: 2rem;margin-left: 1rem;" *ngIf="viewNoUpsi == 'Non UPSI Communication'">- The reason for marking this as Not UPSI shall be recorded for audit purposes. <br> - This decision shall automatically apply to below records that are associated with this communication due to multiple recipients or senders:</p>
                            <p style="margin-bottom: 2rem;margin-left: 1rem;" *ngIf="viewNoUpsi != 'Non UPSI Communication'">- The reason for marking this as UPSI shall be recorded for audit purposes. <br> - This decision shall automatically apply to below records that are associated with this communication due to multiple recipients or senders:</p>
                            <table class="table">
                                <thead>
                                    <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="id no" >ID No.</th>
                                    <th scope="col" sortable="Project Name" >Project Name</th>
                                    <th scope="col" sortable="Sender Name" >Sender Name</th>
                                    <th scope="col" sortable="Recipient Name" >Recipient Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor = "let items of itemSelected | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                        <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                        <td scope="row">UPSI-{{items?.upsi_id }}</td>
                                        <td scope="row">{{items?.project_name}}</td> 
                                        <td scope="row">{{items?.SenderDPName}} - {{items?.sender}} - {{items?.SenderDPPanno}}</td>
                                        <td scope="row">{{items?.RecepientDPName}} - {{items?.recipient}} - {{items?.RecepientDPPanno}}</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                            <div class="list-btn2" *ngIf="itemSelected?.length =='' ">
                                <p>No Data Found</p>
                            </div>
    
                           <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="itemSelected?.length"></ngb-pagination>
                            </div>
                        </div>
                        <!-- <h4 style="font-size: 19px;">Are you sure want to download the PDF?</h4> -->
                        <div class="btn-bottom">
                            <p><b>Note:</b> Email will be automatically send to the sender, recipient and creator.</p>
                            <!-- <button class="btn btn-primary me-1" value="Yes" (click)="chooseAllItem('Yes')">Yes</button> -->
                            <!-- <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
