import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CompanyViewComponent } from '../company-view/company-view.component';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  listCompany: any;
  public selected = [];
  page = 1;
  pageSize = 10;
  accessSuccess: string;
  hidelist = [{"id":1,"name":"Contact Number"},{"id":2,"name":"CIN Number"}];
  selectedhideItems = [];
  selected_contactNo:any;
  selected_cin:any;
  show_contact=false;
  show_cin=false;
  dropdownSettings1:IDropdownSettings;
  constructor(private companyService: CompanyService, private router:Router, private modalService: NgbModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.companyService.CompanyList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.listCompany = res.body['data']     
      sessionStorage.setItem('companyDetails', this.listCompany[0]?.customertype);
      sessionStorage.setItem('complianceofficeremail', this.listCompany[0]?.complianceofficeremail);
        }
        },
        err => {
            console.log(err);
        }
    );
   
         //multiseclector
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  }; 
  }

  onItemSelect(item: any,page) {
    if(page == "Contact Number"){
     if(!this.selected_contactNo.includes(item)){
       this.selected_contactNo.push(item);
     }
   }else if(page == "Address"){
     if(!this.selected_cin?.includes(item)){
       this.selected_cin.push(item);
     }
   }else if(page == "column"){
     if(item.id == 1){
       this.show_contact = true;
     }else if(item.id == 2){
       this.show_cin = true;
     }
   }
  }
  
  onItemDeSelect(item: any,page) {
  
  if(page == "Contact Number"){
     if(this.selected_contactNo.includes(item)){
       this.selected_contactNo.splice(this.selected_contactNo.indexOf(item), 1);
     }
   }else if(page == "Address"){
     if(this.selected_cin.includes(item)){
       this.selected_cin.splice(this.selected_cin.indexOf(item), 1);
     }
   }else if(page == "column"){
     if(item.id == 1){
       this.show_contact = false;
     }else if(item.id == 2){
       this.show_cin = false;
     }
   }
   
  }
  onSelectAll(items: any,page) {
   if(page == "Contact Number"){
     this.selected_contactNo=items;
   }else if(page == "Address"){
     this.selected_cin=items;
   }else if(page == "column"){
     this.show_contact = true;
     this.show_cin = true; 
   }
   
  }
  onDeSelectAll(items: any,page) {
  if(page == "Contact Number"){
     this.selected_contactNo=[];
   }else if(page == "Address"){
     this.selected_cin=[];
   }else if(page == "column"){
     this.show_contact = false;
     this.show_cin = false;
   }
  }
  openModal(id:any) {
    if (id) {
      let companyid ={'id': id}
      this.companyService.viewCompany(companyid).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(CompanyViewComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewCompanyList = data[0];
          modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
      })
  
      }
    
  }

}
