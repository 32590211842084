<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>BENPOS Report</h5>
                    <a routerLink="/pit-compliance/benpos-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                 </div>
                <div class="card-body">
                    <form [formGroup]="banPassForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="cols check-line">
                                    <!-- <label for="nationality">Access to SDD</label> -->
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio2" id="radioinline3" value="Excel"
                                            formControlName="selectradiobtn"  (click)="radioSelect('excel')">
                                            <label for="radioinline3" class="mb-0">Excel Upload</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio2" id="radioinline4" value="PDF" formControlName="selectradiobtn" (click)="radioSelect('pdf')">
                                            <label for="radioinline4" class="mb-0">PDF Upload</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio2" id="radioinline5" value="Text File" formControlName="selectradiobtn" (click)="radioSelect('text')">
                                            <label for="radioinline5" class="mb-0">Text File Upload</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected('excel')">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>Excel Upload File</label>
                                                        <div class="form-group input-group">
                                                            <input #excelattachment type="file" class="custom-file-input form-control"  accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="attachment" (change)="addfile($event)"  formControlName="excel" > 
                                                            <!-- <input type="file" (change)="onFileChange($event)" (click)="next()" multiple="false" /> -->
                                                        </div>
                                                        <div class="progress" *ngIf="exprogressExcel">
                                                            <div class="progress-bar" [style.width]="exprogressExcel + '%'">{{exprogressExcel}}%</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-3">
                                                        <label for="benf_date">Benf Date</label>
                                                        <div class="form-group input-group">
                                                        <input class="form-control " placeholder="yyyy-mm-dd"  type="date" formControlName="benf_date" >                                  
                                                        </div>  
                                                                        
                                                    </div>
                                                   
                                                </div>
                                                
                                                <!-- <div class="scrool-items" id="excel-report" >
                                                    <table class="sjs-table" *ngIf="show_excel">
                                                        <tbody>
                                                      <tr *ngFor="let row of data">
                                                            <td *ngFor="let val of row">
                                                                {{val}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    </table>
                                                </div> -->
                                        
                                            </div>
                                        </div>


                                        </div>

                                        <div class="bottom-field" *ngIf="isSelected('pdf')">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>PDF Upload File</label>
                                                    <div class="form-group input-group">
                                                        <input #userPhoto type="file" class="custom-file-input form-control" accept=".pdf" id="attachment" (change)="pdfOnload($event)" formConrtrolName="pdf" > 
                                                        
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <!-- <div class="scrool-items" id="pdf-report" *ngIf="show_pdf">
                                                <div class="pdf-container">
                                                    <pdf-viewer
                                                      [src]="pdfSrc"
                                                      [rotation]="0"
                                                      [original-size]="false"
                                                      [show-all]="true"
                                                      [fit-to-page]="false"
                                                      [zoom]="0.8"
                                                      [zoom-scale]="'page-width'"
                                                      [stick-to-page]="false"
                                                      [render-text]="false"
                                                      [external-link-target]="'blank'"
                                                      [autoresize]="true"
                                                      [show-borders]="true"
                                                      style="width: 100%; height: calc(100vh - 100px);"
                                                    ></pdf-viewer>
                                            </div>
                                        </div> -->

                                        </div>
                                        <div class="bottom-field" *ngIf="isSelected('text')">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-md-4 mb-3">
                                                        <label for="uploadfiletype">Upload File Type</label>
                                                        <div class="form-group">                                  
                                                             <select class="form-select" class="form-control form-select"
                                                                        id="uploadfiletype" formControlName="uploadfiletype" (change)="fileTypeChanged()">
                                                                        <option value="" selected>--Choose One --</option>
                                                                        <option value="Benf NSDL">Benf_NSDL</option>
                                                                        <option value="Benf CDSL">Benf_CDSL</option>                                                                        
                                                                    </select>                               
                                                        </div> 
                                                   
                                                    </div>
                                                    <div class="col-md-4 mb-3" *ngIf="show_upload_file">
                                                        <label for="benf_date">ISIN Number</label>
                                                        <div class="form-group input-group">
                                                        <input class="form-control " placeholder="ISIN Number"  type="text" disabled formControlName="isin_number" [(ngModel)]="isinNumber" >                                  
                                                        </div>                   
                                                    </div>
                                                        <div class="col-md-4 mb-3" *ngIf="show_upload_file">
                                                            <label for="benf_date">Benf Date</label>
                                                            <div class="form-group input-group">
                                                            <input class="form-control " placeholder="yyyy-mm-dd"  type="date" formControlName="benf_date" >                                  
                                                            </div>  
                                                                            
                                                        </div>
                                                        <div class="col-md-4 mb-3" >
                                                            <label>Upload File</label>
                                                            <div class="form-group input-group">
                                                                <!-- <input type="file" class="custom-file-input form-control" accept=".txt" id="attachment" (change)="onChange($event)"  >  -->
                                                                <input type="file" #benfattachment class="custom-file-input form-control" accept=".txt" id="attachment" (change)="onChange($event)" formControlName="uploadFile" > 

                                                            </div>
                                                            <!-- <mat-progress-bar mode="determinate" [value]="percentagevalue" ></mat-progress-bar> {{totalPercentage}} -->
                                                            <div class="progress" *ngIf="progress">
                                                                <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                                                            </div>
                                                        </div>
                                                   
                                                </div>
                                                <!-- <div class="scrool-items" id="txt-report" *ngIf="show_field">
                                                    <pre class="textReader" > {{ fileContent }} </pre>
                                                </div> -->
                                                <!-- <ul>
                                                    <li *ngFor="let user of userArray">
                                                        {{user | json}}
                                                     </li>
                                                </ul> -->
                                            </div>

                                        </div>

                                </div>
                            </div>
                            <p *ngIf="aleartMessage" style="font-size: 1rem;"><span style="color: #ff9f40 ;"><span *ngIf="banPassForm.get('uploadfiletype').value != ''">{{banPassForm.get('uploadfiletype').value }} -</span> Benpos Upload Success Records = {{successMessage}}, </span> <span style="color: red;"><span *ngIf="banPassForm.get('uploadfiletype').value !=''">{{banPassForm.get('uploadfiletype').value }} -</span> Benpos Upload Failure Records = {{failureMessage - successMessage}}</span> </p>
                            <div class="bottom-btn">
                                <button type="submit" [disabled]="isLoading"  class="btn btn-primary"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                                <button type="button" class="btn btn-primary" (click)="resetBenposs()">Reset</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>

       

    </div>
</div>
<!-- Container-fluid Ends -->
