import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-delete-mail-tempate',
  templateUrl: './delete-mail-tempate.component.html',
  styleUrls: ['./delete-mail-tempate.component.scss']
})
export class DeleteMailTempateComponent implements OnInit {
  twcId: any;
  accessSuccess: string;
  twcReasonDeleteForm: FormGroup;
  getId: any;
  @Input() viewMailTemplateList;
  isLoading = false;
  button = 'Delete';
  submitted = false;
  templateName: any;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private masterService: MasterService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }
  ngOnInit(): void { 

    this.twcReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('',  Validators.required)
    });
   this.getId = this.viewMailTemplateList.id;
   this.templateName = this.viewMailTemplateList.templatename;
  }
  onSubmit(){
    this.submitted = true;
    this.twcReasonDeleteForm.value.id = this.getId;
    if(this.twcReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';
    this.masterService.mailTemplateDelete(this.twcReasonDeleteForm.value).subscribe(data =>{
      console.log(this.twcReasonDeleteForm.value )
      this.toastr.success(this.templateName + " Successfully deleted");
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      window.location.reload();
      
    })
  }else{
    if(this.twcReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting " + this.templateName + ".");
    }
  }

  }
  
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }
}
