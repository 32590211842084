import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginService } from './auth/login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html? v1',
  styleUrls: ['./app.component.scss']
})
// export class AppComponent implements OnDestroy
export class AppComponent  {
  title = 'INSIDER';
  key: string;

  constructor(private bnIdle: BnNgIdleService, public router: Router, private http:HttpClient,public loginService: LoginService){}
  ngOnInit(): void {
    this.bnIdle.startWatching(60000).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('sdd_user_permission');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('access_success');
        sessionStorage.removeItem('sdd_access_roles');
        this.bnIdle.stopTimer();
        this.loginService.doLogout(event);
        this.router.navigate(['/auth/login'])    
        
      }
    });
    
     //console.log(window.location.hostname)

  }
  // @HostListener('window:unload')
  // async ngOnDestroy()
  // {
  //   await this.loginService.doLogout(event);
  // }

//   @HostListener('window:keydown', ['$event'])
//   handleHotkey(event: KeyboardEvent){
//     this.key = event.key;
//    event.preventDefault();
//  }


//  @HostListener('document:keypress', ['$event'])
// handleKeyboardEvent(event: KeyboardEvent) {
//     this.key = event.key;
//     console.log("00",this.key);
//     event.preventDefault();
// }
}

