<div class="modal-header">
    <h5 class="modal-title">Pre Clearance PDF Download </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <h4 style="font-size: 19px;">Are you sure want to download the PDF?</h4>
                        <div class="btn-bottom">
                            <button class="btn btn-primary me-1" (click)="onFilterPdf()">Yes</button>
                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
