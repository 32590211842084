<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs tab-active2">
          <li [ngbNavItem]="1" >
            <a ngbNavLink>Form C</a>
            <ng-template ngbNavContent>
            <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                <div class="container-fluid">
                    <div class="row">
                   
                        <div class="card">
                            <div class="card-header">
                                <!-- <h5>Form C Report Table</h5> -->
                                <div class="list-btn" >
                                    <div class="columnHide mb-3">
                                        <div class="filter-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                                [settings]="dropdownSettings1"
                                                [data]="hidelist"
                                                [(ngModel)]="selectedhideItems"
                                                (onSelect)="onItemSelect($event,'formC')"
                                                (onDeSelect)="onItemDeSelect($event,'formC')"
                                                (onDeSelectAll)="onDeSelectAll($event,'formC')"
                                                (onSelectAll)="onSelectAll($event,'formC')" 
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <a [routerLink]="['/pit-compliance/add-trading-report', 'Form C']"><button class="btn btn-primary me-1">New</button></a>
                                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                                </div>
                                <div class="filterdataclass">
                                  <div class="col-md-6 mb-3">                      
                                      <div class="form-group">
                                          <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                                            >                                                                    
                                      </div>
                                    </div>
                                  </div>                                           
                
                                <!-- <div class="excel-report">            
                                   
                                    <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="onFilter()" alt="Download Overview Excel Report" title="Download Overview Excel Report">
                                </div> -->
                         </div>
                          <div class="card-body">
                                <table class="table" id="formTable">
                                    <thead>
                                        <tr>
                                            <th scope="col" sortable="s.no" rowspan="2" >S.No.</th>
                                            <th scope="col" sortable="cName" rowspan="2" *ngIf="show_cname">Company Name <i class="fa fa-filter" (click)="filterData()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="companyNameFilter"
                                                            [searchKeyword]="keyword" placeholder="Search ..."
                                                            (selected)='selectEvent($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="companyFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="name" rowspan="2" *ngIf="show_name">Name <i class="fa fa-filter" (click)="filterData2()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible2">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="nameFilter"
                                                            [searchKeyword]="keyword2" placeholder="Search ..."
                                                            (selected)='selectEvent2($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="pan" rowspan="2" *ngIf="show_pan">PAN <i class="fa fa-filter" (click)="filterData3()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible3">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="panNameFilter"
                                                            [searchKeyword]="keyword3" placeholder="Search ..."
                                                            (selected)='selectEvent3($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="panNameFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="contactNo" rowspan="2" *ngIf="show_cnumber">Contact Number</th>
                                            <th scope="col" sortable="stock" rowspan="2"*ngIf="show_stock">Stock Exchange <i class="fa fa-filter" (click)="filterData4()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible4">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="exchangeTradeNameFilter"
                                                            [searchKeyword]="keyword4" placeholder="Search ..."
                                                            (selected)='selectEvent4($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="exchangeTradenameFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="address" rowspan="2"*ngIf="show_address">Address</th>
                                            <th scope="col" sortable="cin" rowspan="2"*ngIf="show_cin">CIN/DIN</th>
                                            <th scope="col" sortable="cperson" rowspan="2"*ngIf="show_cperson">Contact Person</th>
                                            <th scope="col" sortable="prior" colspan="3" *ngIf="show_prior">Securities Prior to acquisition</th>
                                            <th scope="col" sortable="acquired" colspan="4" *ngIf="show_acquired">Securities acquired/Disposed</th>
                                            <th scope="col" sortable="post" colspan="3" *ngIf="show_post">Securities post acquisition/ disposal</th>
                                            <th scope="col" sortable="advice" colspan="2" *ngIf="show_advice">Date of allotment advice</th>
                                            <th scope="col" sortable="intimate" rowspan="2"*ngIf="show_intimate">Date of intimation</th>
                                            <th scope="col" sortable="mode" rowspan="2"*ngIf="show_mode">Mode of acquisition <i class="fa fa-filter" (click)="filterData9()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible9">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="modeAcquisitionNameFilter"
                                                            [searchKeyword]="keyword9" placeholder="Search ..."
                                                            (selected)='selectEvent9($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="modeAcquisitionFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="Action" rowspan="2">Action</th>
                                        </tr>
                                        <tr>
                                            <th scope="col" sortable="s.no" *ngIf="show_prior">Type of securities <i class="fa fa-filter" (click)="filterData5()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible5">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="securitiesPriorNameFilter"
                                                            [searchKeyword]="keyword5" placeholder="Search ..."
                                                            (selected)='selectEvent5($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="securitiesPriorFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="cName" *ngIf="show_prior">No. of share holding</th>
                                            <th scope="col" sortable="name" *ngIf="show_prior">% of share holding</th>
                                            <th scope="col" sortable="s.no" *ngIf="show_acquired">Type of securities <i class="fa fa-filter" (click)="filterData6()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible6">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="securitiesAcquiredNameFilter"
                                                            [searchKeyword]="keyword6" placeholder="Search ..."
                                                            (selected)='selectEvent6($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="securitiesAcquiredFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="cName" *ngIf="show_acquired">No.</th>
                                            <th scope="col" sortable="name" *ngIf="show_acquired">Value</th>
                                            <th scope="col" sortable="name" *ngIf="show_acquired">Transaction Type <i class="fa fa-filter" (click)="filterData7()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible7">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="transactionTypeNameFilter"
                                                            [searchKeyword]="keyword7" placeholder="Search ..."
                                                            (selected)='selectEvent7($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="transactionTypeFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="s.no" *ngIf="show_post">Type of securities <i class="fa fa-filter" (click)="filterData8()"
                                                aria-hidden="true"></i>
                                            <div class="filter-form" *ngIf="visible8">
                                                <div class="filter-group">                                             
                                                    <div class="ng-autocomplete">
                                                        <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                        <ng-autocomplete #auto [data]="securitiesPostNameFilter"
                                                            [searchKeyword]="keyword8" placeholder="Search ..."
                                                            (selected)='selectEvent8($event)'
                                                            (inputChanged)='onChangeSearch($event)'
                                                            (inputFocused)='onFocused($event)'
                                                            (inputCleared)="onCleared($event)"
                                                            (scrolledToEnd)='onScrolledEnd()' 
                                                            [(ngModel)]="securitiesPostFilterString" [itemTemplate]="itemTemplate"
                                                            [notFoundTemplate]="notFoundTemplate" focus>
                                                        </ng-autocomplete>
        
                                                        <ng-template #itemTemplate let-item>
                                                            <a [innerHTML]="item"></a>
                                                        </ng-template>
        
                                                        <ng-template #notFoundTemplate let-notFound>
                                                            <div [innerHTML]="notFound"></div>
                                                        </ng-template>
                                                    </div>
        
                                                </div>
                                            </div></th>
                                            <th scope="col" sortable="cName" *ngIf="show_post">No. of share holding</th>
                                            <th scope="col" sortable="name" *ngIf="show_post">% of share holding</th>
                                            <th scope="col" sortable="cName" *ngIf="show_advice">From Date</th>
                                            <th scope="col" sortable="name" *ngIf="show_advice">To Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor = "let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                            <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                            <td scope="row" *ngIf="show_cname">{{item.companyName}}</td>                    
                                            <td scope="row" *ngIf="show_name">{{item.name}}</td>
                                            <td scope="row" *ngIf="show_pan">{{item.pan_no}}</td>
                                            <td scope="row" *ngIf="show_cnumber">{{item.mobNo}}</td>
                                            <td scope="row" *ngIf="show_stock">{{item.exchangeTrade}}</td> 
                                            <td scope="row" *ngIf="show_address">{{item.address}}</td> 
                                            <td scope="row" *ngIf="show_cin">{{item.cin_din}}</td> 
                                            <td scope="row" *ngIf="show_cperson">{{item.categoryPerson}}</td> 
                                            <td scope="row" *ngIf="show_prior">{{item.typeOfSecurities}}</td>
                                            <td scope="row" *ngIf="show_prior">{{item.perShareNumber}}</td>
                                            <td scope="row" *ngIf="show_prior">{{item.perShareHolding}}</td> 
                                            <td scope="row" *ngIf="show_acquired">{{item.typeOfSecurities2}}</td>
                                            <td scope="row" *ngIf="show_acquired">{{item.typeOfSecuritiesNo}}</td>
                                            <td scope="row" *ngIf="show_acquired">{{item.typeOfSecuritiesValue}}</td> 
                                            <td scope="row" *ngIf="show_acquired">{{item.transactionType}}</td> 
                                            <td scope="row" *ngIf="show_post">{{item.typeOfSecuritiespost}}</td>
                                            <td scope="row" *ngIf="show_post">{{item.perShareHoldingNopost}}</td>
                                            <td scope="row" *ngIf="show_post">{{item.perShareHoldingpost}}</td> 
                                            <td scope="row" *ngIf="show_advice">{{item.from_date}}</td>
                                            <td scope="row" *ngIf="show_advice">{{item.to_date}}</td> 
                                            <td scope="row" *ngIf="show_intimate">{{item.intimate_date}}</td> 
                                            <td scope="row" *ngIf="show_mode">{{item.modeOfAcquisition}}</td> 

                                            <td class="icon">
                                                <button class="edit-icon" 
                                                         title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye" ></i></button>
                                                 <a [routerLink]="['/pit-compliance/edit-trading-report', item.id]"><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                                 <button class="edit-icon" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button>
                                                 <button class="edit-icon" title="Download PDF" ><i class="fa fa-file-pdf-o"></i></button>         
                                             </td>                   
                                            
                                        </tr>
                                        
                                        </tbody>
                                </table>
                                <div class="list-btn2" *ngIf="filtered?.length =='' || formCListItem?.length == '' ">
                                    <p>No Data Found...</p>
                                </div> 
                
                                 <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                                </div>
                            </div>
                        </div>
                
                       
                    </div>
                </div>  

            </div>
            </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Form D</a>
                <ng-template ngbNavContent>
                  <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                    <!-- Container-fluid starts -->
                    <div class="container-fluid">
                        <div class="row">
                       
                            <div class="card">
                                <div class="card-header">
                                    <!-- <h5>Form D Report Table</h5> -->
                                    <div class="list-btn" >
                                        <div class="columnHide mb-3">
                                            <div class="filter-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                                    [settings]="dropdownSettings1"
                                                    [data]="hidelist2"
                                                    [(ngModel)]="selectedhideItems2"
                                                    (onSelect)="onItemSelect($event,'formD')"
                                                    (onDeSelect)="onItemDeSelect($event,'formD')"
                                                    (onDeSelectAll)="onDeSelectAll($event,'formD')"
                                                    (onSelectAll)="onSelectAll($event,'formD')" 
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <a [routerLink]="['/pit-compliance/add-trading-report', 'Form D']"><button class="btn btn-primary me-1">New</button></a>
                                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                                    </div>
                                    <div class="filterdataclass">
                                      <div class="col-md-6 mb-3">                      
                                          <div class="form-group">
                                              <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString2" autocomplete="off" (ngModelChange)="onFilterChange2()"  placeholder="Search ..." 
                                                >                                                                    
                                          </div>
                                        </div>
                                      </div>                                           
                    
                                    <!-- <div class="excel-report">            
                                       
                                        <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="onFilter()" alt="Download Overview Excel Report" title="Download Overview Excel Report">
                                    </div> -->
                             </div>
                              <div class="card-body">
                                    <table class="table" id="formTableD">
                                        
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="s.no" rowspan="2" >S.No.</th>
                                                    <th scope="col" sortable="cName" rowspan="2" *ngIf="show_cnameD">Company Name <i class="fa fa-filter" (click)="filterDataD()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visibleD">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="companyNameFilterD"
                                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                                    (selected)='selectEventD($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="companyFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="name" rowspan="2" *ngIf="show_nameD">Name <i class="fa fa-filter" (click)="filterData2D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible2D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="nameFilterD"
                                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                                    (selected)='selectEvent2D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="nameFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="pan" rowspan="2" *ngIf="show_panD">PAN <i class="fa fa-filter" (click)="filterData3D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible3D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="panNameFilterD"
                                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                                    (selected)='selectEvent3D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="panNameFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="contactNo" rowspan="2" *ngIf="show_cnumberD">Contact Number</th>
                                                    <th scope="col" sortable="stock" rowspan="2"*ngIf="show_stockD">Stock Exchange <i class="fa fa-filter" (click)="filterData4D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible4D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="exchangeTradeNameFilterD"
                                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                                    (selected)='selectEvent4D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="exchangeTradenameFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="address" rowspan="2"*ngIf="show_addressD">Address</th>
                                                    <th scope="col" sortable="cin" rowspan="2"*ngIf="show_cinD">CIN/DIN</th>
                                                    <th scope="col" sortable="prior" colspan="3" *ngIf="show_priorD">Securities Prior to acquisition</th>
                                                    <th scope="col" sortable="acquired" colspan="4" *ngIf="show_acquiredD">Securities acquired/Disposed</th>
                                                    <th scope="col" sortable="post" colspan="3" *ngIf="show_postD">Securities post acquisition/ disposal</th>
                                                    <th scope="col" sortable="advice" colspan="2" *ngIf="show_adviceD">Date of allotment advice</th>
                                                    <th scope="col" sortable="intimate" rowspan="2"*ngIf="show_intimateD">Date of intimation</th>
                                                    <th scope="col" sortable="mode" rowspan="2"*ngIf="show_modeD">Mode of acquisition <i class="fa fa-filter" (click)="filterData9D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible9D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="modeAcquisitionNameFilterD"
                                                                    [searchKeyword]="keyword9" placeholder="Search ..."
                                                                    (selected)='selectEvent9D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="modeAcquisitionFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="Action" rowspan="2">Action</th>
                                                </tr>
                                                <tr>
                                                    <th scope="col" sortable="s.no" *ngIf="show_priorD">Type of securities <i class="fa fa-filter" (click)="filterData5D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible5D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="securitiesPriorNameFilterD"
                                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                                    (selected)='selectEvent5D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="securitiesPriorFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="cName" *ngIf="show_priorD">No. of share holding</th>
                                                    <th scope="col" sortable="name" *ngIf="show_priorD">% of share holding</th>
                                                    <th scope="col" sortable="s.no" *ngIf="show_acquiredD">Type of securities <i class="fa fa-filter" (click)="filterData6D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible6D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="securitiesAcquiredNameFilterD"
                                                                    [searchKeyword]="keyword6" placeholder="Search ..."
                                                                    (selected)='selectEvent6D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="securitiesAcquiredFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="cName" *ngIf="show_acquiredD">No.</th>
                                                    <th scope="col" sortable="name" *ngIf="show_acquiredD">Value</th>
                                                    <th scope="col" sortable="name" *ngIf="show_acquiredD">Transaction Type <i class="fa fa-filter" (click)="filterData7D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible7D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="transactionTypeNameFilterD"
                                                                    [searchKeyword]="keyword7" placeholder="Search ..."
                                                                    (selected)='selectEvent7D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="transactionTypeFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="s.no" *ngIf="show_postD">Type of securities <i class="fa fa-filter" (click)="filterData8D()"
                                                        aria-hidden="true"></i>
                                                    <div class="filter-form" *ngIf="visible8D">
                                                        <div class="filter-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlurD($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="securitiesPostNameFilterD"
                                                                    [searchKeyword]="keyword8" placeholder="Search ..."
                                                                    (selected)='selectEvent8D($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onClearedD($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                                    [(ngModel)]="securitiesPostFilterStringD" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>
                
                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>
                
                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>
                
                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="cName" *ngIf="show_postD">No. of share holding</th>
                                                    <th scope="col" sortable="name" *ngIf="show_postD">% of share holding</th>
                                                    <th scope="col" sortable="cName" *ngIf="show_adviceD">From Date</th>
                                                    <th scope="col" sortable="name" *ngIf="show_adviceD">To Date</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor = "let item of filtered2 | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                                <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                                 <td scope="row" *ngIf="show_cnameD">{{item.companyName}}</td>                    
                                                <td scope="row" *ngIf="show_nameD">{{item.name}}</td>
                                                <td scope="row" *ngIf="show_panD">{{item.pan_no}}</td>
                                                <td scope="row" *ngIf="show_cnumberD">{{item.mobNo}}</td>
                                                <td scope="row" *ngIf="show_stockD">{{item.exchangeTrade}}</td> 
                                                <td scope="row" *ngIf="show_addressD">{{item.address}}</td> 
                                                <td scope="row" *ngIf="show_cinD">{{item.cin_din}}</td> 
                                                <td scope="row" *ngIf="show_priorD">{{item.typeOfSecurities}}</td>
                                                <td scope="row" *ngIf="show_priorD">{{item.perShareNumber}}</td>
                                                <td scope="row" *ngIf="show_priorD">{{item.perShareHolding}}</td> 
                                                <td scope="row" *ngIf="show_acquiredD">{{item.typeOfSecurities2}}</td>
                                                <td scope="row" *ngIf="show_acquiredD">{{item.typeOfSecuritiesNo}}</td>
                                                <td scope="row" *ngIf="show_acquiredD">{{item.typeOfSecuritiesValue}}</td> 
                                                <td scope="row" *ngIf="show_acquiredD">{{item.transactionType}}</td> 
                                                <td scope="row" *ngIf="show_postD">{{item.typeOfSecuritiespost}}</td>
                                                <td scope="row" *ngIf="show_postD">{{item.perShareHoldingNopost}}</td>
                                                <td scope="row" *ngIf="show_postD">{{item.perShareHoldingpost}}</td> 
                                                <td scope="row" *ngIf="show_adviceD">{{item.from_date}}</td>
                                                <td scope="row" *ngIf="show_adviceD">{{item.to_date}}</td> 
                                                <td scope="row" *ngIf="show_intimateD">{{item.intimate_date}}</td> 
                                                <td scope="row" *ngIf="show_modeD">{{item.modeOfAcquisition}}</td> 
                                                <td class="icon">
                                                    <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
                                                     <a [routerLink]="['/pit-compliance/edit-trading-report', item.id]"
                                                         ><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                                    <button class="edit-icon" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button>
                                                    <button class="edit-icon" title="Download PDF" (click)="openModalPdf(item.id)"><i class="fa fa-file-pdf-o"></i></button>
                                                                
                                                 </td>      
                                            </tr>
                                            </tbody>
                                    </table>
                                    <div class="list-btn2" *ngIf="filtered2?.length =='' || formDListItem?.length == '' ">
                                        <p>No Data Found...</p>
                                    </div>  
                    
                                   <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered2?.length"></ngb-pagination>
                                    </div>
                                </div>
                            </div>
                    
                           
                        </div>
                    </div>
                  </div>
                </ng-template>
            </li>
         
            
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>




