<div class="modal-header">
    <h5 class="modal-title">View Pre Clearance</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">   
                            <tbody *ngIf="viewPreClearanceList">
                                    <tr>
                                        <td scope="row">Id</td>
                                        <td scope="row">PC - {{viewPreClearanceList.id}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Name of designated person</td>
                                        <td scope="row">{{viewPreClearanceList.dpname}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">PAN</td>
                                        <td scope="row">{{viewPreClearanceList.dppan}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Designation</td>
                                        <td scope="row">{{viewPreClearanceList.dpdesignation}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Category of Person</td>
                                        <td scope="row">{{viewPreClearanceList.categoryofperson}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewPreClearanceList.categoryofperson == 'Others'">
                                        <td scope="row">Other Category of Person</td>
                                        <td scope="row">{{viewPreClearanceList.otherscategoryofperson}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Format of Application for pre-clearance</td>
                                        <td scope="row">{{viewPreClearanceList.application_for}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewPreClearanceList.application_for =='Relative' ">
                                        <td scope="row">Name of Relative</td>
                                        <td scope="row">{{viewPreClearanceList.relativename}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewPreClearanceList.application_for =='Relative' ">
                                        <td scope="row">Relationship</td>
                                        <td scope="row">{{viewPreClearanceList.dpRelationship}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewPreClearanceList.application_for =='Relative' ">
                                        <td scope="row">PAN of relative</td>
                                        <td scope="row">{{viewPreClearanceList.relativepan}}</td>                                         
                                     </tr> 
                                                                                               
                               
                            </tbody>
                           
                        </table>
                    </div>
                    <div class="custom-datatable">
                     <h4 style="text-decoration: underline;font-size: 1rem;">Proposed Transaction in Securities of the Company</h4>
                     <table class="table">   
                         <tbody *ngIf="viewPreClearanceList">
                                 <tr *ngIf="viewPreClearanceList.tradingcompanyname != ''">
                                    <td scope="row">Trading Company Name</td>
                                    <td scope="row">{{viewPreClearanceList.tradingcompanyname}}</td>                                         
                                 </tr>
                                 <tr>
                                     <td scope="row">DP ID / Client ID No</td>
                                     <td scope="row">{{viewPreClearanceList.dp_client_id}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Name of Depository</td>
                                     <td scope="row">{{viewPreClearanceList.nameofdepository}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Number of securities held as on date</td>
                                     <td scope="row">{{viewPreClearanceList.noofsecurities}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Type of Security</td>
                                     <td scope="row">{{viewPreClearanceList.typeofsecurity}}</td>                                         
                                  </tr> 
                                  <tr *ngIf="viewPreClearanceList == 'Others' ">
                                    <td scope="row">Others Type of Security</td>
                                    <td scope="row">{{viewPreClearanceList.otherstypeofsecurity}}</td>                                         
                                 </tr> 
                                  <!-- <tr>
                                     <td scope="row">Proposed date of dealing in securities</td>
                                     <td scope="row">{{viewPreClearanceList.proposeddate | date:'dd-MM-yyyy'}}</td>                                         
                                  </tr>  -->
                                  <tr >
                                     <td scope="row">Nature of proposed transaction</td>
                                     <td scope="row">{{viewPreClearanceList.nature_of_proposed}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Estimated number of securities proposed to be acquired/ subscribed/ sold</td>
                                     <td scope="row">{{viewPreClearanceList.Estimatednumbersecur}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Current market price (as on date of application)</td>
                                     <td scope="row">{{viewPreClearanceList.currentmarketprice}}</td>                                         
                                  </tr> 
                                 
                                  <tr>
                                     <td scope="row">Price at which transaction is proposed</td>
                                     <td scope="row">{{viewPreClearanceList.transactionisproposed}}</td>                                         
                                  </tr> 
                                  <tr>
                                     <td scope="row">Whether the proposed transaction will be through</td>
                                     <td scope="row">{{viewPreClearanceList.whethertransactionproposed}}</td>                                         
                                  </tr> 
                                  <tr *ngIf="viewPreClearanceList.whethertransactionproposed == 'stock exchange' ">
                                     <td scope="row">Name of the Stack Exchange</td>
                                     <td scope="row">{{viewPreClearanceList.proposedbuyer}}</td>                                         
                                  </tr> 
                                  <tr *ngIf="viewPreClearanceList.whethertransactionproposed == 'off-market deal' ">
                                     <td scope="row">Name of the Proposed Buyer/ Seller (for off-market trade)</td>
                                     <td scope="row">{{viewPreClearanceList.proposedbuyer}}</td>                                         
                                  </tr> 
                                   
                                                                                                   
                            
                         </tbody>                        
                        
                     </table>
                 </div>
                 
                  <div class="custom-datatable">
                     <h4 style="text-decoration: underline;font-size: 1rem;">Details of previous transactions and Pre-clearance:</h4>
                     <table class="table">   
                        <tbody *ngIf="viewPreClearanceList">
                                 <tr>
                                    <td scope="row">Previous Pre-clearance ID</td>
                                    <td scope="row">{{viewPreClearanceList.previouspcid}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Number of Shares for which approval was granted</td>
                                    <td scope="row">{{viewPreClearanceList.number_of_shares}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Date when the approval was granted</td>
                                    <td scope="row">{{viewPreClearanceList.Date_when_approval | date:'dd-MM-yyyy'}}</td>                                         
                                 </tr> 
                                 
                                                                                          
                           
                        </tbody>                        
                        
                     </table>
               </div>
                  <div class="custom-datatable">
                     <h4 style="text-decoration: underline;font-size: 1rem;">Details of last transaction</h4>
                     <table class="table">   
                        <tbody *ngIf="viewPreClearanceList">
                              
                                 <tr>
                                    <td scope="row">Nature of transaction</td>
                                    <td scope="row">{{viewPreClearanceList.nature_of_transaction}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Date of transaction</td>
                                    <td scope="row">{{viewPreClearanceList.date_of_transaction | date:'dd-MM-yyyy'}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">No. of securities transacted</td>
                                    <td scope="row">{{viewPreClearanceList.securities_transacted}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Whether the transaction was executed or not?</td>
                                    <td scope="row">{{viewPreClearanceList.transaction_executed}}</td>                                         
                                 </tr>                                 
                                 <tr>
                                    <td scope="row">Remarks</td>
                                    <td scope="row">{{viewPreClearanceList.last_transaction}}</td>                                         
                                 </tr>                                                     
                           
                        </tbody>                        
                        
                     </table>
                  </div>

                  <div class="checkbox">
                     <h4 style="text-decoration: underline;">In connection with my request for this pre-clearance of the trade, I hereby declare & undertake that:</h4>
                     <p>I Declare that I am not in possession of or knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Company's Code of Conduct upto the time of issuing this undertaking</p>
                     <p>In the event that I am in possession/ knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Code of Conduct, after the signing of this undertaking but before executing the transaction for which approval is sought, I shall inform the Compliance Officer of the same and shall completely refrain from dealing in the securities of the Company until such information becomes public;</p>
                     <p>I have not contravened the Company’s Code of Conduct for Prohibition of Trading by Insiders and Code for Fair Disclosure of Unpublished Price Sensitive Information.</p>
                     <p>I have made full and true disclosure in the matter.</p>
                     <p>In case the traded value exceeds Rs. 10 lakh or any such other value as may be specified, disclosures required under the Code of Conduct for Prohibition of Trading by Insiders will be immediately furnished by me.</p>
                     <p>I hereby agree to indemnify and keep the Company and its Board indemnified from and against all and any penalties/fines that may be imposed on them by the SEBI and/or any other Statutory Authorities as a result of violation by me of the Regulation of the Codes prescribed by the Bank.</p>
                     <p>I hereby declare that I shall execute my order in respect of the securities for which preclearance is sought within {{predaycount}} trading days after the approval is given. If the order is not executed within {{predaycount}} trading days after the approval, I undertake to obtain fresh pre-clearance.</p>
                     <p>I have not done any prior contra trade within the period of six months and shall not execute a contra trade for a period of 6 months from the date of current transaction.</p>
                     <p>I have made full and true disclosure in the above matter.</p>
                  </div>
                  
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
