import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-disclaimerpdf',
  templateUrl: './disclaimerpdf.component.html',
  styleUrls: ['./disclaimerpdf.component.scss']
})
export class DisclaimerpdfComponent implements OnInit {
  @Input() pdfapprovedOrder;
  @Input() title;
  public active1 = 1;
  baseUrl = environment.apiUrl;
  @Output() onSubmitEntry = new EventEmitter();
  isLoading = false;
  button = 'Yes';
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit() {
 

  }
 
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

  onFilterPdf(value){
 
    this.onSubmitEntry.emit(value);
   
    this.activeModal.close(event);
  }
}
