import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';
import { PreClearanceMailTemplateComponent } from '../pre-clearance-mail-template/pre-clearance-mail-template.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { DisclosureExistTradingComponent } from '../disclosure-exist-trading/disclosure-exist-trading.component';
import { formatDate } from '@angular/common';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-disclosure-preclearance',
  templateUrl: './disclosure-preclearance.component.html',
  styleUrls: ['./disclosure-preclearance.component.scss']
})
export class DisclosurePreclearanceComponent implements OnInit {
  @Input() preClearancemailTemplate;
  previouspreClearanceForm: FormGroup;
  isLoading = false;
  button = 'Save';
  submitted = false;
  responseDate: any;
  pcId: any;
  PcEditId: any;
  dpNameFilter: string;
  accessUserControl: string;
  listPreviousPreClearShare = [];
  listPreviousPreClearValue = [];
  totalshares: number;
  totalValues: number;
  preclearanceShares: any;
  preclearanceValue: any;
  dp_client_id: any;
  nature_of_proposed: any;
  errorSharesMessage: string;
  errorValuesMessage: string;
  preClearanceDataList: any;
  tradingAllowed:boolean = true;
  tradingNotAllowed:boolean = false;
  dpdesignation: any;
  approved_date: any;
  afterseventhday:any;
  curr_date: string;
  complianceOfficerEmail: any;
  complianceOfficerName: any;
  complianceOfficerDesignation: any;
  companyName: any;
  predaycount: any;
  preDayCounts: any;
  accessModelController: any;
  tradingcompanyname: any;
  typeofsecurity: any;
  constructor(private toastr: ToastrService, public router: Router, private fb: FormBuilder, private dpService:DpModuleService,
    private pitService: PitComplianceService, private companyService: CompanyService,private modalService: NgbModal, private activateRouter:ActivatedRoute) {
      this.accessUserControl = sessionStorage.getItem('access_user');
      this.predaycount = sessionStorage.getItem('predaycount');
      
     }

  ngOnInit(): void {
    let accessData = sessionStorage.getItem('model_access_controller');    
    this.accessModelController = JSON.parse(accessData);

    this.companyService.CompanyList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      let listCompany = res.body['data']     
          this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
          this.complianceOfficerName = listCompany[0].compliance_officer_name;    
          this.complianceOfficerDesignation = listCompany[0].designation;    
          this.companyName = listCompany[0].company_name; 
          // this.predaycount = listCompany[0].predaycount; 
          // console.log("this.predaycount", this.predaycount)
        }
        },
        err => {
            console.log(err);
        }
    );


    let currdate = new Date();

    this.activateRouter.params.subscribe(data =>{
      this.pcId = {'id' :data['id']}      
    })
    if(this.pcId['id'] !==''){
      this.pitService.preClearanceView(this.pcId)
      .toPromise().then(result =>{ 
        this.preClearanceDataList = result.data[0];
        this.PcEditId = result.data[0].id;
        this.preclearanceShares = result.data[0].Estimatednumbersecur;
        this.preclearanceValue = result.data[0].transactionisproposed;
        this.dpNameFilter = result.data[0].dpname;
        this.nature_of_proposed = result.data[0].nature_of_proposed;
        this.dp_client_id = result.data[0].dp_client_id;
        this.dpdesignation = result.data[0].dpdesignation;
        this.tradingcompanyname = result.data[0].tradingcompanyname;
        this.typeofsecurity = result.data[0].typeofsecurity;
        let approved_date = result.data[0].approved_date;
console.log(result.data[0]);
        this.approved_date = formatDate(approved_date, 'yyyy-MM-dd', 'en-US');
        let date = new Date(approved_date);

        if(Number(this.predaycount) == 0 || this.predaycount =='' || this.predaycount == null){
          this.preDayCounts = 7;
        }else{
          this.preDayCounts = this.predaycount;
        }
        let count = this.preDayCounts - 1;
        date.setDate(date.getDate() + Number(count) );

        let sevenDays = new Date(date);
        this.afterseventhday = formatDate(sevenDays, 'yyyy-MM-dd', 'en-US');
        this.curr_date = formatDate(currdate, 'yyyy-MM-dd', 'en-US');
        
        
        if(currdate > sevenDays){
          this.tradingAllowed = false;
          this.tradingNotAllowed = true;
        
        }else{
          this.tradingAllowed = true;
          this.tradingNotAllowed = false;
        }
        this.pitService.listPreviousPreClear(null).subscribe(
        res => {
              let listPreviousPreClear = res['data'];             

            let remainingtradingpreclearance = [];
            this.listPreviousPreClearShare = [];
            this.listPreviousPreClearValue = [];
              listPreviousPreClear.map(data =>{
                if(data.preclearance_id === this.PcEditId){              
                
                this.listPreviousPreClearShare.push(data.no_of_securirities);
                this.listPreviousPreClearValue.push(data.price);
                }else{
                  remainingtradingpreclearance.push(data);                   
                }
              })
              this.totalValues = 0;
              this.totalshares = 0
              for(let i=0; i<this.listPreviousPreClearValue.length; i++){
                this.totalValues += Number(this.listPreviousPreClearValue[i]);               
              }
              for(let i=0; i<this.listPreviousPreClearShare.length; i++){
                this.totalshares += Number(this.listPreviousPreClearShare[i]); 
                
              } 
              
        });
     
      });
      
   
    this.previouspreClearanceForm = this.fb.group({
      name: new FormControl ('', Validators.required),
      no_of_securirities: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      date_of_transaction: new FormControl ('', Validators.required),
      bought_sold_subscribed: new FormControl ('', Validators.required),
      dp_client_id: new FormControl ('', Validators.required),
      price: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),      
      tradingplace: new FormControl ('', Validators.required),      
    
    });
  }
    this.pitService.listPreviousPreClear(null).subscribe(
      res => {
            let listPreviousPreClear = res['data'];
 
      });

      
     
  }

  resetForm(){
    this.previouspreClearanceForm.reset()
    this.submitted = false;
    this.router.navigate(['/pit-compliance/trading-pre-clearance', this.PcEditId]); 
  }
  
  previousmailconfig(valId){
    this.previouspreClearanceForm.value.preclearance_id = this.PcEditId;
    this.previouspreClearanceForm.value.dpdesignation = this.dpdesignation;
    this.previouspreClearanceForm.value.id = valId;
    this.previouspreClearanceForm.value.complianceofficeremail = this.complianceOfficerEmail;
    this.previouspreClearanceForm.value.complianceofficername = this.complianceOfficerName;
    this.previouspreClearanceForm.value.complianceofficerdesignation = this.complianceOfficerDesignation;
    this.previouspreClearanceForm.value.companyname = this.companyName;
    this.previouspreClearanceForm.value.typeofsecurity = this.typeofsecurity;

    this.pitService.previousmailconfig(this.previouspreClearanceForm.value).subscribe(data =>{ 
    
    });
}
  onSubmit(){    
    if(this.previouspreClearanceForm.get('date_of_transaction')?.value != ''){
      if(this.previouspreClearanceForm.get('date_of_transaction')?.value < this.approved_date ||  this.previouspreClearanceForm.get('date_of_transaction')?.value > this.curr_date || this.previouspreClearanceForm.get('date_of_transaction')?.value > this.afterseventhday){
        this.toastr.warning("The trading date should be between after pre-clearance approval is granted and within " + this.preDayCounts + " days thereafter.");
       return false;
      } 
    }
    if(this.previouspreClearanceForm.get('no_of_securirities')?.value != ''){
      if(this.preclearanceShares < (Number(this.totalshares) + Number(this.previouspreClearanceForm.get('no_of_securirities')?.value))){
        this.errorSharesMessage = "Transaction shares greater than to approved shares." ;
       
      }
    }   
    if(this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1){
      if(this.previouspreClearanceForm.get('price')?.value != ''){
        if(this.preclearanceValue < (Number(this.totalValues) + Number(this.previouspreClearanceForm.get('price').value))){
          this.errorValuesMessage = "Transaction Value greater than to approved Value.";
        }
      }else{
        this.errorValuesMessage == undefined;
      }
    }
    this.submitted = true;
    const emptyFileds = Object.keys(this.previouspreClearanceForm.controls).filter(key =>this.previouspreClearanceForm.controls[key].errors !== null)
    this.previouspreClearanceForm.value.preclearance_id = this.PcEditId;
    this.previouspreClearanceForm.value.dpdesignation = this.dpdesignation;
    this.previouspreClearanceForm.value.tradingcompanyname = this.tradingcompanyname;
    this.previouspreClearanceForm.value.typeofsecurity = this.typeofsecurity;

    if(this.previouspreClearanceForm.status !== 'INVALID'){
   this.button = 'Processing...';
   this.isLoading = true;
  if(this.errorSharesMessage == undefined && this.errorValuesMessage == undefined){
      this.pitService.createPreviousPreClear(this.previouspreClearanceForm.value).subscribe(
        data => {
          this.responseDate = data;
         

          if(data.success == 1){
            this.toastr.success("Previous Pre Clearance Successfully Created");
            this.isLoading = false;
            this.button = 'Save';
            this.previousmailconfig(data.previousTradeId);
            this.router.navigate(['/pit-compliance/trading-pre-clearance', this.PcEditId]); 
            
          }else{
            this.isLoading = false;
            this.button = 'Submit';         
            this.toastr.warning(data.message) 
          }
                    
        });
    }else{ 
          this.isLoading = false;
            this.button = 'Save';
          const modalRef = this.modalService.open(DisclosureExistTradingComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.previouspreClearanceForm = this.previouspreClearanceForm.value;
          modalRef.componentInstance.preClearanceDataList = this.preClearanceDataList;
          modalRef.componentInstance.errorSharesMessage = this.errorSharesMessage;
          modalRef.componentInstance.errorValuesMessage = this.errorValuesMessage;
          modalRef.result.then(
            (result) => {
              console.log(result);
            }, (reason) => {
              console.log(reason);
            });       
            
      }
   }else if(emptyFileds.length > 1){
     this.toastr.warning("Please fill all Mandatory fileds.");
     return false;
   }else{
     if(this.previouspreClearanceForm.get('name').value == ''){
       this.toastr.warning("Please fill the Name of holder.");
     }else if(this.previouspreClearanceForm.get('no_of_securirities').value == ''){
       this.toastr.warning("Please fill the No of securities dealt.");
     }else if(this.previouspreClearanceForm.get('bought_sold_subscribed').value == ''){
       this.toastr.warning("Please fill the Bought/ sold/ subscribed.");
     }else if(this.previouspreClearanceForm.get('dp_client_id').value == ''){
       this.toastr.warning("Please fill the DP/ Client ID/ Folio No.");
     }else if(this.previouspreClearanceForm.get('price').value == ''){
       this.toastr.warning("Please fill the Price.");
     } if(this.previouspreClearanceForm.get('tradingplace').value == ''){
       this.toastr.warning("Please fill the Place from which this disclosure is being Made.");
     }
   }
  }
}
