import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-delete-pre-clearance',
  templateUrl: './delete-pre-clearance.component.html',
  styleUrls: ['./delete-pre-clearance.component.scss']
})
export class DeletePreClearanceComponent implements OnInit {
  twcId: any;
  accessSuccess: string;
  pcReasonDeleteForm: FormGroup;
  getId: any;
  @Input() deletePreClearanceList;
  isLoading = false;
  button = 'Delete';
  submitted = false;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private pitService: PitComplianceService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.pcReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('',  Validators.required)
    });
   this.getId = this.deletePreClearanceList.id;
  }
  onSubmit(){
    this.submitted = true;
    this.pcReasonDeleteForm.value.id = this.getId;
    if(this.pcReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
        this.button = 'Processing';
        this.pitService.preClearancedelete(this.pcReasonDeleteForm.value).subscribe(data =>{
      console.log(this.pcReasonDeleteForm.value )
      this.toastr.success("Pre Clearance Successfully deleted");
      this.isLoading = false;
        this.button = 'Delete';
      window.location.reload();
      
    })
  }else{
    if(this.pcReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Pre Clearance.");
    }
  }
  }
  
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }

}
