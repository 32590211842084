import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';

@Component({
  selector: 'app-customer-activity-log',
  templateUrl: './customer-activity-log.component.html',
  styleUrls: ['./customer-activity-log.component.scss']
})
export class CustomerActivityLogComponent implements OnInit {

  user_roles: any;
  role_type: any;
  user_access: boolean = false;
  dpId: any;
  //viewDpList;
  constructor(private dpService:DpModuleService, private activateRouter:ActivatedRoute, private upsiService: UpsiService, public activeModal: NgbActiveModal, private http:HttpClient) {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')))
    this.role_type = this.user_roles.roles_name;
    if(this.user_roles.roles_name !== "Admin"){
      this.user_access = false;
    }else{
      this.user_access = true;
    }
   }



  @Input() viewCustomerList;



  ngOnInit() {
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
