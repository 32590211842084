import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsiService } from '../upsi.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

export interface confidentialityUpsiList{
  id: number;
  confidentiality_agreement_entered: number;
  sender: string;
  nature_of_upsi: string;
  project_name: string;
  recipient: string;
}

@Component({
  selector: 'app-report-upsi',
  templateUrl: './report-upsi.component.html',
  styleUrls: ['./report-upsi.component.scss']
})
export class ReportUpsiComponent implements OnInit {
  public selected = [];
  baseUrl = environment.apiUrl;
  page = 1;
  pageSize = 10;
  public active1 = 1;
  listUpsiTransfer: any;
  pipe: DatePipe;
  filterForm: FormGroup;

  listProject: any;
  filterString = "";
  filtered;
  filterString2 = "";
  filtered2;
  myDateValue: Date;
  toDate: Date;
  duplicateArray = []
  url = "https://localhost/sdd/public/api/upsi/indexexcel"
  upsiId: any;
  confidentialityUpsiList: any;
  accessSuccess: string;
  constructor(private upsiService: UpsiService, private fb: FormBuilder, private http: HttpClient,  public router: Router,private activateRouter: ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

    this.filterForm = this.fb.group({
      fromDate: [''],
      toDate: [''],
      projectName: ['']
    });
  
    // this.upsiService.upsiTransferList(this.filterForm.value).subscribe(
    //   res => {
    //     // console.log("response = ",res)
    //     this.listUpsiTransfer = res;
    //     this.onFilterChange2();
    //     //console.log("response = ", this.listUpsiTransfer.data)
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );

  
//confidentiality_agreement_entered
    // this.activateRouter.params.subscribe(data => {
    //   this.upsiId = data['id']
    // })
    // if(this.upsiId){
      // this.upsiService.confidentialityUpsiTransfer().subscribe(data =>{
      //   this.confidentialityUpsiList = data;
      //   this.onFilterChange();
      // },
      // err => {
      //   console.log(err);
      // })
   // }

  }
  
  onFilterChange() {
    this.filtered = this.confidentialityUpsiList.data?.filter(res =>{ 
     return res.confidentiality_agreement_entered.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.sender.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.nature_of_upsi.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.project_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.recipient.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    }) 
   
  }

  onFilterChange2() {
    this.filtered2 = this.listUpsiTransfer.data?.filter(res =>{   
     return res.confidentiality_agreement_entered.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || res.sender.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || res.nature_of_upsi.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || res.project_name.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || res.recipient.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase())
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }

}
