import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { ModelAccessLevelComponent } from './model-access-level/model-access-level.component';
const routes: Routes = [
  {
    path: '',   
    children: [ 
        {
            path: '',
            component: ModelAccessLevelComponent,
            data: {
              title: "List",
              breadcrumb: "List"
            }
          },
      {
        path: 'list',
        component: ModelAccessLevelComponent,
        data: {
          title: "List",
          breadcrumb: "List"
        }
      },  
      

      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModelAccessControlRoutingModule { }
