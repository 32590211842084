import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { barChartSingle, pieChart, multiData, single } from '../../shared/data/chart/ngx-chart';
import * as graphoptions from '../../shared/data/chart/config';
import * as chartData from '../../shared/data/chart/chartjs';
import { ReportService } from '../report.service';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PopupModelReportComponent } from '../popup-model-report/popup-model-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinct, from, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { jsPDF } from "jspdf";
import { DisclaimerpdfComponent } from '../disclaimerpdf/disclaimerpdf.component';
import { DashboardService } from 'src/app/components/dashboard/dashboard.service';
//import * as jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';
declare let html2canvas: any;
@Component({
  selector: 'app-report-charts',
  templateUrl: './report-charts.component.html',
  styleUrls: ['./report-charts.component.scss']
})
export class ReportChartsComponent implements OnInit {
  @ViewChild('reportContent', {static: false}) reportContent: ElementRef;
  @ViewChild('upsiSixMonthReport') upsiSixMonthReport: ElementRef;
 // @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  selectedsenderName = [];
  selectedrecipientName = [];
  public selected = [];  
  page = 1;
  pageSize = 10;
  public barChartsingle = barChartSingle;
  public pieChart = pieChart;
  public multiData = multiData;
  public single = single
  upsiMonthlyCounting: any;
  upsiMonthlyLabel: any;
  filterForm: FormGroup;
  reportArrForm: FormGroup;
  public active1 = 1;
  public active2 = 3;
  listUpsiTransfer: any;
  pipe: DatePipe; 
  listProject: any;
  filterString = "";
  filtered;
  filterString2 = "";
  filtered2;
  myDateValue: Date;
  toDate: Date;
  duplicateArray = []
  url = "https://localhost/sdd/public/api/upsi/indexexcel"
  upsiId: any;
  confidentialityUpsiList: any;
  accessSuccess: string;
  caUpsiTransferr = [{"id":1,"name":"UPSI ID"},{"id":2,"name":"Delay"},{"id":3,"name":"Nature of UPSI"},{"id":4,"name":"Project Name"},{"id":5,"name":"Date of Sharing"},{"id":6,"name":"Sender Name"},{"id":7,"name":"Recipient Name"}];
  selectedConfidencialAgreement = [];
  visible:boolean = false

  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownSettings2:IDropdownSettings;
  selectedSenderItems = [];
  selectedReceiverItems = [];
  senderList: any;
  receiverList: any;
  listUpsiTransfer2: any;
  selected_project=[];
  listUpsiTransferrecipient = [];
  keyword  ="sender";
  keyword2 = "recipient";
  listUpsiTransferSender = [];
  baseUrl = environment.apiUrl;
  sideMenuLogoPath = environment.sideMenuLogoPath;
  href = environment.href;
  selected_receiver =[];
selected_sender = [];
senderFilterDate = [];

show_upsi_id = true;
show_delay = false;
show_nature_of_upsi = false;
show_project_name = true;
show_date_of_sharing = false;
show_sender = true;
show_recipient = true;
  upsiTotalReportHtml: any;
  upsiSixMonthReportHtml: any;
  upsiChartReportHtmlElement:any;
  sixMonthgeneratedNumber: any;
  totalReportgeneratedNumber: any;
  reportArr: any;
  SixMonthRandomNo: any;
  TotalReportRandomNo: any;
  openNoUpsi: boolean;
  imagepath: string;
  pieCharts = [];
  constructor(private reportService: ReportService, private modalService: NgbModal, private fb: FormBuilder, private upsiService: UpsiService, private http: HttpClient,  public router: Router,private activateRouter: ActivatedRoute,
    private dashboardService:DashboardService) {
    Object.assign(this, { multiData, barChartSingle, pieChart, single })

    this.filterForm = this.fb.group({
      dateOfSharing: new FormControl(''),
      toDate: new FormControl('', [this.dateRangeValidator]),
      type_of_sharing: new FormControl(''),
      nature_of_upsi: new FormControl(''),
      sender: new FormControl(''),
      receiver: new FormControl('')
    });
this.reportArrForm = this.fb.group({});
    this.accessSuccess = sessionStorage.getItem('access_success');
  
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.filterForm && this.filterForm.get("dateOfSharing").value;
    const to = this.filterForm && this.filterForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  ngOnInit() {
    this.imagepath = this.href + '/assets/images/' + this.sideMenuLogoPath;
    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.noupsi_access == true || accessModelController.noupsi_access == 1){
       this.openNoUpsi = true;
    }else{
       this.openNoUpsi = false;
    }
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
   
    if(this.user_roles.upsi_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.upsi_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }

// }

// this.upsiService.getReportData(null).subscribe(data => {
      
//   this.listUpsiTransfer = data;

      
//   });
this.selectedConfidencialAgreement = [{"id":1,"name":"UPSI ID"},{"id":4,"name":"Project Name"},{"id":6,"name":"Sender Name"},{"id":7,"name":"Recipient Name"}];

 // Nature of UPSI Dropdown
//  this.dropdownList = [
//   { id: 1, itemName: 'Financial results' },
//   { id: 2, itemName: 'Dividends' },
//   { id: 3, itemName: 'Change in capital structure' },
//   { id: 4, itemName: 'Mergers, de-mergers & acquisitions' },
//   { id: 5, itemName: 'Delistings' },
//   { id: 6, itemName: 'Disposals' },
//   { id: 7, itemName: 'Expansion of business' },
//   { id: 8, itemName: 'Changes in key managerial personnel' },
//   { id: 9, itemName: 'Others' },
// ];

this.dropdownSettings = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 2,
  allowSearchFilter: true
};
// this.dropdownSettings1 = {
//   singleSelection: false,
//   idField: 'id',
//   textField: 'name',
//   selectAllText: 'Select All',
//   unSelectAllText: 'UnSelect All',
//   itemsShowLimit: 2,
//   allowSearchFilter: true
// };
this.selectedItems = [];
//Nature of UPSI Dropdown

this.onSubmit(); 
const arr = [];
this.upsiRecordData();

this.upsiService.getReportData(null).subscribe(
  res => {
    
    const listUpsiTransfer2 = res['data'];
      const senderList = listUpsiTransfer2.map(item =>(item.SenderDPName + ' - ' + item.sender + ' - ' + item.SenderDPPanno));
      this.listUpsiTransferSender = [];

    from(senderList)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.listUpsiTransferSender.push(r));
  //  this.listUpsiTransferSender.forEach((item, index) => item.id = index + 1);
   

    const ricipientList = listUpsiTransfer2.map(item => (item.RecepientDPName + ' - ' + item.recipient + ' - ' + item.RecepientDPPanno));
      this.listUpsiTransferrecipient = [];

    from(ricipientList)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.listUpsiTransferrecipient.push(r));

   //this.listUpsiTransferrecipient.forEach((item, index) => item.id = index + 1);

 
  this.dropdownList = arr;
    
  },
  err => {
    console.log(err);
  }
);


   }
  //  ngAfterViewInit(){
  //   this.barChartReport();
  //  }
   barChartReport() {
   
  //   let upsiSixMonthReport = document.getElementById('#upsiSixMonthReport');
  // html2canvas(upsiSixMonthReport)?.then(canvas => {
  //   this.upsiSixMonthReportHtml = canvas?.toDataURL("image/png");
  //   console.log("upsiSixMonthReport", this.upsiSixMonthReportHtml)
  //   this.sixMonthgeneratedNumber = Math.floor(1000 * Math.random());
  //   this.reportArrForm.value.upsiSixMonthReportHtml = this.upsiSixMonthReportHtml;
  //  this.reportArrForm.value.sixMonthgeneratedNumber = this.sixMonthgeneratedNumber;
  // });
  // let upsiTotalReport = document.querySelector('#upsiTotalReport');

  // html2canvas(upsiTotalReport)?.then(canvas => {

  //   this.upsiTotalReportHtml = canvas?.toDataURL("image/png");
  //   this.totalReportgeneratedNumber = Math.floor(1000 * Math.random());
  //   this.reportArrForm.value.totalReportgeneratedNumber = this.totalReportgeneratedNumber;
  //   this.reportArrForm.value.upsiTotalReportHtml = this.upsiTotalReportHtml;
    
  // });
 
}
openpdfalert() {
  const modalRef = this.modalService.open(DisclaimerpdfComponent,
  {
    scrollable: true,
    windowClass: 'myCustomModalClass',
    // keyboard: false,
    // backdrop: 'static'
  });
  modalRef.componentInstance.name = 'Alert';
  //modalRef.componentInstance.title = 'Considering Limited Space available on one page, the pdf report is only for internal purposes. For complete upsi report download excel.';
  modalRef.componentInstance.title = ' PDF Report is to be used for internal purposes only, due to the 1-page space limitation. Please download the Excel Sheet Format for the complete UPSI Report.';
  modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
    if(receivedData == 'Yes'){
      this.downloadChartPdf();
    }
  })
 
}
downloadChartPdf(){
  let upsiSixMonthReport = document.querySelector('#upsiSixMonthReport');
  html2canvas(upsiSixMonthReport)?.then(canvas => {
    this.upsiSixMonthReportHtml = canvas?.toDataURL("image/png");
    
    this.sixMonthgeneratedNumber = Math.floor(1000 * Math.random());
    this.reportArrForm.value.upsiSixMonthReportHtml = this.upsiSixMonthReportHtml;
   this.reportArrForm.value.sixMonthgeneratedNumber = this.sixMonthgeneratedNumber;
  //  this.reportService.downloadChartPdf(this.reportArrForm.value).subscribe(data =>{
 
  // })
  });
  let upsiTotalReport = document.querySelector('#upsiTotalReport');

  html2canvas(upsiTotalReport)?.then(canvas => {

    this.upsiTotalReportHtml = canvas?.toDataURL("image/png");
    this.totalReportgeneratedNumber = Math.floor(1000 * Math.random());
    this.reportArrForm.value.totalReportgeneratedNumber = this.totalReportgeneratedNumber;
    this.reportArrForm.value.upsiTotalReportHtml = this.upsiTotalReportHtml;
    
    this.reportService.downloadChartPdf(this.reportArrForm.value).subscribe(data =>{
     
      this.SixMonthRandomNo = data.upsiSixMonthRandomNo;
      this.TotalReportRandomNo = data.upsiTotalReportRandomNo;
      
      
        // this.upsireport();
        // this.nonupsireport();  
        this.upsireport();
        // this.onFilter2();
    if(this.openNoUpsi == true){
        var self = this;
         setTimeout(function(){
          self.nonupsireport();
        },10000);
      }
    })
    
  });

  
}
upsireport(){
  let queryString = '';
      let url = this.baseUrl + 'pdfreports?';
      let tokenStr = sessionStorage.getItem('access_token');
      if(tokenStr){
        tokenStr = tokenStr.replace('Bearer ', '');
      }
      
      if(this.SixMonthRandomNo || this.TotalReportRandomNo || this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.nature_of_upsi || this.filterForm.value.sender || this.filterForm.value.receiver || this.imagepath )  {
        queryString = 'sixMonthRandomNo=' + this.SixMonthRandomNo + '&totalReportRandomNo=' + this.TotalReportRandomNo + '&fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&natureofupsi=' + this.filterForm.value.nature_of_upsi + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver ;
      
        queryString = queryString + '&authToken='+btoa(tokenStr);
        
      } else {
        queryString = queryString + 'authToken='+btoa(tokenStr);
      }
     
        var element = document.createElement('a');
        element.setAttribute('href', url + queryString);
        element.setAttribute('download', 'sample.xls');
    
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
}
nonupsireport(){

      let queryString = '';
      let urlnonupsi = this.baseUrl + 'nonupsireports?';
      let tokenStr = sessionStorage.getItem('access_token');
      if(tokenStr){
        tokenStr = tokenStr.replace('Bearer ', '');
      }
      
      if(this.SixMonthRandomNo || this.TotalReportRandomNo || this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.nature_of_upsi || this.filterForm.value.sender || this.filterForm.value.receiver )  {
        queryString = 'sixMonthRandomNo=' + this.SixMonthRandomNo + '&totalReportRandomNo=' + this.TotalReportRandomNo + '&fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&natureofupsi=' + this.filterForm.value.nature_of_upsi + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver ;
      
        queryString = queryString + '&authToken='+btoa(tokenStr);
        
      } else {
        queryString = queryString + 'authToken='+btoa(tokenStr);
      }
     
        var element = document.createElement('a');
        element.setAttribute('href', urlnonupsi + queryString);
        element.setAttribute('download', 'sample.xls');
    
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
}

downloadexcelsheet(){
  // this.downloadupsiexcelsheet();
  // this.downloadnonupsiexcelsheet();
  this.downloadupsiexcelsheet();
  // this.onFilter2();
  if(this.openNoUpsi == true){
  var self = this;
   setTimeout(function(){
    self.downloadnonupsiexcelsheet();
  },3000);
  }

}




downloadupsiexcelsheet() {
    
  let queryString = '';
  let url = this.baseUrl + 'excelreports?';
  let tokenStr = sessionStorage.getItem('access_token');
  if(tokenStr){
    tokenStr = tokenStr.replace('Bearer ', '');
  }
  
  if(this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.nature_of_upsi || this.filterForm.value.sender || this.filterForm.value.receiver )  {
    queryString = '&fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&natureofupsi=' + this.filterForm.value.nature_of_upsi + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver ;
  
    queryString = queryString + '&authToken='+btoa(tokenStr);
   
    
  } else {
    queryString = queryString + 'authToken='+btoa(tokenStr);
  }
  var element = document.createElement('a');
  element.setAttribute('href', url + queryString);
  element.setAttribute('download', 'sample.xls');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

   
}
downloadnonupsiexcelsheet() {
    
  let queryString = '';
  let url = this.baseUrl + 'excelnonupsireports?';
  let tokenStr = sessionStorage.getItem('access_token');
  if(tokenStr){
    tokenStr = tokenStr.replace('Bearer ', '');
  }
  
  if(this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.nature_of_upsi || this.filterForm.value.sender || this.filterForm.value.receiver )  {
    queryString = '&fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&natureofupsi=' + this.filterForm.value.nature_of_upsi + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver ;
  
    queryString = queryString + '&authToken='+btoa(tokenStr);
   
    
  } else {
    queryString = queryString + 'authToken='+btoa(tokenStr);
  }
  var element = document.createElement('a');
  element.setAttribute('href', url + queryString);
  element.setAttribute('download', 'sample.xls');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

   
}
onDateFilter(e){ 
  let toDate = this.filterForm.value.toDate;
  let dateOfSharing = this.filterForm.value.dateOfSharing;
  this.filterForm.value.toDate = toDate;
  this.filterForm.value.dateOfSharing = dateOfSharing;
  // let fromDate = {'dateOfSharing': e.target.value }
  this.reportService.upsiMonthlyCount(this.filterForm.value).subscribe(
    res => {
      
      
    },
    err => {
      console.log(err);
    }
  )
    //upsi
    // this.upsiService.getReportData(this.filterForm.value).subscribe(
    //   res => {
        
        
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
    this.graphicData();
}
// Nature of UPSI Dropdown
onItemSelect(item: any,page) {
  if(page == "project"){
    if(!this.selected_project.includes(item)){
      this.selected_project.push(item);
    }
  }
  else if(page == "sender"){
    if(!this.selected_sender.includes(item)){
      this.selected_sender.push(item);
      // this.reportService.changeFilterDate(this.selected_sender).subscribe(
      //   res => {
      //   
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )

      // this.reportService.fullTableCount(this.selected_sender).subscribe(
      //   res => {
      //    
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
      // this.reportService.upsiMonthlyCount(this.selected_sender).subscribe(
      //   res => {
      //    //
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
        //upsi
        // this.upsiService.getReportData(this.selected_sender).subscribe(
        //   res => {
            
        //    //
            
        //   },
        //   err => {
        //     console.log(err);
        //   }
        // );
        // this.reportService.confidentialityUpsiTransfer(this.selected_sender).subscribe(data =>{
          
          
        // },
        // err => {
        //   console.log(err);
        // })
    
    }
  }else if(page == "receiver"){
    if(!this.selected_receiver?.includes(item)){
      this.selected_receiver.push(item);
      // this.reportService.fullTableCount(this.selected_receiver).subscribe(
      //   res => {
      //    
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
      // this.reportService.upsiMonthlyCount(this.selected_receiver).subscribe(
      //   res => {
      //    //
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
        //upsi
        // this.upsiService.getReportData(this.selected_receiver).subscribe(
        //   res => {
            
            
            
        //   },
        //   err => {
        //     console.log(err);
        //   }
        // );
        // this.reportService.confidentialityUpsiTransfer(this.selected_receiver).subscribe(data =>{
          
          
        // },
        // err => {
        //   console.log(err);
        // })
    }
  }else if(page == "filter"){
   
    if(this.selectedConfidencialAgreement.length > 4){   
      document.getElementById('datatables').classList.add('tablescroll');
    }else{
      document.getElementById('datatables').classList.remove('tablescroll');
    }
    if(item.id == 1){
      this.show_upsi_id = true;
    }else if(item.id == 2){
      this.show_delay = true;
    }else if(item.id == 3){
      this.show_nature_of_upsi = true;
    }else if(item.id == 4){
      this.show_project_name = true;
    }else if(item.id == 5){
      this.show_date_of_sharing = true;
    }else if(item.id == 6){
      this.show_sender = true;
    }else if(item.id == 7){
      this.show_recipient = true;
    }
  }
  this.graphicData();
//this.upsiConfidencialData();
//this.upsiDelayData();
}

onItemDeSelect(item: any,page) {

  if(page == "project"){
    if(this.selected_project.includes(item)){
      this.selected_project.splice(this.selected_project.indexOf(item), 1);
    }
  }
  else if(page == "sender"){
    if(this.selected_sender.includes(item)){
      this.selected_sender.splice(this.selected_sender.indexOf(item), 1);
      let filteredItemsSender = [];
      if(this.selectedsenderName.length > 0){
        this.selectedsenderName.map(res =>{
          this.listUpsiTransferSender.map(proj =>{    
            if(proj.toLocaleLowerCase().match(res.toLocaleLowerCase())) {
              filteredItemsSender.push(proj);
            }
          });
        });
      }
      
      // this.reportService.fullTableCount(filteredItemsSender).subscribe(
      //   res => {
      //    
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
      // this.reportService.upsiMonthlyCount(filteredItemsSender).subscribe(
      //   res => {
          
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
        //upsi
        // this.upsiService.getReportData(filteredItemsSender).subscribe(
        //   res => {
            
        //    //
            
        //   },
        //   err => {
        //     console.log(err);
        //   }
        // );
        // this.reportService.confidentialityUpsiTransfer(filteredItemsSender).subscribe(data =>{
          
          
        // },
        // err => {
        //   console.log(err);
        // })
    }
  }else if(page == "receiver"){
    if(this.selected_receiver.includes(item)){
      this.selected_receiver.splice(this.selected_receiver.indexOf(item), 1);
      let filteredItemsRecipient = [];
      if(this.selectedrecipientName.length > 0){
        this.selectedrecipientName.map(res =>{
          this.listUpsiTransferrecipient.map(proj =>{    
            if(proj.toLocaleLowerCase().match(res.toLocaleLowerCase())) {
              filteredItemsRecipient.push(proj);
            }
          });
        });
      }
      
      // this.reportService.fullTableCount(filteredItemsRecipient).subscribe(
      //   res => {
      //    
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
      // this.reportService.upsiMonthlyCount(filteredItemsRecipient).subscribe(
      //   res => {
          
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // )
        //upsi
        // this.upsiService.getReportData(filteredItemsRecipient).subscribe(
        //   res => {
            
            
            
        //   },
        //   err => {
        //     console.log(err);
        //   }
        // );
        // this.reportService.confidentialityUpsiTransfer(filteredItemsRecipient).subscribe(data =>{
          
          
        // },
        // err => {
        //   console.log(err);
        // })
    }
  }else if(page == "filter"){
    if(this.selectedConfidencialAgreement.length > 4){   
      document.getElementById('datatables').classList.add('tablescroll');
    }else{
      document.getElementById('datatables').classList.remove('tablescroll');
    }
    if(item.id == 1){
      this.show_upsi_id = false;
    }else if(item.id == 2){
      this.show_delay = false;
    }else if(item.id == 3){
      this.show_nature_of_upsi = false;
    }else if(item.id == 4){
      this.show_project_name = false;
    }else if(item.id == 5){
      this.show_date_of_sharing = false;
    }else if(item.id == 6){
      this.show_sender = false;
    }else if(item.id == 7){
      this.show_recipient = false;
    }
   }
  this.graphicData();
//this.upsiConfidencialData();
//this.upsiDelayData();
}
onSelectAll(items: any,page) {
  if(page == "project"){
    this.selected_project=items;
  
  }
  else if(page == "sender"){
    this.selected_sender=items;
    // this.reportService.fullTableCount(this.selected_sender).subscribe(
    //   res => {
    //    
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
    // this.reportService.upsiMonthlyCount(this.selected_sender).subscribe(
    //   res => {
        
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
      //upsi
      // this.upsiService.getReportData(this.selected_sender).subscribe(
      //   res => {
          
          
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // );
      // this.reportService.confidentialityUpsiTransfer(this.selected_sender).subscribe(data =>{
        
        
      // },
      // err => {
      //   console.log(err);
      // })
  }else if(page == "receiver"){
    this.selected_receiver=items;
    // this.reportService.fullTableCount(this.selected_receiver).subscribe(
    //   res => {
    //    
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
    // this.reportService.upsiMonthlyCount(this.selected_receiver).subscribe(
    //   res => {
       
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
      //upsi
      // this.upsiService.getReportData(this.selected_receiver).subscribe(
      //   res => {
          
         
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // );
      // this.reportService.confidentialityUpsiTransfer(this.selected_receiver).subscribe(data =>{
        
        
      // },
      // err => {
      //   console.log(err);
      // })
  }else if(page == "filter"){
    if(this.selectedConfidencialAgreement.length > 4){   
      document.getElementById('datatables').classList.add('tablescroll');
    }else{
      document.getElementById('datatables').classList.remove('tablescroll');
    }
    this.show_upsi_id = true;
    this.show_delay = true;
    this.show_nature_of_upsi = true;
    this.show_project_name = true;
    this.show_date_of_sharing = true;
    this.show_sender = true;
    this.show_recipient = true;
   }
  this.graphicData();
//this.upsiConfidencialData();
//this.upsiDelayData();
}
onDeSelectAll(items: any,page) {
  if(page == "project"){
    this.selected_project=[];
    // console.log(this.selected_project);
  }
  else if(page == "sender"){
    this.selected_sender=[];
    // this.reportService.fullTableCount(this.selected_sender).subscribe(
    //   res => {
    //    
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
    // this.reportService.upsiMonthlyCount(this.selected_sender).subscribe(
    //   res => {
       
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
      //upsi
      // this.upsiService.getReportData(this.selected_sender).subscribe(
      //   res => {
          
         
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // );
      // this.reportService.confidentialityUpsiTransfer(this.selected_sender).subscribe(data =>{
        
        
      // },
      // err => {
      //   console.log(err);
      // })
  }else if(page == "receiver"){
    this.selected_receiver=[];
    // this.reportService.fullTableCount(this.selected_receiver).subscribe(
    //   res => {
    //    
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
    // this.reportService.upsiMonthlyCount(this.selected_receiver).subscribe(
    //   res => {
       
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // )
      //upsi
      // this.upsiService.getReportData(this.selected_receiver).subscribe(
      //   res => {
          
         
          
      //   },
      //   err => {
      //     console.log(err);
      //   }
      // );
      // this.reportService.confidentialityUpsiTransfer(this.selected_receiver).subscribe(data =>{
        
        
      // },
      // err => {
      //   console.log(err);
      // })
  }else if(page == "filter"){   
    if(this.selectedConfidencialAgreement.length > 4){   
      document.getElementById('datatables').classList.add('tablescroll');
    }else{
      document.getElementById('datatables').classList.remove('tablescroll');
    }
    this.show_upsi_id = false;
    this.show_delay = false;
    this.show_nature_of_upsi = false;
    this.show_project_name = false;
    this.show_date_of_sharing = false;
    this.show_sender = false;
    this.show_recipient = false;
   }
  this.graphicData();
//this.upsiConfidencialData();
//this.upsiDelayData();
}
 // Nature of UPSI Dropdown
 natUpsiFilter(item){
  let natUpsiItem = item.target.value;
  // this.reportService.fullTableCount(natUpsiItem).subscribe(
  //   res => {
  //    
  //   },
  //   err => {
  //     console.log(err);
  //   }
  // )
  // this.reportService.upsiMonthlyCount(natUpsiItem).subscribe(
  //   res => {
     
      
  //   },
  //   err => {
  //     console.log(err);
  //   }
  // )
    //upsi
    // this.upsiService.getReportData(natUpsiItem).subscribe(
    //   res => {
        
       
        
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
    // this.reportService.confidentialityUpsiTransfer(natUpsiItem).subscribe(data =>{
      
      
    // },
    // err => {
    //   console.log(err);
    // })
this.graphicData();
//this.upsiConfidencialData();
//this.upsiDelayData();
 }
 //Filter Pdf
 onFilterPdf() {
    
  let queryString = '';
  let url = this.baseUrl + 'reports?';
  let tokenStr = sessionStorage.getItem('access_token');
  if(tokenStr){
    tokenStr = tokenStr.replace('Bearer ', '');
  }
  
  if(this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.projects || this.filterForm.value.sender || this.filterForm.value.receiver)  {
    queryString = 'fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&projectName=' + this.filterForm.value.projects + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver;
   
    queryString = queryString + '&authToken='+btoa(tokenStr);
    
  } else {
    queryString = queryString + 'authToken='+btoa(tokenStr);
  }
  var element = document.createElement('a');
  element.setAttribute('href', url + queryString);
  element.setAttribute('download', 'sample.xls');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

   
}
upsiRecordData(){
  this.graphicData();
  this.totalCountData();
}
//Graphic Datas api
graphicData(){
  this.reportService.upsiMonthlyCount(this.filterForm.value).subscribe(
    res => {
      const upsiMonthlyCount = res.body['data'];
      this.upsiMonthlyCounting = upsiMonthlyCount.map( data => data.upCount)
      this.barChartLabels = upsiMonthlyCount.map( res => res.monthyearval)
      this.barChartData = [{data:this.upsiMonthlyCounting, label: 'UPSI Transfers'}]
      
    },
    err => {
      console.log(err);
    }
  )
  // this.reportService.fullTableCount(this.filterForm.value).subscribe(
  //   res => {
  //     this.pieChart = res.body['data'];
  //     console.log('pieChart000', this.pieChart)
  //   },
  //   err => {
  //     console.log(err);
  //   }
  // )
  this.pieCharts = [];




}
totalCountData(){
   //DP List
 this.dashboardService.dpListCount(null).subscribe(
  res => {      
  let listDpModule = res.body['data']
  this.pieCharts.push({name: "DP", value: listDpModule})

    },
    err => {
        console.log(err);
    }
);
  //Insider
  this.dashboardService.insiderListCount(null).subscribe(
    res => {
      
    let listinsider = res.body['data']
    this.pieCharts.push({name: "Insider", value: listinsider})
    this.pieCharts.forEach((item) => item);

      },
      err => {
          console.log(err);
      }
  );
     //Log Transfer
  // this.dashboardService.upsiListCount(null).subscribe(
    
  //   res => {
    
  //     let upsicountList = res.body['data']
  //     this.pieCharts.push({name: "Log UPSI Transfer", value: upsicountList});
  //     this.pieCharts.forEach((item) => item);

  //       },
  //       err => {
  //           console.log(err);
  //       }    

  // )
    // project
this.dashboardService.projectListCount(null).subscribe(
  res => {
    
    let listProject = res.body['data'];
    this.pieCharts.push({name: "Projects", value: listProject});
    this.pieCharts.forEach((item) => item);

    this.pieChart = this.pieCharts;

  },
    err => {
        console.log(err);
    }
  );
}
tabularData(){
  //this.upsiDelayData();
  //this.upsiConfidencialData();
}

// upsiDelayData(){
//   //UPSI Delay
// this.upsiService.getReportData(this.filterForm.value).subscribe(
//   res => {
    
//     this.listUpsiTransfer = res;    
//     this.onFilterChange2();
    
//   },
//   err => {
//     console.log(err);
//   }
// );


// }
// upsiConfidencialData(){
  
//   this.reportService.confidentialityUpsiTransfer(this.filterForm.value).subscribe(data =>{
//     this.confidentialityUpsiList = data;
//     this.onFilterChange();
    
//   },
//   err => {
//     console.log(err);
//   })
// }

//delay view
openModal(id:any) {

  if (id) {
    this.upsiService.viewUpsiTransfer(id).subscribe(
      ({data}) =>{
        const modalRef = this.modalService.open(PopupModelReportComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
      
           
        modalRef.componentInstance.viewUpsiList = data[0];
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    })

    }
 
  
}

//role
onBlur(): void {
  // console.log('Focus Is Lost for this Element');
}
//role
selectEvent(item) {
}

onChangeSearch(search: string) {
}

onFocused(e) {
  // do something
}
   //Filter
onFilterChange() {
  this.filtered = this.confidentialityUpsiList?.data.filter(res =>{    
   
   return res.confidentiality_agreement_entered.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.sender.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.nature_of_upsi.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.project_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.recipient.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
  }) 
 
 
}

onFilterChange2() {
  
  this.filtered2 = this.listUpsiTransfer?.data.filter(res =>{     
   
   return res.confidentiality_agreement_entered?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.sender?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.nature_of_upsi?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.nature_of_upsi?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase()) || res.recipient?.toLocaleLowerCase().match(this.filterString2?.toLocaleLowerCase())
  }) 
  
}

isMatch(item) {
  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString) > -1
  }
}
// onChangeSender(items:any){
//   console.log("senderName", items)
//   this.senderFilterDate = []
//   this.senderFilterDate = items;
//   this.reportService.changeFilterDate(this.senderFilterDate).subscribe(
//     res => {
//     
//     },
//     err => {
//       console.log(err);
//     }
//   )

// }
resetForm(){
  this.filterForm.reset();
}

   onSubmit() {
    if(this.filterForm.value.sender == ''){
       this.filterForm.value.sender = "";    
    }else{
      this.filterForm.value.sender = this.selectedsenderName; 
   
    }
    //recipient
    if(this.filterForm.value.receiver == ''){
      this.filterForm.value.receiver = "";
    }else{
      this.filterForm.value.receiver = this.selectedrecipientName;
    }
    // this.upsiRecordData();
    this.graphicData();
    // this.tabularData();
   }

  // pie-chart options
  public pieChartColorScheme = graphoptions.pieChartcolorScheme;
  public pieChartShowLabels = graphoptions.pieChartShowLabels;
  public pieChartGradient = graphoptions.pieChartGradient;
  public chartOptions = graphoptions.chartOptions;

  // barChart
  public barChartOptions = chartData.barChartOptions;
  public barChartLabels = barChartLabels;
  public barChartType = chartData.barChartType;
  public barChartLegend = chartData.barChartLegend;
  public barChartData = barChartData;
  public barChartColors = chartData.barChartColors;
  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  public onSelect(e) {  }
// events
  public chartClicked(e:any):void {  }
 
  public chartHovered(e:any):void { }

  //pdf-download

  // downloadAsPDF() {
  //   const doc = new jsPDF('p', 'pt', 'letter');

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     },
  //     '.controls': function(element, renderer){
  //       return true;
  //     }
  //   };

  //   const reportContent = this.reportContent.nativeElement;
  //   console.log("reportContent", reportContent)
  //   doc.fromHTML(reportContent.innerHTML, 15, 15, {
  //     width: 190,
  //     'elementHandlers': specialElementHandlers
  //   });

  //   // doc.save('tableToPdf.pdf');
  // //   doc.html(reportContent, {
  // //     callback: (doc) => {
  // //       width: 10
  // //       doc.output("dataurlnewwindow");
  // //     }
  // //  });
  // }




  // public onExport() {
  //   const doc = new jsPDF("p", "pt", "a4");
  //   const source = document.getElementById("reportContent");
  //   // doc.text("Test", 40, 20);
  //   doc.setFontSize(12)
  //   doc.html(source, {
  //     callback: function(pdf) {
  //       doc.output("dataurlnewwindow"); 
  //     }
  //   });
    // autotable(doc, {
    //   html: '#content',
    //   useCss: true
    // })

    // const doc = new jsPDF();
    // const specialElementHandlers = {
    //   '#editor': function (element, renderer) {
    //     return true;
    //   }
    // };

    // const content = this.reportContent.nativeElement;

    // doc.fromHTML(content.innerHTML, 15, 15, {
    //   'width': 190,
    //   'elementHandlers': specialElementHandlers
    // });

    // doc.save('asdfghj' + '.pdf');


  //}


}
export var barChartData: any[];

export var barChartLabels: string[];


