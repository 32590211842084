import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-model-upsi',
  templateUrl: './popup-model-upsi.component.html',
  styleUrls: ['./popup-model-upsi.component.scss']
})
export class PopupModelUpsiComponent implements OnInit {
  @Input() viewUpsiList;
  @Input() viewUpsiID;
  totalFileItem: any;
  confidencialUrl: any;
  modelAccess: any;
  public active1 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity:any;
  attachmentFiles: any;
  constructor(
    public activeModal: NgbActiveModal
  ) { 
    this.modelAccess = JSON.parse(sessionStorage.getItem('model_access_controller'));
    this.confidencialUrl = environment.url
  }

  ngOnInit() {
    let fileItem = this.viewUpsiList.confidentiality_agreement_attachment; 
    this.attachmentFiles = fileItem?.split(',');
    this.timelineActivity = this.viewUpsiList.timelineActivity; 

       
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
