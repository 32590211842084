<div class="modal-header">
    <!-- <h5 class="modal-title">Restore Deleted Project </h5> -->
    <h5 class="modal-title">Do you wish to restore this Project/Event?</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <!-- <h4 style="font-size: 19px;">Do you wish to restore this Project?</h4> -->

                        <form [formGroup]="revetProjectForm" class="needs-validation" (ngSubmit)="revertProjectId()">
                            <div class="row">
                                <div class="col-sm-12 col-xl-12">
                                   
                                        <div class="col-md-12 mb-3">
                                            <label for="nameOfInsider">Reason to Restore Project/Event</label>
                                            <textarea class="form-control" rows="5" id="reasonforrestore" placeholder="Reason to Restore Project/Event" formControlName="reasonforrestore" autocomplete="off" 
                                            [ngClass]="{ 'is-invalid': (submitted || revetProjectForm.controls.reasonforrestore.touched) && revetProjectForm.controls.reasonforrestore.errors }"></textarea>
                                            <div *ngIf="(submitted || revetProjectForm.controls.reasonforrestore.touched) && revetProjectForm.controls.reasonforrestore.errors?.required"
                                            class="text text-danger">
                                            Please mention the reason to Restore Project/Event
                                            </div>
                                            <div class="text text-danger" *ngIf="revetProjectForm.controls.reasonforrestore.errors?.pattern">Special Charactors Not Allowed!</div>
                                      
                                        </div>
                                        <div class="submit-btn">
                                            <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                            <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>   
                                            <!-- <button class="btn btn-primary me-1" (click)="revertProjectId()">Yes</button>
                                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>                  -->
                                         </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                </div>
                <div class="card-footer text-muted">
                    <!-- <b>Note:</b> Restoring of {{title}} will extract profile information only. Access to the {{title}} must be granted afresh based on current requirements. -->
                    <b>Note:</b> Restoring this {{title}} will extract profile information only. Please grant access to this {{title}} again.<br><span>This is to comply with current legal requirements.</span>

                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
