import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-company-list',
  templateUrl: './add-company-list.component.html',
  styleUrls: ['./add-company-list.component.scss']
})
export class AddCompanyListComponent implements OnInit {
  @Input() title;
  addCompanyform: FormGroup;
  constructor(public activeModal: NgbActiveModal,private fb: FormBuilder,private masterService: MasterService, public router: Router, private toastr: ToastrService, private activateRouter:ActivatedRoute) { }

  ngOnInit(): void {

    this.addCompanyform = this.fb.group({
      id: new FormControl(''),
      company_list : new FormControl('', Validators.required)
    });
  }
  onSubmit(){}

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
