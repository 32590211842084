import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../master.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router,Params } from '@angular/router';

@Component({
  selector: 'app-add-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.scss']
})
export class EditRolesComponent implements OnInit {
  rolesEditForm: FormGroup;
  checked: any;
  role_id;
  user_roles;
  is_loaded=false;
  isLoading = false;
  button = 'Update';
  submitted: boolean = false; 

  constructor(private route: ActivatedRoute,private fb: FormBuilder, private masterService: MasterService, private toastr: ToastrService, public router: Router) {
    this.route.params.subscribe((params: Params) => {
      this.role_id = {'id':params['id']};
    });
 
   }

  ngOnInit(): void {
    this.user_roles = sessionStorage.getItem('sdd_access_roles');



    this.masterService.getRoleById(this.role_id).subscribe(data =>{
      
      let resp = data.body['data'];
      let resp_data = JSON.parse(resp[0].module_name);
      this.is_loaded=true;
      
      //this.rolesEditForm.patchValue(resp_data); 
      this.rolesEditForm = this.fb.group({
        roles_name: [resp_data.roles_name, Validators.required],
        upsi_name: ['Log UPSI Transfer',  Validators.required],
        upsi_read: [resp_data.upsi_read,  Validators.required],
        dpname_read: [resp_data.dpname_read,  Validators.required],
        insider_read: [resp_data.insider_read,  Validators.required],
        project_read: [resp_data.project_read,  Validators.required],
        twc_read: [resp_data.twc_read,  Validators.required],
        customer_read: [resp_data.customer_read,  Validators.required],
        preclearance_read: [resp_data.preclearance_read,  Validators.required],
        benpos_read: [resp_data.benpos_read,  Validators.required],
        companylist_read: [resp_data.companylist_read,  Validators.required],
        dp_name: ['DP List',  Validators.required],
        insider: ['Insider',  Validators.required],
        project: ['Project',  Validators.required],
        twc: ['Trading Window Closure',  Validators.required],
        preclearance: ['Pre Clearance',  Validators.required],
        benpos: ['BENPOS Report',  Validators.required],
        customers: ['Customers',  Validators.required],
        companylist: ['Company List',  Validators.required],
      });
    });

    
    
  }
  onSubmit(){
    this.submitted = true;
    this.isLoading = true;
    this.button = 'Processing';
    this.rolesEditForm.value.id = this.role_id['id'];
    const emptyFileds = Object.keys(this.rolesEditForm.controls).filter(key =>this.rolesEditForm.controls[key].errors !== null)
    if (this.rolesEditForm.status !== 'INVALID') {
    this.masterService.updateRole(this.rolesEditForm.value).subscribe(data =>{
      
      
        this.toastr.success("Role & Permission details Updated", "Success!");
        this.router.navigate(['/setup/role-permission']);
        this.button = 'Update';
        this.isLoading = false;
    
    })
  } else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    this.button = 'Update';
    this.isLoading = false;
    return false;
    
  }else{
    if(this.rolesEditForm.get('roles_name').value == ''){
      this.toastr.warning("Please fill the Role Name.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('upsi_read').value == ''){
      this.toastr.warning("Please select the UPSI Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }
    else if(this.rolesEditForm.get('dpname_read').value == ''){
      this.toastr.warning("Please select the DP Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('insider_read').value == ''){
      this.toastr.warning("Please select the Insider Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }
    else if(this.rolesEditForm.get('project_read').value == ''){
      this.toastr.warning("Please select the Project Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('twc_read').value == ''){
      this.toastr.warning("Please select the Trading Window Clouser Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('preclearance_read').value == ''){
      this.toastr.warning("Please select the Pre Clearance Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('benpos_read').value == ''){
      this.toastr.warning("Please select the BENPOS Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesEditForm.get('customer_read').value == ''){
      this.toastr.warning("Please select the Customers Permission.");
      this.button = 'Update';
      this.isLoading = false;
    } else if(this.rolesEditForm.get('companylist_read').value == ''){
      this.toastr.warning("Please select the Company List Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else {
        this.toastr.warning("Role & Permission details not created", "Alert!");
        this.button = 'Update';
        this.isLoading = false;
      }
    }
  }

CheckboxChange(){
  this.checked ? 1 : 0;
}
cancelRoles(){
  this.router.navigate(['/setup/role-permission']);
  this.rolesEditForm.reset();
  this.submitted = true;
}

}
