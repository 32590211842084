<div class="modal-header">
    <h5 class="modal-title">UPSIs Attachment files</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body  noscroll">                   
                    <form [formGroup]="upsiAttachmentForm" class="needs-validation" >
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="dateOfSharing">UPSI Attachement <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input  #attachments type="file" class="custom-file-input form-control" accept=".pdf, image/png, image/jpeg"
                                    id="attachment" formControlName="agreement_attachment"  #fileInput
                                    (change)="onFileChanged($event)" multiple="true">
                                    <p class="text text-danger">*Upload Files: .pdf, .png, .jpeg, .xlsx, .docx, .txt, .xls</p>
                                </div>
                                <div *ngFor="let selected of selectedFileName; let index = index" class="itemArray">
                                    <h3 class="listItemhead">{{ selected }}</h3>
                                    <button (click)="removeSelectedFile(index, selected)" type="button" class="selectedBtn"><img src="assets/images/close.png" class="closeIcon"></button>
                                </div>
                            
                            </div>

                            
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <div class="submit-btn">
        <button class="btn btn-primary" [disabled]="isLoading" (click)="onSubmit()" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
    </div>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
