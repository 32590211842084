import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { PitComplianceService } from '../../pit-compliance.service'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PreClearanceMailTemplateComponent } from '../pre-clearance-mail-template/pre-clearance-mail-template.component';
import { MasterService } from 'src/app/master/master.service';
import { CompanyService } from 'src/app/master/company/company.service';
import { DatePipe, formatDate } from '@angular/common';
import { IntimationAlertPopupComponent } from '../intimation-alert-popup/intimation-alert-popup.component';
import { CustomerService } from 'src/app/customer/customer.service';

@Component({
  selector: 'app-add-pre-clearance',
  templateUrl: './add-pre-clearance.component.html',
  styleUrls: ['./add-pre-clearance.component.scss']
})
export class AddPreClearanceComponent implements OnInit {
  @ViewChild('sQty1') sQty1!: ElementRef;
  @ViewChild('sQty2') sQty2!: ElementRef;
  @ViewChild('sQty3') sQty3!: ElementRef;
  @ViewChild('excute') excute!: ElementRef;
  @ViewChild('dpname') dpname!: ElementRef;
  @ViewChild('noofsecurity') noofsecurity!: ElementRef;
  pitClearanceCreateForm: FormGroup;
  //checkboxForm: FormGroup;
  quantity: any;
  percentage: number;
  dpNameFilter = [];
  immediateRelativeList = [];
  selectedimmediateRelativeList = [];
  selectedDpName = [];
  listDpModule: any;
  dpnamelist = [];
  listDpArray = [];
  isLoading = false;
  button = 'Save';
  isLoading2 = false;
  button2 = 'Add Previous Transaction';
  submitted:boolean = false;
  submitted2:boolean = false;
  selected_receiver= [];
  dropdownSettings = {};
  recipientList = [];
  selectedRecipientItems = [];
  listDpEmail = [];
  closeResult = '';
  yesTransactionField = false;
  noTransactionField = false;
  listDpArrayItem: any;
  panItem: any;
  dpDesignation: any;
  dpdatalist:any;
  immediatePan: any;
  immediateRelative: any;
  accessUserControl: string;
  natureTransaction: any;
  dateransaction: any;
  noOfSecurities: any;
  addpreviousbtn:boolean;
  sharesConditions = [];
  valuesConditions = [];
  mymodel: any;
  complianceOfficerEmail: any;
  complianceOfficerName: any;
  complianceOfficerDesignation: any;
  companyName: any;
  prenoOfShares: any;
  dateWhenApproval: any;
  transactionExecuted: any;
  detailsOfYes: any;
  detailsOfNo: any;
  sympol: any;
  lastTwc: any;
  currentDateTime: string;
  showPreclearance = true;
  hidePreclearance = false;
  approvedListData = [];
  statusConfirm: any;
  otherstypeofsecuritys = false;
  selectedTransactionTypeFormC: AbstractControl<any, any>;
  selectedtypeofsecurity: any;
  selectednatureoftransaction: any;
  othernatureoftransaction = false;
  dp_client_id: any;
  PreviousPreclearanceid: any;
  filtered = [];
  disableTextbox:boolean = true;
  offMarketDeal = false;
  stockexchange = false;
  onSubmitprocess = [];
  errorMessages:boolean = false;
  exchangeTradeFilter: any;
  selectedStockExchange = [];
  errorShareCondition: string;
  errorValueCondition: string;
  valueConditionArr = [];
  shareConditionArr = [];
  allowedCompanyList = [];
  regstrictedCompanyList = [];
  selectedtradingcompanyname:any
  customertype: any;
  selectcustomertype = false;
  company_details: string;
  customerValue: string;
  otherscategoryPersonFormC:boolean = false;
  selectedcategoryPersonFormC: any;
  categoryperson = '';
  othercategoryperson: any;
  myDate: Date;
  benposDate: { benpos_id: any; };
  lastbenposDate: any;
  approvedBenposShares = [];
  startTwc: any;
  diffDays: any;
  sharetotalcount: any;
  sharetotalcountqty: any;
  accessModelController: any;
  pretcndropdown:boolean = false;
  preDayCount: any;
  curr_date: string;
  sixmonthvalid: string;
  hideSellSecurity:boolean = false;
  hideBuySecurity:boolean = false;
  hideColumn:boolean = false;
  previouspreclearnceLength: number;
  notradeSelect: any;
  tradingcompanyname: any;
  allowedProcess: string;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private preClearanceService: PitComplianceService,
    private dpService:DpModuleService, private modalService: NgbModal,private masterService: MasterService, private companyService: CompanyService,
    private customerService: CustomerService, public datepipe: DatePipe, private pitService: PitComplianceService) {
      this.accessUserControl = sessionStorage.getItem('access_user');
      this.currentDateTime =this.datepipe.transform((new Date), 'yyyy-MM-dd');
      this.company_details = sessionStorage.getItem('companyDetails');	
      //this.accessUser = sessionStorage.getItem('access_user');

      this.myDate = new Date();
     }


//Annual Confiremation
createDetailOfTrade(){
  return this.fb.group({
    date_of_trade: new FormControl(''),
    type_of_trade: new FormControl(''),
    shared_traded: new FormControl(''),
  })
}
addInputControlsAnnual(){
  // this.procedureCont = true;
  const add = this.pitClearanceCreateForm.get('detailOfTrade') as FormArray;
  add.push(this.createDetailOfTrade())  
 
}
removeInputControlsAnnual(idx: number){
  const add = this.pitClearanceCreateForm.get('detailOfTrade') as FormArray;  
  add.removeAt(idx);   
}
private dateRangeValidator: ValidatorFn = (): {
  [key: string]: any;
} | null => {
  let invalid = false;
  const from = this.pitClearanceCreateForm && this.pitClearanceCreateForm.get("from_Date").value;
  const to = this.pitClearanceCreateForm && this.pitClearanceCreateForm.get("to_Date").value;

  if (from && to) {
    invalid = new Date(from).valueOf() > new Date(to).valueOf();
   
  }
  return invalid ? { invalidRange: { from, to } } : null;
};
  ngOnInit(): void {
    let accessData = sessionStorage.getItem('model_access_controller');    
    this.accessModelController = JSON.parse(accessData);
    if(this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1){
      this.hideColumn = true;
      this.pitClearanceCreateForm = this.fb.group({
        dpName: new FormControl ('', Validators.required),
        dppan: new FormControl ('', Validators.required),
        dpdesignation: new FormControl ('', Validators.required),     
        application_for: new FormControl ('Self'),
        relativename: new FormControl (''),
        dpRelationship: new FormControl (''),
        relativepan: new FormControl (''),
        dp_client_id: new FormControl ('', [Validators.required, Validators.maxLength(17), Validators.minLength(17)]),
        nameofdepository: new FormControl ('', Validators.required),
        noofsecurities: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        typeofsecurity: new FormControl ('', Validators.required),
        otherstypeofsecurity: new FormControl (''),
        //proposeddate: new FormControl ('', Validators.required),
        nature_of_proposed: new FormControl ('', Validators.required),
        Estimatednumbersecur: new FormControl ('',[Validators.required, Validators.pattern("^[0-9.]*$")]),
        currentmarketprice: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        transactionisproposed: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        whethertransactionisproposed: new FormControl ('', Validators.required),
        proposedbuyer: new FormControl (''),
        last_transaction: new FormControl (''),
        nature_of_transaction: new FormControl (''),
        date_of_transaction: new FormControl ('', ),
        securities_transacted: new FormControl ('', [Validators.pattern("^[0-9.]*$")]),
        previouspcid: new FormControl (''),
        number_of_shares: new FormControl ('', [Validators.pattern("^[0-9.]*$")]),
        Date_when_approval: new FormControl (''),
        transaction_executed: new FormControl (''),
        details_of_yes: new FormControl (''),
        details_of_no: new FormControl (''),
        tradingcompanyname: new FormControl (''),
        categoryofperson: new FormControl ('',  Validators.required),
        otherscategoryofperson: new FormControl (''),
        checkboxgroup : this.fb.group({
        checkbox: [false, Validators.required],
        checkbox1: [false, Validators.required],
        checkbox2: [false, Validators.required],
        checkbox3: [false, Validators.required],
        checkbox4: [false, Validators.required],
        checkbox5: [false, Validators.required],
        checkbox6: [false, Validators.required],
        checkbox7: [false, Validators.required],
        checkbox8: [false, Validators.required],
      })
        
    
      });
    }else{
      this.hideColumn = false;
      this.pitClearanceCreateForm = this.fb.group({
        dpName: new FormControl ('', Validators.required),
        dppan: new FormControl ('', Validators.required),
        dpdesignation: new FormControl ('', Validators.required),     
        application_for: new FormControl ('Self'),
        relativename: new FormControl (''),
        dpRelationship: new FormControl (''),
        relativepan: new FormControl (''),
        dp_client_id: new FormControl ('', [Validators.required, Validators.maxLength(17), Validators.minLength(17)]),
        nameofdepository: new FormControl ('', Validators.required),
        noofsecurities: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        typeofsecurity: new FormControl ('', Validators.required),
        otherstypeofsecurity: new FormControl (''),
        //proposeddate: new FormControl ('', Validators.required),
        nature_of_proposed: new FormControl ('', Validators.required),
        Estimatednumbersecur: new FormControl ('',[Validators.required, Validators.pattern("^[0-9.]*$")]),
        //currentmarketprice: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        //transactionisproposed: new FormControl ('', [Validators.required, Validators.pattern("^[0-9.]*$")]),
        whethertransactionisproposed: new FormControl ('', Validators.required),
        proposedbuyer: new FormControl (''),
        last_transaction: new FormControl (''),
        nature_of_transaction: new FormControl (''),
        date_of_transaction: new FormControl ('', ),
        securities_transacted: new FormControl ('', [Validators.pattern("^[0-9.]*$")]),
        previouspcid: new FormControl (''),
        number_of_shares: new FormControl ('', [Validators.pattern("^[0-9.]*$")]),
        Date_when_approval: new FormControl (''),
        transaction_executed: new FormControl (''),
        details_of_yes: new FormControl (''),
        details_of_no: new FormControl (''),
        tradingcompanyname: new FormControl (''),
        categoryofperson: new FormControl ('',  Validators.required),
        otherscategoryofperson: new FormControl (''),
        checkboxgroup : this.fb.group({
        checkbox: [false, Validators.required],
        checkbox1: [false, Validators.required],
        checkbox2: [false, Validators.required],
        checkbox3: [false, Validators.required],
        checkbox4: [false, Validators.required],
        checkbox5: [false, Validators.required],
        checkbox6: [false, Validators.required],
        checkbox7: [false, Validators.required],
        checkbox8: [false, Validators.required],
      })
        
    
      });

    }
  

  
//dp
this.dpService.dpModuleList(null).subscribe(
  res => {
    
  this.listDpModule = res['data'];  
  
   this.listDpModule.map(item => {
    if(item.email == this.accessUserControl){
      this.listDpArray.push( item.dpName + ' - ' + item.email)
    }
  })
  this.dpNameFilter = [];
 
  from(this.listDpArray)
.pipe(
  tap(item => item),
  distinct(d => d)
)
.subscribe(r => this.dpNameFilter.push(r));

//Get email
this.listDpModule.map((role,email) => {
 
 
   if(role.role == 'Admin'){
    this.listDpEmail.push({item_id:role.id, item_text:role.email});
   
   }

  
})

this.recipientList = [];
from(this.listDpEmail)
.pipe(
tap(item => item),
distinct(d => d.item_text)
)
.subscribe(r => this.recipientList.push(r));
    
    },
    err => {
        console.log(err);
    }
);

//this.relativeBtn();

// this.selectedDpName = [this.accessUserControl]

this.dropdownSettings = {
  singleSelection: false,
  idField: 'item_id',
  textField: 'item_text',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 2,
  allowSearchFilter: true,
  defaultOpen: false,
};


//companyRules
this.masterService.listCompanyRules(null).subscribe(
  res => {
    this.sharesConditions = [];
    this.valuesConditions = [];
  let listCompanyRules = res?.data;
    this.statusConfirm = listCompanyRules[0]?.rule_status;
    if(listCompanyRules[0]?.rule_status =="Approved"){
    listCompanyRules[0]?.rules_model.map(item =>{
     
      if(item.rules_condition == "Share"){
        this.sharesConditions.push(item);
       
      }else{
        this.valuesConditions.push(item);        
      }    
     
    })
  }else{
    this.sharesConditions = [];
    this.valuesConditions = [];
  }
    },
    err => {
        console.log(err);
    }
);
//company details
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      let customertype =  listCompany[0].customertype;
      let preDayCounts = listCompany[0].predaycount;
      if(Number(preDayCounts) == 0 || preDayCounts =='' || preDayCounts == null){
        this.preDayCount = 7;
      }else{
        this.preDayCount = preDayCounts;
      }
      sessionStorage.setItem('customertypes', customertype);
      // if(customertype == 'Intermediary' ){
      //   this.selectcustomertype = true;
      // }
      
    }
    },
    err => {
        console.log(err);
    }
);

this.natureTransaction = '';
this.dateransaction = '';
this.noOfSecurities = '';
this.prenoOfShares = '';
this.PreviousPreclearanceid = '';
this.dateWhenApproval = '';
this.transactionExecuted = '';
this.detailsOfYes = '';
this.detailsOfNo = '';

//twc
this.preClearanceService.tradingWindowClosureList(null).subscribe(
  res => {
    let tradingWindowList = res;
    if(tradingWindowList?.data.length >0){
    this.lastTwc = tradingWindowList?.data[0]?.end_date;  
    this.startTwc = tradingWindowList?.data[0]?.start_date;   
    if(tradingWindowList?.data[0]?.tradingcompanyname != ''){
        this.tradingcompanyname = tradingWindowList?.data[0]?.tradingcompanyname.split(',');
    }
      if(this.currentDateTime < this.startTwc ||(this.startTwc < this.currentDateTime && this.lastTwc < this.currentDateTime)){
       this.allowedProcess = "Yes";
        this.hidePreclearance = false;
        this.showPreclearance = true;

      }else{
        this.allowedProcess = "No";
        console.log(this.tradingcompanyname?.length, "00")
        if(this.tradingcompanyname?.length > 0){
          this.hidePreclearance = false;
          this.showPreclearance = true;
        }else{
          this.showPreclearance = false; 
          this.hidePreclearance = true;
        }
        
        let d1:any = new Date(this.startTwc);
        let d2:any = new Date(this.lastTwc);
        this.diffDays = Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
      }
    }
this.changeTradingCompany(event);
  });
  let exchangeTradeData = [
    "BSE Ltd.",
    "Calcutta Stock Exchange Ltd.",
    "Metropolitan Stock Exchange of India Ltd.",
    "Multi Commodity Exchange of India Ltd.",
    "National Commodity & Derivatives Exchange Ltd.",
    "Indian Commodity Exchange Limited",
    "National Stock Exchange of India Ltd."      
  ]
    this.exchangeTradeFilter = exchangeTradeData.map(x => x);

  //restricted company
  
  this.customerService.customerList(null).subscribe(
    res => {
      let allowedCompanyList = [];
      let allowedCompanyLists = [];
    let listcompanyList = res.data;  
    listcompanyList.map(item =>{
        if(item.tradingpermission == 'Allowed'){
            allowedCompanyList.push(item);  
            allowedCompanyList.map(item => allowedCompanyLists.push(item.company_name) ) 
            this.allowedCompanyList = [];
            from(allowedCompanyLists)
            .pipe(
              tap(item => item),
              distinct(d => d)
            )
            .subscribe(r => this.allowedCompanyList.push(r));
          }else{
          this.regstrictedCompanyList.push(item);
        }
    })   
   
      },
      err => {
          console.log(err);
      }
  );
  let currdate = new Date();
  this.curr_date = formatDate(currdate, 'yyyy-MM-dd', 'en-US');


  }
 
  changeTradingCompany(item){
    console.log("145",this.allowedProcess)
    if(this.allowedProcess != 'Yes'){
      this.tradingcompanyname?.map(data =>{
        if(data == item){
              this.hidePreclearance = true;
              this.showPreclearance = false;
        }
      })
    }
  }

  changeFn(val) {
    let items = val?.split('- ');
    
    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      this.listDpModule = res['data'];  
      
      this.listDpArrayItem = this.listDpModule.map((data) => {
       
        if(items != undefined){
          if(data.email == items[1]){
              this.dpdatalist = data;   
              this.panItem = this.dpdatalist.dpPanNo;
              this.dpDesignation = this.dpdatalist.dpDesignation;
              this.categoryperson = this.dpdatalist.categoryofperson;    
              if(this.categoryperson == "Others"){
                this.otherscategoryPersonFormC = true;
                this.othercategoryperson = this.dpdatalist.otherscategoryofperson; 
              }else{
                this.otherscategoryPersonFormC = false;
                this.othercategoryperson = '';
              }
              if(this.dpdatalist.dpIndexId != '' || this.dpdatalist.dpClientId != ''){ 
              this.dp_client_id = this.dpdatalist.dpIndexId+ - +this.dpdatalist.dpClientId;
              }else{
                this.dpclientid(event);
              }

              this.customerValue = sessionStorage.getItem('customertypes');
              if(this.customerValue == 'Intermediary'){
                this.selectcustomertype = true;
                if(this.accessModelController.tcndropdown_access == true || this.accessModelController.tcndropname_access == 1){
                  this.pretcndropdown = true;

                }else{
                  this.pretcndropdown = false;

                }

              }
               
              this.preClearanceService.preClearanceList(null).subscribe(
                res => {
                  this.approvedListData = [];
                  let filtered = [];
                 
                  let preClearanceList = res.data;
                 // this.statusConfirm = res.data.status;
                  if(preClearanceList.length > 0){
                    this.disableTextbox = true; 
                    this.errorMessages = true;
                    // this.pitClearanceCreateForm.controls['previouspcid'].disable();
                    // this.pitClearanceCreateForm.controls['number_of_shares'].disable();
                    // this.pitClearanceCreateForm.controls['Date_when_approval'].disable();
                    // this.pitClearanceCreateForm.controls['transaction_executed'].disable();
                    // this.pitClearanceCreateForm.controls['nature_of_transaction'].disable();
                    // this.pitClearanceCreateForm.controls['date_of_transaction'].disable();
                    // this.pitClearanceCreateForm.controls['securities_transacted'].disable();
                                          
                     
                    preClearanceList.map(item =>{
                      let dpname = item.dpname.split('- ');
                      if(this.accessUserControl == dpname[1]){            
                        filtered.push(item); 
                       
                      }          
                   
                      filtered.map(item =>{
                        if(item.status == 'Approved'){                          
                          this.approvedListData.push(item);                      
       
                        }
                      })
                      if(this.approvedListData.length >0){
                        this.onSubmitprocess = this.approvedListData;
                        document.getElementById("previouspcid").setAttribute('disabled', '');                      
                        document.getElementById("number_of_shares").setAttribute('disabled', '');                      
                        document.getElementById("Date_when_approval").setAttribute('disabled', '');                      
                        document.getElementById("transaction_executed").setAttribute('disabled', '');                      
                        document.getElementById("nature_of_transaction").setAttribute('disabled', '');                      
                        document.getElementById("date_of_transaction").setAttribute('disabled', '');                      
                        document.getElementById("securities_transacted").setAttribute('disabled', '');
                      }
                      if(this.approvedListData.length >0){
                        let myDate = new Date(this.approvedListData[0].approved_date);
                        let validDate = myDate.setMonth(myDate.getMonth()+6);
                        //let validDate =   myDate.setDate(myDate.getDate() + 1 );;
                        this.sixmonthvalid = formatDate(validDate, 'yyyy-MM-dd', 'en-US')
                        if(this.customerValue == 'Company'){
                        if(this.sixmonthvalid >= this.curr_date){
                          if(this.approvedListData[0].nature_of_proposed = 'Buy (Acquisition of shares)'){
                                this.hideSellSecurity = true;
                                this.hideBuySecurity = false;
                          }else if(this.approvedListData[0].nature_of_proposed = 'Sell (disposal of shares)'){
                                this.hideSellSecurity = false;
                                this.hideBuySecurity = true;
                          }else{
                                this.hideSellSecurity = false;
                                this.hideBuySecurity = false;
                          }
                        
                        }
                      }
                      }
                  
                   
                  //Benpose
                 
                })
                    // this.approvedListData.map(item =>{
                    //       let Id = item[0].id;
                   
                          this.preClearanceService.listPreviousPreClear(null).subscribe(
                            res => {
                              let listPreviousPreClear = [];

                                  const listPreviousPreClears = res['data'];
                                  
                                  if(listPreviousPreClears.length > 0){  
                                    listPreviousPreClears?.map(item =>{
                                      if(item.preclearance_id == this.approvedListData[0]?.id)
                                      listPreviousPreClear.push(item);
                                    }) 
                                    //No Trade Approval   
                                    this.previouspreclearnceLength = listPreviousPreClear.length;
                                    this.notradeSelect = this.approvedListData[0]?.notrade; 
                                    
                                    let predaycounts = this.preDayCount;
                                    let predaycount:any;
                                    if(Number(predaycounts) == 0 || predaycounts =='' || predaycounts == null){
                                      predaycount =7;
                                    }else{
                                      predaycount = predaycounts;
                                    }
                                    let count = predaycount - 1;
                                    let currentDate = new Date();
                                    let dateofApproval = new Date(this.approvedListData[0].approved_date);
                                    dateofApproval.setDate(dateofApproval.getDate() + count );                                   
                                      if(dateofApproval < currentDate && this.previouspreclearnceLength == 0 && (this.notradeSelect != 'No Trade')){
                                        alert('You are No Tading. Can you Trade the tranaction (or) click to No Trade option in the list Page')
                                        this.router.navigate(['pit-compliance/list-pre-clearance']);
                                         return false; 
                                      }  
                                      //No Trade Approval   
                                    if(listPreviousPreClear[0]?.preclearance_id == this.approvedListData[0]?.id){
                                   
                                      
                                   if(listPreviousPreClear.length > 0){   
                                    this.transactionExecuted = 'Yes';                                   
                                    this.natureTransaction = listPreviousPreClear[0].bought_sold_subscribed;
                                    this.dateransaction = listPreviousPreClear[0].date_of_transaction;
                                    this.noOfSecurities = listPreviousPreClear[0].no_of_securirities;

                                    document.getElementById("nature_of_transaction").setAttribute('disabled', '');                      
                                    document.getElementById("date_of_transaction").setAttribute('disabled', '');                      
                                    document.getElementById("securities_transacted").setAttribute('disabled', '');
                                    }else{                                     
                                      this.transactionExecuted = 'No';
                                      
                                    }
                                  }
                                  else{
                                    
                                    this.transactionExecuted = 'No';
                                    //this.addpreviousbtn = true;
                                    this.natureTransaction = '';
                                    this.dateransaction = '';
                                    this.noOfSecurities = '';
                                  }
                                }else{
                                  this.natureTransaction = '';
                                    this.dateransaction = '';
                                    this.noOfSecurities = '';
                                    this.transactionExecuted = 'No';
                                }
                                  
                            });
  
                            this.prenoOfShares = this.approvedListData[0]?.Estimatednumbersecur;
                            if(this.approvedListData.length > 0){
                            this.PreviousPreclearanceid = 'PC - ' + this.approvedListData[0]?.id;
                            }else{
                              this.PreviousPreclearanceid = '';
                            }
                            //this.dateWhenApproval = formatDate(item[0].Date_when_approval, 'yyyy-MM-dd','en-US');
                            this.dateWhenApproval = this.approvedListData[0]?.approved_date;
                           
                        if(this.approvedListData.length > 0){
                          let firstApprovedDate = this.approvedListData[0];
                          this.pitService.listPreviousPreClear(null).subscribe(
                            res => {
                                  let listPreviousPreClear = res['data'];
                                let remainingtradingpreclearance = [];
                                 let listPreviousPreClears = [];
                                  listPreviousPreClear.map(data =>{
                                    if(data.preclearance_id === firstApprovedDate.id){
                                    listPreviousPreClears.push(data);
                                   // let previousId = 
                                   let noofsecurity = [];
                                   let countitem = 0;
                                   
                                    listPreviousPreClears.map(item =>{
                                       noofsecurity.push(item.no_of_securirities);                                       
                                    })
                                    for (var i = 0; i < noofsecurity.length; i++){ 
                                             countitem += Number(noofsecurity[i]);
                                    }
                                    
                                    if(listPreviousPreClears[0].bought_sold_subscribed == 'Sell (disposal of shares)'){
                                      this.sharetotalcount =  Number(firstApprovedDate.noofsecurities) - countitem; 
                                  }else{
                                      this.sharetotalcount =  Number(firstApprovedDate.noofsecurities) + countitem; 
                                  }
                                
                                  
                                    }else{
                                      remainingtradingpreclearance.push(data);                   
                                    }
                                  })
                                  
                  
                                  
                            });

                       
                        //benposs
                        this.pitService.benposdatelist(null).subscribe(
                          res => {  
                            let benpossComparison = res['data'];
                            if(benpossComparison.length > 0){
                              this.lastbenposDate = benpossComparison[0]?.benf_date;
                            
                              this.benposDate = {'benpos_id': this.lastbenposDate} 
                            
                            if (this.lastbenposDate !== '') {
                              this.pitService.comparisonbenpass(this.benposDate).subscribe(
                                res => {
                                  this.approvedBenposShares = [];
                                  let benpossComparison = res['data'];                                                           
                                  benpossComparison.map(item =>{
                                    if(item.pan == this.panItem){
                                      this.approvedBenposShares.push(item);
                                      if(this.noofsecurity.nativeElement.value == ''){
                                      this.sharetotalcount =item.tot_shares;
                                      }
                                      this.sharetotalcountqty =Number(item.tot_shares);                                   
        
                                    }
                                  })
                                  
                                });
        
                              }
                            }
                          });
                          if(this.sharetotalcount == '' || this.sharetotalcount == undefined ){
                              this.sharetotalcount = this.approvedListData[0].noofsecurities;
                          }
                        }
                      
                    
                  }else{
                    this.disableTextbox = false;
                    this.errorMessages = false;
                      this.pitService.benposdatelist(null).subscribe(
                        res => {  
                          let benpossComparison = res['data'];
                          if(benpossComparison.length > 0){
                            this.lastbenposDate = benpossComparison[0]?.benf_date;
                          
                            this.benposDate = {'benpos_id': this.lastbenposDate} 
                          
                          if (this.lastbenposDate !== '') {
                            this.pitService.comparisonbenpass(this.benposDate).subscribe(
                              res => {
                                this.approvedBenposShares = [];
                                let benpossComparison = res['data'];                            
                                benpossComparison.map(item =>{
                                  if(item.pan == this.panItem){
                                    this.approvedBenposShares.push(item);  
                                    //this.dateransaction = this.lastbenposDate;
                                    //this.noOfSecurities = item.tot_shares;
                                    this.sharetotalcount =item.tot_shares;
                                    //document.getElementById("date_of_transaction").setAttribute('disabled', '');                      
                                    //document.getElementById("securities_transacted").setAttribute('disabled', '');
      
                                  }
                                })
                                
                              });
      
                            }

                          }
                        });
                      
                  }

                }) 
          }
        }else{
          this.panItem = '';
          this.dpDesignation = '';        
          this.immediateRelativeList = [];
          this.selectedimmediateRelativeList = [];
          this.immediatePan = "";
          this.immediateRelative = "";
          this.natureTransaction = '';
          this.dateransaction = '';
          this.noOfSecurities = '';
          this.prenoOfShares = '';
          this.dateWhenApproval = '';
          this.transactionExecuted = '';
          this.detailsOfYes = '';
          this.detailsOfNo = '';
          this.noTransactionField = false;
          this.yesTransactionField = false;
          this.otherscategoryPersonFormC = false;
          this.categoryperson = '';
          this.othercategoryperson = '';
        }
      })
    
      
        
        },
        err => {
            console.log(err);
        }
    );
    //this.relativeBtn();
}
otherCategoryOfPersonFormC() {
  this.selectedcategoryPersonFormC = this.pitClearanceCreateForm.get('categoryofperson')    
  if(this.selectedcategoryPersonFormC?.value == "Others"){
    this.otherscategoryPersonFormC = true;
  }else{
    this.otherscategoryPersonFormC = false;
  }
}

dpclientid(event){
  //let item  = event.target.value;
  // if(item.length == 8){
  //   this.dp_client_id = item + ' - ' ;
  // }else if(item.length < 8){
  //   this.dp_client_id = item;
  // }else if(item.length > 8){
  //   this.dp_client_id = item;
  // }
  let item = event.target.value?.split("-").join("");

  item = item?.match(new RegExp('.{1,9}$|.{1,8}', 'g')).join("-");

  this.dp_client_id = item;

} 
  // onRemove(item){
  //   this.selectedDpName.splice(this.selectedDpName.indexOf(item), 1);
  //   this.dpNameFilter = this.listDpArray;
  // }
//select radio recipient button
  // Multi Select
onItemSelect(item: any) {
  this.selected_receiver.push(item);  
}
onItemDeSelect(item: any) {
  this.selected_receiver.splice(this.selected_receiver.indexOf(item), 1);
  }
onSelectAll(items: any) {
   this.selected_receiver=items; 
}
onDeSelectAll(items: any) {
  this.selected_receiver=[];   
}
  cancelPreClearanceTemplate(){
    this.router.navigate(['/pit-compliance/list-pre-clearance']);
    this.pitClearanceCreateForm.reset();
    this.submitted = false;
  }
  offMarket(){
    let itemData = this.pitClearanceCreateForm.get('whethertransactionisproposed').value;
    if(itemData == 'off-market deal'){
      this.stockexchange = false;
      this.offMarketDeal = true;
    }else{
      this.stockexchange = true;
      this.offMarketDeal = false;
    }
  }
  addPreviousPreClear(){
    this.submitted = false;
    this.isLoading2 = true;
    this.button2 = 'Processing';
    this.pitClearanceCreateForm.value.insertStatus = "Incomplete"
      this.preClearanceService.preClearanceCreate(this.pitClearanceCreateForm.value).subscribe(data =>{   
        this.isLoading = false;
        this.button = 'Save';
        this.isLoading2 = false;
        this.button2 = 'Add Previous Transaction';
        this.router.navigate(['pit-compliance/add-previous-transacrion']);
    });
  }
  othertypeofsecurity() {
    this.selectedtypeofsecurity = this.pitClearanceCreateForm.get('typeofsecurity').value;   
    if(this.selectedtypeofsecurity == "Others"){
      this.otherstypeofsecuritys = true;
    }else{
      this.otherstypeofsecuritys = false;
    }
  }
  natureoftransaction() {
    this.selectednatureoftransaction = this.pitClearanceCreateForm.get('nature_of_transaction').value;   
    if(this.selectednatureoftransaction == "Others"){
      this.othernatureoftransaction = true;
    }else{
      this.othernatureoftransaction = false;
    }
  }

  changeFnImmediate(val){ 
    let immediateRelative = this.dpdatalist.immediateDpRelative;
    
    if(val != undefined){
        immediateRelative.map(item => {
          
         if(item.name === val){
        
            let immediateArr = item;
            this.immediatePan = immediateArr.pan;
            this.immediateRelative = immediateArr.relationship;
         }
        })
      }else{
        this.immediatePan = '';
        this.immediateRelative = '';
       
      }
  }
//new changes radio btn
selectedButton: string;        


radioSelect(e: string): void {

    this.selectedButton = e;  
    this.relativeBtn();
  
}  

isSelected(name: string): boolean {  

    if (!this.selectedButton) {   
        return false;  
    }    

    return (this.selectedButton === name); 
} 
relativeBtn(){
  let dpname = this.pitClearanceCreateForm.get('dpName')?.value;
  let dpapplication = this.pitClearanceCreateForm.get('application_for')?.value;
   
    if(dpname != null){ 

    if(this.selectedButton == "Relative"){
     // let dpname = this.pitClearanceCreateForm.get('dpName')?.value;
        let immediateRelative = this.dpdatalist?.immediateDpRelative;         
        immediateRelative?.map(item => {
          this.immediateRelativeList?.push(item.name);
        })
      
      }else if(this.selectedButton == "Self"){
        
        this.immediateRelativeList = [];
        this.selectedimmediateRelativeList = [];
        this.immediatePan = "";
        this.immediateRelative = "";
        this.pitClearanceCreateForm.get('relativename').setValue("");
      }
    }else if(dpname == null && this.selectedButton == "Relative"){ 
        
      this.immediateRelativeList = [];
      this.selectedimmediateRelativeList = [];
      this.immediatePan = "";
      this.immediateRelative = "";
    }
}
transactionExcute(){
  const valueExcute = this.excute.nativeElement.value;
  if(valueExcute == 'Yes'){
        this.yesTransactionField = true;
        this.noTransactionField = false;
  }else{
    this.yesTransactionField = false;
    this.noTransactionField = true;
  }
}

  preclearancemailconfig(valId){
    this.pitClearanceCreateForm.value.preclearanceId = valId;
    this.pitClearanceCreateForm.value.complianceofficeremail =this.complianceOfficerEmail;
    this.pitClearanceCreateForm.value.complianceofficername =this.complianceOfficerName;
    this.pitClearanceCreateForm.value.complianceofficerdesignation =this.complianceOfficerDesignation;
    this.pitClearanceCreateForm.value.companyname =this.companyName;
    this.pitClearanceCreateForm.value.preDayCount= this.preDayCount;
    this.preClearanceService.preclearancemailconfig(this.pitClearanceCreateForm.value).subscribe(data =>{ 
      
    });
}

  onSubmit(){
    if(this.previouspreclearnceLength == 0 && this.notradeSelect == "")
    if(this.approvedListData.length > 0){  
      if(this.approvedListData[0].typeofsecurity == 'Equity Share'){ 
      if(this.sharetotalcountqty != undefined){     
          if(this.sharetotalcount !== this.sharetotalcountqty){          
            this.toastr.warning("Number of securities values mismatch in BENPOS and Preclearance. check your Number of securities on current date");        
              return false;
          }
      }
    }else{
      if(this.sharetotalcount !== this.pitClearanceCreateForm.get('noofsecurities').value){ 
        this.toastr.warning("Number of securities values mismatch in trading Preclearance. check your Number of securities on current date");        
          return false;
      }
    }
  }
  if(this.customerValue == 'Intermediary'){
    if(this.pitClearanceCreateForm.get('tradingcompanyname').value == undefined || this.pitClearanceCreateForm.get('tradingcompanyname').value == ''){
      this.toastr.warning("Please fill the Trading company Name.");
      return false;
    }
  } 
    this.pitClearanceCreateForm.value.complianceofficeremail =this.complianceOfficerEmail;
    this.pitClearanceCreateForm.value.complianceofficername =this.complianceOfficerName;
    this.pitClearanceCreateForm.value.complianceofficerdesignation =this.complianceOfficerDesignation;
    this.pitClearanceCreateForm.value.companyname =this.companyName;
    this.pitClearanceCreateForm.value.preDayCount= this.preDayCount;
    if(this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1){
    if(this.statusConfirm == 'Approved'){
      if(this.pitClearanceCreateForm.get('Estimatednumbersecur').value != ''){
        let totalsahresValues = this.pitClearanceCreateForm.get('Estimatednumbersecur').value * this.pitClearanceCreateForm.get('currentmarketprice').value;
        
        this.shareConditionArr = [];
            if(this.sharesConditions.length > 0){
              for(let i=0; i<this.sharesConditions.length; i++){            
                    if(this.sharesConditions[i].rules_setting == "Greater than" && this.sharesConditions[i].rules_value > totalsahresValues){
                      this.errorShareCondition = "Trading Shares Less than Company Rules. Please Check company allotment shares."
                       
                    }else if(this.sharesConditions[i].rules_setting == "Less than" && this.sharesConditions[i].rules_value < totalsahresValues ){
                      this.errorShareCondition = "Trading Shares Greater than Company Rules. Please Check company allotment shares.";
                    
                    }else if(this.sharesConditions[i].rules_setting == "Equal to" && this.sharesConditions[i].rules_value != totalsahresValues ){
                      this.errorShareCondition = "Trading Shares Not Equal to Company Rules. Please Check company allotment shares.";
                     
                    } else if(this.sharesConditions[i].rules_setting == "Greater than or equal to" && this.sharesConditions[i].rules_value >= totalsahresValues ){
                      this.errorShareCondition ="Trading Shares Less than or equal to Company Rules. Please Check company allotment shares.";
                     
                    }else if(this.sharesConditions[i].rules_setting == "Less than or equal to" && this.sharesConditions[i].rules_value <= totalsahresValues ){
                      this.errorShareCondition ="Trading Shares Greater than or equal to Company Rules. Please Check company allotment shares.";
                   }           
                  this.shareConditionArr.push(this.errorShareCondition)
              }
          
            }
          }
      
        if(this.pitClearanceCreateForm.get('transactionisproposed').value != ''){
            this.valueConditionArr = [];
            if(this.valuesConditions.length > 0){
              let totalTradingvalues = this.pitClearanceCreateForm.get('transactionisproposed').value;

                     for(let i=0; i<this.valuesConditions.length; i++){ 
                       
                         if(this.valuesConditions[i].rules_setting == "Greater than" && this.valuesConditions[i].rules_value > totalTradingvalues){
                          this.errorValueCondition = "Trading Values Less than Company Rules. Please Check company allotment Values.";
                          
                         }else if(this.valuesConditions[i].rules_setting == "Less than" && this.valuesConditions[i].rules_value < totalTradingvalues ){
                          this.errorValueCondition ="Trading Values Greater than Company Rules. Please Check company allotment Values.";
                          
                         }else if(this.valuesConditions[i].rules_setting == "Equal to" && this.valuesConditions[i].rules_value != totalTradingvalues ){
                          this.errorValueCondition ="Trading Values Not Equal to Company Rules. Please Check company allotment Values.";
                        
                         }else if(this.valuesConditions[i].rules_setting == "Greater than or equal to" && this.valuesConditions[i].rules_value >= totalTradingvalues ){
                          this.errorValueCondition ="Trading Values Less than or equal to Company Rules. Please Check company allotment Values.";
                          
                         }else if(this.valuesConditions[i].rules_setting == "Less than or equal to" && this.valuesConditions[i].rules_value <= totalTradingvalues ){
                          this.errorValueCondition = "Trading Values Greater than or equal to Company Rules. Please Check company allotment Values.";
                         
                         }
                         this.valueConditionArr.push(this.errorValueCondition)
            
                     }
           }
         } 
      }
    }else{
      if(this.statusConfirm == 'Approved'){
        if(this.pitClearanceCreateForm.get('Estimatednumbersecur').value != ''){
          let totalsahresValues = this.pitClearanceCreateForm.get('Estimatednumbersecur').value;
          
          this.shareConditionArr = [];
              if(this.sharesConditions.length > 0){
                for(let i=0; i<this.sharesConditions.length; i++){            
                      if(this.sharesConditions[i].rules_setting == "Greater than" && this.sharesConditions[i].rules_value > totalsahresValues){
                        this.errorShareCondition = "Trading Shares Less than Company Rules. Please Check company allotment shares."
                         
                      }else if(this.sharesConditions[i].rules_setting == "Less than" && this.sharesConditions[i].rules_value < totalsahresValues ){
                        this.errorShareCondition = "Trading Shares Greater than Company Rules. Please Check company allotment shares.";
                      
                      }else if(this.sharesConditions[i].rules_setting == "Equal to" && this.sharesConditions[i].rules_value != totalsahresValues ){
                        this.errorShareCondition = "Trading Shares Not Equal to Company Rules. Please Check company allotment shares.";
                       
                      } else if(this.sharesConditions[i].rules_setting == "Greater than or equal to" && this.sharesConditions[i].rules_value >= totalsahresValues ){
                        this.errorShareCondition ="Trading Shares Less than or equal to Company Rules. Please Check company allotment shares.";
                       
                      }else if(this.sharesConditions[i].rules_setting == "Less than or equal to" && this.sharesConditions[i].rules_value <= totalsahresValues ){
                        this.errorShareCondition ="Trading Shares Greater than or equal to Company Rules. Please Check company allotment shares.";
                     }           
                    this.shareConditionArr.push(this.errorShareCondition)
                }
            
              }
            }
          }
    }
        if(this.customerValue == 'Intermediary'){
          if(this.pitClearanceCreateForm.get('tradingcompanyname').value == undefined || this.pitClearanceCreateForm.get('tradingcompanyname').value == ''){
            this.toastr.warning("Please fill the Trading company Name.");
            return false;
          }
        }   
      

      this.submitted = true;
    const emptyFileds = Object.keys(this.pitClearanceCreateForm.controls).filter(key =>this.pitClearanceCreateForm.controls[key].errors !== null)
  
    
    if(this.onSubmitprocess?.length >0){
    if(this.pitClearanceCreateForm.get('number_of_shares').value == ''){
      this.toastr.warning("Please fill the Number of Shares for which approval was granted.");
      return false;
    }else if(this.pitClearanceCreateForm.get('Date_when_approval').value == ''){
      this.toastr.warning("Please fill the Date when the approval was granted.");
      return false;
    }else if(this.pitClearanceCreateForm.get('transaction_executed').value == ''){
      this.toastr.warning("Please fill the Whether the transaction was executed or not?.");
      return false;
    }
  }

    if(this.pitClearanceCreateForm.status !== 'INVALID'){
      if(this.statusConfirm == 'Approved' && this.errorShareCondition == undefined){
          this.pitClearanceCreateForm.value.autoapproval = "Approved";  
        
        
          this.isLoading = true;
          this.button = 'Processing';
          this.preClearanceService.preClearanceCreate(this.pitClearanceCreateForm.value).subscribe(data =>{   
          this.isLoading = false;
          this.button = 'Save';
          this.toastr.success("Pre Clearance Successfully Created");
          let preclearanceId = data.preclearanceId;
          this.preclearancemailconfig(preclearanceId);

          const modalRef = this.modalService.open(IntimationAlertPopupComponent,
            {
              scrollable: true,             
              windowClass: 'myCustomModalClass',
              // keyboard: false,
                // backdrop: 'static'
            });
          
          modalRef.componentInstance.title = 'Alert!..';
          modalRef.result.then(
            (result) => {
              console.log(result);
            }, (reason) => {
              console.log(reason);
            });
        
            this.router.navigate(['pit-compliance/list-pre-clearance']);
          
        })  
  }else{
    
      this.isLoading = true;
      this.button = 'Processing';
      this.pitClearanceCreateForm.value.autoapproval = "Pending"; 
    this.preClearanceService.preClearanceCreate(this.pitClearanceCreateForm.value).subscribe(data =>{   
      this.isLoading = false;
      this.button = 'Save';
      this.toastr.success("Pre Clearance Successfully Created");
      let preclearanceId = data.preclearanceId;
      this.preclearancemailconfig(preclearanceId);

      const modalRef = this.modalService.open(IntimationAlertPopupComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      
      modalRef.componentInstance.title = 'Alert!..';
      modalRef.componentInstance.predaycounts = this.preDayCount;
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
     
        this.router.navigate(['pit-compliance/list-pre-clearance']);
      
    })
  
  } 
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    this.isLoading = false;
      this.button = 'Save';
    return false;
  }else{
    if(this.pitClearanceCreateForm.get('dpName').value == ''){
      this.toastr.warning("Please fill the Name of designated person.");
    }else if(this.pitClearanceCreateForm.get('dppan').value == ''){
      this.toastr.warning("Please fill the PAN Number.");
    }
    else if(this.pitClearanceCreateForm.get('dpdesignation').value == ''){
      this.toastr.warning("Please fill the Designation.");
    }else if(this.pitClearanceCreateForm.get('dp_client_id').value == ''){
      this.toastr.warning("Please fill the DP ID / Client ID No.");
    }else if(this.pitClearanceCreateForm.get('nameofdepository').value == ''){
      this.toastr.warning("Please fill the Name of Depository.");
    }else if(this.pitClearanceCreateForm.get('noofsecurities').value == ''){
      this.toastr.warning("Please fill the Number of securities held as on date.");
    }else if(this.pitClearanceCreateForm.get('typeofsecurity').value == ''){
      this.toastr.warning("Please fill the Type of Security.");
    }else if(this.pitClearanceCreateForm.get('nature_of_proposed').value == ''){
      this.toastr.warning("Please fill the Nature of proposed transaction.");
    }else if(this.pitClearanceCreateForm.get('Estimatednumbersecur').value == ''){
      this.toastr.warning("Please fill the Estimated number of securities proposed.");
    }else if(this.pitClearanceCreateForm.get('categoryofperson').value == ''){
      this.toastr.warning("Please fill the Category of Person.");
    }else if(this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox1').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox2').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox3').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox4').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox5').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox6').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox7').value == false || 
    this.pitClearanceCreateForm.get('checkboxgroup').get('checkbox8').value == false ){
      this.toastr.warning("Please Accept the Declaration.");
    } else if(this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1){
      if(this.pitClearanceCreateForm.get('currentmarketprice').value == ''){
        this.toastr.warning("Please fill the Current market price .");
      }else if(this.pitClearanceCreateForm.get('transactionisproposed').value == ''){
        this.toastr.warning("Please fill the Price at which transaction is proposed.");
      }
    } 
    
    
  }
   
   
} 
  onSelectedQty(){
    const quantity1 = this.sQty1.nativeElement.value;
    const quantity2 = this.sQty2.nativeElement.value;
    const quantity3 = this.sQty3.nativeElement.value;
    this.quantity = quantity1 / quantity2 ;
    this.percentage = quantity3 / quantity2 ;
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 



}
