<div class="container-fluid">
    <div class="row" *ngIf = "is_access_no">
      <div class="col-sm-12">
        <div class="list-btn" >
            <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
          <!-- <button class="btn btn-primary me-1" (click)="openModalRestricted()">New</button>                   -->
        </div>
        
        <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs tab-active2">
            <li [ngbNavItem]="1" >
                <a ngbNavLink>All Company's List</a>
                <ng-template ngbNavContent>
                <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                    <!-- Container-fluid starts -->
                    <div class="container-fluid">
                        <div class="row" >
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-md-6 mb-3">                      
                                            <div class="form-group">
                                            <input type="text" class="form-control" id="filterString3" [(ngModel)]="filterString3" (ngModelChange)="onFilterChange3()" placeholder="Search Company by Company Name " >                                      
                                        </div>
                                            </div>
                                        
                                    </div>
                                    <div class="card-body custom-datatable noscroll">
                                        <div class="label-form" *ngIf="isSelected">
                                            <form [formGroup]="allowedcompanyCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                                                <div class="row">
                                                    <div class="col-md-4 mb-3 selectLabel">
                                                        <div class="form-group">                            
                                                            <div class="col-md-12 mb-3">
                                                                <label for="nseScripCode">Permission of Trading for Company</label>
                                                                <div class="form-group">
                                                                    <select class="form-select" class="form-control form-select" id="permission"  formControlName="permission" required (change) = "changeItem($event)"
                                                                    [ngClass]="{ 'is-invalid': (submitted || allowedcompanyCreateForm.controls.permission.touched) && allowedcompanyCreateForm.controls.permission.errors }">
                                                                        <option value="" disabled selected>--Choose One --</option>                                               
                                                                        <option value="Allowed">Allowed</option>
                                                                        <option value="Restricted">Restricted</option>   
                                                                    </select> 
                                                                    <div *ngIf="(submitted ||allowedcompanyCreateForm.controls.permission.touched) && allowedcompanyCreateForm.controls.permission.errors?.required" class="text text-danger">
                                                                        Permission of Trading for Company is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3 selectLabel">
                                                        <div class="form-group">
                                                            <label for="projectName">Send email to following category</label>

                                                            <ng-multiselect-dropdown [placeholder]="'Select Label'"
                                                            [settings]="dropdownSettings2"
                                                            [data]="labelDataList"
                                                            [(ngModel)]="selectedLabelDataItems"
                                                            (onSelect)="onItemSelect1($event, 'label')"
                                                            (onDeSelect)="onItemDeSelect1($event, 'label')"
                                                            (onSelectAll)="onSelectAll1($event, 'label')"
                                                            (onDeSelectAll)="onDeSelectAll1($event, 'label')" formControlName="groupLabelName"
                                                                >
                                                        
                                                            </ng-multiselect-dropdown>
                                                            <!-- <div *ngIf="(submitted ||allowedcompanyCreateForm.controls.groupLabelName.touched) && allowedcompanyCreateForm.controls.groupLabelName.errors?.required"
                                                            class="text text-danger">
                                                            Send email to following category is required.
                                                        </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3 selectLabel">
                                                        <div class="form-group">
                                                            <label for="projectName">Send email to following Insider</label>

                                                            <!-- <ng-select [items]="insiderEmailList"
                                                                    bindLabel=""
                                                                    placeholder="Select Insider"
                                                                    (change) = "changeItem($event)"
                                                                    [(ngModel)]="selectedinsideremail"  formControlName="insideremail">
                                                            </ng-select> -->
                                                            <ng-multiselect-dropdown [placeholder]="'Select Insider'"
                                                            [settings]="dropdownSettings2"
                                                            [data]="insiderEmailList"
                                                            [(ngModel)]="selectedinsideremail"
                                                            (onSelect)="onItemSelect1($event, 'insider')"
                                                            (onDeSelect)="onItemDeSelect1($event, 'insider')"
                                                            (onSelectAll)="onSelectAll1($event, 'insider')"
                                                            (onDeSelectAll)="onDeSelectAll1($event, 'insider')"
                                                              formControlName="insideremail"
                                                                >
                                                        
                                                            </ng-multiselect-dropdown>
                                                            <div *ngIf="restrictedAllow" class="text text-danger">                                                               
                                                               Send email to following Insider is required.
                                                              
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                       
                                                        <div class="col-md-4 mb-3 selecttradingbtn">
                                                        <button class="btn btn-primary me-1" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                                                        </div>
                                                    
                                                    </div>
                                            </form>
                                        </div>

                                        <div class="custom-datatable">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" sortable="permission" *ngIf="is_access_write"></th>
                                                        <th scope="col" sortable="name">S.No.</th>
                                                        <th scope="col" sortable="name" >Company Name </th>
                                                        <th scope="col" sortable="name" >Permission Status</th>
                                                        <th scope="col" sortable="name">Action</th>
    
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let items of filtered3 | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                                    <td scope="row" data-content="#" *ngIf="is_access_write"><input type="checkbox" #label (click)="isSelectLabel($event, items)" [(ngModel)]="items.checked"></td>
                                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                    <td scope="row" data-content="Company Name">{{items.company_name}}</td>                                
                                                    <td scope="row" data-content="Permission Status" *ngIf="items.tradingpermission == 'Allowed'" ><span class="badge badge-success">{{items.tradingpermission}}</span></td>                                
                                                    <td scope="row" data-content="Permission Status" *ngIf="items.tradingpermission == 'Restricted'" ><span class="badge badge-danger">{{items.tradingpermission}}</span></td>                                
                                                    <td scope="row" data-content="Permission Status" *ngIf="items.tradingpermission == null || items.tradingpermission == ''" ><span class="badge badge-warning">Not Provided</span></td>                                
                                                    <td data-content="Action">
                                                        <button class="edit-icon" title="View Log" (click)="openModalView(items.id)"><i class="fa fa-eye"></i></button>
                                                        <!-- <button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button> -->
                                                        <!-- <button class="edit-icon" title="Delete" (click)="openModalDelete(items.id)"><i class="fa fa-trash"></i></button></td> -->
                                                    
                                                </tr>
                                                </tbody>
                                            </table>
    
                                            <div class="list-btn2" *ngIf="filtered3?.length =='' ">
                                                <p>No Data Found</p>
                                            </div>
                                            
                                        <div class="d-flex justify-content-between p-2">
                                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered3?.length"></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </ng-template>
                </li>
          <li [ngbNavItem]="2" >
            <a ngbNavLink>Allowed Company's List</a>
            <ng-template ngbNavContent>
            <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                <!-- Container-fluid starts -->
                <div class="container-fluid">
                    <div class="row" >
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col-md-6 mb-3">                      
                                        <div class="form-group">
                                        <input type="text" class="form-control" id="filterString" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()" placeholder="Search Company by Company Name " >                                      
                                    </div>
                                        </div>
                                    
                                </div>
                                <div class="card-body custom-datatable noscroll">
                                    <div class="custom-datatable">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="name">S.No.</th>
                                                    <th scope="col" sortable="name" >Allowed Company Name </th>
                                                    <th scope="col" sortable="name">Action</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor="let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                <td scope="row" data-content="Allowed &#xa; Company Name">{{items.company_name}}</td>                                
                                                <td data-content="Action">
                                                    <button class="edit-icon" title="View Log" (click)="openModalView(items.id)"><i class="fa fa-eye"></i></button>
                                                    <!-- <button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button> -->
                                                    <!-- <button class="edit-icon" title="Delete" (click)="openModalDelete(items.id)"><i class="fa fa-trash"></i></button></td> -->
                                                
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                                            <p>No Data Found</p>
                                        </div>
                                        
                                    <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Restricted Company's List</a>
                <ng-template ngbNavContent>
                  <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                    <!-- Container-fluid starts -->
                    <div class="container-fluid">
                        <div class="row" >
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-md-6 mb-3">                      
                                            <div class="form-group">
                                            <input type="text" class="form-control" id="filterString" [(ngModel)]="filterString2" (ngModelChange)="onFilterChange2()"  placeholder="Search Company by Restricted Company Name " >                                      
                                        </div>
                                            </div>
                                       
                                    </div>
                                    <div class="card-body custom-datatable noscroll">
                                        <div class="custom-datatable">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" sortable="name">S.No.</th>
                                                        <th scope="col" sortable="name" >Restricted Company Name </th>
                                                        <th scope="col" sortable="name">Action</th>
    
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let items of filtered2 | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                    <td scope="row" data-content="Restricted &#xa; Company Name">{{items.company_name}}</td>                                
                                                    <td data-content="Action">
                                                        <button class="edit-icon" title="View Log" (click)="openModalView(items.id)"><i class="fa fa-eye"></i></button>
                                                        <!-- <button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button> -->
                                                        <!-- <a [routerLink]="['/project/project-delete', items.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-trash"></i></button></a>   -->
                                                        <!-- <button class="edit-icon" title="Delete" (click)="openModalDelete(items.id)"><i class="fa fa-trash"></i></button></td> -->
                                                    
                                                </tr>
                                                </tbody>
                                            </table>
    
                                            <div class="list-btn2" *ngIf="filtered2?.length =='' ">
                                                <p>No Data Found</p>
                                            </div>
                                            
                                        <div class="d-flex justify-content-between p-2">
                                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered2?.length"></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                  </div>
                </ng-template>
            </li>
         
            
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
    <div *ngIf = "!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
  </div>