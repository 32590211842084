import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-model-report',
  templateUrl: './popup-model-report.component.html',
  styleUrls: ['./popup-model-report.component.scss']
})
export class PopupModelReportComponent implements OnInit {
  @Input() viewUpsiList;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    console.log(this.viewUpsiList);
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
