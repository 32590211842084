import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DpModuleService {
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl
  
 dpModuleList(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')
    // const httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   // 'Authorization': this.accessToken
    // });
    return this.http.post(this.baseUrl + 'dp/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      // observe: 'response'
    });
  }
  addDpModule(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "dp/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
   }

   viewDpModule(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "dp/view", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
   editDpModule(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "dp/edit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  updateDpModule(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "dp/update" , userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  deleteDpModule(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'dp/delete' , userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
}
deleteModuleList(userObj:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'dp/deleteDp', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}

dpModuleActivity(userObj:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'dp/dpActivityHistory', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
viewActivitydp(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/dpActivityHistoryView", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
sendJSONToServerDp(reqData:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
   return this.http.post(this.baseUrl + 'dp/indexexcelimport',reqData, {  
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}


//labelDP
addDpLabelModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/labelcreate", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
 }
 listDpLabelModule(userObj:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'dp/labellist', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}

newDpLabelModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dpLabel/add", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
 }

 updateDpLabelModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/updateLabel", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 revertDpModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/revertdp", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
reasonRorDeleteDp(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/reasonfordelete", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
//onchange api
onChangeRoleModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/rolechange" , userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
getDPSelfRecord(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/getDPSelfRecord" , userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 })
}
updateProfile(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/getUpdateprofile" , userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},

 })
}
}
