

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">                             
                  <a routerLink="/project/activity-log"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewUpsiActivityList?.data[0]">
                                    <tr>
                                        <td scope="row">Action Flag</td>
                                        <td scope="row">{{viewUpsiActivityList?.data[0].action_flag}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Created By</td>
                                        <td scope="row">{{viewUpsiActivityList?.data[0].created_by}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Project Name</td>
                                        <td scope="row">{{viewUpsiActivityList?.data[0].project_name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewUpsiActivityList?.data[0].purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     
                            </tbody>
                        </table>
                       
                </div>
            </div>
        </div>
    </div>
 </div> -->

 <div class="modal-header">
    <h5 class="modal-title">View {{title}} Change log</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                   
                    <div class="card-body custom-datatable noscroll">
                        <div class="custom-datatable">
                            
                            <table class="table">                            
                                <tbody *ngIf="viewUpsiActivityList">
                                    <tr>
                                        <td scope="row">Project/Event Name</td>
                                        <td scope="row">{{viewUpsiActivityList.project_name}}</td>                                         
                                     </tr> 
                                    
                                     <tr *ngIf = "viewUpsiActivityList.SenderDPPanno == null">
                                       <td scope="row">Sender Name</td>
                                       <td scope="row">{{viewUpsiActivityList.SenderDPName}} - {{viewUpsiActivityList.sender}} - {{viewUpsiActivityList.SenderDPPanno1}}</td>                                         
                                    </tr> 
                                     <tr *ngIf = "viewUpsiActivityList.SenderDPPanno !== null">                                  
                                        <td scope="row">Sender Name</td>
                                        <td scope="row">{{viewUpsiActivityList.SenderDPName}} - {{viewUpsiActivityList.sender}} - {{viewUpsiActivityList.SenderDPPanno}}</td>                                         
                                     </tr> 
                                     <tr *ngIf = "viewUpsiActivityList.RecepientDPPanno == null">
                                        <td scope="row">Recipient Name</td>
                                        <td scope="row">{{viewUpsiActivityList.RecepientDPName}} - {{viewUpsiActivityList.recipient}} - {{viewUpsiActivityList.RecepientDPPanno1}}</td>                                         
                                     </tr>
                                     <tr *ngIf = "viewUpsiActivityList.RecepientDPPanno !== null">
                                       <td scope="row">Recipient Name</td>
                                       <td scope="row">{{viewUpsiActivityList.RecepientDPName}} - {{viewUpsiActivityList.recipient}} - {{viewUpsiActivityList.RecepientDPPanno}}</td>                                         
                                    </tr>
                                      
                                     <tr>
                                        <td scope="row">Mode of Sharing</td>
                                        <td scope="row">{{viewUpsiActivityList.mode_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiActivityList.mode_of_sharing == 'Others'">
                                       <td scope="row">Others Mode of Sharing</td>
                                       <td scope="row">{{viewUpsiActivityList.others_mos}}</td>                                         
                                    </tr>
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewUpsiActivityList.purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Nature of UPSI</td>
                                        <td scope="row">{{viewUpsiActivityList.nature_of_upsi}} <div *ngIf="viewUpsiActivityList.nature_of_upsi !== 'Others'" > {{viewUpsiActivityList.others_nature_upsi}}</div></td>                                         
                                     </tr>
                                     <tr *ngIf="viewUpsiActivityList.nature_of_upsi == 'Others'">
                                       <td scope="row">Others Nature of UPSI</td>
                                       <td scope="row">{{viewUpsiActivityList.others_nos}}</td>                                         
                                    </tr>
                                       
                                     <tr>
                                        <td scope="row">Sharing Frequency</td>
                                        <td scope="row">{{viewUpsiActivityList.type_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Date of Sharing</td>
                                        <td scope="row">{{viewUpsiActivityList.date_of_sharing | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                     </tr> 
                                     <!-- <tr>
                                        <td scope="row">UPSI From Date</td>
                                        <td scope="row">{{viewUpsiActivityList.from_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>  -->
                                     <tr *ngIf="viewUpsiActivityList.to_date != '' && viewUpsiActivityList.to_date != null && viewUpsiActivityList.to_date != '0000-00-00' ">
                                        <td scope="row">Will This Information Be Made Public?</td>
                                        <td scope="row">{{viewUpsiActivityList.to_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiActivityList.to_date == '' || viewUpsiActivityList.to_date == null || viewUpsiActivityList.to_date == '0000-00-00' ">
                                       <td scope="row">Will This Information Be Made Public?</td>
                                       <td scope="row">Not Published</td>                                         
                                    </tr>  
                                    <tr *ngIf="viewUpsiActivityList.remarks !=''">
                                       <td scope="row">Description, if any</td>
                                       <td scope="row">{{viewUpsiActivityList.remarks}}</td>                                         
                                    </tr>
                                    <tr *ngIf="modelAccess.upsiattachment_access == true && viewUpsiList?.confidentiality_agreement_attachment !='' ">
                                       <td scope="row" >UPSI Attachment</td>
                                       <td scope="row" ><ul *ngFor="let item of attachmentFiles"><li><a href="{{confidencialUrl}}/attachmentFiles/{{viewUpsiActivityList.sender}}/{{item}}" target="_blank">{{item}}</a></li></ul></td>                                         
                                    </tr>                                 
                                    
                                     <tr>
                                        <td scope="row" colspan="2">Timeline of Changes</td>                                        
                                     </tr> 
                                     <tr>
                                        <td scope="row">Record</td>
                                        <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.Record}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Activity</td>
                                        <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.action_flag}}</td>                                         
                                     </tr> 
                                     <tr>
                                       <td scope="row">Action By (Username)</td>
                                       <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.ActionBy}} </td>                                         
                                    </tr>                                    
                                    <tr>
                                       <td scope="row">Action Date</td>
                                       <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                    </tr>
                                    <tr *ngIf="viewUpsiActivityList?.timelineActivity[0]?.activity != 'Created'">
                                       <td scope="row">Reason for Change</td>
                                       <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.reason}}</td>                                         
                                    </tr>
                                    <tr *ngIf="viewUpsiActivityList?.timelineActivity[0]?.activity != 'Created'">
                                       <td scope="row">Change Details</td>
                                       <td scope="row">{{viewUpsiActivityList?.timelineActivity[0]?.changedetails}}</td>                                         
                                    </tr>
                                                                 
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
