import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';
import { UpsiService } from '../upsi.service';
@Component({
  selector: 'app-noupsipopup',
  templateUrl: './noupsipopup.component.html',
  styleUrls: ['./noupsipopup.component.scss']
})
export class NoupsipopupComponent implements OnInit {
  @Input() viewUpsiList;
  @Input() viewNoUpsi;
  public active1 = 1;
  noUpsiCreateForm: FormGroup;

  baseUrl = environment.apiUrl;
  title: string;
  confupsilist: string;
  isLoading = false;
  button = 'Yes';
  submitted = false;
  constructor(private upsiService: UpsiService, public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, ) { }

  ngOnInit() {
  
    this.noUpsiCreateForm = this.fb.group({
      noupsi: new FormControl(''),
      upsiCheckedList: new FormControl(''),
      reasonformove: new FormControl('', Validators.required)
    });
//  this.getIdDp = this.pdfTradingFormList.id;

    if(this.viewNoUpsi == 'Non UPSI Communication'){
        this.title = 'Non UPSI Communication';
        // this.confupsilist = 'Are you sure want the Moved to No UPSI List';
        this.confupsilist = 'Non UPSI Communication';
    }else{
      this.title = 'UPSI';
      this.confupsilist = 'UPSI';

    }

  }
 
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  noupsimail(){
    this.noUpsiCreateForm.value.upsiCheckedList = this.viewUpsiList;
    this.noUpsiCreateForm.value.noupsi = this.viewNoUpsi;
     this.upsiService.noupsimail(this.noUpsiCreateForm.value).subscribe(data =>{     
      
     }) 
  }
  onSubmit() {
    this.submitted = true;
    this.noUpsiCreateForm.value.upsiCheckedList = this.viewUpsiList;
     this.noUpsiCreateForm.value.noupsi = this.viewNoUpsi;
     if(this.noUpsiCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing'; 
     this.upsiService.updateNoUpsiModule(this.noUpsiCreateForm.value).subscribe(data =>{ 
      this.isLoading = false;
      this.button = 'Yes';    
      // this.toastr?.success("No UPSI Added Successfully");
      if(this.viewNoUpsi == 'No UPSI List'){
        this.toastr?.success("Successfully Added No UPSI List");
      }else{
        this.toastr?.success("Successfully Added UPSI List");
      }
      if(data.success = '1'){
        this.noupsimail();
     }
       window.location.reload();
     
    }) 
  }else{
      if(this.noUpsiCreateForm.get('reasonformove').value == ''){
        this.toastr.warning("Please Mention the Reason for Move {{confupsilist}}.");
      }
    }
  }

}
