import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { PitComplianceService } from '../../pit-compliance.service';
import { DeleteFormCAndFormDComponent } from '../delete-form-c-and-form-d/delete-form-c-and-form-d.component';
import { PdfViewFormatComponent } from '../pdf-view-format/pdf-view-format.component';
import { ViewFormCAndFormDComponent } from '../view-form-c-and-form-d/view-form-c-and-form-d.component';

@Component({
  selector: 'app-list-form-c-and-form-d',
  templateUrl: './list-form-c-and-form-d.component.html',
  styleUrls: ['./list-form-c-and-form-d.component.scss']
})
export class ListFormCAndFormDComponent implements OnInit {
  hideFormC:boolean = false;
  hideFormD:boolean = false;
  public active1 = 1;
  public active2 = 3;
  formCListItem = [];
  formDListItem = [];
  page = 1;
  pageSize = 10;
  filterString = '';
  filterString2 = '';
  filtered: any;
  filtered2: any;
  dropdownSettings1:IDropdownSettings;
  hidelist = [];  
  hidelist2 = [];  
  selectedhideItems = [];
  selectedhideItems2 = [];
  show_cname = true;
  show_name = true;
  show_pan = true;
  show_cnumber = true;
  show_stock = true;
  show_address=false;
  show_cin=false;
  show_cperson = false;
  show_prior = false;
  show_acquired = false;
  show_post = false;
  show_advice = false;
  show_intimate = false;
  show_mode = false;
  show_cnameD = true;
  show_nameD = true;
  show_panD = true;
  show_cnumberD = true;
  show_stockD = true;
  show_addressD=false;
  show_cinD=false;
  show_cpersonD = false;
  show_priorD = false;
  show_acquiredD = false;
  show_postD = false;
  show_adviceD = false;
  show_intimateD = false;
  show_modeD = false;
  //filter area
  visible: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false
  visible6: boolean = false
  visible7: boolean = false
  visible8: boolean = false
  visible9: boolean = false
  visibleD: boolean = false
  visible2D: boolean = false
  visible3D: boolean = false
  visible4D: boolean = false
  visible5D: boolean = false
  visible6D: boolean = false
  visible7D: boolean = false
  visible8D: boolean = false
  visible9D: boolean = false
  companyFilterString = '';
  nameFilterString = '';
  panNameFilterString = '';
  exchangeTradenameFilterString = '';
  securitiesPriorFilterString = '';
  securitiesAcquiredFilterString = '';
  securitiesPostFilterString = '';
  transactionTypeFilterString= '';
  modeAcquisitionFilterString = '';
  companyFilterStringD = '';
  nameFilterStringD = '';
  panNameFilterStringD = '';
  exchangeTradenameFilterStringD = '';
  securitiesPriorFilterStringD = '';
  securitiesAcquiredFilterStringD = '';
  securitiesPostFilterStringD = '';
  transactionTypeFilterStringD = '';
  modeAcquisitionFilterStringD = '';

  keyword = 'companyName';
  keyword2 = 'name';
  keyword3 = 'pan_no';
  keyword4 = 'exchangeTrade';
  keyword5 = 'typeOfSecurities';
  keyword6 = 'typeOfSecurities2';
  keyword7 = 'transactionType';
  keyword8 = 'typeOfSecuritiespost';
  keyword9 = 'exchangeTrade';
  keywordD = 'modeOfAcquisition';
  companyNameFilter = [];
  nameFilter = [];
  panNameFilter = [];
  exchangeTradeNameFilter = [];
  securitiesPriorNameFilter = [];
  securitiesAcquiredNameFilter = [];
  transactionTypeNameFilter = [];
  securitiesPostNameFilter = [];
  modeAcquisitionNameFilter = [];
  companyNameFilterD = [];
  nameFilterD = [];
  panNameFilterD = [];
  exchangeTradeNameFilterD = [];
  securitiesPriorNameFilterD = [];
  securitiesAcquiredNameFilterD = [];
  transactionTypeNameFilterD = [];
  securitiesPostNameFilterD = [];
  modeAcquisitionNameFilterD = [];
  emptyFormData: any;

  constructor(private pitService: PitComplianceService, private modalService: NgbModal) { }
//MultiSelect HideShow
onItemSelect(item: any,page) {    
  if(page == "formC"){

    if(this.selectedhideItems.length > 7){   
      document.getElementById('formTable').classList.add('tablescroll');
    }else{
      document.getElementById('formTable').classList.remove('tablescroll');
    }
   if(item.id == 1){
     this.show_cname = true;
   }else if(item.id == 2){
     this.show_name = true;
   }else if(item.id == 3){
     this.show_pan = true;
   }else if(item.id == 4){
     this.show_cnumber = true;
   }else if(item.id == 5){
     this.show_stock = true;
   }else if(item.id == 6){
      this.show_address = true;
    }else if(item.id == 7){
      this.show_cin = true;
    }else if(item.id == 8){
     this.show_cperson = true;
   }else if(item.id == 9){
     this.show_prior = true;
   }else if(item.id == 10){
    this.show_acquired = true;
  }else if(item.id == 11){
    this.show_post = true;
  }else if(item.id == 12){
    this.show_advice = true;
  }else if(item.id == 13){
    this.show_intimate = true;
  }else if(item.id == 14){
    this.show_mode = true;
  }
  }
  if(page == "formD"){    
    if(this.selectedhideItems2.length > 7){   
      document.getElementById('formTableD').classList.add('tablescroll');
    }else{
      document.getElementById('formTableD').classList.remove('tablescroll');
    }
   if(item.id == 1){
     this.show_cnameD = true;
   }else if(item.id == 2){
     this.show_nameD = true;
   }else if(item.id == 3){
     this.show_panD = true;
   }else if(item.id == 4){
     this.show_cnumberD = true;
   }else if(item.id == 5){
     this.show_stockD = true;
   }else if(item.id == 6){
      this.show_addressD = true;
    }else if(item.id == 7){
      this.show_cinD = true;
    }else if(item.id == 8){
     this.show_cpersonD = true;
   }else if(item.id == 9){
     this.show_priorD = true;
   }else if(item.id == 10){
    this.show_acquiredD = true;
  }else if(item.id == 11){
    this.show_postD = true;
  }else if(item.id == 12){
    this.show_adviceD = true;
  }else if(item.id == 13){
    this.show_intimateD = true;
  }else if(item.id == 14){
    this.show_modeD = true;
  }
  }
 }
 
 onItemDeSelect(item: any,page) {  
 if(page == "formC"){
  if(this.selectedhideItems.length > 7){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   if(item.id == 1){
     this.show_cname = false;
   }else if(item.id == 2){
     this.show_name = false;
   }else if(item.id == 3){
     this.show_pan = false;
   }else if(item.id == 4){
     this.show_cnumber = false;
   }else if(item.id == 5){
     this.show_stock = false;
   }else if(item.id == 6){
      this.show_address = false;
    }else if(item.id == 7){
      this.show_cin = false;
    }else if(item.id == 8){
     this.show_cperson = false;
   }else if(item.id == 9){
     this.show_prior = false;
   }else if(item.id == 10){
     this.show_acquired = false;
   }else if(item.id == 11){
     this.show_post = false;
   }else if(item.id == 12){
     this.show_advice = false;
   }else if(item.id == 13){
     this.show_intimate = false;
   }else if(item.id == 14){
     this.show_mode = false;
   }
 }
 if(page == "formD"){
  if(this.selectedhideItems2.length > 7){   
    document.getElementById('formTableD').classList.add('tablescroll');
  }else{
    document.getElementById('formTableD').classList.remove('tablescroll');
  }
   if(item.id == 1){
     this.show_cnameD = false;
   }else if(item.id == 2){
     this.show_nameD = false;
   }else if(item.id == 3){
     this.show_panD = false;
   }else if(item.id == 4){
     this.show_cnumberD = false;
   }else if(item.id == 5){
     this.show_stockD = false;
   }else if(item.id == 6){
      this.show_addressD = false;
    }else if(item.id == 7){
      this.show_cinD = false;
    }else if(item.id == 8){
     this.show_cpersonD = false;
   }else if(item.id == 9){
     this.show_priorD = false;
   }else if(item.id == 10){
     this.show_acquiredD = false;
   }else if(item.id == 11){
     this.show_postD = false;
   }else if(item.id == 12){
     this.show_adviceD = false;
   }else if(item.id == 13){
     this.show_intimateD = false;
   }else if(item.id == 14){
     this.show_modeD = false;
   }
 }
 }
 onSelectAll(items: any,page) {  
 if(page == "formC"){
  
  if(items.length > 7){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   this.show_cname = true;
   this.show_name = true;
   this.show_pan = true;
   this.show_cnumber = true;
   this.show_stock = true;
   this.show_address=true;
   this.show_cin=true;
   this.show_cperson = true;
   this.show_prior = true;
   this.show_acquired = true;
   this.show_post = true;
   this.show_advice = true;
   this.show_intimate = true;
   this.show_mode = true;
  }
  if(page == "formD"){
  
    if(items.length > 7){   
      document.getElementById('formTableD').classList.add('tablescroll');
    }else{
      document.getElementById('formTableD').classList.remove('tablescroll');
    }
     this.show_cnameD = true;
     this.show_nameD = true;
     this.show_panD = true;
     this.show_cnumberD = true;
     this.show_stockD = true;
     this.show_addressD=true;
     this.show_cinD=true;
     this.show_cpersonD = true;
     this.show_priorD = true;
     this.show_acquiredD = true;
     this.show_postD = true;
     this.show_adviceD = true;
     this.show_intimateD = true;
     this.show_modeD = true;
    }
  
 }
 onDeSelectAll(items: any,page) {
 if(page == "formC"){
  if(items.length > 7){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   this.show_cname = false;
   this.show_name = false;
   this.show_pan = false;
   this.show_cnumber = false;
   this.show_stock = false;
   this.show_address=false;
   this.show_cin=false;
   this.show_cperson = false;
   this.show_prior = false;
   this.show_acquired = false;
   this.show_post = false;
   this.show_advice = false;
   this.show_intimate = false;
   this.show_mode = false;
  
  }
  if(page == "formD"){
    if(items.length > 7){   
      document.getElementById('formTableD').classList.add('tablescroll');
    }else{
      document.getElementById('formTableD').classList.remove('tablescroll');
    }
     this.show_cnameD = false;
     this.show_nameD = false;
     this.show_panD = false;
     this.show_cnumberD = false;
     this.show_stockD = false;
     this.show_addressD=false;
     this.show_cinD=false;
     this.show_cpersonD = false;
     this.show_priorD = false;
     this.show_acquiredD = false;
     this.show_postD = false;
     this.show_adviceD = false;
     this.show_intimateD = false;
     this.show_modeD = false;
    
    }
 }
 // Multi Select
  ngOnInit(): void {

    this.pitService.listFormCandD(null).subscribe(
      res => {
            const formtradeData = res['data'];
            this.emptyFormData = formtradeData
            
          formtradeData.map( item =>{
            if(item.formTypes == 'Form C'){
              this.formCListItem.push(item);
              this.filtered = this.formCListItem;
              
              //company Name
              let formCompany = this.formCListItem.map(res => res.companyName)
              this.companyNameFilter = [];
              from(formCompany)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.companyNameFilter.push(r));
              // Name
              let formName = this.formCListItem.map(res => res.name)
              this.nameFilter = [];
              from(formName)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.nameFilter.push(r));
              // PAN
              let formPan = this.formCListItem.map(res => res.pan_no)
              this.panNameFilter = [];
              from(formPan)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.panNameFilter.push(r));
              // Exchange Trade
              let formexchangeTrade= this.formCListItem.map(res => res.exchangeTrade)
              this.exchangeTradeNameFilter = [];
              from(formexchangeTrade)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.exchangeTradeNameFilter.push(r));
              // securitiesprior
              let formsecuritiesprior= this.formCListItem.map(res => res.typeOfSecurities)
              this.securitiesPriorNameFilter = [];
              from(formsecuritiesprior)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesPriorNameFilter.push(r));
              // securities Acquired
              let formsecuritiesAcq= this.formCListItem.map(res => res.typeOfSecurities2)
              this.securitiesAcquiredNameFilter = [];
              from(formsecuritiesAcq)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesAcquiredNameFilter.push(r));
              // securities transaction type
              let formsecuritiesTrans= this.formCListItem.map(res => res.transactionType)
              this.transactionTypeNameFilter = [];
              from(formsecuritiesTrans)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.transactionTypeNameFilter.push(r));
              // securities post
              let formsecuritiesPost = this.formCListItem.map(res => res.typeOfSecuritiespost)
              this.securitiesPostNameFilter = [];
              from(formsecuritiesPost)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesPostNameFilter.push(r));
              // mode Of Acquisition
              let formModeAcquisition = this.formCListItem.map(res => res.modeOfAcquisition)
              this.modeAcquisitionNameFilter = [];
              from(formModeAcquisition)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.modeAcquisitionNameFilter.push(r));

            }else{
              this.formDListItem.push(item);
              this.filtered2 = this.formDListItem;
            //company Name
              let formCompany = this.formDListItem.map(res => res.companyName)
              this.companyNameFilterD = [];
              from(formCompany)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.companyNameFilterD.push(r));

              //Name
              let formName = this.formDListItem.map(res => res.name)
              this.nameFilterD = [];
              from(formName)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.nameFilterD.push(r));
              //PAN Name
              let formPan = this.formDListItem.map(res => res.pan_no)
              this.panNameFilterD = [];
              from(formPan)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.panNameFilterD.push(r));
              //Stock Exchange
              let formexchangeTrade = this.formDListItem.map(res => res.exchangeTrade)
              this.exchangeTradeNameFilterD = [];
              from(formexchangeTrade)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.exchangeTradeNameFilterD.push(r));
              //Securities prior
              let formSecuritiesPrior = this.formDListItem.map(res => res.typeOfSecurities)
              this.securitiesPriorNameFilterD = [];
              from(formSecuritiesPrior)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesPriorNameFilterD.push(r));
              //Securities Acquired
              let formSecuritiesAcq = this.formDListItem.map(res => res.typeOfSecurities2)
              this.securitiesAcquiredNameFilterD = [];
              from(formSecuritiesAcq)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesAcquiredNameFilterD.push(r));
              //Securities Acquired transaction type
              let formSecuritiestrans = this.formDListItem.map(res => res.transactionType)
              this.transactionTypeNameFilterD = [];
              from(formSecuritiestrans)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.transactionTypeNameFilterD.push(r));
              //Securities Post
              let formSecuritiespost = this.formDListItem.map(res => res.typeOfSecuritiespost)
              this.securitiesPostNameFilterD = [];
              from(formSecuritiespost)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.securitiesPostNameFilterD.push(r));
              //Mode of Acquisition
              let formModeAcquisition = this.formDListItem.map(res => res.modeOfAcquisition)
              this.modeAcquisitionNameFilterD = [];
              from(formModeAcquisition)
              .pipe(
              tap(item => item),
              distinct(d => d)
              )
              .subscribe(r => this.modeAcquisitionNameFilterD.push(r));
                 
              
            }
          })
       

      });

   //Multiselect HideShow
this.dropdownSettings1 = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 2,
  allowSearchFilter: true
};
this.hidelist = [
      {"id":1,"name":"Company Name"},
      {"id":2,"name":"Name"},
      {"id":3,"name":"PAN"},
      {"id":4,"name":"Contact Number"},
      {"id":5,"name":"Stock Exchange"},
      {"id":6,"name":"Address"},
      {"id":7,"name":"CIN/DIN"},
      {"id":8,"name":"Category Person"},
      {"id":9,"name":"Securities Prior to acquisition"},
      {"id":10,"name":"Securities acquired/Disposed"},
      {"id":11,"name":"Securities post acquisition/ disposal"},
      {"id":12,"name":"Date of allotment advice"},
      {"id":13,"name":"Date of intimation"},
      {"id":14,"name":"Mode of acquisition"}
];  
this.hidelist2 = [
      {"id":1,"name":"Company Name"},
      {"id":2,"name":"Name"},
      {"id":3,"name":"PAN"},
      {"id":4,"name":"Contact Number"},
      {"id":5,"name":"Stock Exchange"},
      {"id":6,"name":"Address"},
      {"id":7,"name":"CIN/DIN"},
      {"id":8,"name":"Category Person"},
      {"id":9,"name":"Securities Prior to acquisition"},
      {"id":10,"name":"Securities acquired/Disposed"},
      {"id":11,"name":"Securities post acquisition/ disposal"},
      {"id":12,"name":"Date of allotment advice"},
      {"id":13,"name":"Date of intimation"},
      {"id":14,"name":"Mode of acquisition"}
];  

this.selectedhideItems = [
      {"id":1,"name":"Company Name"},
      {"id":2,"name":"Name"},
      {"id":3,"name":"PAN"},
      {"id":4,"name":"Contact Number"},
      {"id":5,"name":"Stock Exchange"}
  ];   
this.selectedhideItems2 = [
      {"id":1,"name":"Company Name"},
      {"id":2,"name":"Name"},
      {"id":3,"name":"PAN"},
      {"id":4,"name":"Contact Number"},
      {"id":5,"name":"Stock Exchange"}
  ];   

  }
  formTypeChoose(e){
    console.log(e.value)
  if(e.value == 'Form C'){
    this.hideFormC = true;
    this.hideFormD = false;
  }else{
    this.hideFormD = true;
    this.hideFormC = false;
  }

}
openModal(id:any) {   
  if (id) {
    let formId = {'id' : id};
    this.pitService.viewFormCandD(formId).subscribe(
      ({data}) =>{
      const modalRef = this.modalService.open(ViewFormCAndFormDComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      modalRef.componentInstance.viewTradingFormList = data[0];
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
    })
  }    
  
} 
//delete
openModalDelete(id:any) {   
  
if (id) {
  let formId = {'id' : id};
this.pitService.viewFormCandD(formId).subscribe(
  ({data}) =>{
  const modalRef = this.modalService.open(DeleteFormCAndFormDComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
  modalRef.componentInstance.deleteTradingFormList = data[0];
  modalRef.result.then(
    (result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
})
}    

} 
//PDF
openModalPdf(id:any) {   
  
if (id) {
  let formId = {'id' : id};
this.pitService.viewFormCandD(formId).subscribe(
  ({data}) =>{
  const modalRef = this.modalService.open(PdfViewFormatComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
  modalRef.componentInstance.pdfTradingFormList = data[0];
  modalRef.result.then(
    (result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
})
}    

} 
//filter Data
onFilterChange() {
  this.filtered = this.formCListItem.filter(res =>{    
    return res.companyName.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.address.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.categoryPerson.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.formTypes.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.exchangeTrade.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.from_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.intimate_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.isinNumber.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.mobNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.modeOfAcquisition.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.pan_no.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.perShareHolding.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.perShareHoldingNopost.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.perShareHoldingpost.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.perShareNumber.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.to_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.transactionType.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.typeOfSecurities.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.typeOfSecurities2.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.typeOfSecuritiesNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.typeOfSecuritiesValue.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.typeOfSecuritiespost.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || 
    res.created_by.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())  }) 
 
}
onFilterChange2() {
  this.filtered2 = this.formDListItem.filter(res =>{    
    return res.companyName.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.address.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.cin_din.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.formTypes.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.exchangeTrade.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.from_date.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.intimate_date.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.mobNo.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.modeOfAcquisition.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.name.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.pan_no.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.perShareHolding.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.perShareHoldingNopost.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.perShareHoldingpost.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.perShareNumber.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.to_date.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.transactionType.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.typeOfSecurities.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.typeOfSecurities2.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.typeOfSecuritiesNo.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.typeOfSecuritiesValue.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.typeOfSecuritiespost.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) || 
    res.created_by.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase())  }) 
 
}
isMatch(item) {

  if (item instanceof Object) {
    return Object.keys(item).some((k) => this.isMatch(item[k]));
  } else {
    return item?.toString().indexOf(this.filterString) > -1
  }
}
//filter area
selectEvent(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.companyName.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible = false;
}
selectEvent2(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.name.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible2 = false;
}
selectEvent3(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.pan_no.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible3 = false;
}
selectEvent4(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.exchangeTrade.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible4 = false;
}
selectEvent5(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.typeOfSecurities.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible5 = false;
}
selectEvent6(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.typeOfSecurities2.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible6 = false;
}
selectEvent7(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.transactionType.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible7 = false;
}
selectEvent8(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.typeOfSecuritiespost.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible8 = false;
}
selectEvent9(item) {
  // do something with selected item
  this.filtered = this.formCListItem.filter(formCItem => {
    return formCItem.modeOfAcquisition.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible9 = false;
}
selectEventD(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.companyName.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visibleD= false;
}
selectEvent2D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.name.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible2D= false;
}
selectEvent3D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.pan_no.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible3D= false;
}
selectEvent4D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.exchangeTrade.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible4D= false;
}
selectEvent5D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.typeOfSecurities.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible5D= false;
}
selectEvent6D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.typeOfSecurities2.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible6D= false;
}
selectEvent7D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.transactionType.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible7D= false;
}
selectEvent8D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.typeOfSecuritiespost.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible8D= false;
}
selectEvent9D(item) {
  // do something with selected item
  this.filtered2 = this.formDListItem.filter(formDItem => {
    return formDItem.modeOfAcquisition.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible9D= false;
}
onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
  console.log("String: " + search);
  
}
onChangeSearchD(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
  console.log("String: " + search);
  
}

onFocused(e) {
  // do something
}
onFocusedD(e) {
  // do something
}
onCleared(e) {
  //console.log('input cleared');
  this.filtered = this.formCListItem;
  ;
}
onClearedD(e) {
  console.log('input cleared', e);
  this.filtered2 = this.formDListItem;
  ;
}

onBlur(e) {
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
onBlurD(e) {
  this.visibleD = false;
  this.visible2D = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
onScrolledEnd() {
  // this.auto.close();
}
onScrolledEndD() {
  // this.auto.close();
}
filterData() {
  this.visible = !this.visible;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData2() {
  this.visible2 = !this.visible2;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData3() {
  this.visible3 = !this.visible3;
  this.visible = false;
  this.visible2 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData4() {
  this.visible4 = !this.visible4;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData5() {
  this.visible5 = !this.visible5;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible2 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData6() {
  this.visible6 = !this.visible6;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible2 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData7() {
  this.visible7 = !this.visible7;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible2 = false;
  this.visible8 = false;
  this.visible9 = false;
}
filterData8() {
  this.visible8 = !this.visible8;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible2 = false;
  this.visible9 = false;
}
filterData9() {
  this.visible9 = !this.visible9;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible2 = false;
}
filterDataD() {
  this.visibleD = !this.visibleD;
  this.visible2D = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData2D() {
  this.visible2D = !this.visible2D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData3D() {
  this.visible3D = !this.visible3D;
  this.visibleD = false;
  this.visible2D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData4D() {
  this.visible4D= !this.visible4D;
  this.visibleD = false;
  this.visible3D = false;4
  this.visible2D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData5D() {
  this.visible5D = !this.visible5D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible2D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData6D() {
  this.visible6D = !this.visible6D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible2D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData7D() {
  this.visible7D = !this.visible7D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible2D = false;
  this.visible8D = false;
  this.visible9D = false;
}
filterData8D() {
  this.visible8D = !this.visible8D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible2D = false;
  this.visible9D = false;
}
filterData9D() {
  this.visible9D = !this.visible9D;
  this.visibleD = false;
  this.visible3D = false;
  this.visible4D = false;
  this.visible5D = false;
  this.visible6D = false;
  this.visible7D = false;
  this.visible8D = false;
  this.visibleD = false;
}
}
