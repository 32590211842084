
<!-- Container-fluid starts -->
<div class="container-fluid">
   
    <div class="row" >
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="search" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search Previous Trading Pre Clearance" 
                             required >
                                                                         
                    </div>
                    
                </div>
                             
                    <div class="list-btn">                         
                        <a [routerLink]="['/pit-compliance/add-trading-transacrion', PcEditId]"><button class="btn btn-primary me-1" >Add New Trade</button></a>
                        <a routerLink="/pit-compliance/list-pre-clearance"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
                       
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div *ngIf="alertpopup">
                        <marquee direction="left" style="font-family: Rajdhani, sans-serif; color: #FFFFFF" bgcolor="#EB7808 " scrolldelay="50" behavior="loop"><b>Note: </b>Please note that the trade value you have entered is more than Rs 10 Lakhs.  You have to inform the Stock Exchange in which the securities are listed about this trade as per SEBI (Prohibition of Insider Trading) Regulations, 2015.</marquee>
                    </div>
                    <h4>PreClearance ID: PC - {{PcEditId}}</h4>
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no" >S.No.</th>
                                    <th scope="col" sortable="id.no">Name of holder</th>
                                    <th scope="col" sortable="subject" class="subject" >No of securities dealt</th>
                                    <th scope="col" sortable="period" >Sell/ Buy / Subscription</th>
                                    <th scope="col" sortable="period" >DP/ Client ID</th>
                                    <th scope="col" sortable="period" >Price</th>
                                    <th scope="col" sortable="action" >Action</th>
                                    <th scope="col" sortable="action" >{{pdfSelect}} PDF</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor = "let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                        <td scope="row" data-content="ID No.">{{(i+1) + (page-1) * pageSize }}</td>                   
                                        <td scope="row" data-content="Name of holder">{{item.name}}</td>
                                        <td scope="row" data-content="No of securities &#xa;dealt">{{item.no_of_securirities}}</td>
                                        <td scope="row" data-content="Sell/ Buy / &#xa;Subscription">{{item.bought_sold_subscribed}}</td> 
                                        <td scope="row" data-content="DP/ Client ID">{{item.dp_client_id}}</td>    
                                        <td scope="row" data-content="Price">{{item.price}}</td>                           

                                        <td class="icon" data-content="Action">
                                            <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye" ></i></button>
                                             <!-- <button class="edit-icon" title="Delete" (click)="openModalDelete(item.id)" ><i class="fa fa-trash"></i></button> -->
                                         </td>  
                                         <td class="icon" data-content="PDF">
                                             <button class="edit-icon" title="Download {{pdfSelect}} PDF" (click)="openModalFormC(item.id)"><i class="fa fa-file-pdf-o"></i></button>
                                         </td>                  
                                        
                                    </tr>
                                    
                                    </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length ==''">
                            <p>No Data Found</p>
                        </div> 

                     <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
<!-- Container-fluid Ends -->





