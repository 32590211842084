import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { AuthService } from '../../shared/services/firebase/auth.service';
import { LoginService } from './login.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
// type UserFields = 'email' | 'password';
// type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public showLoader: boolean = false;
  fieldTextType: boolean;
  isLoading = false;
  button = 'Login';
	closeResult = '';
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  // public formErrors: FormErrors = {
  //   'email': '',
  //   'password': '',
  // };
  public errorMessage: any;
  plainText: any;
  encPassword: any;
  encUsername: any;
  encyPassword: any;
  mailId: any;
  subscriptionCheck: string;

  constructor(
    private afauth: AngularFireAuth, private fb: FormBuilder,
    private router: Router, private loginService: LoginService,
    private modalService: NgbModal) {
      
      this.mailId = environment.mail;
  }

  ngOnInit() {
   
    if(sessionStorage.getItem('access_token') && window.location.pathname== '/auth/login') {
      this.router.navigate(['/dashboard']);
    }
    
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      //username: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
      checkbox: [false , Validators.required]
    });

    this.loginService.subscriptionCheck(null).subscribe(data =>{ 
     if(data.days <=30){
        if(data.success == 1){
          this.subscriptionCheck = ""; 
        }else{
          this.subscriptionCheck = data.message;
        }
     }
    })
    
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  // Login With Google
  // loginGoogle() {  
  //   this.authService.GoogleAuth();
  // }

  // // Login With Twitter
  // loginTwitter(): void {
  //   this.authService.signInTwitter();
  // }

  // // Login With Facebook
  // loginFacebook() {
  //   this.authService.signInFacebok();
  // }

  // Simple Login
  login() {
  
    // const encKey = "Secret Passphrase";
    // var encPassword = this.loginForm.value.password;
    // var iv = CryptoJS.enc.Hex.parse("FgLFXEr1MZl2mEnk");
    // const encryptPassword = CryptoJS.AES.encrypt(this.encPassword, encKey, {iv:iv}).toString();
    // this.loginForm.value.password = encryptPassword;
    if(this.loginForm.status !== 'INVALID'){
      if(this.loginForm.get('checkbox').value == false){
        window.alert("Please accept the Terms and Conditions.");
        this.showLoader = false;
       
      }else{
        this.loginService.signIn(this.loginForm.value);
      
      }
  }else{
    if(this.loginForm.get('username').value == ''){
      window.alert("enter the User name.");
     
    }else if(this.loginForm.controls.username.errors?.email){
      window.alert("enter the Correct User name.");
    }else if(this.loginForm.get('password').value == ''){
      window.alert("Enter the Password.");
     
    }
    else if(this.loginForm.get('checkbox').value === false){
      window.alert("Please accept the Terms and Conditions.");
      this.isLoading = false;
        this.button = 'Login';
    }
  }
  }

//Model Popup
open(content) {
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    },
  );
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


}
