<div class="modal-header">
    <h5 class="modal-title">{{name}} </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <h4 style="font-size: 19px;">Annual Confirmation Financial Year FY-{{financialyear}} </h4>
                        <div *ngIf="annualConformationData != ''" style="margin-top: 1.5rem;">
                        <h6>Dp Basic Details</h6>
                        <table class="table" *ngIf="annualConformationData">                            
                            <tbody >
                                <tr *ngIf="annualConformationData.dpname != '' ">
                                    <td scope="row">Name</td>
                                    <td scope="row">{{annualConformationData.dpName}}</td>                                         
                                 </tr> 
                                <tr *ngIf="annualConformationData.dpname == '' " >
                                    <td scope="row">Name</td>
                                    <td scope="row">Not Provided</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpDesignation != '' ">
                                    <td scope="row">Designation</td>
                                    <td scope="row">{{annualConformationData.dpDesignation}}</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpDesignation == '' ">
                                    <td scope="row">Designation</td>
                                    <td scope="row">Not Provided</td> 
                                </tr> 
                                 <tr *ngIf="annualConformationData.email != '' ">
                                    <td scope="row">Email ID</td>
                                    <td scope="row">{{annualConformationData.email}}</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.email == '' ">
                                    <td scope="row">Email ID</td>
                                    <td scope="row">Not Provided</td> 
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpContactNo != '' ">
                                    <td scope="row">Contact Number</td>
                                    <td scope="row">{{annualConformationData.dpContactNo}}</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpContactNo == '' ">
                                    <td scope="row">Contact Number</td>
                                    <td scope="row">Not Provided</td> 
                                 </tr> 
                                 <tr *ngIf="annualConformationData.DocumentTypes != '' ">
                                    <td scope="row">ID Proof Type - ID Proof No.</td>
                                    <td scope="row">{{annualConformationData.DocumentTypes}} - {{annualConformationData.dpPanNo}}</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.DocumentTypes == '' ">
                                    <td scope="row">ID Proof Type - ID Proof No.</td>
                                    <td scope="row">Not Provided</td> 
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpAddress != '' ">
                                    <td scope="row">Address</td>
                                    <td scope="row">{{annualConformationData.dpAddress}}</td>                                         
                                 </tr> 
                                 <tr *ngIf="annualConformationData.dpAddress == '' ">
                                    <td scope="row">Address</td>
                                    <td scope="row">Not Provided</td> 
                                 </tr> 
                              
                        </tbody>
                    </table>
                    <h6>Historical Details</h6>
                    <table class="table education-report">                            
                        <tbody *ngIf="annualConformationData">
                            <tr *ngIf="annualConformationData.dpEducationalInstitute != '' ">
                                <td scope="row" class="cd0">Educational Institute Name, Last Graduated From</td>
                                <td scope="row">{{annualConformationData.dpEducationalInstitute}}</td>                                         
                             </tr> 
                            <tr *ngIf="annualConformationData.dpEducationalInstitute == '' ">
                                <td scope="row" class="cd0">Educational Institute Name, Last Graduated From</td>
                                <td scope="row">Not Provided</td> 
                            </tr> 
                             <tr *ngIf="annualConformationData.dpPastEmp != '' ">
                                <td scope="row" class="cd0">Company Name of Previous Job</td>
                                <td scope="row">{{annualConformationData.dpPastEmp}}</td>                                         
                             </tr> 
                             <tr *ngIf="annualConformationData.dpPastEmp == '' ">
                                <td scope="row" class="cd0">Company Name of Previous Job</td>
                                <td scope="row">Not Provided</td> 
                             </tr> 
                            
                          
                    </tbody>
                    </table>
                    <h6>Name of Immediate Relative / Person Sharing Financial Material Relationship With DP</h6>
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngFor="let items of annualConformationData?.immediateDpRelative">
                                    <tr>
                                        <td scope="row">Name</td>
                                        <td scope="row">{{items.name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Relationship</td>
                                        <td scope="row">{{items.relationship}}</td>                                         
                                     </tr> 
                                     <tr>
                                       <td scope="row">Contact Number</td>
                                       <td scope="row">{{items.phone_no}}</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">ID Proof Type</td>
                                       <td scope="row">{{items.DocumentType}}</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">ID Proof No.</td>
                                       <td scope="row">{{items.pan}}</td>                                         
                                    </tr> 
                                    
                                  
                            </tbody>
                        </table>
                        <table class="table">                            
                                <tbody *ngIf="annualConformationData?.immediateDpRelative == '' ">
                                    <tr>
                                        <td scope="row">Name</td>
                                        <td scope="row">Not Provided</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Relationship</td>
                                        <td scope="row">Not Provided</td>                                         
                                     </tr> 
                                     <tr>
                                       <td scope="row">Contact Number</td>
                                       <td scope="row">Not Provided</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">ID Proof Type</td>
                                       <td scope="row">Not Provided</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">ID Proof No.</td>
                                       <td scope="row">Not Provided</td>                                         
                                    </tr> 
                                    
                                  
                            </tbody>
                        </table>
                    </div>
                    <h6>Annual Confirmation</h6>
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngFor="let item of annualConformationData?.annualConfirmation">
                                    <tr>
                                        <td scope="row">Financial Year</td>
                                        <td scope="row">{{item.financialYear}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Last Confirmed Date</td>
                                        <td scope="row">{{item.lastConformedDate}}</td>                                         
                                     </tr> 
                                  
                            </tbody>
                        </table>
                        <table class="table" *ngIf="annualConformationData?.annualConfirmation == '' ">                            
                          <tbody >
                              <tr>
                                  <td scope="row">Financial Year</td>
                                  <td scope="row">Not Provided</td>                                         
                               </tr> 
                               <tr>
                                  <td scope="row">Last Confirmed Date</td>
                                  <td scope="row">Not Provided</td>                                         
                               </tr> 
                      </tbody>
                  </table>
                    </div>
                    </div>
                    <div *ngIf="annualConformationData?.length == '' ">
                        <p class="notFound">Annual Confirmation Financial Year FY-{{financialyear}} <strong>Data Not Found</strong>.<br> Kindly contact your Admin/SDD Support to upgrade.</p>
                    </div>
                        <div class="btn-bottom" *ngIf="hideButton">
                            <button class="btn btn-primary me-1" (click)="confirmDetailsId()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1" (click)="open()" >Reject</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
