import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { PitComplianceService } from '../../pit-compliance.service';
import { DeletePreClearanceComponent } from '../delete-pre-clearance/delete-pre-clearance.component';
import { ViewPreClearanceComponent } from '../view-pre-clearance/view-pre-clearance.component';
import { ToastrService } from 'ngx-toastr';
import { PrepdfViewFormatComponent } from '../prepdf-view-format/prepdf-view-format.component';
import { PreClearanceStatusComponent } from '../pre-clearance-status/pre-clearance-status.component';
import { PreClerranceRejectComponent } from '../pre-clerrance-reject/pre-clerrance-reject.component';
import { PreClearanceReApproveComponent } from '../pre-clearance-re-approve/pre-clearance-re-approve.component';
import { CompanyService } from 'src/app/master/company/company.service';
import { Router } from '@angular/router';
import { PreFormCComponentComponent } from '../pre-form-ccomponent/pre-form-ccomponent.component';
import { ApprovedPdfComponent } from '../approved-pdf/approved-pdf.component';
import { formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-pre-clearance',
  templateUrl: './list-pre-clearance.component.html',
  styleUrls: ['./list-pre-clearance.component.scss']
})
export class ListPreClearanceComponent implements OnInit {
  @ViewChild('approval') approval!: ElementRef;
  @ViewChild('reject') reject!: ElementRef;
  @ViewChild('label') label!: ElementRef;
  preClearanceList: any;
  page = 1;
  pageSize = 10;
  filtered:any = [];
  filterString = "";
   hidelist = [{"id":1,"name":"Id No"},{"id":2,"name":"Dp Name"},{"id":3,"name":"From Date"},{"id":4,"name":"To Date"},{"id":5,"name":"Quantity"},{"id":6,"name":"Paid Company"},{"id":7,"name":"Traded Dealt"},{"id":8,"name":"Post Trade"},{"id":9,"name":"Trade Percentage"},{"id":10,"name":"Trade Type"},{"id":11,"name":"Trading Plan"}, {"id":12,"name":"Value Preposed"}, {"id":13,"name":"Created On"}];
  selectedhideItems = [];
  show_id = true;
  show_dpName = true;
  show_from_date = true;
  show_to_date = true;
  show_quantity_traded = true;
  show_paid_company=false;  
  show_traded_dealt = false;
  show_post_trade = false;
  show_trade_percentage = false;
  show_trade_type = false;
  show_trading_plan = false;
  show_value_preposed = false;
  show_created_on = false;
  dropdownSettings1:IDropdownSettings;
  //column filter
  visible: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false
  visible6: boolean = false
  visible7: boolean = false
  visible8: boolean = false
  visible9: boolean = false
  visible10: boolean = false
  visible11: boolean = false
  dpNameFilterString= '';
  fromDateFilterString= '';
  toDateFilterString= '';
  quantityFilterString= '';
  paidComFilterString = '';
  tradeDealFilterString = '';
  postTradeFilterString= '';
  tradePerFilterString= '';
  tradeTypeFilterString= '';
  tradePlanFilterString = '';
  valuePrepFilterString = '';
  keyword = 'dpname';
  keyword2 = 'from_date';
  keyword3 = 'to_date';
  keyword4 = 'quantity_traded';
  keyword5 = 'paid_company';
  keyword6 = 'traded_dealt';
  keyword7 = 'post_trade';
  keyword8 = 'trade_percentage';
  keyword9 = 'trade_type';
  keyword10 = 'trading_plan';
  keyword11= 'value_preposed';
  countValue = 0;
  dpNameFilter = [];
  fromDateNameFilter = [];
  toDateNameFilter = [];
  quantityTradedNameFilter = [];
  paidComNameFilter = [];
  tradedDealtNameFilter = [];
  postTradeNameFilter = [];
  tradePerNameFilter = [];
  tradeTypeNameFilter = [];
  tradePlanNameFilter = [];
  valuePropNameFilter = [];
  elm: any;
  statusApprovalData = []
  statusRejectData = []
  listCompany: any;
  complianceOfficerEmail: any;
  accessUser: string;
  complianceofficeraccess:boolean = false;
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  listPreviousPreClears = [];
  listPreviousPreClearslength: any;
  countCheck:boolean = false;
  noTradeForm: FormGroup;
  predaycounts: any;
  predaycount: any;
  dpNameString: any;
  ItemSelected: boolean = false;
  nameEmailmerge: string;
  constructor(private modalService: NgbModal,private fb: FormBuilder,private pitService: PitComplianceService, private toastr: ToastrService,
    private companyService: CompanyService, private router:Router) {
      this.accessUser = sessionStorage.getItem('access_user');
      // this.predaycounts = sessionStorage.getItem('predaycount');
      // this.complianceOfficerEmail = sessionStorage.getItem('complianceofficeremail');
      this.companyService.CompanyList(null).subscribe(
        res => {
          if(res.body['message'] == 'Invalid User.'){
            this.router.navigate(['/auth/login']);
          }else{
        let listCompany = res.body['data']     
            this.complianceOfficerEmail = listCompany[0].complianceofficeremail; 
            this.predaycounts = listCompany[0].predaycount; 
            this.nameEmailmerge =  listCompany[0].compliance_officer_name + ' - ' + listCompany[0].complianceofficeremail;    
  
            if(this.accessUser == this.complianceOfficerEmail){
                this.complianceofficeraccess = true;
            }else{
              this.complianceofficeraccess = false;
            }
          }
          },
          err => {
              console.log(err);
          }
      );
     }

  //MultiSelect HideShow
onItemSelect(item: any,page) {
  if(page == "column"){
    if(this.selectedhideItems.length > 10){   
      document.getElementById('formTable').classList.add('tablescroll');
    }else{
      document.getElementById('formTable').classList.remove('tablescroll');
    }
   if(item.id == 1){
     this.show_id = true;
   }else if(item.id == 2){
     this.show_dpName = true;
   }else if(item.id == 3){
     this.show_from_date = true;
   }else if(item.id == 4){
     this.show_to_date = true;
   }else if(item.id == 5){
     this.show_quantity_traded = true;
   }else if(item.id == 6){
      this.show_paid_company = true;
    }else if(item.id == 7){
     this.show_traded_dealt = true;
   }else if(item.id == 8){
     this.show_post_trade = true;
   }else if(item.id == 9){
   this.show_trade_percentage = true;
    }else if(item.id == 10){
      this.show_trade_type = true;
    }else if(item.id == 11){
      this.show_trading_plan = true;
    }else if(item.id == 12){
      this.show_value_preposed = true;
    }else if(item.id == 13){
      this.show_created_on = true;
    }
  }
 }
 
 onItemDeSelect(item: any,page) {
 
 if(page == "column"){
  if(this.selectedhideItems.length > 10){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   if(item.id == 1){
     this.show_id = false;
   }else if(item.id == 2){
     this.show_dpName = false;
   }else if(item.id == 3){
     this.show_from_date = false;
   }else if(item.id == 4){
     this.show_to_date = false;
   }else if(item.id == 5){
     this.show_quantity_traded = false;
   }else if(item.id == 6){
      this.show_paid_company = false;
    }else if(item.id == 7){
     this.show_traded_dealt = false;
   }else if(item.id == 8){
     this.show_post_trade = false;
   }else if(item.id == 9){
    this.show_trade_percentage = false;
  }else if(item.id == 10){
    this.show_trade_type = false;
  }else if(item.id == 11){
    this.show_trading_plan = false;
  }else if(item.id == 12){
    this.show_value_preposed = false;
  }else if(item.id == 13){
    this.show_created_on = false;
  }
  }
  
 }
 onSelectAll(items: any,page) {
 if(page == "column"){
  if(items.length > 10){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   this.show_id = true;
   this.show_dpName = true;
   this.show_from_date = true;
   this.show_to_date = true;
   this.show_quantity_traded = true;
   this.show_paid_company=true;
   this.show_traded_dealt = true;
   this.show_post_trade = true;
   this.show_trade_percentage = true;
   this.show_trade_type = true;
   this.show_trading_plan = true;
   this.show_value_preposed = true;
   this.show_created_on = true;
  
  }
  
 }
 onDeSelectAll(items: any,page) {
 if(page == "column"){
  if(items.length > 10){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
   this.show_id = false;
   this.show_dpName = false;
   this.show_from_date = false;
   this.show_to_date = false;
   this.show_quantity_traded = false;
   this.show_paid_company=false;
   this.show_traded_dealt = false;
   this.show_post_trade = false;
   this.show_trade_percentage = false;
   this.show_trade_type = false;
   this.show_trading_plan = false;
   this.show_value_preposed = false;
   this.show_created_on = false;
  
  }
 }
 // Multi Select

  ngOnInit(): void {

   

    if(Number(this.predaycounts) == 0 || this.predaycounts =='' || this.predaycounts == null){
      this.predaycount =7;
    }else{
      this.predaycount = this.predaycounts;
    }
    let count = this.predaycount - 1;

    let currdate = new Date();
    let curr_date = formatDate(currdate, 'yyyy-MM-dd', 'en-US');;
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
    this.noTradeForm = this.fb.group({
      tradebox: new FormControl ('')
    
    });
    this.pitService.preClearanceList(null).subscribe(
      res => {
        this.preClearanceList = res;
        this.filtered = [];
       // this.filtered = this.preClearanceList?.data;
        this.preClearanceList?.data.map((data) => {
          let dpname = data.dpname.split('- ')    
          if(this.accessUser == dpname[1]){      
            this.ItemSelected = true;      
            this.filtered?.push(data);   
          }if(this.accessUser == 'sdd@legalitysimplified.com'){
            this.filtered = this.preClearanceList?.data;
          }else{ 
            // this.companyService.CompanyList(null).subscribe(
            //   res => {
                //let listCompany = res.body['data']     
                //this.complianceOfficerEmail = listCompany[0]?.complianceofficeremail;  
                if(this.accessUser == this.complianceOfficerEmail){
                  this.filtered = this.preClearanceList?.data;
                 
                  this.filtered.map(item => {
                    let date = new Date(item.approved_date);
                    let approved_date = formatDate(date, 'yyyy-MM-dd', 'en-US');
                    date.setDate(date.getDate() + count );
                    let sevenDays = new Date(date);  
                    let afterseventhday = formatDate(sevenDays, 'yyyy-MM-dd', 'en-US');
                    if(curr_date > afterseventhday){
                     
                      if(Number(item.previouslistcountval) === 0){                  
                        item.countCheck = true;
                      }else{   
                        item.countCheck = false;
          
                      }
                    }else{
                      item.countCheck = false;
                    }
                  })


                }            
              // },
              // err => {
              //     console.log(err);
              // });           
            
            }
        }) 


        this.filtered.map(item => {
          let date = new Date(item.approved_date);
          let approved_date = formatDate(date, 'yyyy-MM-dd', 'en-US');
          date.setDate(date.getDate() + count );
          let sevenDays = new Date(date);  
          let afterseventhday = formatDate(sevenDays, 'yyyy-MM-dd', 'en-US');
          if(curr_date > afterseventhday){
            if(Number(item.previouslistcountval) === 0){                  
              item.countCheck = true;
            }else{   
              item.countCheck = false;

            }
          }else{
            item.countCheck = false;
          }
        })

      })
     

      //Multiselect HideShow
this.dropdownSettings1 = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 2,
  allowSearchFilter: true
};
this.selectedhideItems = [{"id":1,"name":"Id No"},{"id":2,"name":"Dp Name"},{"id":3,"name":"From Date"},{"id":4,"name":"To Date"},{"id":5,"name":"Quantity"}];


  }
  notrade(e,item){

    this.noTradeForm.value.id = item;
    // this.pitService.createPreviousPreClear(this.noTradeForm.value).subscribe(
    //   data => {

    //   });
  }
  get result() {         
   
    return this.filtered?.filter(item => item.checked);   
       
 }
 
 isSelectLabel(e, curItem){
   let checkedItemsArr = [];
   let checkedItemsArrlist = [];
   this.filtered.map((item) => {
     if(e.target.checked){
       checkedItemsArr.push(curItem);      
     } else if(item && item.checked && item.id !== curItem.id){
       checkedItemsArr.push(item);
     }
     
   });

   from(checkedItemsArr)
   .pipe(
   tap(item => item),
   distinct(d => d.id)
   )
   .subscribe(r => checkedItemsArrlist.push(r));
  

if(checkedItemsArrlist.length > 0){
   this.noTradeForm.value.noTradeCheckedList = checkedItemsArrlist;
   this.pitService.updatenotradetransaction(this.noTradeForm.value).subscribe(data =>{     
    this.toastr?.success("No Trade registered Successfully");
    this.pitService.notrademailconfig(this.noTradeForm.value).subscribe(data =>{ });
     window.location.reload();
    
   
  }) 
}

 }

  openModal(id:any) {
    if (id) {
      let preId = {'id' : id};
      this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewPreClearanceComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.viewPreClearanceList = data[0];
		modalRef.componentInstance.predaycounts = this.predaycounts;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  })
}
	}

  openModalDelete(id:any) {
    if (id) {
      let preId = {'id' : id};
      this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeletePreClearanceComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.deletePreClearanceList = data[0];
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  })
}
	}

  //Filter

  selectEvent(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.dpname.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible = false;
  }
  selectEvent2(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.from_date.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible2 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.to_date.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible3 = false;
  }
  selectEvent4(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.quantity_traded.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible4 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.paid_company.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible5 = false;
  }
  selectEvent6(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.traded_dealt.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible6 = false;
  }
  selectEvent7(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.post_trade.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible7 = false;
  }
  selectEvent8(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.trade_percentage.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible8 = false;
  }
  selectEvent9(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.trade_type.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible6 = false;
  }
  selectEvent10(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.trading_plan.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible6 = false;
  }
  selectEvent11(item) {
    // do something with selected item
    this.filtered = this.preClearanceList?.data.filter(pcItem => {
      return pcItem.value_preposed.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible6 = false;
  }
onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
 // console.log("String: " + search);
  
}

onFocused(e) {
  // do something
}
onCleared(e) {
  //console.log('input cleared');
  this.filtered = this.preClearanceList?.data;
  ;
}

onBlur(e) {
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
onScrolledEnd() {
  // this.auto.close();
}
filterData() {
  this.visible = !this.visible;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData2() {
  this.visible2 = !this.visible2;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;

}
filterData3() {
  this.visible3 = !this.visible3;
  this.visible = false;
  this.visible2 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData4() {
  this.visible4 = !this.visible4;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData5() {
  this.visible5 = !this.visible5;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData6() {
  this.visible6 = !this.visible6;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData7() {
  this.visible7 = !this.visible7;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData8() {
  this.visible8 = !this.visible8;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible9 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData9() {
  this.visible9 = !this.visible9;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible10 = false;
  this.visible11 = false;
}
filterData10() {
  this.visible10 = !this.visible10;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible11 = false;
}
filterData11() {
  this.visible11 = !this.visible11;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
  this.visible9 = false;
  this.visible10 = false;
}

  //
  onFilterChange() {
    this.filtered = this.preClearanceList.data?.filter(res =>{    
     return res.dpname.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.dppan.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.dpdesignation.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.status.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) ||  res.created_on.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }
  // approvalStatus(item){
  //   let approvalStatus = this.approval.nativeElement.value;
  //   this.statusApprovalData = item;
  //   this.statusApprovalData['status'] = approvalStatus;
  //   console.log("item", this.statusApprovalData);
    
  //   this.pitService.preClearanceSatusApproval(this.statusApprovalData).subscribe(data =>{
  //     this.toastr.success("Pre Clearance Successfully Approved");
  //     window.location.reload();
  //    })
  // }
  // rejectStatus(item){
  //   let rejectedStatus = this.reject.nativeElement.value;
  //   this.statusRejectData = item;
  //   this.statusRejectData['status'] = rejectedStatus;
  //   this.pitService.preClearanceSatusApproval(this.statusRejectData).subscribe(data =>{
  //     this.toastr.success("Pre Clearance Rejected");
  //     window.location.reload();
  //    })
  // }
  openModalPdf(id:any) {   
  
    if (id) {
      let preId = {'id' : id};
    this.pitService.preClearanceView(preId).subscribe(
      ({data}) =>{
      const modalRef = this.modalService.open(PrepdfViewFormatComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      modalRef.componentInstance.pdfPreclerance = data[0];
      modalRef.componentInstance.pdfPrecleranceid = id;
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
    })
    }    
    
    }
    
    
    openModalApproval(id:any) {   
  
      if (id) {
        let preId = {'id' : id};
      this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(PreClearanceStatusComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.statusPreclerance = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
      }    
      
      }  
    openModalReapproval(id:any) {   
  
      if (id) {
        let preId = {'id' : id};
      this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(PreClearanceReApproveComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.statusPreclerance = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
      }    
      
      }  
    openModalReject(id:any) {   
  
      if (id) {
        let preId = {'id' : id};
      this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(PreClerranceRejectComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.statusPreclerance = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
      }    
      
      }  
    
    approvedPdf(id:any){
      if (id) {
        let preId = {'id' : id};
        this.pitService.preClearanceView(preId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(ApprovedPdfComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.pdfapprovedOrder = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
      }    
    }
}
