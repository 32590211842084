import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsiService } from '../../upsi-transfer/upsi.service';
@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit {
  upsiId: any;
  viewUpsiList: any;
  accessSuccess: string;

  constructor(private activateRouter: ActivatedRoute, private upsiService: UpsiService, public router: Router) {
    this.accessSuccess = sessionStorage.getItem('access_success');
   
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data => {
      this.upsiId = data['id']
    })
    if (this.upsiId !== '') {
      this.upsiService.viewUpsiTransfer(this.upsiId).subscribe(
        data =>{
        this.viewUpsiList = data;
       
      })

      }
  }
}
