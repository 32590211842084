import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-delete-declaration-of-trade',
  templateUrl: './delete-declaration-of-trade.component.html',
  styleUrls: ['./delete-declaration-of-trade.component.scss']
})
export class DeleteDeclarationOfTradeComponent implements OnInit {
  twcId: any;
  accessSuccess: any;
  dotReasonDeleteForm: FormGroup;
  getId: any;
  @Input() deleteTradingWindowList;
  isLoading = false;
  button = 'Delete';
  submitted = false;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private pitService: PitComplianceService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }
  ngOnInit(): void {

    this.dotReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('',  Validators.required)
    });
   this.getId = this.deleteTradingWindowList.id;
  }
  onSubmit(){
    this.submitted = true;
    this.dotReasonDeleteForm.value.id = this.getId;
    if(this.dotReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';
    this.pitService.declarationofTradedelete(this.dotReasonDeleteForm.value).subscribe(data =>{
      this.toastr.success("Declaration of Trade Successfully deleted");
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      window.location.reload();
      
    })
  }else{
    if(this.dotReasonDeleteForm.get('reasonfordelete')?.value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Declaration of Trade.");
    }
  }

  }
  
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }
}
