import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../master.service';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisclaimerexcelComponent } from '../../popup/disclaimerexcel/disclaimerexcel.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  accessSuccess: string;
  usersAccessList: any;
  page = 1;
  pageSize = 20;
  filterString = "";
  filtered: any;
  dropdownSettings1:IDropdownSettings;
  selectedusersItems = [];
  selectedStatusItems = [];
  usersaccesslist = [];
  statuslist = [];
  baseUrl = environment.apiUrl;

  constructor(private masterService: MasterService, private router:Router, private modalService: NgbModal,) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

    this.masterService.usersControlAccessList(null).subscribe(
      res => {
        this.usersAccessList = [];
      let usersLists = res?.body['data'];
      usersLists.map(data =>{
        if(data.username != 'sdd@legalitysimplified.com'){
          if(data.user_access == null || data.user_access ==''){
            data['user_access'] = 'No Access';
          }
          if(data.accesstosdd == null || data.accesstosdd ==''){
            data['accesstosdd'] = 'Inactive';
          }
          this.usersAccessList?.push(data);
        }
      })
        this.filtered = this.usersAccessList;
        this.onFilterChange();
        //Access
       let usersItems =  this.usersAccessList.map(({id,user_access}) =>({ id, name: user_access  }));

       this.usersaccesslist = [];
        from(usersItems)
          .pipe(
            tap(item => item),
            distinct((d:any) => d?.name)
          )
          .subscribe(r => this.usersaccesslist?.push(r));
          //Sttus
       let usersItemsStatus =  this.usersAccessList.map(({id,accesstosdd}) =>({ id, name: accesstosdd  }));

       this.statuslist = [];
        from(usersItemsStatus)
          .pipe(
            tap(item => item),
            distinct((d:any) => d?.name)
          )
          .subscribe(r => this.statuslist?.push(r));
    },
        err => {
            console.log(err);
        }
    );
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    }; 
  }

  //MultiSelect hideShow
onItemSelect(item: any,page) {
  if(page == 'access'){
    let filteredItems = []
    if(this.selectedusersItems.length > 0){
      this.selectedusersItems.map(res =>{
        this.usersAccessList?.map(proj =>{    
          if(proj.user_access.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
            filteredItems.push(proj);
          }
        });
      });
    }else {
      filteredItems = this.usersAccessList;
    }
    this.filtered = filteredItems;
  }else if(page == 'status'){
    let filteredItemsStatus = []
    if(this.selectedStatusItems.length > 0){
      this.selectedStatusItems.map(res =>{
        this.usersAccessList?.map(proj =>{    
          if(proj.accesstosdd == res.name) {
            filteredItemsStatus.push(proj);
          }
          // if(proj.accesstosdd.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
          //   filteredItemsStatus.push(proj);
          // }
        });
      });
    }else {
      filteredItemsStatus = this.usersAccessList;
    }
    this.filtered = filteredItemsStatus;
  }
}

onItemDeSelect(item: any,page) {
  if(page == 'access'){
    let filteredItems = []
    if(this.selectedusersItems.length > 0){
      this.selectedusersItems.map(res =>{
        this.usersAccessList?.map(proj =>{    
          if(proj.user_access.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
            filteredItems.push(proj);
          }
        });
      });
    }else {
      filteredItems = this.usersAccessList;
    }
    this.filtered = filteredItems;
  }else if(page == 'status'){
    let filteredItemsStatus = []
    if(this.selectedStatusItems.length > 0){
      this.selectedStatusItems.map(res =>{
        this.usersAccessList?.map(proj =>{    
          if(proj.accesstosdd == res.name) {
            filteredItemsStatus.push(proj);
          }
        });
      });
    }else {
      filteredItemsStatus = this.usersAccessList;
    }
    this.filtered = filteredItemsStatus;
  }
}
onSelectAll(items: any,page) {
  if(page == 'access'){
  this.filtered = this.usersAccessList;
  }if(page == 'status'){
    this.filtered = this.usersAccessList;
  }
}
onDeSelectAll(items: any,page) {
  if(page == 'access'){
    this.filtered = this.usersAccessList;
    }if(page == 'status'){
      this.filtered = this.usersAccessList;
    }
}

  onFilterChange() {
  
    this.filtered = this.usersAccessList?.filter(res => {
     
      return res?.dpname.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) 
      || res?.accesstosdd.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase()) 
      || res?.email.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
      || res?.user_access.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
      || res?.documenttype.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
      || res?.dppanno.toLocaleLowerCase().match(this.filterString?.toLocaleLowerCase())
    })

  }
  openExcelDisclaimer() {

    const modalRef = this.modalService.open(DisclaimerexcelComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
      modalRef.componentInstance.name = 'Alert';
      modalRef.componentInstance.title = 'Users Lists';
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
      if(receivedData == 'Yes'){
        this.onFilter();
      }
    }, (reason) => {
    });
      
}
onFilter() {
     
  let queryString = '';
  let url = this.baseUrl + 'usersexcel?';
  let tokenStr = sessionStorage.getItem('access_token');
  if(tokenStr){
    tokenStr = tokenStr.replace('Bearer ', '');
  }
  queryString = 'authToken='+btoa(tokenStr);
  var element = document.createElement('a');
  element.setAttribute('href', url + queryString);
  element.setAttribute('id', 'filter1');
  element.setAttribute('download', 'users.xls');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
  

} 
}
