import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-dot-mail-component',
  templateUrl: './dot-mail-component.component.html',
  styleUrls: ['./dot-mail-component.component.scss']
})
export class DotMailComponentComponent implements OnInit {
 isLoading = false;
  button = 'Yes';
  page = 1;
  pageSize = 10;
  @Input() sendMailTradingWindowList;
  @Input() hideButton;
  public active1 = 1;
  tradingWindiwDetails: {};
  recipientList: [];
  senderList: [];
  senderItem: any;
  recipientItem: any;
  html: any;
  showAllDetails:boolean = true;
  showMailDetails:boolean = false;
  @Output() twcEmailMsg: EventEmitter<any> = new EventEmitter();
  twcMailTemplate: any;
  tradingmailStatus: any;
  mailStatuslist:any = [];
  senderMailStatus: any;
  mail_status: any;
  recipientMailStatus: any;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, private pitService: PitComplianceService ) { }

  ngOnInit() {
    this.senderItem = this.sendMailTradingWindowList.sender_name;
    this.recipientItem = this.sendMailTradingWindowList.recipient_name;
    this.html = this.sendMailTradingWindowList.body_content;
    this.tradingWindiwDetails = this.sendMailTradingWindowList;

    this.recipientList = this.sendMailTradingWindowList.recipient_name;
    this.senderList = this.sendMailTradingWindowList.sender_name;
    this.tradingWindiwDetails['recipient'] =  this.recipientList.map(item=> ({item_text:item}))
    this.tradingWindiwDetails['sender'] =  this.senderList

      }
  sendMailTwc(){
    this.isLoading = true;
    this.button = 'Processing';
    this.pitService.declarationofTradeMailler(this.tradingWindiwDetails).subscribe(data =>{     
    this.mailStatuslist = [];
      this.isLoading = false;
      this.button = 'Yes';
      this.twcMailTemplate = data?.mailTemplate;
      this.twcEmailMsg.emit(data.success);
      this.toastr.success("Declaration of Trade Email Send", "Success!");
      this.showAllDetails = false;
      this.showMailDetails = true;
      this.senderMailStatus = data?.sender_value;
      let recipientMailStatus = data?.recipient_value;
      this.mail_status = data?.mail_status;
      let mailStatus = data?.data;
      // mailStatus.map((item) => this.mailStatuslist.push({recipient:item.recipient,mail_status:item.mail_status }) )
      recipientMailStatus.map((item) => {
        this.mailStatuslist.push({recipient:item[0],mail_status:item[1] });
        } 
        )
      //this.activeModal.close(event);
      // this.router.navigate(['/dp/dp-list']);
      //}
    });
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    this.hideButton = false;
    window.location.reload();
  }

}
