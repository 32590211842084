import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-delete-previous-trading',
  templateUrl: './delete-previous-trading.component.html',
  styleUrls: ['./delete-previous-trading.component.scss']
})
export class DeletePreviousTradingComponent implements OnInit {
  twcId: any;
  accessSuccess: string;
  previousPcReasonDeleteForm: FormGroup;
  getId: any;
  @Input() deletePreviousPreClearanceList;
  isLoading = false;
  button = 'Delete';
  submitted = false;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private pitService: PitComplianceService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.previousPcReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('',  Validators.required)
    });
   this.getId = this.deletePreviousPreClearanceList.id;
  }
  onSubmit(){
    this.submitted = true;
    this.previousPcReasonDeleteForm.value.id = this.getId;
    if(this.previousPcReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
        this.button = 'Processing';
        this.pitService.deletePreviousPreClear(this.previousPcReasonDeleteForm.value).subscribe(data =>{
      console.log(this.previousPcReasonDeleteForm.value )
      this.toastr.success("Previous Pre Clearance Successfully deleted");
      this.isLoading = false;
        this.button = 'Delete';
      window.location.reload();
      
    })
  }else{
    if(this.previousPcReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Previous Trading Pre Clearance.");
    }
  }
  }
  
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }

}
