
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Update Form C and Form D Report</h5>
                    <a routerLink="/pit-compliance/list-trading-report"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                
                </div>
                <div class="card-body">
                    <div class="row mb-3 row1">
                        <div class="col-md-5">
                            <label for="formType">Form Type</label>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">                    
                                <select class="form-select" class="form-control form-select" id="formType" [(ngModel)]="formTypes"  (change)="formTypeChoose($event.target)">
                                    <option value="" selected>--Choose One --</option>
                                    <option value="Form C" [disabled]="formTypes === 'Form D'">Form C</option>
                                    <option value="Form D" [disabled]="formTypes === 'Form C'">Form D</option>                                    
                                </select>
                              
                            </div>
                        </div>
                    </div>
                    <div class="classFormC" *ngIf="hideFormC">
                        <form [formGroup]="formCEditForm" class="needs-validation" (ngSubmit)="onSubmitFormC()">
                            <div class="row">     
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="dpName">a) Name of the Company:</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="companyName" placeholder="Enter the Company Name" autocomplete="off" formControlName="companyName" disabled [(ngModel)]="companyName"> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="companyName">b) ISIN of the company: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="companyName" placeholder="Enter the ISIN Number" autocomplete="off" formControlName="isinNumber" disabled [(ngModel)]="isinNumber"> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 rowflex">
                                        <div class="col-md-5">
                                            <label for="name">c) Name (Promoter, Member of the Promoter Group, Designated Person or and immediate relatives of such persons) : </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="name" placeholder="Enter the Name" autocomplete="off" formControlName="name"
                                                required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.name.touched) && formCEditForm.controls.name.errors }">
                                                <div *ngIf="(submitted || formCEditForm.controls.name.touched) && formCEditForm.controls.name.errors?.required"
                                                class="text text-danger">
                                                Name is required.
                                            </div> 
                                            </div>
                                         
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">d) PAN: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="panNo" placeholder="Enter the PAN" autocomplete="off" formControlName="panNo"
                                                required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.panNo.touched) && formCEditForm.controls.panNo.errors }"> 
                                                <div *ngIf="(submitted || formCEditForm.controls.panNo.touched) && formCEditForm.controls.panNo.errors?.required"
                                                    class="text text-danger">
                                                    PAN Number is required.
                                                </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="cin_din">e) CIN/DIN Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="cin_din" placeholder="Enter the CIN/DIN Number" autocomplete="off" formControlName="cin_din"
                                                required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.cin_din.touched) && formCEditForm.controls.cin_din.errors }">
                                                <div *ngIf="(submitted || formCEditForm.controls.cin_din.touched) && formCEditForm.controls.cin_din.errors?.required"
                                                    class="text text-danger">
                                                    CIN/DIN Number is required.
                                                </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="mobNo">f) Contact Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="mobNo" placeholder="Enter the Contact Number" autocomplete="off" formControlName="mobNo"
                                                required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.mobNo.touched) && formCEditForm.controls.mobNo.errors }">
                                                <div *ngIf="(submitted || formCEditForm.controls.mobNo.touched) && formCEditForm.controls.mobNo.errors?.required"
                                                    class="text text-danger">
                                                    Contact Number is required.
                                                </div> 
                                                <div class="text text-danger" *ngIf="formCEditForm.controls.mobNo.errors?.pattern">Please, Enter 10 digit Contact Number.</div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="address">g) Address: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <textarea class="form-control" id="address" rows="3" formControlName="address"
                                                placeholder="Enter the Address" required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.address.touched) && formCEditForm.controls.address.errors }"></textarea>
                                                <div *ngIf="(submitted || formCEditForm.controls.address.touched) && formCEditForm.controls.address.errors?.required"
                                                    class="text text-danger">
                                                    Address is required.
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="categoryPerson">h) Category of Person: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <select class="form-select" class="form-control form-select" id="categoryPerson" formControlName="categoryPerson" (click)="otherCategoryOfPersonFormC()" required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.categoryPerson.touched) && formCEditForm.controls.categoryPerson.errors }">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Promoter">Promoter</option>
                                                    <option value="Member of the promoter group">Member of the promoter group</option>                                    
                                                    <option value="Designated person">Designated person</option>                                    
                                                    <option value="Directors">Directors</option>                                    
                                                    <option value="Immediate relative of">Immediate relative of</option>                                    
                                                    <option value="Others">Others</option>                                    
                                                </select>
                                                <div *ngIf="(submitted || formCEditForm.controls.categoryPerson.touched) && formCEditForm.controls.categoryPerson.errors?.required"
                                                class="text text-danger">Category of Person is required. </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="otherscategoryPersonFormC">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="typeOfSecuritiesValue">Others Category of Person: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Category of Person" autocomplete="off" formControlName="otherscategoryofPerson"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-md-12">
                                    <h4>i) Securities held prior to acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecurities" required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.typeOfSecurities.touched) && formCEditForm.controls.typeOfSecurities.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formCEditForm.controls.typeOfSecurities.touched) && formCEditForm.controls.typeOfSecurities.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding">                    
                                                        <input type="text" #sQty3 class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareNumber" (change)="onSelectedQty1C()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.perShareNumber.touched) && formCEditForm.controls.perShareNumber.errors }">
                                                        <div *ngIf="(submitted || formCEditForm.controls.perShareNumber.touched) && formCEditForm.controls.perShareNumber.errors?.required"
                                                class="text text-danger">No. of share holding is required. </div>
                                                <div class="text text-danger" *ngIf="formCEditForm.controls.perShareNumber.errors?.pattern">Please, Enter Numbers only.</div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">c) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage">                    
                                                        
                                                        <input type="text" class="form-control" id="perShareHolding" disabled [ngModel]="perShareHoldingFormC | number:'1.0-4'" placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHolding"> <span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>j) Securities acquired/Disposed</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities2">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities2" formControlName="typeOfSecurities2" required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.typeOfSecurities2.touched) && formCEditForm.controls.typeOfSecurities2.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formCEditForm.controls.typeOfSecurities2.touched) && formCEditForm.controls.typeOfSecurities2.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 rowflex">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesNo">b) No. of securities acquired/ disposed: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="number" class="form-control" id="typeOfSecuritiesNo" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesNo"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.typeOfSecuritiesNo.touched) && formCEditForm.controls.typeOfSecuritiesNo.errors }">
                                                        <div *ngIf="(submitted || formCEditForm.controls.typeOfSecuritiesNo.touched) && formCEditForm.controls.typeOfSecuritiesNo.errors?.required"
                                                        class="text text-danger">No. of securities acquired/ disposed is required. </div> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">c) Value of Securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="text" class="form-control" id="typeOfSecuritiesValue" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesValue"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.typeOfSecuritiesValue.touched) && formCEditForm.controls.typeOfSecuritiesValue.errors }">
                                                        <div *ngIf="(submitted || formCEditForm.controls.typeOfSecuritiesValue.touched) && formCEditForm.controls.typeOfSecuritiesValue.errors?.required"
                                                        class="text text-danger">Value of Securities is required. </div> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="transactionType">d) Transaction Type: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="transactionType" formControlName="transactionType" (click)="otherTransactionTypeFormC()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.transactionType.touched) && formCEditForm.controls.transactionType.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="Purchase">Purchase</option>
                                                        <option value="sale Pledge">sale Pledge</option>                                    
                                                        <option value="Revocation">Revocation</option>                                    
                                                        <option value="Invocation">Invocation</option>                           
                                                        <option value="Others">Others</option>                           
                                                    </select>
                                                    <div *ngIf="(submitted || formCEditForm.controls.transactionType.touched) && formCEditForm.controls.transactionType.errors?.required"
                                                    class="text text-danger">Transaction Type is required. </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="othersTransactionTypeFormC">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">Others Transaction Type: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Transaction Type" autocomplete="off" formControlName="otherstransactiontype"> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <h4>k) Securities held post acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecuritiespost"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.typeOfSecuritiespost.touched) && formCEditForm.controls.typeOfSecuritiespost.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formCEditForm.controls.typeOfSecuritiespost.touched) && formCEditForm.controls.typeOfSecuritiespost.errors?.required"
                                                    class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding ">                    
                                                        <input type="text" #sQty4 class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareHoldingNopost" (change)="onSelectedQty2C()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.perShareHoldingNopost.touched) && formCEditForm.controls.perShareHoldingNopost.errors }"> 
                                                        <div *ngIf="(submitted || formCEditForm.controls.perShareHoldingNopost.touched) && formCEditForm.controls.perShareHoldingNopost.errors?.required"
                                                    class="text text-danger">No. of share holding is required. </div>
                                                    <div class="text text-danger" *ngIf="formCEditForm.controls.perShareHoldingNopost.errors?.pattern">Please, Enter Numbers only.</div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">c) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage">                    
                                                        <input type="text" class="form-control" id="perShareHoldingpost" disabled [ngModel]="perShareHolding2FormC | number:'1.0-4'" placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHoldingpost"><span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>l) Date of allotment advice/ acquisition of shares/ disposal of shares, specify</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="formDate">a) From: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="fromDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="fromDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.fromDate.touched) && formCEditForm.controls.fromDate.errors }"> 
                                                        <div *ngIf="(submitted || formCEditForm.controls.fromDate.touched) && formCEditForm.controls.fromDate.errors?.required"
                                                    class="text text-danger">From Date is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="toDate">b) To: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="toDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="toDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.toDate.touched) && formCEditForm.controls.toDate.errors }">
                                                        <div *ngIf="(submitted || formCEditForm.controls.toDate.touched) && formCEditForm.controls.toDate.errors?.required"
                                                    class="text text-danger">To Date is required. </div>
                                                        <div *ngIf="formCEditForm.controls.toDate.touched && formCEditForm.controls.toDate.invalid" class="text text-danger" >Please add a valid from and To date </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="intimateDate">m) Date of intimation to company: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="date" class="form-control" id="intimateDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="intimateDate"
                                                required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.intimateDate.touched) && formCEditForm.controls.intimateDate.errors }">
                                                <div *ngIf="(submitted || formCEditForm.controls.intimateDate.touched) && formCEditForm.controls.intimateDate.errors?.required"
                                                    class="text text-danger">Date of intimation is required. </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="modeOfAcquisition">n) Mode of acquisition /disposal: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="modeOfAcquisition" formControlName="modeOfAcquisition" (click)="modeofAcquisitionTypeChanged()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.modeOfAcquisition.touched) && formCEditForm.controls.modeOfAcquisition.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="On market">On market</option>
                                                        <option value="Public">Public</option>                                    
                                                        <option value="Rights">Rights</option>                                    
                                                        <option value="Preferential offer">Preferential offer</option>                                    
                                                        <option value="Off market">Off market</option>                                    
                                                        <option value="Inter-se transfer">Inter-se transfer</option>                                    
                                                        <option value="ESOPs">ESOPs</option>                                    
                                                        <option value="Others">Others</option>                                    
                                                    </select>      
                                                    <div *ngIf="(submitted || formCEditForm.controls.modeOfAcquisition.touched) && formCEditForm.controls.modeOfAcquisition.errors?.required"
                                                    class="text text-danger">Mode of acquisition /disposal is required. </div>                                     
                                                </div> 
                                        </div>
                                        </div>
                                     
                                    </div>
                                    <div class="col-md-12" *ngIf="othersModeofAcquisition">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="typeOfSecuritiesValue">Others Mode of acquisition /disposal: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="othersModeofAcquisition" placeholder="Enter the Others Mode of acquisition /disposal" autocomplete="off" formControlName="othersModeofAcquisition"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="exchangeTrade">o) Exchange on which the trade was executed: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <!-- <input type="text" class="form-control" id="exchangeTrade" placeholder="Exchange on which the trade was executed" autocomplete="off" formControlName="exchangeTrade">  -->
                                                <ng-select [items]="exchangeTradeFilter"
                                                bindLabel=""
                                                placeholder="Select Stock Exchange"
                                                [(ngModel)]="selectedStockExchange" formControlName="exchangeTrade" required [ngClass]="{ 'is-invalid': (submitted || formCEditForm?.controls.exchangeTrade.touched) && formCEditForm.controls.exchangeTrade.errors }">
                                                 </ng-select>
                                                 <div *ngIf="(submitted || formCEditForm.controls.exchangeTrade.touched) && formCEditForm.controls.exchangeTrade.errors?.required"
                                                    class="text text-danger">Exchange on which the trade is required. </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                
    
                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="resetForm()" >Cancel</button>
                                 </div>
                            </div>
                        </form>
                    </div>

                    <div class="classFormD" *ngIf="hideFormD">
                        <form [formGroup]="formDEditForm" class="needs-validation" (ngSubmit)="onSubmitFormD()">
                            <div class="row">                                  
                                
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">a) Name (other connected persons): </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="name" placeholder="Enter the Name" autocomplete="off" formControlName="name"
                                                required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.name.touched) && formDEditForm.controls.name.errors }">
                                                <div *ngIf="(submitted || formDEditForm.controls.name.touched) && formDEditForm.controls.name.errors?.required"
                                                class="text text-danger">
                                                Name is required.
                                            </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="name">b) PAN: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="panNo" placeholder="Enter the PAN" autocomplete="off" formControlName="panNo"
                                                required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.panNo.touched) && formDEditForm.controls.panNo.errors }"> 
                                                <div *ngIf="(submitted || formDEditForm.controls.panNo.touched) && formDEditForm.controls.panNo.errors?.required"
                                                    class="text text-danger">
                                                    PAN Number is required.
                                                </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="cin_din">c) CIN/DIN Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="cin_din" placeholder="Enter the CIN/DIN Number" autocomplete="off" formControlName="cin_din"
                                                required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.cin_din.touched) && formDEditForm.controls.cin_din.errors }">
                                                <div *ngIf="(submitted || formDEditForm.controls.cin_din.touched) && formDEditForm.controls.cin_din.errors?.required"
                                                    class="text text-danger">
                                                    CIN/DIN Number is required.
                                                </div>  
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="mobNo">d) Contact Number: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="mobNo" placeholder="Enter the Contact Number" autocomplete="off" formControlName="mobNo"
                                                required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.mobNo.touched) && formDEditForm.controls.mobNo.errors }">
                                                <div *ngIf="(submitted || formDEditForm.controls.mobNo.touched) && formDEditForm.controls.mobNo.errors?.required"
                                                    class="text text-danger">
                                                    Contact Number is required.
                                                </div> 
                                                <div class="text text-danger" *ngIf="formDEditForm.controls.mobNo.errors?.pattern">Please, Enter 10 digit Contact Number.</div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="address">e) Address: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <textarea class="form-control" id="address" rows="3" formControlName="address"
                                                placeholder="Enter the Address" required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.address.touched) && formDEditForm.controls.address.errors }"></textarea>
                                                <div *ngIf="(submitted || formDEditForm.controls.address.touched) && formDEditForm.controls.address.errors?.required"
                                                    class="text text-danger">
                                                    Address is required.
                                                </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="companyName">f) Connection with the company:</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="text" class="form-control" id="companyName" placeholder="Enter the Connection with company" autocomplete="off" formControlName="companyName" [(ngModel)]="companyName" disabled> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>g) Securities held prior to acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecurities" required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.typeOfSecurities.touched) && formDEditForm.controls.typeOfSecurities.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formDEditForm.controls.typeOfSecurities.touched) && formDEditForm.controls.typeOfSecurities.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding">                    
                                                        <input type="text" #sQty class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareNumber" (change)="onSelectedQty1D()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.perShareNumber.touched) && formDEditForm.controls.perShareNumber.errors }">
                                                        <div *ngIf="(submitted || formDEditForm.controls.perShareNumber.touched) && formDEditForm.controls.perShareNumber.errors?.required"
                                                class="text text-danger">No. of share holding is required. </div> 
                                                <div class="text text-danger" *ngIf="formDEditForm.controls.perShareNumber.errors?.pattern">Please, Enter Numbers only.</div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">c) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage" >  
                                                        <input type="text" class="form-control" id="perShareHolding" disabled [ngModel]="perShareHoldingFormD | number:'1.0-4'" placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHolding"> <span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>h) Securities acquired/Disposed</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities2">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities2" formControlName="typeOfSecurities2" required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.typeOfSecurities2.touched) && formDEditForm.controls.typeOfSecurities2.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formDEditForm.controls.typeOfSecurities2.touched) && formDEditForm.controls.typeOfSecurities2.errors?.required"
                                                class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 rowflex">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesNo">b) No of securities acquired/ disposed: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="number" class="form-control" id="typeOfSecuritiesNo" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesNo"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.typeOfSecuritiesNo.touched) && formDEditForm.controls.typeOfSecuritiesNo.errors }">
                                                        <div *ngIf="(submitted || formDEditForm.controls.typeOfSecuritiesNo.touched) && formDEditForm.controls.typeOfSecuritiesNo.errors?.required"
                                                        class="text text-danger">No. of securities acquired/ disposed is required. </div>                                                    
                                                     </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">c) Value of Securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group"> 
                                                        <input type="text" class="form-control" id="typeOfSecuritiesValue" placeholder="Enter the No." autocomplete="off" formControlName="typeOfSecuritiesValue"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.typeOfSecuritiesValue.touched) && formDEditForm.controls.typeOfSecuritiesValue.errors }">
                                                        <div *ngIf="(submitted || formDEditForm.controls.typeOfSecuritiesValue.touched) && formDEditForm.controls.typeOfSecuritiesValue.errors?.required"
                                                        class="text text-danger">Value of Securities is required. </div>                   
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="transactionType">d) Transaction Type: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="transactionType" formControlName="transactionType" (click)="otherTransactionTypeFormC()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.transactionType.touched) && formDEditForm.controls.transactionType.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="Purchase">Purchase</option>
                                                        <option value="sale Pledge">sale Pledge</option>                                    
                                                        <option value="Revocation">Revocation</option>                                    
                                                        <option value="Invocation">Invocation</option>                           
                                                        <option value="Others">Others</option>                           
                                                    </select>
                                                    <div *ngIf="(submitted || formDEditForm.controls.transactionType.touched) && formDEditForm.controls.transactionType.errors?.required"
                                                    class="text text-danger">Transaction Type is required. </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="othersTransactionTypeFormD">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecuritiesValue">Others Transaction Type: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Transaction Type" autocomplete="off" formControlName="otherstransactiontype"> 
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <h4>i) Securities held post acquisition/ disposal</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="typeOfSecurities">a) Type of securities: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <select class="form-select" class="form-control form-select" id="typeOfSecurities" formControlName="typeOfSecuritiespost"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.typeOfSecuritiespost.touched) && formDEditForm.controls.typeOfSecuritiespost.errors }">
                                                            <option value="" disabled selected>--Choose One --</option>
                                                            <option value="Shares">Shares</option>
                                                            <option value="Warrants">Warrants</option>                                    
                                                            <option value="Convertible Debentures">Convertible Debentures</option>                                    
                                                            <option value="Rights entitlements">Rights entitlements</option>                           
                                                        </select>
                                                        <div *ngIf="(submitted || formDEditForm.controls.typeOfSecuritiespost.touched) && formDEditForm.controls.typeOfSecuritiespost.errors?.required"
                                                    class="text text-danger">Type of securities is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) No. of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding">                    
                                                        <input type="text" #sQty2 class="form-control" id="perShareHolding" placeholder="Enter the No. of share holding" autocomplete="off" formControlName="perShareHoldingNopost" (change)="onSelectedQty2D()"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.perShareHoldingNopost.touched) && formDEditForm.controls.perShareHoldingNopost.errors }"> 
                                                        <div *ngIf="(submitted || formDEditForm.controls.perShareHoldingNopost.touched) && formDEditForm.controls.perShareHoldingNopost.errors?.required"
                                                    class="text text-danger">No. of share holding is required. </div>
                                                    <div class="text text-danger" *ngIf="formDEditForm.controls.perShareHoldingNopost.errors?.pattern">Please, Enter Numbers only.</div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="perShareHolding">b) % of share holding: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group perShareHolding percentage">                    
                                                        <input type="text" class="form-control" id="perShareHoldingpost" disabled [ngModel]="perShareHolding2FormD | number:'1.0-4'" placeholder="Enter the % of share holding" autocomplete="off" formControlName="perShareHoldingpost"> <span class="perShareHolding">%</span>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <h4>j) Date of allotment advice/ acquisition of shares/ disposal of shares, specify</h4>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="formDate">a) From: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="fromDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="fromDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.fromDate.touched) && formDEditForm.controls.fromDate.errors }"> 
                                                        <div *ngIf="(submitted || formDEditForm.controls.fromDate.touched) && formDEditForm.controls.fromDate.errors?.required"
                                                    class="text text-danger">From Date is required. </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row mb-3 row1">
                                                <div class="col-md-5">
                                                    <label for="toDate">b) To: </label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="form-group">                    
                                                        <input type="date" class="form-control" id="toDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="toDate"
                                                        required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.toDate.touched) && formDEditForm.controls.toDate.errors }">
                                                        <div *ngIf="(submitted || formDEditForm.controls.toDate.touched) && formDEditForm.controls.toDate.errors?.required"
                                                    class="text text-danger">To Date is required. </div>
                                                        <div *ngIf="formDEditForm.controls.toDate.touched && formDEditForm.controls.toDate.invalid" class="text text-danger" >Please add a valid from and To date </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="intimateDate">k) Date of intimation to company: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <input type="date" class="form-control" id="intimateDate" placeholder="DD-MM-YYYY" autocomplete="off" formControlName="intimateDate"
                                                required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.intimateDate.touched) && formDEditForm.controls.intimateDate.errors }">
                                                <div *ngIf="(submitted || formDEditForm.controls.intimateDate.touched) && formDEditForm.controls.intimateDate.errors?.required"
                                                    class="text text-danger">Date of intimation is required. </div> 
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="modeOfAcquisition">l) Mode of acquisition /disposal: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <div class="form-group">                    
                                                    <select class="form-select" class="form-control form-select" id="modeOfAcquisition" formControlName="modeOfAcquisition" (click)="modeofAcquisitionTypeChanged()"
                                                    required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.modeOfAcquisition.touched) && formDEditForm.controls.modeOfAcquisition.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="On market">On market</option>
                                                        <option value="Public">Public</option>                                    
                                                        <option value="Rights">Rights</option>                                    
                                                        <option value="Preferential offer">Preferential offer</option>                                    
                                                        <option value="Off market">Off market</option>                                    
                                                        <option value="Inter-se transfer">Inter-se transfer</option>                                    
                                                        <option value="ESOPs">ESOPs</option>                                    
                                                        <option value="Others">Others</option>                                    
                                                    </select>      
                                                    <div *ngIf="(submitted || formDEditForm.controls.modeOfAcquisition.touched) && formDEditForm.controls.modeOfAcquisition.errors?.required"
                                                    class="text text-danger">Mode of acquisition /disposal is required. </div>                                           
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="othersModeofAcquisitionFormD">
                                        <div class="row mb-3 row1">
                                            <div class="col-md-5">
                                                <label for="typeOfSecuritiesValue">Others Mode of acquisition /disposal: </label>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="othersModeofAcquisition" placeholder="Enter the Others Mode of acquisition /disposal" autocomplete="off" formControlName="othersModeofAcquisition"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3 row1">
                                        <div class="col-md-5">
                                            <label for="exchangeTrade">m) Exchange on which the trade was executed: </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">                    
                                                <!-- <input type="text" class="form-control" id="exchangeTrade" placeholder="Exchange on which the trade was executed" autocomplete="off" formControlName="exchangeTrade">  -->
                                                <ng-select [items]="exchangeTradeFilter"
                                                bindLabel=""
                                                placeholder="Select Stock Exchange"
                                                [(ngModel)]="selectedStockExchangeD" formControlName="exchangeTrade" required [ngClass]="{ 'is-invalid': (submitted || formDEditForm?.controls.exchangeTrade.touched) && formDEditForm.controls.exchangeTrade.errors }">
                                                 </ng-select>
                                                 <div *ngIf="(submitted || formDEditForm.controls.exchangeTrade.touched) && formDEditForm.controls.exchangeTrade.errors?.required"
                                                    class="text text-danger">Exchange on which the trade is required. </div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                
    
                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading2"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading2, 'fa-check': !isLoading2}"></i> {{button2}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="resetForm()" >Cancel</button>
                                 </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->
<!--Setup2-->

