import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';
import { CompanyService } from 'src/app/master/company/company.service';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { distinct, from, tap } from 'rxjs';
@Component({
  selector: 'app-add-previous-trading',
  templateUrl: './add-previous-trading.component.html',
  styleUrls: ['./add-previous-trading.component.scss']
})
export class AddPreviousTradingComponent implements OnInit {

  @ViewChild('sQty') sQty!: ElementRef;
  @ViewChild('sQty2') sQty2!: ElementRef;
  @ViewChild('sQty3') sQty3!: ElementRef;
  @ViewChild('sQty4') sQty4!: ElementRef;
  hideFormC:boolean = false;
  hideFormD:boolean = false;
  previouspreClearanceForm: FormGroup;
  isLoading = false;
  button = 'Save';
  isLoading2 = false;
  button2 = 'Save';
  submitted:boolean = false;
  formTypes: any;
  exchangeTradeData:any;
  selectedStockExchange = [];
  exchangeTradeFilter: any[];
  responseDate: Object;
  isinNumber: any;
  companyName: any;
  selectedOthers: any;
  othersModeofAcquisition:boolean = false;
  selectedOthersformD: any;
  othersModeofAcquisitionFormD:boolean = false
  selectedTransactionType: any;
  selectedTransactionTypeFormD: any;
  othersTransactionTypepre:boolean = false;
  othersTransactionTypeFormD:boolean = false;
  perShareHoldingFormC: any;
  perShareHoldingFormD: any;
  perShareHolding2FormD: any;
  perShareHolding2FormC: any;
  companyshares: any;
  otherscategoryPersonFormC:boolean = false;
  selectedcategoryPersonFormC: any;
  dpNameFilter = [];
  selectedDpName = [];
  listDpModule: any;
  listDpArray = [];
  accessUserControl: string;
  dpdatalist: any;
  panItem: any;
  dpDesignation: any;
  dpaddress: any;
  dpContactNo: any;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private activateRouter: ActivatedRoute,
    private pitService: PitComplianceService, private companyService: CompanyService, private dpService:DpModuleService,) {
      this.accessUserControl = sessionStorage.getItem('access_user');
     }

  ngOnInit(): void {
    this.previouspreClearanceForm = this.fb.group({
      // companyName: new FormControl (''),
      // isinNumber: new FormControl (''),
      name: new FormControl ('', Validators.required),
      panNo: new FormControl ('', Validators.required),
      cin_din: new FormControl ('', Validators.required),
      address: new FormControl ('', Validators.required),
      mobNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      categoryPerson: new FormControl ('', Validators.required),
      typeOfSecurities: new FormControl ('', Validators.required),
      perShareNumber: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHolding: new FormControl (''),
      typeOfSecurities2: new FormControl ('', Validators.required),
      typeOfSecuritiesNo: new FormControl ('', Validators.required),
      typeOfSecuritiesValue: new FormControl ('', Validators.required),
      transactionType: new FormControl ('', Validators.required),
      typeOfSecuritiespost: new FormControl ('', Validators.required),
      perShareHoldingNopost: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHoldingpost: new FormControl (''),
      fromDate: new FormControl ('', Validators.required),
      toDate: new FormControl ('', [Validators.required, this.dateRangeValidator]), 
      modeOfAcquisition: new FormControl ('', Validators.required),
      othersModeofAcquisition: new FormControl(''),
      otherstransactiontype: new FormControl(''),
      otherscategoryofPerson: new FormControl(''),
    
    });

    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      this.listDpModule = res['data'];  
      
       this.listDpModule.map(item => {
        if(item.email == this.accessUserControl){
          this.listDpArray.push( item.dpName + ' - ' + item.email)
        }
      })
      this.dpNameFilter = [];
      from(this.listDpArray)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dpNameFilter.push(r));
 
        err => {
            console.log(err);
        }
      });
  }
  changeFn(val) {
    let items = val?.split('- ');
    
    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      this.listDpModule = res['data'];  
      
      this.listDpArray = this.listDpModule.map((data) => {
  
        if(items != undefined){
          if(data.email == items[1]){
              this.dpdatalist = data;             
              this.panItem = this.dpdatalist.dpPanNo;
              this.dpContactNo = this.dpdatalist.dpContactNo;
              this.dpaddress = this.dpdatalist.dpAddress;
               
          }
        }else{
          this.panItem = '';
          this.dpDesignation = '';        
       
        }
      })
    
      
        
        },
        err => {
            console.log(err);
        }
    );
   
}
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.previouspreClearanceForm && this.previouspreClearanceForm.get("fromDate").value;
    const to = this.previouspreClearanceForm && this.previouspreClearanceForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  onSelectedQty1C(){
    const quantity3 = this.sQty3.nativeElement.value;
    this.perShareHoldingFormC = quantity3 / this.companyshares * 100;
  }
  onSelectedQty2C(){
    const quantity4 = this.sQty4.nativeElement.value;
    this.perShareHolding2FormC = quantity4 / this.companyshares * 100;
  }

  modeofAcquisitionTypeChanged() {
    this.selectedOthers = this.previouspreClearanceForm.get('modeOfAcquisition')    
    if(this.selectedOthers?.value == "Others"){
      this.othersModeofAcquisition = true;
    }
  }
  
  otherTransactionTypeFormC() {
    this.selectedTransactionType = this.previouspreClearanceForm.get('transactionType')    
    if(this.selectedTransactionType?.value == "Others"){
      this.othersTransactionTypepre = true;
    }else{
      this.othersTransactionTypepre = false;
    }
  }
 
  otherCategoryOfPersonFormC() {
    this.selectedcategoryPersonFormC = this.previouspreClearanceForm.get('categoryPerson')    
    if(this.selectedcategoryPersonFormC?.value == "Others"){
      this.otherscategoryPersonFormC = true;
    }
  }
  resetForm(){
    this.previouspreClearanceForm.reset()
    this.submitted = false;
    this.router.navigate(['/pit-compliance/trading-pre-clearance']); 
  }
  onSubmitPreviousTrade(){
    let idendiferCard = this.previouspreClearanceForm.get('panNo').value; 
    if(this.previouspreClearanceForm.get('panNo').value != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       return false;
      }
    }
      this.submitted = true;
     this.previouspreClearanceForm.value.formTypes = this.formTypes;
     const emptyFileds = Object.keys(this.previouspreClearanceForm.controls).filter(key =>this.previouspreClearanceForm.controls[key].errors !== null)

     if(this.previouspreClearanceForm.status !== 'INVALID'){
    this.button = 'Processing...';
    this.isLoading = true;
    this.pitService.createPreviousPreClear(this.previouspreClearanceForm.value).subscribe(
      res => {
        this.responseDate = res['data'];
       
        if(res['success'] == 1){
          this.toastr.success(res['message']) 
          this.isLoading = false;
          this.button = 'Submit';
                    
          this.router.navigate(['/pit-compliance/trading-pre-clearance']); 
         
        }else{
          this.isLoading = false;
          this.button = 'Submit';         
          this.toastr.warning(res['message']) 
        }
                  
      });
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.previouspreClearanceForm.get('name').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.previouspreClearanceForm.get('panNo').value == ''){
        this.toastr.warning("Please fill the PAN Number.");
      }else if(this.previouspreClearanceForm.get('cin_din').value == ''){
        this.toastr.warning("Please fill the CIN/DIN Number.");
      }else if(this.previouspreClearanceForm.get('address').value == ''){
        this.toastr.warning("Please fill the Address.");
      }else if(this.previouspreClearanceForm.get('mobNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }else if(this.previouspreClearanceForm.get('categoryPerson').value == ''){
        this.toastr.warning("Please fill the  Category of Person.");
      }else if(this.previouspreClearanceForm.get('typeOfSecurities').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal Type of securities.");
      }else if(this.previouspreClearanceForm.get('perShareNumber').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal No. of share holding.");
      }else if(this.previouspreClearanceForm.get('typeOfSecurities2').value == ''){
        this.toastr.warning("Please fill the Securities acquired/Disposed Type of securities.");
      }else if(this.previouspreClearanceForm.get('typeOfSecuritiesNo').value == ''){
        this.toastr.warning("Please fill the No. of securities acquired/ disposed.");
      }else if(this.previouspreClearanceForm.get('typeOfSecuritiesValue').value == ''){
        this.toastr.warning("Please fill the securities acquired/ disposed Value of Securities.");
      }else if(this.previouspreClearanceForm.get('transactionType').value == ''){
        this.toastr.warning("Please fill the Transaction Type.");
      }else if(this.previouspreClearanceForm.get('typeOfSecuritiespost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed Type of securities.");
      }else if(this.previouspreClearanceForm.get('perShareHoldingNopost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed No. of share holding.");
      }else if(this.previouspreClearanceForm.get('fromDate').value == ''){
        this.toastr.warning("Please fill the From Date.");
      }else if(this.previouspreClearanceForm.get('toDate').value == ''){
        this.toastr.warning("Please fill the To Date.");
      }else if(this.previouspreClearanceForm.get('intimateDate').value == ''){
        this.toastr.warning("Please fill the Date of intimation to company.");
      }else if(this.previouspreClearanceForm.get('modeOfAcquisition').value == ''){
        this.toastr.warning("Please fill the Mode of acquisition /disposal.");
      }else if(this.previouspreClearanceForm.get('exchangeTrade').value == ''){
        this.toastr.warning("Please fill the Exchange on which the trade.");
      }
    }
  }
}
