import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-reject-annual-report-module',
  templateUrl: './reject-annual-report-module.component.html',
  styleUrls: ['./reject-annual-report-module.component.scss']
})
export class RejectAnnualReportModuleComponent implements OnInit {
  @Input() listDpModuleList;
  rejectAnnualConfirmFilter = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  rejectAnnualConfirmForm : FormGroup;
  button = 'Send';
  isLoading = false;
  listDpModule: any;
  listDpEmail = [];
  newDate = new Date();
  currentDate: any;
  financialyear: string;
  getId: any;
  count: any;
  submitted:boolean = false;
  immediatRelativesPerson:any;
  d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ];
  
  p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ];

  immediateNameValid: any;
  constructor(public activeModal: NgbActiveModal, public router: Router, private fb:FormBuilder, private toastr: ToastrService,
    private profileService: ProfileService, private dpService:DpModuleService) { }
    createImmediateRelative(){
      return this.fb.group({
        imr_id: new FormControl(''),
        name: new FormControl('', Validators.required),
        relationship: new FormControl('', Validators.required),
        phone_no: new FormControl('', Validators.required),
        pan: new FormControl('', Validators.required),
        DocumentType: new FormControl('', Validators.required)
      })
    }
    i:number;
    addInputControls(){
      // this.procedureCont = true;
      const add = this.rejectAnnualConfirmForm.get('immediateDpRelative') as FormArray;
     
      add.push(this.createImmediateRelative()) 
      this.count = this.rejectAnnualConfirmForm.get('immediateDpRelative');
      // let item1 = this.count.find(i => i.imr_id === this.rejectAnnualConfirmForm.get('immediateDpRelative'));
     
      // let item1 = this.count.find(i => i.imr_id === this.count.imr_id);
      // console.log('count2', item1)
    //   for(let i = 1; i === this.count.value[i].imr_id; i++){
    //     this.count = i+1;
    //     console.log("i", this.count)
    // }
  
    }
    removeInputControls(idx: number){
      const add = this.rejectAnnualConfirmForm.get('immediateDpRelative') as FormArray;  
      add.removeAt(idx); 
      this.count = this.rejectAnnualConfirmForm.get('immediateDpRelative').value.length;
        
  }
 
  ngOnInit(): void {
      this.getId = this.listDpModuleList.id
      
    this.rejectAnnualConfirmFilter =[];
    if(this.getId !==''){
      this.dpService.viewDpModule(this.getId)
      .toPromise().then(result =>{        

        this.immediatRelativesPerson = result.data[0].immediateDpRelative ;
        result.data[0].immediateDpRelative = result.data[0].immediateDpRelative;
        if( this.immediatRelativesPerson &&  this.immediatRelativesPerson.length > 1){
          for(let i = 1;i<  this.immediatRelativesPerson.length; i++ ) {
            this.addInputControls();
          }
        }
        this.rejectAnnualConfirmForm.patchValue(result.data[0]);
       

      })
    this.rejectAnnualConfirmForm = this.fb.group({
      role_name: new FormControl ('',  Validators.required),
      content_text: new FormControl ('',  Validators.required),
      dpName: new FormControl ('',  Validators.required),
      dpDesignation: new FormControl ('',  Validators.required), 
      email: new FormControl ('',  Validators.required),
      dpPanNo: new FormControl ('',  Validators.required),
      DocumentTypes: new FormControl ('',  Validators.required),
      dpContactNo: new FormControl ('',  Validators.required),
      dpEducationalInstitute: new FormControl ('',  Validators.required),
      dpAddress: new FormControl ('',  Validators.required),
      dpPastEmp: new FormControl ('',  Validators.required),
      immediateDpRelative : new FormArray([this.createImmediateRelative()]),
      //annualConfirmation : new FormArray([this.createAnnualConfirmation()]),
    });
  }
    this.selectedItems = [];
 
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select the Admin',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
    
    this.dpService.dpModuleList(null).subscribe(
      res => {
        
      this.listDpModule = res['data'];  
          
    //Get email
    this.listDpModule.map((role,email) => {
     
     
       if(role.role == 'Admin'){
        this.listDpEmail.push({id:role.id, itemName:role.email});
       
       }
    })
   
    this.rejectAnnualConfirmFilter = [];
    from(this.listDpEmail)
    .pipe(
    tap(item => item),
    distinct(d => d.itemName)
    )
    .subscribe(r => this.rejectAnnualConfirmFilter.push(r));
        
        },
        err => {
            console.log(err);
        }
    );

    //Date
    this.getCurrentFinancialYear()

//Current Date
const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
this.currentDate = day + '-' + month + '-' + year;
  }
  getValidity(i) {
    return (<FormArray>this.rejectAnnualConfirmForm.get('immediateDpRelative')).controls[i].invalid;
  }


  getCurrentFinancialYear() {
    this.financialyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      this.financialyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      this.financialyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    
    return this.financialyear
  
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  cancelProject(){
    this.activeModal.close(event);   
    this.rejectAnnualConfirmForm.reset();
  }
   //Multi select box
   onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  //AAdharValidation
validate(addharNumber) {
  let c = 0;
  let invertedArray = addharNumber
    .split('')
    .map(Number)
    .reverse();

  invertedArray.forEach((val, i) => {
    c = this.d[c][this.p[i % 8][val]];
  });

  return c === 0;
}

  //multi select box
  onSubmit(){
    
    let idendiferCard = this.rejectAnnualConfirmForm.get('dpPanNo').value;
    if(this.rejectAnnualConfirmForm.get('DocumentTypes').value == 'PAN'){
      
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       // this.messageValid = 'Please Enter the Valid PAN.';
       return false;
      }
    }else if(this.rejectAnnualConfirmForm.get('DocumentTypes').value == 'Aadhar card'){
      
      let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter Valid Aadhar Number');
        return false;
      }else{
      if (this.validate(idendiferCard) && idendiferCard) {         
        console.log('');
      } else {
        this.toastr.warning('Your Aadhar Number is InValid')
        return false;      
      }

    }
    }else if(this.rejectAnnualConfirmForm.get('DocumentTypes').value == 'Voter ID'){
      let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter Valid Voter ID');
        return false;
      }
  }else if(this.rejectAnnualConfirmForm.get('DocumentTypes').value == 'Driving License'){
    let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter Valid Driving License');
        return false;
      }
  }
  //Immidiate relative
  if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'].length > 0){
    for(let i=0; i<this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'].length; i++){
      if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value != '' && this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value ==''){
        this.toastr.warning( 'Enter the ' + [i + 1] + ' row ID Proof No.')
          return false;  
      }
      let idendiferCard = this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value;
      if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the "+ [i + 1 ] + " row Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Aadhar card'){
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
          console.log('');
        } else {
          this.toastr.warning( [i] + ' row Your Aadhar Number is InValid')
          return false;      
        }
      }
    }else if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Voter ID'){
      let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Voter ID');
        return false;
      }
  }else if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Driving License'){
    let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Driving License ');
        return false;
      }
  }
    }

  }
  this.submitted = true;
  const emptyFileds = Object.keys(this.rejectAnnualConfirmForm.controls).filter(key =>this.rejectAnnualConfirmForm.controls[key].errors !== null)
  if(this.rejectAnnualConfirmForm.status !== 'INVALID'){
  this.isLoading = true;
    this.button = 'Processing';
    // this.rejectAnnualConfirmForm.value.annualConfirmDetails = this.listDpModuleList;
    this.rejectAnnualConfirmForm.value.financialyear = this.financialyear;
    this.rejectAnnualConfirmForm.value.currentDate= this.currentDate
    //console.log("1222", this.rejectAnnualConfirmForm.value)
      this.profileService.rejectAnnualConfirmReport(this.rejectAnnualConfirmForm.value).subscribe(data =>{
        this.button = 'Send';
        this.isLoading = false;
          this.toastr.success("Annual Confirmation Details Successfully Sented");
          this.activeModal.close(event);
          //window.location.href;
       
      })
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.rejectAnnualConfirmForm.get('role_name').value == ''){
        this.toastr.warning("Select the Admin Details.");
      }else if(this.rejectAnnualConfirmForm.get('content_text').value == ''){
        this.toastr.warning("Please Enter the Mail Content.");
      }else if(this.rejectAnnualConfirmForm.get('dpName').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.rejectAnnualConfirmForm.get('dpDesignation').value == ''){
        this.toastr.warning("Please fill the Designation.");
      }
      else if(this.rejectAnnualConfirmForm.get('email').value == ''){
        this.toastr.warning("Please fill the Email ID.");
      }
      else if(this.rejectAnnualConfirmForm.get('dpContactNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }
      else if(this.rejectAnnualConfirmForm.get('dpPanNo').value == ''){
        this.toastr.warning("Please fill the ID Proof Type.");
      }
      else if(this.rejectAnnualConfirmForm.get('DocumentTypes').value == ''){
        this.toastr.warning("Please fill the ID Proof No.");
      }
      else if(this.rejectAnnualConfirmForm.get('dpAddress').value == ''){
        this.toastr.warning("Please fill the Address.");
      }
      else if(this.rejectAnnualConfirmForm.get('dpEducationalInstitute').value == ''){
        this.toastr.warning("Please fill the Educational Institute Name, Last Graduated From.");
      }
      else if(this.rejectAnnualConfirmForm.get('dpPastEmp').value == ''){
        this.toastr.warning("Please fill the Company Name of Previous Job.");
      }else if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'].length > 0){
      
        for(let i=0; i<this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'].length; i++){
          const emptyFileds = Object.keys(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls']).filter(key =>this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'][key].errors !== null)
       
        if(emptyFileds.length > 1){
          this.toastr.warning("Please fill all Immediate Relative Mandatory fileds.");
          return false;
        }else{
          if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].name.value == ''){
             this.toastr.warning('Please Enter ' + [i + 1] + ' row Immediate Relative Name');
            return false;
          }
          if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].relationship.value == ''){
             this.toastr.warning('Please Select ' + [i + 1] + ' row Immediate Relative relationship');
            return false;
          }
          if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].phone_no.value == ''){
             this.toastr.warning('Please Enter ' + [i + 1] + ' row Immediate Relative Contact Number');
            return false;
          }
          if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == ''){
             this.toastr.warning('Please Enter ' + [i + 1] + ' row Immediate Relative ID Proof Type');
            return false;
          }
          if(this.rejectAnnualConfirmForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value == ''){
             this.toastr.warning('Please Enter ' + [i + 1] + ' row Immediate Relative ID Proof No.');
            return false;
          }
        }
        }
      
      }
    }
  }
}
