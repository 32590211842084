import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { environment } from 'src/environments/environment';
import { NavService, Menu } from '../../services/nav.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from "file-saver/dist/FileSaver";
import FileSaver from "file-saver";
import { ModelControlService } from 'src/app/model-access-control/model-control.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  upsiId: any;
  dpList: any;
  listDpModule: any;
  userType: any;
  itemEmail: any;
  accessUser: any;
  sideMenuLogoPath: string;
  companyName: string;
  seperateRole:any;
  accessUserControl: any;
  moduleControlList: any;
  complianceofficeremail: string | null;

  constructor(private router: Router, public navServices: NavService,private http:HttpClient, private modelService: ModelControlService) {
    this.accessUser = sessionStorage.getItem('access_user');
   
    this.userType = sessionStorage.getItem('access_token');
    
    this.navServices.items.subscribe((menuItems) => {
      this.router.events.subscribe((event) => {
       
      
        // console.log(sessionStorage.getItem('sdd_user_type'));
        this.menuItems = this.filterByUserRole(JSON.parse(JSON.stringify(menuItems)), event);
       
      });
    })
    
    this.sideMenuLogoPath = environment.sideMenuLogoPath;
    this.companyName = environment.companyName;
    this.accessUserControl = sessionStorage.getItem('access_user');
    this.complianceofficeremail = sessionStorage.getItem('complianceofficeremail');
  }
ngOnInit(){
  
   
  //var indexSep = accessUser.indexOf( "@legalitysimplified.com" );
  this.modelService.moduleControlList(null).subscribe(
    res => {
     
    this.moduleControlList = res['data'];
    
  
    
  })
  
}
  filterByUserRole (menuItems, event) {
    
   // console.log("event", event);
    //var indexSep = this.accessUserControl.substring(this.accessUserControl.lastIndexOf("@") +1);
    var indexSep = this.accessUserControl;
    //console.log("index", indexSep);
    if(indexSep == 'sdd@legalitysimplified.com'){
      this.seperateRole = 'Allow';
    }else if(indexSep == this.complianceofficeremail){
      this.seperateRole = 'COE';
    }else{
      this.seperateRole = 'NOT';
    }


    let userRole = sessionStorage.getItem('sdd_user_type');
   let accessControl = this.seperateRole;

    let filteredMenuItems = menuItems.filter(menuItem => {
      if((menuItem.userRoles != 3 && menuItem.access) && menuItem.role?.length > 0 && (menuItem.role[0] == 'All' || menuItem.role.indexOf(userRole) >= 0 || menuItem.role.indexOf(accessControl) >= 0 ) ) {
        return menuItem;
      } else if(menuItem.children && menuItem.children.length > 0) {

        menuItem.children = this.filterByUserRole(menuItem.children, event);
        return menuItem;
      }
      if(menuItem.path && menuItem.path == event.url){
        this.setNavActive(menuItem);
      }
    });
    //console.log("filteredMenuItems", filteredMenuItems)
    return filteredMenuItems;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })

  }
//(click)="getMenuItem(event.target)"
  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active

    
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  getMenuItem(item){
  
    if(item.title == 'Help Manual'){
  
  const pdfUrl ="/assets/help-manual/InSiDDer Premium Help Manual.pdf";
    const pdfName = "InSiDDer Premium Help Manual.pdf";
    FileSaver.saveAs(pdfUrl, pdfName);

    }

  }
 

}
function indexOf(seperateRole: any[]): any {
  throw new Error('Function not implemented.');
}

function saveAs(blob: Blob, filename: string) {
  throw new Error('Function not implemented.');
}

