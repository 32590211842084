import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { MasterModule } from '../app/master/master.module';
import {DpModuleModule} from '../app/dp-module/dp-module.module';
import {InsiderModule} from '../app/insider/insider.module';
import { UpsiTransferModule } from './upsi-transfer/upsi-transfer.module';
import { ReportModule } from '../app/report/report.module';
import { ConfirmPasswordComponent } from './auth/confirm-password/confirm-password.component'
import {EmailVerifiedComponent} from './auth/email-verified/email-verified.component'
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { BnNgIdleService } from 'bn-ng-idle';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { StrengthCheckerComponent } from './auth/confirm-password/strength-checker/strength-checker.component';
import {PitComplianceModule } from '../app/pit-compliance/pit-compliance.module';
import {ModelAccessControlModule } from '../app/model-access-control/model-access-control.module';
import { OptAuthComponent } from './auth/opt-auth/opt-auth.component'
import { MaterialModule } from './material.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxOtpInputModule } from "ngx-otp-input";
import { CustomerModule } from "./customer/customer.module";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent, 
    EmailVerifiedComponent,
    ConfirmPasswordComponent,
    BlockCopyPasteDirective,
    StrengthCheckerComponent,
    OptAuthComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MasterModule,
    DpModuleModule,
    InsiderModule,
    UpsiTransferModule,
    ReportModule,
    AngularEditorModule,
    PitComplianceModule,
    MaterialModule,
    AngularMultiSelectModule,
    NgxOtpInputModule,
    ModelAccessControlModule,
    CustomerModule,
  

  ],
  providers: [AuthService, AdminGuard,BnNgIdleService, SecureInnerPagesGuard, CookieService, DecimalPipe,],
  bootstrap: [AppComponent]
})
export class AppModule { }
