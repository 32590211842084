import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss']
})
export class ViewProjectComponent implements OnInit {
  projectId: any;
  viewProjectList: any;

  constructor(private masterService: MasterService, public router: Router, private activateRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data => {
      this.projectId = {'id':data['id']}
    })
    if (this.projectId !== '') {
      this.masterService.viewProject(this.projectId).subscribe(
        data =>{
        this.viewProjectList = data;
       
      })

      }
  }
  

}
