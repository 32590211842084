import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-delete-customer',
  templateUrl: './delete-customer.component.html',
  styleUrls: ['./delete-customer.component.scss']
})
export class DeleteCustomerComponent implements OnInit {
  insiderId: any;
  accessSuccess: string;
  @Input() viewcustomerList;
  isLoading = false;
  button = 'Delete';
  customerReasonDeleteForm: FormGroup;
  getId: any;
  submitted = false;
  constructor(private fb: FormBuilder, private customerService:CustomerService, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

    this.customerReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])
    });
   this.getId = this.viewcustomerList.id;
  

   
  }

onSubmit(){
  this.submitted = true;
  this.customerReasonDeleteForm.value.id = this.getId;
  if(this.customerReasonDeleteForm.status !== 'INVALID'){
  this.isLoading = true;
      this.button = 'Processing';
  this.customerService.deletecustomer(this.customerReasonDeleteForm.value).subscribe(data =>{    
    this.toastr.success("Customer Details Successfully Deleted");
    this.isLoading = false;
      this.button = 'Delete';
    // this.router.navigate(['/insider/insider-list']);
    window.location.reload();
    
  })
}else{
  if(this.customerReasonDeleteForm.get('reasonfordelete').value == ''){
    this.toastr.warning("Please Mention the Reason for Deleting Customer Details.");
  }
}
}


      
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
