<!--Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Add Mail Template</h5>
                    <a routerLink="/setup/list-mail-template"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="mailCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="subject">Template Name</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="templatename" placeholder="Enter the Template Name" autocomplete="off" formControlName="templatename" required
                                    [ngClass]="{ 'is-invalid': (submitted || mailCreateForm.controls.templatename.touched) && mailCreateForm.controls.templatename.errors }"> 
                                    <div *ngIf="(submitted || mailCreateForm.controls.templatename?.touched) && mailCreateForm.controls.templatename.errors?.required"
                                        class="text text-danger">
                                        Template Name is required.
                                    </div>
                                    <div class="text text-danger" *ngIf="mailCreateForm.controls.templatename.errors?.pattern">Special Charactors Not Allowed!</div>
                                </div>

                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="subject">Subject</label>
                                <div class="form-group">                    
                                    <input type="text" class="form-control" id="subject" placeholder="Enter the Subject" autocomplete="off" formControlName="subject" required
                                    [ngClass]="{ 'is-invalid': (submitted || mailCreateForm.controls.subject.touched) && mailCreateForm.controls.subject.errors }"> 
                                    <div *ngIf="(submitted || mailCreateForm.controls.subject?.touched) && mailCreateForm.controls.subject.errors?.required"
                                        class="text text-danger">
                                        Subject Name is required.
                                    </div>
                                    <div class="text text-danger" *ngIf="mailCreateForm.controls.subject.errors?.pattern">Special Charactors Not Allowed!</div>
                                </div>

                            </div>
                            <div class="col-md-12 mb-3">
                                <label for="bodyContent">Body Content</label>
                                <div class="form-group">                    
                                    <angular-editor [(ngModel)]="htmlContent" [config]="config" #userPhoto formControlName="bodycontent"  required ></angular-editor>
                                    <div *ngIf="(submitted || mailCreateForm.controls.bodycontent?.touched) && mailCreateForm.controls.bodycontent.errors?.required"
                                    class="text text-danger">
                                    Body Content is required.
                                </div>
                                    <div class="text text-danger" *ngIf="mailCreateForm.controls.bodycontent.errors?.pattern">Special Charactors Not Allowed!</div>
                                </div>

                            </div>

                            <div class="submit-btn">
                                <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelMailTemplate()" >Cancel</button>
                             </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->