
<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Model Access Control List</h5>
                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form  [formGroup]="modelAccessControlForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                        <div class="accessCreateFormow" >
                                                    
                           <h5>Permission Controls</h5> 
                            <!-- <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="upsiName" placeholder="Model Access Person" formControlName="dpaccess_name" required disabled>                                                                
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0"> 
                                                <ng-multiselect-dropdown [placeholder]="'Select Access DP'"
                                                [settings]="dropdownSettings1"
                                                [data]="dpAccessDataList"                                                                    
                                                [(ngModel)]="selecteddpAccessDataItems"
                                                (onSelect)="onItemSelect1($event)"
                                                (onDeSelect)="onItemDeSelect1($event)"
                                                (onSelectAll)="onSelectAll1($event)"
                                                (onDeSelectAll)="onDeSelectAll1($event)" formControlName="accessdpname" >                                                    
                                                </ng-multiselect-dropdown>
                                            
                                        </div>                                      
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="upsiName" placeholder="Log UPSI Transfer" formControlName="upsi_name" required disabled>                                                                
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="upsi_access"  [color]="color">Enable UPSI's</mat-slide-toggle>                                            
                                                </div>                                          
                                            </div>
                                        </div>                                      
                                    </div>
                                </div>
                            </div>
                             
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="dpName" placeholder="DP" formControlName="dp_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="dp_access">Enable Dp</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="insiderName" placeholder="Insider" formControlName="insider_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="insider_access">Enable Insider</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="projectName" placeholder="Project" formControlName="project_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="project_access">Enable project</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="companyName" placeholder="Company Profile" formControlName="company_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="company_access">Enable Company Profile</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="rolesName" placeholder="Roles and Permission" formControlName="roles_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="roles_access">Enable Roles and Permission</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="activityName" placeholder="Activity Logs" formControlName="activity_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="activity_access">Enable Activity Logs</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="reportsName" placeholder="Reports" formControlName="reports_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="reports_access">Enable Reports</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="reportsName" placeholder="Dp Label" formControlName="dplabel_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="dplabel_access">Enable Dp Label</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="twcName" placeholder="Trading Window Closure" formControlName="twc_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="twc_access">Enable Trading Window Closure</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                          
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="preclearanceName" placeholder="Pre Clearance" formControlName="preclearance_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="preclearance_access">Enable Pre Clearance</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="benposName" placeholder="BENPOS Report" formControlName="benpos_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="benpos_access">Enable BENPOS Report</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="formcordName" placeholder="Form C and Form D Report" formControlName="formcord_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="formcord_access">Enable Form C and Form D Report</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mailTemplateName" placeholder="Mail Template" formControlName="mailtemplate_name" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="mailtemplate_access">Enable Mail Template</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="rules" placeholder="Rules" formControlName="rules" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="rules_access">Enable Rules</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="rules" placeholder="Company's Lists" formControlName="companys_lists" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="companys_lists_access">Enable Company's Lists</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">  
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="customer"  placeholder="Customer Lists" formControlName="customer_lists" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle  [(ngModel)]="customerAccess" formControlName="customer_access">Enable Customer Lists</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="customer" placeholder="UPSI Attachment" formControlName="upsiattachment_lists" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="upsiattachment_access">Enable UPSI Attachment</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="otp" placeholder="Otp Authentication" formControlName="otp" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="otp_access">Enable Otp Authentication</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="bulkupload" placeholder="UPSI Bulk Upload 5.30 hours Difference" formControlName="bulkupload" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="bulkupload_access">Enable UPSI Bulk Upload 5.30 hours Difference</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="tcndropname" placeholder="Preclearance Trading company Name dropdown" formControlName="tcndropdown" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="tcndropdown_access">Enable Preclearance Trading company Name dropdown</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="tradecount" placeholder="Preclearance Trading Day Count" formControlName="tradecount" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="tradecount_access">Enable Preclearance Trading Day Count</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mailsetting" placeholder="Mail Setting" formControlName="mailsetting" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="mailsetting_access">Enable Mail Setting</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mailsetting" placeholder="Preclearance Hide Column" formControlName="hidecolumn" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="hidecolumn_access">Enable Preclearance Hide Column</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="noupsi" placeholder="Non UPSI Communication" formControlName="noupsi" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="noupsi_access">Enable Non UPSI Communication</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="complemail" placeholder="Compliance Office Email ID" formControlName="complemail" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="complemail_access">Enable Compliance Office Email ID</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="userlog" placeholder="User Log" formControlName="userlog" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="userlog_access">Enable User Log</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="systeminfo" placeholder="System Info" formControlName="systeminfo" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="systeminfo_access">Enable System Info</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="bulkuploadfolder" placeholder="Bulk Upload folder" formControlName="bulkuploadfolder" [(ngModel)]="bulkFolder" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="bulkuploadfolder_access">Enable Bulk Upload folder</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="twcattachment" placeholder="TWC Attachment" formControlName="twcattachment_lists" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="twcattachment_access">Enable TWC Attachment</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <div class="row">
                                    <div class="col-md-4 ">                                    
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="users_lists" placeholder="Users List" formControlName="users_lists" required disabled>                                                                   
                                        </div>
                                    </div>
                                    <div class="col-md-8 mb-slide">
                                        <div class="form-group row mb-0">                                       
                                            <div class="col-sm-12">
                                                <div class="form-group m-checkbox-inline mb-0 ms-1">
                                                    <mat-slide-toggle formControlName="users_access">Enable Users List</mat-slide-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                              <div class="submit-btn">
                                <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelRoles()">Cancel</button>
                             </div>
                        </div>
                    </form>
                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->
