import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import * as moment from 'moment';
import { ReportService } from 'src/app/report/report.service';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import { UpsiService } from '../../upsi-transfer/upsi.service';
import { PitComplianceService } from '../pit-compliance.service';

type AOA = any[][];

@Component({
  selector: 'app-excel-report',
  templateUrl: './excel-report.component.html',
  styleUrls: ['./excel-report.component.scss']
})
export class ExcelReportComponent implements OnInit {
  button = 'Submit';
  isLoading = false;
  title = "FileUp";
  form: FormGroup;
  @Input() name;
  private stepper: Stepper;
  filePathName: string;
  fileData: any;
  excelFile: any;
  excelData: any;
  responseDate: any;

  next() {
    this.stepper.next();
  }
  constructor(
     private upsiService: UpsiService, public router: Router, private builder: FormBuilder, private reportService: ReportService,
     private pitService: PitComplianceService
  ) { 
    this.form = this.builder.group({
      files: ['']
    });
  }
  

  ngOnInit() {
        
    // this.stepper = new Stepper(document.querySelector('#stepper1'), {
    //   linear: false,
    //   animation: true
    // })
   
  }

  // closeModal(sendData) {
  //   this.activeModal.close(sendData);
  // }
//read excel
data: AOA = [];
wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
fileName: string = 'SheetJS.xlsx';
submitData() {
  this.isLoading = true;
    this.button = 'Processing';
    setTimeout(() => {
      
      let reqData: any = {excel: this.excelData};
  if(this.fileData) {
    reqData.file = this.fileData;
  }

  this.pitService.sendJSONToServer(reqData).subscribe(
    res => {
      // console.log(res);
      // this.closeModal(event);
          
      this.responseDate = res['body'];
      // console.log("responseDate", this.responseDate)
      if(this.responseDate.success !== '0'){
        window.alert(this.responseDate.message)            
        window.location.reload();
        this.isLoading = false;
        this.button = 'Submit'; 
        
       
      }else{
        window.alert(this.responseDate.message);
        this.isLoading = false;
        this.button = 'Submit';
      }

    },
    err => {
      console.log(err);
    }
  )
    }, 20000)
  
  
}

onFileChange(evt: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(evt.target);
  if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellNF: false, cellDates: true });
    
    // var tableData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {range:3});

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
   
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];  

    /* save data */
    this.data =  <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: "yyyy-mm-dd hh:mm:ss" }));
    let reqArr = [];
    //console.log("this.data", this.data)
    
    if(this.data.length > 1) {
      let keys = this.data[0];
      for(let i=1;i< this.data.length;i++) {
        let reqObj = {};
        this.data[i].map((valStr, index) => {
          console.log(keys[index]+ "--- "+valStr);
          
          // if(keys[index] === 'Date_Of_Sharing') {
          //   valStr = moment(valStr, 'yyyy-MM-DD HH:mm:ss').add({hours: 5, minutes: 30});
          //   // console.log(valStr.format(`yyyy-MM-DD hh:mm`));
          //   valStr = valStr.format(`yyyy-MM-DD HH:mm:ss`);
          //   this.data[i][index] = valStr;
          // } else if(moment(valStr, 'yyyy-MM-DD HH:mm:ss').isValid()) {
          //   valStr = valStr.split(" ")[0];
          //   // console.log(valStr);
          //   this.data[i][index] = valStr;
          // }
          reqObj[keys[index]] = valStr
        });
       console.log(reqObj);
        reqArr.push(reqObj);
        
      }
      
    }
    

    // if(this.data.length > 1) {
    //     let keys = this.data[0];
    //     // keys[this.data[0].length] = "Time_Of_Sharing";
    //     for(let i=1;i< this.data.length;i++) {
    //       let reqObj = {};
    //       this.data[i].map((valStr, index) => {
    //         console.log(keys[index]+ "--- "+valStr);
    //         if(keys[index] === 'Date_Of_Sharing_And_Time') {
    //           valStr = moment(valStr, 'yyyy-MM-DD hh:mm:ss').add({hours: 5, minutes: 30});
    //           // console.log(valStr.format(`yyyy-MM-DD hh:mm`));
    //           valStr = moment(valStr).format(`yyyy-MM-DD hh:mm:ss`);
    //           this.data[i][index] = valStr;
    //           let valStrArr = valStr.split(" ");
    //           reqObj['Times_Of_Sharing'] = valStrArr[1];
    //           valStr = valStrArr[0];
    //         } else if(moment(valStr, 'yyyy-MM-DD hh:mm:ss').isValid()) {
    //           valStr = valStr.split(" ")[0];
    //           // console.log(valStr);
    //           this.data[i][index] = valStr;
    //         }
            
    //         reqObj[keys[index]] = valStr
    //       });
    //      console.log(reqObj);
    //       reqArr.push(reqObj);
    //     }
        
    //   }

    // if(this.data.length > 1) {
    //   let keys = this.data[0];
    //   for(let i=1;i< this.data.length;i++) {
    //     let reqObj = {};
    //     this.data[i].map((valStr, index) => {
    //       console.log(keys[index]+ "--- "+valStr);
    //       if(keys[index] === 'Time_Of_Sharing') {
    //         // valStr = moment(valStr, 'yyyy-MM-DD hh:mm');
    //         console.log(valStr);
    //         // valStr = moment(valStr).format(`hh:mm`);
    //         this.data[i][index] = valStr;
    //       } else if(moment(valStr, 'yyyy-MM-DD hh:mm').isValid()) {
    //         valStr = valStr.split(" ")[0];
    //         // console.log(valStr);
    //         this.data[i][index] = valStr;
    //       }
    //       reqObj[keys[index]] = valStr
    //     });
    //    console.log(reqObj);
    //     reqArr.push(reqObj);
    //   }
      
    // }
    this.excelData = reqArr;
    // console.log(JSON.stringify(reqArr));
  };
  reader.readAsBinaryString(target.files[0]);
  let element = document.getElementById('excel-report');
  element.className = 'exRep'
}


//drag and drop
saveFile(files: FileList) {
  this.filePathName = files[0].name;
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    const bstr: string = e.target.result;
    this.fileData = bstr;
  };
  reader.readAsBinaryString(files[0]);
}

// export(): void {
//   /* generate worksheet */
//   let anchorTag = document.createElement("a");
//   anchorTag.setAttribute('type', 'hidden');
//   anchorTag.href = "/assets/excel/upsi_details.xlsx";
//   anchorTag.download = "upsi_details.xlsx";
//   anchorTag.click();
//   anchorTag.remove();
//   // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.excelFile);

//   // /* generate workbook and add the worksheet */
//   // const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

//   // /* save to file */
//   // XLSX.writeFile(wb, this.fileName);
// }

}
