import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';
import { PreClearanceMailTemplateComponent } from '../pre-clearance-mail-template/pre-clearance-mail-template.component';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-disclosure-exist-trading',
  templateUrl: './disclosure-exist-trading.component.html',
  styleUrls: ['./disclosure-exist-trading.component.scss']
})
export class DisclosureExistTradingComponent implements OnInit {
  @Input() previouspreClearanceForm;
  @Input() errorSharesMessage;
  @Input() errorValuesMessage;
  @Input() preClearanceDataList;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getIdDp: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  companyName: any;
  isinNumber: any;
  getStatus: any;
  complianceofficerdesignation: any;
  complianceofficername: any;
  complianceOfficerEmail: any;
  responseDate: any;
  isLoading = false;
  button = 'Accept';
  totalValues: number;
  totalshares: number;
  sharesCount: any;
  valuesCount: any;
  extraTradingValues = [];
  complianceOfficerDesignation: any;
  accessModelController: any;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService,
    private companyService: CompanyService, private pitService: PitComplianceService, private modalService: NgbModal, ) { }

  ngOnInit() {
  
    let accessData = sessionStorage.getItem('model_access_controller');    
    this.accessModelController = JSON.parse(accessData);
 this.getIdDp = this.preClearanceDataList.id;
 this.getStatus = this.preClearanceDataList.status;
 
    //company details
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      // this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      // this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      this.isinNumber = listCompany[0].isin; 
      this.complianceofficername = listCompany[0].compliance_officer_name; 
      this.complianceofficerdesignation = listCompany[0].designation; 
      this.complianceOfficerDesignation = listCompany[0].designation;    

    }
    },
    err => {
        console.log(err);
    }
);

this.pitService.listPreviousPreClear(null).subscribe(
  res => {
        let listPreviousPreClear = res['data'];             

      let remainingtradingpreclearance = [];
      let listPreviousPreClearShare = [];
       let listPreviousPreClearValue = [];
       this.extraTradingValues = [];
        listPreviousPreClear.map(data =>{
          if(data.preclearance_id === this.getIdDp){              
          
           listPreviousPreClearShare.push(data.no_of_securirities);
           listPreviousPreClearValue.push(data.price);
          }else{
            remainingtradingpreclearance.push(data);                   
          }
        })
        this.totalValues = 0;
        this.totalshares = 0
        for(let i=0; i<listPreviousPreClearValue.length; i++){
          this.totalValues += Number(listPreviousPreClearValue[i]);               
        }
        for(let i=0; i<listPreviousPreClearShare.length; i++){
          this.totalshares += Number(listPreviousPreClearShare[i]); 
          
        } 
        let getpreclearanceSahres = this.preClearanceDataList.Estimatednumbersecur;
        let getpreclearanceValues = this.preClearanceDataList.transactionisproposed;

        let totalSharess = Number(this.previouspreClearanceForm.no_of_securirities) + Number(this.totalshares);
        let totalValuess = Number(this.previouspreClearanceForm.price) + Number(this.totalValues);
        this.sharesCount = totalSharess - getpreclearanceSahres;
        
        this.valuesCount = totalValuess - getpreclearanceValues;
        if(this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1){
          if(this.errorSharesMessage == undefined && this.errorValuesMessage != undefined){
            this.extraTradingValues = ['You are reporting that the Price per share is more than proposed price of trade.  The overall transaction Value is greater than approved Value.'];
          }else if(this.errorSharesMessage != undefined && this.errorValuesMessage == undefined){
            this.extraTradingValues = ['You are reporting an increased trade of  ' + this.sharesCount + ' shares. This Transaction is greater than the pre-clearance value.'];
          }else{
            this.extraTradingValues = ['You are reporting an increased trade of  ' + this.sharesCount + ' shares. This Transaction is greater than the pre-clearance value.', 'You are reporting that the Price per share is more than proposed price of trade.  The overall transaction Value is greater than approved Value.'];
          }
        }else{
          this.extraTradingValues = ['You are reporting an increased trade of  ' + this.sharesCount + ' shares. This Transaction is greater than the pre-clearance value.'];

        }
  });


  }
 
  closeModal(sendData) {
    this.activeModal.close(sendData);
    this.router.navigate(['/pit-compliance/trading-pre-clearance', this.getIdDp]);
  }
  previousmailconfig(valId){
    this.previouspreClearanceForm['disclosureid'] = valId;
    this.previouspreClearanceForm['complianceofficeremail'] = this.complianceOfficerEmail;
    this.previouspreClearanceForm['complianceofficername'] = this.complianceofficername;
    this.previouspreClearanceForm['complianceofficerdesignation'] = this.complianceOfficerDesignation;
    this.previouspreClearanceForm['companyname'] = this.companyName;
    this.previouspreClearanceForm['extra_shares_value'] = this.extraTradingValues;
    this.pitService.previousmailconfig(this.previouspreClearanceForm).subscribe(data =>{ 
    
    });
}
onSubmit(){
  this.button = 'Processing...';
   this.isLoading = true;
   this.previouspreClearanceForm['extra_shares_value'] = this.extraTradingValues;
 
   this.pitService.createPreviousPreClear(this.previouspreClearanceForm).subscribe(
    data => {
      this.responseDate = data;
      
      if(data.success == 1){
        this.toastr.success("Previous Pre Clearance Successfully Created");
        this.isLoading = false;
        this.button = 'Accept';
        this.previousmailconfig(data.previousTradeId);
        this.activeModal.close(event);
        this.router.navigate(['/pit-compliance/trading-pre-clearance', this.getIdDp]); 
        
      }else{
        this.isLoading = false;
        this.button = 'Submit';         
        this.toastr.warning(data.message) 
      }
                
    });
}
}
