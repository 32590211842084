
<!-- Container-fluid starts -->
<div class="container-fluid">
   
    <div class="row" *ngIf = "is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    
                    <button (click)="filterData()" type="button" class="btn  btn-danger hide-btn">
                        {{filterDateItem?"Filter Data":"Filter Data"}}
                      </button>
                      <ng-container *ngIf="visible">
                    <div class="filterFormSection" >
                        <form class="date-block" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="date-block__list">
                                    <div class="col-md-6 mb-3">
                                        <label for="fromDate">From Date of Sharing (Range)</label>
                                        <div class="form-group input-group">
                                        <input class="form-control " placeholder="yyyy-mm-dd"  type="date" formControlName="dateOfSharing">                                  
                                        </div>                   
                                    </div>
                                    <div class="col-md-6 mb-3 list" style="position: relative;">
                                        <label for="fromDate">To Date of Sharing (Range)</label>
                                        <div class="form-group input-group">
                                        <input class="form-control " placeholder="yyyy-mm-dd" type="date" formControlName="toDate" min="1997-01-01" max="2050-12-31">                                  
                                        </div>                 
                                        <p *ngIf="filterForm.touched && filterForm.invalid" style="color:red;position: absolute;bottom: -1.5rem;">Please add a valid from and To date </p>
                                    </div>
                                    
                                    <div class="col-md-6 mb-3">
                                        <label for="projectName">Project/Event Name</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Projects'"
                                                [settings]="dropdownSettings"
                                                [data]="dropdownList"
                                                [(ngModel)]="projectItemList"
                                                (onSelect)="onItemSelect($event,'project')"
                                                (onDeSelect)="onItemDeSelect($event,'project')"
                                                (onSelectAll)="onSelectAll($event,'project')"
                                                (onDeSelectAll)="onDeSelectAll($event,'project')" formControlName="projects"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="row filter-search">
                                    <div class="col-md-6 mb-3">
                                        <label for="projectName">Sender Name</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Sender'"
                                                [settings]="dropdownSettings"
                                                [data]="senderList"
                                                [(ngModel)]="senderItemList"
                                                (onSelect)="onItemSelect($event,'sender')"
                                                (onDeSelect)="onItemDeSelect($event,'sender')"
                                                (onSelectAll)="onSelectAll($event,'sender')"
                                                (onDeSelectAll)="onDeSelectAll($event,'sender')" formControlName="sender"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="projectName">Recipient Name</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Receiver'"
                                                [settings]="dropdownSettings"
                                                [data]="receiverList"
                                                [(ngModel)]="recipientItemList"
                                                (onSelect)="onItemSelect($event,'receiver')"
                                                (onDeSelect)="onItemDeSelect($event,'receiver')"
                                                (onSelectAll)="onSelectAll($event,'receiver')"
                                                (onDeSelectAll)="onDeSelectAll($event,'receiver')" formControlName="receiver"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <label for="projectName">Show Hidden Column</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                                [settings]="dropdownSettings1"
                                                [data]="hidelist"
                                                [(ngModel)]="selectedhideItems"
                                                (onSelect)="onItemSelect($event,'column')"
                                                (onDeSelect)="onItemDeSelect($event,'column')"
                                                (onDeSelectAll)="onDeSelectAll($event,'column')"
                                                (onSelectAll)="onSelectAll($event,'column')" formControlName="showHideColumn"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div> -->
                                    <div class="col-md-6 mb-3">
                                        <div class="btn-list">
                                            <button class="btn btn-primary me-3">Search</button>
                                            <!-- <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="onFilter()" alt="Download UPSI List" title="Download UPSI List"> -->
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <button class="btn btn-primary me-2" (click)="onFilter()">Excel</button> -->
                            <!-- <a href="https://localhost/sdd/public/api/upsi/indexexcel"><input type="button" class="btn btn-primary me-2" value="Excel"></a> -->
                            <!-- <div class="row mb">
                               
                                <div class="col-md-6 mb-3">
                                    <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="onFilter()">

                                </div>
                            </div> -->
                            
                        </form>
                    </div>
                </ng-container>
                    <div class="list-btn" > 
                        <a routerLink="/upsi/upsi-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
             
                    </div>
                    <div class="search-bar mb-3">                      
                        <div class="form-group">
                            <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterStrings" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search UPSI by Project, Sender, or Recipient Name" 
                             >                                                
                         </div>
                    </div>
                    <div class="hideShow mb-3">
                        <label for="projectName">Filter UPSI List Columns</label>
                        <div class="form-group">
                            <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                [settings]="dropdownSettings1"
                                [data]="hidelist"
                                [(ngModel)]="selectedhideItems"
                                (onSelect)="onItemSelect($event,'column')"
                                (onDeSelect)="onItemDeSelect($event,'column')"
                                (onDeSelectAll)="onDeSelectAll($event,'column')"
                                (onSelectAll)="onSelectAll($event,'column')" 
                                >
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="upload-File">
                        <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="openExcelDisclaimer()" alt="Download UPSI List" title="Download UPSI List">
                    </div>
                </div>
                <!-- <div class="label-form" *ngIf="isSelected">
                    <div class="row">
                            <div class="col-md-4 mb-3 selectLabelbtn">
                            <button class="btn btn-primary me-1" type="submit" (click)="onSubmitNoUpsi()">Move to UPSI</button>
                            </div>
                        
                        </div>
            </div> -->
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="Label" *ngIf="openNoUpsi == true && complianceofficeraccess">UPSI List</th>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="id no" *ngIf="show_upsi_id">ID No.</th>
                                    <th scope="col" *ngIf="show_nature_of_upsi" sortable="natureOfUpsi" style="width: 20%;">Nature of UPSI <i class="fa fa-filter" (click)="filterData1()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible1">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="natureofUpsiNameFilter"
                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                    (selected)='selectEvent($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" *ngIf="show_project_name" sortable="projectName">Project/Event Name <i class="fa fa-filter" (click)="filterData2()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="projectNameFilter"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString2" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" *ngIf="show_date_of_sharing" sortable="dateOfShare">Date of Sharing</th>
                                        <!-- <i class="fa fa-filter" (click)="filterData5()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible5">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                    (selected)='selectEvent5($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString5" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.date_of_sharing | date:'dd-MM-yyyy'"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div> -->
                                
                                    <th scope="col" *ngIf="show_sender" class="rowWidth" sortable="namSendere">Sender Name<i class="fa fa-filter" (click)="filterData3()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible3">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="senderNameFilter"
                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                    (selected)='selectEvent3($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString3" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" *ngIf="show_receiver" class="rowWidth" sortable="nameReceiver">Recipient Name<i class="fa fa-filter" (click)="filterData4()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible4">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="recipientNameFilter"
                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                    (selected)='selectEvent4($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString4" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="modeOfShare" *ngIf="show_mode_of_sharing">Mode of Sharing <i class="fa fa-filter" (click)="filterData6()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible6">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="modeofSharingNameFilter"
                                                    [searchKeyword]="keyword6" placeholder="Search ..."
                                                    (selected)='selectEvent6($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString6" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="purposeOfShare" *ngIf="show_purpose_of_sharing">Purpose of Sharing <i class="fa fa-filter" (click)="filterData7()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible7">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="purposefSharingNameFilter"
                                                    [searchKeyword]="keyword7" placeholder="Search ..."
                                                    (selected)='selectEvent7($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()'
                                                    [(ngModel)]="nameFilterString7" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="remarks" *ngIf="show_remarks">Remarks</th>
                                    <!-- <th scope="col" sortable="fromDate" *ngIf="show_from_date">Shared UPSI From Date/th> -->
                                    <th scope="col" sortable="toDate" *ngIf="show_to_date">Date UPSI Made Public</th>
                                    <!-- <th scope="col" sortable="createdby" *ngIf="show_created_by">Username <i class="fa fa-filter" (click)="filterData8()"
                                        aria-hidden="true" title="Search UPSI"></i>
                                    <div class="filter-form" *ngIf="visible8">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="createdByNameFilter"
                                                    [searchKeyword]="keyword8" placeholder="Search ..."
                                                    (selected)='selectEvent8($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString8" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th> -->
                                    <!-- <th scope="col" sortable="createdon" *ngIf="show_created_on">Activity Date</th> -->
                                    <th scope="col" sortable="action" >Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row" data-content="UPSI List"*ngIf="openNoUpsi == true && complianceofficeraccess"><input type="checkbox" #label (click)="selectLabel($event, items)" [(ngModel)]="items.checked"></td>
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID No." *ngIf="show_upsi_id">UPSI-{{items.upsi_id }}</td>
                                    <td scope="row" data-content="Nature of UPSI" *ngIf="show_nature_of_upsi">{{items.nature_of_upsi}}</td> 
                                    <td scope="row" data-content="Project/Event &#xa; Name" *ngIf="show_project_name">{{items.project_name}}</td> 
                                    <td scope="row" data-content="Date of Sharing" *ngIf="show_date_of_sharing">{{items.date_of_sharing | date:'dd-MM-yyyy HH:mm:ss' }}</td>  
                                    <td scope="row" data-content="Sender Name" *ngIf="show_sender" >{{items.SenderDPName}} - {{items.sender}} - {{items.SenderDPPanno}}</td>
                                    <td scope="row" data-content="Recipient Name" *ngIf="show_receiver" >{{items.RecepientDPName}} - {{items.recipient}} - {{items.RecepientDPPanno}}</td>
                                    <td scope="row" data-content="Mode of Sharing" *ngIf="show_mode_of_sharing">{{items.mode_of_sharing}}</td>
                                    <td scope="row" data-content="Purpose of Sharing" *ngIf="show_purpose_of_sharing">{{items.purpose_of_sharing}}</td>
                                    <td scope="row" data-content="Remarks" *ngIf="show_remarks">{{items.remarks}}</td>
                                    <!-- <td scope="row" data-content="UPSI" *ngIf="show_from_date">{{items.from_date}}</td> -->
                                    <td scope="row" data-content="Date UPSI Made &#xa; Public" *ngIf="show_to_date">{{items.to_date}}</td>
                                    <!-- <td scope="row" *ngIf="show_created_by">{{items.created_by}}</td>
                                    <td scope="row" *ngIf="show_created_on">{{items.created_on}}</td> -->
                                    
                                    <td data-content="Actions">
                                        <button class="edit-icon" title="View" (click)="openModal(items.id)"><i class="fa fa-eye" title="View Log"></i></button>
                                        <a [routerLink]="['/upsi/upsi-edit', items.id]" *ngIf="is_access_write"><button class="edit-icon" title="Duplicate"><i class="fa fa-files-o"></i></button></a>
                                       <!-- <a [routerLink]="['/upsi/upsi-delete', items.value.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-trash"></i></button></a>
                                        <a [routerLink]="['/upsi/upsi-history', items.value.id]"><button class="edit-icon"><i class="fa fa-history"></i></button></a>  -->
                                    </td>  
                            </tr>
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>

                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.data?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <p><b>Note:</b> Limited information is show on screen considering screen size constraints. For complete details click on log or eye icon  (<i class="fa fa-eye" ></i>).</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf = "!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>
<!-- Container-fluid Ends -->

