import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { ModelControlService } from 'src/app/model-access-control/model-control.service';

// Menu
export interface Menu {
  [x: string]: any;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	role?: Array<string>;
	children?: Menu[];	
	access?:boolean;
	access_name?: string;
	userRoles?:string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;
	insider_access: any;

	accessData = sessionStorage.getItem('model_access_controller');
	user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
	
	accessModelController = JSON.parse(this.accessData);
	
	MENUITEMS: Menu[] = [
		
		{
			path: '/dashboard/', title: 'Dashboard', icon: 'home', type: 'link', role: ['All'], access: true, userRoles: "1"
		},
		{
			path: '/upsi/upsi-list', title: 'UPSIs', icon: 'airplay', type: 'link', role: ['All'], access: this.accessModelController.upsi_access, userRoles: this.user_roles.upsi_read			
		},
		{
			path: '/insider/insider-list', title: 'Insiders', icon: 'slack', type: 'link', role: ['All'], access: this.accessModelController.insider_access, userRoles: this.user_roles.insider_read
		},
		{
			path: '/dp/dp-list', title: 'DPs', icon: 'box', type: 'link', role: ['All'], access: this.accessModelController.dp_access, userRoles: this.user_roles.dpname_read
		},
		{
			path: '/customer/list-customer', title: 'Customers', icon: 'box', type: 'link', role: ['All'], access: this.accessModelController.customer_access, userRoles: this.user_roles.customer_read
		},
		
		{
			title: 'PIT Compliance', icon: 'folder-plus', type: 'sub', active: false,  children: [
			
				{
					path: '/pit-compliance/list-twc', title: 'Trading Window Closure',  type: 'link', role: ['All'],  access: this.accessModelController.twc_access, userRoles: this.user_roles.twc_read
				},
				{
					path: '/pit-compliance/list-pre-clearance', title: 'Pre Clearance',  type: 'link', role: ['All'],  access: this.accessModelController.preclearance_access, userRoles: this.user_roles.preclearance_read
				},
				{
					path: '/pit-compliance/benpos-list', title: 'BENPOS Report',  type: 'link', role: ['All'], access: this.accessModelController.benpos_access, userRoles: this.user_roles.benpos_read
				},
				{
					path: '/pit-compliance/list-trading-report', title: 'Form C & Form D Report',  type: 'link', role: ['All'], access: this.accessModelController.formcord_access, userRoles: "3"
				},
				
				
			]
		},
		{
			path: '/reports/report', title: 'Reports', icon: 'server', type: 'link', role: ['Admin'], access: this.accessModelController.reports_access, userRoles: "1"
		},
		{
			title: 'Setup', icon: 'folder-plus', type: 'sub', active: false, children: [
				
				{
					path: '/setup/project-list', title: 'Projects/Events',  type: 'link', role: ['All'], access: this.accessModelController.project_access, userRoles: this.user_roles.project_read
				},
				{
					path: '/setup/company-profile', title: 'Company Profile',  type: 'link', role: ['Admin'], access: this.accessModelController.company_access, userRoles: "1"
				},
				
				{
					path: '/setup/role-permission', title: 'Roles & Permissions', type: 'link', role: ['Admin'], access: this.accessModelController.roles_access, userRoles: "1"
				},
				{
					path: '/setup/activity-log', title: 'Change Logs', type: 'link', role: ['All'], access: this.accessModelController.activity_access, userRoles: "1"
				},
				{
					path: '/setup/list-mail-template', title: 'Mail Template', type: 'link', role: ['Admin'], access: this.accessModelController.mailtemplate_access, userRoles: "1"
				},
				{
					path: '/model-access-control/list', title: 'Model Access Controller', type: 'link', role: ['Allow'], access:true, userRoles: "1"
				},
				{
					path: '/setup/rules-list', title: 'Rules', type: 'link', role: ['Admin'], access:this.accessModelController.rules_access, userRoles: "1"
				},
				{
					path: '/setup/companys-list', title: "Company's Lists", type: 'link', role: ['Admin'], access:this.accessModelController.companys_lists_access, userRoles: "1"
				},
				{
					path: '/setup/mail-setting', title: "Email Settings", type: 'link', role: ['Admin'], access:this.accessModelController.mailsetting_access, userRoles: "1"
				},
				{
					path: '/setup/user-log', title: "User Log", type: 'link', role: ['Allow'], access:this.accessModelController.userlog_access, userRoles: "1"
				},
				{
					path: '/setup/system-info', title: "System Log", type: 'link', role: ['Admin', 'Allow'], access:this.accessModelController.systeminfo_access, userRoles: "1"
				},
				{
					path: '/setup/users-list', title: "Users List", type: 'link', role: ['Admin', 'Allow'], access:this.accessModelController.users_access, userRoles: "1"
				},
			]
		},
		{
			path: '', title: 'Help Manual', icon: 'command', type: 'link', role: ['All'], access: true, userRoles: "1"
		},
		
	];

	constructor(private modelService: ModelControlService) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		//this.getAccess();
	}
// getAccess(){
// 	//model Access
// 	this.modelService.moduleControlList(null).subscribe(
// 		res => {
		
// 		let resp_data = JSON.parse(res.data.module_controller);
		
// 		this.MENUITEMS = this.MENUITEMS.map(menuItemObj => {
// 			if(menuItemObj.access_name){
// 				menuItemObj.access = resp_data[menuItemObj.access_name];
// 			}
// 			if(menuItemObj.children?.length > 0 && menuItemObj.type === 'sub') {
// 				menuItemObj.children = menuItemObj.children.map(childObj => {
// 					if(childObj.access_name){
// 						childObj.access = resp_data[childObj.access_name];
// 					}
// 					return childObj;
// 				})
// 			}
// 			return menuItemObj;
// 		});
// 		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

// 		//  console.log("moduleControlList12", resp_data);
// 		//  console.log(this.MENUITEMS);
		
// 	  })
	
// }
	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
