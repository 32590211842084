<div class="modal-header">
    <h5 class="modal-title">Permission of Trading for Company</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatables">
                        <form [formGroup]="addRestrictedCompanyform" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-sm-12 col-xl-12">
                                   
                                        <div class="sellected-option">
                                            <label for="projectAccess" class="mb-0">Restricted Company Name</label>
                                            <div class="bottom-field" >
                                                <div class="form-group">                              
                                                    <angular2-multiselect [data]="restrict_company" [(ngModel)]="selected_restrict_company" 
                                                        [settings]="dropdownSettings" 
                                                        (onSelect)="onItemSelect($event)" 
                                                        (onDeSelect)="OnItemDeSelect($event)"
                                                        (onSelectAll)="onSelectAll($event)"
                                                        (onDeSelectAll)="onDeSelectAll($event)" formControlName="companyname">
                                                    </angular2-multiselect>
                                                    
                                                </div>
                                            </div>                
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <label for="nseScripCode">Permission of Trading</label>
                                            <div class="form-group">
                                                <select class="form-select" class="form-control form-select" id="permission"  formControlName="permission" >
                                                    <option value="" disabled selected>--Choose One --</option>                                               
                                                    <option value="Allowed">Allowed</option>
                                                    <option value="Restricted">Restricted</option>   
                                                </select> 
                                            </div>
                                        </div>
                                        <div class="submit-btn">
                                            <button class="btn btn-primary"  type="submit" [disabled]="isLoading" ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                            <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>                    
                                         </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
