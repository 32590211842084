import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { MasterService } from '../../master.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelControlService } from 'src/app/model-access-control/model-control.service';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { distinct, from, tap } from 'rxjs';

@Component({
  selector: 'app-model-access-level',
  templateUrl: './model-access-level.component.html',
  styleUrls: ['./model-access-level.component.scss']
})
export class ModelAccessLevelComponent implements OnInit {

  modelAccessControlForm: FormGroup;
  checked: any;
  isLoading = false;
  button = 'Update';
  submitted: boolean = false; 
  color = 'primary';
  moduleControlList: any;
  model_id: any;
  dpAccessDataList = [];
  selecteddpAccessDataItems=[];
  dropdownSettings1 = {};
  accessUserControl: string;
  username: any;
  customerAccess = false;
  bulkFolder: string;
  constructor(private fb: FormBuilder, private dpService:DpModuleService, private modelService: ModelControlService, private toastr: ToastrService, public router: Router) { 
    this.accessUserControl = sessionStorage.getItem('access_user');
    
  }

  ngOnInit(): void {

    this.modelService.moduleControlList(null).subscribe(
      res => {
       
      let moduleControlList = res['data'];
      if(moduleControlList.length != 0){
      
      this.model_id = {'id' : moduleControlList[0].id};
      
      if(this.model_id['id'] != ''){
        this.modelService.moduleControlEdit(this.model_id)
        .toPromise().then(result =>{ 
          let resp_data = JSON.parse(result.data[0].module_controller);

          let company_details = sessionStorage.getItem('companyDetails');	
         
         this.modelAccessControlForm.patchValue(resp_data);
         if(company_details == 'Intermediary'){
          this.customerAccess = true;        
         
        }else{
          this.customerAccess = false;
          
        }
        this.bulkFolder = 'Bulk Upload folder';
         })
      }
    }
    })
    //this.model_id = 1;
   
    
    this.modelAccessControlForm = this.fb.group({
     
      upsi_name: ['Log UPSI Transfer',  Validators.required],
      upsi_access: [false,  Validators.required],
      dp_access: [false,  Validators.required],    
      dp_name: ['DP List',  Validators.required],
      insider_access: [false,  Validators.required],    
      insider_name: ['Insider',  Validators.required],
      project_access: [false,  Validators.required],    
      project_name: ['Project',  Validators.required],
      company_access: [false,  Validators.required],    
      company_name: ['Company Profile',  Validators.required],
      roles_access: [false,  Validators.required],    
      roles_name: ['Roles and Permission',  Validators.required],
      activity_access: [false,  Validators.required],    
      activity_name: ['Activity Logs',  Validators.required],
      reports_access: [false,  Validators.required],    
      reports_name: ['Reports',  Validators.required],
      dplabel_access: [false,  Validators.required],    
      dplabel_name: ['Dp Label',  Validators.required],
      twc_access: [false,  Validators.required],    
      twc_name: ['Trading Window Closure',  Validators.required],
      preclearance_access: [false,  Validators.required],    
      preclearance_name: ['Pre Clearance',  Validators.required],
      benpos_access: [false,  Validators.required],    
      benpos_name: ['BENPOS Report',  Validators.required],
      formcord_access: [false,  Validators.required],    
      formcord_name: ['Form C and Form D Report',  Validators.required],
      mailtemplate_access: [false,  Validators.required],    
      mailtemplate_name: ['Mail Template',  Validators.required],
      rules_access: [false,  Validators.required],    
      rules: ['Rules',  Validators.required],
      companys_lists_access: [false,  Validators.required],    
      companys_lists: ["Company's Lists",  Validators.required],
      customer_access: [false,  Validators.required],    
      customer_lists: ["Customers",  Validators.required],
      upsiattachment_access: [false,  Validators.required],    
      upsiattachment_lists: ["UPSI Attachment",  Validators.required],
      otp_access: [false,  Validators.required],    
      otp: ["OTP",  Validators.required],
      bulkupload_access: [false,  Validators.required],    
      bulkupload: ["UPSI Bulk Upload",  Validators.required],
      tcndropdown_access: [false,  Validators.required],    
      tcndropdown: ["Preclearance Trading company Name dropdown",  Validators.required],
      tradecount_access: [false,  Validators.required],    
      tradecount: ["Preclearance Trading Day Count",  Validators.required],
      mailsetting: ["Mail Setting",  Validators.required],
      mailsetting_access: [false,  Validators.required],    
      hidecolumn: ["Preclearance Hide Column",  Validators.required],
      hidecolumn_access: [false,  Validators.required],    
      noupsi: ["Non UPSI Communication",  Validators.required],
      noupsi_access: [false,  Validators.required],    
      complemail: ["Compliance Office Email ID",  Validators.required],
      complemail_access: [false,  Validators.required],    
      userlog: ["User Log",  Validators.required],
      userlog_access: [false,  Validators.required],    
      systeminfo: ["System Info",  Validators.required],
      systeminfo_access: [false,  Validators.required],    
      bulkuploadfolder: ["Bulk Upload folder",  Validators.required],
      bulkuploadfolder_access: [false,  Validators.required],    
      twcattachment_access: [false,  Validators.required],    
      twcattachment_lists: ["TWC Attachment",  Validators.required],
      users_access: [false,  Validators.required],    
      users_lists: ["Users List",  Validators.required],
      
    });
    this.dropdownSettings1 = {
      singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          enableCheckAll: true,
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          allowSearchFilter: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 197,
          itemsShowLimit: 3,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false,
    
    };
    this.dpService.dpModuleList(null).subscribe(
      res => {
        if(res['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          let listDpModule = res['data'];
         let dpAccessList = listDpModule.map(({id,email}) =>({item_id: id,item_text:email}));
         this.dpAccessDataList = [];
          from(dpAccessList)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.dpAccessDataList.push(r));
        }
      });
  }
// Multi Select
onItemSelect1(item: any) {
  console.log(item);
}
onItemDeSelect1(item: any) {
  console.log(item);
}
onSelectAll1(items: any) {
  console.log(items);
}
onDeSelectAll1(items: any) {
  console.log(items);
}
  onSubmit(){
    this.submitted = true;
    this.isLoading = true;
    this.button = 'Processing';
    if(this.moduleControlList?.length != 0){
    this.modelAccessControlForm.value.id = this.model_id['id'];
    this.modelService.moduleControlUpdate(this.modelAccessControlForm.value).subscribe(data =>{
      this.isLoading = false;
      this.button = 'Update';
      window.location.reload();
      sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('sdd_user_permission');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('sdd_user_type');
        sessionStorage.removeItem('access_success');
        sessionStorage.removeItem('sdd_access_roles');
        sessionStorage.removeItem('isinNumber')
      sessionStorage.removeItem('model_access_controller');
      sessionStorage.removeItem('predaycount'); 
      this.router.navigate(['/auth/login']);
    })
    }else{
      this.modelService.moduleControlCreate(this.modelAccessControlForm.value).subscribe(data =>{
     
            this.isLoading = false;
            this.button = 'Update';
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('sdd_user_permission');
            sessionStorage.removeItem('sdd_user_type');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('sdd_user_type');
            sessionStorage.removeItem('access_success');
            sessionStorage.removeItem('sdd_access_roles');
            sessionStorage.removeItem('isinNumber')
            sessionStorage.removeItem('model_access_controller');
            sessionStorage.removeItem('predaycount'); 
            this.router.navigate(['/auth/login']);

          })
      }
    
  }

CheckboxChange(){
  this.checked ? 1 : 0;
}
cancelRoles(){
  this.router.navigate(['/dashboard']);
  // this.modelAccessControlForm.reset();
  this.submitted = false;
}


}
