<div class="modal-header">
    <h5 class="modal-title">View Company List</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                            <tbody *ngIf="viewcompanyList">
                                <tr>
                                    <td scope="row">Company Name</td>
                                    <td scope="row">{{viewcompanyList.company_name}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Permission of Trading</td>
                                    <td scope="row">{{viewcompanyList.tradingpermission}}</td>                                         
                                 </tr>                                     
                                 <!-- <tr>
                                    <td scope="row">Created On</td>
                                    <td scope="row">{{viewcompanyList.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                 </tr>                      -->
                                                    
                           
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>


 