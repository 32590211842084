<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD NEW CUSTOMER</h5>
                    <a routerLink="/customer/list-customer"><button class="btn btn-primary me-2 "><i
                                class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="customerCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">

                            <div class="container-fluid">
                                <div class="row">

                                    <div class="col-md-6 mb-3">
                                        <label for="nameOfInsider">Name<span class="manditary-filed">*</span></label>
                                        <input type="text" class="form-control" id="nameOfInsider" placeholder="Name"
                                            formControlName="customer_name" autocomplete="off" required
                                            [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.customer_name.touched) && customerCreateForm.controls.customer_name.errors }">

                                        <div *ngIf="(submitted || customerCreateForm.controls.customer_name.touched) && customerCreateForm.controls.customer_name.errors?.required"
                                            class="text text-danger">
                                            Name is required.
                                        </div>
                                        <div class="text text-danger" *ngIf="customerCreateForm.controls.customer_name.errors?.pattern">Special Charactors Not Allowed!</div>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email ID <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="email" placeholder="Email ID"
                                                formControlName="email" autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.email.touched) && customerCreateForm.controls.email.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.email.touched) && customerCreateForm.controls.email.errors?.required"
                                                class="text text-danger">
                                                Email ID is required.
                                            </div>
                                            <div class="text text-danger"
                                                *ngIf="customerCreateForm.controls.email.errors?.pattern">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-md-6 mb-3">
                                        <label for="mobileno">Designation <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="designation"
                                                placeholder="Designation" formControlName="designation"
                                                autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.designation.touched) && customerCreateForm.controls.designation.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.designation.touched) && customerCreateForm.controls.designation.errors?.required"
                                                class="text text-danger">
                                                Designation is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.designation.errors?.pattern">Special Charactors Not Allowed!</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="nationality">Nationality</label>
                                        <div class="form-group">
                                            <select class="form-select" class="form-control form-select"
                                                id="nationality" formControlName="nationality"
                                                (change)="insiderNationalityChanged()">
                                                <option value="" disabled selected>--Choose One --</option>
                                                <option value="American">American</option>
                                                <option value="Australian">Australian</option>
                                                <option value="Argentine">Argentine</option>
                                                <!-- <option value="Argentinean">Argentinean</option> -->
                                                <option value="Brazilian">Brazilian</option>
                                                <option value="Colombian">Colombian</option>
                                                <option value="Chinese">Chinese</option>
                                                <option value="French">French</option>
                                                <option value="Indian">Indian</option>
                                                <option value="British">British</option>
                                                <!-- <option value="Great Britain">Great Britain</option> -->
                                                <option value="Singaporean">Singaporean</option>
                                                <!-- <option value="Singapore">Singapore</option> -->
                                                <option value="Emirati">Emirati</option>
                                                <!-- <option value="UAE">UAE</option> -->
                                                <option value="Others">Others</option>
                                            </select>
                                            <div *ngIf="(submitted || customerCreateForm.controls.nationality.touched) && customerCreateForm.controls.nationality.errors?.required"
                                                class="text text-danger">
                                                Nationality is required.
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="othersInsiderNationSelect">
                                            <div class="col-md-12 mb-3">
                                                <label for="nationality">Others Nationality</label>
                                                <input type="text" class="form-control" id="nationality"
                                                    placeholder="Others Nationality"
                                                    formControlName="others_nationality" autocomplete="off">
                                                    <div class="text text-danger" *ngIf="customerCreateForm.controls.others_nationality.errors?.pattern">Special Charactors Not Allowed!</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="panno">ID Proof Type <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <select class="form-select" class="form-control form-select" required
                                                id="documenttype" formControlName="documenttype"
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.documenttype.touched) && customerCreateForm.controls.documenttype.errors }">
                                                <option value="" disabled selected>-- Choose One --</option>
                                                <option value="PAN">PAN</option>
                                                <option value="" disabled style="color: grey;">-- PAN Not Available --
                                                </option>
                                                <option value="Aadhar card">Aadhar card</option>
                                                <option value="Driving License">Driving License</option>
                                                <option value="Passport">Passport</option>
                                                <option value="Voter ID">Voter ID</option>
                                                <!-- <option value="Others">Others</option> -->
                                            </select>
                                            <div *ngIf="(submitted || customerCreateForm.controls.documenttype.touched) && customerCreateForm.controls.documenttype.errors?.required"
                                                class="text text-danger">
                                                ID Proof Type is required.
                                            </div>

                                        </div>
                                        <div class="form-group">
                                            <label for="identiferNumber">ID Proof No.<span
                                                    class="manditary-filed">*</span></label>
                                            <input type="text" class="form-control" id="panno" placeholder="ID Proof No"
                                                autocomplete="off" formControlName="panno" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.panno.touched) && customerCreateForm.controls.panno.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.panno.touched) && customerCreateForm.controls.panno.errors?.required"
                                                class="text text-danger">
                                                ID Proof No is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="mobileno">Contact Number <span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mobileno"
                                                placeholder="Contact Number" formControlName="mobileno"
                                                autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.mobileno.touched) && customerCreateForm.controls.mobileno.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.mobileno.touched) && customerCreateForm.controls.mobileno.errors?.required"
                                                class="text text-danger">
                                                Contact Number is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.mobileno.errors?.pattern">Enter the Valid Contact Number.</div>
                                       </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="address">Address <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <textarea class="form-control" id="address" rows="3"
                                                formControlName="address" placeholder="Address" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.address.touched) && customerCreateForm.controls.address.errors }"></textarea>
                                            <div *ngIf="(submitted || customerCreateForm.controls.address.touched) && customerCreateForm.controls.address.errors?.required"
                                                class="text text-danger">
                                                Address is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.address.errors?.pattern">Special Charactors Not Allowed!</div>

                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="remarks">Remarks</label>
                                        <div class="form-group">
                                            <textarea class="form-control" id="remarks" rows="3"
                                                formControlName="remarks" placeholder="Remarks"></textarea>
                                                <div class="text text-danger" *ngIf="customerCreateForm.controls.remarks.errors?.pattern">Special Charactors Not Allowed!</div>

                                        </div>
                                    </div>


                                </div>
                                <p class="tithead"><b>Company Details:</b></p>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="nameOfCompany">Company Name<span
                                                class="manditary-filed">*</span></label>
                                        <input type="text" class="form-control" id="company_name"
                                            placeholder="Company Name" formControlName="company_name" autocomplete="off"
                                            required
                                            [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_name.touched) && customerCreateForm.controls.company_name.errors }">
                                        <div *ngIf="(submitted || customerCreateForm.controls.company_name.touched) && customerCreateForm.controls.company_name.errors?.required"
                                            class="text text-danger">
                                            Company Name is required.
                                        </div>
                                        <div class="text text-danger" *ngIf="customerCreateForm.controls.company_name.errors?.pattern">Special Charactors Not Allowed!</div>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Company Email ID <span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="company_email"
                                                placeholder="Company Email ID" formControlName="company_email"
                                                autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_email.touched) && customerCreateForm.controls.company_email.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.company_email.touched) && customerCreateForm.controls.company_email.errors?.required"
                                                class="text text-danger">
                                                Company Email ID is required.
                                            </div>
                                            <div class="text text-danger"*ngIf="customerCreateForm.controls.company_email.errors?.pattern">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="nameOfCompany">Company ISIN Number</label>
                                        <input type="text" class="form-control" id="isinnumber"
                                            placeholder="ISIN Number" formControlName="isinnumber" autocomplete="off">
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.isinnumber.errors?.pattern">Special Charactors Not Allowed!</div>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="identiferNumber">Company CIN Number<span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="company_cin"
                                                placeholder="Company CIN Number" autocomplete="off"
                                                formControlName="company_cin" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_cin.touched) && customerCreateForm.controls.company_cin.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.company_cin.touched) && customerCreateForm.controls.company_cin.errors?.required"
                                                class="text text-danger">
                                                Company CIN Number is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.company_cin.errors?.pattern">Special Charactors Not Allowed!</div>

                                        </div>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="identiferNumber">Company PAN No.<span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="panno"
                                                placeholder="Company PAN No" autocomplete="off"
                                                formControlName="company_pan" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_pan.touched) && customerCreateForm.controls.company_pan.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.company_pan.touched) && customerCreateForm.controls.company_pan.errors?.required"
                                                class="text text-danger">
                                                Company PAN No is required.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="mobileno">Company Contact Number <span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mobileno"
                                                placeholder="Company Contact Number"
                                                formControlName="company_contact_no" autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_contact_no.touched) && customerCreateForm.controls.company_contact_no.errors }">
                                            <div *ngIf="(submitted || customerCreateForm.controls.company_contact_no.touched) && customerCreateForm.controls.company_contact_no.errors?.required"
                                                class="text text-danger">
                                                Company Contact Number is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.company_contact_no.errors?.pattern">Enter the Valid Contact Number.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="address">Company Address <span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <textarea class="form-control" id="address" rows="3"
                                                formControlName="company_address" placeholder="Company Address" required
                                                [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_address.touched) && customerCreateForm.controls.company_address.errors }"></textarea>
                                            <div *ngIf="(submitted || customerCreateForm.controls.company_address.touched) && customerCreateForm.controls.company_address.errors?.required"
                                                class="text text-danger">
                                                Company Address is required.
                                            </div>
                                            <div class="text text-danger" *ngIf="customerCreateForm.controls.company_address.errors?.pattern">Special Charactors Not Allowed!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>
                                    {{button}}</button>
                                <button class="btn btn-warning" type="btn" (click)="cancelInsider()">Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->



<!--Backup -->
<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD CUSTOMER</h5>
                    <a routerLink="/customer/list-customer"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="customerCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                                                               
                                    <div class="container-fluid">
                                            <div class="row">
                                                
                                                <div class="col-md-6 mb-3">
                                                    <label for="nameOfInsider">Name<span class="manditary-filed">*</span></label>
                                                    <input type="text" class="form-control"  id="nameOfInsider" placeholder="Name" formControlName="customer_name" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.customer_name.touched) && customerCreateForm.controls.customer_name.errors }"> 
                                                       
                                                        <div *ngIf="(submitted || customerCreateForm.controls.customer_name.touched) && customerCreateForm.controls.customer_name.errors?.required"
                                                        class="text text-danger">
                                                        Name is required.
                                                    </div>
                                                    
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="email">Email ID <span class="manditary-filed">*</span></label>
                                                    <div class="form-group">
                                                    <input type="text" class="form-control" id="email" placeholder="Email ID" formControlName="email" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.email.touched) && customerCreateForm.controls.email.errors }">
                                                        <div *ngIf="(submitted || customerCreateForm.controls.email.touched) && customerCreateForm.controls.email.errors?.required"
                                                        class="text text-danger">
                                                        Email ID is required.
                                                        </div>    
                                                        </div>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="nameOfCompany">Company Name<span class="manditary-filed">*</span></label>
                                                    <input type="text" class="form-control"  id="company_name" placeholder="Company Name" formControlName="company_name" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.company_name.touched) && customerCreateForm.controls.company_name.errors }"> 
                                                        <div *ngIf="(submitted || customerCreateForm.controls.company_name.touched) && customerCreateForm.controls.company_name.errors?.required"
                                                        class="text text-danger">
                                                        Company Name is required.
                                                    </div>
                                                    
                                                </div>

                                                <div class="col-md-6 mb-3">
                                                    <label for="nameOfCompany">ISIN Number<span class="manditary-filed">*</span></label>
                                                    <input type="text" class="form-control"  id="isinnumber" placeholder="ISIN Number" formControlName="isinnumber" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.isinnumber.touched) && customerCreateForm.controls.isinnumber.errors }"> 
                                                        <div *ngIf="(submitted || customerCreateForm.controls.isinnumber.touched) && customerCreateForm.controls.isinnumber.errors?.required"
                                                        class="text text-danger">
                                                        ISIN Number is required.
                                                    </div>
                                                    
                                                </div>                                                                                              
                                                
                                                <div class="col-md-6 mb-3">
                                                    <label for="mobileno">Designation <span class="manditary-filed">*</span></label>
                                                    <div class="form-group">
                                                    <input type="text" class="form-control" id="designation" placeholder="Designation" formControlName="designation" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.designation.touched) && customerCreateForm.controls.designation.errors }">
                                                        <div *ngIf="(submitted || customerCreateForm.controls.designation.touched) && customerCreateForm.controls.designation.errors?.required"
                                                        class="text text-danger">
                                                        Designation is required.
                                                        </div>    
                                                </div>
                                                    </div>
                                                
                                                    <div class="col-md-6 mb-3">
                                                        <label for="nationality">Nationality</label>
                                                        <div class="form-group">
                                                            <select class="form-select" class="form-control form-select" id="nationality" formControlName="nationality" (change)="insiderNationalityChanged()" >
                                                                <option value="" disabled selected>--Choose One --</option>
                                                                <option value="American">American</option>
                                                                <option value="Australian">Australian</option>
                                                                <option value="Argentinean">Argentinean</option>
                                                                <option value="Brazilian">Brazilian</option>
                                                                <option value="Colombian">Colombian</option>
                                                                <option value="Chinese">Chinese</option>
                                                                <option value="French">French</option>
                                                                <option value="Indian">Indian</option>
                                                                <option value="Great Britain">Great Britain</option>
                                                                <option value="Singapore">Singapore</option>
                                                                <option value="UAE">UAE</option>
                                                                <option value="Others">Others</option> 
                                                            </select>
                                                            <div *ngIf="(submitted || customerCreateForm.controls.nationality.touched) && customerCreateForm.controls.nationality.errors?.required"
                                                        class="text text-danger">
                                                        Nationality is required.
                                                        </div> 
                                                        </div>
                                                        <div class="row" *ngIf="othersInsiderNationSelect">                                                    
                                                            <div class="col-md-12 mb-3">
                                                                <label for="nationality">Others Nationality</label>
                                                                <input type="text" class="form-control" id="nationality" placeholder="Others Nationality" formControlName="others_nationality" autocomplete="off" >                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="panno">ID Proof Type <span class="manditary-filed">*</span></label>
                                                    <div class="form-group">
                                                        <select class="form-select" class="form-control form-select"
                                                        required id="documenttype"
                                                        formControlName="documenttype"
                                                        [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.documenttype.touched) && customerCreateForm.controls.documenttype.errors }">
                                                        <option value="" disabled selected>-- Choose One --</option>
                                                        <option value="PAN">PAN</option>
                                                        <option value="" disabled style="color: grey;">-- PAN Not Available --</option>
                                                        <option value="Aadhar card">Aadhar card</option>
                                                        <option value="Driving License">Driving License</option>
                                                        <option value="Passport">Passport</option>
                                                        <option value="Voter ID">Voter ID</option>
                                                        <option value="Others">Others</option>
                                                    </select>  
                                                        <div *ngIf="(submitted || customerCreateForm.controls.documenttype.touched) && customerCreateForm.controls.documenttype.errors?.required"
                                                        class="text text-danger">
                                                        ID Proof Type is required.
                                                        </div> 
                                                            
                                                        </div>  
                                                        <div class="form-group">
                                                            <label for="identiferNumber">ID Proof No.<span class="manditary-filed">*</span></label>
                                                            <input type="text" class="form-control" id="panno" placeholder="ID Proof No" autocomplete="off"
                                                                formControlName="panno" required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.panno.touched) && customerCreateForm.controls.panno.errors }"> 
                                                                <div *ngIf="(submitted || customerCreateForm.controls.panno.touched) && customerCreateForm.controls.panno.errors?.required"
                                                                class="text text-danger">
                                                                ID Proof No is required.
                                                            </div>
                                                        </div>                   
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="mobileno">Contact Number <span class="manditary-filed">*</span></label>
                                                    <div class="form-group">
                                                    <input type="text" class="form-control" id="mobileno" placeholder="Contact Number" formControlName="mobileno" autocomplete="off"
                                                        required [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.mobileno.touched) && customerCreateForm.controls.mobileno.errors }">
                                                        <div *ngIf="(submitted || customerCreateForm.controls.mobileno.touched) && customerCreateForm.controls.mobileno.errors?.required"
                                                        class="text text-danger">
                                                        Contact Number is required.
                                                        </div>    
                                                </div>
                                                    </div>
                                                    
                                                <div class="col-md-6 mb-3">
                                                    <label for="address">Address <span class="manditary-filed">*</span></label>
                                                    <div class="form-group">
                                                        <textarea class="form-control" id="address" rows="3" formControlName="address" placeholder="Address" required  [ngClass]="{ 'is-invalid': (submitted || customerCreateForm.controls.address.touched) && customerCreateForm.controls.address.errors }"></textarea>
                                                        <div *ngIf="(submitted || customerCreateForm.controls.address.touched) && customerCreateForm.controls.address.errors?.required"
                                                        class="text text-danger">
                                                        Address is required.
                                                        </div>
                                                    </div>                          
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <label for="remarks">Remarks</label>
                                                    <div class="form-group">
                                                        <textarea class="form-control" id="remarks" rows="3" formControlName="remarks" placeholder="Remarks" ></textarea>
                                                        
                                                    </div>                          
                                                </div>                                           
                                                
                                                
                                            </div>
                                    </div>
                                        
                                                     
                            
                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="btn" (click)="cancelInsider()">Cancel</button>                    
                             </div>
                        </div>
                    </form>

                </div>
            </div>                       
        </div>
    </div>
</div> -->