<div class="modal-header">
    <h5 class="modal-title">Do you wish to delete this Project/Event?</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <form [formGroup]="projectReasonDeleteForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-sm-12 col-xl-12">
                                   
                                        <div class="col-md-12 mb-3">
                                            <label for="nameOfInsider">Reason to Delete Project/Event</label>
                                            <textarea class="form-control" rows="5" id="reasonfordelete" placeholder="Reason to Delete Project/Event" formControlName="reasonfordelete" autocomplete="off" 
                                            [ngClass]="{ 'is-invalid': (submitted || projectReasonDeleteForm.controls.reasonfordelete.touched) && projectReasonDeleteForm.controls.reasonfordelete.errors }"></textarea>
                                            <!-- <input type="text" class="form-control"  id="reasonfordelete" placeholder="Reason to Delete Project" formControlName="reasonfordelete" autocomplete="off" 
                                            [ngClass]="{ 'is-invalid': (submitted || projectReasonDeleteForm.controls.reasonfordelete.touched) && projectReasonDeleteForm.controls.reasonfordelete.errors }">  -->
                                            <div *ngIf="(submitted || projectReasonDeleteForm.controls.reasonfordelete.touched) && projectReasonDeleteForm.controls.reasonfordelete.errors?.required"
                                            class="text text-danger">
                                            Please mention the reason to Delete Project/Event
                                            </div>  
                                            <div class="text text-danger" *ngIf="projectReasonDeleteForm.controls.reasonfordelete.errors?.pattern">Special Charactors Not allowed!</div>                                    
                                        </div>
                                        <div class="submit-btn">
                                            <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                            <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>                    
                                         </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card-footer text-muted">
                    <!-- <b>Note:</b> UPSI entered cannot be altered. Therefore, any changes to the Project will NOT affect the UPSI Log already entered in the system. -->
                    <b>Note:</b> UPSI entered cannot be altered. Therefore, any changes to the Project/Event WILL NOT AFFECT the UPSI Log already entered in the system.
                  </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
