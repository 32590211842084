<div class="modal-header">
  <h5 class="modal-title">Project Access for Role</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
      <div class="row">
          <div class="col-sm-12">
              <div class="card">
                  <div class="card-body custom-datatable noscroll">
                    <form [formGroup]="projectAccessForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                          <div class="sellected-option">
                            <label for="projectAccess" class="mb-0">Project Access</label>
                            <div class="bottom-field" >
                                <div class="form-group">                              
                                    <angular2-multiselect [data]="projectNameFilter" [(ngModel)]="selectedItems" 
                                        [settings]="dropdownSettings" 
                                        (onSelect)="onItemSelect($event)" 
                                        (onDeSelect)="OnItemDeSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                        (onDeSelectAll)="onDeSelectAll($event)" formControlName="projectname">
                                    </angular2-multiselect>
                                    
                                </div>
                            </div>

                        </div>
                        <div class="submit-btn">
                          <button class="btn btn-primary" type="submit" [disabled]="isLoading" ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                          <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>                    
                       </div>
                      </form>

                  </div>
              </div>
          </div>
      </div>
   </div>
</div>
<!-- <div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
