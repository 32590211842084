import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { customerRoutingModule } from './customer-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { ViewCustomerComponent } from './view-customer/view-customer.component';
import { ListCustomerComponent } from './list-customer/list-customer.component';
import { DeleteCustomerComponent } from './delete-customer/delete-customer.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BulkActionPopupComponent } from './bulk-action-popup/bulk-action-popup.component';
import { ErrorCustomerComponentComponent } from './error-customer-component/error-customer-component.component';
import { RevertCustomerComponent } from './revert-customer/revert-customer.component';
import { DeletedCustomerModuleComponent } from './deleted-customer-module/deleted-customer-module.component';

@NgModule({
  declarations: [
    AddCustomerComponent,
    EditCustomerComponent,
    ViewCustomerComponent,
    ListCustomerComponent,
    DeleteCustomerComponent,
    BulkActionPopupComponent,
    ErrorCustomerComponentComponent,
    
    RevertCustomerComponent,
    DeletedCustomerModuleComponent
  ],
  imports: [
    CommonModule,
    customerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AutocompleteLibModule,
    NgMultiSelectDropDownModule
  ]
})
export class CustomerModule { }
