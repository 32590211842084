import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpsiService } from '../upsi.service';
import { PopupModelUpsiComponent } from '../popup-model-upsi/popup-model-upsi.component';

export interface upsi{
  id: number;
  action_flag: string;
  sender: string;
  nature_of_upsi: string;
  project_name: string;
}

@Component({
  selector: 'app-history-upsi',
  templateUrl: './history-upsi.component.html',
  styleUrls: ['./history-upsi.component.scss']
})
export class HistoryUpsiComponent implements OnInit {
  public selected = [];
  page = 1;
  pageSize = 10;
  upsiId: any;
  historyUpsiList: any;
  accessSuccess: string;
  filterString = "";
  filtered;
  listUpsiTransfer: Object;
  getUpsiId: any;
  getId: any;
  idGet: any;
  constructor(private upsiService: UpsiService, public router: Router,private activateRouter: ActivatedRoute, private modalService: NgbModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

  
   
      
    this.activateRouter.params.subscribe(data => {
      this.upsiId = {'id': data['id']}
      
    })
    // if(this.getUpsiId == this.upsiId){
    //   this.getId = this.getUpsiId;
    // }
    this.upsiService.upsiTransferList(null).subscribe(data => {
      const listUpsiTransfer = data;
     
      
    });

    if(this.upsiId['id']){
      this.upsiService.historyUpsiTransfer(this.upsiId).subscribe(data =>{
        this.historyUpsiList = data;
        this.onFilterChange();
      })
    }

  }

  onFilterChange() {
    this.filtered = this.historyUpsiList?.data.filter(res =>{    
     return res.action_flag.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.sender.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.nature_of_upsi.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.project_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }
  openModal(id:any) {

    if (id) {
      let upsiId = {'id': id};
      this.upsiService.viewUpsiTransfer(upsiId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(PopupModelUpsiComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewUpsiList = data[0];
          modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
      })

      }  
    
  }
}
