import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UpsiService } from '../upsi.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attachmentpopup',
  templateUrl: './attachmentpopup.component.html',
  styleUrls: ['./attachmentpopup.component.scss']
})
export class AttachmentpopupComponent implements OnInit {
  @Input() viewUpsiList;
  @Input() viewUpsiID;
  totalFileItem: any;
  confidencialUrl: any;
  isLoading = false;
  button = 'Save';
  selectedFile = [];
  selectedFileName: any[] = [];
  upsiAttachmentForm: FormGroup;
  submitted: boolean = false;
  senderList: any;
  removeList = [];
  removedFileName: any[];
  constructor(
    public activeModal: NgbActiveModal, private fb: FormBuilder, private upsiService: UpsiService, private toastr: ToastrService
  ) { 
    this.confidencialUrl = environment.url
  }

  ngOnInit() {
    if(this.viewUpsiList.confidentiality_agreement_attachment != ''){
    let selectedFileName = this.viewUpsiList.confidentiality_agreement_attachment; 
    this.selectedFileName = selectedFileName.split(',');
    }
    this.senderList = this.viewUpsiList.sender; 
    this.upsiAttachmentForm = this.fb.group({     
      agreement_attachment: new FormControl()
    });
    // this.onFileChanged(event);

  }
  onFileChanged(event: any) {
   if (event?.target.files && event?.target.files[0]) {
      var filesAmount = event?.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        //this.selectedFileName.push(event?.target.files[i].name)
        this.selectedFileName?.push(event?.target.files[i].name);
        //this.fileList.push(event?.target.files[i].name);
        
        reader.onload = (event: any) => {
          this.selectedFile.push(event.target.result);
        };
    
        reader.readAsDataURL(event.target.files[i]);
      }
     
    }
    
     
    }
 
    removeSelectedFile(index, item) {
      //this.removeList = [];
      this.removeList.push(item);
    this.selectedFile.splice(index, 1);
      // delete file from FileList
       this.selectedFileName.splice(index, 1);

      // this.addedList = this.selectedFileName
   //this.removeListArr(item);
    }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onSubmit(){

    //  let formData = new FormData();
    //  formData.append('upload' , this.selectedFile);
     this.upsiAttachmentForm.value.agreement_attachment = this.selectedFile;
     this.upsiAttachmentForm.value.attachmentfilename = this.selectedFileName;
     this.upsiAttachmentForm.value.id = this.viewUpsiID;
     this.upsiAttachmentForm.value.sender = this.senderList;
     this.upsiAttachmentForm.value.attachmentRemoveList = this.removeList;
      const emptyFileds = Object.keys(this.upsiAttachmentForm.controls).filter(key =>this.upsiAttachmentForm.controls[key].errors !== null)
     
      this.submitted = true;
        this.isLoading = true;
      this.button = 'Processing';
      this.upsiService.upsiTransferAttachment(this.upsiAttachmentForm.value).subscribe(data =>{ 
               
        this.toastr.success("UPSIs Attachment Successfully Added");
        this.isLoading = false;
        this.button = 'Save';
        
        window.location.reload();
      })
      
    
   }
}
