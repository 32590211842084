import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PitComplianceService } from '../pit-compliance.service';
import { barChartSingle, pieChart, multiData, single } from '../../shared/data/chart/ngx-chart';
import * as graphoptions from '../../shared/data/chart/config';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

interface IButton {
  ripple?: string;
  label?: string;
  disabled?: boolean;
  togglable?: boolean;
  selected?: boolean;
  color?: string;
  bgcolor?: string;
  icon?: string;
}

class Button {
  private ripple: string;
  private label: string;
  private disabled: boolean;
  private togglable: boolean;
  private selected: boolean;
  private color: string;
  private bgcolor: string;
  private icon: string;

  constructor(obj?: IButton) {
      this.ripple = obj.ripple || "gray";
      this.label = obj.label;
      this.selected = obj.selected || false;
      this.togglable = obj.togglable;
      this.disabled = obj.disabled || false;
      this.color = obj.color;
      this.bgcolor = obj.bgcolor;
      this.icon = obj.icon;
  }
}
@Component({
  selector: 'app-benpos-dashboard',
  templateUrl: './benpos-dashboard.component.html',
  styleUrls: ['./benpos-dashboard.component.scss']
})
export class BenposDashboardComponent implements OnInit {
  model = 'Both';
  baseUrl = environment.apiUrl;
  benpossComparison: any;
  benposspreDate: any;
  benposscurDate: any;
  page = 1;
  pageSize = 10;
  benposId: any;
  public active1 = 1;
  benf_item: any;
  public barChartsingle = barChartSingle;
   tradingSharedList = [
    { id: 1, name: 'DP'},
    { id: 2, name: 'Insider'}
  ];
  tradingSharedItemList = []
  tradingData: any;
  private multi: boolean = true;
  private alignment = "vertical";
  private fontOptions: Button[];
  tradListForm: FormGroup;
  benposTradeList = [];
  totalpreTotShareCount: any;
  totaltot_sharesCount: any;
  totalshareDiffCount: any;
  benposDpTradeList = [];
  benposInsiderTradeList = [];
  benposDpTradeCount: number;
  benposInsiderTradeCount: number;
  totalNoofTrades: any;
  filterList: any[];
  volumeOfDpTradeCount: number;
  volumeOfInsiderTradeCount: number;
  filterString = "";
  filtered: any;
  constructor(private pitService: PitComplianceService, private activateRouter: ActivatedRoute, private fb: FormBuilder,) { 
    Object.assign(this, { multiData, barChartSingle, pieChart, single })
  }
  getSelection(item) {
    return this.tradingSharedItemList.findIndex(s => s.id === item.id) !== -1;
    
  }

  changeHandler(item: any, event: KeyboardEvent) {
   
    const id = item.id;
   
    const index = this.tradingSharedItemList.findIndex(u => u.id === id);
    if (index === -1) {
      
      this.tradingSharedItemList = [...this.tradingSharedItemList, item];
     
    } else {
     
      this.tradingSharedItemList = this.tradingSharedItemList.filter(user => user.id !== item.id)
     
    }
    //console.log("filterList", this.tradingSharedItemList)
  //  if(this.tradingSharedItemList.length >0){
  //   this.filterList = this.tradingSharedItemList.map(item => item.name)
  
  //  }else{
  //   this.filterList = [];
  //  }
   
    this.onChangeEvent();
    
    
  }

  ngOnInit(): void {
    this.benposTradeList =[];
    this.onChangeEvent()
   
    this.tradListForm = this.fb.group({
      checkList: new FormControl('')
    });
    
    
  }


  onChangeEvent(){
   
   this.activateRouter.params.subscribe(data => {   
      this.benposId = data['id']      
      this.benf_item = {'benpos_id': this.benposId}      
  
    })
    this.benf_item['tradeItemList'] = this.tradingSharedItemList;
    
    if (this.benposId !== '') {
      
    this.pitService.comparisonbenpass(this.benf_item).subscribe(
      res => {
        this.benposDpTradeList = [];
        this.benposInsiderTradeList = [];
        this.volumeOfDpTradeCount = 0;
        this.benpossComparison = res['data'];
        //console.log("789", this.benpossComparison)
        this.filtered = res['data'];
        if(this.benpossComparison != ''){
          this.benposscurDate = res['data'][0].benfUpdateDate;
          this.benposspreDate = res['data'][0]?.preBenfDate;
        }
        this.totalNoofTrades = this.benpossComparison.length;
       
        //Dp and Insider Count
        this.benposDpTradeCount = 0;
        this.benposInsiderTradeCount = 0;
        this.benpossComparison?.map(item =>{         
        
          if(item.type == "DP"){
            let benposDpTradeList = item;          
            this.benposDpTradeList.push(benposDpTradeList);
           
            this.benposDpTradeCount= this.benposDpTradeList?.length;
           // console.log("Dp", this.benposDpTradeList.length)
            
          }else{
            
            let benposListOfInsiderTrade = item;
            this.benposInsiderTradeList?.push(benposListOfInsiderTrade);
            
            this.benposInsiderTradeCount= this.benposInsiderTradeList?.length;
            //console.log("Insider", this.benposInsiderTradeList.length)
          }
        
            // console.log("ss789",this.volumeOfDpTradeCount)

          // this.totalpreTotShareCount = item.preTotShare.length;          
          // this.totaltot_sharesCount = item.tot_shares.length;
          // this.totalshareDiffCount = item.share_diff.length;
       
         

        })
        this.volumeOfDpTradeCount = 0
        this.volumeOfInsiderTradeCount = 0;
        //DpShared Count
        let volumeOfDpTrade = this.benposDpTradeList.map(item => item.share_diff);
        if(typeof volumeOfDpTrade != "undefined"){
        for(let i = 0; i <= volumeOfDpTrade.length; i++){
          if(typeof volumeOfDpTrade[i] != "undefined" ){
            this.volumeOfDpTradeCount = this.volumeOfDpTradeCount + Number(volumeOfDpTrade[i]);
          }

        }
    
      }
        //Insider Shared Count
        
          let volumeOfInsiderTrade = this.benposInsiderTradeList?.map(item => item.share_diff);      
          if(typeof volumeOfInsiderTrade != "undefined"){
          for(let i = 0; i <= volumeOfInsiderTrade.length; i++){
            if(typeof volumeOfInsiderTrade[i] != "undefined" ){
              this.volumeOfInsiderTradeCount = this.volumeOfInsiderTradeCount + Number(volumeOfInsiderTrade[i]);
            }
  
          }
          }
       
      
       
      });
      
    }
  }

  //filter
  onFilterChange() {
    
    this.filtered = this.benpossComparison?.filter(res =>{    
     return res.dpName.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.pan.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
    }) 
   
  }


//excel Report
onFilter() {
 
   let queryString = '';
  let url = this.baseUrl + 'benposexcel?';
  let tokenStr = sessionStorage.getItem('access_token');
  if(tokenStr){
    tokenStr = tokenStr.replace('Bearer ', '');
  }
  if(this.tradingSharedItemList.length >0){
    this.filterList = this.tradingSharedItemList.map(item => item.name)
  
   }else{
    this.filterList = [];
   }
 
  if(this.benf_item.benpos_id || this.filterList )  {

    queryString = 'benpos_date=' + this.benf_item.benpos_id + '&benf_item=' + this.filterList;
    queryString = queryString + '&authToken='+btoa(tokenStr);
    
  } else {
    queryString = queryString + 'authToken='+btoa(tokenStr);
  }
  var element = document.createElement('a');
  element.setAttribute('href', url + queryString);
  element.setAttribute('download', 'sample.xls');

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

   
}

  // Bar-chart options
  public barChartShowYAxis = graphoptions.barChartShowYAxis;
  public barChartShowXAxis = graphoptions.barChartShowXAxis;
  public barChartGradient = graphoptions.barChartGradient;
  public barChartShowLegend = graphoptions.barChartShowLegend;
  public barChartShowXAxisLabel = graphoptions.barChartShowXAxisLabel;
  //public barChartXAxisLabel = graphoptions.barChartXAxisLabel;
  public barChartShowYAxisLabel = graphoptions.barChartShowYAxisLabel;
  //public barChartYAxisLabel = graphoptions.barChartYAxisLabel;
  public barChartColorScheme = graphoptions.barChartColorScheme;
  public barChartshowGridLines = graphoptions.barChartshowGridLines;

public onSelect(e) {  }
}
