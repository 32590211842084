<div class="modal-header">
    <h5 class="modal-title">VIEW UPSI TRANSFER LOG</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewUpsiList">
                                    <tr>
                                        <td scope="row">Project Name</td>
                                        <td scope="row">{{viewUpsiList.project_name}}</td>                                         
                                     </tr> 
                                     <!-- <tr>
                                        <td scope="row">Name of Sender</td>
                                        <td scope="row">{{viewUpsiList.sender}} - {{viewUpsiList.SenderDPPanno}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Name of Receiver</td>
                                        <td scope="row">{{viewUpsiList.recipient}} - {{viewUpsiList.RecepientDPPanno}}</td>                                         
                                     </tr>  -->
                                     <tr *ngIf = "viewUpsiList.SenderDPPanno == null">
                                       <td scope="row">Sender Name</td>
                                       <td scope="row">{{viewUpsiList.SenderDPName}} - {{viewUpsiList.sender}} - {{viewUpsiList.SenderDPPanno1}}</td>                                         
                                    </tr> 
                                     <tr *ngIf = "viewUpsiList.SenderDPPanno !== null">                                  
                                        <td scope="row">Sender Name</td>
                                        <td scope="row">{{viewUpsiList.SenderDPName}} - {{viewUpsiList.sender}} - {{viewUpsiList.SenderDPPanno}}</td>                                         
                                     </tr> 
                                     <tr *ngIf = "viewUpsiList.RecepientDPPanno == null">
                                        <td scope="row">Recipient Name</td>
                                        <td scope="row">{{viewUpsiList.RecepientDPName}} - {{viewUpsiList.recipient}} - {{viewUpsiList.RecepientDPPanno1}}</td>                                         
                                     </tr>
                                     <tr *ngIf = "viewUpsiList.RecepientDPPanno !== null">
                                       <td scope="row">Recipient Name</td>
                                       <td scope="row">{{viewUpsiList.RecepientDPName}} - {{viewUpsiList.recipient}} - {{viewUpsiList.RecepientDPPanno}}</td>                                         
                                    </tr>
                                     <tr>
                                        <td scope="row">UPSI Details</td>
                                        <td scope="row">{{viewUpsiList.remarks}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Mode of Sharing</td>
                                        <td scope="row">{{viewUpsiList.mode_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewUpsiList.purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Nature of UPSI</td>
                                        <td scope="row">{{viewUpsiList.nature_of_upsi}} <div *ngIf="viewUpsiList.nature_of_upsi !== 'Others'" > {{viewUpsiList.others_nature_upsi}}</div></td>                                         
                                     </tr>
                                       
                                     <tr>
                                        <td scope="row">Sharing Frequency</td>
                                        <td scope="row">{{viewUpsiList.type_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Date of Sharing</td>
                                        <td scope="row">{{viewUpsiList.date_of_sharing | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">UPSI From Date</td>
                                        <td scope="row">{{viewUpsiList.from_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiList.to_date != '' && viewUpsiList.to_date != null ">
                                        <td scope="row">Will this Information be made public?</td>
                                        <td scope="row">{{viewUpsiList.to_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiList.to_date == '' || viewUpsiList.to_date == null ">
                                       <td scope="row">Will this Information be made public?</td>
                                       <td scope="row">Not Published</td>                                         
                                    </tr>  
                                     <!-- <tr>
                                        <td scope="row">Confidentiality Agreement entered</td>
                                        <td scope="row">{{viewUpsiList.confidentiality_agreement_entered}}</td>                                         
                                     </tr>                                         
                                     <tr *ngIf="viewUpsiList.confidentiality_agreement_entered == 'Yes' ">
                                        <td scope="row">Confidentiality Agreement date</td>
                                        <td scope="row">{{viewUpsiList.confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>    -->
                                     <tr>
                                       <td scope="row">Username</td>
                                       <td scope="row">{{viewUpsiList.created_by}}</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Activity Date</td>
                                       <td scope="row">{{viewUpsiList.created_on}}</td>                                         
                                    </tr>                                
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
