import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-approved-pdf',
  templateUrl: './approved-pdf.component.html',
  styleUrls: ['./approved-pdf.component.scss']
})
export class ApprovedPdfComponent implements OnInit {
  @Input() pdfapprovedOrder;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getIdDp: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  companyName: any;
  isinNumber: any;
  getStatus: any;
  complianceofficerdesignation: any;
  complianceofficername: any;
  complianceOfficerEmail: any;
    constructor(
      public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService,
      private companyService: CompanyService ) { }

  ngOnInit() {
 this.getIdDp = this.pdfapprovedOrder.id;
 this.getStatus = this.pdfapprovedOrder.status;
 
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      // this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      // this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      this.isinNumber = listCompany[0].isin; 
      this.complianceofficername = listCompany[0].compliance_officer_name; 
      this.complianceofficerdesignation = listCompany[0].designation; 
      console.log(listCompany[0].designation)
    }
    },
    err => {
        console.log(err);
    }
);
  }
 
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onFilterPdf() {
    
    let queryString = '';
    let url = this.baseUrl + 'preClearancePdfapproved?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.getIdDp || this.getStatus || this.companyName || this.complianceOfficerEmail || this.complianceofficername || this.complianceofficerdesignation )  {
      queryString = 'postId=' + this.getIdDp + '&status=' + this.getStatus + '&companyName=' + this.companyName + '&complianceOfficerEmail=' + this.complianceOfficerEmail + '&complianceofficername=' + this.complianceofficername + '&complianceofficerdesignation=' + this.complianceofficerdesignation ;
      queryString = queryString + '&authToken='+btoa(tokenStr);
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    this.activeModal.close(event);
     
  }
}
