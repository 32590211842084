<div class="modal-header">
    <h5 class="modal-title">Alert!.. </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <p class="tradealert"><b>Note: </b>Please note that the trade value you have entered is more than Rs 10 Lakhs.  You have to inform the Stock Exchange in which the securities are listed about this trade as per SEBI (Prohibition of Insider Trading) Regulations, 2015.</p>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
