<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header btnlist">
                    <div class="filterFormSection" >
                        <div class="col-md-6 mb-3">
                            <div class="form-group">
                                <input type="text" class="form-control" id="filterString" [(ngModel)]="filterString" autocomplete="off"
                                    (ngModelChange)="onFilterChange()" placeholder="Search Insider by Name or Email ID" required>
                            </div>
                        </div>
                        <div class="back-btn">

                            <div class="columnHide mb-3">
                                <div class="form-group">
                                    <label for="hideShow">Filter Users Access</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Users Access'"
                                        [settings]="dropdownSettings1" 
                                        [data]="usersaccesslist"
                                        [(ngModel)]="selectedusersItems"
                                        (onSelect)="onItemSelect($event,'access')"
                                        (onDeSelect)="onItemDeSelect($event,'access')"
                                        (onDeSelectAll)="onDeSelectAll($event,'access')"
                                        (onSelectAll)="onSelectAll($event,'access')" 
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="columnHide mb-3">
                                <div class="form-group">
                                    <label for="hideShow">Filter Status</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Status'"
                                        [settings]="dropdownSettings1" 
                                        [data]="statuslist"
                                        [(ngModel)]="selectedStatusItems"
                                        (onSelect)="onItemSelect($event,'status')"
                                        (onDeSelect)="onItemDeSelect($event,'status')"
                                        (onDeSelectAll)="onDeSelectAll($event,'status')"
                                        (onSelectAll)="onSelectAll($event,'status')" 
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="openExcelDisclaimer()" title="Download Users List">
                            <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>

                        </div>
                    </div>
                    <!-- <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a> -->
                                    
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="username">User Name</th>
                                    <th scope="col" sortable="email">Email</th>
                                    <th scope="col" sortable="idproof">ID Proof No.</th>
                                    <th scope="col" sortable="access">Access</th>
                                    <th scope="col" sortable="access">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                <td scope="row" data-content="username">{{items.dpname}}</td>
                                <td scope="row" data-content="email">{{items.email}}</td>                                
                                <td scope="row" data-content="idproof">{{items.documenttype}} - {{items.dppanno}}</td>                                
                                <td scope="row" data-content="access">{{items.user_access}}</td>                           
                                <td scope="row" data-content="active">{{items.accesstosdd}}</td>  
                            </tr>
                            </tbody> 
                        </table>

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        
                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

