import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-upsi-component',
  templateUrl: './error-upsi-component.component.html',
  styleUrls: ['./error-upsi-component.component.scss']
})
export class ErrorUpsiComponentComponent implements OnInit {
  @Input() viewUpsiErrorList;
  page = 1;
  pageSize = 10;
  constructor(public activeModal: NgbActiveModal, private router:Router) { }

  ngOnInit(): void {
    //this.viewDpErrorList;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }

}
