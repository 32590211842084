<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Update Company Profile</h5>
                    <a routerLink="/setup/company-profile"><button class="btn btn-primary me-2 "><i
                                class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="companyEditForm" class="needs-validation" (ngSubmit)="openModalEditReason()">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="companyName">Company Name <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="companyName"
                                        placeholder="Enter the Company Name" autocomplete="off"
                                        formControlName="company_name" required
                                        [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.company_name.touched) && companyEditForm.controls.company_name.errors }">
                                </div>
                                <div *ngIf="(submitted || companyEditForm.controls.company_name.touched) && companyEditForm.controls.company_name.errors?.required"
                                    class="text text-danger">
                                    Company Name is required.
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 contact-detailes">
                                <div class="col-md-12 mb-3">
                                    <label for="sub_status">SDD Subscription Key</label>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="sub_status"
                                            placeholder="Enter the SDD Supscription Key" autocomplete="off"
                                            formControlName="sub_status">
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label for="expiryDate">Subscription Expiry Date</label>
                                    <div class="form-group">
                                        <input class="form-control" id="expiryDate" placeholder="yyyy-mm-dd" type="date"
                                            formControlName="sub_validity">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3 contact-detailes">
                                <h4>Address and Contact Details</h4>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="registeredOffice">Registered Office Address <span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <textarea class="form-control" id="registeredOffice" rows="3"
                                                formControlName="registered_office"
                                                placeholder="Enter the Registered Office" required
                                                [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.registered_office.touched) && companyEditForm.controls.registered_office.errors }"></textarea>
                                        </div>
                                        <div *ngIf="(submitted || companyEditForm.controls.registered_office?.touched) && companyEditForm.controls.registered_office.errors?.required"
                                            class="text text-danger">
                                            Registered Office Address is required.
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="corporateOffice">Corporate Office Address</label>
                                        <div class="form-group">
                                            <textarea class="form-control" id="corporateOffice" rows="3"
                                                formControlName="corporate_office"
                                                placeholder="Enter the Corporate Office"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email ID <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="email" autocomplete="off"
                                                placeholder="Enter the Email ID" formControlName="email" required
                                                [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.email.touched) && companyEditForm.controls.email.errors }">
                                                <div *ngIf="(submitted || companyEditForm.controls.email?.touched) && companyEditForm.controls.email.errors?.required"
                                                class="text text-danger">
                                                Email ID is required.
                                            </div>
                                            <div class="text text-danger"
                                                *ngIf="companyEditForm.controls.email.errors?.pattern">
                                                Email must be a valid email address
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="mobileNo">Contact Number<span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="mobileNo" autocomplete="off"
                                                placeholder="Enter the Contact Number" formControlName="mobileno"
                                                required
                                                [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.mobileno.touched) && companyEditForm.controls.mobileno.errors }">
                                                <div *ngIf="(submitted || companyEditForm.controls.mobileno?.touched) && companyEditForm.controls.mobileno.errors?.required"
                                                class="text text-danger">
                                                Contact Number is required.
                                            </div>
                                            <div class="text text-danger"
                                                *ngIf="companyEditForm.controls.mobileno.errors?.pattern">Enter the Valid
                                                Contact Number.</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3 other-detrails">
                                <h4>Other Details</h4>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="cin">CIN Number <span class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="cin" autocomplete="off"
                                                placeholder="Enter the CIN Number" formControlName="cin" required
                                                [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.cin.touched) && companyEditForm.controls.cin.errors }">
                                        </div>
                                        <div *ngIf="(submitted || companyEditForm.controls.cin?.touched) && companyEditForm.controls.cin.errors?.required"
                                            class="text text-danger">
                                            CIN Number is required.
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="isin">ISIN Number</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="isin" autocomplete="off"
                                                placeholder="Enter the ISIN Number" formControlName="isin">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="cin">GST Number</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="gstNumber" autocomplete="off"
                                                placeholder="Enter the GST Number" formControlName="GSTNumber">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="isin">PAN Number</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="panNumber" autocomplete="off"
                                                placeholder="Enter the PAN Number" formControlName="PANNumber">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="bseScripCode">BSE Scrip Code</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="bseScripCode" autocomplete="off"
                                                placeholder="Enter the BSE Scrip Code" formControlName="bse_scrip_code">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="nseScripCode">NSE Scrip Code</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="nseScripCode" autocomplete="off"
                                                placeholder="Enter the NSE Scrip Code" formControlName="nse_scrip_code">
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="nseScripCode">Customer Type</label>
                                        <div class="form-group">
                                            <select class="form-select" class="form-control form-select"
                                                id="customertype" formControlName="customertype">
                                                <option value="" disabled selected>--Choose One --</option>
                                                <option value="Company">Company</option>
                                                <option value="Intermediary">Intermediary</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class ="row" *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Equity Shares </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="companyshares"
                                                    autocomplete="off" placeholder="Enter the Company Total Equity Shares"
                                                    formControlName="companyshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.companyshares.errors?.pattern">Please,
                                                    Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total preference Shares </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="companyshares"
                                                    autocomplete="off"
                                                    placeholder="Enter the Company Total Preference Shares"
                                                    formControlName="preferenceshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.preferenceshares.errors?.pattern">
                                                    Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Warrants </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="warrantsshares"
                                                    autocomplete="off" placeholder="Enter the Company Total Warrants"
                                                    formControlName="warrantsshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.warrantsshares.errors?.pattern">Please,
                                                    Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Convertible Debentures</label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="convertibleshares"
                                                    autocomplete="off"
                                                    placeholder="Enter the Company Total Convertible Debentures"
                                                    formControlName="convertibleshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.convertibleshares.errors?.pattern">
                                                    Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Non-Convertible Debenture</label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="nonconvertibleshares"
                                                    autocomplete="off"
                                                    placeholder="Enter the Company Total Non-Convertible Debenture"
                                                    formControlName="nonconvertibleshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.nonconvertibleshares.errors?.pattern">
                                                    Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Rights entitlements</label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="rightentitleshares"
                                                    autocomplete="off"
                                                    placeholder="Enter the Company Total Rights entitlements"
                                                    formControlName="rightentitleshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.rightentitleshares.errors?.pattern">
                                                    Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="nseScripCode">Company Total Others Shares </label>
                                            <div class="form-group">
                                                <input type="text" class="form-control" id="othersshares" autocomplete="off"
                                                    placeholder="Enter the Company Total Others Shares"
                                                    formControlName="othersshares">
                                                <div class="text text-danger"
                                                    *ngIf="companyEditForm.controls.othersshares.errors?.pattern">Please,
                                                    Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3 compliance-details">
                                <h4>Compliance Details</h4>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="complianceOfficerName">Compliance Officer Name <span
                                            class="manditary-filed">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" autocomplete="off" id="complianceOfficerName" placeholder="Enter the Compliance Officer Name"
                                                formControlName="compliance_officer_name" [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.compliance_officer_name.touched) && companyEditForm.controls.compliance_officer_name.errors }">
                                                <div *ngIf="(submitted || companyEditForm.controls.compliance_officer_name?.touched) && companyEditForm.controls.compliance_officer_name.errors?.required"
                                                    class="text text-danger">
                                                    Compliance Officer Name is required.
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="designation">Compliance Officer Designation</label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" autocomplete="off" id="designation"
                                                placeholder="Enter the Compliance Officer Designation"
                                                formControlName="designation">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="designation">Compliance Officer Email ID <span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" autocomplete="off"
                                        id="complianceofficeremail" placeholder="Enter the Compliance Officer Email ID"
                                        formControlName="complianceofficeremail" required
                                        [ngClass]="{ 'is-invalid': (submitted || companyEditForm.controls.complianceofficeremail.touched) && companyEditForm.controls.complianceofficeremail.errors }">
                                        <div *ngIf="(submitted || companyEditForm.controls.complianceofficeremail?.touched) && companyEditForm.controls.complianceofficeremail.errors?.required"
                                        class="text text-danger">
                                        Compliance Officer Email ID is required.
                                    </div>
                                    <div class="text text-danger" *ngIf="companyEditForm.controls.complianceofficeremail.errors?.pattern">
                                        Email must be a valid email address
                                    </div>
                                </div>
                                 
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                <label for="designation">Preclearance Trading Customize Day Count </label>
                                <div class="form-group">
                                    <input type="text" class="form-control" autocomplete="off" id="predaycount"
                                        placeholder="Enter the Preclearance Trading Customize Day Count"
                                        formControlName="predaycount">
                                </div>
                                <div class="text text-danger"
                                    *ngIf="companyEditForm.controls.predaycount.errors?.pattern">Please, Enter 0-7
                                    Numbers of Days only.</div>
                                <div class="text text-danger"
                                    *ngIf="companyEditForm.controls.predaycount.errors?.maxlength">Please, Enter One
                                    character only.</div>
                            </div>
                            <div class="col-md-12 mb-3 compliance-details" *ngIf="accessUser == 'sdd@legalitysimplified.com'">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="installation_date">Installation Date</label>
                                        <div class="form-group">
                                            <input class="form-control " placeholder="yyyy-mm-dd" type="datetime-local"
                                                id="installation_date" formControlName="installation_date">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit-btn">
                                <button class="btn btn-primary" *ngIf="showButton" type="submit"
                                    [disabled]="isLoading"><i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>
                                    {{button}}</button>
                                <button class="btn btn-warning" type="btn" (click)="cancelProject()">Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->