import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl;
  
 projectList(userObj:any) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
    return this.http.post(this.baseUrl + 'project/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  addProject(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "project/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  
  

  viewProject(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "project/view" ,userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}    
    })
  }
  editProject(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "project/edit" ,userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}    
    })
  }
  updateProject(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "project/update", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
//   deleteProject(id:String):Observable<any>{
//     return this.http.delete(this.baseUrl + 'project/delete/' + id, {
//       headers: {'Authorization': sessionStorage.getItem('access_token')}
//     });
// }
deleteProject(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'project/delete' , userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
}
deletedlist(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + 'project/deletedlist' , userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  });
}
revertProject(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + 'project/revertproject' , userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  });
}

ProjectActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + 'project/projectActivity' , userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  });
}
viewActivityProject(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "project/projectActivityHistoryView", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
//project access
projectAccess(userObj:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'project/projectModule',userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
//roles
addRole(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/create", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  } )
}
updateRole(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/update", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  })
}
getRoles(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/list", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  })
}
roleNamesList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/RoleNamesList", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  })
}
getRoleById(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/edit", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  })
}
usersControlAccessList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/userscontrolaccesslist", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  })
}

//mail template

mailTemplateCreate(userObj:any):Observable<any> {
  
  return this.http.post(this.baseUrl + 'mailTemplate/create', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
mailTemplateUpdate(userObj:any):Observable<any> {

  return this.http.post(this.baseUrl + 'mailTemplate/update', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
mailTemplateList(userObj:any):Observable<any> {
  return this.http.post(this.baseUrl + 'mailTemplate/list', userObj,{
    headers: {'Authorization': sessionStorage.getItem('access_token')},
   
  })
}
mailTemplateDelete(userObj:any):Observable<any> {
  return this.http.post(this.baseUrl + 'mailTemplate/delete', userObj,{
    headers: {'Authorization': sessionStorage.getItem('access_token')},
   
  })
}
mailTemplateEdit(userObj:any):Observable<any> {

  return this.http.post(this.baseUrl + 'mailTemplate/edit', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
mailTemplateView(userObj:any):Observable<any> {

  return this.http.post(this.baseUrl + 'mailTemplate/view', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
//Role Project access
addRoleProject(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "role/projectcreate", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
//company rules
addCompanyRules(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/createRules", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
listCompanyRules(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/listOfRules", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
editCompanyRules(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/editRules", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
 updateCompanyRules(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/updateRules" , userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
 deleteCompanyRules(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + 'company/deleteRules', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  });
}
//Restricted Company 
addPermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/permissionoftradingcompany", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
listPermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/permissionoftradingcompanylist", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
deletePermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/permissionoftradingcompanydelete", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  });
}
editPermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "company/permissionoftradingcompanyedit", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
updatePermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/permissionoftradingcompanyupdate", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }
 mailPermissionTradingCompany(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/mailconfig", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
 }

 addMailSetting(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "addmailsettings", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
 updateMailSetting(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "updatemailsettings", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
mailsettings(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "mailsettings",userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
mailsettingsedit(userObj:any):Observable<any> {

  return this.http.post(this.baseUrl + 'editmailsettings', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
sendTestMailSetting(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "sendtestemailsettings", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
clearCache():Observable<any>{
  return this.http.get(this.baseUrl + "/clear", {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
userLogHistory(userObj:any) {
   return this.http.post(this.baseUrl + 'userloginhistory', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
systemInfoHistory(userObj:any) {
   return this.http.post(this.baseUrl + 'detailedactivitylog/systeminfohistory', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
viewsystemInfoHistory(userObj:any) {
   return this.http.post(this.baseUrl + 'detailedactivitylog/view', userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
}

//mail Template
