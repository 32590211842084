import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { PitComplianceService } from '../../pit-compliance.service';
import { DeleteTradingWindowClosureComponent } from '../delete-trading-window-closure/delete-trading-window-closure.component';
import { MailComponentComponent } from '../mail-component/mail-component.component';
import { ViewTradingWindowClosureComponent } from '../view-trading-window-closure/view-trading-window-closure.component';

@Component({
  selector: 'app-list-trading-window-closure',
  templateUrl: './list-trading-window-closure.component.html',
  styleUrls: ['./list-trading-window-closure.component.scss']
})
export class ListTradingWindowClosureComponent implements OnInit {
  tradingWindowList: any;
  page = 1;
  pageSize = 10;
  filtered: any;
  filterString = "";
  hidelist = [{"id":1,"name":"Id No"},{"id":2,"name":"Subject"},{"id":3,"name":"Period"},{"id":4,"name":"Sender"},{"id":5,"name":"Recipient"},{"id":6,"name":"Start Date"},{"id":7,"name":"To Date"},{"id":8,"name":"Description"},{"id":9,"name":"created_on"}];
  selectedhideItems = [];
  show_id = true;
  show_subject = true;
  show_period = true;
  show_sender = true;
  show_recipient = true;
  show_start_date=false;
  show_to_date=false;
  show_description = false;
  show_created_on = false;
  dropdownSettings1:IDropdownSettings;

  visible: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false
  visible6: boolean = false
  subjectFilterString= '';
  periodFilterString= '';
  senderFilterString= '';
  recipientFilterString= '';
  startDateFilterString = '';
  endDateFilterString = '';
  keyword = 'subject';
  keyword2 = 'period';
  keyword3 = 'sender_name';
  keyword4 = 'recipient_name';
  keyword5 = 'start_date';
  keyword6 = 'end_date';
  subjectNameFilter = [];
  periodNameFilter = [];
  EndDateNameFilter = [];
  senderNameFilter = [];
  recipientNameFilter = [];
  startDateNameFilter = [];
  hideButton:boolean = false;
  changeElement: any;
  changeElementShow:boolean = false;
  changeElementHide:boolean = true;
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  constructor(private modalService: NgbModal, private pitService: PitComplianceService) { }
//MultiSelect HideShow
onItemSelect(item: any,page) {
 if(page == "column"){
  if(item.id == 1){
    this.show_id = true;
  }else if(item.id == 2){
    this.show_subject = true;
  }else if(item.id == 3){
    this.show_period = true;
  }else if(item.id == 4){
    this.show_sender = true;
  }else if(item.id == 5){
    this.show_recipient = true;
  }else if(item.id == 6){
     this.show_start_date = true;
   }else if(item.id == 7){
     this.show_to_date = true;
   }else if(item.id == 8){
    this.show_description = true;
  }else if(item.id == 9){
    this.show_created_on = true;
  }
 }
}

onItemDeSelect(item: any,page) {

if(page == "column"){
  if(item.id == 1){
    this.show_id = false;
  }else if(item.id == 2){
    this.show_subject = false;
  }else if(item.id == 3){
    this.show_period = false;
  }else if(item.id == 4){
    this.show_sender = false;
  }else if(item.id == 5){
    this.show_recipient = false;
  }else if(item.id == 6){
     this.show_start_date = false;
   }else if(item.id == 7){
     this.show_to_date = false;
   }else if(item.id == 8){
    this.show_description = false;
  }else if(item.id == 9){
    this.show_created_on = false;
  }
 }
 
}
onSelectAll(items: any,page) {
if(page == "column"){
  this.show_id = true;
  this.show_subject = true;
  this.show_period = true;
  this.show_sender = true;
  this.show_recipient = true;
  this.show_start_date=true;
  this.show_to_date=true;
  this.show_description = true;
  this.show_created_on = true;
 
 }
 
}
onDeSelectAll(items: any,page) {
if(page == "column"){
  this.show_id = false;
  this.show_subject = false;
  this.show_period = false;
  this.show_sender = false;
  this.show_recipient = false;
  this.show_start_date=false;
  this.show_to_date=false;
  this.show_description = false;
  this.show_created_on = false;
 
 }
}
// Multi Select
  ngOnInit(): void {

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.twc_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.twc_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
    this.pitService.tradingWindowClosureList(null).subscribe(
      res => {
        let send_item = []
        this.tradingWindowList = res;
        this.filtered = this.tradingWindowList?.data;
        this.tradingWindowList.data.map(item =>
          {
          //  send_item.push(item)
          //   console.log("777",send_item.length)
            for(let i=0; i<item.length; i++){
            if(item[i].send_mail != 1 || item[i].send_mail == null){
          
              this.changeElementHide = true;
              this.changeElementShow = false;
               
            }else{
              this.changeElementShow = true;
              this.changeElementHide = false;
            }
          }
          });
        // const xyz = this.tradingWindowList.data.map(item =>(item.sender_name));
        // console.log("xyz", xyz)
        // const iterator = xyz.values();
        // for(const value of iterator){
        //   const value1 = value;
        //   var match = value1.split(', ')
          
        //   for(const a of match){
        //     var match1 = a?.split(', ')
        //     //var variable = match[a]
        //     console.log('variable', match1)
        //   }
        // }
//Subject Filter
let twcSubject = this.tradingWindowList?.data.map(res => res.subject)
this.subjectNameFilter = [];
from(twcSubject)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.subjectNameFilter.push(r));

//period Filter
let twcperiod = this.tradingWindowList?.data.map(res => res.period)
this.periodNameFilter = [];
from(twcperiod)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.periodNameFilter.push(r));
//sender Filter
let twcSender = this.tradingWindowList?.data.map(res => res.sender_name)
this.senderNameFilter = [];
from(twcSender)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.senderNameFilter.push(r));

//Recipient Filter
let twcrecipient = this.tradingWindowList?.data.map(res => res.recipient_name)
this.recipientNameFilter = [];
from(twcrecipient)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.recipientNameFilter.push(r));

//start date Filter
let twcStartDate = this.tradingWindowList?.data.map(res => res.start_date)
this.startDateNameFilter = [];
from(twcStartDate)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.startDateNameFilter.push(r));

//end date Filter
let twcEndDate = this.tradingWindowList?.data.map(res => res.end_date)
this.EndDateNameFilter = [];
from(twcEndDate)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.EndDateNameFilter.push(r));

      })
//Multiselect HideShow
this.dropdownSettings1 = {
  singleSelection: false,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 2,
  allowSearchFilter: true
};
this.selectedhideItems = [{"id":1,"name":"Id No"},{"id":2,"name":"Subject"},{"id":3,"name":"Period"},{"id":4,"name":"Sender"},{"id":5,"name":"Recipient"}];

  }
  openModal(id:any) {
    if (id) {
      let twcId = {'id': id};
      this.pitService.tradingWindowClosureView(twcId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(ViewTradingWindowClosureComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.viewTradingWindowList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}

  openModalDelete(id:any) {
    if (id) {
      let twcId = {'id': id};
      this.pitService.tradingWindowClosureView(twcId).subscribe(
        ({data}) =>{
		const modalRef = this.modalService.open(DeleteTradingWindowClosureComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });		
    modalRef.componentInstance.deleteTradingWindowList = data[0];
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  })
}
	}
  
  openModalSendMail(id:any) {
    if (id) {
      let twcId = {'id': id};
      this.pitService.tradingWindowClosureView(twcId).subscribe(
        ({data}) =>{
          this.hideButton = true;
		const modalRef = this.modalService.open(MailComponentComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
      
      });		
    
    modalRef.componentInstance.hideButton = this.hideButton
    modalRef.componentInstance.sendMailTradingWindowList = data[0];
    // modalRef.result.then((result) => {
    //   console.log(result);
    //   this.hideButton = false;
    // }, (reason) => {
    // });
    modalRef.componentInstance.twcEmailMsg.subscribe((receivedEntry) => {
      this.changeElement = receivedEntry;
      })
  })
}
	}
  //Filter
  // 

  selectEvent(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.subject.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible = false;
  }
  selectEvent1(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.period.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible2 = false;
  }
  selectEvent2(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.sender_name.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible3 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.recipient_name.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible4 = false;
  }
  selectEvent4(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.start_date.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible5 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.tradingWindowList?.data.filter(twcItem => {
      return twcItem.end_date.toLocaleLowerCase().match(item.toLocaleLowerCase());
  
    })
  
    this.visible6 = false;
  }
onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
  console.log("String: " + search);
  
}

onFocused(e) {
  // do something
}
onCleared(e) {
  //console.log('input cleared');
  this.filtered = this.tradingWindowList?.data;
  ;
}

onBlur(e) {
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
}
onScrolledEnd() {
  // this.auto.close();
}
filterData() {
  this.visible = !this.visible;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
}
filterData2() {
  this.visible2 = !this.visible2;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;

}
filterData3() {
  this.visible3 = !this.visible3;
  this.visible = false;
  this.visible2 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
}
filterData4() {
  this.visible4 = !this.visible4;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible5 = false;
  this.visible6 = false;
}
filterData5() {
  this.visible5 = !this.visible5;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible6 = false;
}
filterData6() {
  this.visible6 = !this.visible6;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
}



//
  onFilterChange() {
    this.filtered = this.tradingWindowList.data?.filter(res =>{    
     return res.subject.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.period.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.sender_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.recipient_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.start_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.end_date.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.created_on.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }

}
