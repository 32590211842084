import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DpModuleService } from '../dp-module.service';

@Component({
  selector: 'app-revet-dp-module',
  templateUrl: './revet-dp-module.component.html',
  styleUrls: ['./revet-dp-module.component.scss']
})
export class RevetDpModuleComponent implements OnInit {
  @Input() viewDbList;
  @Input() title;
  public active1 = 1;
  revetDpForm: FormGroup;
  getIdDp: any;
  getEmailDp: any;
  isLoading = false;
  button = 'Restore';
  submitted = false;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, private dpService:DpModuleService ) { }

  ngOnInit() {
  this.revetDpForm = this.fb.group({
    id: new FormControl(''),
    email: new FormControl(''),
    reasonforrestore: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])

  });
 this.getIdDp = this.viewDbList.id;
 this.getEmailDp = this.viewDbList.email;
    
  }
  revertDpId(){
    this.submitted = true;
    this.revetDpForm.value.id = this.getIdDp;
    this.revetDpForm.value.email = this.getEmailDp;
    if(this.revetDpForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.dpService.revertDpModule(this.revetDpForm.value).subscribe(data =>{
        if(data.success == 2){
          this.toastr.warning(data.message);
          this.isLoading = false;
          this.button = 'Restore';
          this.activeModal.close(event);
        }else{
        this.toastr.success("DP Successfully Restored");
        this.isLoading = false;
        this.button = 'Restore';
        this.activeModal.close(event);
        this.router.navigate(['/dp/dp-list']);
        }
      });
    }else{
      if(this.revetDpForm.get('reasonforrestore').value == ''){
        this.toastr.warning("Please Mention the Reason for Restore DP.");
      }
    }
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
