<div class="modal-header">
    <h5 class="modal-title">VIEW PROJECT/EVENT DETAILS</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body custom-datatable noscroll">
                        <div class="custom-datatable">
                            <table class="table">                            
                                    <tbody *ngIf="viewProjectList">
                                        <tr>
                                            <td scope="row">Project/Events Name</td>
                                            <td scope="row">{{viewProjectList.project_name}}</td>                                         
                                         </tr> 
                                         <tr *ngIf="viewProjectList.durationfrom !== ''">
                                            <td scope="row">From Date</td>
                                            <td scope="row">{{viewProjectList.durationfrom | date:'dd-MM-yyyy'}}</td>                                         
                                         </tr> 
                                         <tr *ngIf="viewProjectList.durationto !== '' ">
                                            <td scope="row">To Date</td>
                                            <td scope="row">{{viewProjectList.durationto | date:'dd-MM-yyyy'}}</td>                                         
                                         </tr>                     
                                         <tr *ngIf="viewProjectList.ProjectAccess != '' ">
                                            <td scope="row">Project Access</td>
                                            <td scope="row">{{viewProjectList.ProjectAccess}}</td>                                         
                                         </tr>                     
                                   
                                </tbody>
                            </table>

                            

                            <div class="checkbox">
                           
                                <div class="col-sm-12 col-xl-12">
                                   <div class="card">                                                     
                                     <div class="card-body timeline">
                                       <ngb-accordion [closeOthers]="true">
                                         <ngb-panel id="static-1">
                                           <ng-template ngbPanelTitle>
                                               <span>Timeline of Changes</span>
                                             </ng-template>
                                           <ng-template ngbPanelContent>
                                            <div class="custom-datatable">
                                                <div class="timeline-wrapper"> 
                                                    <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                                           <li class="StepProgress-item is-done">
                                                               <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                               <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                               <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                               <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                               <div ><p><span class="bold">Action By (Username):</span> {{items.ActionBy}}</p></div>
                                                               <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                           </li>                                       
                                                    </ul>
                                                </div>    
                                                
                                                <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                                    <p>No Data Found</p>
                                                </div>
                                             
                                           </div>
     
                                           </ng-template>
                                         </ngb-panel>
                                                                             
                                       </ngb-accordion>
                                     </div>
                                   </div>
                                 </div>
                               
                             </div>
                             
                            </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
