import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-view-pre-clearance',
  templateUrl: './view-pre-clearance.component.html',
  styleUrls: ['./view-pre-clearance.component.scss']
})
export class ViewPreClearanceComponent implements OnInit {
  @Input() viewPreClearanceList;
  @Input() predaycounts;
  predaycount: any;
  // predaycounts: any;

  constructor(
    public activeModal: NgbActiveModal, private companyService: CompanyService
  ) { 
    //this.predaycounts = sessionStorage.getItem('predaycount');
    
  }

  ngOnInit() {  
    // this.companyService.CompanyList(null).subscribe(
    //   res => {        
    //   let listCompany = res.body['data']     
    //   this.predaycounts = listCompany[0].predaycount; 
    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    

    if(Number(this.predaycounts) == 0 || this.predaycounts =='' || this.predaycounts == null){
      this.predaycount = 7;
    }else{
      this.predaycount = this.predaycounts;
    }
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
