<div class="container-fluid report-section">
    <div class="row">
       <div class="card">
        <div class="card-header">
            <div class="filterFormSection" >
                <form class="date-block" [formGroup]="filterForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="row date-block__list">
                            <div class="col-md-6 mb-3">
                                <label for="fromDate">From Date of Sharing (Range)</label>
                                <div class="form-group input-group">
                                <input class="form-control " placeholder="yyyy-mm-dd"  type="date" (change)="onDateFilter($event)" formControlName="dateOfSharing" min="1997-01-01" max="2050-12-31">                                  
                                </div>                   
                            </div>
                            <div class="col-md-6 mb-3 list" style="position: relative;">
                                <label for="fromDate">To Date of Sharing (Range)</label>
                                <div class="form-group input-group">
                                <input class="form-control " placeholder="yyyy-mm-dd" type="date" formControlName="toDate" (change)="onDateFilter($event)" min="1997-01-01" max="2050-12-31">                                  
                                </div>                 
                                <p *ngIf="filterForm.touched && filterForm.invalid" style="color:red;position: absolute;bottom: -1.5rem;">Please add a valid from and To date </p>
                            </div>
                            <!-- <div class="col-md-6 mb-3">
                                <label for="nameOfSender">Type of sharing</label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select"
                                        id="typeOfSharing" formControlName="type_of_sharing">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option value="One time">One time</option>
                                        <option value="ongoing">ongoing</option>
                                    </select>                                  
                                </div>
                            </div> -->
                          
                            <div class="col-md-6 mb-3">
                                <label for="natureOfUpsi">Nature of UPSI</label>
                                <div class="form-group">                                  
                                     <select class="form-select" class="form-control form-select" (change)="natUpsiFilter($event)"
                                                id="typeOfSharing" formControlName="nature_of_upsi">
                                                <option value="" selected>--Choose One --</option>
                                                <option value="Financial results">Financial results</option>
                                                <option value="Dividends">Dividends</option>
                                                <option value="Change in capital structure">Change in capital structure</option>
                                                <option value="Mergers, de-mergers & acquisitions">Mergers, de-mergers & acquisitions</option>
                                                <option value="Delistings">Delistings</option>
                                                <option value="Disposals">Disposals</option>
                                                <option value="Expansion of business">Expansion of business</option>
                                                <option value="Changes in key managerial personnel">Changes in key managerial personnel</option>
                                                <option value="Others">Others</option>
                                            </select>                               
                                </div> 
                            <!-- <label for="projectName">Nature of UPSI</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Nature of UPSI'"
                                                [settings]="dropdownSettings"
                                                [data]="dropdownList"
                                                [(ngModel)]="selectedItems"
                                                (onSelect)="onItemSelect($event,'project')"
                                                (onDeSelect)="onItemDeSelect($event,'project')"
                                                (onSelectAll)="onSelectAll($event,'project')"
                                                (onDeSelectAll)="onDeSelectAll($event,'project')" formControlName="projects"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>                               -->
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Sender Name</label>
                                <div class="form-group">
                                    <ng-multiselect-dropdown [placeholder]="'Select Sender Name'"
                                        [settings]="dropdownSettings"
                                        [data]="listUpsiTransferSender"
                                        [(ngModel)]="selectedsenderName"
                                        (onSelect)="onItemSelect($event,'sender')"
                                        (onDeSelect)="onItemDeSelect($event,'sender')"
                                        (onSelectAll)="onSelectAll($event,'sender')"
                                        (onDeSelectAll)="onDeSelectAll($event,'sender')"
                                       
                                        formControlName="sender"
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Recipient Name</label>
                                <div class="form-group">
                                    <ng-multiselect-dropdown [placeholder]="'Select Recipient Name'"
                                        [settings]="dropdownSettings"
                                        [data]="listUpsiTransferrecipient"
                                        [(ngModel)]="selectedrecipientName"
                                        (onSelect)="onItemSelect($event,'receiver')"
                                        (onDeSelect)="onItemDeSelect($event,'receiver')"
                                        (onSelectAll)="onSelectAll($event,'receiver')"
                                        (onDeSelectAll)="onDeSelectAll($event,'receiver')"
                                         formControlName="receiver"
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <!-- <div class="col-md-6 mb-3 wid-field">
                                        <label for="projectName">Sender Name</label>
                                        <div class="form-group lstsender">
                                           
                                             <ng-select [items]="listUpsiTransferSender"
                                                        bindLabel=""
                                                        placeholder="Enter the Sender Name"
                                                        [(ngModel)]="selectedsenderName" formControlName="sender">
                                                </ng-select> 
                                       
                                                
                                           
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-6 mb-3 wid-field">
                                        <label for="projectName">Receiver Name</label>
                                        <div class="form-group lstsender">
                                            
                                           <ng-select [items]="listUpsiTransferrecipient"
                                                        bindLabel=""
                                                        placeholder="Enter the Recipient Name"
                                                        [(ngModel)]="selectedrecipientName" formControlName="receiver">
                                                </ng-select> 
                                              
                                            
                                        </div>
                                       
                                    </div> -->
                            <div class="col-md-2 mb-3">
                                <div class="btn-list">
                                    <button class="btn btn-primary me-3">Search</button>
                                    <button type="button" class="btn btn-primary me-3" (click)="resetForm()">Reset</button> 
                                   <!-- <img src="/assets/images/pdf-icon.png" class="pdf-icon" (click)="onFilterPdf()" style="cursor: pointer;">    -->
                                  <img src="/assets/images/pdf-icon.png" class="pdf-icon" (click)="openpdfalert()" title="Download PDF" style="cursor: pointer;"> 
                                  <img src="assets/images/excel.png" class="pdf-icon" (click)="downloadexcelsheet()" title="Download Excel" style="cursor: pointer;">                            
                           
                                 </div>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    
                    
                </form>
                <div class="back-btn">
                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
            </div>
        </div>
       </div>
      
    </div>
</div>


<div class="container-fluid" #reportContent >
    <div class="row" id="project">
      <div class="col-sm-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs tab-active2">
          <li [ngbNavItem]="1" >
            <a ngbNavLink>Graphical Reports</a>
            <ng-template ngbNavContent>
            <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                <div class="row">
                    <div class="col-xl-6 col-md-12" id="upsiSixMonthReport">
                        <div class="card" >
                            <div class="card-header">
                                <h5>UPSI Transfers Logged (or Filtered) in Last 6 Months</h5>
                            </div>
                            <div class="card-body chart-block">
                                <canvas baseChart class="chart" id="canvas" [datasets]="barChartData" [labels]="barChartLabels"
                                    [options]="barChartOptions" [legend]="barChartLegend"
                                    [type]="barChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-6 col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h5>Log UPSI Transfer Delay Count</h5>
                            </div>
                            <div class="card-body chart-block">
                                <canvas baseChart class="chart" [datasets]="lineChartData" [labels]="lineChartLabels"
                                    [options]="lineChartOptions" [legend]="lineChartLegend"
                                    [type]="lineChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                    </div> -->
            
                    <div class="col-xl-6 xl-100" id="upsiTotalReport">
                        <div class="card">
                            <div class="card-header">
                                <h5>Total DPs, Insiders, Projects/Events</h5>
                            </div>
                            <div class="card-body">
                                <div class="flot-chart-container">
                                    <div class="flot-chart-placeholder ngx-chart-direction" id="github-issues">
                                        <ngx-charts-pie-chart [scheme]="pieChartColorScheme" [results]="pieChart"
                                            [explodeSlices]="true" [labels]="pieChartShowLabels" [doughnut]="false" arcWidth=0.30
                                            [gradient]="pieChartGradient" (select)="onSelect($event)">
                                        </ngx-charts-pie-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <!-- <div class="col-xl-6 xl-100">
                        <div class="card">
                            <div class="card-header">
                                <h5>Upsi Delay Count</h5>
                            </div>
                            <div class="card-body">
                                <div class="flot-chart-container grid-pie-chart">
                                    <div class="flot-chart-placeholder ngx-chart-direction" id="github-issues">
                                        <ngx-charts-pie-grid [scheme]="pieChartColorScheme" [results]="pieChart"
                                            (select)="onSelect($event)">
                                        </ngx-charts-pie-grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                  </div>
            </div>
            </ng-template>
            </li>
            <!-- <li [ngbNavItem]="2" (click)="tabularData()">
              <a ngbNavLink>Tabular Reports</a>
                <ng-template ngbNavContent>
                  <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                    <div class="container-fluid">
                        <div class="row" id="caReport">
                          <div class="col-sm-12">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active2" class="nav-tabs tab-active1">
                              <li [ngbNavItem]="3" (click)="upsiDelayData()">
                                <a ngbNavLink>Delay</a>
                                <ng-template ngbNavContent>
                                <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                                    <div class="row" *ngIf = "is_access_no">
                                         <div class="col-sm-12">
                                            <div class="card">
                                                <div class="card-header">                             
                                                    <div class="col-md-7 mb-3">                      
                                                        <div class="form-group">
                                                        <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString2" (ngModelChange)="onFilterChange2()"  placeholder="Search ..." 
                                                             required >
                                                                                
                                                    </div>
                                                        </div>
                                                       
                                                </div>
                                                <div class="card-body custom-datatable noscroll">
                                                    <div class="custom-datatable">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" sortable="name">S.No.</th>
                                                                    <th scope="col" sortable="name">ID No.</th>
                                                                    <th scope="col" sortable="name">Delay period</th>
                                                                    <th scope="col" sortable="name">Nature of UPSI</th>
                                                                    <th scope="col" sortable="name">Project Name</th>
                                                                    <th scope="col" sortable="gender">Sender Name</th>
                                                                    <th scope="col" sortable="gender">Recipient Name</th>
                                                                    <th scope="col" sortable="gender">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody *ngFor = "let items of filtered2 | slice: (page-1) * pageSize : page * pageSize + pageSize; let i=index">
                                                                <tr *ngIf="items.delay !== '0'">
                                                                    <td scope="row" >{{(i+1) + (page-1) * pageSize }}</td>
                                                                    <td>UPSI-{{items.upsi_id}}</td> 
                                                                    //<td scope="row" >{{items.delay/24 | number: '1.0-1'}} Days {{items.delay%24}} Hours</td> //
                                                                    <td scope="row" >{{items.delay}}</td>
                                                                    <td>{{items.nature_of_upsi}}</td>  
                                                                    <td scope="row">{{items.project_name}}</td>
                                                                    <td scope="row">{{items.sender}}</td>
                                                                    <td scope="row">{{items.recipient}}</td>
                                                                    <td>
                                                                        <button class="edit-icon" (click)="openModal(items.id)" title="View Log"><i class="fa fa-eye"></i></button>                                                   
                                                                        // <a [routerLink]="['/upsi/upsi-edit', items.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-pencil-square-o"></i></button></a> //
                                                                    </td>                        
                                                            </tbody>
                                                        </table>
                    
                                                        <div class="list-btn2" *ngIf="filtered2?.length =='' ">
                                                            <p>No Data Found</p>
                                                        </div>
                    
                                                       <div class="d-flex justify-content-between p-2">
                                                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered2?.length"></ngb-pagination>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div *ngIf = "!is_access_no">
                                        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                                    </div>
                                </div>
                                </ng-template>
                                </li> 
                                <li [ngbNavItem]="3" (click)="upsiDelayData()">
                                  <a ngbNavLink>Confidentiality Agreement</a>
                                    <ng-template ngbNavContent>
                                      <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                                        <div class="row" *ngIf = "is_access_no">
                                           
                                            <div class="col-sm-12">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="col-md-7 mb-3">                      
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()"  placeholder="Search Report by Sender/Recipient Name or Nature of UPSI" 
                                                                 required >                                                                                    
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-3 caFilter">
                                                            <label for="caName">Filter UPSI CA List Columns</label>
                                                            <div class="form-group">
                                                                <ng-multiselect-dropdown [placeholder]="'Select UPSI CA List Columns'"
                                                                    [settings]="dropdownSettings"
                                                                    [data]="caUpsiTransferr"
                                                                    [(ngModel)]="selectedConfidencialAgreement"
                                                                    (onSelect)="onItemSelect($event,'filter')"
                                                                    (onDeSelect)="onItemDeSelect($event,'filter')"
                                                                    (onSelectAll)="onSelectAll($event,'filter')"
                                                                    (onDeSelectAll)="onDeSelectAll($event,'filter')"
                                                                    
                                                                    >
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body custom-datatable noscroll" >
                                                        <div class="custom-datatable">
                                                            <table class="table" id="datatables">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" sortable="sNo">S.No.</th>
                                                                        <th scope="col" sortable="idNo" *ngIf="show_upsi_id">ID No.</th>
                                                                        <th scope="col" sortable="delay" *ngIf="show_delay">Delay</th>
                                                                        <th scope="col" sortable="natureOfUpsi" *ngIf="show_nature_of_upsi">Nature of UPSI</th>
                                                                        <th scope="col" sortable="projectName" *ngIf="show_project_name">Project Name</th>
                                                                        <th scope="col" sortable="projectName" *ngIf="show_date_of_sharing">Date of Sharing</th>
                                                                        <th scope="col" sortable="sender" *ngIf="show_sender">Sender Name</th>
                                                                        <th scope="col" sortable="recipient" *ngIf="show_recipient">Recipient Name</th>
                                                                        <th scope="col" sortable="action">Actions</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr *ngFor = "let item of filtered | keyvalue | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                                                        <td scope="row" >{{(i+1) + (page-1) * pageSize }}</td>
                                                                        <td scope="row" *ngIf="show_upsi_id">UPSI-{{item.value.upsi_id}}</td> 
                                                                        <td scope="row" *ngIf="show_delay">{{item.value.delay}}</td>
                                                                        <td scope="row" *ngIf="show_nature_of_upsi">{{item.value.nature_of_upsi}}</td>                                      
                                                                        <td scope="row" *ngIf="show_project_name">{{item.value.project_name}}</td>                                      
                                                                        <td scope="row" *ngIf="show_date_of_sharing">{{item.value.date_of_sharing}}</td>
                                                                        <td scope="row" *ngIf="show_sender">{{item.value.SenderDPName}} - {{item.value.sender}} - {{item.value.SenderDPPanno}}</td>
                                                                        <td scope="row" *ngIf="show_recipient">{{item.value.RecepientDPName}} - {{item.value.recipient}} - {{item.value.RecepientDPPanno}}</td>
                                                                    <td>
                                                                        <button class="edit-icon" title="View Log" (click)="openModal(item.value.id)"><i class="fa fa-eye"></i></button>
                                                                         <a [routerLink]="['/upsi/upsi-edit', item?.value.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-pencil-square-o"></i></button></a> 
                                                                    </td> 
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="list-btn2" *ngIf="filtered?.length =='' ">
                                                                <p>No Data Found</p>
                                                            </div>
                    
                                                           <div class="d-flex justify-content-between p-2">
                                                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf = "!is_access_no">
                                            <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                                        </div>
                                      </div>
                                    </ng-template>
                                </li>
                                
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                          </div>
                        </div>
                      </div>
                  </div>
                </ng-template>
            </li> -->
         
            
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>