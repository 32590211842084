import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from '../../master.service';
import { EditReasonComponent } from '../edit-reason/edit-reason.component';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  button = 'Update';
  showButton = false;
  isLoading = false;
  submitted: boolean = false; 
  projectEditForm: FormGroup;
  projectOldEditForm: FormGroup;
  selectedOthers: any;
  othersSelect: boolean = false;
  othersSelectnature: boolean = false;
  selectedOthersupsi: any;
  projectId: any;
  accessSuccess: string;
  rollListarray = [];
  listRoleName: any;
  roleEditList: any;
  category: any;
  measurements: any;
  roleNameFilter = [];
  dropdownList = [];
  selectedItems = [];
  selectedOldItems = [];
  dropdownSettings = {};
  editReason = '';
  constructor(private fb: FormBuilder, private masterService: MasterService, public router: Router, private toastr: ToastrService, private activateRouter:ActivatedRoute, private modalService: NgbModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

   createRoleList(){
    return this.fb.group({roleListItem: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.projectId = {'id': data['id']};
            
      
    })
    if(this.projectId !==''){
      this.masterService.editProject(this.projectId)
      .toPromise().then(result =>{  
    const roleEditLists = result?.data[0].ProjectAccess;
        const listRoleNames = roleEditLists.map(({role_id, role_name })=>({id:role_id, itemName: role_name}))

        if(listRoleNames != ''){
        if(listRoleNames[0].id !== null && listRoleNames[0].itemName !== null){
          listRoleNames.forEach(result =>this.selectedItems.push(result))
           listRoleNames.forEach(result =>this.selectedOldItems.push(result))
           this.projectOldEditForm.get('roleListItem').setValue(this.selectedOldItems);
        }else{
          this.selectedItems = [];        

        }
      }else{
        this.selectedOldItems = [];
        this.projectOldEditForm.get('roleListItem').setValue(this.selectedOldItems);
      }
      // roleEditLists.map(resStr => {
      //   let filteredItem = this.listRoleName?.filter(listRoleName => listRoleName.role == resStr)[0];
      //   if(filteredItem && filteredItem.id){
      //     this.roleEditList.push(filteredItem);
      //   }
      // })

        
        this.projectEditForm.patchValue(result?.data[0]);      
        this.projectOldEditForm.patchValue(result?.data[0]);

        this.projectEditForm.statusChanges.subscribe(status => {
          const isEqual = this.areObjectsEqual(this.projectEditForm.value, this.projectOldEditForm.value);
          // const isEqual1 = this.areArraysEqual(this.selectedItems, this.selectedOldItems);
          if(!isEqual){
            this.showButton = true;
          }else{
            this.showButton = false;
          }
        });
        
        // this.projectOldEditForm.roleListItem.patchValue(result?.data[0]);  
        // this.projectEditForm.get('roleListItem').valueChanges.subscribe((roleListValue) => {
        //   console.log('roleListItem value changed:', roleListValue);
        //   // You can perform actions based on the changed 'roleListItem' value
        // });
        
        
      })

      


    this.projectEditForm = this.fb.group({
      project_name: new FormControl ('', [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      durationfrom: new FormControl ('', Validators.required),
      durationto: new FormControl ('', [this.dateRangeValidator]),
      // roleListItem: this.fb.array([this.createRoleList()]),
      roleListItem: new FormControl('')
    });
    this.projectOldEditForm = this.fb.group({
      project_name: new FormControl ('', [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      durationfrom: new FormControl ('', Validators.required),
      durationto: new FormControl ('', [this.dateRangeValidator]),
      // roleListItem: this.fb.array([this.createRoleList()]),
      roleListItem: new FormControl('')
    });

    // this.projectEditForm.valueChanges.subscribe((formValue) => {
    //   console.log('Form value changed:', formValue);
    //   // You can perform actions based on the changed form values
    // });

    this.rollListarray = [];
    this.roleNameFilter = [];
    this.masterService.getRoles(null).subscribe(data =>{
      let resp = data.body['data'];
      
     const listRoleName = resp.map(({id,role_name })=>({id, itemName: role_name}))
     this.dropdownList = [];
     this.roleNameFilter = [];
     listRoleName.forEach(result =>this.dropdownList.push(result)) 
     from(this.dropdownList)
     .pipe(
       tap(item => item),
       distinct(d => d)
     )
     .subscribe(r => this.roleNameFilter.push(r));
    })

    this.selectedItems = [];
    this.selectedOldItems=[];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select All Applicable User Roles',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
  }

  

  }

  areArraysEqual(array1: any[], array2: any[]): boolean {
    return JSON.stringify(array1) === JSON.stringify(array2);
  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.projectEditForm && this.projectEditForm.get("durationfrom").value;
    const to = this.projectEditForm && this.projectEditForm.get("durationto").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
//Multi select box
onItemSelect(item: any) {
}
OnItemDeSelect(item: any) {
}
onSelectAll(items: any) {
}
onDeSelectAll(items: any) {
}
  // get categoryArray(): FormArray {
  //   return this.projectEditForm.get("roleListItem") as FormArray;
  // }
  // onChange(index: number, id: string, role: string, isChecked: boolean) {
  //   if (isChecked) {
   
  //     this.projectEditForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem.push({ id: id, role: role });
     

  //   } else {
     
  //     this.projectEditForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem = this.projectEditForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem.filter(item => id !== item.id);
  //   }
  // }

  // getcategory() {
  //   this.category = {    
  //     roleListItem: [
  //       {
  //         roleListItem:  [this.measurements],
        
  //       },
      
  //     ]

  //   };
    
  //   // this.category.push(this.createRoleList());
  //   //console.log("roleEditListqq", this.category)

  //   //this.projectEditForm.controls["name"].setValue(this.category.name);
  //   for (let i = 0; i <  this.measurements?.length; i++) {
  //     if (i !== 0) {
  //       const control = <FormArray>(
  //         this.projectEditForm.controls["roleListItem"]
  //       );
  //       control.push(this.createRoleList());
  //     }
  //     // this.roleListItem
  //     // .at(i)
  //     // .get("label")
  //     // .setValue(this.roleListItem[i].label);
  //   }
  // }
  // get roleListItem(): FormArray {
  //   return this.projectEditForm.get("roleListItem") as FormArray;
  // }
  // inputChecked(i: any, data: any) {

  //   let checked = false;
    
  //   //console.log('data = ', data);
  //   for (
  //     let l = 0;
  //     l < this.measurements?.length;
  //     l++
  //   ) {

  //     let temp = this.measurements[l];
  //     let temp2;
  //      //console.log('inside =',temp);

  //     if (temp.id == data.id && temp.role == data.role) {
  //       if(checked = true){
  //         this.projectEditForm.controls.roleListItem.value[
  //           i
  //         ].roleListItem.push({ id: temp.id, role: temp.role });
         
    
  //       } else {
         
  //         this.projectEditForm.controls.roleListItem.value[
  //           i
  //         ].roleListItem = this.projectEditForm.controls.roleListItem.value[
  //           i
  //         ].roleListItem.filter(item => temp.id !== item.id);
  //       }
       
        
  //             }
  //   }
  //   return checked;
  // }
  cancelProject(){
    this.router.navigate(['/setup/project-list']);
    this.submitted = false;
    this.projectEditForm.reset();
  }
  areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);
  
    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};

    for (const key in objA) {
      if(key != 'id'){
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {
          
            resultA[key] = objA[key];
          
        }
      }
    }

    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }
   
    return {
      "oldData": resultA,      
      "newData": resultB
    };
   
  }
  openModalEditReason() {
    const emptyFileds = Object.keys(this.projectEditForm.controls).filter(key =>this.projectEditForm.controls[key].errors !== null)
    if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fields.");
      return false;
    }else{
      if(this.projectEditForm.get('project_name').value == ''){
        this.toastr.warning("Please fill the Project/Event Name.");
        return false;
      }else if(this.projectEditForm.get('durationfrom').value == ''){
        this.toastr.warning("Please fill the Start Date.");
        return false;
      }else if(this.projectEditForm.get('durationto').value != ''){
        if(this.projectEditForm.get('durationfrom').value > this.projectEditForm.get('durationto').value){
          this.toastr.warning("Start Date cannot be greater than End date.");
          return false;
        }
      }
    }
    const isEqual = this.areObjectsEqual(this.projectEditForm.value, this.projectOldEditForm.value);
    // const isEqual2 = this.areObjectsEqual(this.selectedItems, this.selectedOldItems);
    if(!isEqual){
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        this.editReason=result;
        if(this.editReason != 'dismiss'){
          this.onSubmit();
        }
      }, (reason) => {
      });
    }
    
  }

  onSubmit(){
    
      this.submitted = true;
      this.projectEditForm.value.id = this.projectId['id'];
      const changedKeyValues = this.findChangedKeyValues(this.projectOldEditForm.value,this.projectEditForm.value);
        // const changedKeyValues2 = this.findChangedKeyValues(this.selectedItems, this.selectedOldItems);
        
        let reqObj = {
          data:changedKeyValues,
          // data2:changedKeyValues2,
          id:this.projectEditForm.value.id,
          editReason:this.editReason
        }
      if(this.projectEditForm.status !== 'INVALID'){
        this.isLoading = true;
        this.button = 'Processing';

        this.masterService.updateProject(reqObj).subscribe(data =>{
        this.button = 'Update';
        this.isLoading = false;
          this.toastr.success(" Project Successfully updated");
          this.router.navigate(['/setup/project-list']);
      
      })
      
      }else {
        this.toastr.warning("Invalid Field....");
      }
       
  }
}