import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../company.service';
import { EditReasonComponent } from '../../project/edit-reason/edit-reason.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  button = 'Update';
  isLoading = false;
  companyEditForm: FormGroup;
  companyOldEditForm: FormGroup;
  companyId: any;
  submitted: boolean = false;
  accessSuccess: string;
  accessUser: string;
  expiryDate: boolean = false;
  accessModelController: any;
  pretradecountaccess = false;
  showButton = false;
  editReason = '';
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  preclearanceAccess:boolean = false;
  user_roles: any;
  constructor(private fb: FormBuilder, private router: Router, private activateRouter: ActivatedRoute, private toastr: ToastrService, private companyService: CompanyService,
    private modalService: NgbModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');

    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
    this.accessUser = sessionStorage.getItem('access_user');
  }

  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
    let accessData = sessionStorage.getItem('model_access_controller');
    this.accessModelController = JSON.parse(accessData);


    this.activateRouter.params.subscribe(data => {
      this.companyId = { 'id': data['id'] }
    })
    if (this.companyId['id'] !== '') {
      this.companyService.editCompany(this.companyId)
        .toPromise().then(result => {
          this.companyEditForm?.patchValue(result.data[0]);
          this.companyOldEditForm?.patchValue(result.data[0]);

          this.companyEditForm.statusChanges.subscribe(status => {
            const isEqual = this.areObjectsEqual(this.companyEditForm.value, this.companyOldEditForm.value);
            // const isEqual1 = this.areArraysEqual(this.selectedItems, this.selectedOldItems);
            if (!isEqual) {
              this.showButton = true;
            } else {
              this.showButton = false;
            }
          });

        })
      this.companyEditForm = this.fb.group({
        company_name: new FormControl('', Validators.required),
        sub_status: new FormControl(''),
        // expiryDate: new FormControl (''),
        sub_validity: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
        registered_office: new FormControl('', Validators.required),
        corporate_office: new FormControl(''),
        mobileno: new FormControl('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
        landlineno: new FormControl(''),
        cin: new FormControl('', Validators.required),
        GSTNumber: new FormControl(''),
        PANNumber: new FormControl(''),
        bse_scrip_code: new FormControl(''),
        nse_scrip_code: new FormControl(''),
        isin: new FormControl(''),
        compliance_officer_name: new FormControl('', Validators.required),
        designation: new FormControl(''),
        complianceofficeremail: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
        companyshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        preferenceshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        warrantsshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        convertibleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        nonconvertibleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        rightentitleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        othersshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        customertype: new FormControl(''),
        predaycount: new FormControl('', [Validators.pattern("^[0-7]*$"), Validators.maxLength(1)]),
        installation_date: new FormControl('')

      });

      this.companyOldEditForm = this.fb.group({
        company_name: new FormControl('', Validators.required),
        sub_status: new FormControl(''),
        sub_validity: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
        registered_office: new FormControl('', Validators.required),
        corporate_office: new FormControl(''),
        mobileno: new FormControl('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
        landlineno: new FormControl(''),
        cin: new FormControl('', Validators.required),
        GSTNumber: new FormControl(''),
        PANNumber: new FormControl(''),
        bse_scrip_code: new FormControl(''),
        nse_scrip_code: new FormControl(''),
        isin: new FormControl(''),
        compliance_officer_name: new FormControl('', Validators.required),
        designation: new FormControl(''),
        complianceofficeremail: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
        companyshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        preferenceshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        warrantsshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        convertibleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        nonconvertibleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        rightentitleshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        othersshares: new FormControl('', Validators.pattern("^[0-9]*$")),
        customertype: new FormControl(''),
        predaycount: new FormControl('', [Validators.pattern("^[0-7]*$"), Validators.maxLength(1)]),
        installation_date: new FormControl(''),

      });
    }
    //access expiry date

    if (this.accessUser == 'sdd@legalitysimplified.com') {
      var eleman = document.getElementById('expiryDate');
      eleman.removeAttribute("disabled");
      var eleman2 = document.getElementById('customertype');
      eleman2.removeAttribute("disabled");
    } else {
      var eleman = document.getElementById('expiryDate');
      eleman.setAttribute("disabled", '');
      var eleman2 = document.getElementById('customertype');
      eleman2.setAttribute("disabled", '');
    }

    if (this.accessModelController.tradecount_access == true || this.accessModelController.tradecount_access == 1) {
      this.pretradecountaccess = true;
    } else {
      this.pretradecountaccess = true;
    }
    if(this.accessModelController.preclearance_access == true || this.accessModelController.preclearance_access == 1){
      this.preclearanceAccess = true;
    }else{
      this.preclearanceAccess = true;
    }
  }
  cancelProject() {
    this.router.navigate(['/setup/company-profile']);
    this.companyEditForm.reset();
  }
  areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);

    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};

    for (const key in objA) {
      if (key != 'id') {
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {

          resultA[key] = objA[key];

        }
      }
    }

    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }

    return {
      "oldData": resultA,
      "newData": resultB
    };
  
  }
  openModalEditReason() {
    if (this.companyEditForm.controls.email.errors?.pattern != undefined) {
      this.toastr.warning('Company Email Id is InValid')
      return false;
    } else if (this.companyEditForm.controls.mobileno?.errors?.pattern != undefined) {
      this.toastr.warning('Enter Contact Number accept number format only')
      return false;
    } else if (this.companyEditForm.controls.complianceofficeremail.errors?.pattern != undefined) {
      this.toastr.warning('Compliance Officer Email Id is InValid')
      return false;
    }
    let idendiferCard = this.companyEditForm.get('PANNumber').value;
    if (this.companyEditForm.get('PANNumber').value != '') {
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }

    const emptyFileds = Object.keys(this.companyEditForm.controls).filter(key => this.companyEditForm.controls[key].errors !== null)
    if (emptyFileds.length > 1) {
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    } else {
      if (this.companyEditForm.get('company_name').value == '') {
        this.toastr.warning("Please fill the Company Name.");
        return false;
      }else if (this.companyEditForm.get('registered_office').value == '') {
        this.toastr.warning("Please fill the Registered Office Address.");
        return false;
      }else if (this.companyEditForm.get('email').value == '') {
        this.toastr.warning("Please fill the Email ID.");
        return false;
      }else if (this.companyEditForm.get('mobileno').value == '') {
        this.toastr.warning("Please fill the Contact Number.");
        return false;
      }else if (this.companyEditForm.get('cin').value == '') {
        this.toastr.warning("Please fill the CIN Number.");
        return false;
      }else if (this.companyEditForm.get('complianceofficeremail').value == '') {
        this.toastr.warning("Please fill the Compliance Officer Email ID.");
        return false;
      }else if(this.companyEditForm.get('compliance_officer_name').value == ''){
        this.toastr.warning("Please fill the Compliance Officer Name.");
        return false;
      }
    }
    const isEqual = this.areObjectsEqual(this.companyEditForm.value, this.companyOldEditForm.value);
    // const isEqual2 = this.areObjectsEqual(this.selectedItems, this.selectedOldItems);
    if (!isEqual) {
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        this.editReason = result;
        if (this.editReason != 'dismiss') {
          this.onSubmit();
        }
      }, (reason) => {
      });
    }

  }
  onSubmit() {
    this.submitted = true;
    this.companyEditForm.value.id = this.companyId['id'];

    const changedKeyValues = this.findChangedKeyValues(this.companyOldEditForm.value, this.companyEditForm.value);
    // const changedKeyValues2 = this.findChangedKeyValues(this.selectedItems, this.selectedOldItems);

    let reqObj = {
      data: changedKeyValues,
      // data2:changedKeyValues2,
      id: this.companyEditForm.value.id,
      editReason: this.editReason
    }
    if (this.companyEditForm.status !== 'INVALID') {
      this.isLoading = true;
      this.button = 'Processing';
      this.companyService.updateCompany(reqObj).subscribe(data => {

        this.toastr.success("Company Profile Successfully Updated");
        this.isLoading = false;
        this.button = 'Update';
        this.router.navigate(['/setup/company-profile']);
        // if (this.agendaCreateForm.valid) {
        //   this.toastr.success("Agent Items details created", "Success!");
        // } else {
        //   this.toastr.warning("Agent Items details not created", "Alert!");
        // }
      })
    }
  }

}
