import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseUrl = environment.apiUrl;
  paramQuery: any;

  constructor(private http:HttpClient) { }

  ChangePasswordUser(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "user/auth-changePassword", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    });
  }

  rejectAnnualConfirmReport(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "dp/annualconfirmreject", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
  }

  confirmAnnualConfirmReport(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "dp/annualconfirmentry", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
  }
}
