import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AddTradingWindowClosureComponent } from './trading-window-closure/add-trading-window-closure/add-trading-window-closure.component';
import { DeleteTradingWindowClosureComponent } from './trading-window-closure/delete-trading-window-closure/delete-trading-window-closure.component';
import { EditTradingWindowClosureComponent } from './trading-window-closure/edit-trading-window-closure/edit-trading-window-closure.component';
import { ListTradingWindowClosureComponent } from './trading-window-closure/list-trading-window-closure/list-trading-window-closure.component';
import { ViewTradingWindowClosureComponent } from './trading-window-closure/view-trading-window-closure/view-trading-window-closure.component';
import { AddPreClearanceComponent } from './pre-clearance/add-pre-clearance/add-pre-clearance.component';
import { EditPreClearanceComponent } from './pre-clearance/edit-pre-clearance/edit-pre-clearance.component';
import { ViewPreClearanceComponent } from './pre-clearance/view-pre-clearance/view-pre-clearance.component';
import { ListPreClearanceComponent } from './pre-clearance/list-pre-clearance/list-pre-clearance.component';
import { DeletePreClearanceComponent } from './pre-clearance/delete-pre-clearance/delete-pre-clearance.component';
import { ExcelReportComponent } from './excel-report/excel-report.component';
import { ExcelMultiReportComponent } from './excel-multi-report/excel-multi-report.component';
import { BenposDashboardComponent } from './benpos-dashboard/benpos-dashboard.component';
import { AddBenposReportComponent } from './add-benpos-report/add-benpos-report.component';
import { FormCAndFormDComponent } from './formCandD/form-c-and-form-d/form-c-and-form-d.component';
import { ListFormCAndFormDComponent } from './formCandD/list-form-c-and-form-d/list-form-c-and-form-d.component';
import { EditFormCAndFormDComponent } from './formCandD/edit-form-c-and-form-d/edit-form-c-and-form-d.component';
import { ViewFormCAndFormDComponent } from './formCandD/view-form-c-and-form-d/view-form-c-and-form-d.component';
import { DeleteFormCAndFormDComponent } from './formCandD/delete-form-c-and-form-d/delete-form-c-and-form-d.component';
import { PreClearanceTradingComponent } from './pre-clearance/pre-clearance-trading/pre-clearance-trading.component';
import { AddPreviousTradingComponent } from './pre-clearance/add-previous-trading/add-previous-trading.component';
import { DisclosurePreclearanceComponent } from './pre-clearance/disclosure-preclearance/disclosure-preclearance.component';
import { DuplicatePreclearanceComponent } from './pre-clearance/duplicate-preclearance/duplicate-preclearance.component';

const routes: Routes = [
  {
    path: '',   
    children: [ 
        {
            path: '',
            component: ListTradingWindowClosureComponent,
            data: {
              title: "PIT Compliance",
              breadcrumb: "Trading Window Closure"
            }
          },
      {
        path: 'list-twc',
        component: ListTradingWindowClosureComponent,
        data: {
          title: "PIT Compliance",
          breadcrumb: "Trading Window Closure"
        }
      },  
      {
        path: 'add-twc',
        component: AddTradingWindowClosureComponent,
        data: {
          title: "PIT Compliance",
          breadcrumb: "Add Trading Window Closure"
        }
      },
      {
        path: 'edit-twc/:id',
        component: EditTradingWindowClosureComponent,
        data: {
          title: "PIT Compliance",
          breadcrumb: "Edit Trading Window Closure"
        }
      },
      {
        path: 'view-twc/:id',
        component: ViewTradingWindowClosureComponent,
        data: {
          title: "PIT Compliance",
          breadcrumb: "view Trading Window Closure"
        }
      },
      {
        path: 'delete-twc/:id',
        component: DeleteTradingWindowClosureComponent,
        data: {
          title: "PIT Compliance",
          breadcrumb: "Delete Trading Window Closure"
        }
      },
      {
        path: 'list-pre-clearance',
        component: ListPreClearanceComponent,
        data: {
          title: "Pre Clearance",
          breadcrumb: "Pre Clearance"
        }
      }, 
      {
        path: 'add-pre-clearance',
        component: AddPreClearanceComponent,
        data: {
          title: "Add Pre Clearance",
          breadcrumb: "Add Pre Clearance"
        }
      }, 
      {
        path: 'edit-pre-clearance/:id',
        component: EditPreClearanceComponent,
        data: {
          title: "Edit Pre Clearance",
          breadcrumb: "Edit Pre Clearance"
        }
      }, 
      {
        path: 'duplicate-pre-clearance/:id',
        component: DuplicatePreclearanceComponent,
        data: {
          title: "Edit Pre Clearance",
          breadcrumb: "Edit Pre Clearance"
        }
      }, 
      {
        path: 'view-pre-clearance/:id',
        component: ViewPreClearanceComponent,
        data: {
          title: "View Pre Clearance",
          breadcrumb: "view Pre Clearance"
        }
      }, 
      {
        path: 'delete-pre-clearance/:id',
        component: DeletePreClearanceComponent,
        data: {
          title: "Delete Pre Clearance",
          breadcrumb: "Delete Pre Clearance"
        }
      },
      {
        path: 'trading-pre-clearance/:id',
        component: PreClearanceTradingComponent,
        data: {
          title: "Trading History",
          breadcrumb: "Trading History"
        }
      },
      {
        path: 'benpos-report',
        component: ExcelReportComponent,
        data: {
          title: "BENPOS Report",
          breadcrumb: "BENPOS Report"
        }
      }, 
      {
        path: 'benpos-list',
        component: ExcelMultiReportComponent,
        data: {
          title: "BENPOS List",
          breadcrumb: "BENPOS List"
        }
      }, 
      {
        path: 'benpos-reports/:id',
        component: BenposDashboardComponent,
        data: {
          title: "BENPOS Reports",
          breadcrumb: "BENPOS Reports"
        }
      }, 
      {
        path: 'add-benpos-report',
        component: AddBenposReportComponent,
        data: {
          title: "ADD BENPOS Reports",
          breadcrumb: "ADD BENPOS Reports"
        }
      }, 
      {
        path: 'add-trading-report/:name',
        component: FormCAndFormDComponent,
        data: {
          title: "Trading Report",
          breadcrumb: "Trading Report"
        }
      }, 
       
      {
        path: 'edit-trading-report/:id',
        component: EditFormCAndFormDComponent,
        data: {
          title: "Trading Report",
          breadcrumb: "Trading Report"
        }
      }, 
      {
        path: 'list-trading-report',
        component: ListFormCAndFormDComponent,
        data: {
          title: "Trading Report",
          breadcrumb: "Trading Report"
        }
      }, 
      {
        path: 'view-trading-report/:id',
        component: ViewFormCAndFormDComponent,
        data: {
          title: "Trading Report",
          breadcrumb: "Trading Report"
        }
      }, 
      {
        path: 'add-trading-transacrion/:id',
        component: DisclosurePreclearanceComponent,
        data: {
          title: "Add Trading Transaction",
          breadcrumb: "Add Trading Transaction"
        }
      }, 

      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class pitRoutingModule { }
