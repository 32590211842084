import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { distinct, from, tap } from 'rxjs';
import { MasterService } from '../../master.service';
import { PopupModelComponentComponent } from '../../project/popup-model-component/popup-model-component.component';
import { DeleteProjectComponent } from '../delete-project/delete-project.component';
import { environment } from 'src/environments/environment';
import { DisclaimerexcelComponent } from '../../popup/disclaimerexcel/disclaimerexcel.component';
export interface dp{
  id: number;
  project_name: string;
}

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss']
})
export class ListProjectComponent implements OnInit {
  @ViewChild('auto') auto;
  closeResult = '';
  public newUser = false;
  public selected = [];
  page = 1;
  pageSize = 10;
  listProject: any;
  accessSuccess: string;
  filterString = "";
  filterString2 = "";
  filtered;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;
  viewProjectList: any;
  projectId: any;
  project_read: any;
  hidelist = [];
  selectedhideItems = [];
  selected_fromDate:any;
  selected_toDate:any;
  selected_projectName: any;
  show_fromDate=false;
  show_toDate=false;
  show_projectName = true;
  show_completedproject = true;
  dropdownSettings1:IDropdownSettings;
  visible: boolean = false;
  visible2:boolean = false
  keyword = 'project_name';
  keyword2 = 'project_name';
  nameFilterString= '';
  nameFilterString2= '';
  completedprojectNameFilter = []
  projectNameFilter: any[];
  selected_project=[];
  selected_compaletedproject=[];
  dropdownList = [];
  completedProjetctdropdown = [];
  projectList: any;
  currentDate = new Date();
  myDate: any;
  dates: Date;
  myDates: any;
  completedProjects: any;
  completed_project = [];
  filtered2: any;
  public active1 = 1;
  public active2 = 3;
  userType: string;
  baseUrl = environment.apiUrl;
  dropdownListCompleted: any[];
  constructor(private datePipe: DatePipe, private masterService: MasterService, public router: Router, private modalService: NgbModal, private activateRouter: ActivatedRoute,
    ) { 

    this.accessSuccess = sessionStorage.getItem('access_success');
    this.userType = sessionStorage.getItem('sdd_user_type');
    
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);
    }
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    
  }

  ngOnInit(): void {    
    

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    
    if(this.user_roles.project_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.project_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }
    // this.masterService.projectAccess().subscribe(
    //   res => {
    //   this.project_read = res.body['data']
    //     },
    //     err => {
    //     }
    // );


    this.masterService.projectList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          const allProjects = res.body['data'];
          let activeProjects = [];
          this.completedProjects = [];
          let completedProjectItems = [];
          allProjects.map((projectItem) => {
            if(projectItem.durationto < this.myDate){            
             
              completedProjectItems.push(projectItem); 
                
            } else {
              activeProjects.push(projectItem);
             
           
            }
          });
        
          this.listProject = activeProjects;
          this.completedProjects = completedProjectItems;   
        

      let projectName = this.listProject?.map(res =>res.project_name)
      this.projectNameFilter = [];
      from(projectName)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.projectNameFilter.push(r));
    this.projectList = this.listProject.map(({id,project_name}) =>({id,name: project_name}));
    this.dropdownList = [];
    //Project Filter
    from(this.projectList)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dropdownList.push(r));
    //completed project
    let completedProjects = this.completedProjects.map(({id,project_name}) =>({id,name: project_name}));
    this.dropdownListCompleted = [];
    //Project Filter
    from(completedProjects)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.dropdownListCompleted.push(r));
    
//Completed Project completedProjetctdropdown
let completedProjectName = this.completedProjects?.map(res =>res.project_name)
      this.completedprojectNameFilter = [];
      from(completedProjectName)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.completedprojectNameFilter.push(r));
const completeprojectList = this.completedProjects.map(({id,project_name}) =>({id,name: project_name}));
    this.completedProjetctdropdown = [];
    //Project Filter
    from(completeprojectList)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.completedProjetctdropdown.push(r));

      this.filtered = this.listProject
      this.filtered2 = this.completedProjects;
      
     
      // this.project_read= res.body['mod']
        this.onFilterChange();
        this.onFilterChange2();
        
        }
        },
        err => {
            console.log(err);
        }
    );
    this.hidelist = [{"id":1,"name":"Project Name"},{"id":2,"name":"From Date"},{"id":3,"name":"To Date"}];
    this.selectedhideItems = [{"id":1,"name":"Project Name"}];



    // this.activateRouter.params.subscribe(data => {
    //   this.projectId = data['id']
    // })
    // if (this.projectId !== '') {
    //   this.masterService.viewProject(this.projectId).subscribe(
    //     data =>{
    //     this.viewProjectList = data;
      
    //   })

    //   }

      //multiseclector
  this.selected_project = [];
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };  

  }
  selectEvent(item) {
    this.filtered = this.listProject?.filter(upsiItem => {
      return upsiItem.project_name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible = false;
  }
  selectEvent2(item) {
  
    this.filtered2 = this.completedProjects?.filter(upsiItem => {
      return upsiItem.project_name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible2 = false;
  }
  onCleared(e) {
    //console.log('input cleared');
    this.filtered = this.listProject;
  }
  onCleared2(e) {
    this.filtered2 = this.completedProjects;
  }
  
  onBlur(e) {
    this.visible = false;
  }
  onBlur2(e) {
    this.visible2 = false;
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    // console.log("String: " + search);
   
  }

  onFocused(e) {
    // do something
  }
  
  onScrolledEnd() {
    // this.auto.close();
  }
  onFilter(){
    let queryString = '';
    let url = this.baseUrl + 'projectexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'projectreport.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  filterData() {
    this.visible = !this.visible;
  }
  filterData2() {
    this.visible2 = !this.visible2;
  }
  //MultiSelect hideShow
onItemSelect(item: any,page) {
  if(page == "Contact Number"){
   if(!this.selected_fromDate.includes(item)){
     this.selected_fromDate.push(item);
   }
 }else if(page == "Address"){
   if(!this.selected_toDate?.includes(item)){
     this.selected_toDate.push(item);
   }
 }else  if(page == "project"){
  if(!this.selected_projectName?.includes(item)){
    this.selected_projectName.push(item);
    this.show_projectName = true;
  }
}else if(page == "column"){
  if(item.id == 1){
    this.show_projectName = true;
    this.selectedhideItems.push(item)
  }else if(item.id == 2){
     this.show_fromDate = true;
   }else if(item.id == 3){
     this.show_toDate = true;
   }
 } else if(page == "projectItem"){
  let filteredItems = [];
  let regex = /\(/;
  if(this.selected_project.length > 0){
    this.selected_project.map(res =>{
      this.listProject?.map(proj =>{         
        // if(proj.project_name.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
        if(proj.project_name == res.name) {
          filteredItems.push(proj);

        }
      });
    });
  } else {
    filteredItems = this.listProject;
  }
  this.filtered = filteredItems;
}else if(page == "completedProjectItem"){
  let filteredItemsCompleted = [];
  if(this.completed_project.length > 0){
    this.completed_project.map(res =>{
      this.completedProjects?.map(proj =>{    
        // if(proj.project_name.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
          if(proj.project_name == res.name) {
          filteredItemsCompleted.push(proj);
        }
      });
    });
  } else {
    filteredItemsCompleted = this.completedProjects;
  }
  this.filtered2 = filteredItemsCompleted;
 }

}

onItemDeSelect(item: any,page) {

if(page == "Contact Number"){
   if(this.selected_fromDate.includes(item)){
     this.selected_fromDate.splice(this.selected_fromDate.indexOf(item), 1);
   }
 }else if(page == "Address"){
   if(this.selected_toDate.includes(item)){
     this.selected_toDate.splice(this.selected_toDate.indexOf(item), 1);
   }
 }else if(page == "project"){
  if(this.selected_projectName.includes(item)){
    this.selected_projectName.splice(this.selected_projectName.indexOf(item), 1);
  }
}else if(page == "column"){
  if(item.id == 1){
    this.show_projectName = false;
  }else if(item.id == 2){
     this.show_fromDate = false;
   }else if(item.id == 3){
     this.show_toDate = false;
   }
 } else if(page == "projectItem"){
  let filteredItems = [];
  if(this.selected_project.length > 0){
    this.selected_project.map(res =>{
      this.listProject?.map(proj =>{    
       // if(proj.project_name.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
        if(proj.project_name == res.name) {
          filteredItems.push(proj);
        }
      });
    });
  } else {
    filteredItems = this.listProject;
  }
  this.filtered = filteredItems;

}else if(page == "completedProjectItem"){
  let filteredItemsCompleted = [];
  if(this.completed_project.length > 0){
    this.completed_project.map(res =>{
      this.completedProjects?.map(proj =>{    
       // if(proj.project_name.toLocaleLowerCase().match(res.name.toLocaleLowerCase())) {
        if(proj.project_name == res.name) {
          filteredItemsCompleted.push(proj);
        }
      });
    });
  } else {
    filteredItemsCompleted = this.completedProjects;
  }
  this.filtered2 = filteredItemsCompleted;
 }


}
onSelectAll(items: any,page) {
 if(page == "Contact Number"){
   this.selected_fromDate=items;
 }else if(page == "Address"){
   this.selected_toDate=items;
 }if(page == "project"){
  this.selected_projectName=items;
}else if(page == "column"){
   this.show_fromDate = true;
   this.show_toDate = true; 
   this.show_projectName = true;
 }else  if(page == "projectItem"){
  this.selected_project=items;
  this.selected_project = this.listProject.map(x => x.id);
 
 } else if(page == "projectItem"){
  this.filtered = this.listProject;
}else if(page == "completedProjectItem"){
  this.filtered2 = this.completedProjects;
 }

}
onDeSelectAll(items: any,page) {
if(page == "Contact Number"){
   this.selected_fromDate=[];
 }else if(page == "Address"){
   this.selected_toDate=[];
 }else if(page == "Contact Number"){
  this.selected_projectName=[];
}else if(page == "column"){
   this.show_fromDate = false;
   this.show_toDate = false;
   this.show_projectName = false;
 } else if(page == "projectItem"){  
  this.filtered = this.listProject;

 } else if(page == "completedProjectItem"){
  this.filtered2 = this.completedProjects;
 }
}

  onFilterChange() {
    
    this.filtered = this.listProject?.filter(res =>{    
     return res.project_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) ||
     res.Record.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
    }) 
   
  }
  onFilterChange2() {
    this.filtered2 = this.completedProjects?.filter(res =>{    
     return res.project_name.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase()) ||
     res.Record.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase());
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
    
  }

  //Model Popup
open(content, items) {
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    },
  );
 

}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


openModal(id:any) {
  if (id) {
    let projectid ={'id': id}
    this.masterService.viewProject(projectid).subscribe(
      ({data}) =>{
        const modalRef = this.modalService.open(PopupModelComponentComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
      
           
        modalRef.componentInstance.viewProjectList = data[0];
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    })

    }
  
}
openModalDelete(id:any) {
  if (id) {
    let ptojectid = {'id': id}
    this.masterService.viewProject(ptojectid).subscribe(
      ({data}) =>{
        const modalRef = this.modalService.open(DeleteProjectComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
      
           
        modalRef.componentInstance.viewprojectList = data[0];
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    })

    }
  
}
openExcelDisclaimer() {

    const modalRef = this.modalService.open(DisclaimerexcelComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
      modalRef.componentInstance.name = 'Alert';
      modalRef.componentInstance.title = 'Project';
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
      if(receivedData == 'Yes'){
        this.onFilter();
      }
    }, (reason) => {
    });
  
  
}

}
