<!-- Container-fluid starts -->
<div class="container-fluid">
   
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <!-- <h5>Log UPSI Transfer History</h5> -->
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                             required >
                                                
                    </div>
                        </div>
                        <a routerLink="/upsi/upsi-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="name">S.No</th>
                                    <th scope="col" sortable="name">Action </th>
                                    <th scope="col" sortable="name">By Whom</th>
                                    <th scope="col" sortable="name">Updated date</th>
                                    <th scope="col" sortable="gender">Nature of UPSI</th>
                                    <th scope="col" sortable="gender">Project name</th>
                                    <th scope="col" sortable="name">View Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of filtered | keyvalue | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row">{{items.value.action_flag}}</td>
                                <td scope="row">{{items.value.sender}}</td>
                                <td scope="row">{{items.value.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                <td scope="row">{{items.value.nature_of_upsi}}</td>    
                                <td scope="row">{{items.value.project_name}}</td>
                                <td><button class="edit-icon" title="View" (click)="openModal(items.value.id)"><i class="fa fa-eye"></i></button></td>
                                
                            </tbody>
                        </table>
                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.data?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->
