<div class="modal-header">
    <h5 class="modal-title">{{name}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  
<div class="container-fluid">
    <div class="row">
        <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
              <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                  <span class="bs-stepper-circle">1</span>
                  <span class="bs-stepper-label">UPLOAD DATA</span>
                </button>
              </div>
              <div class="line"></div>
              <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                  <span class="bs-stepper-circle">2</span>
                  <span class="bs-stepper-label">VERIFY INFO</span>
                </button>
              </div>
              <!-- <div class="line"></div>
              <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                  <span class="bs-stepper-circle">3</span>
                  <span class="bs-stepper-label">ATTACH AND SAVE</span>
                </button>
              </div> -->
            </div>
            <div class="bs-stepper-content">
              <form>
                <div id="test-l-1" class="content">
                  <div class="description">
                    <h5>Step-1: Download the excel template.</h5>
                    <h5>Step-2: Fill in the downloaded template with accurate data.<br><span>Check the <a href="javascript:void(0);" (click)="getMenuItem()">InSiDDer Help Manual</a> for assistance.</span></h5>
                    <h5>Step-3: Save the template in the same name.</h5>
                    <h5>Step-4: Click on the 'Choose File' Button and upload the edited excel template.</h5>
                    <div class="download-excel">
                        <h3 style="color:#357EC7">1. Download Template</h3>
                        <div class="excel-temp" (click)="export()">
                            <img src="assets/images/excel.png" class="img-responsive excel-data"  alt=" Download Template" title=" Download Template">  Download Template 
                           
                        </div>
                        <h3 style="color:#357EC7">2. UPLOAD File</h3>
                        <div class="excel-temp">
                            <input #userPhoto type="file" class="custom-file-input form-control" accept=".xlsx" id="attachment" (change)="onFileChange($event)"  > 
                            <!-- <input type="file" (change)="onFileChange($event)" (click)="next()" multiple="false" /> -->
                        </div>
                    </div>
                  </div>
                  
                </div>
                <div id="test-l-2" class="content">
                    <div class="scrool-items">
                    <table class="sjs-table">
                        <tbody>
                      <tr *ngFor="let row of data">
                            <td *ngFor="let val of row">
                                {{val}}
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                  <!-- <button (click)="next()" class="btn btn-primary">Next</button> -->
                  <button type="button" [disabled]="isLoading" (click)="submitData()" class="btn btn-primary mt-5"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                </div>
                <!-- <div id="test-l-3" class="content text-center">
                    <form class="drop-zone" [formGroup]="form" file-drop>
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"/></svg>
                        <input id="file" type="file" formControlName="files" (change)="saveFile(form.value.files)" formControlName="files">
                        <label for="file"><strong>Choose a file</strong><span class="dragndrop"> or drag it here</span>.</label>
                      </form>
                      <p class="dragDrop">{{filePathName}}</p>
                      <button type="button" [disabled]="isLoading" (click)="submitData()" class="btn btn-primary mt-5"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                </div> -->
              </form>
            </div>
          </div>
    </div>
</div>

</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
