
<!-- Container-fluid starts -->
<div class="container-fluid">
   
    <div class="row" >
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="mobileNo" autocomplete="off" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()"  placeholder="Search Declaration of Trade" 
                             required >
                                                                         
                    </div>
                        </div>
                             
                    <div class="list-btn"> 
                        <div class="columnHide mb-3">
                            <div class="filter-group">
                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                    [settings]="dropdownSettings1"
                                    [data]="hidelist"
                                    [(ngModel)]="selectedhideItems"
                                    (onSelect)="onItemSelect($event,'column')"
                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                    (onSelectAll)="onSelectAll($event,'column')" 
                                    >
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <a routerLink="/pit-compliance/add-declaration-of-trade" ><button class="btn btn-primary me-1" >New</button></a>
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
                       
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no" >S.No.</th>
                                    <th scope="col" sortable="id.no" *ngIf="show_id">ID No.</th>
                                    <th scope="col" sortable="subject" class="subject" *ngIf="show_subject">Subject <i class="fa fa-filter" (click)="filterData()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="subjectNameFilter"
                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                    (selected)='selectEvent($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="subjectFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_period">Period <i class="fa fa-filter" (click)="filterData2()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="periodNameFilter"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent1($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="periodFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_sender" class="widthColumn">Sender <i class="fa fa-filter" (click)="filterData3()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible3">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="senderNameFilter"
                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="senderFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_recipient" class="widthColumn">Recipient <i class="fa fa-filter" (click)="filterData4()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible4">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="recipientNameFilter"
                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                    (selected)='selectEvent3($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="recipientFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_start_date">Start Date <i class="fa fa-filter" (click)="filterData5()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible5">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="startDateNameFilter"
                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                    (selected)='selectEvent4($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="startDateFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_to_date">End Date <i class="fa fa-filter" (click)="filterData6()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible6">
                                        <div class="filter-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="EndDateNameFilter"
                                                    [searchKeyword]="keyword6" placeholder="Search ..."
                                                    (selected)='selectEvent5($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="endDateFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="period" *ngIf="show_description" class="widthColumn">Description</th>
                                    <th scope="col" sortable="createdOn" *ngIf="show_created_on">Created On</th>
                                    <th scope="col" sortable="action" >Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor =" let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID No." *ngIf="show_id">DoT-{{item.id }}</td>
                                    <td scope="row" data-content="Subject " *ngIf="show_subject">{{item.subject}}</td>
                                    <td scope="row" data-content="Period " *ngIf="show_period">{{item.period}}</td> 
                                    <td scope="row" data-content="Sender" *ngIf="show_sender" >{{item.sender_name}}</td> 
                                    <td scope="row" data-content="Recipient" *ngIf="show_recipient" >{{item.recipient_name}}</td> 
                                    <td scope="row" data-content="Start Date" *ngIf="show_start_date">{{item.start_date}}</td> 
                                    <td scope="row" data-content="End Date" *ngIf="show_to_date">{{item.end_date}}</td> 
                                    <td scope="row" data-content="Description" *ngIf="show_description" [innerHTML]="item.body_content"></td> 
                                    <td scope="row" data-content="Created On" *ngIf="show_created_on">{{item.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                    
                                    <td data-content="Actions">
                                        <button class="btn btn-primary me-1"  (click)="openModalSendMail(item.id)" *ngIf="item.send_mail == 0 || item.send_mail == null">Send Mail</button>
                                        <button class="btn btn-primary me-1"  (click)="openModalSendMail(item.id)" *ngIf="item.send_mail == 1">Resend Mail</button>
                                        <button class="edit-icon" title="View Log"  (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
                                        <a [routerLink]="['/pit-compliance/edit-declaration-of-trade', item.id]" ><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                        <button class="edit-icon" (click)="openModalDelete(item.id)" title="Delete"><i class="fa fa-trash"></i></button>
                                        
                                    </td>   
                            </tr>
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div> 

                     <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div> -->
</div>
<!-- Container-fluid Ends -->





