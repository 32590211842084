import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-add-companyrules',
  templateUrl: './add-companyrules.component.html',
  styleUrls: ['./add-companyrules.component.scss']
})
export class AddCompanyrulesComponent implements OnInit {
  createCompanyRulesForm: FormGroup;
  submitted: boolean = false;
  button = 'Save';
  isLoading = false;
  accessSuccess: string;
  accessUser: string;
  constructor(private fb: FormBuilder, private masterService: MasterService, private router:Router, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    this.accessUser = sessionStorage.getItem('access_user');

   }

  //rules confimation
  createDetailOfRules(){
    return this.fb.group({
      rules_condition: new FormControl(''),
      rules_setting: new FormControl(''),
      rules_value: new FormControl(''),
    })
  }
  addInputControlsRules(){
    // this.procedureCont = true;
    const add = this.createCompanyRulesForm.get('rules_model') as FormArray;
    add.push(this.createDetailOfRules())  
   
  }
  removeInputControlsRules(idx: number){
    // const add = this.createCompanyRulesForm.get('rules_model') as FormArray;  
    // add.removeAt(idx);  
    //if(this.createCompanyRulesForm.get('rules_model').value.length <= 1){
    //   const add = this.createCompanyRulesForm.get('rules_model') as FormArray;    
    //  add.reset();
    
    // }else{
      const add = this.createCompanyRulesForm.get('rules_model') as FormArray;  
      add.removeAt(idx); 
    //} 
  }
  ngOnInit(): void {
    this.createCompanyRulesForm = this.fb.group({
      rule_name: new FormControl('', Validators.required),
      rules_model: new FormArray([this.createDetailOfRules()]),
      rule_status: new FormControl('', Validators.required)
    });
  }
  cancelrules(){
    this.router.navigate(['/setup/rules-list']);
    this.createCompanyRulesForm.reset();
  }
  onSubmit(){
    this.submitted = true;
    if(this.createCompanyRulesForm.get('rules_model')['controls'].length > 0){
      for(let i=0; i<this.createCompanyRulesForm.get('rules_model')['controls'].length; i++){
        if(this.createCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_condition.value == ''){
           this.toastr.warning("Please Enter the "+ [i + 1] + " row Rule Condition.");          
           return false;          
        }else if(this.createCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_setting.value == ''){
          this.toastr.warning("Please Enter the "+ [i + 1] + " row Setting.");          
          return false;          
       }else if(this.createCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_value.value == ''){
        this.toastr.warning("Please Enter the "+ [i + 1] + " row Value.");          
        return false;          
     }
      }
    }
    const emptyFileds = Object.keys(this.createCompanyRulesForm.controls).filter(key =>this.createCompanyRulesForm.controls[key].errors !== null)
    if(this.createCompanyRulesForm.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';
    this.masterService.addCompanyRules(this.createCompanyRulesForm.value).subscribe(data =>{
      this.toastr.success("Company Rules Successfully created");
      this.isLoading = false;
      this.button = 'Save';
      this.router.navigate(['/setup/rules-list']);      
      
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    return false;
  }else{
    if(this.createCompanyRulesForm.get('rule_name').value == ''){
      this.toastr.warning("Please fill the Rule Name.");
    }else  if(this.createCompanyRulesForm.get('rule_status').value == ''){
      this.toastr.warning("Please Select Rule Approved/rejected.");
      return false;
    }
  }
  }

}
