<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD NEW PROJECT/EVENT</h5>
                    <a routerLink="/setup/project-list"><button class="btn btn-primary me-2 "><i
                                class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="projectCreateForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="projectName">Project/Event Name <span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="projectName"
                                        placeholder="Project/Event Name" autocomplete="off"
                                        formControlName="project_name" required
                                        [ngClass]="{ 'is-invalid': (submitted || projectCreateForm.controls.project_name.touched) && projectCreateForm.controls.project_name.errors }">
                                    <div *ngIf="(submitted || projectCreateForm.controls.project_name?.touched) && projectCreateForm.controls.project_name.errors?.required"
                                        class="text text-danger">
                                        Project Name is required.
                                    </div>
                                    <div class="text text-danger" *ngIf="projectCreateForm.controls.project_name.errors?.pattern">Special Charactors Not allowed!</div>                                    

                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="fromDate">Start Date</label>
                                <div class="form-group">
                                    <input class="form-control " placeholder="yyyy-mm-dd" type="date"
                                        formControlName="durationfrom" required
                                        [ngClass]="{ 'is-invalid': (submitted || projectCreateForm.controls.durationfrom.touched) && projectCreateForm.controls.durationfrom.errors }">
                                    <div *ngIf="(submitted || projectCreateForm.controls.durationfrom?.touched) && projectCreateForm.controls.durationfrom.errors?.required"
                                        class="text text-danger">
                                        Start Date is required.
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="toDate">End Date</label>
                                <div class="form-group">
                                    <input class="form-control " placeholder="yyyy-mm-dd" type="date"
                                        formControlName="durationto">
                                    <div class="text text-danger"
                                        *ngIf="projectCreateForm.controls.durationto.touched && projectCreateForm.controls.durationto.invalid">
                                        From Date should not be greater than To date</div>

                                </div>

                            </div>
                            <div class="col-md-6 mb-3 sellected-option">
                                <label for="projectAccess" class="mb-0">User Roles with Access To This Project</label>
                                <div class="bottom-field">
                                    <div class="form-group">
                                        <angular2-multiselect [data]="roleNameFilter" [(ngModel)]="selectedItems"
                                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                            (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelectAll)="onDeSelectAll($event)" formControlName="roleListItem">
                                        </angular2-multiselect>

                                    </div>
                                </div>
                                <p style="color:grey;font-size: 0.8rem;"><b>Note:</b> If no role is selected, only Admin
                                    can view the project and any information associated with this project.</p>
                            </div>

                            <!-- <div class="rollFormArray">
                              <h3>Role Permission</h3>
                            <div formArrayName="roleListItem" >
                                <fieldset *ngFor="let address of projectCreateForm.controls.roleListItem['controls']; let i = index" >
                            <div [formGroupName]="i" >
                               
                                 <div class="check-box" *ngFor="let data of listRoleName">
                                    <span>{{data.role}}</span> <input type="checkbox" (change)="onChange(i,data.id,data.role, $event.target.checked)" value="{{data.role}}" [checked]="inputChecked(i,data)" > 
                                </div> 
                                  
                            </div>
                              
                                 </fieldset> 
                               </div>
                            </div> -->

                            <div class="submit-btn">
                                <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>
                                    {{button}}</button>
                                <button class="btn btn-warning" type="submit" (click)="cancelProject()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->