import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mail-preview-popup',
  templateUrl: './mail-preview-popup.component.html',
  styleUrls: ['./mail-preview-popup.component.scss']
})
export class MailPreviewPopupComponent implements OnInit {
  @Input() viewUpsiList;
  @Input() viewUpsiID;
  totalFileItem: any;
  confidencialUrl: any;
  mailLogItem: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { 
    this.confidencialUrl = environment.url
  }

  ngOnInit() {
    this.mailLogItem = this.viewUpsiList;  
       
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
