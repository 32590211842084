import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-mail-template',
  templateUrl: './view-mail-template.component.html',
  styleUrls: ['./view-mail-template.component.scss']
})
export class ViewMailTemplateComponent implements OnInit {

  @Input() viewMailTemplateList;
  html: any;
  timelineActivity: any;


  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  
    this.html = this.viewMailTemplateList.contents;
    this.timelineActivity = this.viewMailTemplateList.timelineActivity;
   if(this.viewMailTemplateList.templatename == 'UPSI Sender' || this.viewMailTemplateList.templatename == 'UPSI Recipient'){
    var element = document.getElementById("datatable");
    element.classList.add("ex-datatable");
   }
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
