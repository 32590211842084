import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelAccessControlRoutingModule } from './model-access-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelAccessLevelComponent } from './model-access-level/model-access-level.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import { MaterialModule } from '../material.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    ModelAccessLevelComponent
  ],
 
  imports: [
    CommonModule,
    ModelAccessControlRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule
    
  ]
})
export class ModelAccessControlModule { }
