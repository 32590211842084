<div class="modal-header">
    <h5 class="modal-title">View Declaration of Trade</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewTradingWindowList">
                                    <tr>
                                        <td scope="row">ID</td>
                                        <td scope="row">TWC - {{viewTradingWindowList.id}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Start Date</td>
                                        <td scope="row">{{viewTradingWindowList.start_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">End Date</td>
                                        <td scope="row">{{viewTradingWindowList.end_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>
                                    <tr>
                                    <tr>
                                        <td scope="row">Sender Name</td>
                                        <td scope="row">{{viewTradingWindowList.sender_name}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Recipient Type</td>
                                        <td scope="row">{{viewTradingWindowList.recipient_type}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Recipient Name</td>
                                        <td scope="row"><ul *ngFor="let item of viewTradingWindowList.recipient_name"><li>{{item}}</li></ul></td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Period</td>
                                        <td scope="row">{{viewTradingWindowList.period}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">Subject</td>
                                        <td scope="row">{{viewTradingWindowList.subject}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="html != '' " >
                                        <td scope="row">Body Content</td>
                                        <td scope="row" [innerHTML]="html"></td>                                         
                                     </tr> 
                                     <tr *ngIf="html == '' ">
                                        <td scope="row">Body Content</td>
                                        <td scope="row">Not Provided</td>                                         
                                     </tr> 
                                     <!-- <tr *ngIf="viewTradingWindowList.aggreement_attachment.length !=''">
                                        <td scope="row">Attachement</td>
                                        <td scope="row" ><ul *ngFor="let item of viewTradingWindowList.aggreement_attachment"><li>{{item}}</li></ul></td>                                         
                                     </tr>  -->
                                     <tr>
                                        <td scope="row">Created On</td>
                                        <td scope="row">{{viewTradingWindowList.created_on | date:'dd-MM-yyyy hh:mm:ss'}}</td>                                         
                                     </tr> 
                                                                                                      
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
