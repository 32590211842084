import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../master.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewCompanyrulesComponent } from '../view-companyrules/view-companyrules.component';
import { DeleteCompanyRulesComponent } from '../delete-company-rules/delete-company-rules.component';

@Component({
  selector: 'app-list-companyrules',
  templateUrl: './list-companyrules.component.html',
  styleUrls: ['./list-companyrules.component.scss']
})
export class ListCompanyrulesComponent implements OnInit {
  page = 1;
  pageSize = 10;
  listCompanyRules: any;
  filtered: any;
  constructor(private masterService: MasterService, private router:Router, private toastr: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.masterService.listCompanyRules(null).subscribe(
      res => {
      let listCompanyRules = res.data;
         
        this.filtered = listCompanyRules;
       
        },
        err => {
            console.log(err);
        }
    );
  }
  openModal(id:any) {   
      
    if (id) {
      let roleId = {'id' : id};
      this.masterService.editCompanyRules(roleId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(ViewCompanyrulesComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewCompanyRules = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }    
    
  }

  openModalDelete(id:any) {   
      
    if (id) {
      let roleId = {'id' : id};
      this.masterService.editCompanyRules(roleId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(DeleteCompanyRulesComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewCompanyRules = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }    
    
  }
}
