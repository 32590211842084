



<div class="modal-header">
    <h5 class="modal-title">View Company Profile</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
 </div>
 <div class="modal-body">
    <div class="container-fluid">
       <div class="row">
           <div class="col-sm-12">
               <div class="card">
                   
                   <div class="card-body custom-datatable noscroll">
                       <div class="custom-datatable">
                           <table class="table">                            
                                   <tbody *ngIf="viewCompanyRules">
                                       <tr>
                                           <td scope="row">Rule Name</td>
                                           <td scope="row">{{viewCompanyRules.rule_name}}</td>                                         
                                        </tr> 
                                        <tr>
                                           <td scope="row">Rule Approved/rejected</td>
                                           <td scope="row">{{viewCompanyRules.rule_status}}</td>                                         
                                        </tr>                                                                   
                                  
                               </tbody>
                           </table>
                           <table class="table">                            
                                   <tbody *ngFor="let item of viewCompanyRules.rules_model">
                                       <tr>
                                           <td scope="row">Rule Condition</td>
                                           <td scope="row">{{item.rules_condition}}</td>                                         
                                        </tr> 
                                        <tr>
                                           <td scope="row">Setting</td>
                                           <td scope="row">{{item.rules_setting}}</td>                                         
                                        </tr>                                                                   
                                        <tr>
                                           <td scope="row">Value</td>
                                           <td scope="row">{{item.rules_value}}</td>                                         
                                        </tr>                                                                   
                                                                                                        
                                  
                               </tbody>
                           </table>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    </div>
 </div>
 <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
 </div>
 