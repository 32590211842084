import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trading-alert-popup',
  templateUrl: './trading-alert-popup.component.html',
  styleUrls: ['./trading-alert-popup.component.scss']
})
export class TradingAlertPopupComponent implements OnInit {
  @Input() preClearancTradingAlert;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
