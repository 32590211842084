import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';

@Component({
  selector: 'app-delete-trading-window-closure',
  templateUrl: './delete-trading-window-closure.component.html',
  styleUrls: ['./delete-trading-window-closure.component.scss']
})
export class DeleteTradingWindowClosureComponent implements OnInit {
  twcId: any;
  accessSuccess: string;
  twcReasonDeleteForm: FormGroup;
  getId: any;
  @Input() deleteTradingWindowList;
  isLoading = false;
  button = 'Delete';
  submitted = false;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private pitService: PitComplianceService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }
  ngOnInit(): void {
    // this.activateRouter.params.subscribe(data =>{
    //       this.twcId = data['id']
    //     })
    //     if(this.twcId){
    //       this.pitService.tradingWindowClosuredelete(this.twcId).subscribe(data =>{
    //       // console.log(agenda Details deleted )
    //       this.toastr.success("Trading Window Closure details deleted", "Success!");
    //       this.router.navigate(['pit-compliance/list-twc']);
    //     })
    //   }

    this.twcReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('',  Validators.required)
    });
   this.getId = this.deleteTradingWindowList.id;
  }
  onSubmit(){
    this.submitted = true;
    this.twcReasonDeleteForm.value.id = this.getId;
    if(this.twcReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';
    this.pitService.tradingWindowClosuredelete(this.twcReasonDeleteForm.value).subscribe(data =>{
      console.log(this.twcReasonDeleteForm.value )
      this.toastr.success("Trading Window Closure Successfully deleted");
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      window.location.reload();
      
    })
  }else{
    if(this.twcReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Trading Window Closure.");
    }
  }

  }
  
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }

}
