<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>UPDATE INSIDER </h5>
                    <a routerLink="/insider/insider-list"><button class="btn btn-primary me-2 "><i
                                class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="insiderEditForm" class="needs-validation" (ngSubmit)="openModalEditReason()">
                        <div class="row">
                            <div class="col-sm-12 col-xl-12">
                                <div class="card">
                                    <div class="card-body">
                                        <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                            <ngb-panel id="static-1">
                                                <ng-template ngbPanelTitle>
                                                    <span>Insider Basic Details</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <!-- <div class="col-md-6 mb-3">
                                                <label for="projectName">Project Name <span class="manditary-filed">*</span></label>
                                                <div class="form-group">
                                                    <select class="form-select" #teams class="form-control form-select" required id="projectName" formControlName="project" [ngClass]="{ 'is-invalid': submitted && insiderEditForm.controls.project.errors }">
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option *ngFor = "let items of listProject" value="{{items.project_name}}">{{items.project_name}}</option>
                                                      
                                                    </select> 
                                                    <div *ngIf="(submitted || insiderEditForm.controls.project?.touched) && insiderEditForm.controls.project.errors?.required"
                                                    class="text text-danger">
                                                    Project Name is required.
                                                </div>                            
                                                </div>
                                            </div> -->
                                                            <div class="col-md-6 mb-3">
                                                                <label for="nameOfInsider">Name <span
                                                                        class="manditary-filed">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    id="nameOfInsider" placeholder="Name"
                                                                    formControlName="name" autocomplete="off" required
                                                                    [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.name.touched) && insiderEditForm.controls.name.errors }">
                                                                <!-- <div *ngIf="submitted && insiderEditForm.controls.name.errors" class="invalid-feedback">
                                                        <div *ngIf="insiderEditForm.controls.name.touched && insiderEditForm.controls.name.errors.required">Name is required</div>
                                                    </div> -->
                                                                <div *ngIf="(submitted || insiderEditForm.controls.name.touched) && insiderEditForm.controls.name.errors?.required"
                                                                    class="text text-danger">
                                                                    Name is required.
                                                                </div>
                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.name.errors?.pattern">Special Charactors Not Allowed!</div>

                                                            </div>
                                                            <div class="col-md-6 mb-3" *ngIf="accessUser == 'sdd@legalitysimplified.com'">
                                                                <label for="email">Email ID <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="email"
                                                                        placeholder="Email ID" formControlName="email"
                                                                        autocomplete="off" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.email.touched) && insiderEditForm.controls.email.errors }">
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.email.touched) && insiderEditForm.controls.email.errors?.required"
                                                                        class="text text-danger">
                                                                        Email ID is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="insiderEditForm.controls.email.errors?.pattern">
                                                                        Email must be a valid email address
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3" *ngIf="accessUser != 'sdd@legalitysimplified.com'">
                                                                <label for="email">Email ID <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control" id="email"
                                                                        placeholder="Email ID" formControlName="email"
                                                                        autocomplete="off" required disabled
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.email.touched) && insiderEditForm.controls.email.errors }">
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.email.touched) && insiderEditForm.controls.email.errors?.required"
                                                                        class="text text-danger">
                                                                        Email ID is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="insiderEditForm.controls.email.errors?.pattern">
                                                                        Email must be a valid email address
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="insiderType">Insider Type <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <select class="form-select"
                                                                        class="form-control form-select"
                                                                        (change)="insiderTypeChanged()" required
                                                                        id="insiderType" formControlName="insiderType"
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.insiderType.touched) && insiderEditForm.controls.insiderType.errors }">
                                                                        <option value="" disabled selected>--Choose One
                                                                            --</option>
                                                                        <option value="Individual">Individual</option>
                                                                        <option value="Company">Company</option>
                                                                        <option value="Partnership Firm">Partnership
                                                                            Firm</option>
                                                                        <option value="LLP">LLP</option>
                                                                        <option value="Trust">Trust</option>
                                                                        <option value="Co-operative Society">
                                                                            Co-operative Society</option>
                                                                        <option value="HUF">HUF</option>
                                                                    </select>
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.insiderType.touched) && insiderEditForm.controls.insiderType.errors?.required"
                                                                        class="text text-danger">
                                                                        Insider Type is required.
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-12 mb-3 insidetTypes-list"
                                                                    *ngIf="othersInsiderSelectCin">
                                                                    <div class="row">
                                                                        <div class="col-md-12 mb-3">
                                                                            <label for="cin">CIN Number</label>
                                                                            <input type="text" class="form-control"
                                                                                id="cin" placeholder="CIN Number"
                                                                                formControlName="cin"
                                                                                autocomplete="off">
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.cin.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3 insidetTypes-list"
                                                                    *ngIf="othersInsiderSelect">
                                                                    <div class="row">
                                                                        <div class="col-md-6 mb-3">
                                                                            <label for="designation">Designation</label>
                                                                            <input type="text" class="form-control"
                                                                                id="designation"
                                                                                placeholder="Designation"
                                                                                formControlName="designation"
                                                                                autocomplete="off">
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.designation.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                        </div>
                                                                        <div class="col-md-6 mb-3">
                                                                            <label for="nationality">Nationality</label>
                                                                            <div class="form-group">
                                                                                <select class="form-select"
                                                                                    class="form-control form-select"
                                                                                    id="nationality"
                                                                                    formControlName="nationality"
                                                                                    (change)="insiderNationalityChanged()">
                                                                                    <option value="" disabled selected>--Choose One --</option>
                                                                                        <option value="American">American</option>
                                                                                        <option value="Australian">Australian</option>
                                                                                        <option value="Argentine">Argentine</option>
                                                                                        <!-- <option value="Argentinean">Argentinean</option> -->
                                                                                        <option value="Brazilian">Brazilian</option>
                                                                                        <option value="Colombian">Colombian</option>
                                                                                        <option value="Chinese">Chinese</option>
                                                                                        <option value="French">French</option>
                                                                                        <option value="Indian">Indian</option>
                                                                                        <option value="British">British</option>
                                                                                        <!-- <option value="Great Britain">Great Britain</option> -->
                                                                                        <option value="Singaporean">Singaporean</option>
                                                                                        <!-- <option value="Singapore">Singapore</option> -->
                                                                                        <option value="Emirati">Emirati</option>
                                                                                        <!-- <option value="UAE">UAE</option> -->
                                                                                        <option value="Others">Others</option>
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row" *ngIf="othersInsiderNationSelect">
                                                                        <div class="col-md-12 mb-3">
                                                                            <label for="nationality">Others Nationality</label>
                                                                            <input type="text" class="form-control"
                                                                                id="nationality"
                                                                                placeholder="Others Nationality"
                                                                                formControlName="others_nationality"
                                                                                autocomplete="off">
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.others_nationality.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="insiderType">Relationship with Company <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <select class="form-select"
                                                                        class="form-control form-select"
                                                                        [(ngModel)]="relativeCompany"
                                                                        (change)="relationshipPersonChanged(event)"
                                                                        required id="insiderType"
                                                                        formControlName="relationship_with_company"
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.relationship_with_company.touched) && insiderEditForm.controls.relationship_with_company.errors }">
                                                                        <option value="" disabled selected>--Choose One
                                                                            --</option>
                                                                        <option
                                                                            *ngFor="let list of relationshipDropdown"
                                                                            value="{{list.listItem}}">{{list.listItem}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.relationship_with_company.touched) && insiderEditForm.controls.relationship_with_company.errors?.required"
                                                                        class="text text-danger">
                                                                        Relationship with Company is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3" *ngIf="othersSelect">
                                                                    <label for="insiderType">Relationship with Connected
                                                                        Person</label>
                                                                    <div class="form-group">
                                                                        <select class="form-select"
                                                                            class="form-control form-select"
                                                                            id="insiderType"
                                                                            formControlName="realationship_with_connected_person">
                                                                            <option value="" disabled selected>-- Choose
                                                                                One --</option>
                                                                            <option value="Father">Father</option>
                                                                            <option value="Mother">Mother</option>
                                                                            <option value="Brother">Brother</option>
                                                                            <option value="Sister">Sister</option>
                                                                            <option value="Spouse">Spouse</option>
                                                                            <option value="Father In Law">Father In Law
                                                                            </option>
                                                                            <option value="Mother in Law">Mother in Law
                                                                            </option>
                                                                            <option value="Son">Son</option>
                                                                            <option value="Daughter">Daughter</option>
                                                                            <option value="Step-son">Step-son</option>
                                                                            <option value="Step-daughter">Step-daughter
                                                                            </option>
                                                                            <option value="Material Relationship">
                                                                                Material Relationship</option>
                                                                            <option value="Brother in Law">Brother in
                                                                                Law</option>
                                                                            <option value="Sister in Law">Sister in Law
                                                                            </option>
                                                                            <option value="Son in law">Son in law
                                                                            </option>
                                                                            <option value="Daughter in law">Daughter in
                                                                                law</option>
                                                                            <option value="Spouse of Brother in law">Spouse of Brother in law</option>
                                                                            <option value="Spouse of Sister in law">Spouse of Sister in law</option>
                                                                            <option value="Soupse of Step son">Soupse of Step son</option>
                                                                            <option value="Soupse of Step Daughter">Soupse of Step Daughter</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3" *ngIf="othersSelectDic">
                                                                    <label for="othersSelectDic">{{this.selectedOthers?.value}}</label>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            id="otherdic" placeholder="{{this.selectedOthers?.value}}"
                                                                            formControlName="others" autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.others.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-12 mb-3" *ngIf="othersSelectInter">
                                                                    <label for="othersSelectInter">Other Intermediary
                                                                        Name </label>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            id="otherdic"
                                                                            placeholder="Other Intermediary Name"
                                                                            formControlName="others" autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.others.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3"
                                                                    *ngIf="othersSelectFiduciary">
                                                                    <label for="othersSelectFiduciary">Other Fiduciary
                                                                        Name</label>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            id="otherdic"
                                                                            placeholder="Other Fiduciary Name"
                                                                            formControlName="others" autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.others.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3"
                                                                    *ngIf="othersSelectconnectPerson">
                                                                    <label for="othersSelectconnectPerson">Other
                                                                        Connected Person Name</label>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            id="otherdic"
                                                                            placeholder="Other Connected Person Name"
                                                                            formControlName="others" autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.others.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12 mb-3"
                                                                    *ngIf="othersSelectAccessUpsi">
                                                                    <label for="othersSelectAccessUpsi">Other Person
                                                                        with access to UPSI</label>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control"
                                                                            id="otherdic"
                                                                            placeholder="Other Person with access to UPSI"
                                                                            formControlName="others" autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.others.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                </div> -->


                                                            </div>


                                                            <div class="col-md-6 mb-3">
                                                                <label for="mobileNo">Contact Number <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <input type="text" class="form-control"
                                                                        id="mobileNo" placeholder="Contact Number"
                                                                        formControlName="mobileNo" autocomplete="off"
                                                                        required
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.mobileNo.touched) && insiderEditForm.controls.mobileNo.errors }">
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.mobileNo.touched) && insiderEditForm.controls.mobileNo.errors?.required"
                                                                        class="text text-danger">
                                                                        Contact Number is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="insiderEditForm.controls.mobileNo.errors?.pattern">Enter the Valid Contact Number.</div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-md-6 mb-3">
                                                <label for="phoneNo">Phone Number</label>
                                                <div class="form-group">
                                                <input type="text" class="form-control" id="phoneNo" placeholder="Phone Number" formControlName="phoneNo"
                                                     required [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.phoneNo.touched) && insiderEditForm.controls.phoneNo.errors }">
                                                     <div *ngIf="(submitted || insiderEditForm.controls.phoneNo.touched) && insiderEditForm.controls.phoneNo.errors?.required"
                                                    class="text text-danger">
                                                    Phone No is required.
                                                    </div>      
                                                    </div>                       
                                            </div> -->
                                                            <div class="col-md-6 mb-3">
                                                                <label for="panNo"> ID Proof Type <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <select class="form-select"
                                                                        class="form-control form-select" required
                                                                        id="DocumentType" formControlName="DocumentType"
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.DocumentType.touched) && insiderEditForm.controls.DocumentType.errors }">
                                                                        <option value="" disabled selected>-- Choose One
                                                                            --</option>
                                                                        <option value="PAN">PAN</option>
                                                                        <option value="" disabled style="color: grey;">
                                                                            -- PAN Not Available --</option>
                                                                        <option value="Aadhar card">Aadhar card</option>
                                                                        <option value="Driving License">Driving License
                                                                        </option>
                                                                        <option value="Passport">Passport</option>
                                                                        <option value="Voter ID">Voter ID</option>
                                                                        <!-- <option value="Others">Others</option> -->
                                                                    </select>
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.DocumentType.touched) && insiderEditForm.controls.DocumentType.errors?.required"
                                                                        class="text text-danger">
                                                                        ID Proof Type is required.
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label for="identiferNumber">ID Proof No. <span
                                                                            class="manditary-filed">*</span></label>
                                                                    <input type="text" class="form-control" id="panNo"
                                                                        placeholder="ID Proof No" autocomplete="off"
                                                                        formControlName="panNo" required
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.panNo.touched) && insiderEditForm.controls.panNo.errors }">
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.panNo.touched) && insiderEditForm.controls.panNo.errors?.required"
                                                                        class="text text-danger">
                                                                        ID Proof No is required.
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6 mb-3">
                                                                <label for="address">Address <span
                                                                        class="manditary-filed">*</span></label>
                                                                <div class="form-group">
                                                                    <textarea class="form-control" id="address" rows="3"
                                                                        formControlName="address" placeholder="Address"
                                                                        required
                                                                        [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.address.touched) && insiderEditForm.controls.address.errors }"></textarea>
                                                                    <div *ngIf="(submitted || insiderEditForm.controls.address.touched) && insiderEditForm.controls.address.errors?.required"
                                                                        class="text text-danger">
                                                                        Address is required.
                                                                    </div>
                                                                    <div class="text text-danger" *ngIf="insiderEditForm.controls.address.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-3">
                                                                <label for="remarks">Remarks</label>
                                                                <div class="form-group">
                                                                    <textarea class="form-control" id="remarks" rows="3"
                                                                        formControlName="remarks"
                                                                        placeholder="Remarks"></textarea>
                                                                        <div class="text text-danger" *ngIf="insiderEditForm.controls.remarks.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 mb-3 insidetTypes-list"
                                                                *ngIf="companyDetailsSelect">
                                                                <p class="tithead"><b>Entity Details:</b></p>

                                                                <div class="row">
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="nameOfCompany">Entity Name <span
                                                                                class="manditary-filed">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            id="company_name" placeholder="Entity Name"
                                                                            formControlName="company_name"
                                                                            autocomplete="off">
                                                                           <div class="text text-danger" *ngIf="insiderEditForm.controls.company_name.errors?.pattern">Special Charactors Not Allowed!</div>

                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="email">Entity Email ID <span
                                                                                class="manditary-filed">*</span></label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                id="company_email"
                                                                                placeholder="Entity Email ID"
                                                                                formControlName="company_email"
                                                                                autocomplete="off">
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.company_email.errors?.pattern">
                                                                                    Email must be a valid email address
                                                                                </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="isin">ISIN Number </label>
                                                                        <input type="text" class="form-control"
                                                                            id="isinnumber" placeholder="ISIN Number"
                                                                            formControlName="isinnumber"
                                                                            autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.isinnumber.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="cin">CIN Number <span
                                                                                class="manditary-filed">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            id="company_cin" placeholder="CIN Number"
                                                                            formControlName="company_cin"
                                                                            autocomplete="off">
                                                                            <div class="text text-danger" *ngIf="insiderEditForm.controls.company_cin.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="identiferNumber">Entity PAN
                                                                            No.<span
                                                                                class="manditary-filed">*</span></label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                id="panno" placeholder="Entity PAN No"
                                                                                autocomplete="off"
                                                                                formControlName="company_pan">
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="mobileno">Entity Contact Number
                                                                            <span
                                                                                class="manditary-filed">*</span></label>
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                id="mobileno"
                                                                                placeholder="Entity Contact Number"
                                                                                formControlName="company_contact_no"
                                                                                autocomplete="off">
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.company_contact_no.errors?.pattern">Enter the Valid Contact Number.</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 mb-3">
                                                                        <label for="address">Entity Address <span
                                                                                class="manditary-filed">*</span></label>
                                                                        <div class="form-group">
                                                                            <textarea class="form-control" id="address"
                                                                                rows="3"
                                                                                formControlName="company_address"
                                                                                placeholder="Entity Address"></textarea>
                                                                                <div class="text text-danger" *ngIf="insiderEditForm.controls.company_address.errors?.pattern">Special Charactors Not Allowed!</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <!-- <div class="col check-line">
                                                    <label for="nationality">Designated Person</label>
                                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                        <div class="radio radio-primary">
                                                            <input type="radio" name="radio1" id="radioinline1" value="Yes" formControlName="designated_person" [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.designated_person.touched) && insiderEditForm.controls.designated_person.errors }">
                                                            <label for="radioinline1" class="mb-0">Yes</label>
                                                        </div>
                                                        <div class="radio radio-primary">
                                                            <input type="radio" name="radio1" id="radioinline2" value="No" formControlName="designated_person" [ngClass]="{ 'is-invalid': (submitted || insiderEditForm.controls.designated_person.touched) && insiderEditForm.controls.designated_person.errors }">
                                                            <label for="radioinline2" class="mb-0">No</label>
                                                        </div>                                     
                                                    </div>
                                                    <div *ngIf="(submitted || insiderEditForm.controls.designated_person.touched) && insiderEditForm.controls.designated_person.errors?.required"
                                                    class="text text-danger">
                                                    Designated Person is required.
                                                </div>
                                                </div>     -->

                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-2">
                                                <ng-template ngbPanelTitle>
                                                    <span>Confidentiality Agreement Entered</span>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <div class="col-md-12 mb-3 confidentiality-btn">
                                                                <div class="cols check-line1">
                                                                    <!-- <label for="nationality">Confidentiality Agreement Copy</label> -->
                                                                    <div
                                                                        class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                                        <div class="radio radio-primary">
                                                                            <input type="radio" name="radio3"
                                                                                id="radioinline5" value="Yes"
                                                                                formControlName="confidentiality_agreement_entered"
                                                                                (click)="radioSelect('Yes')">
                                                                            <label for="radioinline5"
                                                                                class="mb-0">Yes</label>
                                                                        </div>
                                                                        <div class="radio radio-primary">
                                                                            <input type="radio" name="radio3"
                                                                                id="radioinline6" value="No"
                                                                                formControlName="confidentiality_agreement_entered"
                                                                                (click)="radioSelect('No')">
                                                                            <label for="radioinline6"
                                                                                class="mb-0">No</label>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-md-12 mb-3">
                                                                    <div class="row">
                                                                        <div class="sellected-option">
                                                                            <div class="row bottom-field"
                                                                                *ngIf="isSelected('Yes')">
                                                                                <div class="col-md-6 mb-3">
                                                                                    <label
                                                                                        for="fromDate">Confidentiality
                                                                                        Agreement Date</label>
                                                                                    <div class="form-group input-group">
                                                                                        <input class="form-control "
                                                                                            placeholder="yyyy-mm-dd"
                                                                                            type="date"
                                                                                            formControlName="confidentiality_agreement_date">
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6 mb-3">
                                                                                    <label class="custom-file-label"
                                                                                        for="attachment">Confidentiality
                                                                                        Agreement Copy</label>
                                                                                    <div class="form-group">
                                                                                        <input #userPhoto type="file"
                                                                                            class="custom-file-input form-control"
                                                                                            accept=".pdf"
                                                                                            id="attachment"
                                                                                            formControlName="fileName"
                                                                                            (change)="changeListener($event)">
                                                                                        <p class="text text-danger">
                                                                                            *Upload Files: .pdf</p>
                                                                                    </div>

                                                                                    <div *ngIf="attachmentPath == true">
                                                                                        <!-- <p class="attachmentPath"><a
                                                                                                href="{{baseUrl}}/public/uploads/insider/{{insiderId['id']}}/{{path}}"
                                                                                                routerLinkActive="active"
                                                                                                target="_blank">{{path}}</a>
                                                                                        </p> -->
                                                                                        <p class="attachmentPath">{{path}} </p>
                                                                                        <img src="assets/images/close.png"
                                                                                            class="right-image"
                                                                                            alt="Close"
                                                                                            (click)="removeFile()">
                                                                                    </div>

                                                                                    <div class="form-group"
                                                                                        style="display:none;">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            id="filePath"
                                                                                            [(ngModel)]="path"
                                                                                            placeholder="File Path"
                                                                                            formControlName="filePath">
                                                                                    </div>
                                                                                    <div class="form-group"
                                                                                        style="display:none;">
                                                                                        <textarea rows="3"
                                                                                            class="form-control"
                                                                                            id="filePath"
                                                                                            [(ngModel)]="pathImage"
                                                                                            placeholder="File Path Link"
                                                                                            formControlName="image"></textarea>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="sellected-option">
                                                                            <div class="bottom-field"
                                                                                *ngIf="isSelected('No')">
                                                                                <div class="row" style="display:none ;">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ngb-panel>


                                        </ngb-accordion>
                                    </div>
                                </div>
                            </div>

                            <div class="submit-btn">
                                <button *ngIf="showButton" class="btn btn-primary" [disabled]="isLoading"
                                    type="submit"><i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>
                                    {{button}}</button>
                                <button class="btn btn-warning" type="btn" (click)="cancelInsider()">Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->
<!-- {{insiderEditForm.value | json}} -->