import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpsiRoutingModule } from '../upsi-transfer/upsi-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddUpsiComponent } from './add-upsi/add-upsi.component';
import { DeleteUpsiComponent } from './delete-upsi/delete-upsi.component';
import { EditUpsiComponent } from './edit-upsi/edit-upsi.component';
import { HistoryUpsiComponent } from './history-upsi/history-upsi.component';
import { ListUpsiComponent } from './list-upsi/list-upsi.component';
import { NoLongerUpsiComponent } from './no-longer-upsi/no-longer-upsi.component';
import { ReportUpsiComponent } from './report-upsi/report-upsi.component';
import { ViewUpsiComponent } from './view-upsi/view-upsi.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PopupModelUpsiComponent } from './popup-model-upsi/popup-model-upsi.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BulActionPopupComponent } from './bul-action-popup/bul-action-popup.component';
import { FileDirective } from './bul-action-popup/file.directive';
import { FileDropDirective } from './bul-action-popup/file-drop.directive';
import { DeletedUpsiModuleComponent } from './deleted-upsi-module/deleted-upsi-module.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorUpsiComponentComponent } from './error-upsi-component/error-upsi-component.component';
import { UpsiMailTemplateComponent } from './upsi-mail-template/upsi-mail-template.component';
import { AttachmentpopupComponent } from './attachmentpopup/attachmentpopup.component';
import { MailPreviewPopupComponent } from './mail-preview-popup/mail-preview-popup.component';
import { NoupsiComponent } from './noupsi/noupsi.component';
import { NoupsipopupComponent } from './noupsipopup/noupsipopup.component';
import { WarningalertpopupComponent } from './warningalertpopup/warningalertpopup.component';
@NgModule({
  declarations: [
    AddUpsiComponent,
    DeleteUpsiComponent,
    EditUpsiComponent,
    HistoryUpsiComponent,
    ListUpsiComponent,
    NoLongerUpsiComponent,
    ReportUpsiComponent,
    ViewUpsiComponent,
    PopupModelUpsiComponent,
    BulActionPopupComponent,
    FileDirective,
    FileDropDirective,
    DeletedUpsiModuleComponent,
    ErrorUpsiComponentComponent,
    UpsiMailTemplateComponent,
    AttachmentpopupComponent,
    MailPreviewPopupComponent,
    NoupsiComponent,
    NoupsipopupComponent,
    WarningalertpopupComponent
  ],
  imports: [
    CommonModule,
    UpsiRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    AutocompleteLibModule,
    NgSelectModule
  ],
  entryComponents:[
    PopupModelUpsiComponent
  ],
})
export class UpsiTransferModule { }
