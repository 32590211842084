

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">                             
                  <a routerLink="/insider/insider-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewInsiderList?.data[0]">
                                    <!-- <tr>
                                        <td scope="row">Project Name</td>
                                        <td scope="row">{{viewInsiderList?.data[0].project}}</td>                                         
                                     </tr>  -->
                                     <tr>
                                        <td scope="row">Name of Insider</td>
                                        <td scope="row">{{viewInsiderList?.data[0].name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Insider Type</td>
                                        <td scope="row">{{viewInsiderList?.data[0].insiderType}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Relationship with Company</td>
                                        <td scope="row">{{viewInsiderList?.data[0].relationship_with_company}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Email ID</td>
                                        <td scope="row">{{viewInsiderList?.data[0].email}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Contact Number</td>
                                        <td scope="row">{{viewInsiderList?.data[0].mobileNo}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row"> ID Proof Type - ID Proof No.</td>
                                        <td scope="row">{{viewInsiderList?.data[0].panNo}} - {{viewInsiderList?.data[0].DocumentType}}</td>                                         
                                     </tr>
                                       
                                     <tr>
                                        <td scope="row">Address</td>
                                        <td scope="row">{{viewInsiderList?.data[0].address}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Designated Person</td>
                                        <td scope="row">{{viewInsiderList?.data[0].designated_person}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Confidentiality Agreement entered</td>
                                        <td scope="row">{{viewInsiderList?.data[0].confidentiality_agreement_entered}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewInsiderList?.data[0].confidentiality_agreement_entered == 'Yes'">
                                       <td scope="row">Confidentiality Agreement Copy</td>
                                       <td scope="row"><a href="http://192.168.0.22:5039/public/uploads/insider/{{insiderId}}/{{viewInsiderList?.data[0].filePath}}" target="_blank">{{viewInsiderList?.data[0].filePath}}</a></td>                                         
                                    </tr> 
                                    <tr *ngIf="viewInsiderList?.data[0].confidentiality_agreement_entered == 'Yes'">
                                       <td scope="row">Confidentiality Agreement Date</td>
                                       <td scope="row">{{viewInsiderList?.data[0].confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">Username</td>
                                       <td scope="row">{{viewInsiderList?.data[0].created_by}}</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Activity Date</td>
                                       <td scope="row">{{viewInsiderList?.data[0].created_on}}</td>                                         
                                    </tr>                                
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>