import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.scss']
})
export class CompanyViewComponent implements OnInit {
  companyId: any;
  @Input() viewCompanyList;
  accessUser: string;
  constructor(private activateRouter: ActivatedRoute, private companyService: CompanyService, public router: Router, public activeModal: NgbActiveModal) { 
    this.accessUser = sessionStorage.getItem('access_user');

  }
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  preclearanceAccess:boolean = false;
  accessModelController: any;
  // ngOnInit(): void {

  //   this.activateRouter.params.subscribe(data => {
  //     this.companyId = data['id']
  //   })
  //   if (this.companyId !== '') {
  //     this.companyService.viewCompany(this.companyId).subscribe(
  //       data =>{
  //       this.viewCompanyList = data;
        
  //     })

  //     }
  // }

 
  ngOnInit() {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    let accessData = sessionStorage.getItem('model_access_controller');    
    this.accessModelController = JSON.parse(accessData);
    if(this.accessModelController.preclearance_access == true || this.accessModelController.preclearance_access == 1){
      this.preclearanceAccess = true;
    }else{
      this.preclearanceAccess = true;
    }
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
