import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-model-component',
  templateUrl: './popup-model-component.component.html',
  styleUrls: ['./popup-model-component.component.scss']
})
export class PopupModelComponentComponent implements OnInit {
  @Input() viewProjectList;
  public active1 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity: any;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
   
    this.timelineActivity = this.viewProjectList.timelineActivity; 
    

    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
