import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/app/report/report.service';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import { ErrorCustomerComponentComponent } from '../error-customer-component/error-customer-component.component';
import { CustomerService } from '../customer.service';
import { saveAs } from "file-saver/dist/FileSaver";
import FileSaver from "file-saver";

type AOA = any[][];

@Component({
  selector: 'app-bulk-action-popup',
  templateUrl: './bulk-action-popup.component.html',
  styleUrls: ['./bulk-action-popup.component.scss']
})
export class BulkActionPopupComponent implements OnInit {
  button = 'Submit';
  isLoading = false;
  title = "FileUp";
  form: FormGroup;
  @Input() name;
  private stepper: Stepper;
  filePathName: string;
  fileData: any;
  excelFile: any;
  excelData: any;
  responseDate: any;

  next() {
    this.stepper.next();
  }
  constructor(
    public activeModal: NgbActiveModal, private toastr: ToastrService, private customerService: CustomerService, private router:Router, private builder: FormBuilder, private reportService: ReportService,
    private modalService: NgbModal
  ) { 
    this.form = this.builder.group({
      files: ['']
    });
  }
  

  ngOnInit() {
    
    
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
   
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
//read excel
getMenuItem(){  
const pdfUrl ="/assets/help-manual/InSiDDer Premium Help Manual.pdf";
  const pdfName = "InSiDDer Premium Help Manual.pdf";
  FileSaver.saveAs(pdfUrl, pdfName);
}

data: AOA = [];
wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
fileName: string = 'SheetJS.xlsx';
submitData() {
  this.isLoading = true;
    this.button = 'Processing';
  let reqData: any = {excel: this.excelData};
  if(this.fileData) {
    reqData.file = this.fileData;
  }
  this.customerService.sendJSONToServerCustomer(reqData).subscribe(
    res => {
      this.closeModal(event);
      
      this.responseDate = res.body;

      if(this.responseDate.duplicates == ''){
        // window.alert(this.responseDate.message + ". " + "Please Refresh the page")  
        this.toastr.success("Insider Successfully created");     
        window.location.reload();
        this.isLoading = false;
        this.button = 'Submit';
      }else{
        const modalRef = this.modalService.open(ErrorCustomerComponentComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewCustomerErrorList = this.responseDate.duplicates;
        modalRef.result.then(
          (result) => {
            //console.log(result);
          }, (reason) => {
            //console.log(reason);
          });
          this.isLoading = false;
          this.button = 'Submit';
      }


      // else  if(this.responseDate.success == '2'){
      //   this.toastr.warning(this.responseDate.message + ". " + "Please check your Email!")    
      //   this.isLoading = false;
      //   this.button = 'Submit';
      // }else{
      //   this.toastr.error(this.responseDate.message);
      //   this.isLoading = false;
      //   this.button = 'Submit'; 
      // }
      
    },
    err => {
     // console.log(err);
    }
  )
}

onFileChange(evt: any) {
  /* wire up file reader */
  const target: DataTransfer = <DataTransfer>(evt.target);
  if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    /* read workbook */
    const bstr: string = e.target.result;
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    this.data =  <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, dateNF: "yyyy-mm-dd" }));
    let reqArr = [];
    if(this.data.length > 1) {
      let keys = this.data[0];
      for(let i=1;i< this.data.length;i++) {
        let reqObj = {};
        this.data[i].map((valStr, index) => {
          if(keys[index] === 'Name'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Company_Name'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Designation'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'ID_Proof_No'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Address'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Remarks'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }else if(keys[index] === 'Others_Nationality'){
            let str = valStr;
            valStr = str.replace(/[^a-zA-Z0-9 ]/g, "");
            this.data[i][index] = valStr;
          }
          
          
          reqObj[keys[index]] = valStr});
       
        reqArr.push(reqObj);
      }
      
    }
    this.excelData = reqArr;
  };
  reader.readAsBinaryString(target.files[0]);
  this.next();
}


//drag and drop
saveFile(files: FileList) {
  this.filePathName = files[0].name;
  const reader: FileReader = new FileReader();
  reader.onload = (e: any) => {
    const bstr: string = e.target.result;
    this.fileData = bstr;
  };
  reader.readAsBinaryString(files[0]);
}

export(): void {
  /* generate worksheet */
  let anchorTag = document.createElement("a");
  anchorTag.setAttribute('type', 'hidden');
  anchorTag.href = "/assets/excel/customer.xlsx";
  anchorTag.download = "customer.xlsx";
  anchorTag.click();
  anchorTag.remove();
  // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.excelFile);

  // /* generate workbook and add the worksheet */
  // const wb: XLSX.WorkBook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // /* save to file */
  // XLSX.writeFile(wb, this.fileName);
}
}
