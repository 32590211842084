import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent implements OnInit {
  @Input() viewRoleList;
  listProject: any;
  projectNameFilter = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  projectAccessForm : FormGroup;
  button = 'Save';
  isLoading = false;
  roleId: any;
  currentDate = new Date();
  myDate: string;
  roleName: any;
  constructor(private datePipe: DatePipe, public activeModal: NgbActiveModal, private masterService: MasterService, public router: Router, private fb:FormBuilder, private toastr: ToastrService
  ) {
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
   }

  ngOnInit() {
      this.roleId = this.viewRoleList.id;      
      const roleParse = JSON.parse(this.viewRoleList.module_name);
      this.roleName = roleParse.roles_name;

    this.projectAccessForm = this.fb.group({
      role_id: new FormControl (''),
      projectname: new FormControl ('')
      
    });

    this.masterService.projectList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.listProject = res.body['data'];

      const procedureList = this.listProject?.map(({id,project_name,durationfrom,durationto}) =>({id,itemName: project_name,durationfrom,durationto}));

      procedureList.map((projectItem) => {
      
        let completedProjectItems = [];
        if(projectItem.durationto < this.myDate){            
          completedProjectItems.push(projectItem); 
                     
        }else{
          this.dropdownList.push(projectItem); 
     
        } 
      });
     
      //const procedureList = this.listProject.map(({id,project_name}) =>({id,itemName: project_name}));
      //this.dropdownList = [];
      this.projectNameFilter = [];
      //procedureList.forEach(result =>this.dropdownList.push(result)) 
      
      
      from(this.dropdownList)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.projectNameFilter.push(r));
    
        }
        },
        err => {
            console.log(err);
        }
    );
   
    //project dropdown
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select the Project Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
    const selectedProject = this.viewRoleList.role_id;
    
    this.selectedItems = selectedProject.map(({project_id, project_name}) => ({id: project_id, itemName: project_name }))

  }
  //Multi select box
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  //multi select box
  closeModal(sendData) {
    this.activeModal.close(sendData);
  
  }
  cancelProject(){
    this.activeModal.close(event);
    this.router.navigate(['/setup/role-permission']);    
    this.projectAccessForm.reset();
  }

  onSubmit(){
    this.projectAccessForm.value.role_id = this.roleId;
    this.projectAccessForm.value.role_name = this.roleName;
    this.isLoading = true;
    this.button = 'Processing';
      this.masterService.addRoleProject(this.projectAccessForm.value).subscribe(data =>{
        this.button = 'Save';
        this.isLoading = false;
          this.toastr.success("Project Successfully Added");
          this.activeModal.close(event);
          window.location.reload();
       
      })
  }

}
