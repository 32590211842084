import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pre-clearance-mail-template',
  templateUrl: './pre-clearance-mail-template.component.html',
  styleUrls: ['./pre-clearance-mail-template.component.scss']
})
export class PreClearanceMailTemplateComponent implements OnInit {
  @Input() preClearancemailTemplate;
  @Input() title;
  preClearanceMailTemplate: any;
  constructor(public activeModal: NgbActiveModal,  private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {
    this.preClearanceMailTemplate = this.preClearancemailTemplate;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }

}
