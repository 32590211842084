<!-- Container-fluid starts -->
<div class="container-fluid" >
    <div class="row" *ngIf="is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <!-- <h5>Basic Table</h5> -->
                    <div class="col-md-6 mb-3">
                        <div class="form-group">
                            <input type="text" class="form-control" id="filterString" [(ngModel)]="filterString" autocomplete="off"
                                (ngModelChange)="onFilterChange()" placeholder="Search Insider by Name or Email ID" required>
                        </div>
                    </div>

                    <div class="list-btn" >
                        <a routerLink="/insider/insider-add" *ngIf="is_access_write"><button class="btn btn-primary me-1">New</button></a>
                        <img src="assets/images/excel.png" class="img-responsive excel-data" (click)="openExcelDisclaimer()" title="Download Insider List">
                        <div class="columnHide mb-3">
                            <div class="form-group">
                                <label for="hideShow">Filter Insider List Columns</label>
                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                    [settings]="dropdownSettings1" 
                                    [data]="hidelist"
                                    [(ngModel)]="selectedhideItems"
                                    (onSelect)="onItemSelect($event,'column')"
                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                    (onSelectAll)="onSelectAll($event,'column')" 
                                    >
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    
                    <div *ngIf="bulkuploadfolder == true"><img src="assets/images/Bulk-Upload.png" class="img-responsive excel-data" (click)="open()" alt="Bulk Upload" title="Bulk Upload"></div>
                    <a routerLink="/insider/deleted-insider"><button class="btn btn-primary me-2" > Deleted Insiders List</button></a>
                </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" *ngIf="show_id" sortable="id">ID No.</th>
                                    <th scope="col" *ngIf="show_name" sortable="name">Name <i class="fa fa-filter" (click)="filterData()"
                                            aria-hidden="true" title="Search Insider"></i>
                                        <div class="filter-form" *ngIf="visible">
                                            <div class="form-group">                                             
                                                <div class="ng-autocomplete">
                                                    <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                    <ng-autocomplete #auto [data]="insiderNameFilter"
                                                        [searchKeyword]="keyword" placeholder="Search ..."
                                                        (selected)='selectEvent($event)'
                                                        (inputChanged)='onChangeSearch($event)'
                                                        (inputFocused)='onFocused($event)'
                                                        (inputCleared)="onCleared($event)"
                                                        (scrolledToEnd)='onScrolledEnd()' 
                                                        [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                        [notFoundTemplate]="notFoundTemplate" focus>
                                                    </ng-autocomplete>

                                                    <ng-template #itemTemplate let-item>
                                                        <a [innerHTML]="item"></a>
                                                    </ng-template>

                                                    <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template>
                                                </div>

                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" *ngIf="show_relationship_with_company" sortable="company">Relationship with Company
                                        <i class="fa fa-filter" (click)="filterData2()"
                                        aria-hidden="true" title="Search Insider"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto2  [data]="insiderRwcNameFilter"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (inputCleared)="onCleared($event)"
                                                    (scrolledToEnd)='onScrolledEnd()' 
                                                    [(ngModel)]="nameFilterString2" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <!-- <th scope="col" sortable="agreement">Confidentiality Agreement</th> -->
                                    <th scope="col" *ngIf="show_email" sortable="email">Email ID <i class="fa fa-filter" (click)="filterData3()"
                                        aria-hidden="true" title="Search Insider"></i>
                                        <div class="filter-form" *ngIf="visible3">
                                            <div class="form-group">                                             
                                                <div class="ng-autocomplete">
                                                    <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                    <ng-autocomplete #auto3  [data]="insiderEmailNameFilter"
                                                        [searchKeyword]="keyword3" placeholder="Search ..."
                                                        (selected)='selectEvent3($event)'
                                                        (inputChanged)='onChangeSearch($event)'
                                                        (inputFocused)='onFocused($event)'
                                                        (inputCleared)="onCleared($event)"
                                                        (scrolledToEnd)='onScrolledEnd()' 
                                                        [(ngModel)]="nameFilterString3" [itemTemplate]="itemTemplate"
                                                        [notFoundTemplate]="notFoundTemplate" focus>
                                                    </ng-autocomplete>
    
                                                    <ng-template #itemTemplate let-item>
                                                        <a [innerHTML]="item"></a>
                                                    </ng-template>
    
                                                    <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template>
                                                </div>
    
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" *ngIf="show_insiderType" sortable="insiderType">Insider Type <i class="fa fa-filter" (click)="filterData4()"
                                        aria-hidden="true" title="Search Insider"></i>
                                        <div class="filter-form" *ngIf="visible4">
                                            <div class="form-group">                                             
                                                <div class="ng-autocomplete">
                                                    <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                    <ng-autocomplete #auto3  [data]="insiderTypeNameFilter"
                                                        [searchKeyword]="keyword4" placeholder="Search ..."
                                                        (selected)='selectEvent4($event)'
                                                        (inputChanged)='onChangeSearch($event)'
                                                        (inputFocused)='onFocused($event)'
                                                        (inputCleared)="onCleared($event)"
                                                        (scrolledToEnd)='onScrolledEnd()' 
                                                        [(ngModel)]="nameFilterString4" [itemTemplate]="itemTemplate"
                                                        [notFoundTemplate]="notFoundTemplate" focus>
                                                    </ng-autocomplete>
    
                                                    <ng-template #itemTemplate let-item>
                                                        <a [innerHTML]="item"></a>
                                                    </ng-template>
    
                                                    <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template>
                                                </div>
    
                                            </div>
                                        </div></th>
                                    <th scope="col" sortable="cae" *ngIf="show_confidentiality_agreement_entered">Confidentiality Agreement Entered <i class="fa fa-filter" (click)="filterData5()"
                                        aria-hidden="true" title="Search Insider"></i>
                                        <div class="filter-form" *ngIf="visible5">
                                            <div class="form-group">                                             
                                                <div class="ng-autocomplete">
                                                    <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                    <ng-autocomplete #auto3  [data]="insiderCaeNameFilter"
                                                        [searchKeyword]="keyword5" placeholder="Search ..."
                                                        (selected)='selectEvent5($event)'
                                                        (inputChanged)='onChangeSearch($event)'
                                                        (inputFocused)='onFocused($event)'
                                                        (inputCleared)="onCleared($event)"
                                                        (scrolledToEnd)='onScrolledEnd()' 
                                                        [(ngModel)]="nameFilterString5" [itemTemplate]="itemTemplate"
                                                        [notFoundTemplate]="notFoundTemplate" focus>
                                                    </ng-autocomplete>
    
                                                    <ng-template #itemTemplate let-item>
                                                        <a [innerHTML]="item"></a>
                                                    </ng-template>
    
                                                    <ng-template #notFoundTemplate let-notFound>
                                                        <div [innerHTML]="notFound"></div>
                                                    </ng-template>
                                                </div>
    
                                            </div>
                                        </div></th>
                                    <th scope="col" sortable="mobile" *ngIf="show_id_proof">ID Proof No.</th>
                                    <th scope="col" sortable="email" *ngIf="show_contactNumber">Contact Number</th>
                                    <th scope="col" sortable="action">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID" *ngIf="show_id">INS-{{items.id }}</td>
                                    <td scope="row" data-content="Name" *ngIf="show_name">{{items.name}} </td>
                                    <td scope="row" data-content="Relationship with &#xa; Company" *ngIf="show_relationship_with_company">{{items.relationship_with_company}}</td>
                                    <!-- <td scope="row" data-content="ID">{{items.confidentiality_agreement_entered}}</td> -->
                                    <td scope="row" data-content="Email ID" *ngIf="show_email">{{items.email}}</td>
                                    <td scope="row" data-content="Insider Type" *ngIf="show_insiderType">{{items.insiderType}}</td>
                                    <td scope="row" data-content="Confidentiality &#xa; Agreement Entered" *ngIf="show_confidentiality_agreement_entered">{{items.confidentiality_agreement_entered}}</td>
                                    <td scope="row" data-content="ID Proof No." *ngIf="show_id_proof">{{items.DocumentType}} - {{items.panNo}} </td>
                                    <td scope="row" data-content="Contact Number" *ngIf="show_contactNumber">{{items.mobileNo}}</td>
                                    <td data-content="Actions">
                                       <button class="edit-icon" (click)="openModal(items.id)"
                                                title="View Log"><i class="fa fa-eye"></i></button>
                                        <a [routerLink]="['/insider/insider-edit', items.id]"
                                            *ngIf="is_access_write"><button class="edit-icon" title="Update"><i
                                                    class="fa fa-pencil-square-o"></i></button></a>
                                        <!-- <a [routerLink]="['/insider/insider-delete', items.id]"
                                            *ngIf="is_access_write"><button class="edit-icon"><i
                                                    class="fa fa-trash"></i></button></a> -->
                                                    <button class="edit-icon" *ngIf="is_access_write" (click)="openModalDelete(items.id)"
                                                title="Delete"><i class="fa fa-trash"></i></button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length">
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>
<!-- Container-fluid Ends -->