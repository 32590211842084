import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UpsiService } from '../upsi.service';

@Component({
  selector: 'app-warningalertpopup',
  templateUrl: './warningalertpopup.component.html',
  styleUrls: ['./warningalertpopup.component.scss']
})
export class WarningalertpopupComponent implements OnInit {
  accessSuccess: string;
  @Input() name;
  @Input() selectedItems;
  @Input() choosedItems;
  itemSelected: any;
  page = 1;
  pageSize = 10;
  itemsAllSelected = [];
  @Output() onSubmitEntry = new EventEmitter();
  @Input() viewUpsiList;
  @Input() viewNoUpsi;
  public active1 = 1;
  noUpsiCreateForm: FormGroup;
  title: string;
  confupsilist: string;
  isLoading = false;
  button = 'Yes';
  submitted = false;
  constructor(private router: Router, private activateRouter: ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private upsiService: UpsiService, private fb: FormBuilder) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
  }

  ngOnInit(): void {
    this.itemSelected = this.selectedItems;
    this.noUpsiCreateForm = this.fb.group({
      noupsi: new FormControl(''),
      upsiCheckedList: new FormControl(''),
      reasonformove: new FormControl('', Validators.required)
    });

    if (this.viewNoUpsi == 'Non UPSI Communication') {
      this.title = 'This communication is determined to be Not Unpublished Price Sensitive Information (Not UPSI) for the following reason:';
      // this.confupsilist = 'Are you sure want the Moved to No UPSI List';
      this.confupsilist = 'Reason for marking communication as Not UPSI';
    } else {
      this.title = 'This communication is determined to be Unpublished Price Sensitive Information (UPSI) for the following reason:';
      this.confupsilist = 'Reason for marking communication as UPSI';

    }

  }
  // chooseAllItem(value){
  //   // this.onSubmitEntry.emit(value);
  //      this.itemSelected.push(this.choosedItems);
  //      console.log("this.itemAllSelected",this.itemSelected);

  //   this.onSubmitEntry.emit({itemSelected:this.itemSelected, value});
  //   this.activeModal.close(event);

  // }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();

  }
  noupsimail(item) {

    this.noUpsiCreateForm.value.upsiCheckedList = item;
    this.noUpsiCreateForm.value.noupsi = this.viewNoUpsi;

    this.upsiService.noupsimail(this.noUpsiCreateForm.value).subscribe(data => {

    })
  }
  onSubmit() {
    this.submitted = true;
    
    if (this.noUpsiCreateForm.status !== 'INVALID') {
      this.itemSelected.push(this.choosedItems);
      this.noUpsiCreateForm.value.upsiCheckedList = this.itemSelected;
      this.noUpsiCreateForm.value.noupsi = this.viewNoUpsi;
      this.isLoading = true;
      this.button = 'Processing';
      this.upsiService.updateNoUpsiModule(this.noUpsiCreateForm.value).subscribe(data => {
        this.isLoading = false;
        this.button = 'Yes';
        // this.toastr?.success("No UPSI Added Successfully");
        if (this.viewNoUpsi == 'No UPSI List') {
          this.toastr?.success("Successfully Added No UPSI List");
        } else {
          this.toastr?.success("Successfully Added UPSI List");
        }
        if (data.success = '1') {
          this.noupsimail(this.itemSelected);
        }
        window.location.reload();

      })
    } else {
      if (this.noUpsiCreateForm.get('reasonformove').value == '') {
        this.toastr.warning("Please Mention the Reason for Move " + this.confupsilist + ".");
      }
    }
  }
}
