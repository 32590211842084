import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DpModuleService } from '../dp-module.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-label-popup',
  templateUrl: './label-popup.component.html',
  styleUrls: ['./label-popup.component.scss']
})
export class LabelPopupComponent implements OnInit {
  // @Input() name;
  TaskList = [];
  countTask = 0;
  tasks = '';
  dpLabelListArr = [];
  submitted:boolean = false;
  isLoading = false;
  button = 'Add Label';
  @Output() onSubmitEntry = new EventEmitter();
  dbLabelCreateForm: FormGroup;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,private dpService:DpModuleService, private toastr: ToastrService, public router: Router) {
    
     }

  ngOnInit(): void {

    this.dbLabelCreateForm = this.fb.group({
      dp_labelname: new FormControl ('', Validators.required)
    })
  

  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

  // this mathed is use for the push element in to the array
  // addTask() {
  //   if( this.tasks != '' ) {
  //     this.TaskList.push(this.tasks);
  //     this.countTask = this.TaskList.length;
  //     this.tasks = '';
  //   this.onSubmitEntry.emit(this.TaskList);

  //   } else {
  //     alert("Please insert something!!!");
  //   }
  // }

  // removeTask(index) {
  //   this.TaskList.splice(index, 1);
  //   this.countTask = this.TaskList.length;
  // }

  ngSubmit(){
   this.submitted = true;
    if(this.dbLabelCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
    this.dpService.addDpLabelModule(this.dbLabelCreateForm.value).subscribe(data =>{
           
      const dpLabelListSend = data['data'];
      this.toastr.success("Label Name created", "Success!");
      this.isLoading = false;
      this.button = 'Add Label';
      this.onSubmitEntry.emit(data['data']);
      this.closeModal(event);
      // window.location.reload();
      // const taskList = data;
      // this.TaskList =taskList.map(result =>result);
      // console.log("this.TaskList", this.TaskList);
      
    }) 
  }else{
    if(this.dbLabelCreateForm.get('dp_labelname').value == ''){
      this.toastr.warning("Please fill the Label Name.");
    }

  }

  }

}
