import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthlogService {
  baseUrl = environment.apiUrl;
  paramQuery: any;
  constructor(private http:HttpClient, private activatedRoute: ActivatedRoute) {
    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   this.paramQuery = params.authToken
    //   console.log(params.authToken);
    // });
    
    
   }
  
  forgetPassword(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "auth-forget", userObj, {
      headers: {
        "Access-Control-Allow-Origin": '*',
     "Access-Control-Allow-Methods": 'POST',
      }
    });
  }


  forgetChangePassword(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "auth-newPassword", userObj, {
      headers: {
    //     "Access-Control-Allow-Origin": '*',
    //  "Access-Control-Allow-Methods": 'POST',
    //  'Authorization': sessionStorage.getItem('access_token')
      }
    });
  }
  otpVerifiedData(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "twofactor", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_tokens')}
    //   headers: {
    // //     "Access-Control-Allow-Origin": '*',
    // //  "Access-Control-Allow-Methods": 'POST',
    // //  'Authorization': sessionStorage.getItem('access_token')
    //   }
    });
  }
  get isLoggedIn(): boolean {
      let authToken = sessionStorage.getItem('access_token');
      return authToken !== null ? true : false;
    }
}
