import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { distinct, from, Observable, tap } from 'rxjs';
import { COMPANYDB, CompanyDB } from '../../shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { InsiderServiceService } from '../insider-service.service'
import { ActivatedRoute, Router } from '@angular/router';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import{ GlobalComponent } from '../../auth/authentication';
import { environment } from 'src/environments/environment';
import { PopupModelInsiderComponent } from '../popup-model-insider/popup-model-insider.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RevertInsiderComponent } from '../revert-insider/revert-insider.component';
export interface insider {
  id: number;
  name: string;
  relationship_with_company: string;
  confidentiality_agreement_entered: string;
  email: string;
}
@Component({
  selector: 'app-deleted-insider-module',
  templateUrl: './deleted-insider-module.component.html',
  styleUrls: ['./deleted-insider-module.component.scss']
})
export class DeletedInsiderModuleComponent implements OnInit {

  @ViewChild('auto') auto;
  public placeholder = 'Enter the Country Name';
  baseUrl = environment.apiUrl;
  keyword = 'name';
  keyword2 = 'relationship_with_company';
  keyword3 = 'email';
  public selected = [];
  page = 1;
  pageSize = 10;
  listinsider: any;
  accessSuccess: string;
  filterString = "";
  nameFilterString = '';
  nameFilterString1= '';
  nameFilterString2= '';
  nameFilterString4= '';
  nameFilterString5= '';
  filterString2 = "";
  filtered;
  filteredItem;
  searchValue: string;
  listinsiders: never[];
  filterDateItem: boolean = false;
  visible: boolean = false;
  visible2: boolean = false;
  visible3: boolean = false;
  visible4: boolean = false;
  visible5: boolean = false;
  selectedValue: any;
  searchValues: any;
  filteredList: any = [];
  filtered2: any = [];
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  insiderId: any;
  viewInsiderList: any;
  hidelist = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Relationship with Company"},{"id":4,"name":"Email ID"},{"id":5,"name":"Insider Type"},{"id":6,"name":"Confidentiality Agreement Entered"}];
  selectedhideItems = [];
  selected_contactNo:any;
  selected_address:any;
  show_contact=false;
  show_address=false;
  show_id = true;
  show_name = true;
  show_relationship_with_company = true;
  show_email = true;
  show_insiderType = false;
  show_confidentiality_agreement_entered = false;
  dropdownSettings1:IDropdownSettings;
  insiderNameFilter: any[];
  insiderRwcNameFilter: any[];
  insiderEmailNameFilter: any[];
  insiderTypeNameFilter: any[];
  insiderCaeNameFilter: any[];
  constructor(private insiderService: InsiderServiceService, private router: Router, private modalService: NgbModal, private activateRouter: ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
  }


  ngOnInit(): void {
   

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.insider_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.insider_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
    this.insiderService.deletedinsiderList(null).subscribe(
      res => {
        
          
        if(res.message == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          this.listinsider = res.data;
         
          this.onFilterChange()
          this.filtered = this.listinsider; 

          //name
          let insiderName = this.listinsider.map(res => res.name);
          this.insiderNameFilter = [];
          from(insiderName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderNameFilter.push(r));
          //relationship_with_company
          let insiderRwcName = this.listinsider.map(res => res.relationship_with_company);
          this.insiderRwcNameFilter = [];
          from(insiderRwcName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderRwcNameFilter.push(r));
          //email
          let insiderEmailName = this.listinsider.map(res => res.email);
          this.insiderEmailNameFilter = [];
          from(insiderEmailName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderEmailNameFilter.push(r));

          //Insider Type
          let insiderTypeName = this.listinsider.map(res => res.insiderType);
          this.insiderTypeNameFilter = [];
          from(insiderTypeName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderTypeNameFilter.push(r));

          //Confidencial Agreement
          let insiderCaeName = this.listinsider.map(res => res.confidentiality_agreement_entered);
          this.insiderCaeNameFilter = [];
          from(insiderCaeName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderCaeNameFilter.push(r));

        }
      },
      err => {
        console.log(err);
      }
    );
    this.selectedhideItems = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Relationship with Company"},{"id":4,"name":"Email ID"}];

  //multiseclector
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };  
  }
//MultiSelect hideShow
onItemSelect(item: any,page) {
  if(page == "Contact Number"){
   if(!this.selected_contactNo.includes(item)){
     this.selected_contactNo.push(item);
   }
 }else if(page == "Address"){
   if(!this.selected_address?.includes(item)){
     this.selected_address.push(item);
   }
 }else if(page == "column"){
   if(item.id == 1){
     this. show_id = true;
   }else if(item.id == 2){
     this.show_name = true;
   }else if(item.id == 3){
     this.show_relationship_with_company = true;
   }else if(item.id == 4){
     this.show_email = true;
   }else if(item.id == 5){
     this.show_insiderType = true;
   }else if(item.id == 6){
     this.show_confidentiality_agreement_entered = true;
   }
 }
}

onItemDeSelect(item: any,page) {

if(page == "Contact Number"){
   if(this.selected_contactNo.includes(item)){
     this.selected_contactNo.splice(this.selected_contactNo.indexOf(item), 1);
   }
 }else if(page == "Address"){
   if(this.selected_address.includes(item)){
     this.selected_address.splice(this.selected_address.indexOf(item), 1);
   }
 }else if(page == "column"){
   if(item.id == 1){
     this. show_id = false;
   }else if(item.id == 2){
     this.show_name = false;
   }else if(item.id == 3){
     this.show_relationship_with_company = false;
   }else if(item.id == 4){
     this.show_email = false;
   }else if(item.id == 5){
     this.show_insiderType = false;
   }else if(item.id == 6){
     this.show_confidentiality_agreement_entered = false;
   }
 }
 
}
onSelectAll(items: any,page) {
 if(page == "Contact Number"){
   this.selected_contactNo=items;
 }else if(page == "Address"){
   this.selected_address=items;
 }else if(page == "column"){
   this.show_id = true;
   this.show_name = true;
   this.show_relationship_with_company = true;
   this.show_email = true;
   this.show_insiderType = true;
   this.show_confidentiality_agreement_entered = true; 
 }
 
}
onDeSelectAll(items: any,page) {
if(page == "Contact Number"){
   this.selected_contactNo=[];
 }else if(page == "Address"){
   this.selected_address=[];
 }else if(page == "column"){
   this.show_id = false;
   this.show_name = false;
   this.show_relationship_with_company = false;
   this.show_email = false;
   this.show_insiderType = false;
   this.show_confidentiality_agreement_entered = false; 
 }
}

  onFilter() {
     
    let queryString = '';
    let url = this.baseUrl + 'insiderexcel';
    // let tokenStr = sessionStorage.getItem('access_token');
    // if(tokenStr){
    //   tokenStr = tokenStr.replace('Bearer ', '');
    // }
    // queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'sample.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    
  } 

  selectEvent(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      // console.log("item1235", this.nameFilterString)
      // console.log("item", insiderItem.name.toLocaleLowerCase().match(item.name.toLocaleLowerCase()))
      return insiderItem.name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible = false;
  }
  selectEvent2(item) {
    // do something with selected item
  
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      // console.log("item2", insiderItem?.relationship_with_company.toLocaleLowerCase().match(item.relationship_with_company.toLocaleLowerCase()))
      return insiderItem?.relationship_with_company.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible2 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.email.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible3 = false;
  }
  selectEvent4(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.insiderType.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible4 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.confidentiality_agreement_entered.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible5 = false;
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    console.log("String: " + search);
    
  }

  onFocused(e) {
    // do something
  }
  onCleared(e) {
    //console.log('input cleared');
    this.filtered = this.listinsider;
  }

  onBlur(e) {
    this.visible = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  onScrolledEnd() {
    // this.auto.close();
  }

  //2nd set
  onChangeSearch2(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    console.log("String: " + search);
    // this.auto.close();
  }

  onFocused2(e) {
    // do something
  }

  onScrolledEnd2() {
    // this.auto.close();
  }
  //(name, email) => this.isMatch(name) || this.isMatch(email)
  onFilterChange() {
    this.filtered = this.listinsider?.filter(res => {
     
      return res.name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.email.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.relationship_with_company.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.confidentiality_agreement_entered.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.Record.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    })

  }
  onFilterChange2() {
    this.filtered2 = this.listinsider?.filter(res => {
      
      return res.name.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase())
    })

  }
  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }

  }
  // search(){
  //   if(this.searchValue == ""){
  //     this.ngOnInit();

  //   }else{
  //     this.listinsiders = this.listinsider.filter(res =>{
  //       return res.name.toLocaleLowerCase().match(this.searchValue)
  //     })
  //   }
  // }

  // key:string;
  // reverse :boolean = false;
  // sort(key){
  //   this.key = key;
  //   this.reverse = !this.reverse;
  //   console.log("key", this.key)
  //   console.log("reverse", this.reverse)
  // }
  //(click)="sort('id')", <i class="fa fa-sort"></i>

  filterData() {
    this.visible = !this.visible;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData2() {
    this.visible2 = !this.visible2;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData3() {
    this.visible3 = !this.visible3;
    this.visible2 = false;
    this.visible = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData4() {
    this.visible4 = !this.visible4;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible5 = false;
  }
  filterData5() {
    this.visible5 = !this.visible5;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
  }

  openModal(id:any) {    
    if (id) {
      let insiderId = {'id': id};
      this.insiderService.viewInsider(insiderId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(PopupModelInsiderComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewInsiderList = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }

     
    
  }

  openModalRevert(id:any) {    
    if (id) {
      let insiderId = {'id': id};
      this.insiderService.viewInsider(insiderId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(RevertInsiderComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewInsiderList = data[0];
        modalRef.componentInstance.title = 'Insider';
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }
  }




}
