import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { distinct, from, Observable, ReplaySubject, tap } from 'rxjs';
import { MasterService } from 'src/app/master/master.service';
import { DpModuleService } from '../dp-module.service';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LabelPopupComponent } from '../label-popup/label-popup.component';
import { SharedDataService } from '../shared-data.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Item } from 'angular2-multiselect-dropdown';
import { gte } from '../custom-validator';
import { DpMailTemplateComponent } from '../dp-mail-template/dp-mail-template.component';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';
@Component({
  selector: 'app-db-module-add',
  templateUrl: './db-module-add.component.html',
  styleUrls: ['./db-module-add.component.scss']
})
export class DbModuleAddComponent implements OnInit {
  selectedRoleName = [];
  @ViewChild('teams') teams!: ElementRef;
  isLoading = false;
  button = 'Save';
  @ViewChild(LabelPopupComponent) child;
  TaskList = []
  dataList = [];
  queryString = sessionStorage.getItem('access_token');
  submitted:boolean = false;
  dbCreateForm: FormGroup;
  attachName: any;
  path: any;
  attachmentPath = false;
  listProject: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownSettings1 = {};
  dropdownListSelf = [];
  selectedItemsSelf  = [];
  dropdownSettingsSelf  = {};
  labelDataList = [];
  labelDataList1 = [];
  selectedLabelDataItems = [];
  selected_label = [];
  image: string | ArrayBuffer;
  //regex:any;
  // regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
  // regex2 = "^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$";
   regex = "^[+]{1}(?:[0-9\-\(\)\/\.]\s?){6, 15}[0-9]{1}$";
   //regex = new RegExp(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$);

  labelDataList3 = [];
  rolesNameFilter = [];
  keyword = "role_name"
//tab set
currentJustify = 'start';
currentOrientation = 'horizontal';

public active1 = 1;
disabled = true;
  annualYear: any;

//tab set
financialYears = [];
  year: string;
  accessSuccess: string;
  listroles: any;
  projectVal: any;
  project_val: boolean = false;
  pathImage: any;
  listDpModule: any;
  labelData = [];
  message: string;
  hrefUrl: string;
  mailUrl: string;
  companyname: string;
  selectedItemRole: any;
  myDate: any;
//  financialYears_array = [];
currentDate = new Date();
activeProjects = [];
messageValid = '';
d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
];

p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
];
  selectedDocumentType: AbstractControl<any, any>;
  requiredValue: string;
  modelAccessControl: {};
  otherscategoryPersonFormC = false;
  selectedcategoryPersonFormC: any;
  openDpLabel = false;
  mailPopupModel = environment.mailPopupModel;
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  preclearanceAccess: boolean;
  constructor(private datePipe: DatePipe,private fb: FormBuilder, private modalService: NgbModal, private dpService:DpModuleService, private toastr: ToastrService, public router: Router, private masterService: MasterService,
    private service: SharedDataService, private upsiService: UpsiService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    // const projectVal = this.dbCreateForm.get('accessToSdd').value;
    // if(projectVal == "Active"){
    //   this.project_val = true;
    // }else{
    //   this.project_val = false;
    // }
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
   
   }

  //Immediate Relative
  createImmediateRelative(){
    return this.fb.group({
      name: new FormControl(''),
      relationship: new FormControl(''),
      phone_no: new FormControl('', Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")),
      pan: new FormControl(''),
      DocumentType: new FormControl('')
    })
  }
  addInputControls(){
    // this.procedureCont = true;    
    const add = this.dbCreateForm.get('immediateDpRelative') as FormArray;

    add.push(this.createImmediateRelative())
   
  }
  removeInputControls(idx: number){
    
    if(this.dbCreateForm.get('immediateDpRelative').value.length <= 1){
      const add = this.dbCreateForm.get('immediateDpRelative') as FormArray;    
     add.reset();
    
    }else{
      const add = this.dbCreateForm.get('immediateDpRelative') as FormArray;  
      add.removeAt(idx); 
    }
      
  }
//ImmediateRelative

//Annual Confiremation
createAnnualConfirmation(){
  return this.fb.group({
    financialYearSymbol: new FormControl('FY'),
    financialYear: new FormControl(''),
    lastConformedDate: new FormControl(''),
  })
}
addInputControlsAnnual(){
  // this.procedureCont = true;
  const add = this.dbCreateForm.get('annualConfirmation') as FormArray;
  add.push(this.createAnnualConfirmation())
  for(let i=2019; i<2100; i++){
    this.year = '{' + i + '-' + (i+1) + '}' 
    this.financialYears.push (this.year);
 
}
const listprocedureremove = this.financialYears.map(({}) =>({linkedName: this.year}));
  listprocedureremove.forEach(data =>{
    this.annualYear = data;
 })

 
}

getFinancialYear(){ 


}
removeInputControlsAnnual(idx: number){
  const add = this.dbCreateForm.get('annualConfirmation') as FormArray;  
  add.removeAt(idx);   
}

//Annual Confirmation
//  getCurrentFiscalYear() {
//     //get current date
//     var today = new Date();
     
//     //get current month
//     var curMonth = today.getMonth();
     
//     var fiscalYr = "";
//     if (curMonth > 3) { //
//         var nextYr1 = (today.getFullYear() + 1).toString();
//         fiscalYr = today.getFullYear().toString() + "-" + nextYr1.charAt(2) + nextYr1.charAt(3);
//     } else {
//         var nextYr2 = today.getFullYear().toString();
//         fiscalYr = (today.getFullYear() - 1).toString() + "-" + nextYr2.charAt(2) + nextYr2.charAt(3);
//     }
     
//     document.write(fiscalYr);
//     console.log("fiscalYr", fiscalYr )
//  }
//  financialYear = this.getCurrentFiscalYear();


  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.dplabel_access == true || accessModelController.dplabel_access == 1){
       this.openDpLabel = true;
    }else{
       this.openDpLabel = false;
    }
    if(accessModelController.preclearance_access == true || accessModelController.preclearance_access == 1){
      this.preclearanceAccess = true;
    }else{
      this.preclearanceAccess = true;
    }
    
    this.hrefUrl = environment.href;
    this.mailUrl = environment.mail;
    this.companyname = environment.companyName;
    this.dbCreateForm = this.fb.group({      
      dpName: new FormControl('',  [Validators.required,Validators.pattern("[a-zA-Z _.,-]*")]),
      dpDesignation: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]), 
      email: new FormControl ('', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-zA-Z/\| ]{2,}$')]),
      //email: new FormControl ('', [Validators.required,  Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      dpPanNo: new FormControl ('', [Validators.required]),
      DocumentType: new FormControl ('', Validators.required),
      dpContactNo: new FormControl ('',  [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      //dpContactNo: new FormControl ('',  [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      // withWhom: new FormControl ('',  Validators.required),
      dpEducationalInstitute: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpAddress: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')]),
      dpClientId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      dpIndexId: new FormControl('', [Validators.minLength(8),Validators.maxLength(8),Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
       groupLabelName: new FormControl(''),
      dpPastEmp: new FormControl ('',  [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      remarks: new FormControl ('',Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
      confidentiality_agreement_attachment: new FormControl (''),
      filePath: new FormControl (this.path),
      image: new FormControl(''),
      pathImage:new FormControl(''),
      immediateDpRelative : new FormArray([this.createImmediateRelative()]),
      //annualConfirmation : new FormArray([this.createAnnualConfirmation()]),
      confidentiality_agreement_entered: new FormControl ('No',  Validators.required),
      confidentiality_agreement_date: new FormControl(''),
      accessToSdd: new FormControl ('Inactive', Validators.required),
      // access_select_project: ['Project'],
      project: new FormControl(''),
      self_records: new FormControl(false),
      role: new FormControl(''),
      categoryofperson: new FormControl (''),
      otherscategoryofperson: new FormControl ('',Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$'))
    });
  
 

    this.modelAccessControl = {"upsi_name":null,"upsi_access":true,"dp_access":true,"dp_name":null,"insider_access":true,"insider_name":null,"project_access":true,"project_name":null,"company_access":true,"company_name":null,"roles_access":true,"roles_name":null,"activity_access":true,"activity_name":null,"reports_access":true,"reports_name":null,"twc_access":true,"twc_name":null,"preclearance_access":true,"preclearance_name":null,"benpos_access":true,"benpos_name":null,"formcord_access":false,"formcord_name":null,"mailtemplate_access":true,"mailtemplate_name":null,"rules_access":true,"rules":null,"companys_lists_access":false,"companys_lists":"Company's Lists","customer_access":true,"customer_lists":"Customers","upsiattachment_access":false,"upsiattachment_lists":"UPSI Attachment"}
    
    let requestType = {'requestType' : 'DPProjects'}
    this.upsiService.projectList(requestType).subscribe(
      res => {
      
      let listProject = res.data;
   
      // const procedureList = this.listProject?.map(({id,project_name,durationfrom,durationto}) =>({id,itemName: project_name,durationfrom,durationto}));
      const procedureList = listProject?.map(({id,project_name}) =>{
        this.dropdownList.push({id,itemName: project_name}); 

      });
     
      // procedureList.map((projectItem) => {
      //   this.activeProjects = [];
      //   let completedProjectItems = [];
      //   if(projectItem.durationto < this.myDate){            
      //     completedProjectItems.push(projectItem); 
                     
      //   }else{
      //     this.dropdownList.push(projectItem); 
     
      //   } 
      // });
      // const procedureList = this.activeProjects?.map(({id,project_name}) =>({id,itemName: project_name}));
      // this.dropdownList = [];
      // procedureList.forEach(result =>this.dropdownList.push(result))  
      // console.log("dropdownList", this.dropdownList)
        },
        err => {
            console.log(err);
        }
    );
  //roles and permission
  this.masterService.roleNamesList(null).subscribe(
    res => {
    this.listroles = res.body['data'];
    let rolesName = this.listroles?.map(res =>res.role_name)
    this.rolesNameFilter = [];
    from(rolesName)
    .pipe(
    tap(item => item),
    distinct(d => d)
    )
    .subscribe(r => this.rolesNameFilter.push(r));

      },
      err => {
          console.log(err);
      }
  );


//label api

// this.dpService.listDpLabelModule().subscribe(
//   res => {
    
//     if(res.body['message'] == 'Invalid User.'){
//       this.router.navigate(['/auth/login']);
//     }else{
//   const listDpLabelModule = res.body['data'];
//   const labelDataListres = res.body['data'];
//   this.labelDataList = [];
//   const labelDatas = labelDataListres?.map(({id,dp_labelname}) =>({item_id: id,item_text: dp_labelname}));
//   this.labelDataList = [];
//   labelDatas.forEach(result =>this.labelDataList.push(result))
//     }
//     },
//     err => {
//         console.log(err);
//     }
// );


  //Multi select box
  // this.dropdownList = [
  //   // { id: 1, itemName: 'Project 1' },
  //   // { id: 2, itemName: 'Project 2' },
  //   // { id: 3, itemName: 'Project 3' },
  //   // { id: 4, itemName: 'Project 4' },
  //   // { id: 5, itemName: 'Project 5' }
  // ];
  this.selectedItems = [
    // { id: 2, itemName: 'Singapore' },
    // { id: 3, itemName: 'Australia' },
    // { id: 4, itemName: 'Canada' },
    // { id: 5, itemName: 'South Korea' }
  ];
  this.dropdownSettings = {
    singleSelection: false,
    text: 'Select the Project Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class'
  };
  this.labelDataList = [];
  this.selectedLabelDataItems = [];
  //Multi select box
// LabelMultiselect
this.dropdownSettings1 = {
  // singleSelection: false,
  // idField: 'item_id',
  // textField: 'item_text',
  // selectAllText: 'Select All',
  // unSelectAllText: 'UnSelect All',
  // itemsShowLimit: 2,
  // allowSearchFilter: true,
  singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,

};
  //dplist
  this.dpService.dpModuleList(null).subscribe(
    res => {
    
    this.listDpModule = res['data']
     

      },
      err => {
          console.log(err);
      }
  );
 //label List
 this.service.listDpLabelModule.subscribe(
  res => {
    this.labelDataList = [];
    let labelDataList1 = [];
    
    labelDataList1 = res?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id: id,item_text: dp_labelname}));
    from(labelDataList1)
    .pipe(
    tap(item => item),
    distinct(d => d.item_text)
    )
    .subscribe(r => this.labelDataList.push(r));

  }
);
  
//db

  }
 
  chnageIdentifer(){
    // let selectedDocumentType = this.dbCreateForm.get('DocumentType')
    // console.log(selectedDocumentType)
    // if(selectedDocumentType.value == 'PAN'){      
    //   this.regex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
    //   this.dbCreateForm.get('dpPanno').validator(this.regex)
    //   this.requiredValue = "Please Enter the Valid PAN";
    //   console.log("regex", this.regex)
    // }else if(selectedDocumentType.value == 'Aadhar card'){
     
    //   this.regex = "^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$";
    //   this.requiredValue = "Please Enter the 12 Digit Aadhar Number";
    //   console.log("regex", this.regex)
  
    // }
  
  }
//   onSelected(item){
//     this.selectedItemRole = {role: item}

//     // let item1 = this.listroles.data.find(i => i.role_name === this.teams.nativeElement.value);
//     // // console.log("procedureList", item1)
//     // const procedureList = this.listroles.data.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
//     // if(item1.role_name === this.teams.nativeElement.value){
//     //   const procedureList = item1.project;
     
//     //   this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
//     //   console.log("123", this.selectedItems)
//     //   return false;
//     // }else{    console.log("Error");
//     // }
    
//     this.dpService.onChangeRoleModule(this.selectedItemRole).subscribe(data =>{
     
//       const listRoleItem = data;
//       let item1 = this.listroles.data.find(i => i.role_name === item);
  
//     const procedureList = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
    
//     const splitData = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,project_name: project_name}));
    
// let v = null;
// if(v !== splitData[0].project_name ){
//   if(item1.role_name === item){
//     const procedureList = listRoleItem.data;   
//     this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));  
//     return false;
//   }else{    console.log("Error");
//   }
    
//   }else{
//     this.selectedItems = [];
    
//   }
  
//     });
  
//   }

//Ng-Select

onSelected(items){
  this.selectedItemRole = {role: items}
  
  // let item1 = this.listroles.data.find(i => i.role_name === this.teams.nativeElement.value);
  // // console.log("procedureList", item1)
  // const procedureList = this.listroles.data.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
  // if(item1.role_name === this.teams.nativeElement.value){
  //   const procedureList = item1.project;
   
  //   this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
  //   console.log("123", this.selectedItems)
  //   return false;
  // }else{    console.log("Error");
  // }
  
  this.dpService.onChangeRoleModule(this.selectedItemRole).subscribe(data =>{
   
    const listRoleItem = data;
    let item1 = this.listroles.find(i => i.role_name === items);

  const procedureList = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));
  
  const splitData = listRoleItem.data.map(({project_id,project_name}) =>({id: project_id ,project_name: project_name}));
  
let v = null;
if(v !== splitData[0]?.project_name ){
if(item1.role_name === items){
  const procedureList = listRoleItem.data;   
  this.selectedItems = procedureList?.map(({project_id,project_name}) =>({id: project_id ,itemName: project_name}));  
  return false;
}else{    console.log("Error");
}
  
}else{
  this.selectedItems = [];
  
}

  });

}

  base64Output : string;

  filePath(){
    if(this.dbCreateForm.get('confidentiality_agreement_attachment').value != ''){
    this.attachName = this.dbCreateForm.get('confidentiality_agreement_attachment').value;
    
   // this.path =  this.attachName.split("\\").pop();
    this.path =  this.attachName[0].name;
   
    }
    this.attachmentPath = true;
  }
  removeFile(){
    this.dbCreateForm.get('confidentiality_agreement_attachment').setValue("");
    this.path = "";
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;
    
  }
  
  onFileSelected(event) {
    this.filePath()
      this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;   
    
    });
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(reader.result.toString()));
    return result;
  }
  changeListener($event) : void {   
    this.readThis($event.target);
    this.filePath()
    //this.dbCreateForm.get('confidentiality_agreement_attachment').setValue(File);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];   
    var myReader:FileReader = new FileReader();   
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());   
      this.dbCreateForm.value.image=myReader.result;
      this.pathImage = this.dbCreateForm.value.image;
    }
  }
  
  //Multi select box
onItemSelect(item: any) {
  
}
OnItemDeSelect(item: any) {
  
}
onSelectAll(items: any) {
}
onDeSelectAll(items: any) {
}
//multi select box
 
// Multi Select
onItemSelect1(item: any,page) {
  
  if(page == "label"){
   if(!this.selected_label.includes(item)){
     this.selected_label.push(item);
   }
 }
}

onItemDeSelect1(item: any,page) {

 if(page == "label"){
   if(this.selected_label.includes(item)){
     this.selected_label.splice(this.selected_label.indexOf(item), 1);
   }
 }
}
onSelectAll1(items: any,page) {
if(page == "label"){
   this.selected_label=items;
 }
 
}
onDeSelectAll1(items: any,page) {
if(page == "label"){
     this.selected_label=[];
   }
}

//radio Button
  selectedButton: string="No";        

  radioSelect(e: string): void {
      this.selectedButton = e; 
      if(this.selectedButton !== "Yes"){        
        this.dbCreateForm.get('role').setValue("");
        this.dbCreateForm.get('project').setValue("");
        this.dbCreateForm.get('self_records').setValue("0");    
        this.attachmentPath = false;
      }

  }  
  
  isSelected(name: string): boolean {  
  
      if (!this.selectedButton) {   
          return false;  
      }    
  
      return (this.selectedButton === name); 
  } 
//radio Button
SelectAccessSdd(){
  
}
//Checkbox Button
  selectedButtonChecked: string="Project";        

  radioSelect1(e: string): void {
      this.selectedButtonChecked = e;  
  }  
  
  isSelected1(name: string): boolean {  
  
      if (!this.selectedButtonChecked) {   
          return false;  
      }    
  
      return (this.selectedButtonChecked === name); 
  } 
//Checkbox Button
//radio Button
selectedButton2: string="No";        

radioSelect2(e: string): void {
    this.selectedButton2 = e;  
    if(this.selectedButton !== "Yes"){
      this.dbCreateForm.get('confidentiality_agreement_attachment').setValue("");
      this.dbCreateForm.get('confidentiality_agreement_date').setValue("");
      this.dbCreateForm.get('filePath').setValue("");   
      this.attachmentPath = false;
    }
}  

isSelected2(name: string): boolean {  

    if (!this.selectedButton2) {   
        return false;  
    }    

    return (this.selectedButton2 === name); 
} 
//radio Button

//tab set
onNavChange1(changeEvent: NgbNavChangeEvent) {
  if (changeEvent.nextId === 3) {
    changeEvent.preventDefault();
  }
}

onNavChange(changeEvent: NgbNavChangeEvent) {
  if (changeEvent.nextId === 3) {
    changeEvent.preventDefault();
  }
}
//tab set
//Accordion
public beforeChange(e) { }
//Accordion
// accessFunction(){
//   if(this.dbCreateForm.get('accessToSdd').value == 'Inactive'){
//     this.dbCreateForm.get('role').value.reset();
//     this.dbCreateForm.get('project').value.reset();
//     console.log("HII", this.dbCreateForm.get('role').value)
//   }
// }

//role
onBlur(): void {
  // console.log('Focus Is Lost for this Element');
}
//role
selectEvent(item) {
}

onChangeSearch(search: string) {
}

onFocused(e) {
  // do something
}

//AAdharValidation
validate(addharNumber) {
  let c = 0;
  let invertedArray = addharNumber
    .split('')
    .map(Number)
    .reverse();

  invertedArray.forEach((val, i) => {
    c = this.d[c][this.p[i % 8][val]];
  });

  return c === 0;
}

// verify(adhaarNo) {
//   this.message = adhaarNo.value;
//   if (this.validate(adhaarNo.value) && adhaarNo.value) {
//     this.messageValid = 'Your Aadhar Number is Valid';
//   } else {
//     this.messageValid = 'Your Aadhar Number is InValid';
//   }
// }
ValidateId(idendiferCard) { 

 
  let idendiferCheck = this.dbCreateForm.get('DocumentType').value;
  
  if(idendiferCheck == "PAN"){
    
    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
    if (!regex.test(idendiferCard)) {
      this.toastr.warning("Please Enter the Valid PAN.");
      this.messageValid = 'Please Enter the Valid PAN.';
    }
  }else if(idendiferCheck == "Aadhar card"){

    this.messageValid = "Please Enter the Valid Aadhar Number'";
    if (this.validate(idendiferCard) && idendiferCard) {
      this.messageValid = 'Your Aadhar Number is Valid';
     // this.toastr.warning("Please fill the Document Number.");
    } else {
      this.messageValid = 'Your Aadhar Number is InValid';
      this.toastr.warning(this.messageValid);
    }
   
    
  }
  


}
otherCategoryOfPersonFormC() {
  this.selectedcategoryPersonFormC = this.dbCreateForm.get('categoryofperson').value;
  if(this.selectedcategoryPersonFormC == "Others"){
    this.otherscategoryPersonFormC = true;
  }else{
    this.otherscategoryPersonFormC = false;
    this.dbCreateForm.get('otherscategoryofperson').setValue('');
  }
}

  cancelDpList(){
    this.router.navigate(['/dp/dp-list']);
    this.dbCreateForm.reset();
    this.submitted = false;
    
  }
    onSubmit(){

      let idendiferCard = this.dbCreateForm.get('dpPanNo').value;
      if(this.dbCreateForm.get('DocumentType').value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.dbCreateForm.get('DocumentType').value == 'Aadhar card'){
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
          console.log('');
        } else {
          this.toastr.warning('Your Aadhar Number is InValid')
          return false;      
        }
      }
    }else if(this.dbCreateForm.get('DocumentType').value == 'Voter ID'){
      let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter Valid Voter ID');
        return false;
      }
  }else if(this.dbCreateForm.get('DocumentType').value == 'Driving License'){
    let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter Valid Driving License');
        return false;
      }
  }
  if(this.dbCreateForm.get('confidentiality_agreement_entered').value == 'Yes'){
    if(this.dbCreateForm.get('confidentiality_agreement_date').value == null || this.dbCreateForm.get('confidentiality_agreement_date').value == ''){
      this.toastr.warning('Please Enter the Confidentiality Agreement Date');
      return false;
    }
  }
//immediate relative
  if(this.dbCreateForm.get('immediateDpRelative')['controls'].length > 0){
    for(let i=0; i<this.dbCreateForm.get('immediateDpRelative')['controls'].length; i++){
      if(this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value != '' && this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value ==''){
        this.toastr.warning( 'Enter the ' + [i + 1] + ' row ID Proof No.')
          return false;  
      }

      let idendiferCard = this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].pan.value;
      if(this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'PAN'){
        
        let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/); 
        if (!regex.test(idendiferCard)) {
          this.toastr.warning("Please Enter the "+ [i + 1] + " row Valid PAN.");
         // this.messageValid = 'Please Enter the Valid PAN.';
         return false;
        }
      }else if(this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Aadhar card'){
        let regex = new RegExp(/^[A-Z]{0}[0-9]{12}[A-Z]{0}$/);
        if(!regex.test(idendiferCard)){
          this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Aadhar Number');
          return false;
        }else{
        if (this.validate(idendiferCard) && idendiferCard) {         
          console.log('');
        } else {
          this.toastr.warning( [i + 1] + ' row Your Aadhar Number is InValid')
          return false;      
        }
      }
    }else if(this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Voter ID'){
      let regex = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Voter ID');
        return false;
      }
  }else if(this.dbCreateForm.get('immediateDpRelative')['controls'][i]['controls'].DocumentType.value == 'Driving License'){
    let regex = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/);
      if(!regex.test(idendiferCard)){
        this.toastr.warning('Please Enter ' + [i + 1] + ' row Valid Driving License ');
        return false;
      }
  }
    }

  }

      if(this.dbCreateForm.get('accessToSdd').value == 'Active'){
        this.dbCreateForm.value.modelAccessControl = this.modelAccessControl
        if(this.dbCreateForm.get('role').value == ''){   
          this.dbCreateForm.get('role').value.error ;
          alert( "Please provide your Role Type!" );
          return false;
        }
     
      }
      this.submitted = true;
      if(this.dbCreateForm.value.self_records == true){
        this.dbCreateForm.value.self_records = 1;
      }else{
        this.dbCreateForm.value.self_records = 0;
      }
      const emptyFileds = Object.keys(this.dbCreateForm.controls).filter(key =>this.dbCreateForm.controls[key].errors !== null)

     
     if(this.dbCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.dpService.addDpModule(this.dbCreateForm.value).subscribe(data =>{  
        
        if (data?.success == '1') {
          this.toastr.success("Dp Successfully Created");
          this.isLoading = false;
        this.button = 'Save';
        if(this.mailPopupModel == 'LSTEST'){
          if(data.mailTemplate !=''){
            const modalRef = this.modalService.open(DpMailTemplateComponent,
              {
                scrollable: true,
                windowClass: 'myCustomModalClass',
                // keyboard: false,
                // backdrop: 'static'
              });
            modalRef.componentInstance.viewDbmailTemplate = data.mailTemplate;
            modalRef.result.then(
              (result) => {
                console.log(result);
              }, (reason) => {
                console.log(reason);
              });
          }
        }
          this.router.navigate(['/dp/dp-list']);

        } else{
          this.toastr.warning(data?.message);
          this.isLoading = false;
        this.button = 'Save';
        }
      })   
      
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fields.");
      return false;
    } else{
      if(this.dbCreateForm.get('dpName').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.dbCreateForm.get('dpDesignation').value == ''){
        this.toastr.warning("Please fill the Designation.");
      }
      else if(this.dbCreateForm.get('email').value == ''){
        this.toastr.warning("Please fill the Email ID.");
      }
      else if(this.dbCreateForm.get('dpContactNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }
      else if(this.dbCreateForm.get('dpPanNo').value == ''){
        this.toastr.warning("Please fill the ID Proof Type.");
      }
      
      else if(this.dbCreateForm.get('dpAddress').value == ''){
        this.toastr.warning("Please fill the Address.");
      }
      else if(this.dbCreateForm.get('dpEducationalInstitute').value == ''){
        this.toastr.warning("Please fill the Educational Institute Name, Last Graduated From.");
      }
      else if(this.dbCreateForm.get('dpPastEmp').value == ''){
        this.toastr.warning("Please fill the Company Name of Previous Job.");
      }else if(this.dbCreateForm.get('DocumentType').value == ''){
        this.toastr.warning("Please fill the ID Proof No.");
      }
      // else if(this.dbCreateForm.get('categoryofperson').value == ''){
      //   this.toastr.warning("Please fill the Category of Person.");
      // }
      
      
    }
  }

//formarray
// getAtributeDisplayNameAt(index: number) {
//   return this.items
//     ? (this.items.at(index).get('attributeDisplayName') as FormControl)
//     : null;
// }
// validateUniq(index) {
//   return (control: AbstractControl) => {
//     if (control.value) {
//       const formArray = control.parent
//         ? (control.parent.parent as FormArray)
//         : null;
//       if (formArray) {
//         const attributes = formArray.value.map((x) => x.attributeDisplayName);
//         return attributes.indexOf(control.value)>=0 && attributes.indexOf(control.value)<index
//           ? { duplicateName: true }
//           : null;
//       }
//     }
//   };
// }
// checkDuplicacy(index) {
//   this.items.controls.forEach((x,i)=>{
//     if (index!=i)
//       (x as FormGroup).get('attributeDisplayName').updateValueAndValidity()
//   })
// }

// @HostListener('document:keyup.escape') onClose() {
//   this.onCancel();
// }
dpLabelChange(event){
  console.log(event)
}

open() {
  const modalRef = this.modalService.open(LabelPopupComponent,
  {
    scrollable: true,
    windowClass: 'myCustomModalClass',
    // keyboard: false,
    // backdrop: 'static'
  });
  modalRef.componentInstance.name = 'Label';
  modalRef.componentInstance.onSubmitEntry.subscribe((receivedEntry) => {
  
    this.labelDataList = receivedEntry?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id: id,item_text: dp_labelname}));
  })
}



}

