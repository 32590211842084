import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prepdf-view-format',
  templateUrl: './prepdf-view-format.component.html',
  styleUrls: ['./prepdf-view-format.component.scss']
})
export class PrepdfViewFormatComponent implements OnInit {

  @Input() statusPreclerance;
  @Input() pdfPrecleranceid;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getId: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  complianceOfficerEmail: any;
  complianceOfficerName: any;
  complianceOfficerDesignation: any;
  companyName: any;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, private companyService: CompanyService ) { }

  ngOnInit() {
  //  console.log(this.pdfPreclerance);
  // this.pdfFormTrading = this.fb.group({
  //   id: new FormControl(''),
  //   formTypes: new FormControl('')
  // });
 this.getId = this.pdfPrecleranceid;
    
 this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
    }
    },
    err => {
        console.log(err);
    }
);
  }
  
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onFilterPdf() {
    
    let queryString = '';
    let url = this.baseUrl + 'preclearancepdf?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.getId || this.complianceOfficerEmail )  {
      queryString = 'postId=' + this.getId + '&complianceofficeremail=' + this.complianceOfficerEmail + '&complianceofficername=' + this.complianceOfficerName + '&complianceofficerdesignation=' + this.complianceOfficerDesignation + '&companyname=' + this.companyName ;

      queryString = queryString + '&authToken='+btoa(tokenStr);
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    this.activeModal.close(event);
     
  }

}
