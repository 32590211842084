<div class="modal-header">
    <h5 class="modal-title">View Pre Clearance Previous Trading</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                            <tbody *ngIf="viewPreviousTradingList">
                                <tr>
                                    <td scope="row"> Name of holder</td>
                                    <td scope="row">{{viewPreviousTradingList.name}}</td>                                         
                                 </tr>
                                <tr>
                                    <td scope="row"> No of securities dealt with</td>
                                    <td scope="row">{{viewPreviousTradingList.no_of_securirities}}</td>                                         
                                 </tr> 
                                 <tr>
                                    <td scope="row">Date of transaction</td>
                                    <td scope="row">{{viewPreviousTradingList.date_of_transaction}}</td>                                         
                                 </tr> 
                                <tr>
                                    <td scope="row"> Sale/ Purchase/ Subscription</td>
                                    <td scope="row">{{viewPreviousTradingList.bought_sold_subscribed}}</td>                                         
                                 </tr> 
                                <tr>
                                    <td scope="row"> DP/ Client ID</td>
                                    <td scope="row">{{viewPreviousTradingList.dp_client_id}}</td>                                         
                                 </tr> 
                                <tr>
                                    <td scope="row"> Price in Rs</td>
                                    <td scope="row">{{viewPreviousTradingList.price}}</td>                                         
                                 </tr> 
                                <tr *ngIf="viewPreviousTradingList.extra_shares_value != ''">
                                    <td scope="row"> Remarks</td>
                                    <td scope="row">{{viewPreviousTradingList.extra_shares_value}}</td>                                         
                                 </tr> 
                                                                                                  
                                 <tr>
                                   <td scope="row">Created By</td>
                                   <td scope="row">{{viewPreviousTradingList.created_by}}</td>                                         
                                </tr>
                                <tr>
                                   <td scope="row">Created On</td>
                                   <td scope="row">{{viewPreviousTradingList.created_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                </tr>                                
                           
                        </tbody>
                    </table>
                        <!-- <table class="table">                            
                                <tbody *ngIf="viewPreviousTradingList">
                                    <tr>
                                        <td scope="row"> Name</td>
                                        <td scope="row">{{viewPreviousTradingList.name}}</td>                                         
                                     </tr>
                                    <tr>
                                        <td scope="row"> PAN</td>
                                        <td scope="row">{{viewPreviousTradingList.panNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> CIN/DIN Number</td>
                                        <td scope="row">{{viewPreviousTradingList.cin_din}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Contact Number</td>
                                        <td scope="row">{{viewPreviousTradingList.mobNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row"> Address</td>
                                        <td scope="row">{{viewPreviousTradingList.address}}</td>                                         
                                     </tr> 
                                    <tr *ngIf="viewPreviousTradingList.categoryPerson != ''">
                                        <td scope="row"> Category of Person</td>
                                        <td scope="row">{{viewPreviousTradingList.categoryPerson}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row" colspan="2">Securities held prior to acquisition/ disposal</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewPreviousTradingList.typeOfSecurities}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No. of share holding</td>
                                        <td scope="row">{{viewPreviousTradingList.perShareNumber}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) % of share holding</td>
                                        <td scope="row">{{viewPreviousTradingList.perShareHolding}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Transaction Type</td>
                                        <td scope="row">{{viewPreviousTradingList.transactionType}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2">Securities acquired/Disposed</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewPreviousTradingList.typeOfSecurities2}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No.</td>
                                        <td scope="row">{{viewPreviousTradingList.typeOfSecuritiesNo}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) Value</td>
                                        <td scope="row">{{viewPreviousTradingList.typeOfSecuritiesValue}}</td>                                         
                                     </tr> 
                                    
                                     <tr>
                                        <td scope="row" colspan="2">Securities held post acquisition/ disposal</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) Type of securities</td>
                                        <td scope="row">{{viewPreviousTradingList.typeOfSecuritiespost}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) No. of share holding</td>
                                        <td scope="row">{{viewPreviousTradingList.perShareHoldingNopost}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">c) % of share holding</td>
                                        <td scope="row">{{viewPreviousTradingList.perShareHoldingpost}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2">Date of allotment advice/ acquisition of shares/ disposal of shares, specify</td>                                                                                 
                                     </tr> 
                                    <tr>
                                        <td scope="row">a) From Date</td>
                                        <td scope="row">{{viewPreviousTradingList.fromDate}}</td>                                         
                                     </tr> 
                                    <tr>
                                        <td scope="row">b) To Date</td>
                                        <td scope="row">{{viewPreviousTradingList.toDate}}</td>                                         
                                     </tr>                                    
                                    <tr>
                                        <td scope="row"> Mode of acquisition /disposal</td>
                                        <td scope="row">{{viewPreviousTradingList.modeOfAcquisition}}</td>                                         
                                     </tr>                                     
                                     <tr>
                                       <td scope="row">Created By</td>
                                       <td scope="row">{{viewPreviousTradingList.created_by}}</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Created On</td>
                                       <td scope="row">{{viewPreviousTradingList.created_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                    </tr>                                
                               
                            </tbody>
                        </table> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
