import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dp-mail-template',
  templateUrl: './dp-mail-template.component.html',
  styleUrls: ['./dp-mail-template.component.scss']
})
export class DpMailTemplateComponent implements OnInit {
  @Input() viewDbmailTemplate;
  mailTemplate: any;
  constructor(public activeModal: NgbActiveModal,  private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {
    this.mailTemplate = this.viewDbmailTemplate;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }
}
