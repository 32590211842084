import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-insider-component',
  templateUrl: './error-insider-component.component.html',
  styleUrls: ['./error-insider-component.component.scss']
})
export class ErrorInsiderComponentComponent implements OnInit {
  @Input() viewInsiderErrorList;
  page = 1;
  pageSize = 10;
  constructor(public activeModal: NgbActiveModal, private router:Router) { }

  ngOnInit(): void {
    //this.viewDpErrorList;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }
}
