import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DpModuleService } from '../dp-module.service';
import { PopupModelDpComponent } from '../popup-model-dp/popup-model-dp.component';
import { RevetDpModuleComponent } from '../revet-dp-module/revet-dp-module.component';

export interface dp{
  id: number;
  dpName: string;
  dpDesignation: string;
  confidentiality_agreement_entered: string;
  email: string;
  dpPanNo: number;
  dpContactNo: number;
}

@Component({
  selector: 'app-deleted-dp-list',
  templateUrl: './deleted-dp-list.component.html',
  styleUrls: ['./deleted-dp-list.component.scss']
})
export class DeletedDpListComponent implements OnInit {
  public selected = [];
  @ViewChild('auto') auto;
  page = 1;
  pageSize = 10;
  isDisabled = true;
  listDpModule: any;
  userPermission = 'none';
  accessSuccess: string;
  filterString = "";
  filtered;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;
  hidelist = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":4,"name":"Confidentiality Agreement Entered "},{"id":5,"name":"Email"},{"id":6,"name":"Contact Number"},{"id":7,"name":"Address"},{"id":8,"name":"Identifier Number"},{"id":9,"name":"Educational Institute"},{"id":10,"name":"Past Emplaoyee"},{"id":11,"name":"Remarks"}];
  selectedhideItems = [];
  selected_contactNo:any;
  selected_address:any;  
  show_id = true;
  show_dpName = true;
  show_dpDesignation = true;
  show_confidentiality_agreement_entered = true;
  show_email = true;
  show_contact=false;
  show_address=false;
  show_identifier = false;
  show_dpEducationalInstitute = false;
  show_dpPastEmp = false;
  show_remarks = false;
  dropdownSettings1:IDropdownSettings;
  visible: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false
  visible6: boolean = false
  visible7: boolean = false
  visible8: boolean = false
  nameFilterString= '';
  designationFilterString= '';
  emailFilterString= '';
  contactFilterString= '';
  addressFilterString = '';
  identifierFilterString = '';
  educationalFilterString = '';
  pastEmpFilterString = '';
  keyword = 'dpName';
  keyword2 = 'dpDesignation';
  keyword3 = 'email';
  keyword4 = 'dpContactNo';
  keyword5 = 'dpAddress';
  keyword6 = 'DocumentType';
  keyword7 = 'dpEducationalInstitute';
  keyword8 = 'dpPastEmp' ; 
  openDpLabel = false;
  constructor( private dpService:DpModuleService, public router: Router, private modalService: NgbModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));    
    if(this.user_roles.dpname_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.dpname_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }
   

    this.dpService.deleteModuleList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      this.listDpModule = res.body['data']
        this.onFilterChange();
        }
        },
        err => {
            console.log(err);
        }
    );
    this.selectedhideItems = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":4,"name":"Confidentiality Agreement Entered "},{"id":5,"name":"Email"}];

    //Multiselect HideShow
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

//MultiSelect HideShow
onItemSelect(item: any,page) {
  if(page == "Contact Number"){
   if(!this.selected_contactNo.includes(item)){
     this.selected_contactNo.push(item);
   }
 }else if(page == "Address"){
   if(!this.selected_address?.includes(item)){
     this.selected_address.push(item);
   }
 }else if(page == "column"){
  if(this.selectedhideItems.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  if(item.id == 1){
    this.show_id = true;
  }else if(item.id == 2){
    this.show_dpName = true;
  }else if(item.id == 3){
    this.show_dpDesignation = true;
  }else if(item.id == 4){
    this.show_confidentiality_agreement_entered = true;
  }else if(item.id == 5){
    this.show_email = true;
  }else if(item.id == 6){
     this.show_contact = true;
   }else if(item.id == 7){
     this.show_address = true;
   }else if(item.id == 8){
    this.show_identifier = true;
  }else if(item.id == 9){
    this.show_dpEducationalInstitute = true;
  }else if(item.id == 10){
    this.show_dpPastEmp = true;
  }else if(item.id == 11){
    this.show_remarks = true;
  }
 }
}

onItemDeSelect(item: any,page) {

if(page == "Contact Number"){
   if(this.selected_contactNo.includes(item)){
     this.selected_contactNo.splice(this.selected_contactNo.indexOf(item), 1);
   }
 }else if(page == "Address"){
   if(this.selected_address.includes(item)){
     this.selected_address.splice(this.selected_address.indexOf(item), 1);
   }
 }else if(page == "column"){
  if(this.selectedhideItems.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  if(item.id == 1){
    this.show_id = false;
  }else if(item.id == 2){
    this.show_dpName = false;
  }else if(item.id == 3){
    this.show_dpDesignation = false;
  }else if(item.id == 4){
    this.show_confidentiality_agreement_entered = false;
  }else if(item.id == 5){
    this.show_email = false;
  }else if(item.id == 6){
     this.show_contact = false;
   }else if(item.id == 7){
     this.show_address = false;
   }else if(item.id == 8){
    this.show_identifier = false;
  }else if(item.id == 9){
    this.show_dpEducationalInstitute = false;
  }else if(item.id == 10){
    this.show_dpPastEmp = false;
  }else if(item.id == 11){
    this.show_remarks = false;
  }
 }
 
}
onSelectAll(items: any,page) {
 if(page == "Contact Number"){
   this.selected_contactNo=items;
 }else if(page == "Address"){
   this.selected_address=items;
 }else if(page == "column"){
  if(items.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  this.show_id = true;
  this.show_dpName = true;
  this.show_dpDesignation = true;
  this.show_confidentiality_agreement_entered = true;
  this.show_email = true;
  this.show_contact=true;
  this.show_address=true;
  this.show_identifier = true;
  this.show_dpEducationalInstitute = true;
  this.show_dpPastEmp = true;
  this.show_remarks = true; 
 }
 
}
onDeSelectAll(items: any,page) {
if(page == "Contact Number"){
   this.selected_contactNo=[];
 }else if(page == "Address"){
   this.selected_address=[];
 }else if(page == "column"){
  if(items.length > 8){   
    document.getElementById('formTable').classList.add('tablescroll');
  }else{
    document.getElementById('formTable').classList.remove('tablescroll');
  }
  this.show_id = false;
  this.show_dpName = false;
  this.show_dpDesignation = false;
  this.show_confidentiality_agreement_entered = false;
  this.show_email = false;
  this.show_contact=false;
  this.show_address=false;
  this.show_identifier = false;
  this.show_dpEducationalInstitute = false;
  this.show_dpPastEmp = false;
  this.show_remarks = false;
 }
}

  onFilterChange() {
    this.filtered = this.listDpModule?.filter(res =>{    
      
      return res.dpName?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.email?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpDesignation?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.confidentiality_agreement_entered?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpContactNo?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpAddress?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.DocumentType?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpPanNo?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpEducationalInstitute?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) 
      || res.dpPastEmp?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.Record?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
    }) 
   
  }

  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }
//colum filter
selectEvent(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpName.toLocaleLowerCase().match(item.dpName.toLocaleLowerCase());

  })

  this.visible = false;
}
selectEvent2(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpDesignation.toLocaleLowerCase().match(item.dpDesignation.toLocaleLowerCase());

  })

  this.visible2 = false;
}
selectEvent3(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.email.toLocaleLowerCase().match(item.email.toLocaleLowerCase());

  })

  this.visible3 = false;
}
selectEvent4(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpContactNo.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible4 = false;
}
selectEvent5(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpAddress.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible5 = false;
}
selectEvent6(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.DocumentType.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible6 = false;
}
selectEvent7(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpEducationalInstitute.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible7 = false;
}
selectEvent8(item) {
  // do something with selected item
  this.filtered = this.listDpModule?.filter(dpItem => {
    return dpItem.dpPastEmp.toLocaleLowerCase().match(item.toLocaleLowerCase());

  })

  this.visible8 = false;
}
// 
onChangeSearch(search: string) {
  // fetch remote data from here
  // And reassign the 'data' which is binded to 'data' property.
  console.log("String: " + search);
  
}

onFocused(e) {
  // do something
}
onCleared(e) {

  this.filtered = this.listDpModule;
}

onBlur(e) {
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
onScrolledEnd() {
  // this.auto.close();
}
filterData() {
  this.visible = !this.visible;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData2() {
  this.visible2 = !this.visible2;
  this.visible = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;

}
filterData3() {
  this.visible3 = !this.visible3;
  this.visible = false;
  this.visible2 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData4() {
  this.visible4 = !this.visible4;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData5() {
  this.visible5 = !this.visible5;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible6 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData6() {
  this.visible6 = !this.visible6;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible7 = false;
  this.visible8 = false;
}
filterData7() {
  this.visible7 = !this.visible7;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible8 = false;
}
filterData8() {
  this.visible8 = !this.visible8;
  this.visible = false;
  this.visible2 = false;
  this.visible3 = false;
  this.visible4 = false;
  this.visible5 = false;
  this.visible6 = false;
  this.visible7 = false;
  
}
//column filter
  openModal(id:any) {   
      
    if (id) {
      let dpId = {'id' : id};
      this.dpService.viewDpModule(dpId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(PopupModelDpComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewDbList = data[0];
        modalRef.result.then(
          (result) => {
            console.log(result);
          }, (reason) => {
            console.log(reason);
          });
      })
    }    
    
  } 

//Revert dp
openModalRevert(id:any) {   
      
  if (id) {
    let dpId = {'id' : id};
    this.dpService.viewDpModule(dpId).subscribe(
      ({data}) =>{
      const modalRef = this.modalService.open(RevetDpModuleComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
          // keyboard: false,
          // backdrop: 'static'
        });
      modalRef.componentInstance.viewDbList = data[0];
      modalRef.componentInstance.title = 'DP';
      modalRef.result.then(
        (result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
    })
  }    
  
} 

}
