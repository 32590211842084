import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-delete-company',
  templateUrl: './delete-company.component.html',
  styleUrls: ['./delete-company.component.scss']
})
export class DeleteCompanyComponent implements OnInit {
  companyId: any;
  accessSuccess: string;

  constructor(private companyService: CompanyService, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.companyId = data['id']
    })
    if(this.companyId){
      this.companyService.deleteCompany(this.companyId).subscribe(data =>{
      // console.log(agenda Details deleted )
      this.toastr.success("Company Profile details Successfully Deleted");
      this.router.navigate(['/setup/company-profile']);
    })
  }
  }

}
