import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from 'angular2-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { ProfileService } from '../../services/profile.service';
import { RejectAnnualReportModuleComponent } from '../reject-annual-report-module/reject-annual-report-module.component';

@Component({
  selector: 'app-annual-conform-module',
  templateUrl: './annual-conform-module.component.html',
  styleUrls: ['./annual-conform-module.component.scss']
})
export class AnnualConformModuleComponent implements OnInit {
  @Input() name;
  public active1 = 1;
  revetDpForm: FormGroup;
  getIdDp: any;
  getEmailDp: any;
  financialyear: string;
  listDpModule: any;
  accessUser: string;
  annualConformationData: any;
  listDpannualReport: any[];
  button = 'Confirm';
  isLoading = false;
  newDate = new Date();
  currentDate: any;
  last: any;
  hideButton:boolean = false;
  constructor(private modalService: NgbModal, private profileService: ProfileService,
    public activeModal: NgbActiveModal, private dpService:DpModuleService, private router:Router, private fb: FormBuilder, private toastr: ToastrService ) {
      this.accessUser = sessionStorage.getItem('access_user');
     }

  ngOnInit() {
  //  console.log(this.viewDbList);
  // this.revetDpForm = this.fb.group({
  //   id: new FormControl(''),
  //   email: new FormControl('')
  // });
//  this.getIdDp = this.viewDbList.id;
//  this.getEmailDp = this.viewDbList.email;
this.getCurrentFinancialYear()

//Current Date
const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
this.currentDate = day + '-' + month + '-' + year;

// this.dpService.dpModuleList(null).subscribe(
//   res => {
   
//   this.listDpModule = res['data'];
//  this.listDpModule.map((item) =>{
    
//     let otherDpannualReport = [];
//     if(this.accessUser == item.email){
//       this.listDpannualReport =item;
//     }else{
//       otherDpannualReport.push(item);
//     }
//   })
  
//   this.annualConformationData = this.listDpannualReport; 
//   console.log("annualConformationData2", this.annualConformationData)
//     });
    this.getdpdetails();
  }
  confirmDetailsId(){
    this.isLoading = true;
    this.button = 'Processing';
    this.annualConformationData.financialyear = this.financialyear;
    this.annualConformationData.currentDate= this.currentDate
      this.profileService.confirmAnnualConfirmReport(this.annualConformationData).subscribe(data =>{
        this.button = 'Confirm';
        this.isLoading = false;
        this.toastr.success("Annual Confirmation Details Successfully Added");
        this.activeModal.close(event);
          //window.location.href;
       
      })
    
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  getCurrentFinancialYear() {
    this.financialyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      this.financialyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      this.financialyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    
    return this.financialyear
  
  }

  open() {
    this.activeModal.close(event);
		const modalRef = this.modalService.open(RejectAnnualReportModuleComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.listDpModuleList = this.annualConformationData;
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
	}
  getdpdetails(){
    this.getCurrentFinancialYear();
    let listDpannualReport = [];
   let accessUser = sessionStorage.getItem('access_user')
    this.dpService.dpModuleList(null).subscribe(
      res => {
       
      this.listDpModule = res['data'];
     this.listDpModule.map((item) =>{
        
        let otherDpannualReport = [];
        if(accessUser == item.email){
          listDpannualReport =item;
                
        }else{
          otherDpannualReport.push(item);
        }
      })
      
      this.annualConformationData = listDpannualReport; 
    
      let annualconfirmentry = this.annualConformationData['annualConfirmation'];
      if(this.annualConformationData != ''){
       
          if(annualconfirmentry.length > 0 ){       
            this.last = annualconfirmentry.slice(-1)[0].financialYear;   
                      
            }else{             
              this.last = '' 
            }  
        
            //check Financial Year
            if(this.financialyear != this.last ){
              this.hideButton = true;
            }
      
      }
  
        });
  }
}
