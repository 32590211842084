import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl;

  upsiMonthlyCount(userObj:any) {  
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
     return this.http.post(this.baseUrl + 'upsi/upsiMontlyCount', userObj, {  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }

  fullTableCount(userObj:any) {  
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
     return this.http.post(this.baseUrl + 'upsi/fullTableCount', userObj, {  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  
 

  //filter
  confidentialityUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/confidentiality", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      
    });
  }
//Sender and Recipient change filter
changeFilterDate(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/changeFilter", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    
  });
}
downloadChartPdf(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "reports", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    
  });
}

  
  
}
