<div class="modal-header">
    <h5 class="modal-title">Download {{title}} Information </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <!-- <h4 style="font-size: 19px;">The audit trail associated with these entries will not be visible in this excel.  Please view ‘change log’ for the same</h4> -->
                        <h4 style="font-size: 19px;" *ngIf="showuserstext == false"> The audit trail associated with these entries will not be visible in this excel. Please view 'Change Logs' for the same.</h4>
                        <h4 style="font-size: 19px;" *ngIf="showuserstext == true"> Are you sure want to download the Users List Excel?</h4>
                        <div class="btn-bottom">
                            <button class="btn btn-primary me-1" (click)="onFilterPdf('Yes')">Download</button>
                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <div class="text-muted">
        <p><b>Note: </b>Please wait a few seconds for the file to download.</p>
    </div>
    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button> -->
</div>
