import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { distinct, from, Observable, tap } from 'rxjs';
import { COMPANYDB, CompanyDB } from '../../shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { UpsiService } from '../upsi.service'
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MasterService } from 'src/app/master/master.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PopupModelUpsiComponent } from '../popup-model-upsi/popup-model-upsi.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulActionPopupComponent } from '../bul-action-popup/bul-action-popup.component';
import { ToastrService } from 'ngx-toastr';
import { NoupsipopupComponent } from '../noupsipopup/noupsipopup.component';
import { WarningalertpopupComponent } from '../warningalertpopup/warningalertpopup.component';
import { CompanyService } from 'src/app/master/company/company.service';
import { DisclaimerexcelComponent } from 'src/app/master/popup/disclaimerexcel/disclaimerexcel.component';

@Component({
  selector: 'app-noupsi',
  templateUrl: './noupsi.component.html',
  styleUrls: ['./noupsi.component.scss']
})
export class NoupsiComponent implements OnInit {

  fileName: string = 'SheetJS.xlsx';
  @ViewChild('auto') auto;
  Date= new Date;
  dropdownSettings:IDropdownSettings;
  dropdownSettings1:IDropdownSettings;
  dropdownSettings2:IDropdownSettings;
  public selected = [];
  baseUrl = environment.apiUrl;
  page = 1;
  // arr=[];
  newarr:any;
  is_show=false;
  pageSize = 10;
  listUpsiTransfer: any;
  listUpsiTransfers: any;
  pipe: DatePipe;
  filterForm: FormGroup;
  public active1 = 1;
  listProject: any;
  selected_project=[];
  selected_sender:any;
  selected_receiver:any;
  selected_hideproject:any;
  filtered: any;
  filterString: any;
  myDateValue: Date;
  toDate: Date;
  duplicateArray = []
  // url = "https://localhost/sdd/public/api/upsi/indexexcel"
  upsiId: any;
  confidentialityUpsiList: any;
  accessSuccess: string;

  dropdownList = [];
  senderList = [];
  receiverList = [];
  selectedItems = [];
  selectedSenderItems = [];
  selectedReceiverItems = [];
  hidelist = [{"id":1,"name":"UPSI ID"},{"id":2,"name":"Nature of UPSI"},{"id":3,"name":"Project/Event Name"},{"id":4,"name":"Date of Sharing"},{"id":5,"name":"Sender Name"},{"id":6,"name":"Recipient Name"},{"id":7,"name":"Mode of Sharing"}, {"id":8,"name":"Purpose of Sharing"},{"id":9,"name":"Remarks"},{"id":11,"name":"Date UPSI Made Public"}];
  selectedhideItems = [];
  noUpsiCreateForm: FormGroup;
  show_sender=true;
  show_receiver=true;
  show_upsi_id = true;
  show_nature_of_upsi = true;
  show_project_name = true;
  show_date_of_sharing = false;
  show_mode_of_sharing = false;
  show_purpose_of_sharing = false;
  show_remarks = false;
  show_from_date = false;
  show_to_date = false;
  show_created_by =false;
  show_created_on = false;
  // dropdownSettings = {};
  filterDateItem:boolean = false;
  visible: boolean = false
  visible1: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false;
  visible6:boolean = false;
  visible7:boolean = false;
  visible8:boolean = false;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;
  keyword = 'nature_of_upsi';
  keyword2 ='project_name';
  keyword3 ='sender';
  keyword4 ='recipient';
  keyword5 ='date_of_sharing';
  keyword6 = 'mode_of_sharing';
  keyword7 = 'purpose_of_sharing';
  keyword8 = 'created_by';
  nameFilterString= '';
  nameFilterString2= '';
  nameFilterString3= '';
  nameFilterString4= '';
  nameFilterString5= '';
  nameFilterString6= '';
  nameFilterString7= '';
  nameFilterString8= '';
  excelFile: any;
  filterStrings = "";
  projectNameFilter: any;
  senderNameFilter: any[];
  recipientNameFilter: any;
  natureofUpsiNameFilter: any;
  modeofSharingNameFilter: any[];
  purposefSharingNameFilter: any[];
  createdByNameNameFilter: any[];
  createdByNameFilter: any[];
  projectItemList = [];
  senderItemList = [];
  recipientItemList = [];
  openNoUpsi: boolean = false;
  isSelected: boolean = false;
  otherSelectedItem: any[];
  complianceofficeraccess:boolean = false;
  accessUser: string;

  constructor(private upsiService: UpsiService, private modalService: NgbModal, private masterService: MasterService, private fb: FormBuilder, private http: HttpClient,  public router: Router,private activateRouter: ActivatedRoute,
    private toastr: ToastrService, private companyService: CompanyService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    this.accessUser = sessionStorage.getItem('access_user');
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }

    
  }

  // applyFilter() {
  //   this.listUpsiTransfer.filter = ''+Math.random();
  // }
  onItemSelect(item: any,page) {
    if(page == "project"){
      
        // this.selected_project.push(item);
        // this.selected_project.push(item.id);
        let filteredItems = [];
  if(this.projectItemList.length > 0){
    this.projectItemList.map(res =>{
      this.listUpsiTransfer.data?.map(proj =>{    
        if(proj.project_name.toLocaleLowerCase().match(res.toLocaleLowerCase())) {
          filteredItems.push(proj);
        }
      });
    });
  } else {
    filteredItems = this.listUpsiTransfer.data;
  }
  this.filtered = filteredItems;
      
    }else if(page == "sender"){
      // if(!this.selected_sender?.includes(item)){
      //   this.selected_sender?.push(item);    
      //   this.selected_sender.push(item.id);
      // }
      let filteredItemsSender = [];
      if(this.senderItemList.length > 0){
        this.senderItemList.map(res =>{
          let item = res?.split('- ');
          let items = item[1].trim();
          this.listUpsiTransfer.data?.map(proj =>{    
            if(proj.sender.toLocaleLowerCase().match(items.toLocaleLowerCase())) {
              filteredItemsSender.push(proj);
            }
          });
        });
      } else {
        filteredItemsSender = this.listUpsiTransfer.data;
      }
      this.filtered = filteredItemsSender;
    }else if(page == "receiver"){
      let filteredItemsRecipient = [];
      if(this.recipientItemList.length > 0){
        this.recipientItemList.map(res =>{
          let item = res?.split('- ');
          let items = item[1].trim();
          this.listUpsiTransfer.data?.map(proj =>{    
            if(proj.recipient.toLocaleLowerCase().match(items.toLocaleLowerCase())) {
              filteredItemsRecipient.push(proj);
            }
          });
        });
      } else {
        filteredItemsRecipient = this.listUpsiTransfer.data;
      }
      this.filtered = filteredItemsRecipient;
    }else if(page == "column"){
      if(item.id == 1){
        this.show_upsi_id = true;
      }else if(item.id == 2){
        this.show_nature_of_upsi = true;
      }else if(item.id == 3){
        this.show_project_name = true;
      }else if(item.id == 4){
        this.show_date_of_sharing = true;
      }else if(item.id == 5){
        this.show_sender = true;
      }else if(item.id == 6){
        this.show_receiver = true;
      }else if(item.id == 7){
        this.show_mode_of_sharing = true;
      }else if(item.id == 8){
        this.show_purpose_of_sharing = true;
      }else if(item.id == 9){
        this.show_remarks = true;
      }else if(item.id == 10){
        this.show_from_date = true;
      }else if(item.id == 11){
        this.show_to_date = true;
      }else if(item.id == 12){
        this.show_created_by = true;
      }else if(item.id == 13){
        this.show_created_on = true;
      }1
    }
    // this.onSubmit();
  }

  onItemDeSelect(item: any,page) {

    if(page == "project"){
      
        // this.selected_project.splice(this.selected_project.indexOf(item), 1);
    
          // this.selected_project.push(item);
          // this.selected_project.push(item.id);
          let filteredItems = [];
    if(this.projectItemList.length > 0){
      this.projectItemList.map(res =>{
        this.listUpsiTransfer.data?.map(proj =>{    
          if(proj.project_name.toLocaleLowerCase().match(res.toLocaleLowerCase())) {
            filteredItems.push(proj);
          }
        });
      });
    } else {
      filteredItems = this.listUpsiTransfer.data;
    }
    this.filtered = filteredItems;
        
      
    }else if(page == "sender"){
      // if(!this.selected_sender?.includes(item)){
      //   this.selected_sender?.push(item);    
      //   this.selected_sender.push(item.id);
      // }
      let filteredItemsSender = [];
      if(this.senderItemList.length > 0){
        this.senderItemList.map(res =>{
          let item = res?.split('- ');
          let items = item[1].trim();
          this.listUpsiTransfer.data?.map(proj =>{    
            if(proj.sender.toLocaleLowerCase().match(items.toLocaleLowerCase())) {
              filteredItemsSender.push(proj);
            }
          });
        });
      } else {
        filteredItemsSender = this.listUpsiTransfer.data;
      }
      this.filtered = filteredItemsSender;
    }else if(page == "receiver"){
      let filteredItemsRecipient = [];
      if(this.recipientItemList.length > 0){
        this.recipientItemList.map(res =>{
          let item = res?.split('- ');
          let items = item[1].trim();
          this.listUpsiTransfer.data?.map(proj =>{    
            if(proj.recipient.toLocaleLowerCase().match(items.toLocaleLowerCase())) {
              filteredItemsRecipient.push(proj);
            }
          });
        });
      } else {
        filteredItemsRecipient = this.listUpsiTransfer.data;
      }
      this.filtered = filteredItemsRecipient;
    }else if(page == "column"){
      if(item.id == 1){
        this.show_upsi_id = false;
      }else if(item.id == 2){
        this.show_nature_of_upsi = false;
      }else if(item.id == 3){
        this.show_project_name = false;
      }else if(item.id == 4){
        this.show_date_of_sharing = false;
      }else if(item.id == 5){
        this.show_sender = false;
      }else if(item.id == 6){
        this.show_receiver = false;
      }else if(item.id == 7){
        this.show_mode_of_sharing = false;
      }else if(item.id == 8){
        this.show_purpose_of_sharing = false;
      }else if(item.id == 9){
        this.show_remarks = false;
      }else if(item.id == 10){
        this.show_from_date = false;
      }else if(item.id == 11){
        this.show_to_date = false;
      }else if(item.id == 12){
        this.show_created_by = false;
      }else if(item.id == 13){
        this.show_created_on = false;
      }
    }
    // this.onSubmit();
  }
  onSelectAll(items: any,page) {
    if(page == "project"){
      this.selected_project=items;
      // this.selected_project = this.listUpsiTransfer?.data.map(x => x.id);
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "sender"){
      // this.selected_sender=items;
      // this.selected_sender = this.listUpsiTransfer?.data.map(x => x.id);
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "receiver"){
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "column"){
      this.show_sender = true;
      this.show_receiver = true; 
      this.show_upsi_id = true;
      this.show_nature_of_upsi = true;
      this.show_project_name = true;
      this.show_date_of_sharing = true
      this.show_mode_of_sharing = true;
      this.show_purpose_of_sharing = true;
      this.show_remarks = true;
      this.show_from_date = true;
      this.show_to_date = true;
      this.show_created_by =true;
      this.show_created_on = true;
    }
    // this.onSubmit();
  }
  onDeSelectAll(items: any,page) {
    if(page == "project"){
      this.selected_project=[];
      // // console.log(this.selected_project);
      // this.selected_project = this.listUpsiTransfer?.data
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "sender"){
      this.selected_sender=[];
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "receiver"){
      this.selected_receiver=[];
      this.filtered =this.listUpsiTransfer?.data;
    }else if(page == "column"){
      this.show_sender = false;
      this.show_receiver = false;
      this.show_upsi_id = false;
      this.show_nature_of_upsi = false;
      this.show_project_name = false;
      this.show_date_of_sharing = false;
      this.show_mode_of_sharing = false;
      this.show_purpose_of_sharing = false;
      this.show_remarks = false;
      this.show_from_date = false;
      this.show_to_date = false;
      this.show_created_by =false;
      this.show_created_on = false;
    }

  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.filterForm && this.filterForm.get("dateOfSharing").value;
    const to = this.filterForm && this.filterForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  ngOnInit(): void {



    this.filterForm = this.fb.group({
      dateOfSharing: new FormControl(''),
      toDate: new FormControl('', [this.dateRangeValidator]),
      // projectName: ['', Validators.required],
      projects: new FormControl(''),
      sender: new FormControl(''),
      receiver: new FormControl(''),
      showHideColumn: new FormControl('')
    });
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    // console.log("user_roles", this.user_roles);
    if(this.user_roles.upsi_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.upsi_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }

    this.noUpsiCreateForm = this.fb.group({
      noupsi: new FormControl(''),
      upsiCheckedList: new FormControl('')
    });


    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);
    if(accessModelController.noupsi_access == true || accessModelController.noupsi_access == 1){
       this.openNoUpsi = true;
    }else{
       this.openNoUpsi = false;
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.selectedItems = [];
    this.selectedSenderItems = [];
    this.selectedReceiverItems = [];
   this.onSubmit();

    //Project api
    // setTimeout(() =>{
      this.masterService.projectList(null).subscribe(
        res => {
          this.listProject = res.body['data'];    
          
        },
        err => {
          console.log(err);
        }
      )
      this.selectedhideItems = [{"id":1,"name":"UPSI ID"},{"id":2,"name":"Nature of UPSI"},{"id":3,"name":"Project/Event Name"},{"id":5,"name":"Sender Name"},{"id":6,"name":"Recipient Name"}];
      this.companyService.CompanyList(null).subscribe(
        res => {
          if(res.body['message'] == 'Invalid User.'){
            this.router.navigate(['/auth/login']);
          }else{
        let listCompany = res.body['data']     
            let complianceOfficerEmail = listCompany[0].complianceofficeremail; 
            if(this.accessUser == complianceOfficerEmail){
                this.complianceofficeraccess = true;
            }else{
              this.complianceofficeraccess = false;
            }
          }
          },
          err => {
              console.log(err);
          }
      );
//confidentiality_agreement_entered
    // this.activateRouter.params.subscribe(data => {
    //   this.upsiId = data['id']
    // })
    // if(this.upsiId){
      // this.upsiService.confidentialityUpsiTransfer().subscribe(data =>{
      //   this.confidentialityUpsiList = data;
        
      // })
   // }

  //  this.upsiService.upsiTransferList(null).subscribe(data => {
      
  //   this.listUpsiTransfer = data;

  //  });


  }

  //   get from_date() { return this.filterForm.get('fromDate').value; }
  // get to_date() { return this.filterForm.get('toDate').value; }

  // onFilterChange() {
  //   this.filtered = this.listUpsiTransfer?.filter((project_name) => this.isMatch(project_name));
  // }

  // isMatch(item) {
  //   if (item instanceof Object) {
  //     return Object.keys(item).some((k) => this.isMatch(item[k]));
  //   } else {
  //     return item.toString().indexOf(this.filterString) > -1
  //   }
  // }
  // onDateChange(newDate: Date) {
  //   console.log("newDate", newDate);
  // }

   reverseAndTimeStamp(dateString) {
        const reverse = new Date(dateString.split("-").reverse().join("-"));
        return reverse.getTime();
        }
    filterDate() {
        let fromdate=moment(this.myDateValue).format('DD-MM-YYYY');
   
    let todate=moment(this.toDate).format('DD-MM-YYYY');
    if(this.myDateValue && this.toDate){
    const selectedMembers = this.listUpsiTransfer.filter(m => {
            return this.reverseAndTimeStamp(m.from_date) >= this.reverseAndTimeStamp(fromdate) && this.reverseAndTimeStamp(m.from_date) <= this.reverseAndTimeStamp(todate)
        }
        );
        this.duplicateArray=selectedMembers
    }else{
  this.duplicateArray=this.listUpsiTransfer
    }

       
    }
  onFilter() {
    
    let queryString = '';
    let url = this.baseUrl + 'noupsiexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.projects || this.filterForm.value.sender || this.filterForm.value.receiver)  {
      queryString = 'fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&projectName=' + this.filterForm.value.projects + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver;
      queryString = queryString + '&authToken='+btoa(tokenStr);
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  
     
  }
  filterData(){
    this.filterDateItem = !this.filterDateItem;
    this.visible = !this.visible
    
  }
  onSubmit() {
    const arr = [];
    const arr2 = [];
    const arr3 = [];
    this.filterForm.value.projects=this.projectItemList;
    this.filterForm.value.sender=this.senderItemList;
    this.filterForm.value.receiver=this.recipientItemList;
    this.upsiService.noUpsi(this.filterForm.value).subscribe(data => {
      this.listUpsiTransfer = data;
      // if(this.listUpsiTransfer.message == 'Invalid User.'){
      //   this.router.navigate(['/auth/login']);
      // }else{
      this.filtered = this.listUpsiTransfer.data
      // this.listUpsiTransfers = this.listUpsiTransfer.data;
//project
      let projectName = this.listUpsiTransfer?.data.map(res =>res.project_name)
      this.projectNameFilter = [];
      from(projectName)
    .pipe(
      tap(item => item),
      distinct(d => d)
    )
    .subscribe(r => this.projectNameFilter.push(r));

//sender
let senderName = this.listUpsiTransfer?.data.map(res =>res.sender)
this.senderNameFilter = [];
from(senderName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.senderNameFilter.push(r));

//Recipient
let recipientName = this.listUpsiTransfer?.data.map(res =>res.recipient)
this.recipientNameFilter = [];
from(recipientName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.recipientNameFilter.push(r));

let natureOfUpsiName = this.listUpsiTransfer?.data.map(res =>res.nature_of_upsi)
this.natureofUpsiNameFilter = [];
from(natureOfUpsiName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.natureofUpsiNameFilter.push(r));

//Mode of Sharing
let modeofSharingName = this.listUpsiTransfer?.data.map(res =>res.mode_of_sharing)
this.modeofSharingNameFilter = [];
from(modeofSharingName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.modeofSharingNameFilter.push(r));

//Purpose of Sharing
let purposeofSharingName = this.listUpsiTransfer?.data.map(res =>res.purpose_of_sharing)
this.purposefSharingNameFilter = [];
from(purposeofSharingName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.purposefSharingNameFilter.push(r));

//created By
let createdByName = this.listUpsiTransfer?.data.map(res =>res.created_by)
this.createdByNameFilter = [];
from(createdByName)
.pipe(
tap(item => item),
distinct(d => d)
)
.subscribe(r => this.createdByNameFilter.push(r));

      
      this.filtered.forEach(function (key,value) {
         
          if(!arr.includes(key.project_name)){
            arr.push(key.project_name);

          }
          if(!arr2.includes(key.SenderDPName + ' - ' + key.sender + ' - ' + key.SenderDPPanno)){
            arr2.push(key.SenderDPName + ' - ' + key.sender + ' - ' + key.SenderDPPanno);
          }
          if(!arr3.includes(key.RecepientDPName + ' - ' + key.recipient + ' - ' + key.RecepientDPPanno)){
            arr3.push(key.RecepientDPName + ' - ' + key.recipient + ' - ' + key.RecepientDPPanno);
          }                   
          
      });
      this.dropdownList = arr;
      this.senderList = arr2;
      this.receiverList = arr3;
      this.newarr =arr;
    },
      err => {
        console.log(err);
      }
    )
  }
//colum filter
  selectEvent(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      return upsiItem.nature_of_upsi.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible1 = false;
  }
  selectEvent2(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.project_name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible2 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.sender.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible3 = false;
  }
  selectEvent4(item) {
    
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.recipient.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible4 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.date_of_sharing.toLocaleLowerCase().match(item.date_of_sharing.toLocaleLowerCase());

    })
   
    this.visible5 = false;
  }
  selectEvent6(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.mode_of_sharing.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
   
    this.visible6 = false;
  }
  selectEvent7(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.purpose_of_sharing.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
   
    this.visible7 = false;
  }
  selectEvent8(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.created_by.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
   
    this.visible8 = false;
  }
  onFilterChange() {
    
    this.filtered = this.listUpsiTransfer?.data?.filter(res =>{         
      return res.nature_of_upsi?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.project_name?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.sender?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.recipient?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.date_of_sharing?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase())|| res.SenderDPPanno?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.RecepientDPPanno?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.SenderDPName?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.RecepientDPName?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase())
     });
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    console.log("String: " + search);
    this.auto.close();
  }

  onFocused(e) {
    // do something
  }
  onCleared(e) {
    //console.log('input cleared');
    this.filtered = this.listUpsiTransfer?.data;
  }


  onBlur(e) {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  onScrolledEnd() {
    // this.auto.close();
  }
  filterData1() {
    this.visible1 = !this.visible1;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData2() {
    this.visible2 = !this.visible2;
    this.visible1 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData3() {
    this.visible3 = !this.visible3;
    this.visible1 = false;
    this.visible2 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData4() {
    this.visible4 = !this.visible4;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData5() {
    this.visible5 = !this.visible5;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData6() {
    this.visible6 = !this.visible6;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible7 = false;
    this.visible8 = false;
  }
  filterData7() {
    this.visible7 = !this.visible7;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible8 = false;
  }
  filterData8() {
    this.visible8 = !this.visible8;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  //column Filter
  openwarrning(item) {
		const modalRef = this.modalService.open(WarningalertpopupComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.name = 'Alert';
		modalRef.componentInstance.selectedItems = this.otherSelectedItem;
    modalRef.componentInstance.choosedItems = item;
    modalRef.componentInstance.viewNoUpsi = 'UPSI List';
    modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
      let {itemSelected} = receivedData;
    let tempFiltered = this.filtered.map(filteredItem => {
      let itemFiltered = itemSelected.filter(selItem => selItem.upsi_id === filteredItem.upsi_id);
      if(itemFiltered?.length > 0) {
        filteredItem.checked = true;
      }
      return filteredItem
    })
    this.filtered = [...tempFiltered];
    })
    let overallSelected = this.filtered.filter(filterItem => filterItem.checked);
    if(overallSelected.length > 0) {
      this.isSelected = true;
    }else{
      this.isSelected = false;

    }
	}

  get result() {         
    return this.listUpsiTransfer?.data.filter(item => item.checked);      
 } 
  isSelectLabel(e, curItem){
    let checkedItemsArr = []
    this.filtered.map((item) => {
      if(e.target.checked){
        checkedItemsArr.push(curItem);
      } else if(item && item.checked && item.id !== curItem.id){
        checkedItemsArr.push(item);
      }

    });
    //const checkedLabel = this.label.nativeElement.value;
    if(checkedItemsArr.length > 0){
      this.isSelected = true;
    }else{
      this.isSelected = false;
    }
  }
  selectLabel(e, curItem){

    if(e.target.checked) {
      this.otherSelectedItem = [];
      this.upsiService.upsiCountCheck(curItem).subscribe(data =>{ 
        let selectedItem = data.data;
        if(data.listcount > 0){
          selectedItem.map(item =>{
            if(item.id != curItem.id){
              this.otherSelectedItem.push(item);
            }
          });
          if(this.otherSelectedItem.length >0){
            this.openwarrning(curItem);   
          } else{
            this.openwarrning(curItem);   

          }
        }else{
          this.isSelected = true;
          this.openwarrning(curItem);   

        }
      });
      this.isSelected = true;

    } else {
      let checkedItems = this.filtered.filter(filterItem => filterItem.id !== curItem.id && filterItem.checked);
      if(checkedItems.length == 0) {
        this.isSelected = false;
      }
    }

  }
  
 
  onSubmitNoUpsi(){
    //  this.noUpsiCreateForm.value.upsiCheckedList = this.result;
    //  this.noUpsiCreateForm.value.noupsi = 'UPSI List';
    // this.upsiService.updateNoUpsiModule(this.noUpsiCreateForm.value).subscribe(data =>{     
    //   this.toastr?.success("No UPSI Added Successfully");
    //    window.location.reload();
     
    // }) 
    const modalRef = this.modalService.open(NoupsipopupComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });
  
       
    modalRef.componentInstance.viewUpsiList = this.result;
    modalRef.componentInstance.viewNoUpsi = 'UPSI List';
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
  }
  openModal(id:any) {

    if (id) {
      let upsiId = {'id': id};
      this.upsiService.viewUpsiTransfer(upsiId).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(PopupModelUpsiComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewUpsiList = data[0];
          modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
      })

      }
   
    
  }

  //bulk-action
  open() {
		const modalRef = this.modalService.open(BulActionPopupComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.name = 'Bulk Action';
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
    });
	}
  openExcelDisclaimer() {

    const modalRef = this.modalService.open(DisclaimerexcelComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
      modalRef.componentInstance.name = 'Alert';
      modalRef.componentInstance.title = 'Non UPSI Communication';
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
      if(receivedData == 'Yes'){
        this.onFilter();
      }
    }, (reason) => {
    });
  
  
}
}
