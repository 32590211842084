<div class="modal-header">
    <h5 class="modal-title">VIEW UPSI TRANSFER LOG</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewUpsiList">
                                    <tr>
                                        <td scope="row">Project/Event Name</td>
                                        <td scope="row">{{viewUpsiList.project_name}}</td>                                         
                                     </tr> 
                                    
                                     <tr *ngIf = "viewUpsiList.SenderDPPanno == null">
                                       <td scope="row">Sender Name</td>
                                       <td scope="row">{{viewUpsiList.SenderDPName}} - {{viewUpsiList.sender}} - {{viewUpsiList.SenderDPPanno1}}</td>                                         
                                    </tr> 
                                     <tr *ngIf = "viewUpsiList.SenderDPPanno !== null">                                  
                                        <td scope="row">Sender Name</td>
                                        <td scope="row">{{viewUpsiList.SenderDPName}} - {{viewUpsiList.sender}} - {{viewUpsiList.SenderDPPanno}}</td>                                         
                                     </tr> 
                                     <tr *ngIf = "viewUpsiList.RecepientDPPanno == null">
                                        <td scope="row">Recipient Name</td>
                                        <td scope="row">{{viewUpsiList.RecepientDPName}} - {{viewUpsiList.recipient}} - {{viewUpsiList.RecepientDPPanno1}}</td>                                         
                                     </tr>
                                     <tr *ngIf = "viewUpsiList.RecepientDPPanno !== null">
                                       <td scope="row">Recipient Name</td>
                                       <td scope="row">{{viewUpsiList.RecepientDPName}} - {{viewUpsiList.recipient}} - {{viewUpsiList.RecepientDPPanno}}</td>                                         
                                    </tr>
                                    
                                     <tr>
                                        <td scope="row">Mode of Sharing</td>
                                        <td scope="row">{{viewUpsiList.mode_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiList.mode_of_sharing == 'Others'">
                                       <td scope="row">Others Mode of Sharing</td>
                                       <td scope="row">{{viewUpsiList.others_mos}}</td>                                         
                                    </tr>
                                     <tr>
                                        <td scope="row">Purpose of Sharing</td>
                                        <td scope="row">{{viewUpsiList.purpose_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Nature of UPSI</td>
                                        <td scope="row">{{viewUpsiList.nature_of_upsi}} <div *ngIf="viewUpsiList.nature_of_upsi !== 'Others'" > {{viewUpsiList.others_nature_upsi}}</div></td>                                         
                                     </tr>
                                     <tr *ngIf="viewUpsiList.nature_of_upsi == 'Others'">
                                       <td scope="row">Others Nature of UPSI</td>
                                       <td scope="row">{{viewUpsiList.others_nos}}</td>                                         
                                    </tr>
                                       
                                     <tr>
                                        <td scope="row">Sharing Frequency</td>
                                        <td scope="row">{{viewUpsiList.type_of_sharing}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Date of Sharing</td>
                                        <td scope="row">{{viewUpsiList.date_of_sharing | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                     </tr> 
                                     <!-- <tr>
                                        <td scope="row">UPSI From Date</td>
                                        <td scope="row">{{viewUpsiList.from_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>  -->
                                     <tr *ngIf="viewUpsiList.to_date != '' && viewUpsiList.to_date != null && viewUpsiList.to_date != '0000-00-00' ">
                                        <td scope="row">Will This Information Be Made Public?</td>
                                        <td scope="row">{{viewUpsiList.to_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewUpsiList.to_date == '' || viewUpsiList.to_date == null || viewUpsiList.to_date == '0000-00-00' ">
                                       <td scope="row">Will This Information Be Made Public?</td>
                                       <td scope="row">Not Published</td>                                         
                                    </tr>  
                                    <tr *ngIf="viewUpsiList.remarks !=''">
                                       <td scope="row">Description, if any</td>
                                       <td scope="row">{{viewUpsiList.remarks}}</td>                                         
                                    </tr> 
                                     <!-- <tr>
                                        <td scope="row">Confidentiality Agreement entered</td>
                                        <td scope="row">{{viewUpsiList.confidentiality_agreement_entered}}</td>                                         
                                     </tr>                                         
                                     <tr *ngIf="viewUpsiList.confidentiality_agreement_entered == 'Yes' ">
                                        <td scope="row">Confidentiality Agreement date</td>
                                        <td scope="row">{{viewUpsiList.confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>    -->
                                     <!-- <tr *ngIf="viewUpsiList.createddpemail">
                                       <td scope="row">Username (Action By)</td>
                                       <td scope="row">{{viewUpsiList.createddpname}} - {{viewUpsiList.createddpemail}} - {{viewUpsiList.createddppanno}}</td>                                         
                                    </tr>
                                     <tr *ngIf="!viewUpsiList.createddpemail">
                                       <td scope="row">Username (Action By)</td>
                                       <td scope="row">System (Default)</td>                                         
                                    </tr>
                                    <tr>
                                       <td scope="row">Activity Date</td>
                                       <td scope="row">{{viewUpsiList.created_on}}</td>                                         
                                    </tr> -->
                                    <tr *ngIf="modelAccess.upsiattachment_access == true && viewUpsiList.confidentiality_agreement_attachment !='' ">
                                       <td scope="row" >UPSI Attachment</td>
                                       <td scope="row" ><ul *ngFor="let item of attachmentFiles"><li><a href="{{confidencialUrl}}/attachmentFiles/{{viewUpsiList.sender}}/{{item}}" target="_blank">{{item}}</a></li></ul></td>                                         
                                    </tr>                                
                                    <tr *ngIf="modelAccess.upsiattachment_access == true && viewUpsiList.confidentiality_agreement_attachment !='' ">
                                       <td scope="row" >Declaration</td>
                                       <td scope="row" ><p>I declare that the UPSI was shared only in accordance with the legitimate purpose policy of the company and only on a need-to-know basis.</p></td>                                         
                                    </tr>                                
                               
                            </tbody>
                        </table>
                        <!-- <div class="checkbox">
                           <h4 style="text-decoration: underline;">Declaration:</h4>
                           <p>I declare that the UPSI was shared only in accordance with the legitimate purpose policy of the company and only on a need to know basis.</p>
                          
                        </div> -->
                        <div class="checkbox">
                           
                           <div class="col-sm-12 col-xl-12">
                              <div class="card">                                                     
                                <div class="card-body timeline">
                                  <ngb-accordion [closeOthers]="true">
                                    <ngb-panel id="static-1">
                                      <ng-template ngbPanelTitle>
                                          <span>Timeline of Changes</span>
                                        </ng-template>
                                      <ng-template ngbPanelContent>
                                       <div class="custom-datatable">
                                           <div class="timeline-wrapper"> 
                                               <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                                      <li class="StepProgress-item is-done">
                                                          <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                          <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                          <div ><p><span class="bold">Activity:</span> {{items.activity}}</p></div>
                                                          <div *ngIf="items.activity != 'Created'"><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                          <div ><p><span class="bold">Username (Action By):</span> {{items.ActionBy}}</p></div>
                                                          <div *ngIf="items.activity != 'Created'"><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                      </li>                                       
                                               </ul>
                                           </div>    
                                           
                                           <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                               <p>No Data Found</p>
                                           </div>
                                        
                                      </div>

                                      </ng-template>
                                    </ngb-panel>
                                                                        
                                  </ngb-accordion>
                                </div>
                              </div>
                            </div>
                          
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
