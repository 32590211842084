
<!-- Container-fluid starts -->
<div class="container-fluid">
   
    <div class="row" *ngIf = "is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                                     
                    <div class="list-btn" *ngIf="is_access_write"> 
                        <a routerLink="/upsi/upsi-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>                     
             
                    </div>
                    <div class="search-bar mb-3">                      
                        <div class="form-group">
                            <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterStrings" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                             >                                                
                         </div>
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No</th>
                                    <th scope="col" sortable="id no">Id No</th>
                                    <th scope="col" sortable="natureOfUpsi" style="width: 20%;">Nature of UPSI <i class="fa fa-filter" (click)="filterData1()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible1">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword" placeholder="Search ..."
                                                    (selected)='selectEvent($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.nature_of_upsi"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="projectName">Project/Event Name <i class="fa fa-filter" (click)="filterData2()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible2">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword2" placeholder="Search ..."
                                                    (selected)='selectEvent2($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString2" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.project_name"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" sortable="dateOfShare">Date of Sending </th>
                                        <!-- <i class="fa fa-filter" (click)="filterData5()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible5">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword5" placeholder="Search ..."
                                                    (selected)='selectEvent5($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString5" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.date_of_sharing | date:'dd-MM-yyyy'"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div> -->
                                
                                    <th scope="col" *ngIf="show_sender" sortable="namSendere">Name of Sender <i class="fa fa-filter" (click)="filterData3()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible3">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword3" placeholder="Search ..."
                                                    (selected)='selectEvent3($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString3" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.sender"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <th scope="col" *ngIf="show_receiver" sortable="nameReceiver">Name of Receiver <i class="fa fa-filter" (click)="filterData4()"
                                        aria-hidden="true"></i>
                                    <div class="filter-form" *ngIf="visible4">
                                        <div class="form-group">                                             
                                            <div class="ng-autocomplete">
                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                <ng-autocomplete #auto [data]="listUpsiTransfer.data"
                                                    [searchKeyword]="keyword4" placeholder="Search ..."
                                                    (selected)='selectEvent4($event)'
                                                    (inputChanged)='onChangeSearch($event)'
                                                    (inputFocused)='onFocused($event)'
                                                    (scrolledToEnd)='onScrolledEnd()' historyIdentifier="filtered"
                                                    [(ngModel)]="nameFilterString4" [itemTemplate]="itemTemplate"
                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                </ng-autocomplete>

                                                <ng-template #itemTemplate let-item>
                                                    <a [innerHTML]="item.recipient"></a>
                                                </ng-template>

                                                <ng-template #notFoundTemplate let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                            </div>

                                        </div>
                                    </div></th>
                                    <!-- <th scope="col" sortable="gender">Name of recipient</th> -->
                                    <!-- <th scope="col" sortable="created_by">Created By</th> -->
                                    <th scope="col" sortable="action" >Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor = "let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                    <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row">UPSI-{{items.upsi_id }}</td>
                                    <td scope="row">{{items.nature_of_upsi}}</td> 
                                    <td scope="row">{{items.project_name}}</td> 
                                    <td scope="row">{{items.date_of_sharing | date:'dd-MM-yyyy HH:mm:ss' }}</td>  
                                    <td scope="row" *ngIf="show_sender">{{items.sender}}</td>
                                    <td scope="row" *ngIf="show_receiver">{{items.recipient}}</td>
                                    <!-- <td scope="row">{{items.value.created_by}}</td> -->
                                    
                                    <td>
                                        <button class="edit-icon" title="View" (click)="openModal(items.id)"><i class="fa fa-eye"></i></button>
                                        <!-- <a [routerLink]="['/upsi/upsi-edit', items.value.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-pencil-square-o"></i></button></a>
                                        <a [routerLink]="['/upsi/upsi-delete', items.value.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-trash"></i></button></a>
                                        <a [routerLink]="['/upsi/upsi-history', items.value.id]"><button class="edit-icon"><i class="fa fa-history"></i></button></a>  -->
                                    </td>  
                            </tr>
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>

                       <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="listUpsiTransfer?.data?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf = "!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>
<!-- Container-fluid Ends -->

