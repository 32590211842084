

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">                             
                    <a routerLink="/project/project-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewProjectList?.data[0]">
                                    <tr>
                                        <td scope="row">Project/Event Name</td>
                                        <td scope="row">{{viewProjectList?.data[0].project_name}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewProjectList?.data[0].durationfrom !== ''">
                                        <td scope="row">From Date</td>
                                        <td scope="row">{{viewProjectList?.data[0].durationfrom | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewProjectList?.data[0].durationfrom == null ">
                                        <td scope="row">Start Date</td>
                                        <td scope="row">Not Provided</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewProjectList?.data[0].durationto !== '' ">
                                        <td scope="row">End Date</td>
                                        <td scope="row">{{viewProjectList?.data[0].durationto | date:'dd-MM-yyyy'}}</td>                                         
                                     </tr>                     
                                     <tr *ngIf="viewProjectList?.data[0].durationto == null ">
                                        <td scope="row">End Date</td>
                                        <td scope="row">Not Provided</td>                                         
                                     </tr>                     
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
 