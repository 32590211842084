import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { PitComplianceService } from '../../pit-compliance.service';
import { PreClearanceMailTemplateComponent } from '../pre-clearance-mail-template/pre-clearance-mail-template.component';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-pre-clerrance-reject',
  templateUrl: './pre-clerrance-reject.component.html',
  styleUrls: ['./pre-clerrance-reject.component.scss']
})
export class PreClerranceRejectComponent implements OnInit {
  @ViewChild('reason') reason!: ElementRef;
  @ViewChild('reject') reject!: ElementRef;
  @Input() statusPreclerance;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getId: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  statusApprovalData = [];
  statusRejectData = [];
  pcReasonRejectForm: FormGroup;
  complianceOfficerEmail: any;
  complianceOfficerName: any;
  complianceOfficerDesignation: any;
  companyName: any;
  isLoading = false;
  button = 'Reject';
  preDayCount: any;
  tradingcompanyname:any;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, private pitService: PitComplianceService,
    private modalService: NgbModal, private companyService: CompanyService ) { }

  ngOnInit() {
    this.pcReasonRejectForm = this.fb.group({     
      rejectreason : new FormControl('')
    });
 this.statusRejectData = this.statusPreclerance;
 this.tradingcompanyname = this.statusPreclerance.tradingcompanyname;

 this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      this.preDayCount = listCompany[0].predaycount;
    }
    },
    err => {
        console.log(err);
    }
);
  }
  // approvalStatus(){
  //   let approvalStatus = this.approval.nativeElement.value;
  //   //this.statusApprovalData = item;
  //   this.statusApprovalData['status'] = approvalStatus;
  //   console.log("item", this.statusApprovalData);
    
  //   this.pitService.preClearanceSatusApproval(this.statusApprovalData).subscribe(data =>{
  //     this.toastr.success("Pre Clearance Successfully Approved");
  //     window.location.reload();
  //    })
  // }
  statusapprovalmailconfig(status,valId){
    let rejectedReasonStatus = this.reason.nativeElement.value;
    this.statusApprovalData['complianceofficeremail'] = this.complianceOfficerEmail;
    this.statusApprovalData['complianceofficername'] = this.complianceOfficerName;
    this.statusApprovalData['complianceofficerdesignation'] = this.complianceOfficerDesignation;
    this.statusApprovalData['companyname'] = this.companyName;
    this.statusApprovalData['status'] = status;
    this.statusApprovalData['id'] = valId;
    this.statusRejectData['reason_for_reject'] = rejectedReasonStatus;
    this.statusRejectData['preDayCount'] = this.preDayCount;
    this.statusRejectData['tradingcompanyname'] = this.tradingcompanyname;
    this.pitService.statusapprovalmailconfig(this.statusRejectData).subscribe(data =>{ 
      
    });
}
  rejectStatus(){
    let rejectedStatus = this.reject.nativeElement.value;
    let rejectedReasonStatus = this.reason.nativeElement.value;
    
    this.statusRejectData['complianceofficeremail'] = this.complianceOfficerEmail;
    this.statusRejectData['complianceofficername'] = this.complianceOfficerName;
    this.statusRejectData['complianceofficerdesignation'] = this.complianceOfficerDesignation;
    this.statusRejectData['companyname'] = this.companyName;
    this.statusRejectData['preDayCount'] = this.preDayCount;
    this.statusRejectData['status'] = rejectedStatus;
    this.statusRejectData['reason_for_reject'] = rejectedReasonStatus;
    this.isLoading = true;
    this.button = 'Processing';
    this.pitService.preClearanceSatusApproval(this.statusRejectData).subscribe(data =>{
      this.isLoading = false;
      this.button = 'Reject';
      let statusApprovalId = data.statusApprovalId
      this.statusapprovalmailconfig(rejectedStatus,statusApprovalId)
      this.toastr.success("Pre Clearance Rejected");
      
      window.location.reload();
     })
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
