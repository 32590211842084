import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';
@Component({
  selector: 'app-delete-form-c-and-form-d',
  templateUrl: './delete-form-c-and-form-d.component.html',
  styleUrls: ['./delete-form-c-and-form-d.component.scss']
})
export class DeleteFormCAndFormDComponent implements OnInit {
  twcId: any;
  accessSuccess: string;
  formReasonDelete: FormGroup;
  getId: any;
  submitted = false;
  @Input() deleteTradingFormList;
  isLoading = false;
  button = 'Delete';
  formTypes: any;
  constructor(private fb: FormBuilder, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal, private pitService: PitComplianceService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }
  ngOnInit(): void {

    this.formReasonDelete = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('', Validators.required)
    });
   this.getId = this.deleteTradingFormList.id;
   this.formTypes = this.deleteTradingFormList.formTypes;
  }
  onSubmit(){
    this.submitted = true;
    this.formReasonDelete.value.id = this.getId;
    if(this.formReasonDelete.status !== 'INVALID'){
    this.isLoading = true;
    this.button = 'Processing';    
    this.pitService.deleteFormCandD(this.formReasonDelete.value).subscribe(data =>{
      console.log(this.formReasonDelete.value )
      this.toastr.success(data['message']);
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      this.activeModal.close(event);
      window.location.reload();
      
    })
  }else{
    if(this.formReasonDelete.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting " + this.formTypes);
    }
  }
}
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }


}
