import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsiderServiceService {
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl
  
 insiderList(userObj:any):Observable<any> {
    // return this.http.get(this.baseUrl + 'upsi/list')
    // const httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   // 'Authorization': this.accessToken
    // });
    return this.http.post(this.baseUrl + 'insider/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
      // observe: 'response'
    });
  }
  addinsider(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "insider/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
   }
   viewInsider(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "insider/view", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
   editInsider(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "insider/edit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  updateInsider(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "insider/update", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  deleteInsider(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'insider/delete' , userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
}
insiderActivity(userObj:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'insider/insiderActivityHistory',userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
viewActivityInsider(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/insiderActivityHistoryView", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
}
sendJSONToServerInsider(reqData:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
   return this.http.post(this.baseUrl + 'insider/indexexcelimport',reqData, {  
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
deletedinsiderList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/deletedlist", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
 }
 revertInsider(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/revertinsider", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
dpInsiderrecipient(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/bothlist", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
alldpInsiderrecipient(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/allUpsilist", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
}
