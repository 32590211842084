<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>Email Configuration</h5>
                    <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form  [formGroup]="mailSettingCreateForm" class="needs-validation" novalidate (ngSubmit)="onSubmit()">
                        <div class="row" >
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Email ID <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="email" placeholder="Email Id" autocomplete="off" formControlName="email"
                                     required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.email.touched) && mailSettingCreateForm.controls.email.errors }"> 
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.email?.touched) && mailSettingCreateForm.controls.email.errors?.required"
                                    class="text text-danger">
                                    Email ID is required.
                                </div>                            
                                </div>
                            </div>                          
                       
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Password <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="password" class="form-control" id="password" placeholder="Password" autocomplete="off" formControlName="password"
                                     required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.password.touched) && mailSettingCreateForm.controls.password.errors }"> 
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.password?.touched) && mailSettingCreateForm.controls.password.errors?.required"
                                    class="text text-danger">
                                    Password is required.
                                </div>                            
                                </div>
                            </div> 

                            <div class="col-md-6 mb-3">
                                <label for="projectName">Mail Type <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select" id="mailtype" (change)="changeFn()" formControlName="mailtype" required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.mailtype.touched) && mailSettingCreateForm.controls.mailtype.errors }">
                                        <option value="" disabled selected>--Choose One --</option>                                               
                                        <option value="Gmail">Gmail</option>
                                        <option value="Outlook">Outlook</option>   
                                        <option value="Office365">Office365</option>   
                                        <option value="webmail">webmail</option>   
                                    </select>
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.mailtype?.touched) && mailSettingCreateForm.controls.mailtype.errors?.required"
                                    class="text text-danger">
                                    Mail Type is required.
                                </div>                            
                                </div>
                            </div> 

                            <div class="col-md-6 mb-3">
                                <label for="projectName">Host <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="host" [(ngModel)]="hostname" placeholder="Host" autocomplete="off" formControlName="host"
                                     required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.host.touched) && mailSettingCreateForm.controls.host.errors }"> 
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.host?.touched) && mailSettingCreateForm.controls.host.errors?.required"
                                    class="text text-danger">
                                    Host is required.
                                </div>                            
                                </div>
                            </div>
                            
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Port <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="port" placeholder="port" autocomplete="off" formControlName="port"
                                     required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.port.touched) && mailSettingCreateForm.controls.port.errors }"> 
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.port?.touched) && mailSettingCreateForm.controls.port.errors?.required"
                                    class="text text-danger">
                                    Port is required.
                                </div>                            
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Encryption Type <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select" id="encryptiontype"  formControlName="encryptiontype" required [ngClass]="{ 'is-invalid': (submitted || mailSettingCreateForm.controls.encryptiontype.touched) && mailSettingCreateForm.controls.encryptiontype.errors }">
                                        <option value="" disabled selected>--Choose One --</option>                                               
                                        <option value="tls">tls</option>
                                        <option value="ssl">ssl</option>
                                        <option value="No Mail Encryption">No Mail Encryption</option>     
                                    </select>
                                    <div *ngIf="(submitted || mailSettingCreateForm.controls.encryptiontype?.touched) && mailSettingCreateForm.controls.encryptiontype.errors?.required"
                                    class="text text-danger">
                                    Encryption Type is required.
                                </div>                            
                                </div>
                            </div>
                          
                            
                              <div class="submit-btn">
                                <button class="btn btn-primary" type="submit" [disabled]="isLoading" ><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                <button class="btn btn-warning" type="button" (click)="cancelMailSetting()">Cancel</button>                    
                                <button class="btn btn-primary" type="button" [disabled]="isLoading2" (click)="testingMailSetting()" *ngIf="listMailSetting?.length > 0"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading2, 'fa-check': !isLoading2}"></i> {{button3}}</button>                    
                             </div>
                        </div>
                    </form>
                </div>
            </div>                       
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

