import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {environment} from 'src/environments/environment'
import { Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
@Injectable({
  providedIn: 'root'
})
export class UpsiService {
  setUserData(COMPANYDB: import("../shared/data/tables/company").CompanyDB[]) {
    throw new Error('Method not implemented.');
  }
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl;
  
  upsiTransferListDashboard(userObj:any) {  
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': this.accessToken
    });
     return this.http.post(this.baseUrl + 'upsi/upsiCount', userObj, {  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  upsiTransferList(userObj:any) {
    
    return this.http.post(this.baseUrl + 'upsi/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  getReportData(userObj:any) {
    
    return this.http.post(this.baseUrl + 'upsi/getReportData', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  
  upsiTransferListitem() {
    // return this.http.get(this.baseUrl + 'upsi/list')
    return this.http.post(this.baseUrl + 'upsi/list', {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  filterUpsiTransfer(queryParams: string):Observable<any>{
    return this.http.get(this.baseUrl + "upsi/indexexcel"+ queryParams, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      responseType: 'blob'
    });
  }
  historyUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/history", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      
    });
  }
  addUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
  }
  viewUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/view", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  editUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/edit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  mailPreviewUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "upsi/mailPreview", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  updateUpsiTransfer(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "upsi/update" ,userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  deleteUpsiTransfer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'upsi/delete', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    });
}
//confidentiality_agreement_entered
// confidentialityUpsiTransfer():Observable<any>{
//   return this.http.get(this.baseUrl + "upsi/confidentiality", {
//     headers: {'Authorization': sessionStorage.getItem('access_token')},
    
//   });
// }

//UpsiActivity
upsiActivity(userObj:any):Observable<any>{
  // return this.http.get(this.baseUrl + "upsiActivity", {
  //   headers: {'Authorization': sessionStorage.getItem('access_token')},
    
  // });
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'upsi/upsiActivityHistory',userObj, {

    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
upsiViewActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/upsiActivityHistoryView", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
noupsiActivity(userObj:any):Observable<any>{
 
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
  return this.http.post(this.baseUrl + 'upsi/noupsiActivityHistory',userObj, {

    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
noupsiViewActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/noupsiActivityHistoryView", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')},
  })
}
//No longer
upsiNoLonger(userObj:any):Observable<any>{
  // return this.http.get(this.baseUrl + "upsiActivity", {
  //   headers: {'Authorization': sessionStorage.getItem('access_token')},
    
  // });
  // const httpHeaders = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   // 'Authorization': this.accessToken
  // });
  return this.http.post(this.baseUrl + 'upsi/noLongerUpsi', userObj, {

    headers: {'Authorization': sessionStorage.getItem('access_token')}
   
  });
}
noUpsi(userObj:any):Observable<any>{
 
  return this.http.post(this.baseUrl + 'upsi/noupsi', userObj, {

    headers: {'Authorization': sessionStorage.getItem('access_token')}
   
  });
}
noupsimail(userObj:any):Observable<any>{
 
  return this.http.post(this.baseUrl + 'upsi/noupsimail', userObj, {

    headers: {'Authorization': sessionStorage.getItem('access_token')}
   
  });
}

sendJSONToServer(reqData:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });

   return this.http.post(this.baseUrl + 'upsi/indexexcelimport',reqData, {  
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
//Mail Config
mailUpsiTransfer(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/mailconfig", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  });
}
//attachment api
upsiTransferAttachment(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/upsiattachment", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  });
}
updateNoUpsiModule(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/updatenoupsi", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 nonUpsiCountCheck(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/noupsicountcheck", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 upsiCountCheck(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "upsi/upsicountcheck", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 insiderList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "insider/InsnamesList", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 projectList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "project/ProjectNamesList", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 customerList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/CustomerNamesList", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 dpModuleList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "dp/DPNamesList", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
 }
 

}
