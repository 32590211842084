import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../../pit-compliance.service';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-edit-form-c-and-form-d',
  templateUrl: './edit-form-c-and-form-d.component.html',
  styleUrls: ['./edit-form-c-and-form-d.component.scss']
})
export class EditFormCAndFormDComponent implements OnInit {
  @ViewChild('sQty') sQty!: ElementRef;
  @ViewChild('sQty2') sQty2!: ElementRef;
  @ViewChild('sQty3') sQty3!: ElementRef;
  @ViewChild('sQty4') sQty4!: ElementRef;
  hideFormC:boolean = false;
  hideFormD:boolean = false;
  formCEditForm: FormGroup;
  formDEditForm: FormGroup;
  isLoading = false;
  button = 'Update';
  isLoading2 = false;
  button2 = 'Update';
  submitted:boolean = false;
  formTypes: any;
  exchangeTradeData:any;
  selectedStockExchange = [];
  selectedStockExchangeD = [];
  exchangeTradeFilter: any[];
  formId: any;
  responseDate: any;
  formEditId: any;
  isinNumber: any;
  companyName: any;
  selectedOthers: any;
  selectedOthersformD: any;
  selectedTransactionTypeFormC: any;
  selectedTransactionTypeFormD: any;
  othersTransactionTypeFormC:boolean = false;
  othersTransactionTypeFormD:boolean = false;
  othersModeofAcquisition:boolean = false;
  othersModeofAcquisitionFormD:boolean = false;
  perShareHoldingFormC: any;
  perShareHoldingFormD: any;
  perShareHolding2FormD: any;
  perShareHolding2FormC: any;
  companyshares: any;
  selectedcategoryPersonFormC: any;
  otherscategoryPersonFormC:boolean = false;
  constructor(private fb: FormBuilder, private router:Router, private toastr: ToastrService, private activateRouter:ActivatedRoute,
    private pitService: PitComplianceService, private companyService: CompanyService) { }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.formId = {'id' : data['id']}
      
    })
    if(this.formId['id'] !==''){

      this.pitService.viewFormCandD(this.formId)
      .toPromise().then(result =>{
        this.formEditId = result.data[0].id;
        this.formTypes = result.data[0].formTypes;
        
        this.isinNumber =result.data[0].isinNumber;
        this.companyName = result.data[0].companyName;
        if(result.data[0].formTypes == 'Form C'){  
          this.selectedStockExchange = result.data[0].exchangeTrade;   
          this.perShareHolding2FormC = result.data[0].perShareHoldingpost;
          this.perShareHoldingFormC = result.data[0].perShareHolding;    
          this.formCEditForm.patchValue(result.data[0]);
        }else{
          this.selectedStockExchangeD = result.data[0].exchangeTrade;
          this.perShareHolding2FormD = result.data[0].perShareHoldingpost;
          this.perShareHoldingFormD = result.data[0].perShareHolding;
          this.formDEditForm.patchValue(result.data[0]);
          
        }
      

    if(this.formTypes == 'Form C' ){
      this.hideFormC = true;
      this.hideFormD = false;
    }else{
      this.hideFormD = true;
      this.hideFormC = false;
    }

      })

    this.formCEditForm = this.fb.group({
      companyName: new FormControl (''),
      isinNumber: new FormControl (''),
      name: new FormControl ('', Validators.required),
      panNo: new FormControl ('', Validators.required),
      cin_din: new FormControl ('', Validators.required),
      address: new FormControl ('', Validators.required),
      mobNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      categoryPerson: new FormControl ('', Validators.required),
      typeOfSecurities: new FormControl ('', Validators.required),
      perShareNumber: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHolding: new FormControl (''),
      typeOfSecurities2: new FormControl ('', Validators.required),
      typeOfSecuritiesNo: new FormControl ('', Validators.required),
      typeOfSecuritiesValue: new FormControl ('', Validators.required),
      transactionType: new FormControl ('', Validators.required),
      typeOfSecuritiespost: new FormControl ('', Validators.required),
      perShareHoldingNopost: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHoldingpost: new FormControl (''),
      fromDate: new FormControl ('', Validators.required),
      toDate: new FormControl ('', [Validators.required, this.dateRangeValidator]),
      intimateDate: new FormControl ('', Validators.required),
      modeOfAcquisition: new FormControl ('', Validators.required),
      othersModeofAcquisition: new FormControl(''),
      otherstransactiontype: new FormControl(''),
      otherscategoryofPerson: new FormControl(''),
      exchangeTrade: new FormControl ('', Validators.required)
    });
    //Form D 
    this.formDEditForm = this.fb.group({          
      name: new FormControl ('', Validators.required),
      panNo: new FormControl ('', Validators.required),
      cin_din: new FormControl ('', Validators.required),
      address: new FormControl ('', Validators.required),
      mobNo: new FormControl ('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      companyName: new FormControl (''), 
      typeOfSecurities: new FormControl ('', Validators.required),
      perShareNumber: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHolding: new FormControl (''),
      typeOfSecurities2: new FormControl ('', Validators.required),
      typeOfSecuritiesNo: new FormControl ('', Validators.required),
      typeOfSecuritiesValue: new FormControl ('', Validators.required),
      transactionType: new FormControl ('', Validators.required),
      typeOfSecuritiespost: new FormControl ('', Validators.required),
      perShareHoldingNopost: new FormControl ('', [Validators.required, Validators.pattern("^[0-9]*$")]),
      perShareHoldingpost: new FormControl (''),
      fromDate: new FormControl ('', Validators.required),
      toDate: new FormControl ('', [Validators.required,  this.dateRangeValidator2]),
      intimateDate: new FormControl ('', Validators.required),
      modeOfAcquisition: new FormControl ('', Validators.required),
      othersModeofAcquisition: new FormControl(''),
      otherstransactiontype: new FormControl(''),
      exchangeTrade: new FormControl ('', Validators.required)
    });


  }
    //Exchange on which the trade
    this.exchangeTradeData = [
      "BSE Ltd.",
      "Calcutta Stock Exchange Ltd.",
      "Metropolitan Stock Exchange of India Ltd.",
      "Multi Commodity Exchange of India Ltd.",
      "National Commodity & Derivatives Exchange Ltd.",
      "Indian Commodity Exchange Limited",
      "National Stock Exchange of India Ltd."      
    ]
    this.exchangeTradeFilter = this.exchangeTradeData.map(x => x);

     //company Profile
     this.companyService.CompanyList(null).subscribe(
      res => {
        if(res.body['message'] == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
      let listCompany = res.body['data']     
     
      this.isinNumber = listCompany[0].isin;
      this.companyName = listCompany[0].company_name;
      this.companyshares = listCompany[0].companyshares;
        }
        },
        err => {
            console.log(err);
        }
    );

  }
  formTypeChoose(e){     
    if(e.value == 'Form C' ){
      this.hideFormC = true;
      this.hideFormD = false;
      this.formCEditForm.reset();
    }else{
      this.hideFormD = true;
      this.hideFormC = false;
      this.formDEditForm.reset();
    }
  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.formCEditForm && this.formCEditForm.get("fromDate").value;
    const to = this.formCEditForm && this.formCEditForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  private dateRangeValidator2: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.formDEditForm && this.formDEditForm.get("fromDate").value;
    const to = this.formDEditForm && this.formDEditForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  onSelectedQty1C(){
    const quantity3 = this.sQty3.nativeElement.value;
    this.perShareHoldingFormC = quantity3 / this.companyshares * 100;
  }
  onSelectedQty2C(){
    const quantity4 = this.sQty4.nativeElement.value;
    this.perShareHolding2FormC = quantity4 / this.companyshares * 100;
  }
  onSelectedQty1D(){
    const quantity1 = this.sQty.nativeElement.value;
    this.perShareHoldingFormD = quantity1 / this.companyshares * 100;
  }
  onSelectedQty2D(){
    const quantity2 = this.sQty2.nativeElement.value;
    this.perShareHolding2FormD = quantity2 / this.companyshares * 100 ;
  }
  modeofAcquisitionTypeChanged() {
    this.selectedOthers = this.formCEditForm.get('modeOfAcquisition')    
    if(this.selectedOthers?.value == "Others"){
      this.othersModeofAcquisition = true;
    }
  }
  modeofAcquisitionTypeChangedformD() {
    this.selectedOthersformD = this.formDEditForm.get('modeOfAcquisition')    
    if(this.selectedOthersformD?.value == "Others"){
      this.othersModeofAcquisitionFormD = true;
    }
  }
  otherTransactionTypeFormC() {
    this.selectedTransactionTypeFormC = this.formCEditForm.get('transactionType')    
    if(this.selectedTransactionTypeFormC?.value == "Others"){
      this.othersTransactionTypeFormC = true;
    }
  }
  otherTransactionTypeFormD() {
    this.selectedTransactionTypeFormD = this.formDEditForm.get('transactionType')    
    if(this.selectedTransactionTypeFormD?.value == "Others"){
      this.othersTransactionTypeFormD = true;
    }
  }
  otherCategoryOfPersonFormC() {
    this.selectedcategoryPersonFormC = this.formCEditForm.get('categoryPerson')    
    if(this.selectedcategoryPersonFormC?.value == "Others"){
      this.otherscategoryPersonFormC = true;
    }
  }
  resetForm(){
    this.formCEditForm.reset()
    this.formDEditForm.reset();
    this.router.navigate(['/pit-compliance/list-trading-report']); 
  }
  onSubmitFormC(){
    let idendiferCard = this.formCEditForm.get('panNo').value; 
    if(this.formCEditForm.get('panNo').value != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       return false;
      }
    }
      this.submitted = true;
      
    this.formCEditForm.value.formTypes = this.formTypes;
    this.formCEditForm.value.id = this.formEditId;
     const emptyFileds = Object.keys(this.formCEditForm.controls).filter(key =>this.formCEditForm.controls[key].errors !== null)

     if(this.formCEditForm.status !== 'INVALID'){
    this.button = 'Processing...';
    this.isLoading = true;
    this.pitService.updateFormCandD(this.formCEditForm.value).subscribe(
      res => {
        this.responseDate = res['data'];
        if(res['success'] == 1){
          this.toastr.success(res['message']) 
          this.isLoading = false;
          this.button = 'Update';
          this.router.navigate(['/pit-compliance/list-trading-report']); 
         
        }else{
          this.isLoading = false;
          this.button = 'Update';         
          this.toastr.warning(res['message']) 
        }
                  
      });
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.formCEditForm.get('name').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.formCEditForm.get('panNo').value == ''){
        this.toastr.warning("Please fill the PAN Number.");
      }else if(this.formCEditForm.get('cin_din').value == ''){
        this.toastr.warning("Please fill the CIN/DIN Number.");
      }else if(this.formCEditForm.get('address').value == ''){
        this.toastr.warning("Please fill the Address.");
      }else if(this.formCEditForm.get('mobNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }else if(this.formCEditForm.get('categoryPerson').value == ''){
        this.toastr.warning("Please fill the  Category of Person.");
      }else if(this.formCEditForm.get('typeOfSecurities').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal Type of securities.");
      }else if(this.formCEditForm.get('perShareNumber').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal No. of share holding.");
      }else if(this.formCEditForm.get('typeOfSecurities2').value == ''){
        this.toastr.warning("Please fill the Securities acquired/Disposed Type of securities.");
      }else if(this.formCEditForm.get('typeOfSecuritiesNo').value == ''){
        this.toastr.warning("Please fill the No. of securities acquired/ disposed.");
      }else if(this.formCEditForm.get('typeOfSecuritiesValue').value == ''){
        this.toastr.warning("Please fill the securities acquired/ disposed Value of Securities.");
      }else if(this.formCEditForm.get('transactionType').value == ''){
        this.toastr.warning("Please fill the Transaction Type.");
      }else if(this.formCEditForm.get('typeOfSecuritiespost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed Type of securities.");
      }else if(this.formCEditForm.get('perShareHoldingNopost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed No. of share holding.");
      }else if(this.formCEditForm.get('fromDate').value == ''){
        this.toastr.warning("Please fill the From Date.");
      }else if(this.formCEditForm.get('toDate').value == ''){
        this.toastr.warning("Please fill the To Date.");
      }else if(this.formCEditForm.get('intimateDate').value == ''){
        this.toastr.warning("Please fill the Date of intimation to company.");
      }else if(this.formCEditForm.get('modeOfAcquisition').value == ''){
        this.toastr.warning("Please fill the Mode of acquisition /disposal.");
      }else if(this.formCEditForm.get('exchangeTrade').value == ''){
        this.toastr.warning("Please fill the Exchange on which the trade.");
      }
    }
  }
  onSubmitFormD(){
    let idendiferCard = this.formDEditForm.get('panNo').value; 
    
    if(this.formDEditForm.get('panNo').value != ''){
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       return false;
      }
    }
      this.submitted = true;
      this.formDEditForm.value.formTypes = this.formTypes;
      this.formDEditForm.value.id = this.formEditId;
    const emptyFileds = Object.keys(this.formDEditForm.controls).filter(key =>this.formDEditForm.controls[key].errors !== null)

     if(this.formDEditForm.status !== 'INVALID'){
    
    this.button2 = 'Processing...';
    this.isLoading2 = true;
    this.pitService.updateFormCandD(this.formDEditForm.value).subscribe(
      res => {
        this.responseDate = res['data'];
        if(res['success'] == 1){
          this.toastr.success(res['message']) 
          this.isLoading2 = false;
          this.button2 = 'Update';         
           this.router.navigate(['/pit-compliance/list-trading-report']); 
      
        }else{
          this.isLoading2 = false;
          this.button2 = 'Update';         
          this.toastr.warning(res['message']) 
        }
      });
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.formDEditForm.get('name').value == ''){
        this.toastr.warning("Please fill the Name.");
      }else if(this.formDEditForm.get('panNo').value == ''){
        this.toastr.warning("Please fill the PAN Number.");
      }else if(this.formDEditForm.get('cin_din').value == ''){
        this.toastr.warning("Please fill the CIN/DIN Number.");
      }else if(this.formDEditForm.get('address').value == ''){
        this.toastr.warning("Please fill the Address.");
      }else if(this.formDEditForm.get('mobNo').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }else if(this.formDEditForm.get('typeOfSecurities').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal Type of securities.");
      }else if(this.formDEditForm.get('perShareNumber').value == ''){
        this.toastr.warning("Please fill the Securities held prior to acquisition/ disposal No. of share holding.");
      }else if(this.formDEditForm.get('typeOfSecurities2').value == ''){
        this.toastr.warning("Please fill the Securities acquired/Disposed Type of securities.");
      }else if(this.formDEditForm.get('typeOfSecuritiesNo').value == ''){
        this.toastr.warning("Please fill the No. of securities acquired/ disposed.");
      }else if(this.formDEditForm.get('typeOfSecuritiesValue').value == ''){
        this.toastr.warning("Please fill the securities acquired/ disposed Value of Securities.");
      }else if(this.formDEditForm.get('transactionType').value == ''){
        this.toastr.warning("Please fill the Transaction Type.");
      }else if(this.formDEditForm.get('typeOfSecuritiespost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed Type of securities.");
      }else if(this.formDEditForm.get('perShareHoldingNopost').value == ''){
        this.toastr.warning("Please fill the Securities held post acquired/Disposed No. of share holding.");
      }else if(this.formDEditForm.get('fromDate').value == ''){
        this.toastr.warning("Please fill the From Date.");
      }else if(this.formDEditForm.get('toDate').value == ''){
        this.toastr.warning("Please fill the To Date.");
      }else if(this.formDEditForm.get('intimateDate').value == ''){
        this.toastr.warning("Please fill the Date of intimation to company.");
      }else if(this.formDEditForm.get('modeOfAcquisition').value == ''){
        this.toastr.warning("Please fill the Mode of acquisition /disposal.");
      }else if(this.formDEditForm.get('exchangeTrade').value == ''){
        this.toastr.warning("Please fill the Exchange on which the trade.");
      }
    }
  }
}
