<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                
               <h4 class="head" *ngIf="viewNoUpsi == 'Non UPSI Communication'">This communication is determined to be Not Unpublished Price Sensitive Information (Not UPSI) for the following reason:</h4>
               
                    <div class="card-body custom-datatable noscroll">
                        <div class="custom-datatable">
                            <form [formGroup]="noUpsiCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-sm-12 col-xl-12">
                                       
                                            <div class="col-md-12 mb-3">
                                                <label for="nameOfInsider">Reason to Move {{confupsilist}}</label>
                                                <input type="text" class="form-control"  id="reasonformove" placeholder="Reason to Move {{confupsilist}}" formControlName="reasonformove" autocomplete="off" required
                                                [ngClass]="{ 'is-invalid': (submitted || noUpsiCreateForm.controls.reasonformove.touched) && noUpsiCreateForm.controls.reasonformove.errors }"> 
                                                <div *ngIf="(submitted || noUpsiCreateForm.controls.reasonformove.touched) && noUpsiCreateForm.controls.reasonformove.errors?.required"
                                                class="text text-danger">
                                                Please mention the reason to Move {{confupsilist}}
                                                </div>                                      
                                            </div>
                                            <div class="submit-btn">
                                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                                <button class="btn btn-warning" type="button" (click)="closeModal('close')">No</button>                    
                                             </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <p><b>Note:</b> Email will be automatically send to the sender, recipient and creator.</p>
    <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button> -->
</div>
