import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-systeminfo-view',
  templateUrl: './systeminfo-view.component.html',
  styleUrls: ['./systeminfo-view.component.scss']
})
export class SysteminfoViewComponent implements OnInit {
  @Input() viewsystemInfoHistory;
  public active1 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity: any;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
   
console.log(this.viewsystemInfoHistory)

    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
