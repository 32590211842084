import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-dp-component',
  templateUrl: './error-dp-component.component.html',
  styleUrls: ['./error-dp-component.component.scss']
})
export class ErrorDpComponentComponent implements OnInit {
  @Input() viewDpErrorList;
  page = 1;
  pageSize = 10;
  basicDpList:boolean = false;
  immediateDpList:boolean = false;
  constructor(public activeModal: NgbActiveModal, private router:Router) { }

  ngOnInit(): void {
    //this.viewDpErrorList;
    console.log(this.viewDpErrorList)
    if(this.viewDpErrorList.success == '2'){     
        this.basicDpList = true;
        this.immediateDpList = false;
    }else if(this.viewDpErrorList.success == '1'){
      if(this.viewDpErrorList.duplicates.length > 0){
        this.basicDpList = true;
        this.immediateDpList = false;
      }
    }else if(this.viewDpErrorList.success == '3'){
      this.basicDpList = false;
      this.immediateDpList = true;
    }else if(this.viewDpErrorList.success == '4'){
      this.basicDpList = false;
      this.immediateDpList = true;
    }
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    window.location.reload();
  }
}
