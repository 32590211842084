import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { AuthlogService } from "../authlog.service"
import { ToastrService } from 'ngx-toastr';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
//import { Input } from 'hammerjs';
@Component({
  selector: 'app-opt-auth',
  templateUrl: './opt-auth.component.html',
  styleUrls: ['./opt-auth.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: NgxOtpInputComponent
    }
  ] 
})
export class OptAuthComponent implements OnInit {
  public newUser = false;
  otpVerifiedForm: FormGroup;
  emptyMessage: boolean = false;
  hideForm: boolean = true;
  authGuard: string;
  forgetPasswordLink: any;
  otpAuth: any;
  accessUser: string;
  isLoading = false;
  button = 'Submit';
  authToken: string;
  constructor(private fb: FormBuilder,
    private router: Router, private loginService: LoginService, private authlog: AuthlogService, private toastr: ToastrService) {
      this.accessUser = sessionStorage.getItem('access_user');
      this.authToken = sessionStorage.getItem('access_tokens');
     
  }
  
 
  ngOnInit() {
    // if(sessionStorage.getItem('access_token') && this.router.url == '/auth/otp-authentication') {
    //   this.router.navigate(['/dashboard']);
    // }

    
    this.otpVerifiedForm = this.fb.group({
      //otpauth: new FormControl(''),
      username: new FormControl('')   
    });
    ;
  }

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  handeOtpChange(value: string[]): void {
  }

  handleFillEvent(value: string): void {
    this.otpAuth = value;
  }
  otpVerified(){
    
    this.otpVerifiedForm.value.username = this.accessUser 
    this.otpVerifiedForm.value.otp = this.otpAuth 
    this.otpVerifiedForm.value.authToken = this.authToken 
  
    //return false;
    if(this.otpVerifiedForm.status !== 'INVALID'){
      this.isLoading = true;
    this.button = 'Processing';
    this.authlog.otpVerifiedData(this.otpVerifiedForm.value).subscribe(data =>{
      if(data.success == 1){

       let tem_token =  sessionStorage.getItem('temp_token');

      sessionStorage.setItem('access_token', 'Bearer ' + tem_token);
      
      sessionStorage.removeItem('temp_token');
      
      //this.getdpdetails()
     // this.open();   
     this.isLoading = false;
      this.button = 'Submit';     
      this.toastr.success("Login Successful!"); 
      sessionStorage.setItem('access_token', 'Bearer ' + data.access_token);
      if(data.permissionSettings && data.permissionSettings[0].role_type){
        sessionStorage.setItem('sdd_user_type', data.permissionSettings[0].role_type);
        sessionStorage.setItem('access_user', data.permissionSettings[0].username);
        sessionStorage.setItem('sdd_access_roles', btoa(data.permissionSettings[0].module_name));
        sessionStorage.setItem('access_success', data.success);
        let accessUser = sessionStorage.getItem('access_user')

        sessionStorage.setItem('model_access_controller', data.modelPermission[0].module_controller);
        
        let accessObj = data.permissionSettings;
        this.router.navigate(['dashboard/']);  
      
      }      
    
    }else if(data.success == 2){
      this.isLoading = false;
      this.button = 'Submit'; 
      this.toastr.error(data.message);
    } else {
      this.isLoading = false;
      this.button = 'Submit'; 
      this.toastr.error(data.message);
    }
      })
    }else{
      if(this.otpVerifiedForm.get('otp').value == ''){
        this.toastr.warning("Please fill the OTP.");
        return false;
      }
    }
  }

}
