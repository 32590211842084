<div class="modal-header">
    <h5 class="modal-title">Change Password</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="theme-form change" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label class="col-form-label">New Password</label>
            <div class="hidePassword">
                <div class="input-group-append pass-right">
                    <span class="input-group-text pass-right">
                        <i
                        class="fa"
                        [ngClass]="{
                          'fa-eye-slash': !fieldTextType,
                          'fa-eye': fieldTextType
                        }"
                        (click)="toggleFieldTextType()"
                      ></i>
                    </span>
                 </div>
            <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" required="" placeholder="New Password">
            <!-- <div *ngIf="changePasswordForm.controls.password.touched && changePasswordForm.controls.password.errors?.required"
                class="text text-danger mt-1">
                Password is required
            </div> -->
            <div class="errors" *ngIf="changePasswordForm.get('password').invalid && (changePasswordForm.get('password').touched || changePasswordForm.get('password').dirty)">

                <div *ngIf="changePasswordForm.get('password').hasError('required')">
                    New Password is required
                </div>
        
                <div *ngIf="changePasswordForm.get('password').errors.minlength">
                    New Password must be at least 8 characters
                </div>
        
                <div class="error-text" *ngIf="changePasswordForm.get('password').hasError('passwordStrength')">
                    {{changePasswordForm.get('password').errors['passwordStrength']}}
                </div>
            </div>
            <div *ngIf="changePasswordForm.value.password != '' ">
                <app-strength-checker2 barLabel="Password Strength: " [passwordToVerify]="changePasswordForm.value.password" (passwordStrength)="passwordValid($event)" ></app-strength-checker2>
                </div>
            </div>      
        </div>
        <div class="form-group">
            <label class="col-form-label">Confirm New Password</label>
            <div class="hidePassword">
                <div class="input-group-append pass-right">
                    <span class="input-group-text pass-right">
                        <i
                        class="fa"
                        [ngClass]="{
                          'fa-eye-slash': !fieldTextType2,
                          'fa-eye': fieldTextType2
                        }"
                        (click)="toggleFieldTextType2()"
                      ></i>
                    </span>
                 </div>
            <input class="form-control" [type]="fieldTextType2 ? 'text' : 'password'" formControlName="conformPassword" required="" placeholder="Confirm New Password">
            <!-- <div *ngIf="changePasswordForm.controls.conformPassword.touched && changePasswordForm.controls.conformPassword.errors?.required"
                class="text text-danger mt-1">
                Conform Password is required
            </div> -->
            <div class="errors" *ngIf="changePasswordForm.get('conformPassword').invalid && (changePasswordForm.get('conformPassword').touched || changePasswordForm.get('conformPassword').dirty)">

                <div *ngIf="changePasswordForm.get('conformPassword').hasError('required')">
                    Confirm New Password is required
                </div>
        
                <div *ngIf="changePasswordForm.get('conformPassword').errors.minlength">
                    Confirm New Password must be at least 8 characters
                </div>
        
                <div class="error-text" *ngIf="changePasswordForm.get('conformPassword').hasError('passwordStrength')">
                    {{changePasswordForm.get('conformPassword').errors['passwordStrength']}}
                </div>
            </div>
            <!-- <div *ngIf="changePasswordForm.controls.errors?.MustMatch"
                class="text text-danger mt-1">
                Conform Password Didn't Match
            </div> -->
            <div *ngIf="changePasswordForm.value.conformPassword != '' ">
                <app-strength-checker2 barLabel="Confirm Password Strength: " [passwordToVerify]="changePasswordForm.value.conformPassword" (passwordStrength)="passwordValid($event)" ></app-strength-checker2>
              </div>	
           </div>
        </div>
        
        
        <div class="form-group row mt-3 mb-0">
            <!-- <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button> -->
            <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>  
        </div>
        <!-- [disabled]="!changePasswordForm.valid" -->
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>