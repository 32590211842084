import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InsiderServiceService } from '../insider-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-model-insider',
  templateUrl: './popup-model-insider.component.html',
  styleUrls: ['./popup-model-insider.component.scss']
})
export class PopupModelInsiderComponent implements OnInit {
  @Input() viewInsiderList;
  baseUrl = environment.url;
  getId: any;
  public active1 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity: any;
  constructor(
    public activeModal: NgbActiveModal, private activateRouter: ActivatedRoute, private insiderService: InsiderServiceService,
  ) { }
  
  ngOnInit() {
    this.getId = this.viewInsiderList.id;
    this.timelineActivity = this.viewInsiderList.timelineActivity; 
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
