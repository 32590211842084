import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';
import { MasterService } from 'src/app/master/master.service';
import { environment } from 'src/environments/environment';
import { UpsiService } from '../upsi.service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { UpsiMailTemplateComponent } from '../upsi-mail-template/upsi-mail-template.component';
import { CustomerService } from 'src/app/customer/customer.service';
import { CompanyService } from 'src/app/master/company/company.service';
import * as moment from 'moment';
@Component({
  selector: 'app-add-upsi',
  templateUrl: './add-upsi.component.html',
  styleUrls: ['./add-upsi.component.scss']
})
export class AddUpsiComponent implements OnInit {
  selectedProjectName = [];
  selectedSenderName = [];
  selectedDpName = [];
  @ViewChild('teams') teams!: ElementRef;
  @ViewChild('sdate') sdate!: ElementRef;
  @ViewChild('auto') auto;
  isLoading = false;
  button = 'Save';
  submitted: boolean = false;
  model: NgbDateStruct;
  model2: NgbDateStruct;
  model3: NgbDateStruct;
  upsiCreateForm: FormGroup;
  selectedOthers: any;
  othersSelect: boolean = false;
  othersSelectnature: boolean = false;
  selectedOthersupsi: any;
  listProject = [];
  dropdownList:any = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownListRep = [];
  selectedItemsRep = [];
  dropdownSettingsRep = {};
  dropdownListRepDp = [];
  dropdownListRepDp2 = [];
  selectedItemsRepDp = [];
  dropdownListRepAll = [];
  dropdownListRepBoth = [];
  selectedItemsRepAll = [];
  selectedItemsRepBoth = [];
  dropdownSettingsRepDp = {};
  dropdownSettingsRepAll = {};
  toDate: any;
  projectId = [];
  durationend: any;
  fromDate: any;
  sharingDate: any;
  listinsider: any;
  listDpModule: any;
  attachName: any;
  path: any;
  attachmentPath: boolean;
  image: string;
  accessSuccess: string;
  today = new Date();
  todaysDataTime = '';
  minutesToAdd = 15;
  addDate: Date;
  reasonField: boolean = false;
  updateDate: string;
  dropdownListRepAlls = [];
  hrefUrl: string;
  mailUrl: string;
  companyname: string;
  keyword = 'email';
  keyword2 = 'name';
  senderNameFilter: any[];
  senderDpNameFilter: any[];
  projectNameFilter: any[];
  myDate: any;
  currentDate = new Date();
  splitlistProjects: any;
  fileList: File[] = [];
  listOfFiles: any[] = [];
  isLoadings = false;
  selectedFile = [];
  pathImage: any[];
  selectedFileName: any[] = [];
  dropdownListRepCustomer = [];
  senderCustomerNameFilter = [];
  selectedCustomerName = [];
  selectedItemsRepCustomer = [];
  modelAccess: any;
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  companyApplication: any;
  projEndDate: any;
  projEndDateVal: boolean;
  projNoEndDateVal: boolean;
  mailPopupModel = environment.mailPopupModel;
  constructor(private datePipe: DatePipe, private fb: FormBuilder, private upsiService: UpsiService, public router: Router, private toastr: ToastrService, private masterService: MasterService, private activateRouter: ActivatedRoute,
    private insiderService: InsiderServiceService, private dpService: DpModuleService, private modalService: NgbModal, private customerService: CustomerService, private companyService: CompanyService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    this.modelAccess = JSON.parse(sessionStorage.getItem('model_access_controller'));

    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');

  }

  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.customer_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.customer_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    this.hrefUrl = environment.href;
    this.mailUrl = environment.mail;
    this.companyname = environment.companyName;
    this.upsiCreateForm = this.fb.group({
      project_name: new FormControl('', Validators.required),
      sender: new FormControl('', Validators.required),
      mode_of_sharing: new FormControl('', Validators.required),
      others_sharing: new FormControl('', Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
      recipientValue: new FormControl('DP', Validators.required),
      recipient: new FormControl('', Validators.required),
      purpose_of_sharing: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      nature_of_upsi: new FormControl('', Validators.required),
      others_nature_upsi: new FormControl('', Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
      type_of_sharing: new FormControl('', Validators.required),
      date_of_sharing: new FormControl('', Validators.required),
      // delay_time: new FormControl (''),
      from_date: new FormControl('', Validators.required),
      to_date: new FormControl('', [this.dateRangeValidator]),
      value_of_Sender: new FormControl('DP', Validators.required),
      // confidentiality_agreement_entered:new FormControl ('No',  Validators.required),
      // confidentiality_agreement_date: new FormControl (''),
      confidentiality_agreement_attachment: new FormControl(''),
      filePath: new FormControl(''),
      image: new FormControl(''),
      remarks: new FormControl('', Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
      public_Date: new FormControl('No'),
      agreement_attachment: new FormControl(),
      checkboxgroup: this.fb.group({
        checkbox: [false, Validators.required]
      })
    });
    this.activateRouter.params.subscribe(data => {
      this.projectId = data['id']
    })
    //Project api
    this.upsiService.projectList(null).subscribe(
      res => {

        //const listProject = res.body['data']
        const listProject = res.data;

        // listProject.map((projectItem) => {

        //   let completedProjectItems = [];
        //   if(projectItem.durationto < this.myDate){            
        //     completedProjectItems.push(projectItem); 

        //   }else{
        //     const splitlistProjects = projectItem;    
        //     this.listProject.push(splitlistProjects);

        //   } 
        // });


        let projectName = listProject?.map(res => res.project_name)
        this.projectNameFilter = [];
        from(projectName)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.projectNameFilter.push(r));

      },
      err => {
        console.log(err);
      }
    );




    this.dropdownListRepAll = [];
    this.dropdownListRepBoth = [];
    //DP Apiinsider
    
    this.upsiService.dpModuleList(null).subscribe(
      res => {
        this.listDpModule = res['data']
        //Sender
        let senderDpName = this.listDpModule?.map(res => (res.name + ' - ' + res.email + ' - ' + res.panNo))
        this.senderDpNameFilter = [];
        from(senderDpName)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.senderDpNameFilter.push(r));

        //Sender
        const dpList = this.listDpModule?.map(({ id, email, name, panNo }) => ({ id, itemName: name + ' - ' + email + ' - ' + panNo }));
        this.selectedItemsRepDp = [];
        this.dropdownListRepDp2 = [];
        const dropdownListReprecipientlist = [];
        dpList?.forEach(result => dropdownListReprecipientlist.push(result))
        from(dropdownListReprecipientlist)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.dropdownListRepDp2?.push(r));
        // const dpListAll = this.listDpModule.map(item =>({item: item.email}));
        this.dropdownListRepDp2.forEach(result => this.dropdownListRepBoth.push(result))
        this.dropdownListRepDp2.forEach(result => this.dropdownListRepAll.push(result))

        // const dropdownListRepAllss = this.dropdownListRepAlls.map(({id,itemName}) =>({id,itemName: itemName}));
        // this.dropdownListRepBoth.forEach((item, index) => item.id = index + 1);

        // from(this.dropdownListRepAlls)
        //     .pipe(
        //       tap(item => item),
        //       distinct(d => d.itemName)
        //     )
        //     .subscribe(r => this.dropdownListRepAll.push(r));

      },
      err => {
        console.log(err);
      }
    );

    //Insider Api
    this.upsiService.insiderList(null).subscribe(
      res => {
        this.dropdownListRep = [];

        const dropdownListRepInsiderlist = [];
        this.listinsider = res.data;

        //Sender
        let senderName = this.listinsider?.map(res => (res.name + ' - ' + res.email + ' - ' + res.panNo))
        this.senderNameFilter = [];
        from(senderName)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.senderNameFilter.push(r));
        //Sender

        const insiderList = this.listinsider?.map(({ id, email, name, panNo }) => ({ id, itemName: name + ' - ' + email + ' - ' + panNo }));

        insiderList.forEach(result => dropdownListRepInsiderlist.push(result))
        from(dropdownListRepInsiderlist)
          .pipe(
            tap(item => item),
            distinct(d => d.itemName)
          )
          .subscribe(r => this.dropdownListRep.push(r));
        // const insiderListAll = this.listinsider.map(item =>({item: item.email}));
        this.dropdownListRep.forEach(result => this.dropdownListRepBoth.push(result))
        this.dropdownListRepBoth.forEach((item, index) => item.id = index + 1);
        this.dropdownListRep.forEach(result => this.dropdownListRepAll.push(result))
        this.dropdownListRepAll.forEach((item, index) => item.id = index + 1);
      },
      err => {
        console.log(err);
      }
    );
    //customer

    this.companyService.CompanyList(null).subscribe(
      res => {
        if (res.body['message'] == 'Invalid User.') {
          this.router.navigate(['/auth/login']);
        } else {
          let listCompany = res.body['data']
          this.companyApplication = listCompany[0].customertype;

        }
      },
      err => {
        console.log(err);
      }
    );

    if (this.is_access_write == true) {
      this.upsiService.customerList(null).subscribe(
        res => {
          this.dropdownListRepCustomer = [];
          this.senderCustomerNameFilter = [];
          const dropdownListRepCustomerlist = [];
          this.listinsider = res.data;

          this.senderCustomerNameFilter = this.listinsider.map(item => (item.name + ' - ' + item.email + ' - ' + item.panNo));

          const insiderList = this.listinsider.map(({ id, email, name, panNo }) => ({ id, itemName: name + ' - ' + email + ' - ' + panNo }));

          insiderList.forEach(result => dropdownListRepCustomerlist.push(result))
          from(dropdownListRepCustomerlist)
            .pipe(
              tap(item => item),
              distinct(d => d.itemName)
            )
            .subscribe(r => this.dropdownListRepCustomer.push(r));
          this.dropdownListRepCustomer.forEach(result => this.dropdownListRepAll.push(result))
          this.dropdownListRepAll.forEach((item, index) => item.id = index + 1);

        },
        err => {
          console.log(err);
        }
      );
    }


    //Both Api

    // this.insiderService.alldpInsiderrecipient(null).subscribe(
    //   res => {
    //     const bothListEmail = res.data;

    //   const bothList = bothListEmail.map(({dpemail, dpname,panno}) =>({itemName: dpname + ' - ' + dpemail + ' - ' + panno}));      
    //   bothList.forEach((item, index) => item.id = index + 1);

    //   this.dropdownListRepAll = [];
    //   from(bothList)
    //   .pipe(
    //     tap(item => item),
    //     distinct(d => d)
    //   )
    //   .subscribe(r => this.dropdownListRepAll.push(r));     

    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    // this.insiderService.dpInsiderrecipient(null).subscribe(
    //   res => {
    //     const bothListEmail = res.data;

    //   const bothList = bothListEmail.map(({dpemail, dpname,panno}) =>({itemName: dpname + ' - ' + dpemail + ' - ' + panno}));      
    //   bothList.forEach((item, index) => item.id = index + 1);

    //   this.dropdownListRepBoth = [];
    //   from(bothList)
    //   .pipe(
    //     tap(item => item),
    //     distinct(d => d)
    //   )
    //   .subscribe(r => this.dropdownListRepBoth.push(r));     

    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    //companyProfile




    // Nature of UPSI Dropdown
    this.dropdownList = [
      { id: 1, itemName: 'Financial results' },
      { id: 2, itemName: 'Dividends' },
      { id: 3, itemName: 'Change in capital structure' },
      { id: 4, itemName: 'Mergers, de-mergers & acquisitions' },
      { id: 5, itemName: 'Delistings' },
      { id: 6, itemName: 'Disposals' },
      { id: 7, itemName: 'Expansion of business' },
      { id: 8, itemName: 'Changes in key managerial personnel' },
      { id: 9, itemName: 'Others' },
    ];
    this.selectedItems = [];
    this.dropdownSettings = {
      text: 'Nature of UPSI',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Nature of UPSI Dropdown

    //Recipient dropdown
    // this.dropdownListRep = [
    //   { id: 1, itemName: 'Recipient 1' },
    //   { id: 2, itemName: 'Recipient 2' },
    //   { id: 3, itemName: 'Recipient 3' },
    //   { id: 4, itemName: 'Recipient 4' },
    //   { id: 5, itemName: 'Recipient 5' },
    //   { id: 6, itemName: 'Recipient 6' },
    //   { id: 7, itemName: 'Recipient 7' },
    //   { id: 8, itemName: 'Recipient 8' },
    //   { id: 9, itemName: 'Recipient 9' },
    // ];
    this.selectedItemsRep = [];
    this.dropdownSettingsRep = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Recipient dropdown
    //Recipient Dp dropdown
    // this.dropdownListRepDp = [
    //   { id: 1, itemName: 'Recipient Dp 1' },
    //   { id: 2, itemName: 'Recipient Dp 2' },
    //   { id: 3, itemName: 'Recipient Dp 3' },
    //   { id: 4, itemName: 'Recipient Dp 4' },
    //   { id: 5, itemName: 'Recipient Dp 5' },
    //   { id: 6, itemName: 'Recipient Dp 6' },
    //   { id: 7, itemName: 'Recipient Dp 7' },
    //   { id: 8, itemName: 'Recipient Dp 8' },
    //   { id: 9, itemName: 'Recipient Dp 9' },
    //   { id: 10, itemName: 'Recipient Dp 10' },
    // ];
    this.selectedItemsRepDp = [];
    this.dropdownSettingsRepDp = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    this.selectedItemsRepAll = [];
    this.dropdownSettingsRepAll = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Recipient Dp dropdown
    this.onFileChanged(event);
  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from2 = this.upsiCreateForm && this.upsiCreateForm.get("date_of_sharing").value;
    const from = moment(from2).format('YYYY-MM-DD');
    const to = this.upsiCreateForm && this.upsiCreateForm.get("to_date").value;

    if (from && to) {

      if (new Date(from).valueOf() == new Date(to).valueOf()) {
        invalid = false;
      } else if (new Date(from).valueOf() > new Date(to).valueOf()) {
        invalid = true;
      } else {
        invalid = false;
      }
      // invalid = new Date(from).valueOf() >= new Date(to).valueOf();
    }

    return invalid ? { invalidRange: { from, to } } : null;

    //return invalid ? { invalidRange: { from, to } } : null;
  };
  // Nature of UPSI Dropdown
  onItemSelect(item: any) {

    if (item.itemName == "Others") {
      this.othersSelectnature = true;
    } else {
      this.othersSelectnature = false;
    }
  }
  OnItemDeSelect(item: any) {

    if (item.itemName == "Others") {
      this.othersSelectnature = false;
    } else {
      this.othersSelectnature = true;
    }
  }
  onSelectAll(items: any) {

    if (items[8].itemName == "Others") {
      this.othersSelectnature = true;
    } else {
      this.othersSelectnature = false;
    }
  }
  onDeSelectAll(items: any) {

    this.othersSelectnature = false;
    // if(items[8].itemName == "Others"){
    //   this.othersSelectnature = false;
    // }else{
    //   this.othersSelectnature = true;
    // }
  }
  // Nature of UPSI Dropdown
  //Recipient dropdown
  onItemSelectRep(item: any) {

  }
  OnItemDeSelectRep(item: any) {


  }
  onSelectAllRep(items: any) {
  }
  onDeSelectAllRep(items: any) {
  }
  //Recipient dropdown

  //Recipient Dp dropdown
  onItemSelectRepDp(item: any) {

  }
  OnItemDeSelectRepDp(item: any) {


  }
  onSelectAllRepDp(items: any) {
  }
  onDeSelectAllRepDp(items: any) {
  }
  //Recipient Dp dropdown
  //Recipient Dp dropdown
  onItemSelectRepAll(item: any) {

  }
  OnItemDeSelectRepAll(item: any) {


  }
  onSelectAllRepAll(items: any) {
  }
  onDeSelectAllRepAll(items: any) {
  }
  //Recipient Dp dropdown


  selectedItem: any;
  i: number;
  //Search Project
  onSelected(item) {

    let item1 = this.listProject.find(i => i.project_name === item);

    if (item1.project_name === item) {
      this.toDate = item1.durationto;

    } else {
      console.log("Error");
    }

  }
  //Noemal
  // onSelected(){
  //   // this.selectedItem = this.teams.nativeElement.value;  

  //   let item1 = this.listProject.find(i => i.project_name === this.teams.nativeElement.value);

  //   if(item1.project_name === this.teams.nativeElement.value){
  //      this.toDate = item1.durationto;

  //   }else{    console.log("Error");
  //   }

  // }
  onSelectedDate() {
    this.fromDate = this.sdate.nativeElement.value;
    //   
    // let addDate = new Date(this.today.getTime() - this.minutesToAdd*60000);    
    //     let datepicker = this.upsiCreateForm.get('date_of_sharing').value;   
    //     let updateDate = this.convert(addDate);    
    //     if(datepicker < updateDate){
    //         this.reasonField = true;
    //     }else{
    //        this.reasonField = false;
    //     }


  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${mnth}-${day}T${hours}:${minutes}`
  }

  modeOfSharingChanged() {
    this.selectedOthers = this.upsiCreateForm.get('mode_of_sharing')

    if (this.selectedOthers.value == "Others") {
      this.othersSelect = true;
    } else {
      this.othersSelect = false;
      this.upsiCreateForm.get('others_sharing').setValue("");
    }
  }
  projectchangeFn(val) {
    this.masterService.projectList(null).subscribe(
      res => {

        const listProject = res.body['data']
        listProject.map((projectItem) => {
          let completedProjectItems = [];
          if (projectItem.durationto < this.myDate) {
            completedProjectItems.push(projectItem);

          } else {
            const splitlistProjects = projectItem;
            this.listProject.push(splitlistProjects);

          }
        });
        this.listProject?.map(res => {

          if (res.project_name == val) {
            if (res.durationto == '' || res.durationto == null) {
              this.projEndDate = 'Not Provided';
              this.projEndDateVal = false;
              this.projNoEndDateVal = true;

            } else {
              this.projEndDate = res.durationto;
              this.projEndDateVal = true;
              this.projNoEndDateVal = false;

            }

          }

        })


      },
      err => {
        console.log(err);
      }
    );

  }
  //  natureOfUpsiChanged() {
  //     this.selectedOthersupsi = this.upsiCreateForm.get('nature_of_upsi')
  //     // console.log("Hii", this.selectedOthersupsi)
  //     if(this.selectedOthersupsi.value == "Others"){
  //       this.othersSelectnature = true;
  //     }else{
  //       this.othersSelectnature = false;
  //     }
  //   }
  // onDateSelect(event) {
  //   let year = event.year;
  //   let month = event.month <= 9 ? '0' + event.month : event.month;;
  //   let day = event.day <= 9 ? '0' + event.day : event.day;;
  //   let finalDate = year + "-" + month + "-" + day;
  //   console.log("Hi", finalDate)
  // } 

  //select radio button
  selectedButton: string = "DP";

  radioSelect(e: string): void {
    this.selectedButton = e;
    if (this.selectedButton == 'Insider') {
      this.selectedDpName = [];
      this.selectedItemsRepCustomer = [];
    } else if (this.selectedButton == 'DP') {
      this.selectedSenderName = [];
      this.selectedCustomerName = [];
    } else if (this.selectedButton == 'Customer') {
      this.selectedSenderName = [];
      this.selectedDpName = [];
    }
  }

  isSelected(name: string): boolean {

    if (!this.selectedButton) {
      return false;
    }

    return (this.selectedButton === name);
  }
  //select radio recipient button
  selectedButtonRep: string = "DP";

  radioSelectRep(e: string): void {
    this.selectedButtonRep = e;
    if (this.selectedButtonRep == 'DP') {
      this.selectedItemsRep = [];
      this.selectedItemsRepCustomer = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'Insider') {
      this.selectedItemsRepDp = [];
      this.selectedItemsRepCustomer = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'Customer') {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'Both') {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepCustomer = [];
    } else {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepBoth = [];
      this.selectedItemsRepCustomer = [];
    }

  }

  isSelectedRep(name: string): boolean {

    if (!this.selectedButtonRep) {
      return false;
    }

    return (this.selectedButtonRep === name);
  }
  //select radio recipient button
  //COnfidential date
  selectedButton2: string = "No";

  radioSelect2(e: string): void {
    this.selectedButton2 = e;
  }

  isSelected2(name: string): boolean {

    if (!this.selectedButton2) {
      return false;
    }

    return (this.selectedButton2 === name);
  }

  // Attachment file code
  //Todate
  selectedButton3: string = "No";

  radioSelect3(e: string): void {
    this.selectedButton3 = e;

    if (this.selectedButton3 !== "Yes") {
      this.upsiCreateForm.get('to_date').setValue("");
    }
  }

  isSelected3(name: string): boolean {

    if (!this.selectedButton3) {
      return false;
    }

    return (this.selectedButton3 === name);
  }




  base64Output: string;

  filePath() {
    if (this.upsiCreateForm.get('confidentiality_agreement_attachment').value != '') {
      this.attachName = this.upsiCreateForm.get('confidentiality_agreement_attachment').value;

      this.path = this.attachName.split("\\").pop();

    }
    this.attachmentPath = true;
  }
  removeFile() {
    this.upsiCreateForm.get('confidentiality_agreement_attachment').setValue("");
    this.path = "";
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;

  }


  changeListener($event): void {
    this.readThis($event.target);
    //this.filePath()
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());
      this.upsiCreateForm.value.image = myReader.result;
      this.pathImage = this.upsiCreateForm.value.image;
    }
  }
  onFileSelected(event) {
    this.filePath()
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;
    });
  }

  convertFile(file: File): Observable<string> {
    this.filePath()
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    // reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }
  getCurrentDate() {
    // let todaysDataTime = formatDate(this.today, 'hh:mm:ss a', 'en-US', '+0530');
    // let addDate = new Date(this.today.getTime() + this.minutesToAdd*60000);
    // console.log("addDate", this.addDate )
    // let datepicker = this.upsiCreateForm.get('date_of_sharing').value
    // if(addDate < datepicker ){
    //     this.reasonField = true;
    // }else{
    //    this.reasonField = true;
    // }
  }
  cancelUpsi() {
    this.router.navigate(['/upsi/upsi-list']);
    this.submitted = false;
    this.upsiCreateForm.reset();
  }
  //attachement file
  onFileChanged(event: any) {
    //   this.isLoadings = true;
    //  console.log("event", fileList?.target.files?.length)

    //   for (var i = 0; i <= fileList?.target.files?.length - 1; i++) {
    //     this.selectedFile = fileList?.target.files[i];
    //     let fileReader: FileReader = new FileReader();
    //     console.log("4587", this.selectedFile[i].name)

    //     if (this.listOfFiles.indexOf(this.selectedFile[i]) === -1) {
    //       this.fileList.push(this.selectedFile[i]);
    //       this.listOfFiles.push(this.selectedFile[i]);
    //       console.log("listOfFiles", this.listOfFiles)
    //     }
    //   }
    //   //this.upsiCreateForm.value.agreement_attachment = this.selectedFile;
    //   this.isLoadings = false;
    if (event?.target.files && event?.target.files[0]) {
      var filesAmount = event?.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        //this.selectedFileName.push(event?.target.files[i].name)
        this.selectedFileName.push(event?.target.files[i].name);
        //this.fileList.push(event?.target.files[i].name);

        reader.onload = (event: any) => {
          this.selectedFile?.push(event.target.result);

        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }


  }
  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.selectedFile?.splice(index, 1);
    // delete file from FileList
    this.selectedFileName?.splice(index, 1);
  }

  onBlur(): void {
    //console.log('Focus Is Lost for this Element');

  }
  //role
  selectEvent(item) {
    if (item == '') {
    }
  }

  onChangeSearch(search: string) {
  }

  onFocused(e) {
    // do something
    // var div = document.getElementsByClassName("autocomplete-container");


    // while (div.length) {

    //   div[0].classList.add("active");

    // }

    // document.getElementById('suggestions').classList.remove('is-hidden');
    // document.getElementById('suggestions').classList.add('is-visible');
    // document.getElementById('suggestions-history').classList.remove('is-visible');
    // document.getElementById('suggestions-history').classList.add('is-hidden');

  }
  mailConfifDunction(valId) {
    // let recipientItem = this.upsiCreateForm.get('recipient').value;
    this.upsiCreateForm.value.upsiId = valId; this.upsiCreateForm.value.upsiId = valId;
    // let upsiID = valId;
    // recipientItem
    // upsiID.map(item =>{
    //   recipientItem.push(({recId:item}))
    //   recipientItem.forEach((item,index) => item.recId = item);
    //   console.log("recipientItem", recipientItem);
    // })

    this.upsiService.mailUpsiTransfer(this.upsiCreateForm.value).subscribe(data => {
      if (this.mailPopupModel == 'LSTEST') {
        if (data.sendermailTemplate != '' && data.recipientmailTemplate != '') {
          const modalRef = this.modalService.open(UpsiMailTemplateComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.viewUpsimailTemplate = data;
          modalRef.result.then(
            (result) => {
              //console.log(result);
            }, (reason) => {
              //console.log(reason);
            });
        }
      }

    });


  }

  onSubmit() {   
    
    let regex = new RegExp('^[a-zA-Z\n0-9 _.,-/()#&]+$');
    let remarks = this.upsiCreateForm.get('remarks').value;
    let pos = this.upsiCreateForm.get('purpose_of_sharing').value;
    let othermos = this.upsiCreateForm.get('others_sharing').value;
    let othernos = this.upsiCreateForm.get('others_nature_upsi').value;

    if (remarks != '') {
      if (!regex.test(remarks)) {
        this.toastr.warning("UPSI Details Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (pos != '') {
      if (!regex.test(pos)) {
        this.toastr.warning("Legitimate Purpose of Sharing Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (othernos != '') {
      if (!regex.test(othernos)) {
        this.toastr.warning("Nature of UPSI  Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (othermos != '') {
      if (!regex.test(othermos)) {
        this.toastr.warning("Mode of Sharing Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (this.upsiCreateForm.get('recipient').value !== '') {

      let recipientVal = this.upsiCreateForm.get('recipient').value;
      let senderVal = this.upsiCreateForm.get('sender').value;
      if (recipientVal.length > 0) {
        for (let i = 0; i < recipientVal.length; i++) {
          if (recipientVal[i].itemName == senderVal) {
            this.toastr.warning("Sender and Recipient Can not be same.");
            return false;
          }
        }
      }

    }
    if(this.projEndDate < this.upsiCreateForm.get('date_of_sharing').value){
      this.toastr.warning("Date of Sharing should not be greater than Project end date.");
      return false;
    }
    //  let formData = new FormData();
    //  formData.append('upload' , this.selectedFile);
    this.upsiCreateForm.value.agreement_attachment = this.selectedFile;
    this.upsiCreateForm.value.attachmentfilename = this.selectedFileName;
    const emptyFileds = Object.keys(this.upsiCreateForm.controls).filter(key => this.upsiCreateForm.controls[key].errors !== null)
    this.upsiCreateForm.value.hrefLink = this.hrefUrl;
    this.upsiCreateForm.value.mailId = this.mailUrl;
    this.upsiCreateForm.value.companyName = this.companyname;
    if (this.upsiCreateForm.get('public_Date').value == 'Yes') {
      if (this.upsiCreateForm.get('to_date').value == '' || this.upsiCreateForm.get('to_date').value == undefined) {
        // this.upsiCreateForm.get('to_date').value.error;
        this.toastr.warning("Please provide your Date when made public!");
        return false;
      }
    }
   
    this.submitted = true;
    if (this.upsiCreateForm.status !== 'INVALID') {
      this.isLoading = true;
      this.button = 'Processing';
      this.upsiService.addUpsiTransfer(this.upsiCreateForm.value).subscribe(data => {

        this.toastr.success("UPSIs Successfully created");
        this.isLoading = false;
        this.button = 'Save';
        let upsiId = data.upsiId;
        this.mailConfifDunction(upsiId);
        this.router.navigate(['/upsi/upsi-list']);
        // if (this.upsiCreateForm.valid) {
        //   this.toastr.success("UPSI details created", "Success!");
        //   this.router.navigate(['/upsi/upsi-list']);
        // } else {
        //   this.toastr.warning("UPSI details not created", "Alert!");
        // }
      })
      return false;
    } else if (emptyFileds.length > 1) {
      this.toastr.warning("Please fill all Mandatory fields.");
      return false;
    } else {
      if (this.upsiCreateForm.get('project_name').value == '') {
        this.toastr.warning("Please fill the Project Name.");
        return false;
      } else if (this.upsiCreateForm.get('sender').value == '') {
        this.toastr.warning("Please fill the Name of Sender.");
        return false;
      }
      else if (this.upsiCreateForm.get('recipient').value == '') {
        this.toastr.warning("Please fill the Name of Recipient.");
        return false;
      }
      else if (this.upsiCreateForm.get('mode_of_sharing').value == '') {
        this.toastr.warning("Please fill the Mode of Sharing.");
        return false;
      }
      else if (this.upsiCreateForm.get('purpose_of_sharing').value == '') {
        this.toastr.warning("Please fill the Legitimate Purpose of Sharing.");
        return false;
      }
      else if (this.upsiCreateForm.get('nature_of_upsi').value == '') {
        this.toastr.warning("Please fill the Nature of UPSI.");
        return false;
      }
      else if (this.upsiCreateForm.get('type_of_sharing').value == '') {
        this.toastr.warning("Please fill the Type of sharing.");
        return false;
      }
      else if (this.upsiCreateForm.get('date_of_sharing').value == '') {
        this.toastr.warning("Please fill the Date of Sharing.");
        return false;
      }
      else if (this.upsiCreateForm.get('from_date').value == '') {
        this.toastr.warning("Please fill the From Date.");
        return false;
      } else if (this.upsiCreateForm.get('checkboxgroup').get('checkbox').value == false) {
        this.toastr.warning("Please Accept the Declaration.");
        return false;
      } else if (this.upsiCreateForm.get('date_of_sharing').value > this.upsiCreateForm.get('to_date').value) {
        this.toastr.warning("Date of Sharing should not be greater than public date.");
        return false;
      }


    }
  }

  //  onSubmitData(){
  //   this.onSubmit();
  //   this.mailConfifDunction();
  //  }
}
