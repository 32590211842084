<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD NEW PRE CLEARANCE</h5>
                    <a routerLink="/pit-compliance/list-pre-clearance"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <div *ngIf="showPreclearance == true && hideColumn">
                        <form [formGroup]="pitClearanceCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                            
                            <div class="row mb-3 row1" >
                                    <div class="col-md-5">
                                        <label for="dpName"> Name of designated person</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <!-- <div class="form-group">                    
                                            <input type="text" class="form-control" id="dpName" placeholder="Enter the Name of designated person" autocomplete="off" formControlName="dpName"> 
                                        </div> -->
                                        <ng-select [items]="dpNameFilter"
                                                bindLabel="" #dpname
                                                placeholder="Select Name of designated person"
                                                [(ngModel)]="selectedDpName" formControlName="dpName"
                                                (change)="changeFn($event)"                                         
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dpName.touched) && pitClearanceCreateForm.controls.dpName.errors }" required>
                                            </ng-select>
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dpName.touched) && pitClearanceCreateForm.controls.dpName.errors?.required"
                                            class="text text-danger">
                                            Name of designated person is required.
                                            </div>
                                        </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">b) PAN </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dppan"  autocomplete="off" required placeholder="PAN" formControlName="dppan" [(ngModel)]="panItem" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dppan.touched) && pitClearanceCreateForm.controls.dppan.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dppan.touched) && pitClearanceCreateForm.controls.dppan.errors?.required"
                                            class="text text-danger">
                                            PAN Number is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">c) Designation  </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dpdesignation"  autocomplete="off" placeholder="Designation " required formControlName="dpdesignation" [(ngModel)]="dpDesignation" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dpdesignation.touched) && pitClearanceCreateForm.controls.dpdesignation.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dpdesignation.touched) && pitClearanceCreateForm.controls.dpdesignation.errors?.required"
                                            class="text text-danger">
                                            Designation is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="row mb-3 ">
                                        <div class="col-md-5">
                                            <label for="categoryPerson">d) Category of Person: </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <select class="form-select" class="form-control form-select" id="categoryofperson" formControlName="categoryofperson" [(ngModel)]="categoryperson" disabled (click)="otherCategoryOfPersonFormC()" required [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm?.controls.categoryofperson.touched) && pitClearanceCreateForm.controls.categoryofperson.errors }">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Directors">Directors</option>                                                                               
                                                    <option value="Employees designed based on their role">Employees designed based on their role</option>                                    
                                                    <option value="Employees having UPSI">Employees having UPSI</option>                                    
                                                    <option value="Employees of material subsidiaries having UPSI">Employees of material subsidiaries having UPSI</option>                                    
                                                    <option value="Promoter">Promoter</option>
                                                    <option value="Chief Executive Officer">Chief Executive Officer</option>                                    
                                                    <option value="2 levels below CEO">2 levels below CEO</option>
                                                    <option value="2 levels below CEO of material subsidiary company">2 levels below CEO of material subsidiary company</option>
                                                    <option value="Head of the organization for intermediary or fiduciary">Head of the organization for intermediary or fiduciary</option>
                                                    <option value="Support staff">Support staff</option>
                                                    <option value="Others">Others</option>
                                                    <!-- <option value="Promoter">Promoter</option>
                                                    <option value="Member of the promoter group">Member of the promoter group</option>                                    
                                                    <option value="Designated person">Designated person</option>                                    
                                                    <option value="Directors">Directors</option>                                    
                                                    <option value="Immediate relative of">Immediate relative of</option>                                    
                                                    <option value="Others">Others</option>                                     -->
                                                </select>
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.categoryofperson.touched) && pitClearanceCreateForm.controls.categoryofperson.errors?.required"
                                                class="text text-danger">Category of Person is required. </div>
                                            </div>
                                                                                  
                                        </div>
                                    </div>
                               
                                        <div class="row mb-3 " *ngIf="otherscategoryPersonFormC">
                                            <div class="col-md-5 ">
                                                <label for="typeOfSecuritiesValue">Others Category of Person: </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Category of Person" [(ngModel)]="othercategoryperson" disabled autocomplete="off" formControlName="otherscategoryofperson"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                     
                                
                                <!-- <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">d) Department</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="department"  autocomplete="off" placeholder="Department" formControlName="department"> 
                                        
                                        </div>
                                    </div>
                                </div> -->
                                

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">e) Format of Application for pre-clearance</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline1" value="Self" (click)="radioSelect('Self')" formControlName="application_for" >
                                                <label for="radioinline1" class="mb-0">Self</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline2" value="Relative" (click)="radioSelect('Relative')" formControlName="application_for" >
                                                <label for="radioinline2" class="mb-0">Relative</label>
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected('Self')">
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelected('Relative')">
                                        <h6>Name of Relative & Relationship (if transaction on behalf of immediate relatives)</h6>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">A) Name of Relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <ng-select [items]="immediateRelativeList"
                                                bindLabel="" 
                                                placeholder="Select Name of Relative"
                                                [(ngModel)]="selectedimmediateRelativeList" formControlName="relativename"
                                                (change)="changeFnImmediate($event)" >
                                            </ng-select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5"> 
                                                <label for="dpName">B) Relationship </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="dpRelationship"  autocomplete="off" [(ngModel)]="immediateRelative" disabled placeholder="Relationship" formControlName="dpRelationship"> 
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">C) PAN of relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="relativepan"  autocomplete="off" [(ngModel)]="immediatePan" disabled placeholder="PAN of relative" formControlName="relativepan"> 
                                                
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="proposedTrans">
                                    <h4 style="text-decoration: underline;">Proposed Transaction in Securities of the Company</h4>
                                    <div class="row mb-3" *ngIf="selectcustomertype">
                                        <div class="col-md-5">
                                            <label for="dpName"> Trading company Name</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5" *ngIf="pretcndropdown">   
                                            <div class="form-group">                                         
                                                <ng-select [items]="allowedCompanyList"
                                                        bindLabel="" #companyname
                                                        placeholder="Select Trading company Name" (change)="changeTradingCompany($event)"
                                                        [(ngModel)]="selectedtradingcompanyname" formControlName="tradingcompanyname"
                                                        >
                                                </ng-select>  
                                            </div>                                              
                                        </div>
                                        <div class="col-md-7 col-lg-5" *ngIf="!pretcndropdown">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="tradingcompanyname" (ngModelChange)="changeTradingCompany($event)" autocomplete="off" placeholder="Select Trading company Name" formControlName="tradingcompanyname"> 
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">f)DP ID / Client ID No </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="tel"  class="form-control" id="dp_client_id"  autocomplete="off" placeholder="DP ID - Client ID" [(ngModel)]="dp_client_id" (input)="dpclientid($event)" formControlName="dp_client_id" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dp_client_id.touched) && pitClearanceCreateForm.controls.dp_client_id.errors }" required> 
                                                <p class="text-danger">*Ex: 12345678-87654321</p>
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.dp_client_id.touched) && pitClearanceCreateForm.controls.dp_client_id.errors?.required"
                                                class="text text-danger">
                                                DP ID / Client ID No is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.dp_client_id.errors?.maxlength">Please, Enter DP Id - Client Id 16 Digits Number.</div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.dp_client_id.errors?.minlength">Please, Enter DP Id - Client Id 16 Digits Number.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">g) Name of Depository </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <!-- <input type="text"  class="form-control" id="nameofdepository"  autocomplete="off" placeholder="Name of Depository" formControlName="nameofdepository" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors }" required>  -->
                                                <select class="form-select" class="form-control form-select" formControlName="nameofdepository" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="National Securities Depositories Ltd (NSDL)">National Securities Depositories Ltd (NSDL)</option>
                                                    <option value="Central Securities Depositories Ltd (CDSL)">Central Securities Depositories Ltd (CDSL)</option>                                                                
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors?.required"
                                                class="text text-danger">
                                                Name of Depository is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">h) Type of Security </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <!-- <input type="text"  class="form-control" id="typeofsecurity"  autocomplete="off" placeholder="Type of Security" formControlName="typeofsecurity" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors }" required>  -->
                                                <select class="form-select" class="form-control form-select" formControlName="typeofsecurity" (change)="othertypeofsecurity()"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Equity Share">Equity Share</option>
                                                    <option value="Preference Share">Preference Share</option>
                                                    <option value="Warrants">Warrants</option>                                                                    
                                                    <option value="Convertible Debentures">Convertible Debentures</option>                                                                    
                                                    <option value="Non-Convertible Debenture">Non-Convertible Debenture</option>                                                                    
                                                    <option value="Rights entitlements">Rights entitlements</option>                                                                    
                                                    <option value="Others">Others</option>                                                                    
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors?.required"
                                                class="text text-danger">
                                                Type of Security is required.
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="otherstypeofsecuritys">                                        
                                            <div class="col-md-5">
                                                <label for="otherstypeofsecuritys">Others Type of Security </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstypeofsecurity" placeholder="Enter the Others Type of Security" autocomplete="off" formControlName="otherstypeofsecurity"> 
                                                </div>                                            
                                            </div>                                        
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">i) Number of securities held as on date </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text" #noofsecurity class="form-control" id="noofsecurities"  autocomplete="off" placeholder="Number of securities held as on date" formControlName="noofsecurities" [(ngModel)]="sharetotalcount"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.noofsecurities.touched) && pitClearanceCreateForm.controls.noofsecurities.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.noofsecurities.touched) && pitClearanceCreateForm.controls.noofsecurities.errors?.required"
                                                class="text text-danger">
                                                Number of securities is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.noofsecurities.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                            <p> Balance Shares : {{sharetotalcount}}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">i) Proposed date of dealing in securities </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="date"  class="form-control" id="proposeddate"  autocomplete="off" placeholder="DD-MM-YYYY" formControlName="proposeddate" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.proposeddate.touched) && pitClearanceCreateForm.controls.proposeddate.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.proposeddate.touched) && pitClearanceCreateForm.controls.proposeddate.errors?.required"
                                                class="text text-danger">
                                                Proposed date of dealing in securities is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">j) Nature of proposed transaction transmission  </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" formControlName="nature_of_proposed" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nature_of_proposed.touched) && pitClearanceCreateForm.controls.nature_of_proposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option [disabled]="hideSellSecurity" value="Sell (disposal of shares)">Sell (disposal of shares)</option>
                                                    <option [disabled]="hideBuySecurity" value="Buy (Acquisition of shares)">Buy (Acquisition of shares)</option>
                                                    <option value="Subscription (Allotment)">Subscription (Allotment)</option>                                                                    
                                                </select>  
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.nature_of_proposed.touched) && pitClearanceCreateForm.controls.nature_of_proposed.errors?.required"
                                            class="text text-danger">
                                            Nature of proposed transaction transmission  is required.
                                            </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">k)Estimated number of securities proposed to be acquired/ subscribed/ sold	 </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="Estimatednumbersecur"  autocomplete="off" placeholder="Estimated number of securities" formControlName="Estimatednumbersecur"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.Estimatednumbersecur.touched) && pitClearanceCreateForm.controls.Estimatednumbersecur.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.Estimatednumbersecur.touched) && pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.required"
                                                class="text text-danger">
                                                Estimated number of securities proposed is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.pattern">Please, Enter Numbers only.</div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.invalid">
                                                    Check Your Rules Conditions
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" >
                                        <div class="col-md-5">
                                            <label for="dpName">l)Current market price (as on date of application)</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="currentmarketprice"  autocomplete="off" placeholder="Current market price" formControlName="currentmarketprice" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.currentmarketprice.touched) && pitClearanceCreateForm.controls.currentmarketprice.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.currentmarketprice.touched) && pitClearanceCreateForm.controls.currentmarketprice.errors?.required"
                                                class="text text-danger">
                                                Current market price is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.currentmarketprice.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" >
                                        <div class="col-md-5">
                                            <label for="dpName">m) Price at which transaction is proposed</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="quantity_traded"  autocomplete="off" placeholder="Price at which transaction is proposed" formControlName="transactionisproposed"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.transactionisproposed.touched) && pitClearanceCreateForm.controls.transactionisproposed.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.transactionisproposed.touched) && pitClearanceCreateForm.controls.transactionisproposed.errors?.required"
                                                class="text text-danger">
                                                Price at which transaction is proposed is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.transactionisproposed.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">n) Whether the proposed transaction will be through </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" (change)="offMarket()" formControlName="whethertransactionisproposed" [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.whethertransactionisproposed.touched) && pitClearanceCreateForm.controls.whethertransactionisproposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="stock exchange">stock exchange</option>
                                                    <option value="off-market deal">off-market deal</option>                                                                                                               
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.whethertransactionisproposed.touched) && pitClearanceCreateForm.controls.whethertransactionisproposed.errors?.required"
                                            class="text text-danger">
                                            Whether the proposed transaction is required.
                                            </div>   
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="stockexchange">
                                        <div class="col-md-5">
                                            <label for="dpName">Name of the Stock Exchange</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <ng-select [items]="exchangeTradeFilter"
                                                bindLabel=""
                                                placeholder="Select Stock Exchange"
                                                [(ngModel)]="selectedStockExchange" formControlName="proposedbuyer">
                                                 </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="offMarketDeal">
                                        <div class="col-md-5">
                                            <label for="dpName">Name of the Proposed Buyer/ Seller (for off-market trade)</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="proposedbuyer"  autocomplete="off" placeholder="Name of the Proposed Buyer/ Seller" formControlName="proposedbuyer" > 
                                                <!-- <div *ngIf="(submitted || pitClearanceCreateForm.controls.proposedbuyer.touched) && pitClearanceCreateForm.controls.proposedbuyer.errors?.required"
                                                class="text text-danger">
                                                Name of the Proposed Buyer/ Seller is required.
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="dprelative-details">
                                    <h4 style="text-decoration: underline;"> Fixed Shares</h4>  
                                    <div class="row mb-3" *ngIf="equitysharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Equity Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="equityshares"  autocomplete="off" placeholder="Enter Current Equity Share" formControlName="equityshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="preferencesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Preference Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="preferenceshares"  autocomplete="off" placeholder="Enter Current Preference Share" formControlName="preferenceshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="warrantssharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Warrants Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="warrantsshares"  autocomplete="off" placeholder="Enter Current Warrants Share" formControlName="warrantsshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="convertiblesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Convertible Debentures Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="convertibleshares"  autocomplete="off" placeholder="Enter Current Convertible Debentures Share" formControlName="convertibleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="nonconvertiblesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Non-Convertible Debentures Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="nonconvertibleshares"  autocomplete="off" placeholder="Enter Current Non-Convertible Debentures Share" formControlName="nonconvertibleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="rightentitlesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Rights entitlements Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="rightentitleshares"  autocomplete="off" placeholder="Enter Current Rights entitlements Share" formControlName="rightentitleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="otherssharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Others Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="othersshares"  autocomplete="off" placeholder="Enter Current Others Share" formControlName="othersshares" >                                                
                                            </div>
                                        </div>
                                    </div>


                                    
                                </div> -->
                                <div class="previous-preclearance">
                                    <h4 style="text-decoration: underline;">o) Details of previous transactions and Pre-clearance</h4>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>a) Previous Pre-clearance ID</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="previouspcid" placeholder="Previous Pre-clearance ID" autocomplete="off" type="text" formControlName="previouspcid" [(ngModel)]="PreviousPreclearanceid"> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>b) Number of Shares for which Pre-clearance approval was granted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="number_of_shares" placeholder="Number of Shares" type="text" autocomplete="off" formControlName="number_of_shares" [(ngModel)]="prenoOfShares">                                                                       
                                                
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.number_of_shares.errors?.pattern">Please, Enter Numbers only.</div>               
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>c) Date when the Pre-clearance approval was granted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="Date_when_approval" placeholder="DD-MM-YYYY" type="date" autocomplete="off" formControlName="Date_when_approval" [(ngModel)]="dateWhenApproval">                                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>n) Whether the transaction was executed or not? </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <select #excute class="form-select" id="transaction_executed" class="form-control form-select"  formControlName="transaction_executed" [(ngModel)]="transactionExecuted">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                                                                                    
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                                <div class="previousTransaction">
                                    <div class="align-btn">
                                        <h4 style="text-decoration: underline;">Details of last transaction:</h4>
                                        <!-- <button type="button" class="btn btn-primary me-1" *ngIf="addpreviousbtn" (click)="addPreviousPreClear()"[disabled]="isLoading2"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading2, 'fa-check': !isLoading2}"></i> {{button2}}n</button> -->
                                    </div>
                                    
                                    <!-- <div class="row mb-3" *ngIf="yesTransactionField">
                                        <div class="col-md-5">
                                            <label>If Yes, Details of the same</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="If Yes, Details of the same" rows="4" [(ngModel)]="detailsOfYes" formControlName="details_of_yes" > </textarea>                                                                      
                                                                
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row mb-3" *ngIf="noTransactionField">
                                        <div class="col-md-5">
                                            <label>If No, reasons for not transacting the same</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="If No, reasons for not transacting the same" rows="4" [(ngModel)]="detailsOfNo"  formControlName="details_of_no" > </textarea>                                                                      
                                                                
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>p) Nature of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <select class="form-select" id="nature_of_transaction" class="form-control form-select" [(ngModel)]="natureTransaction"  formControlName="nature_of_transaction" >
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Sell (disposal of shares)">Sell (disposal of shares)</option>
                                                    <option value="Buy (Acquisition of shares)">Buy (Acquisition of shares)</option>                                    
                                                    <option value="Subscription (Allotment)">Subscription (Allotment)</option> 
                                                    <!-- <option value="Sale">Sale</option>
                                                    <option value="Purchase">Purchase</option>
                                                    <option value="Subscription">Subscription</option> -->
                                                </select> 
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.nature_of_transaction.touched) && pitClearanceCreateForm.controls.nature_of_transaction.errors?.required" class="text text-danger">
                                                        Nature of transaction is required.
                                                    </div>   
                                                </div>                                                            
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>q) Date of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="date_of_transaction" placeholder="dd/mm/yy"  autocomplete="off" type="date" [(ngModel)]="dateransaction" formControlName="date_of_transaction" >                                                                       
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.date_of_transaction.touched) && pitClearanceCreateForm.controls.date_of_transaction.errors?.required" class="text text-danger">
                                                        Date of transaction is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>r) No. of securities transacted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="securities_transacted" placeholder="No. of securities transacted" autocomplete="off" [(ngModel)]="noOfSecurities" type="text" formControlName="securities_transacted">                                                                       
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.securities_transacted.touched) && pitClearanceCreateForm.controls.securities_transacted.errors?.required" class="text text-danger">
                                                        No. of securities transacted is required.
                                                    </div> 
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.securities_transacted.errors?.pattern">Please, Enter Numbers only.</div>              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>s) Remarks</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" rows="3" autocomplete="off" placeholder="Remarks" formControlName="last_transaction"> </textarea>
                                                                                                                         
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                                        
                        
                            
                                <div class="checkbox" formGroupName="checkboxgroup">
                                    <h4 style="text-decoration: underline;">In connection with my request for this pre-clearance of the trade, I hereby declare & undertake that:</h4>
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox" class="checkbox" value="value-1" formControlName="checkbox" required>
                                        <label for="checkbox1">I Declare that I am not in possession of or knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Company's Code of Conduct  upto the time of issuing this undertaking </label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox2" type="checkbox" class="checkbox" formControlName="checkbox1" required>
                                        <label for="checkbox2">In the event that I am in possession/ knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Code of Conduct, after the signing of this undertaking but before executing the transaction for which approval is sought, I shall inform the Compliance Officer of the same and shall completely refrain from dealing in the securities of the Company until such information becomes public;</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox1').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox3" type="checkbox" class="checkbox" formControlName="checkbox2" required>
                                        <label for="checkbox3">I have not contravened the Company’s Code of Conduct for Prohibition of Trading by Insiders and Code for Fair Disclosure of Unpublished Price Sensitive Information.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox2').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox4" type="checkbox" class="checkbox" formControlName="checkbox3" required>
                                        <label for="checkbox4">I have made full and true disclosure in the matter.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox3').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox5" type="checkbox" class="checkbox" formControlName="checkbox4" required>
                                        <label for="checkbox5">In case the traded value exceeds Rs. 10 lakh or any such other value as may be specified, disclosures required under the Code of Conduct for Prohibition of Trading by Insiders will be immediately furnished by me.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox4').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox6" type="checkbox" class="checkbox" formControlName="checkbox5" required>
                                        <label for="checkbox6">I hereby agree to indemnify and keep the Company and its Board indemnified from and against all and any penalties/fines that may be imposed on them by the SEBI and/or any other Statutory Authorities as a result of violation by me of the Regulation of the Codes prescribed by the Bank.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox5').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox7" type="checkbox" class="checkbox" formControlName="checkbox6" required>
                                        <label for="checkbox7">I hereby declare that I shall execute my order in respect of the securities for which preclearance is sought within {{preDayCount}} trading days after the approval is given. If the order is not executed within {{preDayCount}} trading days after the approval, I undertake to obtain fresh pre-clearance.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox6').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox8" type="checkbox" class="checkbox" formControlName="checkbox7" required>
                                        <label for="checkbox8">I have not done any prior contra trade within the period of six months and shall not execute a contra trade for a period of 6 months from the date of current transaction.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox7').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox9" type="checkbox" class="checkbox" formControlName="checkbox8" required>
                                        <label for="checkbox9">I have made full and true disclosure in the above matter.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox8').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                </div>

                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="cancelPreClearanceTemplate()" >Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="showPreclearance == true && hideColumn == false">
                        <form [formGroup]="pitClearanceCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                            
                            <div class="row mb-3 row1" >
                                    <div class="col-md-5">
                                        <label for="dpName"> Name of designated person</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <!-- <div class="form-group">                    
                                            <input type="text" class="form-control" id="dpName" placeholder="Enter the Name of designated person" autocomplete="off" formControlName="dpName"> 
                                        </div> -->
                                        <ng-select [items]="dpNameFilter"
                                                bindLabel="" #dpname
                                                placeholder="Select Name of designated person"
                                                [(ngModel)]="selectedDpName" formControlName="dpName"
                                                (change)="changeFn($event)"                                         
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dpName.touched) && pitClearanceCreateForm.controls.dpName.errors }" required>
                                            </ng-select>
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dpName.touched) && pitClearanceCreateForm.controls.dpName.errors?.required"
                                            class="text text-danger">
                                            Name of designated person is required.
                                            </div>
                                        </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">b) PAN </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dppan"  autocomplete="off" required placeholder="PAN" formControlName="dppan" [(ngModel)]="panItem" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dppan.touched) && pitClearanceCreateForm.controls.dppan.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dppan.touched) && pitClearanceCreateForm.controls.dppan.errors?.required"
                                            class="text text-danger">
                                            PAN Number is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">c) Designation  </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dpdesignation"  autocomplete="off" placeholder="Designation " required formControlName="dpdesignation" [(ngModel)]="dpDesignation" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dpdesignation.touched) && pitClearanceCreateForm.controls.dpdesignation.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceCreateForm.controls.dpdesignation.touched) && pitClearanceCreateForm.controls.dpdesignation.errors?.required"
                                            class="text text-danger">
                                            Designation is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="row mb-3 ">
                                        <div class="col-md-5">
                                            <label for="categoryPerson">d) Category of Person: </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <select class="form-select" class="form-control form-select" id="categoryofperson" formControlName="categoryofperson" [(ngModel)]="categoryperson" disabled (click)="otherCategoryOfPersonFormC()" required [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm?.controls.categoryofperson.touched) && pitClearanceCreateForm.controls.categoryofperson.errors }">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Directors">Directors</option>                                                                               
                                                    <option value="Employees designed based on their role">Employees designed based on their role</option>                                    
                                                    <option value="Employees having UPSI">Employees having UPSI</option>                                    
                                                    <option value="Employees of material subsidiaries having UPSI">Employees of material subsidiaries having UPSI</option>                                    
                                                    <option value="Promoter">Promoter</option>
                                                    <option value="Chief Executive Officer">Chief Executive Officer</option>                                    
                                                    <option value="2 levels below CEO">2 levels below CEO</option>
                                                    <option value="2 levels below CEO of material subsidiary company">2 levels below CEO of material subsidiary company</option>
                                                    <option value="Head of the organization for intermediary or fiduciary">Head of the organization for intermediary or fiduciary</option>
                                                    <option value="Support staff">Support staff</option>
                                                    <option value="Others">Others</option>                                    
                                                </select>
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.categoryofperson.touched) && pitClearanceCreateForm.controls.categoryofperson.errors?.required"
                                                class="text text-danger">Category of Person is required. </div>
                                            </div>
                                                                                  
                                        </div>
                                    </div>
                               
                                        <div class="row mb-3 " *ngIf="otherscategoryPersonFormC">
                                            <div class="col-md-5 ">
                                                <label for="typeOfSecuritiesValue">Others Category of Person: </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstransactiontype" placeholder="Enter the Others Category of Person" [(ngModel)]="othercategoryperson" disabled autocomplete="off" formControlName="otherscategoryofperson"> 
                                                </div>                                            
                                            </div>
                                        </div>
                                     
                                
                                <!-- <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">d) Department</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="department"  autocomplete="off" placeholder="Department" formControlName="department"> 
                                        
                                        </div>
                                    </div>
                                </div> -->
                                

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">e) Format of Application for pre-clearance</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline1" value="Self" (click)="radioSelect('Self')" formControlName="application_for" >
                                                <label for="radioinline1" class="mb-0">Self</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline2" value="Relative" (click)="radioSelect('Relative')" formControlName="application_for" >
                                                <label for="radioinline2" class="mb-0">Relative</label>
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected('Self')">
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelected('Relative')">
                                        <h6>Name of Relative & Relationship (if transaction on behalf of immediate relatives)</h6>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">A) Name of Relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <ng-select [items]="immediateRelativeList"
                                                bindLabel="" 
                                                placeholder="Select Name of Relative"
                                                [(ngModel)]="selectedimmediateRelativeList" formControlName="relativename"
                                                (change)="changeFnImmediate($event)" >
                                            </ng-select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5"> 
                                                <label for="dpName">B) Relationship </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="dpRelationship"  autocomplete="off" [(ngModel)]="immediateRelative" disabled placeholder="Relationship" formControlName="dpRelationship"> 
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">C) PAN of relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="relativepan"  autocomplete="off" [(ngModel)]="immediatePan" disabled placeholder="PAN of relative" formControlName="relativepan"> 
                                                
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="proposedTrans">
                                    <h4 style="text-decoration: underline;">Proposed Transaction in Securities of the Company</h4>
                                    <div class="row mb-3" *ngIf="selectcustomertype">
                                        <div class="col-md-5">
                                            <label for="dpName"> Trading company Name</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5" *ngIf="pretcndropdown">   
                                            <div class="form-group">                                         
                                                <ng-select [items]="allowedCompanyList"
                                                        bindLabel="" #companyname
                                                        placeholder="Select Trading company Name" (change)="changeTradingCompany($event)"
                                                        [(ngModel)]="selectedtradingcompanyname" formControlName="tradingcompanyname"
                                                        >
                                                </ng-select>  
                                            </div>                                              
                                        </div>
                                        <div class="col-md-7 col-lg-5" *ngIf="!pretcndropdown">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="tradingcompanyname"  (ngModelChange)="changeTradingCompany($event)" autocomplete="off" placeholder="Select Trading company Name" formControlName="tradingcompanyname"> 
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">f)DP ID / Client ID No </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="tel"  class="form-control" id="dp_client_id"  autocomplete="off" placeholder="DP ID - Client ID" [(ngModel)]="dp_client_id" (input)="dpclientid($event)" formControlName="dp_client_id" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.dp_client_id.touched) && pitClearanceCreateForm.controls.dp_client_id.errors }" required> 
                                                <p class="text-danger">*Ex: 12345678-87654321</p>
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.dp_client_id.touched) && pitClearanceCreateForm.controls.dp_client_id.errors?.required"
                                                class="text text-danger">
                                                DP ID / Client ID No is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.dp_client_id.errors?.maxlength">Please, Enter DP Id - Client Id 16 Digits Number.</div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.dp_client_id.errors?.minlength">Please, Enter DP Id - Client Id 16 Digits Number.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">g) Name of Depository </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <!-- <input type="text"  class="form-control" id="nameofdepository"  autocomplete="off" placeholder="Name of Depository" formControlName="nameofdepository" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors }" required>  -->
                                                <select class="form-select" class="form-control form-select" formControlName="nameofdepository" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="National Securities Depositories Ltd (NSDL)">National Securities Depositories Ltd (NSDL)</option>
                                                    <option value="Central Securities Depositories Ltd (CDSL)">Central Securities Depositories Ltd (CDSL)</option>                                                                
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.nameofdepository.touched) && pitClearanceCreateForm.controls.nameofdepository.errors?.required"
                                                class="text text-danger">
                                                Name of Depository is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">h) Type of Security </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <!-- <input type="text"  class="form-control" id="typeofsecurity"  autocomplete="off" placeholder="Type of Security" formControlName="typeofsecurity" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors }" required>  -->
                                                <select class="form-select" class="form-control form-select" formControlName="typeofsecurity" (change)="othertypeofsecurity()"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Equity Share">Equity Share</option>
                                                    <option value="Preference Share">Preference Share</option>
                                                    <option value="Warrants">Warrants</option>                                                                    
                                                    <option value="Convertible Debentures">Convertible Debentures</option>                                                                    
                                                    <option value="Non-Convertible Debenture">Non-Convertible Debenture</option>                                                                    
                                                    <option value="Rights entitlements">Rights entitlements</option>                                                                    
                                                    <option value="Others">Others</option>                                                                    
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.typeofsecurity.touched) && pitClearanceCreateForm.controls.typeofsecurity.errors?.required"
                                                class="text text-danger">
                                                Type of Security is required.
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="otherstypeofsecuritys">                                        
                                            <div class="col-md-5">
                                                <label for="otherstypeofsecuritys">Others Type of Security </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text" class="form-control" id="otherstypeofsecurity" placeholder="Enter the Others Type of Security" autocomplete="off" formControlName="otherstypeofsecurity"> 
                                                </div>                                            
                                            </div>                                        
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">i) Number of securities held as on date </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text" #noofsecurity class="form-control" id="noofsecurities"   autocomplete="off" placeholder="Number of securities held as on date" formControlName="noofsecurities" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.noofsecurities.touched) && pitClearanceCreateForm.controls.noofsecurities.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.noofsecurities.touched) && pitClearanceCreateForm.controls.noofsecurities.errors?.required"
                                                class="text text-danger">
                                                Number of securities is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.noofsecurities.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                            <p> Balance Shares : {{sharetotalcount}}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">i) Proposed date of dealing in securities </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="date"  class="form-control" id="proposeddate"  autocomplete="off" placeholder="DD-MM-YYYY" formControlName="proposeddate" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.proposeddate.touched) && pitClearanceCreateForm.controls.proposeddate.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.proposeddate.touched) && pitClearanceCreateForm.controls.proposeddate.errors?.required"
                                                class="text text-danger">
                                                Proposed date of dealing in securities is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">j) Nature of proposed transaction transmission  </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" formControlName="nature_of_proposed" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.nature_of_proposed.touched) && pitClearanceCreateForm.controls.nature_of_proposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option [disabled]="hideSellSecurity" value="Sell (disposal of shares)">Sell (disposal of shares)</option>
                                                    <option [disabled]="hideBuySecurity" value="Buy (Acquisition of shares)">Buy (Acquisition of shares)</option>
                                                    <option value="Subscription (Allotment)">Subscription (Allotment)</option>                                                                    
                                                </select>  
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.nature_of_proposed.touched) && pitClearanceCreateForm.controls.nature_of_proposed.errors?.required"
                                            class="text text-danger">
                                            Nature of proposed transaction transmission  is required.
                                            </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">k)Estimated number of securities proposed to be acquired/ subscribed/ sold	 </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="Estimatednumbersecur"  autocomplete="off" placeholder="Estimated number of securities" formControlName="Estimatednumbersecur"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.Estimatednumbersecur.touched) && pitClearanceCreateForm.controls.Estimatednumbersecur.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.Estimatednumbersecur.touched) && pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.required"
                                                class="text text-danger">
                                                Estimated number of securities proposed is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.pattern">Please, Enter Numbers only.</div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.Estimatednumbersecur.errors?.invalid">
                                                    Check Your Rules Conditions
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">l) Whether the proposed transaction will be through </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" (change)="offMarket()" formControlName="whethertransactionisproposed" [ngClass]="{ 'is-invalid': (submitted || pitClearanceCreateForm.controls.whethertransactionisproposed.touched) && pitClearanceCreateForm.controls.whethertransactionisproposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="stock exchange">stock exchange</option>
                                                    <option value="off-market deal">off-market deal</option>                                                                                                               
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceCreateForm.controls.whethertransactionisproposed.touched) && pitClearanceCreateForm.controls.whethertransactionisproposed.errors?.required"
                                            class="text text-danger">
                                            Whether the proposed transaction is required.
                                            </div>   
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="stockexchange">
                                        <div class="col-md-5">
                                            <label for="dpName">Name of the Stock Exchange</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <ng-select [items]="exchangeTradeFilter"
                                                bindLabel=""
                                                placeholder="Select Stock Exchange"
                                                [(ngModel)]="selectedStockExchange" formControlName="proposedbuyer">
                                                 </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="offMarketDeal">
                                        <div class="col-md-5">
                                            <label for="dpName">Name of the Proposed Buyer/ Seller (for off-market trade)</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="proposedbuyer"  autocomplete="off" placeholder="Name of the Proposed Buyer/ Seller" formControlName="proposedbuyer" > 
                                                <!-- <div *ngIf="(submitted || pitClearanceCreateForm.controls.proposedbuyer.touched) && pitClearanceCreateForm.controls.proposedbuyer.errors?.required"
                                                class="text text-danger">
                                                Name of the Proposed Buyer/ Seller is required.
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dprelative-details">
                                    <h4 style="text-decoration: underline;"> Fixed Shares</h4>  
                                    <div class="row mb-3" *ngIf="equitysharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Equity Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="equityshares"  autocomplete="off" placeholder="Enter Current Equity Share" formControlName="equityshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="preferencesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Preference Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="preferenceshares"  autocomplete="off" placeholder="Enter Current Preference Share" formControlName="preferenceshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="warrantssharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Warrants Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="warrantsshares"  autocomplete="off" placeholder="Enter Current Warrants Share" formControlName="warrantsshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="convertiblesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Convertible Debentures Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="convertibleshares"  autocomplete="off" placeholder="Enter Current Convertible Debentures Share" formControlName="convertibleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="nonconvertiblesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Non-Convertible Debentures Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="nonconvertibleshares"  autocomplete="off" placeholder="Enter Current Non-Convertible Debentures Share" formControlName="nonconvertibleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="rightentitlesharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Rights entitlements Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="rightentitleshares"  autocomplete="off" placeholder="Enter Current Rights entitlements Share" formControlName="rightentitleshares" >                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3" *ngIf="otherssharesfield">
                                        <div class="col-md-5">
                                            <label for="dpName">Others Share</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="othersshares"  autocomplete="off" placeholder="Enter Current Others Share" formControlName="othersshares" >                                                
                                            </div>
                                        </div>
                                    </div>


                                    
                                </div>
                                
                                <div class="previous-preclearance">
                                    <h4 style="text-decoration: underline;">m) Details of previous transactions and Pre-clearance</h4>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>a) Previous Pre-clearance ID</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="previouspcid" placeholder="Previous Pre-clearance ID" autocomplete="off" type="text" formControlName="previouspcid" [(ngModel)]="PreviousPreclearanceid"> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>b) Number of Shares for which Pre-clearance approval was granted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="number_of_shares" placeholder="Number of Shares" type="text" autocomplete="off" formControlName="number_of_shares" [(ngModel)]="prenoOfShares">                                                                       
                                                
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.number_of_shares.errors?.pattern">Please, Enter Numbers only.</div>               
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>c) Date when the Pre-clearance approval was granted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="Date_when_approval" placeholder="DD-MM-YYYY" type="date" autocomplete="off" formControlName="Date_when_approval" [(ngModel)]="dateWhenApproval">                                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>n) Whether the transaction was executed or not? </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <select #excute class="form-select" id="transaction_executed" class="form-control form-select"  formControlName="transaction_executed" [(ngModel)]="transactionExecuted">
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                                                                                                    
                                            </div>
                                        </div>
                                    </div>

                                </div>
                              
                                <div class="previousTransaction">
                                    <div class="align-btn">
                                        <h4 style="text-decoration: underline;">Details of last transaction:</h4>
                                        <!-- <button type="button" class="btn btn-primary me-1" *ngIf="addpreviousbtn" (click)="addPreviousPreClear()"[disabled]="isLoading2"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading2, 'fa-check': !isLoading2}"></i> {{button2}}n</button> -->
                                    </div>
                                    
                                    <!-- <div class="row mb-3" *ngIf="yesTransactionField">
                                        <div class="col-md-5">
                                            <label>If Yes, Details of the same</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="If Yes, Details of the same" rows="4" [(ngModel)]="detailsOfYes" formControlName="details_of_yes" > </textarea>                                                                      
                                                                
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row mb-3" *ngIf="noTransactionField">
                                        <div class="col-md-5">
                                            <label>If No, reasons for not transacting the same</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="If No, reasons for not transacting the same" rows="4" [(ngModel)]="detailsOfNo"  formControlName="details_of_no" > </textarea>                                                                      
                                                                
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>o) Nature of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <select class="form-select" id="nature_of_transaction" class="form-control form-select" [(ngModel)]="natureTransaction"  formControlName="nature_of_transaction" >
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Sell (disposal of shares)">Sell (disposal of shares)</option>
                                                    <option value="Buy (Acquisition of shares)">Buy (Acquisition of shares)</option>                                    
                                                    <option value="Subscription (Allotment)">Subscription (Allotment)</option> 
                                                    <!-- <option value="Sale">Sale</option>
                                                    <option value="Purchase">Purchase</option>
                                                    <option value="Subscription">Subscription</option> -->
                                                </select> 
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.nature_of_transaction.touched) && pitClearanceCreateForm.controls.nature_of_transaction.errors?.required" class="text text-danger">
                                                        Nature of transaction is required.
                                                    </div>   
                                                </div>                                                            
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>p) Date of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="date_of_transaction" placeholder="dd/mm/yy"  autocomplete="off" type="date" [(ngModel)]="dateransaction" formControlName="date_of_transaction" >                                                                       
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.date_of_transaction.touched) && pitClearanceCreateForm.controls.date_of_transaction.errors?.required" class="text text-danger">
                                                        Date of transaction is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>q) No. of securities transacted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" id="securities_transacted" placeholder="No. of securities transacted" autocomplete="off" [(ngModel)]="noOfSecurities" type="text" formControlName="securities_transacted">                                                                       
                                                <div *ngIf="errorMessages">
                                                    <div *ngIf="(submitted || pitClearanceCreateForm.controls.securities_transacted.touched) && pitClearanceCreateForm.controls.securities_transacted.errors?.required" class="text text-danger">
                                                        No. of securities transacted is required.
                                                    </div> 
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceCreateForm.controls.securities_transacted.errors?.pattern">Please, Enter Numbers only.</div>              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>r) Remarks</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" rows="3" autocomplete="off" placeholder="Remarks" formControlName="last_transaction"> </textarea>
                                                                                                                         
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                          
                                <div class="checkbox" formGroupName="checkboxgroup">
                                    <h4 style="text-decoration: underline;">In connection with my request for this pre-clearance of the trade, I hereby declare & undertake that:</h4>
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox" class="checkbox" value="value-1" formControlName="checkbox" required>
                                        <label for="checkbox1">I Declare that I am not in possession of or knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Company's Code of Conduct  upto the time of issuing this undertaking </label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox2" type="checkbox" class="checkbox" formControlName="checkbox1" required>
                                        <label for="checkbox2">In the event that I am in possession/ knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Code of Conduct, after the signing of this undertaking but before executing the transaction for which approval is sought, I shall inform the Compliance Officer of the same and shall completely refrain from dealing in the securities of the Company until such information becomes public;</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox1').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox3" type="checkbox" class="checkbox" formControlName="checkbox2" required>
                                        <label for="checkbox3">I have not contravened the Company’s Code of Conduct for Prohibition of Trading by Insiders and Code for Fair Disclosure of Unpublished Price Sensitive Information.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox2').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox4" type="checkbox" class="checkbox" formControlName="checkbox3" required>
                                        <label for="checkbox4">I have made full and true disclosure in the matter.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox3').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox5" type="checkbox" class="checkbox" formControlName="checkbox4" required>
                                        <label for="checkbox5">In case the traded value exceeds Rs. 10 lakh or any such other value as may be specified, disclosures required under the Code of Conduct for Prohibition of Trading by Insiders will be immediately furnished by me.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox4').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox6" type="checkbox" class="checkbox" formControlName="checkbox5" required>
                                        <label for="checkbox6">I hereby agree to indemnify and keep the Company and its Board indemnified from and against all and any penalties/fines that may be imposed on them by the SEBI and/or any other Statutory Authorities as a result of violation by me of the Regulation of the Codes prescribed by the Bank.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox5').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox7" type="checkbox" class="checkbox" formControlName="checkbox6" required>
                                        <label for="checkbox7">I hereby declare that I shall execute my order in respect of the securities for which preclearance is sought within {{preDayCount}} trading days after the approval is given. If the order is not executed within {{preDayCount}} trading days after the approval, I undertake to obtain fresh pre-clearance.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox6').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox8" type="checkbox" class="checkbox" formControlName="checkbox7" required>
                                        <label for="checkbox8">I have not done any prior contra trade within the period of six months and shall not execute a contra trade for a period of 6 months from the date of current transaction.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox7').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox9" type="checkbox" class="checkbox" formControlName="checkbox8" required>
                                        <label for="checkbox9">I have made full and true disclosure in the above matter.</label>
                                        <div *ngIf="submitted && pitClearanceCreateForm.controls.checkboxgroup.get('checkbox8').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                </div>

                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="cancelPreClearanceTemplate()" >Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <div *ngIf="hidePreclearance && hideNewTrading == false"> -->
                    <div *ngIf="hidePreclearance == true">
                        <!-- <p class="hidepreclearances">Trading Window Closure is continuing for {{diffDays}} days from {{startTwc | date:'dd-MM-yyyy'}} to {{lastTwc | date:'dd-MM-yyyy'}}. you are can not trading preclearance.</p> -->
                        <!-- <p class="hidepreclearances">From {{startTwc | date:'dd-MM-yyyy'}} To {{lastTwc | date:'dd-MM-yyyy'}}. You cannot trade or make an application seeking pre-clearance when the trading window is closed.</p> -->
                        <p class="hidepreclearances">Trading Window is closed from {{startTwc | date:'dd-MM-yyyy'}} to {{lastTwc | date:'dd-MM-yyyy'}} You are not allowed to apply for preclearance when the trading window is closed.<br/>(OR)<br/>Trading Precious Preclearance</p>
                    </div>
                   

                </div>
            </div>                       
        </div>
    </div>


</div>

