import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-add-mail-setting',
  templateUrl: './add-mail-setting.component.html',
  styleUrls: ['./add-mail-setting.component.scss']
})
export class AddMailSettingComponent implements OnInit {
  mailSettingCreateForm: FormGroup;
  button = 'Save';
  button2 = 'Update';
  button3 = 'Send Testing Mail';
  isLoading = false;
  isLoading2 = false;
  submitted: boolean = false;
  listMailSetting: any;
  mailsetting_id: any;
  hostname: string;
  accessUser: any;
  constructor(private fb: FormBuilder, private masterService: MasterService, public router: Router, private toastr: ToastrService) {
    this.accessUser = sessionStorage.getItem('access_user');
   }

  ngOnInit(): void {

    this.masterService.mailsettings(null).subscribe(
      res => {     
      this.listMailSetting = res['data'];  
      if(this.listMailSetting.length > 0){
        this.button = 'Update';
      }else{
        this.button = 'Save';
      }


      if(this.listMailSetting.length != 0){
     
      this.mailsetting_id = {'id':this.listMailSetting[0].id};
      if(this.mailsetting_id['id'] != ''){

        this.masterService.mailsettingsedit(this.mailsetting_id)
        .toPromise().then(result =>{  
         this.mailSettingCreateForm.patchValue(result.data[0]);
       
         })
        //  this.mailSettingCreateForm = this.fb.group({
        //   email: new FormControl ('', Validators.required),
        //   password: new FormControl ('', Validators.required),
        //   mailtype: new FormControl ('', Validators.required),
        //   host: new FormControl('', Validators.required),
        //   port: new FormControl('', Validators.required)
        // });
      }
    }else{
      this.listMailSetting = [];
    }


        },
        err => {
            console.log(err);
        }
    );
  
    this.mailSettingCreateForm = this.fb.group({
      email: new FormControl ('', Validators.required),
      password: new FormControl ('', Validators.required),
      mailtype: new FormControl ('', Validators.required),
      host: new FormControl('', Validators.required),
      port: new FormControl('587', Validators.required),
      encryptiontype: new FormControl('', Validators.required)
    });
   
  }
  changeFn(){
    let mailType = this.mailSettingCreateForm.get('mailtype');
    if(mailType.value == "Gmail"){
        this.hostname = "smtp.gmail.com";
    }else if(mailType.value == "Outlook"){
        this.hostname = "smtp-mail.outlook.com";
    }else if(mailType.value == "Office365"){
        this.hostname = "smtp.office365.com";
    }else if(mailType.value == "webmail"){
        this.hostname = "";
    }
  }
  cancelMailSetting(){
    this.router.navigate(['/dashboard']);
    this.submitted = false;
    this.mailSettingCreateForm.reset();
  }
  testingMailSetting(){
    this.mailSettingCreateForm.value.id = this.mailsetting_id['id'];
    this.mailSettingCreateForm.value.accessUser = this.accessUser;
    this.isLoading2 = true;
      this.button3 = 'Processing';
    this.masterService.sendTestMailSetting(this.mailSettingCreateForm.value).subscribe(data =>{
      if(data.success != '0'){ 
        this.button3 = 'Send Testing Mail';
        this.isLoading2 = false;
        this.toastr.success("Successfully Sent Mail..");

       }else{
        this.button3 = 'Send Testing Mail';
        this.isLoading2 = false;
        this.toastr.success(data.message);
       }
    });
  }
  onSubmit(){
    const emptyFileds = Object.keys(this.mailSettingCreateForm.controls).filter(key =>this.mailSettingCreateForm.controls[key].errors !== null)
    this.submitted = true;
    if(this.mailSettingCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      if(this.listMailSetting?.length > 0){
        this.mailSettingCreateForm.value.id = this.mailsetting_id['id'];

        this.masterService.updateMailSetting(this.mailSettingCreateForm.value).subscribe(data =>{
          if(data.success != '0'){     
            this.toastr.success(" Mail Setting Successfully Updated");
            this.button = 'Update';
            this.isLoading = false;
            this.masterService.clearCache().subscribe(res => { })

            window.location.reload();
       
            }else{
              this.toastr.success(data.message);
              this.button = 'Update';
              this.isLoading = false;
            }
          })

      }else{
        this.masterService.addMailSetting(this.mailSettingCreateForm.value).subscribe(data =>{
          if(data.success != '0'){     
            this.toastr.success(" Mail Setting Successfully created");
            this.button = 'Save';
            this.isLoading = false;
            this.masterService.clearCache().subscribe(res => { })

            window.location.reload();

            }else{
              this.toastr.success(data.message);
              this.button = 'Save';
              this.isLoading = false;
            }
          })
      }
      
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fields.");
    return false;
  }else{
    if(this.mailSettingCreateForm.get('email').value == ''){
      this.toastr.warning("Please fill the Mail Id.");
    }else if(this.mailSettingCreateForm.get('password').value == ''){
      this.toastr.warning("Please fill the Password.");
    }else if(this.mailSettingCreateForm.get('mailtype').value == ''){
      this.toastr.warning("Please fill the Mail Type.");
    }else if(this.mailSettingCreateForm.get('host').value == ''){
      this.toastr.warning("Please fill the Host.");
    }else if(this.mailSettingCreateForm.get('port').value == ''){
      this.toastr.warning("Please fill the port.");
    }else if(this.mailSettingCreateForm.get('encryptiontype').value == ''){
      this.toastr.warning("Please fill the Encryption Type.");
    }
  }
  }

}