<div class="container-fluid">
    <div class="row">
        <div class="card">
            <div class="card-header">
                <h5>View Your Profile</h5>
                <a routerLink="/dashboard"><button class="btn btn-primary me-2 "><i class="fa fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
            </div>
       
            <div class="card-body" *ngIf="profileDetails">
                <h6>DP Basic Details</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngIf="profileDetails">
                                <tr>
                                    <td scope="row">Name</td>
                                    <td scope="row">{{profileDetails.dpName}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Designation</td>
                                    <td scope="row">{{profileDetails.dpDesignation}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Email ID</td>
                                    <td scope="row">{{profileDetails.email}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Contact Number</td>
                                    <td scope="row">{{profileDetails.dpContactNo}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">ID Proof Type -  ID Proof No.</td>
                                    <td scope="row">{{profileDetails.DocumentType}} - {{profileDetails.dpPanNo}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Address</td>
                                    <td scope="row">{{profileDetails.dpAddress}}</td>                                         
                                </tr> 
                                                            
                        </tbody>
                    </table>
                </div>
                <h6>Historical Details</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngIf="profileDetails">
                                <tr>
                                    <td scope="row">Educational Institute Name, Last Graduated From</td>
                                    <td scope="row">{{profileDetails.dpEducationalInstitute}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Company Name of Previous Job</td>
                                    <td scope="row">{{profileDetails.dpPastEmp}}</td>                                         
                                </tr> 
                                
                            
                        </tbody>
                    </table>
                </div>
                <h6>Remarks</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngIf="profileDetails">
                                <tr>
                                    <td scope="row">Remarks</td>
                                    <td scope="row">{{profileDetails.remarks}}</td>                                         
                                </tr> 
                                
                            
                        </tbody>
                    </table>
                </div>
                <h6>Confidentiality Agreement Entered</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngIf="profileDetails">
                                <tr>
                                    <td scope="row">Confidentiality Agreement entered</td>
                                    <td scope="row">{{profileDetails.confidentiality_agreement_entered}}</td>                                         
                                </tr> 
                                <tr *ngIf="profileDetails.confidentiality_agreement_entered == 'Yes' ">
                                    <td scope="row">Confidentiality Agreement Copy</td>
                                    <td scope="row"><a href="{{confidencialUrl}}/public/uploads/dp/{{dpId}}/{{profileDetails?.data[0].filePath}}" target="_blank">{{profileDetails.filePath}}</a></td>                                         
                                </tr> 
                                <tr *ngIf="profileDetails.confidentiality_agreement_entered == 'Yes' ">
                                <td scope="row">Confidentiality Agreement Date</td>
                                <td scope="row">{{profileDetails.confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                </tr> 
                                
                            
                        </tbody>
                    </table>
                </div>
                <h6>Name of Immediate Relative / Person Sharing Financial Material Relationship With DP</h6>
                <div class="custom-datatable">
                    <table class="table" *ngIf="profileDetails?.immediateDpRelative != ''">                            
                            <tbody *ngFor="let items of profileDetails?.immediateDpRelative">
                                <tr>
                                    <td scope="row">Name</td>
                                    <td scope="row">{{items.name}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Relationship</td>
                                    <td scope="row">{{items.relationship}}</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">Contact Number</td>
                                <td scope="row">{{items.phone_no}}</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">ID Proof Type</td>
                                <td scope="row">{{items.DocumentType}}</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">ID Proof No.</td>
                                <td scope="row">{{items.pan}}</td>                                         
                                </tr> 
                                
                            
                        </tbody>
                    </table>
                    <table class="table" *ngIf="profileDetails?.immediateDpRelative == '' ">                            
                            <tbody >
                                <tr>
                                    <td scope="row">Name</td>
                                    <td scope="row">Not Provided</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Relationship</td>
                                    <td scope="row">Not Provided</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">Contact Number</td>
                                <td scope="row">Not Provided</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">ID Proof Type</td>
                                <td scope="row">Not Provided</td>                                         
                                </tr> 
                                <tr>
                                <td scope="row">ID Proof No.</td>
                                <td scope="row">Not Provided</td>                                         
                                </tr> 
                                
                            
                        </tbody>
                    </table>
                </div>
                <h6>Annual Confirmation</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngFor="let item of profileDetails?.annualConfirmation">
                                <tr>
                                    <td scope="row">Financial Year</td>
                                    <td scope="row">{{item.financialYear}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row">Last Confirmed Date</td>
                                    <td scope="row">{{item.lastConformedDate}}</td>                                         
                                </tr> 
                            
                        </tbody>
                    </table>
                    <table class="table" *ngIf="profileDetails?.annualConfirmation == '' ">                            
                    <tbody >
                        <tr>
                            <td scope="row">Financial Year</td>
                            <td scope="row">Not Provided</td>                                         
                        </tr> 
                        <tr>
                            <td scope="row">Last Confirmed Date</td>
                            <td scope="row">Not Provided</td>                                         
                        </tr> 
                </tbody>
            </table>
                </div>
                <h6>Additional Details</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                            <tbody *ngIf="profileDetails">
                                
                            <tr>
                                <td scope="row">Group Label Name</td>
                                <td scope="row">{{profileDetails.groupLabelName}}</td>                                         
                            </tr> 
                            <tr>
                                <td scope="row">DP ID</td>
                                <td scope="row">{{profileDetails.dpIndexId}}</td>                                         
                            </tr> 
                            <tr>
                                <td scope="row">Client ID</td>
                             <td scope="row">{{profileDetails.dpClientId}}</td>                                         
                            </tr> 
                            <tr>
                                <td scope="row">Category of Person</td>
                             <td scope="row">{{profileDetails.categoryofperson}}</td>                                         
                            </tr> 
                            
                        </tbody>
                    </table>
                </div>
                <h6>Access to SDD</h6>
                <div class="custom-datatable">
                    <table class="table">                            
                    <tbody *ngIf="profileDetails">
                        <tr *ngIf="profileDetails.accessToSdd == 'Active' ">
                            <td scope="row">Access to SDD</td>
                            <td scope="row" >Yes</td>                                        
                        </tr> 
                        <tr *ngIf="profileDetails.accessToSdd == 'Inactive' ">
                            <td scope="row">Access to SDD</td>
                            <td scope="row" >No</td>                                        
                        </tr> 
                        <tr *ngIf="profileDetails.accessToSdd == null ">
                        <td scope="row">Access to SDD</td>
                        <td scope="row" >No</td>                                        
                    </tr>
                    <tr *ngIf="profileDetails.accessToSdd == '' ">
                        <td scope="row">Access to SDD</td>
                        <td scope="row" >No</td>                                        
                    </tr> 
                        <tr *ngIf="profileDetails.accessToSdd == 'Active' ">
                            <td scope="row">User Type</td>
                            <td scope="row">{{profileDetails.role}}</td>                                         
                        </tr> 
                        <tr *ngIf="profileDetails.accessToSdd == 'Active' ">
                            <td scope="row">Project Access</td>
                            <td scope="row">{{profileDetails.project}}</td>                                         
                        </tr> 
                      
                </tbody>
            </table>
                </div>
                <div class="checkbox">
                           
                    <div class="col-sm-12 col-xl-12">
                       <div class="card">                                                     
                         <div class="card-body timeline">
                           <ngb-accordion [closeOthers]="true">
                             <ngb-panel id="static-1">
                               <ng-template ngbPanelTitle>
                                   <span>Timeline Activity</span>
                                 </ng-template>
                               <ng-template ngbPanelContent>
                                <div class="custom-datatable">
                                    <div class="timeline-wrapper"> 
                                        <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                               <li class="StepProgress-item is-done">
                                                   <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                   <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                   <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                   <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                   <div ><p><span class="bold">Username (Action By):</span> {{items.ActionBy}}</p></div>
                                                   <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                               </li>                                       
                                        </ul>
                                    </div>    
                                    
                                    <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                        <p>No Data Found</p>
                                    </div>
                                 
                               </div>
  
                               </ng-template>
                             </ngb-panel>
                                                                 
                           </ngb-accordion>
                         </div>
                       </div>
                     </div>
                   
                 </div>
            </div>
            <div class="card-footer" *ngIf="is_access_write && profileDetails">
                <div class="submit-btn" *ngIf="profileDetails">
                    <a [routerLink]="['/dp/profile-edit', profileDetails.id]"> <button class="btn btn-primary" type="submit">Edit</button></a> 
                    <button class="btn btn-warning" type="btn" (click)="cancelProfile()">Cancel</button>                    
                 </div>
            </div>
            <div class="card-body" *ngIf="!profileDetails">
                <p class="notfound">No Data Found...</p>
            </div>
        </div>
    </div>
</div>