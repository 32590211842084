import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { window } from 'rxjs';
import { InsiderServiceService } from '../insider-service.service';

@Component({
  selector: 'app-revert-insider',
  templateUrl: './revert-insider.component.html',
  styleUrls: ['./revert-insider.component.scss']
})
export class RevertInsiderComponent implements OnInit {

  @Input() viewInsiderList;
  @Input() title;
  getIdInsider: any;
  revetInsiderForm:FormGroup;
  getEmailInsider: any;
  isLoading = false;
  button = 'Restore';
  submitted = false;
  constructor(
    public activeModal: NgbActiveModal,private router:Router, private fb: FormBuilder, private toastr: ToastrService, private activateRouter: ActivatedRoute, private insiderService: InsiderServiceService,
  ) { }

  ngOnInit() {
    
    this.revetInsiderForm = this.fb.group({
      id: new FormControl(''),
      email: new FormControl(''),
      reasonforrestore: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])

    });
   this.getIdInsider = this.viewInsiderList.id;
   this.getEmailInsider = this.viewInsiderList.email;

  }
  revertInsiderId(){
    this.submitted = true;
    this.revetInsiderForm.value.id = this.getIdInsider;
    this.revetInsiderForm.value.email = this.getEmailInsider;
    if(this.revetInsiderForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.insiderService.revertInsider(this.revetInsiderForm.value).subscribe(data =>{
        if(data.success == 2){
          this.toastr.warning(data.message);
          this.isLoading = false;
          this.button = 'Restore';
          this.activeModal.close(event);
        }else{
        this.toastr.success("Insider Successfully Restored");
        this.isLoading = false;
          this.button = 'Restore';
        this.activeModal.close(event);
        this.router.navigate(['/insider/insider-list']);
        }
      });
    }else{
        if(this.revetInsiderForm.get('reasonforrestore').value == ''){
          this.toastr.warning("Please Mention the Reason for Restore Insider.");
        }
      }

  }
  cancelRevertData(){
    this.activeModal.close(event);
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
