import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DpModuleService } from '../dp-module.service';

@Component({
  selector: 'app-db-module-delete',
  templateUrl: './db-module-delete.component.html',
  styleUrls: ['./db-module-delete.component.scss']
})
export class DbModuleDeleteComponent implements OnInit {
  dpId: any;
  accessSuccess: string;
  dpReasonDeleteForm:FormGroup;
  @Input() viewDbList;
  isLoading = false;
  button = 'Delete';
  getId: any;
  submitted = false;
  constructor(private fb: FormBuilder,private activateRouter:ActivatedRoute, private dpService:DpModuleService,  public router: Router, private toastr: ToastrService,
    public activeModal: NgbActiveModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){      
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.dpReasonDeleteForm = this.fb.group({
      id : new FormControl(''),
      reasonfordelete : new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])
    });
    this.getId = this.viewDbList.id
  //   this.activateRouter.params.subscribe(data =>{
  //     this.dpId = data['id']
  //   })
  //   if(this.dpId){
  //     this.dpService.deleteDpModule(this.dpId).subscribe(data =>{
      
  //     this.toastr.success("This person is no longer DP in the company", "Success!");
  //     this.router.navigate(['/dp/dp-list']);
  //   })
  // }
  }

  onSubmit(){
    this.submitted = true;
    this.dpReasonDeleteForm.value.id = this.getId;
    if(this.dpReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
        this.button = 'Processing';
    this.dpService.deleteDpModule(this.dpReasonDeleteForm.value).subscribe(data =>{
      // console.log(agenda Details deleted )
      this.toastr.success("DP Successfully Deleted");
      this.isLoading = false;
        this.button = 'Delete';
      window.location.reload();
      
    })
  }else{
    if(this.dpReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting DP.");
    }
  }
  }
  
  
        
    closeModal(sendData) {
      this.activeModal.close(sendData);
    }

}
