import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { distinct, from, Observable, tap } from 'rxjs';
import { COMPANYDB, CompanyDB } from '../../shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { UpsiService } from '../upsi.service'
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MasterService } from 'src/app/master/master.service';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PopupModelUpsiComponent } from '../popup-model-upsi/popup-model-upsi.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulActionPopupComponent } from '../bul-action-popup/bul-action-popup.component';

@Component({
  selector: 'app-deleted-upsi-module',
  templateUrl: './deleted-upsi-module.component.html',
  styleUrls: ['./deleted-upsi-module.component.scss']
})
export class DeletedUpsiModuleComponent implements OnInit {

  fileName: string = 'SheetJS.xlsx';
  @ViewChild('auto') auto;
  Date= new Date;
  dropdownSettings:IDropdownSettings;
  dropdownSettings1:IDropdownSettings;
  dropdownSettings2:IDropdownSettings;
  public selected = [];
  baseUrl = environment.apiUrl;
  page = 1;
  // arr=[];
  newarr:any;
  is_show=false;
  pageSize = 10;
  listUpsiTransfer: any;
  listUpsiTransfers: any;
  pipe: DatePipe;
  filterForm: FormGroup;
  public active1 = 1;
  listProject: any;
  selected_project=[];
  selected_sender:any;
  selected_receiver:any;
  selected_hideproject:any;
  filtered: any;
  filterString: any;
  myDateValue: Date;
  toDate: Date;
  duplicateArray = []
  // url = "https://localhost/sdd/public/api/upsi/indexexcel"
  upsiId: any;
  confidentialityUpsiList: any;
  accessSuccess: string;

  dropdownList = [];
  senderList = [];
  receiverList = [];
  selectedItems = [];
  selectedSenderItems = [];
  selectedReceiverItems = [];
  hidelist = [{"id":1,"name":"Sender"},{"id":2,"name":"Recipient"}];
  selectedhideItems = [];

  show_sender=false;
  show_receiver=false;
  // dropdownSettings = {};
  filterDateItem:boolean = false;
  visible: boolean = false
  visible1: boolean = false
  visible2: boolean = false
  visible3: boolean = false
  visible4: boolean = false
  visible5: boolean = false;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;
  keyword = 'nature_of_upsi';
  keyword2 ='project_name';
  keyword3 ='sender';
  keyword4 ='recipient';
  keyword5 ='date_of_sharing';
  nameFilterString= '';
  nameFilterString2= '';
  nameFilterString3= '';
  nameFilterString4= '';
  nameFilterString5= '';
  excelFile: any;
  filterStrings = "";
  constructor(private upsiService: UpsiService, private modalService: NgbModal, private masterService: MasterService, private fb: FormBuilder, private http: HttpClient,  public router: Router,private activateRouter: ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }

    
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.filterForm && this.filterForm.get("dateOfSharing").value;
    const to = this.filterForm && this.filterForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  ngOnInit(): void {


    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    // console.log("user_roles", this.user_roles);
    if(this.user_roles.upsi_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.upsi_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.selectedItems = [];
    this.selectedSenderItems = [];
    this.selectedReceiverItems = [];
  //  this.onSubmit();

    //Project api
    // setTimeout(() =>{
      this.masterService.projectList(null).subscribe(
        res => {
          this.listProject = res.body['data'];    
          
        },
        err => {
          console.log(err);
        }
      )

      this.upsiService.upsiNoLonger(this.filterForm.value).subscribe(data => {
        this.listUpsiTransfer = data;
        // if(this.listUpsiTransfer.message == 'Invalid User.'){
        //   this.router.navigate(['/auth/login']);
        // }else{
        this.filtered = this.listUpsiTransfer.data
      });
  
     
  }

  //   get from_date() { return this.filterForm.get('fromDate').value; }
  // get to_date() { return this.filterForm.get('toDate').value; }

  // onFilterChange() {
  //   this.filtered = this.listUpsiTransfer?.filter((project_name) => this.isMatch(project_name));
  // }

  // isMatch(item) {
  //   if (item instanceof Object) {
  //     return Object.keys(item).some((k) => this.isMatch(item[k]));
  //   } else {
  //     return item.toString().indexOf(this.filterString) > -1
  //   }
  // }
  // onDateChange(newDate: Date) {
  //   console.log("newDate", newDate);
  // }

   reverseAndTimeStamp(dateString) {
        const reverse = new Date(dateString.split("-").reverse().join("-"));
        return reverse.getTime();
        }
    filterDate() {
        let fromdate=moment(this.myDateValue).format('DD-MM-YYYY');
   
    let todate=moment(this.toDate).format('DD-MM-YYYY');
    if(this.myDateValue && this.toDate){
    const selectedMembers = this.listUpsiTransfer.filter(m => {
            return this.reverseAndTimeStamp(m.from_date) >= this.reverseAndTimeStamp(fromdate) && this.reverseAndTimeStamp(m.from_date) <= this.reverseAndTimeStamp(todate)
        }
        );
        this.duplicateArray=selectedMembers
    }else{
  this.duplicateArray=this.listUpsiTransfer
    }

       
    }

  filterData(){
    this.filterDateItem = !this.filterDateItem;
    this.visible = !this.visible
    
  }
  
//colum filter
  selectEvent(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      // console.log("item", upsiItem.nature_of_upsi.toLocaleLowerCase().match(item.nature_of_upsi.toLocaleLowerCase()))
      return upsiItem.nature_of_upsi.toLocaleLowerCase().match(item.nature_of_upsi.toLocaleLowerCase());

    })

    this.visible1 = false;
  }
  selectEvent2(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.project_name.toLocaleLowerCase().match(item.project_name.toLocaleLowerCase());

    })

    this.visible2 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.sender.toLocaleLowerCase().match(item.sender.toLocaleLowerCase());

    })

    this.visible3 = false;
  }
  selectEvent4(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.recipient.toLocaleLowerCase().match(item.recipient.toLocaleLowerCase());

    })

    this.visible4 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.listUpsiTransfer?.data?.filter(upsiItem => {
      // console.log("item1235", this.nameFilterString)
      return upsiItem.date_of_sharing.toLocaleLowerCase().match(item.date_of_sharing.toLocaleLowerCase());

    })
   
    this.visible5 = false;
  }
  onFilterChange() {
    
    this.filtered = this.listUpsiTransfer?.data?.filter(res =>{         
      return res.nature_of_upsi?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.project_name?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.sender?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.recipient?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase()) || res.date_of_sharing?.toLocaleLowerCase().match(this.filterStrings?.toLocaleLowerCase())
     });
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    console.log("String: " + search);
    this.auto.close();
  }

  onFocused(e) {
    // do something
  }


  onBlur(e) {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  onScrolledEnd() {
    // this.auto.close();
  }
  filterData1() {
    this.visible1 = !this.visible1;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData2() {
    this.visible2 = !this.visible2;
    this.visible1 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData3() {
    this.visible3 = !this.visible3;
    this.visible1 = false;
    this.visible2 = false;
    this.visible4 = false;
    this.visible5 = false;
  }
  filterData4() {
    this.visible4 = !this.visible4;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible5 = false;
  }
  filterData5() {
    this.visible5 = !this.visible5;
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
  }
  //column Filter

  openModal(id:any) {

    if (id) {
      this.upsiService.viewUpsiTransfer(id).subscribe(
        ({data}) =>{
          const modalRef = this.modalService.open(PopupModelUpsiComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewUpsiList = data[0];
          modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
      })

      }
   
    
  }


}
