import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { AuthlogService } from "../authlog.service"
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {
  public newUser = false;
  emailVerifiedForm: FormGroup;
  emptyMessage: boolean = false;
  hideForm: boolean = true;
  authGuard: string;
  forgetPasswordLink: any;
  href: string;
  path: string;
  hrefUrl: any;
  companyname: string;
  button = 'Submit';
  isLoading = false;
  constructor(private fb: FormBuilder,
    private router: Router, private loginService: LoginService, private authlog: AuthlogService, private toastr: ToastrService) {
     
  }

  ngOnInit() {
    this.hrefUrl = environment.href;
    this.companyname = environment.companyName;
   
    this.emailVerifiedForm = this.fb.group({
      username: ['', Validators.required],   
      //username: ['', [Validators.required, Validators.email]],   
    });
  }
  // emailVerified(){
  //   this.router.navigate(["/auth/change-password"]);
  // }
  emailVerified(){
    this.isLoading = true;
  this.button = 'Processing';
    this.emailVerifiedForm.value.url = this.hrefUrl;   
    this.emailVerifiedForm.value.companyName = this.companyname;
    this.authlog.forgetPassword(this.emailVerifiedForm.value).subscribe(data =>{
      if(data.success !==0){       
        this.emptyMessage = true;
      this.hideForm = false;
      this.forgetPasswordLink = data;
      this.isLoading = false;
      this.button = 'Submit';
      }else{
        this.toastr.error("Invalid User");
        this.isLoading = false;
        this.button = 'Submit';
      }
      
      })

  }
  
}
