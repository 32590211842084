import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-declaration-of-trade',
  templateUrl: './view-declaration-of-trade.component.html',
  styleUrls: ['./view-declaration-of-trade.component.scss']
})
export class ViewDeclarationOfTradeComponent implements OnInit {
 @Input() viewTradingWindowList;
  html: any;


  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.html = this.viewTradingWindowList.body_content
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
