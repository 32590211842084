import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PitComplianceService } from '../pit-compliance.service';

@Component({
  selector: 'app-benpos-mail-config',
  templateUrl: './benpos-mail-config.component.html',
  styleUrls: ['./benpos-mail-config.component.scss']
})
export class BenposMailConfigComponent implements OnInit {
  @Input() mailTemplate;
  @Input() benf_date;
  benposMailTemplate: any;
  benfDate: any;
  constructor(public activeModal: NgbActiveModal, private pitService: PitComplianceService, private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {
   
    this.benposMailTemplate = this.mailTemplate;
    this.benfDate = this.benf_date;
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
    // window.location.reload();
  }
  submitData(){
    let benposId = {'id':this.benf_date, 'confirm': 'Yes'}
    this.pitService.benposTradesMail(benposId).subscribe(
      res =>{      
        this.closeModal(event)
    })
  }
}
