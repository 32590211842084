import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { ListUpsiComponent } from './list-upsi/list-upsi.component';
import { AddUpsiComponent } from './add-upsi/add-upsi.component';
import { EditUpsiComponent } from './edit-upsi/edit-upsi.component';
import { DeleteUpsiComponent } from './delete-upsi/delete-upsi.component';
import { HistoryUpsiComponent } from './history-upsi/history-upsi.component';
import { ReportUpsiComponent } from './report-upsi/report-upsi.component';
import { NoLongerUpsiComponent } from './no-longer-upsi/no-longer-upsi.component';
import { ViewUpsiComponent } from './view-upsi/view-upsi.component';
import { DeletedUpsiModuleComponent } from './deleted-upsi-module/deleted-upsi-module.component';
import { NoupsiComponent } from './noupsi/noupsi.component';
const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: ListUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "UPSI List"
        }
      }, 
      {
        path: 'upsi-list',
        component: ListUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "UPSI List"
        }
      },  
      {
        path: 'upsi-add',
        component: AddUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "Add"
        }
      },
      {
        path: 'upsi-edit/:id',
        component: EditUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "Duplicate"
        }
      },
      {
        path: 'upsi-view/:id',
        component: ViewUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "view"
        }
      },
      {
        path: 'upsi-delete/:id',
        component: DeleteUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "Delete"
        }
      },
      {
        path: 'upsi-history/:id',
        component: HistoryUpsiComponent,
        data: {
          title: "UPSI ",
          breadcrumb: "History"
        }
      },
      {
        path: 'upsi-report',
        component: ReportUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "Report"
        }
      },
      {
        path: 'deleted-upsi',
        component: DeletedUpsiModuleComponent,
        data: {
          title: "UPSI",
          breadcrumb: "UPSI Deleted List"
        }
      },
      {
        path: 'no-longer-upsi',
        component: NoLongerUpsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "No Longer UPSI List"
        }
      },
      {
        path: 'noupsi',
        component: NoupsiComponent,
        data: {
          title: "UPSI",
          breadcrumb: "Non UPSI Communication"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpsiRoutingModule { }
