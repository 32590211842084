import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { PitComplianceService } from '../../pit-compliance.service';
import { PreClearanceMailTemplateComponent } from '../pre-clearance-mail-template/pre-clearance-mail-template.component';
import { CompanyService } from 'src/app/master/company/company.service';
import { MasterService } from 'src/app/master/master.service';
import { UpsiService } from 'src/app/upsi-transfer/upsi.service';

@Component({
  selector: 'app-pre-clearance-status',
  templateUrl: './pre-clearance-status.component.html',
  styleUrls: ['./pre-clearance-status.component.scss']
})
export class PreClearanceStatusComponent implements OnInit {
  @ViewChild('approval') approval!: ElementRef;
  @ViewChild('reject') reject!: ElementRef;
  @Input() statusPreclerance;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getId: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  statusApprovalData = [];
  statusRejectData = [];
  complianceOfficerEmail: any;
  complianceOfficerDesignation: any;
  complianceOfficerName: any;
  companyName: any;
  sharesConditions = [];
  valuesConditions = [];
  statusConfirm: any;
  errorShareCondition: string;
  errorValueCondition: string;
  trueConditions = true;
  wrningConditions = false;
  errorConditions = false;
  valueConditionArr = [];
  shareConditionArr = [];
  isLoading = false;
  button = 'Approve';
  preDayCount: any;
  warningmessage: any;
  tradingcompanyname:any
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService, private pitService: PitComplianceService,
    private modalService: NgbModal,private upsiService: UpsiService, private companyService: CompanyService, private masterService: MasterService ) { }

  ngOnInit() {
 this.statusApprovalData = this.statusPreclerance;
 this.tradingcompanyname = this.statusPreclerance.tradingcompanyname;
 //company details
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      this.preDayCount = listCompany[0].predaycount;

    }
    },
    err => {
        console.log(err);
    }
);

this.masterService.listCompanyRules(null).subscribe(
  res => {
    this.sharesConditions = [];
    this.valuesConditions = [];
  let listCompanyRules = res.data;
    this.statusConfirm = listCompanyRules[0].rule_status;
    if(listCompanyRules[0]?.rule_status =="Approved"){
    listCompanyRules[0]?.rules_model.map(item =>{
     
      if(item.rules_condition == "Share"){
        this.sharesConditions.push(item);
      }else{
        this.valuesConditions.push(item);       
      }    
     
    })
  }else{
    this.sharesConditions = [];
    this.valuesConditions = [];
  }
    },
    err => {
        console.log(err);
    }
);

//upsi
this.upsiService.upsiTransferList(null).subscribe(data => {
    
  let listUpsiTransfer = data;
  
},
  err => {
    console.log(err);
  }
)



  }

  
  statusapprovalmailconfig(status,valId){
    this.statusApprovalData['complianceofficeremail'] = this.complianceOfficerEmail;
    this.statusApprovalData['complianceofficername'] = this.complianceOfficerName;
    this.statusApprovalData['complianceofficerdesignation'] = this.complianceOfficerDesignation;
    this.statusApprovalData['companyname'] = this.companyName;
    this.statusApprovalData['status'] = status;
    this.statusApprovalData['id'] = valId;
    this.statusApprovalData['preDayCount'] = this.preDayCount;
    this.statusApprovalData['tradingcompanyname'] = this.tradingcompanyname;
    this.pitService.statusapprovalmailconfig(this.statusApprovalData).subscribe(data =>{ 
     
    });
}
  approvalStatus(){
    let approvalStatus = this.approval.nativeElement.value;
    //this.statusApprovalData = item;
    // this.statusApprovalData['status'] = approvalStatus;
    this.statusApprovalData['complianceofficeremail'] = this.complianceOfficerEmail;
    this.statusApprovalData['complianceofficername'] = this.complianceOfficerName;
    this.statusApprovalData['complianceofficerdesignation'] = this.complianceOfficerDesignation;
    this.statusApprovalData['companyname'] = this.companyName;
    this.statusApprovalData['preDayCount'] = this.preDayCount;
    
      this.isLoading = true;
      this.button = 'Processing';
      this.trueConditions = true;
      this.errorConditions = false;
      this.statusApprovalData['status'] = approvalStatus;
      this.pitService.preClearanceSatusApproval(this.statusApprovalData).subscribe(data =>{
        if(data.warningmessage ==""){
          this.isLoading = false;
          this.button = 'Approve';
          let statusApprovalId = data.statusApprovalId;
          this.statusapprovalmailconfig(approvalStatus,statusApprovalId);
          this.toastr.success("Pre Clearance Successfully Approved");
        }else{
          this.trueConditions = false;
          this.wrningConditions = true;
          this.warningmessage = data.warningmessage;
        }
      
      
      window.location.reload();
    
     })
    
  
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
