import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DpModuleService } from '../dp-module.service';

@Component({
  selector: 'app-db-module-view',
  templateUrl: './db-module-view.component.html',
  styleUrls: ['./db-module-view.component.scss']
})
export class DbModuleViewComponent implements OnInit {
  dbId: any;
  viewDbList: any;
  public active1 = 1;
  confidencialUrl: any;
  constructor(private activateRouter: ActivatedRoute, private dpService:DpModuleService, public router: Router) { 
    this.confidencialUrl = environment.url
  }

  ngOnInit(): void {

    this.activateRouter.params.subscribe(data => {
      this.dbId = data['id']
    })
    if (this.dbId !== '') {
      this.dpService.viewDpModule(this.dbId).subscribe(
        data =>{
        this.viewDbList = data;
       
      })

      }
  }

}
