<div #popup class="model-popup">
    <div class="modal-header">
        <h5 class="modal-title">BENPOS Mail Template</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
     
          <div class="row benpos-mail" *ngIf="benposMailTemplate !='' ">
          <div [innerHTML]="benposMailTemplate"></div> 
          
          </div>
          <div class="list-btn2" *ngIf="benposMailTemplate =='' ">
            <p>No previous week data to compare for this date - {{this.benfDate | date:'dd-MM-yyyy'}}</p>
        </div> 
        <div class="btn-editor">
            <button type="button" (click)="submitData()" class="btn btn-primary mt-5">YES</button>
            <button type="button" (click)="closeModal('close')" class="btn btn-primary mt-5">NO</button>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div>
    
</div>