import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../master.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-company-list',
  templateUrl: './edit-company-list.component.html',
  styleUrls: ['./edit-company-list.component.scss']
})
export class EditCompanyListComponent implements OnInit {
  companyId: any;
  @Input() viewcompanyList;
  constructor(public activeModal: NgbActiveModal, private masterService: MasterService, public router: Router, private activateRouter: ActivatedRoute) { }

  ngOnInit(): void {
    
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
