import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-disclaimerexcel',
  templateUrl: './disclaimerexcel.component.html',
  styleUrls: ['./disclaimerexcel.component.scss']
})
export class DisclaimerexcelComponent implements OnInit {

  @Input() pdfapprovedOrder;
  @Input() title;
  public active1 = 1;
  baseUrl = environment.apiUrl;
  @Output() onSubmitEntry = new EventEmitter();
  isLoading = false;
  button = 'Yes';
  showuserstext: boolean = false;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit() {
    if(this.title == 'Users Lists'){
        this.showuserstext = true;

    }else{
        this.showuserstext = false;


    }

  }
 
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

  onFilterPdf(value){
    this.onSubmitEntry.emit(value);
   
    this.activeModal.close(event);
  }
}
