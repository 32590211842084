import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventTypes } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-delete-company-list',
  templateUrl: './delete-company-list.component.html',
  styleUrls: ['./delete-company-list.component.scss']
})
export class DeleteCompanyListComponent implements OnInit {
  projectId: any;
  accessSuccess: string;
  insiderId: any;
  @Input() viewcompanyList;
  isLoading = false;
  button = 'Delete';
  projectReasonDeleteForm: FormGroup;
  getId: any;
  submitted = false;
  constructor(public activeModal: NgbActiveModal,private fb: FormBuilder,private masterService: MasterService, public router: Router, private toastr: ToastrService, private activateRouter:ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.projectReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('', Validators.required)
    });
   this.getId = this.viewcompanyList.id;

  //   this.activateRouter.params.subscribe(data =>{
  //     this.projectId = data['id']
  //   })
  //   if(this.projectId){
  //     this.masterService.deleteProject(this.projectId).subscribe(data =>{
  //     // console.log(agenda Details deleted )
  //     this.toastr.success("project details deleted", "Success!");
  //     this.router.navigate(['/project/project-list']);
  //   })
  // }
  }

  onSubmit(){
    this.submitted = true;
    this.projectReasonDeleteForm.value.id = this.getId;
    if(this.projectReasonDeleteForm.status !== 'INVALID'){
    this.isLoading = true;
        this.button = 'Processing';
    this.masterService.deletePermissionTradingCompany(this.projectReasonDeleteForm.value).subscribe(data =>{
      console.log(this.projectReasonDeleteForm.value )
      this.activeModal.close(event);
      this.toastr.success("Company Name Successfully Deleted");
      this.isLoading = false;
        this.button = 'Delete';
      // this.router.navigate(['/insider/insider-list']);
      window.location.reload();
      
    })
  }else{
    if(this.projectReasonDeleteForm.get('reasonfordelete').value == ''){
      this.toastr.warning("Please Mention the Reason for Deleting Project.");
    }
  }
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
