<div class="container-fluid">
   
    <div class="row" *ngIf="is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="serach" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search ..." 
                             required >
                                                                         
                    </div>
                        </div>
                             
                    <div class="list-btn"> 
                        <!-- <div class="columnHide mb-3">
                            <div class="filter-group">
                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                    [settings]="dropdownSettings1"
                                    [data]="hidelist"
                                    [(ngModel)]="selectedhideItems"
                                    (onSelect)="onItemSelect($event,'column')"
                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                    (onSelectAll)="onSelectAll($event,'column')" 
                                    >
                                </ng-multiselect-dropdown>
                            </div>
                        </div> -->
                        <a routerLink="/pit-compliance/add-pre-clearance" *ngIf="is_access_write"><button class="btn btn-primary me-1" >New</button></a>
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>  
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table center" id="formTable">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="s.no">S.No.</th>
                                    <th scope="col" sortable="Id.no" >ID No.</th>
                                    <th scope="col" sortable="dpName" class="dpName" >Dp Name </th>
                                    <th scope="col" sortable="PAN" >PAN</th>
                                    <!-- <th scope="col" sortable="Designation" >Designation</th>  -->
                                    <th scope="col" sortable="Status" >No Trade</th>                                   
                                    <th scope="col" sortable="Designation" >Status</th>                                    
                                    <th scope="col" sortable="action" >Actions</th>                                    
                                    <th scope="col" sortable="Status" ></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                    <td scope="row" data-content="ID No.">PC - {{item.id}}</td>
                                    <td scope="row" data-content="Dp Name">{{item.dpname}}</td> 
                                    <td scope="row" data-content="PAN">{{item.dppan}}</td> 
                                    <!-- <td scope="row" >{{item.dpdesignation}}</td>  -->

                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == false && item.status == 'Approved' && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved' && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.dpname != nameEmailmerge && item.notrade =='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.dpname === nameEmailmerge && item.notrade =='No Trade'">                                    
                                        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
                                    </td> 
                                    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved' && item.previouslistcountval == 0 && item.notrade !='No Trade'">--</td> 
                                    <!-- <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess && item.dpname === nameEmailmerge && item.status == 'Approved' && item.previouslistcountval == 0 && item.notrade !='No Trade'">--</td>  -->
                                    <!-- <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved'  && item.previouslistcountval == 0">--</td>  -->
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Pending'">--</td> 
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.previouslistcountval != 0">--</td> 
                                    <!-- <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved'  && item.previouslistcountval != 0">--</td>   -->
                                    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Rejected'">--</td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Pending'"><span class="badge badge-warning">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Approved'"><span class="badge badge-success">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Rejected'"><span class="badge badge-danger">{{item.status}}</span></td> 
                                    <td scope="row" data-content="Status" *ngIf="item.status == 'Incomplete'"><span class="badge badge-info">{{item.status}}</span></td> 
                                    <td data-content="Actions">
                                        <!-- <button class="btn btn-primary me-1">Send Mail</button> -->
                                        <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
                                        <!-- <button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button> -->
                                        <button class="edit-icon" *ngIf="is_access_write" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button>
                                        <button class="edit-icon" title="Download Application Form" (click)="openModalPdf(item.id)"><i class="fa fa-file-pdf-o"></i></button> 
                                        <!-- <a [routerLink]="['/pit-compliance/duplicate-pre-clearance', item.id]"><button class="edit-icon" title="Duplicate"><i class="fa fa-files-o"></i></button></a> -->
                                        <!-- <a [routerLink]="['/pit-compliance/add-trading-report', item.id, 'Form C']" *ngIf="item.status == 'Approved'"><button class="edit-icon" title="Download Form C PDF"><i class="fa fa-plus-square-o"></i></button></a> -->
                                    </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Pending' || item.status == null" class="statusItem">                                       
                                        <button class="btn me-1 ls"  title="Approval" value="Approved"  (click)="openModalApproval(item.id)" ><img src="assets/images/check-mark.png"></button>
                                        <button class="btn me-1 ls"  title="Reject" value="Rejected"   (click)="openModalReject(item.id)" ><img src="assets/images/cross-button.png"></button>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls redirect" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                        <!-- <button class="btn me-1 ls redirect" title="Pending"><img src="assets/images/pendingImg.png"></button> -->
                                    </td>   
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Pending' || item.status == null" class="statusItem">  
                                        <button class="btn me-1 ls redirect" title="Pending"><img src="assets/images/pendingImg.png"></button>
                                     </td>   
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Approved'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Approval" ><img src="assets/images/check-mark.png"></button> -->
                                        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
                                        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
                                        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
                                        <button class="btn me-1 ls" title="Approval" ><img src="assets/images/check-mark.png"></button>
                                        <!-- <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Rejected'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Reject"><img src="assets/images/cross-button.png"></button> -->
                                        <button class="btn me-1 ls re-app" title="Re-Approval" value="Approved" (click)="openModalReapproval(item.id)"><img src="assets/images/approve.png"></button>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Rejected'" class="statusItem">                                       
                                        <!-- <button class="btn me-1 ls" title="Reject"><img src="assets/images/cross-button.png"></button> -->
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                        <button class="btn me-1 ls" title="Rejected Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
                                    </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Incomplete'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
                                        <!-- <a routerLink="/pit-compliance/trading-pre-clearance"><button class="btn me-1 ls" title="Previous Pre Clearance Trading "><img src="assets/images/rediect-page.png"></button></a> -->
                                     </td>  
                                    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Incomplete'" class="statusItem">                                       
                                        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
                                     </td>  
                                     
                            </tr> 
                            </tbody>
                        </table> 

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div> 

                        <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>


<!-- Container-fluid starts -->
<!-- <tr *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
    <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
    <td scope="row" data-content="ID No.">PC - {{item.id}}</td>
    <td scope="row" data-content="Dp Name">{{item.dpname}}</td> 
    <td scope="row" data-content="PAN">{{item.dppan}}</td> 

    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == false && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
    </td> 
    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">                                    
        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label (click)="isSelectLabel($event, item)" [(ngModel)]="item.checked" title="If You No Trade any Transaction Click Here." >
    </td> 
    <td scope="row" data-content="No Trade" *ngIf="item.dpname != nameEmailmerge && item.notrade =='No Trade'">                                    
        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
    </td> 
    <td scope="row" data-content="No Trade" *ngIf="item.dpname === nameEmailmerge && item.notrade =='No Trade'">                                    
        <input id="checkbox2" type="checkbox" class="checkbox" value="No Trade" #label checked  disabled title="No Trade Transaction">
    </td> 
    <td scope="row" data-content="No Trade" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved' && item.countCheck == true && item.previouslistcountval == 0 && item.notrade !='No Trade'">--</td> 
    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == false && item.previouslistcountval == 0">--</td> 
    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Pending'">--</td> 
    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == true && item.previouslistcountval != 0">--</td> 
    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Approved' && item.countCheck == false && item.previouslistcountval != 0">--</td>  
    <td scope="row" data-content="No Trade" *ngIf="item.status == 'Rejected'">--</td> 
    <td scope="row" data-content="Status" *ngIf="item.status == 'Pending'"><span class="badge badge-warning">{{item.status}}</span></td> 
    <td scope="row" data-content="Status" *ngIf="item.status == 'Approved'"><span class="badge badge-success">{{item.status}}</span></td> 
    <td scope="row" data-content="Status" *ngIf="item.status == 'Rejected'"><span class="badge badge-danger">{{item.status}}</span></td> 
    <td scope="row" data-content="Status" *ngIf="item.status == 'Incomplete'"><span class="badge badge-info">{{item.status}}</span></td> 
    <td data-content="Actions">
        <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
        <button class="edit-icon" *ngIf="is_access_write" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button>
        <button class="edit-icon" title="Download Application Form" (click)="openModalPdf(item.id)"><i class="fa fa-file-pdf-o"></i></button> 
    </td>  
    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Pending' || item.status == null" class="statusItem">                                       
        <button class="btn me-1 ls"  title="Approval" value="Approved"  (click)="openModalApproval(item.id)" ><img src="assets/images/check-mark.png"></button>
        <button class="btn me-1 ls"  title="Reject" value="Rejected"   (click)="openModalReject(item.id)" ><img src="assets/images/cross-button.png"></button>
    </td>   
    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Pending' || item.status == null" class="statusItem">  
        <button class="btn me-1 ls redirect" title="Pending"><img src="assets/images/pendingImg.png"></button>
     </td>   
    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Approved'" class="statusItem">                                       
        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
     </td>  
    <td data-content="#" *ngIf="complianceofficeraccess == true && item.dpname === nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
        <a [routerLink]="['/pit-compliance/trading-pre-clearance', item.id]" *ngIf="is_access_write && item.notrade !='No Trade'"><button class="btn me-1 ls" title=" Related trade - click here"><img src="assets/images/rediect-page.png"></button></a>
        <button class="btn me-1 ls" title="Apporoved Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
     </td>  
    <td data-content="#" *ngIf="complianceofficeraccess && item.dpname != nameEmailmerge && item.status == 'Approved'" class="statusItem">                                       
        <button class="btn me-1 ls" title="Approval" ><img src="assets/images/check-mark.png"></button>
     </td>  
    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Rejected'" class="statusItem">                                       
        <button class="btn me-1 ls re-app" title="Re-Approval" value="Approved" (click)="openModalReapproval(item.id)"><img src="assets/images/approve.png"></button>
     </td>  
    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Rejected'" class="statusItem">                                       
        <button class="btn me-1 ls" title="Rejected Pre-clearance Order" (click)="approvedPdf(item.id)"><img src="/assets/images/pdf-icon-64.png"></button>
    </td>  
    <td data-content="#" *ngIf="complianceofficeraccess == false && item.status == 'Incomplete'" class="statusItem">                                       
        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
     </td>  
    <td data-content="#" *ngIf="complianceofficeraccess && item.status == 'Incomplete'" class="statusItem">                                       
        <a [routerLink]="['/pit-compliance/edit-pre-clearance', item.id]" *ngIf="is_access_write"><button class="btn me-1 ls-incompleted" title="Incompleted"><img src="assets/images/editing.png"></button></a>
     </td>  
     
</tr>  -->
<!-- Container-fluid Ends -->





