import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-model-dp',
  templateUrl: './popup-model-dp.component.html',
  styleUrls: ['./popup-model-dp.component.scss']
})
export class PopupModelDpComponent implements OnInit {
  @Input() viewDbList;
  @Input() dpId;
  public active1 = 1;
  confidencialUrl: any;
  public active2 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity: any;
  preclearanceAccess: boolean;
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  openDpLabel: boolean;
  constructor(
    public activeModal: NgbActiveModal ) { 
      this.confidencialUrl = environment.url
    
    }

  ngOnInit() {
  this.timelineActivity = this.viewDbList.timelineActivity; 

  this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
  if (this.user_roles.preclearance_read == '2') {
    this.is_access_write = true;
    this.is_access_read = true;
    this.is_access_no = true;
  } else if (this.user_roles.preclearance_read == '1') {
    this.is_access_write = false;
    this.is_access_read = true;
    this.is_access_no = true;
  }

  let accessData = sessionStorage.getItem('model_access_controller');    
  let accessModelController = JSON.parse(accessData);
  if(accessModelController.dplabel_access == true || accessModelController.dplabel_access == 1){
     this.openDpLabel = true;
  }else{
     this.openDpLabel = false;
  }
  if(accessModelController.preclearance_access == true || accessModelController.preclearance_access == 1){
    this.preclearanceAccess = true;
  }else{
    this.preclearanceAccess = true;
  }

  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
