import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../company.service'
@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  button = 'Save';
  isLoading = false;
  companyCreateForm: FormGroup;
  submitted: boolean = false
  accessSuccess: string;
  accessUser: string;
  accessModelController: any;
  pretradecountaccess = false;
  modelAccess: any;
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  preclearanceAccess:boolean = false;
  constructor(private fb: FormBuilder, private companyService: CompanyService, private router:Router, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    this.accessUser = sessionStorage.getItem('access_user');

   }

  
  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.preclearance_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.preclearance_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }

    let accessData = sessionStorage.getItem('model_access_controller');    
    this.accessModelController = JSON.parse(accessData);

    this.companyCreateForm = this.fb.group({
      company_name: new FormControl ('', Validators.required),
      sub_status: new FormControl (''),
      expiryDate: new FormControl (''),
      email: new FormControl ('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
      registered_office: new FormControl ('', Validators.required),
      corporate_office: new FormControl (''),
      mobileno: new FormControl ('', [Validators.required, Validators.pattern("^((\\+?)|0)?[0-9\ \-]+$")]),
      landlineno: new FormControl (''),
      cin: new FormControl ('', Validators.required),
      GSTNumber: new FormControl (''),
      PANNumber: new FormControl (''),
      bse_scrip_code: new FormControl (''),
      nse_scrip_code: new FormControl (''),
      isin: new FormControl (''),
      compliance_officer_name: new FormControl ('', Validators.required),
      designation: new FormControl (''),
      complianceofficeremail: new FormControl ('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z/\| ]{2,}$')]),
      companyshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      preferenceshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      warrantsshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      convertibleshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      nonconvertibleshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      rightentitleshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      othersshares: new FormControl ('', Validators.pattern("^[0-9]*$")),
      customertype: new FormControl (''),
      predaycount: new FormControl ('7', [Validators.pattern("^[0-7]*$"), Validators.maxLength(1)]),
      installation_date: new FormControl ('')
    });

    //access expiry date
    if(this.accessUser == 'sdd@legalitysimplified.com'){
      var eleman = document.getElementById('expiryDate');
      eleman.removeAttribute("disabled");    
      var eleman2 = document.getElementById('customertype');
      eleman2.removeAttribute("disabled"); 
   
    }else{
      var eleman = document.getElementById('expiryDate');
      eleman.setAttribute("disabled", '');
      var eleman2 = document.getElementById('customertype');
      eleman2.setAttribute("disabled", '');

    }

    if(this.accessModelController.tradecount_access == true || this.accessModelController.tradecount_access == 1){
      this.pretradecountaccess = true;
    }else{
      this.pretradecountaccess = true;
    }
    if(this.accessModelController.preclearance_access == true || this.accessModelController.preclearance_access == 1){
      this.preclearanceAccess = true;
    }else{
      this.preclearanceAccess = true;
    }

  }
  cancelProject(){
    this.router.navigate(['/setup/company-profile']);
    this.companyCreateForm.reset();
  }
  onSubmit(){
    this.submitted = true;
    let idendiferCard = this.companyCreateForm.get('PANNumber').value;
    if(this.companyCreateForm.get('PANNumber').value != ''){        
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (!regex.test(idendiferCard)) {
        this.toastr.warning("Please Enter the Valid PAN.");
       // this.messageValid = 'Please Enter the Valid PAN.';
       return false;
      }
    }

    const emptyFileds = Object.keys(this.companyCreateForm.controls).filter(key =>this.companyCreateForm.controls[key].errors !== null)

    if(this.companyCreateForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.companyService.addCompany(this.companyCreateForm.value).subscribe(data =>{
        this.toastr.success("Company Profile Successfully created");
        this.isLoading = false;
        this.button = 'Save';
        this.router.navigate(['/setup/company-profile']);
        // if (this.insiderCreateForm.valid) {
        //   this.toastr.success("Insider details created", "Success!");
        //   this.router.navigate(['/insider/insider-list']);
        //   return;
        // } else {
        //   this.toastr.warning("Insider details not created", "Alert!");
        // }
      })
    }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
    }else{
      if(this.companyCreateForm.get('company_name').value == ''){
        this.toastr.warning("Please fill the Company Name.");
      }
      if(this.companyCreateForm.get('registered_office').value == ''){
        this.toastr.warning("Please fill the Registered Office Address.");
      }
      if(this.companyCreateForm.get('email').value == ''){
        this.toastr.warning("Please fill the Email ID.");
      }
      if(this.companyCreateForm.get('mobileno').value == ''){
        this.toastr.warning("Please fill the Contact Number.");
      }
      if(this.companyCreateForm.get('cin').value == ''){
        this.toastr.warning("Please fill the CIN Number.");
      }
      if(this.companyCreateForm.get('complianceofficeremail').value == ''){
        this.toastr.warning("Please fill the Compliance Officer Email ID.");
      }
       if(this.companyCreateForm.get('compliance_officer_name').value == ''){
        this.toastr.warning("Please fill the Compliance Officer Name.");
      }
    }
    }
   
  
}
