
<!--Seperate Project-->
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav-tabs tab-active2">
          <li [ngbNavItem]="1" >
            <a ngbNavLink>Active Projects/Events</a>
            <ng-template ngbNavContent>
            <div class="tab-pane fade show" id="home" role="tabpanel" aria-labelledby="home">
                <!-- Container-fluid starts -->
                <div class="container-fluid">
                    <div class="row" *ngIf = "is_access_no">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col-md-6 mb-3">                      
                                        <div class="form-group">
                                        <input type="text" class="form-control" id="filterString" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search Active Projects/Events by Name " 
                                            >
                                                                                        
                                    </div>
                                        </div>
                                    <!-- <h5>Basic Table</h5> -->
                                    <div class="list-btn" >
                                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                                        <a routerLink="/setup/project-add" *ngIf="is_access_write"><button class="btn btn-primary me-1" >New</button></a>
                                        <img src="assets/images/excel.png" title="Download Projects List" class="img-responsive excel-data" (click)="openExcelDisclaimer()">
                                        <div class="columnHide mb-3">
                                            <label for="hideShow">Filter Projects/Events List Columns</label>
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                                    [settings]="dropdownSettings1"
                                                    [data]="hidelist"
                                                    [(ngModel)]="selectedhideItems"
                                                    (onSelect)="onItemSelect($event,'column')"
                                                    (onDeSelect)="onItemDeSelect($event,'column')"
                                                    (onDeSelectAll)="onDeSelectAll($event,'column')"
                                                    (onSelectAll)="onSelectAll($event,'column')"
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <!--Filter-->
                                        <div class="columnHide mb-3">
                                            <label for="selectProject">Filter by Project Name</label>
                                        <div class="form-group">
                                            <ng-multiselect-dropdown [placeholder]="'Select Project'"
                                                [settings]="dropdownSettings1"
                                                [data]="dropdownList"
                                                [(ngModel)]="selected_project"
                                                (onSelect)="onItemSelect($event,'projectItem')"
                                                (onDeSelect)="onItemDeSelect($event,'projectItem')"
                                                (onDeSelectAll)="onDeSelectAll($event,'projectItem')"
                                                (onSelectAll)="onSelectAll($event,'projectItem')"
                                                >
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <a routerLink="/setup/deleted-project"><button class="btn btn-primary me-2" >Deleted Projects/Events List</button></a>
                                    </div>
                                </div>
                                <div class="card-body custom-datatable noscroll">
                                    <div class="custom-datatable">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="name">S.No.</th>
                                                    <th scope="col" sortable="name">ID No.</th>
                                                    <th scope="col" sortable="name" *ngIf="show_projectName">Project/Event Name <i class="fa fa-filter" (click)="filterData()"
                                                        aria-hidden="true" title="search Project"></i>
                                                    <div class="filter-form" *ngIf="visible">
                                                        <div class="form-group">                                             
                                                            <div class="ng-autocomplete">
                                                                <i class="fa fa-close" (click)="onBlur($event)" aria-hidden="true"></i>
                                                                <ng-autocomplete #auto [data]="projectNameFilter"
                                                                    [searchKeyword]="keyword" placeholder="Search"
                                                                    (selected)='selectEvent($event)'
                                                                    (inputChanged)='onChangeSearch($event)'
                                                                    (inputFocused)='onFocused($event)'
                                                                    (inputCleared)="onCleared($event)"
                                                                    (scrolledToEnd)='onScrolledEnd()'
                                                                    [(ngModel)]="nameFilterString" [itemTemplate]="itemTemplate"
                                                                    [notFoundTemplate]="notFoundTemplate" focus>
                                                                </ng-autocomplete>

                                                                <ng-template #itemTemplate let-item>
                                                                    <a [innerHTML]="item"></a>
                                                                </ng-template>

                                                                <ng-template #notFoundTemplate let-notFound>
                                                                    <div [innerHTML]="notFound"></div>
                                                                </ng-template>
                                                            </div>

                                                        </div>
                                                    </div></th>
                                                    <th scope="col" sortable="name" *ngIf="show_fromDate">Start Date</th>
                                                    <th scope="col" sortable="name" *ngIf="show_toDate">End Date</th>
                                                    <th scope="col" sortable="name">Actions</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr *ngFor = "let items of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                <td scope="row" data-content="ID No.">{{items.Record}}</td>  
                                                <td scope="row" data-content="Project/Event &#xa; Name" *ngIf="show_projectName">{{items.project_name}}</td>  
                                                <td scope="row" data-content="Start Date" *ngIf="show_fromDate">{{items.durationfrom | date:'dd-MM-yyyy'}}</td>  
                                                <td scope="row" data-content="End Date" *ngIf="show_toDate">{{items.durationto | date:'dd-MM-yyyy'}}</td>                                
                                                <td data-content="Actions">
                                                    <button class="edit-icon" (click)="openModal(items.id)" title="View Log"><i class="fa fa-eye"></i></button>
                                                    <a [routerLink]="['/setup/project-edit', items.id]" *ngIf="is_access_write"><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                                    <!-- <a [routerLink]="['/project/project-delete', items.id]" *ngIf="is_access_write"><button class="edit-icon"><i class="fa fa-trash"></i></button></a>   -->
                                                    <button class="edit-icon" *ngIf="is_access_write" (click)="openModalDelete(items.id)" title="Delete"><i class="fa fa-trash"></i></button></td>
                                                
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                                            <p>No Data Found</p>
                                        </div>
                                        
                                    <div class="d-flex justify-content-between p-2">
                                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf = "!is_access_no">
                        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                    </div>
                </div>
            </div>
            </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Completed Projects/Events</a>
                <ng-template ngbNavContent>
                  <div class="tab-pane fade show" id="budget" role="tabpanel" aria-labelledby="budget">
                    <!-- Container-fluid starts -->
                    <div class="container-fluid">
                        <div class="row" *ngIf = "is_access_no">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="col-md-6 mb-3">                      
                                            <div class="form-group">
                                            <input type="text" class="form-control" id="Search" [(ngModel)]="filterString2" autocomplete="off" (ngModelChange)="onFilterChange2()"  placeholder="Search Completed Projects/Events by Name" 
                                                >
                                                <!-- <input type="text" class="form-control" id="mobileNo" [(ngModel)]="searchValue"  (ngModelChange)="search()" placeholder="Search ..." 
                                                required > -->
                                                                    
                                        </div>
                                            </div>
                                        <!-- <h5>Basic Table</h5> -->
                                        <div class="list-btn" >
                                            <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                                            <!-- <a routerLink="/project/project-add" *ngIf="is_access_write"><button class="btn btn-primary me-1" >New</button></a> -->
                                            <div class="columnHide mb-3">
                                                <label for="hideShow">Filter Projects/Events List Columns</label>
                                                <div class="form-group">
                                                    <ng-multiselect-dropdown [placeholder]="'Select Hidden Column'"
                                                        [settings]="dropdownSettings1"
                                                        [data]="hidelist"
                                                        [(ngModel)]="selectedhideItems"
                                                        (onSelect)="onItemSelect($event,'column')"
                                                        (onDeSelect)="onItemDeSelect($event,'column')"
                                                        (onDeSelectAll)="onDeSelectAll($event,'column')"
                                                        (onSelectAll)="onSelectAll($event,'column')"
                                                        >
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                            <!--Filter-->
                                            <div class="columnHide mb-3">
                                                <label for="selectProject">Filter by Project/Event Name</label>
                                            <div class="form-group">
                                                <ng-multiselect-dropdown [placeholder]="'Select Project'"
                                                    [settings]="dropdownSettings1"
                                                    [data]="dropdownListCompleted"
                                                    [(ngModel)]="completed_project"
                                                    (onSelect)="onItemSelect($event,'completedProjectItem')"
                                                    (onDeSelect)="onItemDeSelect($event,'completedProjectItem')"
                                                    (onDeSelectAll)="onDeSelectAll($event,'completedProjectItem')"
                                                    (onSelectAll)="onSelectAll($event,'completedProjectItem')"
                                                    >
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <a routerLink="/setup/deleted-project"><button class="btn btn-primary me-2" >Deleted Projects List</button></a>

                                        </div>
                                    </div>
                                    <div class="card-body custom-datatable noscroll">
                                        <div class="custom-datatable">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" sortable="name">S.No.</th>
                                                        <th scope="col" sortable="name">ID No.</th>
                                                        <th scope="col" sortable="name" *ngIf="show_completedproject">Project/Event Name <i class="fa fa-filter" (click)="filterData2()"
                                                            aria-hidden="true"></i>
                                                        <div class="filter-form" *ngIf="visible2">
                                                            <div class="form-group">                                             
                                                                <div class="ng-autocomplete">
                                                                    <i class="fa fa-close" (click)="onBlur2($event)" aria-hidden="true"></i>
                                                                    <ng-autocomplete #auto [data]="completedprojectNameFilter"
                                                                        [searchKeyword]="keyword2" placeholder="Search"
                                                                        (selected)='selectEvent2($event)'
                                                                        (inputChanged)='onChangeSearch($event)'
                                                                        (inputFocused)='onFocused($event)'
                                                                        (inputCleared)="onCleared2($event)"
                                                                        (scrolledToEnd)='onScrolledEnd()'
                                                                        [(ngModel)]="nameFilterString2" [itemTemplate]="itemTemplate"
                                                                        [notFoundTemplate]="notFoundTemplate" focus>
                                                                    </ng-autocomplete>

                                                                    <ng-template #itemTemplate let-item>
                                                                        <a [innerHTML]="item"></a>
                                                                    </ng-template>

                                                                    <ng-template #notFoundTemplate let-notFound>
                                                                        <div [innerHTML]="notFound"></div>
                                                                    </ng-template>
                                                                </div>

                                                            </div>
                                                        </div></th>
                                                        <th scope="col" sortable="name" *ngIf="show_fromDate">Start Date</th>
                                                        <th scope="col" sortable="name" *ngIf="show_toDate">End Date</th>
                                                        <th scope="col" sortable="name">Actions</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor = "let items of filtered2 | slice: (page-1) * pageSize : page * pageSize; let i=index">
                                                        <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                                        <td scope="row" data-content="ID No.">{{items.Record}}</td>  
                                                        <td scope="row" data-content="Project/Event &#xa; Name" *ngIf="show_projectName">{{items.project_name}}</td>  
                                                        <td scope="row" data-content="Start Date" *ngIf="show_fromDate">{{items.durationfrom | date:'dd-MM-yyyy'}}</td>  
                                                        <td scope="row" data-content="End Date" *ngIf="show_toDate">{{items.durationto | date:'dd-MM-yyyy'}}</td>                                 
                                                    <td>
                                                        <button class="edit-icon" (click)="openModal(items.id)" title="View Log"><i class="fa fa-eye" ></i></button>
                                                        <a [routerLink]="['/setup/project-edit', items.id]" *ngIf="is_access_write"><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                                        <!-- <a [routerLink]="['/setup/project-delete', items.id]" *ngIf="is_access_write"><button class="edit-icon" title="Delete"><i class="fa fa-trash"></i></button></a>                                 -->
                                                        <button class="edit-icon" *ngIf="is_access_write" (click)="openModalDelete(items.id)" title="Delete"><i class="fa fa-trash"></i></button>
                                                     </td>
                                                    
                                                </tr>
                                                </tbody>
                                            </table>

                                            <div class="list-btn2" *ngIf="filtered2?.length =='' ">
                                                <p>No Data Found</p>
                                            </div>
                                            
                                        <div class="d-flex justify-content-between p-2">
                                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered2?.length"></ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf = "!is_access_no">
                            <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
                        </div>
                    </div>
                  </div>
                </ng-template>
            </li>
         
            
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>

