import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { InsiderListComponent } from './insider-list/insider-list.component';
import { InsiderAddComponent } from './insider-add/insider-add.component';
import { InsiderEditComponent } from './insider-edit/insider-edit.component';
import { InsiderDeleteComponent } from './insider-delete/insider-delete.component';
import { InsiderViewComponent } from './insider-view/insider-view.component';
import { DeletedInsiderModuleComponent } from './deleted-insider-module/deleted-insider-module.component';

const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: InsiderListComponent,
        data: {
          title: "Insiders",
          breadcrumb: "Insiders List"
        }
      }, 
      {
        path: 'insider-list',
        component: InsiderListComponent,
        data: {
          title: "Insiders",
          breadcrumb: "Insiders List"
        }
      },  
      {
        path: 'insider-add',
        component: InsiderAddComponent,
        data: {
          title: "Insiders",
          breadcrumb: "New"
        }
      },
      {
        path: 'insider-edit/:id',
        component: InsiderEditComponent,
        data: {
          title: "Insiders",
          breadcrumb: "Update"
        }
      },
      {
        path: 'insider-view',
        component: InsiderViewComponent,
        data: {
          title: "Insiders",
          breadcrumb: "View"
        }
      },
      {
        path: 'insider-delete',
        component: InsiderDeleteComponent,
        data: {
          title: "Insiders",
          breadcrumb: "Delete"
        }
      },
      {
        path: 'deleted-insider',
        component: DeletedInsiderModuleComponent,
        data: {
          title: "Insiders",
          breadcrumb: "Deleted Insiders List"
        }
      },
   
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class insiderRoutingModule { }
