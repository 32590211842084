



 <div class="modal-header">
   <h5 class="modal-title">View Company Profile</h5>
   <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
       <span aria-hidden="true">&times;</span>
   </button>
</div>
<div class="modal-body">
   <div class="container-fluid">
      <div class="row">
          <div class="col-sm-12">
              <div class="card">
                  
                  <div class="card-body custom-datatable noscroll">
                      <div class="custom-datatable">
                          <table class="table">                            
                                  <tbody *ngIf="viewCompanyList">
                                      <tr>
                                          <td scope="row">Company Name</td>
                                          <td scope="row">{{viewCompanyList.company_name}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Registered Office Address</td>
                                          <td scope="row">{{viewCompanyList.registered_office}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Corporate Office Address</td>
                                          <td scope="row">{{viewCompanyList.corporate_office}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Email ID</td>
                                          <td scope="row">{{viewCompanyList.email}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Contact Number</td>
                                          <td scope="row">{{viewCompanyList.mobileno}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">CIN Number</td>
                                          <td scope="row">{{viewCompanyList.cin}}</td>                                         
                                       </tr> 
                                                                             
                                       <tr>
                                          <td scope="row">ISIN Number</td>
                                          <td scope="row">{{viewCompanyList.isin}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">GST Number</td>
                                          <td scope="row">{{viewCompanyList.GSTNumber}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">PAN Number</td>
                                          <td scope="row">{{viewCompanyList.PANNumber}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">BSE Scrip Code</td>
                                          <td scope="row">{{viewCompanyList.bse_scrip_code}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">NSE Scrip Code</td>
                                          <td scope="row">{{viewCompanyList.nse_scrip_code}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Compliance Officer Name</td>
                                          <td scope="row">{{viewCompanyList.compliance_officer_name}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Designation</td>
                                          <td scope="row">{{viewCompanyList.designation}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Compliance Officer Email ID</td>
                                          <td scope="row">{{viewCompanyList.complianceofficeremail}}</td>                                         
                                       </tr> 
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Equity Shares</td>
                                          <td scope="row">{{viewCompanyList.companyshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total preference Shares</td>
                                          <td scope="row">{{viewCompanyList.preferenceshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Warrants</td>
                                          <td scope="row">{{viewCompanyList.warrantsshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Convertible Debentures</td>
                                          <td scope="row">{{viewCompanyList.convertibleshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Non-Convertible Debenture</td>
                                          <td scope="row">{{viewCompanyList.nonconvertibleshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Rights entitlements</td>
                                          <td scope="row">{{viewCompanyList.rightentitleshares}}</td>                                         
                                       </tr>
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true)">
                                          <td scope="row">Company Total Others Shares</td>
                                          <td scope="row">{{viewCompanyList.othersshares}}</td>                                         
                                       </tr> 
                                       <tr>
                                          <td scope="row">Customer Type</td>
                                          <td scope="row">{{viewCompanyList.customertype}}</td>                                         
                                       </tr> 
                                      
                                       <tr *ngIf="(preclearanceAccess == true && is_access_write == true) && viewCompanyList.predaycount != '' ">
                                          <td scope="row">Preclearance Trading Customize Day Count</td>
                                          <td scope="row">{{viewCompanyList.predaycount}}</td>                                         
                                       </tr> 
                                       <tr *ngIf="accessUser == 'sdd@legalitysimplified.com'">
                                          <td scope="row">Installation Date</td>
                                          <td scope="row">{{viewCompanyList.installation_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                       </tr>                                 
                                 
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
   </div>
</div>
<div class="modal-footer">
   <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
