import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject } from 'rxjs';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { PitComplianceService } from '../pit-compliance.service';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';

type AOA = any[][];

@Component({
  selector: 'app-add-benpos-report',
  templateUrl: './add-benpos-report.component.html',
  styleUrls: ['./add-benpos-report.component.scss']
})
export class AddBenposReportComponent implements OnInit {
  @ViewChild('benfattachment') benfattachment: ElementRef;
  @ViewChild('excelattachment') excelattachment: ElementRef;
  page = 1;
  pageSize = 10;
  button = 'Submit';
  isLoading = false;
  title = "FileUp";
  form: FormGroup;
  filePathName: string;
  fileData: any;
  excelFile: any;
  excelData: any;
  responseDate: any;
  banPassForm: FormGroup;
  pdfSrc:any;
  pdfBufferRender;
  localPDF:any;
  fileContent: any = '';
  show_field:boolean = false;
  show_excel:boolean = false;
  show_pdf:boolean = false;
  image: string;
  base64Output: string;
  textReader = [];
  valArray: any;
  public userArray: User[] = [];
  show_upload_file:boolean = false;
  fileTextReader: any;
  listCompany: any;
  isinNumber: any;
  benpossComparison: any;
  benposscurDate: any;
  benposspreDate: any;
  successMessage: any;
  failureMessage: any;
  aleartMessage:boolean = false;
  baseUrl = environment.apiUrl;
  benf_date: any;
  progress: number;
  progressValue:number;
  totalValue:number;
percentagevalue: number =0;
  totalPercentage: any;
  percentageHide:boolean = false;
  excelFileReader: any;
  maxSize: any;
  progressExcel: number;
  exprogressExcel: number;
  fileType: any;
  filelist: any[];
  file: any;
  arrayBuffer:any;
  arraylist:any;
  excelDatas = [];
  excelbenfDate: any;
  dp_panNo = [];
  preDayCount: any;
 constructor(private fb: FormBuilder, private pitService: PitComplianceService, private companyService: CompanyService, private router:Router,
  private toastr: ToastrService, private http: HttpClient, private dpService:DpModuleService){}

  ngOnInit(): void {
    this.banPassForm = this.fb.group({
      selectradiobtn: new FormControl('Excel'),
      files: [''],
      pdfreader:new FormControl(''),
      uploadfiletype: new FormControl(''),
      benf_date: new FormControl(''),
      textFileReader: new FormControl(''),
      file: new FormControl(''),
      isin_number: new FormControl(''),
      uploadFile: new FormControl(''),
      pdf:new FormControl(''),
      excel: new FormControl('')
    });
  
  //company Profile
  this.companyService.CompanyList(null).subscribe(
    res => {
      if(res.body['message'] == 'Invalid User.'){
        this.router.navigate(['/auth/login']);
      }else{
    let listCompany = res.body['data']     
    this.isinNumber = listCompany[0].isin;
      }
      },
      err => {
          console.log(err);
      }
  );
  // this.isinNumber = sessionStorage.getItem('isinNumber');
  // console.log("isinNumber", this.isinNumber)
  //comparison Benpass
  // this.pitService.comparisonbenpass(null).subscribe(
  //   res => {
  //     console.log("res", res['data'][0]);
  //     this.benpossComparison = res['data'];
  //     this.benposscurDate = res['data'][0].benfUpdateDate;
  //     this.benposspreDate = res['data'][0].preBenfDate;
  //   });
  
  this.pitService.benposdatelist(null).subscribe(
    res => {
      
      this.benpossComparison = res['data'];
  
    });
    //this.dplist();
   }
   camparisonBenpos(id){
  
     if(id){
      this.benpossComparison.map(item =>{
        if(item.id == id){
          this.benf_date = {'benf_date' : item.benf_date }        
        }
      })
      this.pitService.comparisonbenpass(this.benf_date).subscribe(
        res => {
          console.log("Data Successfully Send!..");
        });
    }
   }
  
  //Excel Report Read
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  
  onFileChange(evt: any) {
    /* wire up file reader */
    this.progressExcel = 1;
    this.excelFileReader = evt.target.files[0];
    this.maxSize = evt.target.files[0].size;  
    this.fileType = evt.target.files[0].type; 
   
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    //let fileReader = new FileReader(); 
    reader.readAsArrayBuffer(this.excelFileReader);  
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      //this.progressExcel = Math.round((100 / e.total) * e.loaded);
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellNF: false, cellDates: true });
      
      // var tableData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {range:3});
  
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
     
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];  
  
      /* save data */
      this.data =  <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: "yyyy-mm-dd hh:mm:ss" }));
      let reqArr = [];
     
      //console.log("data", this.data);
      if(this.data.length > 1) {
        let keys = this.data[0];
        for(let i=1;i< this.data.length;i++) {
          let reqObj = {};
          if(this.data.length > 1) {
            let keys = this.data[0];
            for(let i=1;i< this.data.length;i++) {
              let reqObj = {};
              this.data[i].map((valStr, index) => reqObj[keys[index]] = valStr);
             
              reqArr.push(reqObj);
            }
            
          }
        
          
        }
        
      }
         
      this.excelData = reqArr;
     
    };
    this.show_excel = true;
    reader.readAsBinaryString(target.files[0]);
    // let element = document.getElementById('excel-report');
    // element.className = 'exRep'
  }

  // dplist(){
  //  this.dpService.dpModuleList(null).subscribe(
  //     res => {
  //       this.dp_panNo = [];
  //       if(res['message'] == 'Invalid User.'){
  //         this.router.navigate(['/auth/login']);
  //       }else{
  //         let listDpModule = res['data'];

  //         listDpModule.map(item => {
  //           this.dp_panNo.push(item.dpPanNo);
           
  //           item.immediateDpRelative.map(items =>{
  //             this.dp_panNo.push(items.pan);
              
  //           })
  //         })
  //       }
  //     });
  //     this.addfile(event) 
  // }
  addfile(event){    
    this.exprogressExcel = 1;
  this.file= event?.target.files[0];     
  let fileReader = new FileReader(); 
  this.maxSize = event?.target.files[0].size;    
  fileReader?.readAsArrayBuffer(this.file);     
  fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);
      this.exprogressExcel = Math.round((100 / e.total) * e.loaded);
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
         
        this.arraylist = XLSX.utils.sheet_to_json(worksheet,{ header: 1, raw: false, dateNF: "yyyy-mm-dd hh:mm:ss" }); 
        
        let date = this.arraylist[1].toString().split(" ");
       this.excelbenfDate = date.pop();
        this.filelist = [];
        let reqArr = []       
            let reqObj = {};
            if(this.arraylist.length > 1) {
              let keys = this.arraylist[2];            
              
              for(let i=3;i< this.arraylist?.length;i++) {
               
                let reqObj = {};
                
                 this.arraylist[i]?.map((valStr, index) => reqObj[keys[index]] = valStr);
               
                reqArr.push(reqObj);
              }
              
            }
         
            // this.dp_panNo = [];
            // this.dpService.dpModuleList(null).subscribe(
            //   res => {
            //     this.dp_panNo = [];
            //     if(res['message'] == 'Invalid User.'){
            //       this.router.navigate(['/auth/login']);
            //     }else{
            //       let listDpModule = res['data'];
        
            //       listDpModule.map(item => {
            //         this.dp_panNo.push(item.dpPanNo);
                   
            //         item.immediateDpRelative.map(items =>{
            //           this.dp_panNo.push(items.pan);
                      
            //         })
            //       })
            //       console.log("dp_panNo", this.dp_panNo);

                  
            //         this.dp_panNo.map(data =>{        
            //           reqArr.map(item =>{
            //           if(data == item.Pan_No){
            //             console.log("hi")
            //             this.excelDatas.push(item);
            //             console.log("excelDatas", this.excelDatas)
            //           }
            //         })
            //       });
            //     }
            //   });   
       
        this.excelDatas = reqArr;
       
       
    
  } 
}
// max_input_time = 24000
// max_execution_time = 24000
// upload_max_filesize = 12000M
// post_max_size = 24000M
// memory_limit = 12000M 
  //drag and drop
  saveFile(files: FileList) {
    this.filePathName = files[0].name;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      this.fileData = bstr;

    };
    reader.readAsBinaryString(files[0]);
  }
  
  //Pdf read
  pdfOnload(event) {
    const pdfTatget: any = event.target;
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
        this.localPDF = this.pdfSrc;
        
      };
      
      this.pdfBufferRender = pdfTatget.files[0];
      reader.readAsArrayBuffer(pdfTatget.files[0]);
    
      // this.banPassForm.value.pdfreader = reader.result;
    }
    this.readThis(event?.target);
    // this.banPassForm.get('pdfreader').setValue(File);
  }
  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];
   
    var myReader:FileReader = new FileReader();
    // console.log(myReader);
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.image = btoa(myReader.result.toString());   
      this.banPassForm.value.pdfreader=myReader.result;
    }
  }
  onFileSelected(event) {
    
      this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.base64Output = base64;     
    });
  }
  convertFile(file : File) : Observable<string> {
  
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    // reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }
  //text file Reader
  fileTypeChanged(){
    const fileType = this.banPassForm.get('uploadfiletype')
    if(fileType?.value != ''){
       this.show_upload_file = true;
   
    }else{
      this.show_upload_file = false;
      this.banPassForm.get('benf_date').setValue('');
      this.banPassForm.get('uploadFile').setValue(''); 
     
    }
  }
  public onChange(fileList: any): void {
    this.progress = 1;
    let file = fileList.target.files[0];
    this.progressValue=file.size;
    this.totalValue=file.size;
    this.percentageHide = true;
    

    this.fileTextReader = fileList.target.files[0];
    let fileReader: FileReader = new FileReader();
    let self = this;
    this.textReader =[];
    fileReader.onloadend = function (x) {
    
      self.fileContent = fileReader.result;
      self.progress = Math.round((100 / x.total) * x.loaded);

    // self.fileContent.replace(/#,/g, '')
  //   self.fileContent.forEach((element,index)=>{
  //     if(element==2) delete self.fileContent[index];
  //  });
 
  let reqArr = [];
  const myArray = self.fileContent.split()
  const myArray3 = self.fileContent.replace(/##|~/g, ',');
  const myArray2 = myArray3.split("\n");
  
  //console.log("myArray2", myArray2)
  self.textReader = myArray2.map(item =>item)
  for (let index = 1; index < myArray2.length - 1; index++) {
    let row = myArray2[index].split(",");
    self.userArray.push(new User( parseInt( row[0], 10), row[1], row[2], row[8], row[3], row[9], row[10].trim()));
  }
  // console.log("popo",self.userArray[0]);
  
  for(let i =1; i<=self.textReader.length; i++){
    self.valArray = self.textReader[i];
   // const valArry2 = self.valArray.map(item =>({sno:item[0], cid:item[1],DpId: item[2]}))
  // console.log("valArray", self.valArray)
    
  }
  
  
  
   };
    fileReader.readAsText(file);
   
  }
  // convertFile(file : File) : Observable<string> {
  //   const result = new ReplaySubject<string>(1);
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(file);
  //   reader.onload = (event) => result.next(btoa(reader.result.toString()));
  //   return result;
  // }
  // changeListener($event) : void {   
  //   this.readThise($event.target); 
    
  // }
  
  // readThise(inputValue: any): void {
  //   var file:File = inputValue.files[0];   
  //   console.log("file", file)
  //   this.banPassForm.get('textFileReader').setValue('file');
  //   // var myReader:FileReader = new FileReader();   
  //   // myReader.readAsDataURL(file);
  //   // myReader.onloadend = (e) => {
  //   //   this.image = btoa(myReader.result.toString());   
  //   //   // this.dbCreateForm.value.image=myReader.result;
  //   //   // this.pathImage = this.dbCreateForm.value.image;
  //   // }
  // }
  resetBenposs(){
    // this.banPassForm.reset();    
    this.banPassForm.get('pdf').setValue('');
    this.banPassForm.get('uploadfiletype').setValue(''); 
    this.banPassForm.get('benf_date').setValue('');
    this.banPassForm.get('uploadFile').setValue(""); 
    this.banPassForm.get('excel').setValue("");
    if(this.banPassForm.value.selectradiobtn == "Text File"){
      this.benfattachment.nativeElement.value ="";
      this.progressExcel = 0;
    }else if(this.banPassForm.value.selectradiobtn == "Excel"){
      this.excelattachment.nativeElement.value ="";
      this.exprogressExcel = 0;
    }   
    this.fileTextReader = [];
    this.file = [];
    this.show_field = false;
    this.aleartMessage = false;
    this.progress = 0;
    
   
  }
  onSubmit(){
    // if(this.banPassForm.value.selectradiobtn == "Excel"){
    //   const allowed_type = ['application/xslx'];
    //   if(this.fileType !== allowed_type){
    //     this.toastr.warning("Pleace Attached the Correct Format File")   
    //     return false;
    //   }else if(this.maxSize > 5000 * 1024){
    //     this.toastr.warning("File exceeds the maximum size")   
    //     return false;
    //   }
    // }
  if(this.banPassForm.value.selectradiobtn == "Text File"){
    if(this.banPassForm.value.uploadfiletype == ""){
      this.toastr.warning("Please fil the Upload File Type")   
      return false;
    }else if(this.banPassForm.value.benf_date == ""){
      this.toastr.warning("Please choose the Upload Benpos File Date")   
      return false;
    }else if(this.banPassForm.value.uploadFile == ""){
      this.toastr.warning("Please choose the Upload File")   
      return false;
    }
  }
  if(this.banPassForm.value.selectradiobtn == "Excel"){
     if(this.banPassForm.value.benf_date == ""){
      this.toastr.warning("Please choose the Upload Benpos File Date")   
      return false;
    }else if(this.banPassForm.value.excel == ""){
      this.toastr.warning("Please choose the Excel Upload File")   
      return false;
    }
  }
  if(this.banPassForm.value.selectradiobtn == "PDF"){
    if(this.banPassForm.value.pdf == ""){
     this.toastr.warning("Please choose the pdf Upload File")   
     return false;
   }
 }

  this.button = 'Processing...';
  this.isLoading = true;
  //this.banPassForm.value.files= this.excelData;
  let formData = new FormData();
  formData.append('upload' , this.fileTextReader);
  formData.append('benf_date', this.banPassForm.value.benf_date);
  formData.append('selectradiobtn', this.banPassForm.value.selectradiobtn);
  formData.append('uploadfiletype', this.banPassForm.value.uploadfiletype);
  formData.append('pdfreader', this.pdfBufferRender); 
  formData.append('isinNumber', this.isinNumber);
  formData.append('excelUpload', this.file);
  formData.append('excelbenfDate', this.excelbenfDate);
  formData.append('excelDatas', JSON.stringify(this.excelDatas));
  //this.banPassForm.value.textreader = this.fileTextReader;
  
  this.pitService.sendJSONToServer(formData).subscribe(
    res => {
      this.responseDate =res;

      this.successMessage = this.responseDate?.Successmsg;
      this.failureMessage = this.responseDate?.Failuremsg;
     
     if(this.responseDate?.success != '0'){
      this.isLoading = false;
      this.button = 'Submit'; 
      this.aleartMessage = true;
  
  
      this.toastr.success(this.responseDate?.message)
                  
      //window.location.reload();
      
      
     
    }else{
      this.isLoading = false;
      this.button = 'Submit';
      this.aleartMessage = false;
      this.toastr.warning(this.responseDate?.message);
      
    }  
  
    },
    err => {
      console.log(err);
    }
  )
    
  }
  // onFilter() {
      
  //   let queryString = '';
  //   let url = this.baseUrl + 'benposexcel?';
  //   let tokenStr = sessionStorage.getItem('access_token');
  //   if(tokenStr){
  //     tokenStr = tokenStr.replace('Bearer ', '');
  //   }
    
  //   // if(this.filterForm.value.dateOfSharing || this.filterForm.value.toDate || this.filterForm.value.projects || this.filterForm.value.sender || this.filterForm.value.receiver)  {
  //   //   queryString = 'fromDate=' + this.filterForm.value.dateOfSharing + '&toDate=' + this.filterForm.value.toDate + '&projectName=' + this.filterForm.value.projects + '&sender=' + this.filterForm.value.sender + '&recipient=' + this.filterForm.value.receiver;
  //   //  console.log("queryString", queryString)
  //   //   queryString = queryString + '&authToken='+btoa(tokenStr);
      
  //   // } else {
  //     queryString = queryString + 'authToken='+btoa(tokenStr);
  //   // }
  //   var element = document.createElement('a');
  //   element.setAttribute('href', url + queryString);
  //   element.setAttribute('download', 'sample.xls');
  
  //   element.style.display = 'none';
  //   document.body.appendChild(element);
  
  //   element.click();
  
  //   document.body.removeChild(element);
  
     
  // }
  //radio Button
  selectedButton: string="excel";        
  
  radioSelect(e: string): void {
      this.selectedButton = e; 
      if(this.selectedButton == "excel"){
        this.banPassForm.get('pdf').setValue('');
        this.banPassForm.get('uploadfiletype').setValue(''); 
        this.banPassForm.get('benf_date').setValue('');
        this.banPassForm.get('uploadFile').setValue(''); 
        this.show_field = false;
        this.show_excel = true;
        this.show_pdf = false;
        this.show_upload_file = false;
      }else if(this.selectedButton == "pdf"){
        this.banPassForm.get('excel').setValue('');
        this.banPassForm.get('uploadfiletype').setValue(''); 
        this.banPassForm.get('benf_date').setValue('');
        this.banPassForm.get('uploadFile').setValue(''); 
        this.show_field = false;
        this.show_excel = false;
        this.show_pdf = true;
        this.show_upload_file = false
      }else{
        this.banPassForm.get('pdf').setValue('');
        this.banPassForm.get('excel').setValue('');       
        this.show_excel = false;
        this.show_pdf = false;
        if(this.banPassForm.value.textreader != ''){
          this.show_field = true;
        }else{
          this.show_field = false;
        }
      }
  }  
  
  isSelected(name: string): boolean {  
  
      if (!this.selectedButton) {   
          return false;  
      }    
  
      return (this.selectedButton === name); 
  } 
  //radio Button
  
  }
  export class User{
    sNo: number;
    id: number;
    cid: String;
    dpId:string
    senderName:string;
    recipientName:string;
    address:string
    constructor(sNo: number, id: number, cid: String, senderName:string, dpId:string, recipientName:string, address:string){
      this.sNo = sNo;
      this.id = id;
      this.cid = cid;
      this.dpId = dpId
      this.senderName = senderName;
      this.recipientName = recipientName;
      this.address = address;
    }
  

}
