import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, 
UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login/login.service';
import { AuthlogService } from './authlog.service';
// import { AuthService } from './../shared/services/firebase/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    public router: Router,
    private authService: AuthlogService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn !== true) {
      // window.alert("Access not allowed!");
      this.router.navigate(['/auth/login'])
    }
    return true;
    
  }
  
       
}