import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { MasterService } from '../../master.service'

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  button = 'Save';
  isLoading = false;
  submitted: boolean = false; 
  projectCreateForm: FormGroup;
  selectedOthers: any;
  othersSelect: boolean = false;
  othersSelectnature: boolean = false;
  selectedOthersupsi: any;
  accessSuccess: string;
  listRoleName: any;
  rollListarray = [];
  category: { roles: any[]; };
  roleNameFilter = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  constructor(private fb: FormBuilder, private masterService: MasterService, public router: Router, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

   createRoleList(){
    return this.fb.group({roleListItem: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.rollListarray = [];
    this.roleNameFilter = [];
    this.masterService.getRoles(null).subscribe(data =>{
      let resp = data.body['data'];
      
     const listRoleName = resp.map(({id,role_name })=>({id, itemName: role_name}))
     this.dropdownList = [];
     this.roleNameFilter = [];
     listRoleName.forEach(result =>this.dropdownList.push(result)) 
     from(this.dropdownList)
     .pipe(
       tap(item => item),
       distinct(d => d)
     )
     .subscribe(r => this.roleNameFilter.push(r));
    })

    // const controlArray = this.rollListarray.map(c => new FormControl(false));
    // controlArray[0].setValue(true);

    this.projectCreateForm = this.fb.group({
      project_name: new FormControl ('', [Validators.required,Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
      durationfrom: new FormControl ('', Validators.required),
      durationto: new FormControl ('', [this.dateRangeValidator]),
      // roleListItem: this.fb.array([this.createRoleList()]),
      roleListItem: new FormControl('')
    });

    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select All Applicable User Roles',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };


  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.projectCreateForm && this.projectCreateForm.get("durationfrom").value;
    const to = this.projectCreateForm && this.projectCreateForm.get("durationto").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
 //Multi select box
 onItemSelect(item: any) {
  
}
OnItemDeSelect(item: any) {

}
onSelectAll(items: any) {
 
}
onDeSelectAll(items: any) {
  
}
//multi select box

  // get categoryArray(): FormArray {
  //   return this.projectCreateForm.get("roleListItem") as FormArray;
  // }
  // onChange(index: number, id: string, role: string, isChecked: boolean) {
  //   if (isChecked) {
   
  //     this.projectCreateForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem.push({ id: id, role: role });


  //   } else {
  //     //this.projectCreateForm.controls.roleListItem.value[index].rollListarray.pop({ id: _id, name: name });
  //     this.projectCreateForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem = this.projectCreateForm.controls.roleListItem.value[
  //       index
  //     ].roleListItem.filter(item => id !== item.id);
  //   }
  // }

  // getcategory() {
  //   this.category = {
    
  //       roles: [ { role: "Admin", id: "1" }] 

  //   };
  //   // this.projectCreateForm.controls["name"].setValue(this.category.name);
  //   for (let i = 0; i < this.rollListarray.length; i++) {
  //     if (i !== 0) {
  //       const control = <FormArray>(
  //         this.projectCreateForm.controls["roleListItem"]
  //       );
  //       control.push(this.createRoleList());
  //     }
  
  //   }
  // }

  // inputChecked(i: any, data: any) {
  //   let checked = false;
  //   // console.log(this.rollListarray[i]);
  //   //console.log('data = ', data);
  //   for (
  //     let l = 0;
  //     l < this.rollListarray.length;
  //     l++
  //   ) {
  //     let temp = this.rollListarray[i];
  //     //console.log('inside =',temp);
  //     if (temp.role == data.role && temp.id == data.id) {
  //       checked = true;
  //     }
  //   }
  //   return checked;
  // }

  cancelProject(){
    this.router.navigate(['/setup/project-list']);
    this.submitted = false;
    this.projectCreateForm.reset();
  }
  onSubmit(){
    if(this.projectCreateForm.get('durationto').value != ''){
      if(this.projectCreateForm.get('durationfrom').value > this.projectCreateForm.get('durationto').value){
        this.toastr.warning("Start Date cannot be greater than End date.");
        return false;
      }
    }
    const emptyFileds = Object.keys(this.projectCreateForm.controls).filter(key =>this.projectCreateForm.controls[key].errors !== null)
   
    this.submitted = true;
    if(this.projectCreateForm.status !== 'INVALID'){
      this.isLoading = true;
  this.button = 'Processing';
    this.masterService.addProject(this.projectCreateForm.value).subscribe(data =>{
      if(data.success != '2'){     
        this.toastr.success(" Project/Event Successfully created");
        this.button = 'Save';
        this.isLoading = false;
        this.router.navigate(['/setup/project-list']);
    }else{
      this.button = 'Save';
      this.isLoading = false;
      this.toastr.warning("Project Already Exist!");
    }
    })
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fields.");
    return false;
  }else{
    if(this.projectCreateForm.get('project_name').value == ''){
      this.toastr.warning("Please fill the Project/Event Name.");
    }else if(this.projectCreateForm.get('durationfrom').value == ''){
      this.toastr.warning("Please fill the Start Date.");
    }
  }
  }
}
