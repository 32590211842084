<div #popup class="model-popup">
    <div class="modal-header">
        <h5 class="modal-title">DP Mail Template</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
     
          <div class="row">
          <div [innerHTML]="mailTemplate"></div> 
          
          </div>
    
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div>
    
</div>