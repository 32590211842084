import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.scss']
})
export class ViewCustomerComponent implements OnInit {
  @Input() viewCustomerList;
  public active1 = 1;
  page = 1;
  pageSize = 10;
  timelineActivity: any;
  constructor(
    public activeModal: NgbActiveModal, private activateRouter: ActivatedRoute, private customerService: CustomerService,
  ) { }

  ngOnInit() {
   this.timelineActivity = this.viewCustomerList.timelineActivity; 

  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
