<div class="container-fluid">
    <div class="row" >
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                        <input type="text" class="form-control" id="filterString"  placeholder="Search Company Rules" >                                      
                    </div>
                        </div>
                    <div class="list-btn" >
                        <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                        <a routerLink="/setup/add-rules" *ngIf="filtered?.length == ''"><button class="btn btn-primary me-1" >New</button></a>                  
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" sortable="name">S.No.</th>
                                    <th scope="col" sortable="name" >Rule Name</th>
                                    <th scope="col" sortable="name">Actions</th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                                <td scope="row" data-content="S.No.">{{(i+1) + (page-1) * pageSize }}</td>
                                <td scope="row" data-content="Rule Name">{{item.rule_name}}</td>                                
                                <td data-content="Actions">
                                    <button class="edit-icon" title="View Log" (click)="openModal(item.id)"><i class="fa fa-eye"></i></button>
                                    <a [routerLink]="['/setup/edit-rules', item.id]" ><button class="edit-icon" title="Update"><i class="fa fa-pencil-square-o"></i></button></a>
                                    <button class="edit-icon" title="Delete" (click)="openModalDelete(item.id)"><i class="fa fa-trash"></i></button></td>
                                
                            </tr>
                            </tbody>
                        </table>

                        <div class="list-btn2" *ngIf="filtered?.length =='' ">
                            <p>No Data Found</p>
                        </div>
                        
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</div>