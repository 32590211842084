<div class="modal-header">
    <h5 class="modal-title">Log Transaction</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="errorMessageList" *ngIf="this.accessModelController.hidecolumn_access == true || this.accessModelController.hidecolumn_access == 1">
                        <p *ngIf="errorSharesMessage">You are reporting an increased trade of {{sharesCount}} shares. This Transaction is greater than the pre-clearance value.</p>
                        <p *ngIf="errorValuesMessage">You are reporting that the Price per share is more than proposed price of trade.  The overall transaction Value is greater than approved Value.</p>
                    </div><br>
                    <div class="errorMessageList" *ngIf="this.accessModelController.hidecolumn_access == false || this.accessModelController.hidecolumn_access == 0">
                        <p *ngIf="errorSharesMessage">You are reporting an increased trade of {{sharesCount}} shares. This Transaction is greater than the pre-clearance value.</p>
                    </div><br>
                    <div class="custom-datatable1">
                        <h4 style="font-size: 19px;">Are you sure you want to log this transaction?</h4>
                        <div class="btn-bottom">
                            <button class="btn btn-primary me-1" (click)="onSubmit()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1" (click)="closeModal('close')">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
