<div class="modal-header">
    <h5 class="modal-title">Pre Clearance Reject Status </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1">
                        <form [formGroup]="pcReasonRejectForm" class="needs-validation" >
                            <div class="row">
                                <h4 style="font-size: 19px;">Are you sure want to Reject Pre Clearance?</h4>
                                <div class="form-group"> 
                                    <textarea class="form-control" #reason placeholder="Enter the Reason for Reject" rows="4" autocomplete="off" formControlName="rejectreason"> </textarea>
                                </div>
                            </div>
                                <div class="btn-bottom">
                                    <button class="btn btn-primary me-1" value="Rejected" #reject (click)="rejectStatus()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                                    <button class="btn btn-primary me-1"  (click)="closeModal('close')">Cancel</button>
                                </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
