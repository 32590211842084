import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../master.service';

@Component({
  selector: 'app-edit-company-rules',
  templateUrl: './edit-company-rules.component.html',
  styleUrls: ['./edit-company-rules.component.scss']
})
export class EditCompanyRulesComponent implements OnInit {
  editCompanyRulesForm: FormGroup;
  submitted: boolean = false;
  button = 'Update';
  isLoading = false;
  accessSuccess: string;
  accessUser: string;
  companyId: any;
  constructor(private fb: FormBuilder, private masterService: MasterService, private router:Router, private toastr: ToastrService,
    private activateRouter:ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
    this.accessUser = sessionStorage.getItem('access_user');

   }

  //rules confimation
  createDetailOfRules(){
    return this.fb.group({
      id: new FormControl(''),
      rules_condition: new FormControl(''),
      rules_setting: new FormControl(''),
      rules_value: new FormControl(''),
    })
  }
  addInputControlsRules(){
    // this.procedureCont = true;
    const add = this.editCompanyRulesForm.get('rules_model') as FormArray;
    add.push(this.createDetailOfRules())  
   
  }
  removeInputControlsRules(idx: number){
    // const add = this.editCompanyRulesForm.get('rules_model') as FormArray;  
    // add.removeAt(idx);  
    // if(this.editCompanyRulesForm.get('rules_model').value.length <= 1){
    //   const add = this.editCompanyRulesForm.get('rules_model') as FormArray;    
    //  add.reset();
    
    // }else{
      const add = this.editCompanyRulesForm.get('rules_model') as FormArray;  
      add.removeAt(idx); 
    //} 
  }
  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.companyId = {'id' : data['id']};      
    })
    if(this.companyId['id'] !==''){
      this.masterService.editCompanyRules(this.companyId)
      .toPromise().then(result =>{  
      let rules_model = result.data[0].rules_model ;
        result.data[0].rules_model = result.data[0].rules_model;
        if( rules_model &&  rules_model.length > 1){
          for(let i = 1;i<  rules_model.length; i++ ) {
            this.addInputControlsRules();
          }
        }      
        this.editCompanyRulesForm?.patchValue(result.data[0]);      
        
      })
    this.editCompanyRulesForm = this.fb.group({
      rule_name: new FormControl('', Validators.required),
      rules_model: new FormArray([this.createDetailOfRules()]),
      rule_status: new FormControl('', Validators.required)
    });
  }
  }
  cancelrules(){
    this.router.navigate(['/setup/rules-list']);
    this.editCompanyRulesForm.reset();
  }
  onSubmit(){
    this.submitted = true;
    if(this.editCompanyRulesForm.get('rules_model')['controls'].length > 0){
      for(let i=0; i<this.editCompanyRulesForm.get('rules_model')['controls'].length; i++){
        if(this.editCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_condition.value == ''){
           this.toastr.warning("Please Enter the "+ [i + 1] + " row Rule Condition.");          
           return false;          
        }else if(this.editCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_setting.value == ''){
          this.toastr.warning("Please Enter the "+ [i + 1] + " row Setting.");          
          return false;          
       }else if(this.editCompanyRulesForm.get('rules_model')['controls'][i]['controls'].rules_value.value == ''){
        this.toastr.warning("Please Enter the "+ [i + 1] + " row Value.");          
        return false;          
     }
      }
    }
    this.editCompanyRulesForm.value.id = this.companyId['id'];
    const emptyFileds = Object.keys(this.editCompanyRulesForm.controls).filter(key =>this.editCompanyRulesForm.controls[key].errors !== null)
    if(this.editCompanyRulesForm.status !== 'INVALID'){
      this.isLoading = true;
      this.button = 'Processing';
      this.masterService.updateCompanyRules(this.editCompanyRulesForm.value).subscribe(data =>{
      this.toastr.success("Company Rules Successfully Updated");
      this.isLoading = false;
      this.button = 'Update';
      this.router.navigate(['/setup/rules-list']);
      
    })
  }else if(emptyFileds.length > 1){
      this.toastr.warning("Please fill all Mandatory fileds.");
      return false;
  }else{
    if(this.editCompanyRulesForm.get('rule_name').value == ''){
      this.toastr.warning("Please fill the Rule Name.");
    }else  if(this.editCompanyRulesForm.get('rule_status').value == ''){
      this.toastr.warning("Please Select Rule Approved/rejected.");
      return false;
    }
  }
  }

}
