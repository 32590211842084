import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/master/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pre-form-ccomponent',
  templateUrl: './pre-form-ccomponent.component.html',
  styleUrls: ['./pre-form-ccomponent.component.scss']
})
export class PreFormCComponentComponent implements OnInit {
  @Input() pdfTradingFormList;
  @Input() precleranceEditView;
  public active1 = 1;
  pdfFormTrading: FormGroup;
  getIdDp: any;
  getformTypes: any;
  baseUrl = environment.apiUrl;
  companyName: any;
  isinNumber: any;
  cinNumber: any;
  prename: any;
  preemail: any;
  companyshares: any;
  constructor(
    public activeModal: NgbActiveModal, private router:Router, private fb: FormBuilder, private toastr: ToastrService,
    private companyService: CompanyService ) { }

  ngOnInit() {
  
  if(this.precleranceEditView.application_for == 'Self'){
    this.getformTypes = 'Form C';
  }else{
    this.getformTypes = 'Form D';
  }

  let items = this.pdfTradingFormList.name?.split('- ');
  this.prename = items[0];
  this.preemail = items[1];
 this.getIdDp = this.pdfTradingFormList.id;
    //company details
this.companyService.CompanyList(null).subscribe(
  res => {
    if(res.body['message'] == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
  let listCompany = res.body['data']     
      // this.complianceOfficerEmail = listCompany[0].complianceofficeremail;    
      // this.complianceOfficerName = listCompany[0].compliance_officer_name;    
      // this.complianceOfficerDesignation = listCompany[0].designation;    
      this.companyName = listCompany[0].company_name; 
      this.isinNumber = listCompany[0].isin; 
      this.cinNumber = listCompany[0].cin; 
      this.companyshares = listCompany[0].companyshares; 
      console.log(listCompany[0])
    }
    },
    err => {
        console.log(err);
    }
);


  }
  // revertInsiderId(){
  //   this.pdfFormTrading.value.id = this.getIdDp;
  //   this.pdfFormTrading.value.formTypes = this.getformTypes;
  //   this.dpService.revertDpModule(this.pdfFormTrading.value).subscribe(data =>{
  //     // if(data.message = "formTypes exists!"){
  //     //   this.toastr.warning("Already Exist the formTypes!");
  //     //   this.activeModal.close(event);
  //     // }else{
  //     this.toastr.success("DP Deleted data Updated ", "Success!");
  //     this.activeModal.close(event);
  //     this.router.navigate(['/dp/dp-list']);
  //     //}
  //   });
  // }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onFilterPdf() {
    
    let queryString = '';
    let url = this.baseUrl + 'preClearancePdfFormc?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.getIdDp || this.cinNumber || this.prename || this.preemail || this.companyshares)  {
      queryString = 'postId=' + this.getIdDp + '&companyName=' + this.companyName + '&isinNumber=' + this.isinNumber + '&cinNumber=' + this.cinNumber + '&name=' + this.prename + '&email='+ this.preemail + '&companyshares=' + this.companyshares;
      queryString = queryString + '&authToken='+btoa(tokenStr);
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    this.activeModal.close(event);
     
  }


}
