<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>UPDATE PRE CLEARANCE</h5>
                    <a routerLink="/pit-compliance/list-pre-clearance"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <div *ngIf="showPreclearance">
                        <form [formGroup]="pitClearanceEditForm" class="needs-validation" (ngSubmit)="onSubmit()">
                            <div class="row">
                            
                            <div class="row mb-3 row1">
                                    <div class="col-md-5">
                                        <label for="dpName">a) Name of designated person</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <!-- <div class="form-group">                    
                                            <input type="text" class="form-control" id="dpName" placeholder="Enter the Name of designated person" autocomplete="off" formControlName="dpName"> 
                                        </div> -->
                                        <ng-select [items]="dpNameFilter"
                                                bindLabel="" #dpname
                                                placeholder="Select Name of designated person"
                                                [(ngModel)]="selectedDpName" formControlName="dpName"
                                                (change)="changeFn($event)"                                         
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.dpName.touched) && pitClearanceEditForm.controls.dpName.errors }" required>
                                            </ng-select>
                                            <div *ngIf="(submitted || pitClearanceEditForm.controls.dpName.touched) && pitClearanceEditForm.controls.dpName.errors?.required"
                                            class="text text-danger">
                                            Name of designated person is required.
                                            </div>
                                        </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">b) PAN </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dppan"  autocomplete="off" required placeholder="PAN" formControlName="dppan" [(ngModel)]="panItem" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.dppan.touched) && pitClearanceEditForm.controls.dppan.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceEditForm.controls.dppan.touched) && pitClearanceEditForm.controls.dppan.errors?.required"
                                            class="text text-danger">
                                            PAN Number is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">c) Designation  </label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="dpdesignation"  autocomplete="off" placeholder="Designation " required formControlName="dpdesignation" [(ngModel)]="dpDesignation" disabled
                                            [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.dpdesignation.touched) && pitClearanceEditForm.controls.dpdesignation.errors }"> 
                                            <div *ngIf="(submitted || pitClearanceEditForm.controls.dpdesignation.touched) && pitClearanceEditForm.controls.dpdesignation.errors?.required"
                                            class="text text-danger">
                                            Designation is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">d) Department</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group">                    
                                            <input type="text"  class="form-control" id="department"  autocomplete="off" placeholder="Department" formControlName="department"> 
                                        
                                        </div>
                                    </div>
                                </div> -->
                                

                                <div class="row mb-3">
                                    <div class="col-md-5">
                                        <label for="dpName">d) Application for</label>
                                    </div>
                                    <div class="col-md-7 col-lg-5">
                                        <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline1" value="Self" (click)="radioSelect('Self')" formControlName="application_for" >
                                                <label for="radioinline1" class="mb-0">Self</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="radio1" id="radioinline2" value="Relative" (click)="radioSelect('Relative')" formControlName="application_for" >
                                                <label for="radioinline2" class="mb-0">Relative</label>
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected('Self')">
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelected('Relative')">
                                        <h6>Name of Relative & Relationship (if transaction on behalf of immediate relatives)</h6>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">A) Name of Relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <ng-select [items]="immediateRelativeList"
                                                bindLabel="" 
                                                placeholder="Select Name of Relative"
                                                [(ngModel)]="selectedimmediateRelativeList" formControlName="relativename"
                                                (change)="changeFnImmediate($event)" >
                                            </ng-select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5"> 
                                                <label for="dpName">B) Relationship </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="dpRelationship"  autocomplete="off" [(ngModel)]="immediateRelative" disabled placeholder="Relationship" formControlName="dpRelationship"> 
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="dpName">C) PAN of relative</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">                    
                                                    <input type="text"  class="form-control" id="relativepan"  autocomplete="off" [(ngModel)]="immediatePan" disabled placeholder="PAN of relative" formControlName="relativepan"> 
                                                
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="proposedTrans">
                                    <h4 style="text-decoration: underline;">Proposed Transaction in Securities of the Company</h4>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">e) Folio No. / DP ID / Client ID No </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="dp_client_id"  autocomplete="off" placeholder="Folio No. / DP ID / Client ID No" formControlName="dp_client_id" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.dp_client_id.touched) && pitClearanceEditForm.controls.dp_client_id.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.dp_client_id.touched) && pitClearanceEditForm.controls.dp_client_id.errors?.required"
                                                class="text text-danger">
                                                Folio No. / DP ID / Client ID No is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">f) Name of Depository </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="nameofdepository"  autocomplete="off" placeholder="Name of Depository" formControlName="nameofdepository" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.nameofdepository.touched) && pitClearanceEditForm.controls.nameofdepository.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.nameofdepository.touched) && pitClearanceEditForm.controls.nameofdepository.errors?.required"
                                                class="text text-danger">
                                                Name of Depository is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">g) Number of securities held as on date </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="noofsecurities"  autocomplete="off" placeholder="Number of securities held as on date" formControlName="noofsecurities" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.noofsecurities.touched) && pitClearanceEditForm.controls.noofsecurities.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.noofsecurities.touched) && pitClearanceEditForm.controls.noofsecurities.errors?.required"
                                                class="text text-danger">
                                                Number of securities is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.noofsecurities.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">h) Type of Security </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="typeofsecurity"  autocomplete="off" placeholder="Type of Security" formControlName="typeofsecurity" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.typeofsecurity.touched) && pitClearanceEditForm.controls.typeofsecurity.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.typeofsecurity.touched) && pitClearanceEditForm.controls.typeofsecurity.errors?.required"
                                                class="text text-danger">
                                                Type of Security is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">i) Proposed date of dealing in securities </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="date"  class="form-control" id="proposeddate"  autocomplete="off" placeholder="DD-MM-YYYY" formControlName="proposeddate" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.proposeddate.touched) && pitClearanceEditForm.controls.proposeddate.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.proposeddate.touched) && pitClearanceEditForm.controls.proposeddate.errors?.required"
                                                class="text text-danger">
                                                Proposed date of dealing in securities is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">j) Nature of proposed transaction </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" formControlName="nature_of_proposed" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.nature_of_proposed.touched) && pitClearanceEditForm.controls.nature_of_proposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Sale">Sale</option>
                                                    <option value="Purchase">Purchase</option>
                                                    <option value="Subscription">Subscription</option>                                                                    
                                                </select>  
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.nature_of_proposed.touched) && pitClearanceEditForm.controls.nature_of_proposed.errors?.required"
                                            class="text text-danger">
                                            Nature of proposed transaction is required.
                                            </div>  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">k)Estimated number of securities proposed to be acquired/ subscribed/ sold	 </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="Estimatednumbersecur"  autocomplete="off" placeholder="Estimated number of securities" formControlName="Estimatednumbersecur"
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.Estimatednumbersecur.touched) && pitClearanceEditForm.controls.Estimatednumbersecur.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.Estimatednumbersecur.touched) && pitClearanceEditForm.controls.Estimatednumbersecur.errors?.required"
                                                class="text text-danger">
                                                Estimated number of securities proposed is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.Estimatednumbersecur.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">l)Current market price (as on date of application)</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="currentmarketprice"  autocomplete="off" placeholder="Current market price" formControlName="currentmarketprice" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.currentmarketprice.touched) && pitClearanceEditForm.controls.currentmarketprice.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.currentmarketprice.touched) && pitClearanceEditForm.controls.currentmarketprice.errors?.required"
                                                class="text text-danger">
                                                Current market price is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.currentmarketprice.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">m) Price at which transaction is proposed</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="quantity_traded"  autocomplete="off" placeholder="Price at which transaction is proposed" formControlName="transactionisproposed" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.transactionisproposed.touched) && pitClearanceEditForm.controls.transactionisproposed.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.transactionisproposed.touched) && pitClearanceEditForm.controls.transactionisproposed.errors?.required"
                                                class="text text-danger">
                                                Price at which transaction is proposed is required.
                                                </div>
                                                <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.transactionisproposed.errors?.pattern">Please, Enter Numbers only.</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">n) Whether the proposed transaction will be through </label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                                                                                                                                      
                                                <select class="form-select" class="form-control form-select" formControlName="whethertransactionisproposed" [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.whethertransactionisproposed.touched) && pitClearanceEditForm.controls.whethertransactionisproposed.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="stock exchange">stock exchange</option>
                                                    <option value="off-market deal">off-market deal</option>                                                                                                               
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.whethertransactionisproposed.touched) && pitClearanceEditForm.controls.whethertransactionisproposed.errors?.required"
                                            class="text text-danger">
                                            Whether the proposed transaction is required.
                                            </div>   
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label for="dpName">o) Name of the Proposed Buyer/ Seller (for off-market trade)</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">                    
                                                <input type="text"  class="form-control" id="proposedbuyer"  autocomplete="off" placeholder="Name of the Proposed Buyer/ Seller" formControlName="proposedbuyer" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.proposedbuyer.touched) && pitClearanceEditForm.controls.proposedbuyer.errors }" required> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.proposedbuyer.touched) && pitClearanceEditForm.controls.proposedbuyer.errors?.required"
                                                class="text text-danger">
                                                Name of the Proposed Buyer/ Seller is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="previousTransaction">
                                    <div class="align-btn">
                                        <h4 style="text-decoration: underline;">Details of previous transactions</h4>
                                        <!-- <button type="button" class="btn btn-primary me-1" *ngIf="addpreviousbtn" (click)="addPreviousPreClear()">Add Previous Transaction</button> -->
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>p) Details of last transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <textarea class="form-control" rows="3" autocomplete="off" placeholder="Details of last transaction" formControlName="last_transaction" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.last_transaction.touched) && pitClearanceEditForm.controls.last_transaction.errors }" required> </textarea>
                                                    <div *ngIf="(submitted || pitClearanceEditForm.controls.last_transaction.touched) && pitClearanceEditForm.controls.last_transaction.errors?.required" class="text text-danger">
                                                        Details of last transaction is required.
                                                    </div>                                                                      
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>q) Nature of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <select class="form-select" class="form-control form-select" [(ngModel)]="natureTransaction"  formControlName="nature_of_transaction" [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.nature_of_transaction.touched) && pitClearanceEditForm.controls.nature_of_transaction.errors }" required>
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option value="Sale">Sale</option>
                                                    <option value="Purchase">Purchase</option>
                                                    <option value="Subscription">Subscription</option>                                                                    
                                                </select> 
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.nature_of_transaction.touched) && pitClearanceEditForm.controls.nature_of_transaction.errors?.required" class="text text-danger">
                                                    Nature of transaction is required.
                                                </div>                                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>r) Date of transaction</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control"  placeholder="dd/mm/yy" type="date" [(ngModel)]="dateransaction" formControlName="date_of_transaction" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.date_of_transaction.touched) && pitClearanceEditForm.controls.date_of_transaction.errors }" required>                                                                       
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.date_of_transaction.touched) && pitClearanceEditForm.controls.date_of_transaction.errors?.required" class="text text-danger">
                                                    Date of transaction is required.
                                                </div>                  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-5">
                                            <label>s) No. of securities transacted</label>
                                        </div>
                                        <div class="col-md-7 col-lg-5">
                                            <div class="form-group">
                                                <input class="form-control" placeholder="No. of securities transacted" [(ngModel)]="noOfSecurities" type="text" formControlName="securities_transacted" 
                                                [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.securities_transacted.touched) && pitClearanceEditForm.controls.securities_transacted.errors }" required>                                                                       
                                                <div *ngIf="(submitted || pitClearanceEditForm.controls.securities_transacted.touched) && pitClearanceEditForm.controls.securities_transacted.errors?.required" class="text text-danger">
                                                    No. of securities transacted is required.
                                                </div>   
                                                <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.securities_transacted.errors?.pattern">Please, Enter Numbers only.</div>              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="previous-preclearance">
                                        <h4 style="text-decoration: underline;">t) Details of any Previous Pre-Clearance, if any:</h4>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label>a) Number of Shares for which approval was granted</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">
                                                    <input class="form-control" placeholder="Number of Shares" type="text" formControlName="number_of_shares" 
                                                    [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.number_of_shares.touched) && pitClearanceEditForm.controls.number_of_shares.errors }" required>                                                                       
                                                    <div *ngIf="(submitted || pitClearanceEditForm.controls.number_of_shares.touched) && pitClearanceEditForm.controls.number_of_shares.errors?.required" class="text text-danger">
                                                        Number of Shares for which approval was granted is required.
                                                    </div>   
                                                    <div class="text text-danger" *ngIf="pitClearanceEditForm.controls.number_of_shares.errors?.pattern">Please, Enter Numbers only.</div>               
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label>b) Date when the approval was granted</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">
                                                    <input class="form-control" placeholder="DD-MM-YYYY" type="date" formControlName="Date_when_approval" 
                                                    [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.Date_when_approval.touched) && pitClearanceEditForm.controls.Date_when_approval.errors }" required>                                                                       
                                                    <div *ngIf="(submitted || pitClearanceEditForm.controls.Date_when_approval.touched) && pitClearanceEditForm.controls.Date_when_approval.errors?.required" class="text text-danger">
                                                        Date when the approval was granted is required.
                                                    </div>                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label>c) Whether the transaction was executed or not? </label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">
                                                    <select #excute class="form-select" class="form-control form-select" (change)="transactionExcute()" formControlName="transaction_executed" 
                                                    [ngClass]="{ 'is-invalid': (submitted || pitClearanceEditForm.controls.transaction_executed.touched) && pitClearanceEditForm.controls.transaction_executed.errors }" required>
                                                        <option value="" disabled selected>--Choose One --</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                    <div *ngIf="(submitted || pitClearanceEditForm.controls.transaction_executed.touched) && pitClearanceEditForm.controls.transaction_executed.errors?.required" class="text text-danger">
                                                        Whether the transaction was executed is required.
                                                    </div>                                                                     
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3" *ngIf="yesTransactionField">
                                            <div class="col-md-5">
                                                <label>If Yes, Details of the same</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">
                                                    <textarea class="form-control" placeholder="If Yes, Details of the same" rows="4"  formControlName="details_of_yes" > </textarea>                                                                      
                                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3" *ngIf="noTransactionField">
                                            <div class="col-md-5">
                                                <label>If No, reasons for not transacting the same</label>
                                            </div>
                                            <div class="col-md-7 col-lg-5">
                                                <div class="form-group">
                                                    <textarea class="form-control" placeholder="If No, reasons for not transacting the same" rows="4"  formControlName="details_of_no" > </textarea>                                                                      
                                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                                        
                        
                            
                                <div class="checkbox" formGroupName="checkboxgroup">
                                    <h4 style="text-decoration: underline;">In connection with my request for this pre-clearance of the trade, I hereby declare & undertake that:</h4>
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox" class="checkbox" value="value-1" formControlName="checkbox" required>
                                        <label for="checkbox1">I Declare that I am not in possession of or knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Policy upto the time of issuing this undertaking </label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox2" type="checkbox" class="checkbox" formControlName="checkbox1" required>
                                        <label for="checkbox2">In the event that I am in possession/ knowledge of any information that could be construed as “Unpublished Price Sensitive Information” as defined in the Policy, after the signing of this undertaking but before executing the transaction for which approval is sought, I shall inform the Compliance Officer of the same and shall completely refrain from dealing in the securities of the Company until such information becomes public;</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox1').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox3" type="checkbox" class="checkbox" formControlName="checkbox2" required>
                                        <label for="checkbox3">I have not contravened the Company’s Code of Conduct for Prohibition of Trading by Insiders and Code for Fair Disclosure of Unpublished Price Sensitive Information.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox2').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox4" type="checkbox" class="checkbox" formControlName="checkbox3" required>
                                        <label for="checkbox4">I have made full and true disclosure in the matter.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox3').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox5" type="checkbox" class="checkbox" formControlName="checkbox4" required>
                                        <label for="checkbox5">In case the traded value exceeds Rs. 10 lakh or any such other value as may be specified, disclosures required under the Code of Conduct for Prohibition of Trading by Insiders will be immediately furnished by me.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox4').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox6" type="checkbox" class="checkbox" formControlName="checkbox5" required>
                                        <label for="checkbox6">I hereby agree to indemnify and keep the Company and its Board indemnified from and against all and any penalties/fines that may be imposed on them by the SEBI and/or any other Statutory Authorities as a result of violation by me of the Regulation of the Codes prescribed by the Bank.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox5').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox7" type="checkbox" class="checkbox" formControlName="checkbox6" required>
                                        <label for="checkbox7">I hereby declare that I shall execute my order in respect of the securities for which preclearance is sought within 7 trading days after the approval is given. If the order is not executed within 7 trading days after the approval, I undertake to obtain fresh pre-clearance.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox6').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0">
                                        <input id="checkbox8" type="checkbox" class="checkbox" formControlName="checkbox7" required>
                                        <label for="checkbox8">I have not done any prior contra trade within the period of six months and shall not execute a contra trade for a period of 6 months from the date of current transaction.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox7').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                    <div class="checkbox p-0 check2">
                                        <input id="checkbox9" type="checkbox" class="checkbox" formControlName="checkbox8" required>
                                        <label for="checkbox9">I have made full and true disclosure in the above matter.</label>
                                        <div *ngIf="submitted && pitClearanceEditForm.controls.checkboxgroup.get('checkbox8').errors?.required" class="text text-danger mt-1">
                                            Please Accept the Declaration
                                        </div>
                                    </div>
                                </div>

                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>   
                                    <button class="btn btn-warning" type="btn" (click)="cancelPreClearanceTemplate()" >Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="hidePreclearance">
                        <p class="hidepreclearances">Trading date is closed. You are can not trade preclearance.</p>
                    </div>
                </div>
            </div>                       
        </div>
    </div>
</div>





