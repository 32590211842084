<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row" *ngIf="is_access_no">
      
        <div class="card bp-list">
            <div class="card-header">
                <h5>Benpos Comparison Report</h5>
                <div class="filterdataclass">
                    <div class="col-md-6 mb-3">                      
                        <div class="form-group">
                            <input type="text" class="form-control" id="mobileNo" [(ngModel)]="filterString" (ngModelChange)="onFilterChange()" autocomplete="off"  placeholder="Search ..." 
                              >                                                                    
                        </div>
                      </div>
                    </div>
                      <a routerLink="/dashboard"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                <div class="excel-report">
                    <a routerLink="/pit-compliance/add-benpos-report" *ngIf="is_access_write"><button class="btn btn-primary me-1" >New</button></a>
                </div>
          </div>
          <div class="card-body">
            <h6>Last Trading Window Closure Date: {{lastTwc | date:'dd-MM-yyyy'}}</h6>
                <table class="table">
                    <thead>
                        <!-- <tr>
                            <th scope="col" sortable="s.no" >S.No</th>
                            <th scope="col" sortable="dpName" >Benpos Date</th>
                            <th scope="col" sortable="pan" >View Report</th>                            
                        </tr> -->
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filtered | slice: (page-1) * pageSize : page * pageSize; let i=index ">
                            <td scope="row">{{(i+1) + (page-1) * pageSize }}</td>
                            <td scope="row">Benpos Report Generated on {{item.benf_date | date:'dd-MM-yyyy'}}</td>                    
                            <td scope="row" *ngIf="is_access_write"><a [routerLink]="['/pit-compliance/benpos-reports', item.benf_date]"><button class="btn btn-primary me-1"  >View Report</button></a>
                            <button class="btn btn-primary me-1" (click)="openModal(item.benf_date)" >Mail</button></td>
                                            
                            
                        </tr>
                        </tbody>
                </table>
                <div class="list-btn2" *ngIf="filtered?.length =='' ">
                    <p>No Data Found</p>
                </div> 

            <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="filtered?.length"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>
<!-- Container-fluid Ends -->

