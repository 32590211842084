import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../../master.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {
  rolesCreateForm: FormGroup;
  checked: any;
  isLoading = false;
  button = 'Save';
  submitted: boolean = false; 
  constructor(private fb: FormBuilder, private masterService: MasterService, private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {
    this.rolesCreateForm = this.fb.group({
      roles_name: ['', Validators.required],
      upsi_name: ['Log UPSI Transfer',  Validators.required],
      upsi_read: ['',  Validators.required],
      dpname_read: ['',  Validators.required],
      insider_read: ['',  Validators.required],
      project_read: ['',  Validators.required],
      twc_read: ['',  Validators.required],
      customer_read: ['',  Validators.required],
      preclearance_read: ['',  Validators.required],
      benpos_read: ['',  Validators.required],
      dp_name: ['DP List',  Validators.required],
      insider: ['Insider',  Validators.required],
      project: ['Project',  Validators.required],
      twc: ['Trading Window Closure',  Validators.required],
      preclearance: ['Pre Clearance',  Validators.required],
      benpos: ['BENPOS Report',  Validators.required],
      customers: ['Customers',  Validators.required],
      companylist: ['Company List',  Validators.required],
      companylist_read: ['',  Validators.required]

    });
  }
  onSubmit(){
    this.submitted = true;
    this.isLoading = true;
    this.button = 'Processing';
    const emptyFileds = Object.keys(this.rolesCreateForm.controls).filter(key =>this.rolesCreateForm.controls[key].errors !== null)
    if (this.rolesCreateForm.status !== 'INVALID') {
    this.masterService.addRole(this.rolesCreateForm.value).subscribe(data =>{         
       
        if(data.success != '2'){
          this.toastr.success("Role & Permission details created", "Success!");        
        this.button = 'Save';
        this.isLoading = false;
        this.router.navigate(['/setup/role-permission']);
      }else{      
        this.button = 'Save';
        this.isLoading = false;
        this.toastr.warning("Roles Already Exists!");
      }
     
    })
  } else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fileds.");
    this.button = 'Save';
    this.isLoading = false;
    return false;
    
  }else{
    if(this.rolesCreateForm.get('roles_name').value == ''){
      this.toastr.warning("Please fill the Role Name.");
      this.button = 'Save';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('upsi_read').value == ''){
      this.toastr.warning("Please select the UPSI Permission.");
      this.button = 'Save';
      this.isLoading = false;
    }
    else if(this.rolesCreateForm.get('dpname_read').value == ''){
      this.toastr.warning("Please select the DP Permission.");
      this.button = 'Save';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('insider_read').value == ''){
      this.toastr.warning("Please select the Insider Permission.");
      this.button = 'Save';
      this.isLoading = false;
    }
    else if(this.rolesCreateForm.get('project_read').value == ''){
      this.toastr.warning("Please select the Project Permission.");
      this.button = 'Save';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('twc_read').value == ''){
      this.toastr.warning("Please select the Trading Window Clouser Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('preclearance_read').value == ''){
      this.toastr.warning("Please select the Pre Clearance Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('benpos_read').value == ''){
      this.toastr.warning("Please select the BENPOS Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('customer_read').value == ''){
      this.toastr.warning("Please select the Customers Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else if(this.rolesCreateForm.get('companylist_read').value == ''){
      this.toastr.warning("Please select the Company List Permission.");
      this.button = 'Update';
      this.isLoading = false;
    }else {
        this.toastr.warning("Role & Permission details not created", "Alert!");
        this.button = 'Save';
        this.isLoading = false;
      }

  }
  // }else {
  //   this.toastr.warning("Role & Permission details not created", "Alert!");
  //   this.button = 'Save';
  //   this.isLoading = false;
  // }
  }

CheckboxChange(){
  this.checked ? 1 : 0;
}
cancelRoles(){
  this.router.navigate(['/setup/role-permission']);
  this.rolesCreateForm.reset();
  this.submitted = false;
}

}
