import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private http:HttpClient) { 
    
  }
  baseUrl = environment.apiUrl
  
  CompanyList(userObj:any) {
    // return this.http.get(this.baseUrl + 'upsi/list')]
    return this.http.post(this.baseUrl + 'company/list', userObj, {
  
      headers: {'Authorization': sessionStorage.getItem('access_token')},
      observe: 'response'
    });
  }
  addCompany(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "company/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
   }
   editCompany(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "company/edit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
   viewCompany(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "company/view", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  updateCompany(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "company/update", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    })
  }
  deleteCompany(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'company/delete', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')},
    });
}
}
