<div class="page-wrapper" *ngIf="hideForm">
	<div class="auth-bg">
		<div class="authentication-box">
			<div class="text-center"><img src="assets/images/sdd-insidder-login.png" alt=""></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<div class="otpImg"><img src="assets/images/otp.png" alt=""></div>
						<h4>Verification</h4>
						<p>We will send you a <b>One Time Password</b> on your Email ID.</p>
					</div>
					<form class="theme-form" [formGroup]="otpVerifiedForm"  (ngSubmit)="otpVerified()">
						<div class="form-group">
							<label class="col-form-label pt-0">Enter verification code</label>
							<ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" required>
							</ngx-otp-input>
							
						</div>
						
						
						
						<div class="form-group row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg" [disabled]="isLoading"								
								 type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}
							</button>
						</div>
						
					</form>
				</div>
				
			</div>
		</div>
	</div>
</div>

<div class="container-fluid" *ngIf="emptyMessage">
	<div class="row content-details" *ngIf="forgetPasswordLink" >
		<p>Successfully link sent via email, Please check your inbox<br> or spam section.</p>
		<!-- <a href="{{forgetPasswordLink.data}}">Successfully Created a link.<br> Click Here ...</a> -->
		<!-- <a href="http://sdd.legalitygoal.com/auth/change-password?authToken={{authGuard}}">Click Here</a> -->
		<!-- <div *ngIf="forgetPasswordLink.success == 1">
		<p >Link has been Successfully Created.<br> <a href="{{forgetPasswordLink.data}}"> Click Here ...</a></p>
	</div>
	<div *ngIf="forgetPasswordLink.success == 0 ">
		<p>Email Id is Wrong. Check Email ID  ...</p>
	</div> -->
	</div>
</div>