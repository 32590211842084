<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>ADD NEW UPSI</h5>
                    <a routerLink="/upsi/upsi-list"><button class="btn btn-primary me-2 "><i
                                class="fa fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                    <form [formGroup]="upsiCreateForm" class="needs-validation" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="projectName">Project/Event Name <span class="manditary-filed">*</span></label>
                                <div class="form-group lstsender">
                                    <!-- <select class="form-select" #teams class="form-control form-select" required (change)="onSelected()"
                                        id="projectName" (change)="onSelected()" formControlName="project_name"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.project_name.errors }">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option *ngFor="let items of listProject" value="{{items.project_name}}">
                                            {{items.project_name}}</option>
                                          (focusout)='onBlur()'                      

                                    </select> -->
                                    <ng-select [items]="projectNameFilter" bindLabel=""
                                        placeholder="Select Project Name" [(ngModel)]="selectedProjectName"
                                        (change)="projectchangeFn($event)" formControlName="project_name">
                                    </ng-select>
                                    <!-- <div class="ng-autocomplete" #auto>
                                        <ng-autocomplete 
                                          [data]="projectNameFilter"
                                          [searchKeyword]="keyword2"
                                          placeholder="Enter the Project Name" 
                                          (selected)='selectEvent($event)'
                                          (inputChanged)='onChangeSearch($event)'
                                          (inputFocused)='onFocused($event)' 
                                          [itemTemplate]="itemTemplate"
                                          [notFoundTemplate]="notFoundTemplate" #teams formControlName="project_name" (selected)="onSelected($event)">
                                        </ng-autocomplete>
                                       
                                        <ng-template #itemTemplate let-item>
                                          <div class="item">
                                            <span [innerHTML]="item.name"></span> 
                                          </div>
                                        </ng-template>
                                      
                                        <ng-template #notFoundTemplate let-notFound>
                                          <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                      </div> -->
                                    <div *ngIf="(submitted || upsiCreateForm.controls.project_name?.touched) && upsiCreateForm.controls.project_name.errors?.required"
                                        class="text text-danger">
                                        Project Name is required.
                                    </div>
                                </div>
                                <div class="bottomspace">
                                    <p class="projenddate" *ngIf="projEndDateVal == true"><b>Project End Date:</b>
                                        {{projEndDate | date:'dd-MM-yyyy'}}</p>
                                    <p class="projenddate" *ngIf="projNoEndDateVal == true"><b>Project End Date:</b>
                                        {{projEndDate}}</p>
                                </div>
                            </div>
                            <!-- <div class="col-md-6 mb-3">
                                <label for="nameOfSender">Name of Sender</label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select" required id="nameOfSender" formControlName="sender" [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.sender.errors }">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option value="Sender1">Sender1</option>
                                        <option value="Sender2">Sender2</option>
                                    </select>
                                    <div *ngIf="(submitted || upsiCreateForm.controls.sender.touched) && upsiCreateForm.controls.sender.errors?.required"
                                    class="text text-danger">
                                    Name of Sender is required.
                                </div>                                    
                                </div>
                            </div> -->
                            <div class="col-md-6 mb-3">
                                <div class="cols check-line">
                                    <label for="nationality">Sender Name<span class="manditary-filed">*</span></label>
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio2" id="radioinline4" value="DP"
                                                formControlName="value_of_Sender" (click)="radioSelect('DP')">
                                            <label for="radioinline4" class="mb-0">DP</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio2" id="radioinline3" value="Insider"
                                                formControlName="value_of_Sender" (click)="radioSelect('Insider')">
                                            <label for="radioinline3" class="mb-0">Insider</label>
                                        </div>
                                        <div class="radio radio-primary"
                                            *ngIf="companyApplication == 'Intermediary' && is_access_write">
                                            <input type="radio" name="radio2" id="radioinline22" value="Customer"
                                                formControlName="value_of_Sender" (click)="radioSelect('Customer')">
                                            <label for="radioinline22" class="mb-0">Customer</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected('DP')">
                                        <div class="row">
                                            <div class="form-group lstsender">
                                                <!-- <select class="form-select" class="form-control form-select" 
                                                    id="Sender_dp" formControlName="sender" >
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option *ngFor="let dpItem of dropdownListRepDp"
                                                        value="{{dpItem.itemName}}">{{dpItem.itemName}}</option>
                                                </select> -->
                                                <ng-select [items]="senderDpNameFilter" bindLabel=""
                                                    placeholder="Select Sender" [(ngModel)]="selectedDpName"
                                                    formControlName="sender">
                                                </ng-select>
                                                <!-- <div class="ng-autocomplete">
                                                    <ng-autocomplete
                                                      [data]="senderDpNameFilter"
                                                      [searchKeyword]="email"
                                                      placeholder="Enter the Sender Email"                                                      
                                                      [itemTemplate]="itemTemplate"
                                                      [notFoundTemplate]="notFoundTemplate" formControlName="sender">
                                                    </ng-autocomplete>
                                                   
                                                    <ng-template #itemTemplate let-item>
                                                      <div class="item">
                                                        <span [innerHTML]="item"></span> 
                                                      </div>
                                                    </ng-template>
                                                  
                                                    <ng-template #notFoundTemplate let-notFound>
                                                      <div [innerHTML]="notFound"></div>
                                                    </ng-template>
                                                  </div> -->
                                                <div *ngIf="(submitted ||upsiCreateForm.controls.sender.touched) && upsiCreateForm.controls.sender.errors?.required"
                                                    class="text text-danger">
                                                    Sender Name is required.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelected('Insider')">
                                        <div class="row">
                                            <div class="form-group lstsender">
                                                <!-- <select class="form-select" class="form-control form-select" 
                                                    id="Sender_insider" formControlName="sender" >
                                                    <option value="" disabled selected>--Choose One --</option>
                                                    <option *ngFor="let insiderItem of dropdownListRep"
                                                        value="{{insiderItem.itemName}}">{{insiderItem.itemName}}</option>
                                                </select> -->
                                                <ng-select [items]="senderNameFilter" bindLabel=""
                                                    placeholder="Select Sender" [(ngModel)]="selectedSenderName"
                                                    formControlName="sender">
                                                </ng-select>

                                                <div *ngIf="(submitted ||upsiCreateForm.controls.sender.touched) && upsiCreateForm.controls.sender.errors?.required"
                                                    class="text text-danger">
                                                    Sender Name is required.
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div class="bottom-field"
                                        *ngIf="isSelected('Customer') && (companyApplication == 'Intermediary' && is_access_write)">
                                        <div class="row">
                                            <div class="form-group lstsender">

                                                <ng-select [items]="senderCustomerNameFilter" bindLabel=""
                                                    placeholder="Select Sender" [(ngModel)]="selectedCustomerName"
                                                    formControlName="sender">
                                                </ng-select>

                                                <div *ngIf="(submitted ||upsiCreateForm.controls.sender.touched) && upsiCreateForm.controls.sender.errors?.required"
                                                    class="text text-danger">
                                                    Sender Name is required.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="remarks">Description, if any</label>
                                <div class="form-group">
                                    <textarea class="form-control" id="remarks" rows="3" formControlName="remarks"
                                        placeholder="Description, if any"></textarea>
                                    <div class="text text-danger" *ngIf="upsiCreateForm.controls.remarks.errors?.pattern">Special Charactors Not Allowed!</div>
                                </div>

                            </div>

                            <div class="col-md-6 mb-3">
                                <div class="cols check-line">
                                    <label for="nationality">Recipient Name<span
                                            class="manditary-filed">*</span></label>
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline8" value="DP"
                                                formControlName="recipientValue" (click)="radioSelectRep('DP')">
                                            <label for="radioinline8" class="mb-0">DP</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline7" value="Insider"
                                                formControlName="recipientValue" (click)="radioSelectRep('Insider')">
                                            <label for="radioinline7" class="mb-0">Insider</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio4" id="radioinline26" value="Both"
                                                formControlName="recipientValue" (click)="radioSelectRep('Both')">
                                            <label for="radioinline26" class="mb-0">Both</label>
                                        </div>
                                        <div class="radio radio-primary"
                                            *ngIf="companyApplication == 'Intermediary' && is_access_write">
                                            <input type="radio" name="radio4" id="radioinline21" value="Customer"
                                                formControlName="recipientValue" (click)="radioSelectRep('Customer')">
                                            <label for="radioinline21" class="mb-0">Customer</label>
                                        </div>
                                        <div class="radio radio-primary"
                                            *ngIf="companyApplication == 'Intermediary' && is_access_write">
                                            <input type="radio" name="radio4" id="radioinline20" value="All"
                                                formControlName="recipientValue" (click)="radioSelectRep('All')">
                                            <label for="radioinline20" class="mb-0">ALL</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelectedRep('DP')">
                                        
                                        <div class="row">
                                            <div class="form-group">
                                                <angular2-multiselect [data]="dropdownListRepDp2"
                                                    [(ngModel)]="selectedItemsRepDp" [settings]="dropdownSettingsRepDp"
                                                    (onSelect)="onItemSelectRepDp($event)"
                                                    (onDeSelect)="OnItemDeSelectRepDp($event)"
                                                    (onSelectAll)="onSelectAllRepDp($event)"
                                                    (onDeSelectAll)="onDeSelectAllRepDp($event)"
                                                    formControlName="recipient" required id="recipient"
                                                    class="multi-select-int">
                                                </angular2-multiselect>
                                                <div *ngIf="(submitted || upsiCreateForm.controls.recipient.touched) && upsiCreateForm.controls.recipient.errors?.required"
                                                    class="text text-danger">
                                                    Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelectedRep('Insider')">
                                        <div class="row">
                                            <div class="form-group">
                                                <angular2-multiselect [data]="dropdownListRep"
                                                    [(ngModel)]="selectedItemsRep" [settings]="dropdownSettingsRep"
                                                    (onSelect)="onItemSelectRep($event)"
                                                    (onDeSelect)="OnItemDeSelectRep($event)"
                                                    (onSelectAll)="onSelectAllRep($event)" (blur)="onBlur()"
                                                    (onDeSelectAll)="onDeSelectAllRep($event)"
                                                    formControlName="recipient" required id="recipient"
                                                    class="multi-select-int">
                                                </angular2-multiselect>
                                                <div *ngIf="(submitted ||upsiCreateForm.controls.recipient.touched) && upsiCreateForm.controls.recipient.errors?.required"
                                                    class="text text-danger">
                                                    Recipient Name is required.
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="bottom-field" *ngIf="isSelectedRep('Both')">
                                        <div class="row">
                                            <div class="form-group">
                                                <angular2-multiselect [data]="dropdownListRepBoth"
                                                    [(ngModel)]="selectedItemsRepBoth"
                                                    [settings]="dropdownSettingsRepAll"
                                                    (onSelect)="onItemSelectRepAll($event)"
                                                    (onDeSelect)="OnItemDeSelectRepAll($event)"
                                                    (onSelectAll)="onSelectAllRepAll($event)"
                                                    (onDeSelectAll)="onDeSelectAllRepAll($event)"
                                                    formControlName="recipient" required id="recipient"
                                                    class="multi-select-int">
                                                </angular2-multiselect>
                                                <div *ngIf="(submitted ||upsiCreateForm.controls.recipient.touched) && upsiCreateForm.controls.recipient.errors?.required"
                                                    class="text text-danger">
                                                    Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-field"
                                        *ngIf="isSelectedRep('Customer') && (companyApplication == 'Intermediary' && is_access_write)">
                                        <div class="row">
                                            <div class="form-group">
                                                <angular2-multiselect [data]="dropdownListRepCustomer"
                                                    [(ngModel)]="selectedItemsRepCustomer"
                                                    [settings]="dropdownSettingsRep"
                                                    (onSelect)="onItemSelectRepDp($event)"
                                                    (onDeSelect)="OnItemDeSelectRepDp($event)"
                                                    (onSelectAll)="onSelectAllRepDp($event)"
                                                    (onDeSelectAll)="onDeSelectAllRepDp($event)"
                                                    formControlName="recipient" required id="recipient"
                                                    class="multi-select-int">
                                                </angular2-multiselect>
                                                <div *ngIf="(submitted ||upsiCreateForm.controls.recipient.touched) && upsiCreateForm.controls.recipient.errors?.required"
                                                    class="text text-danger">
                                                    Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-field"
                                        *ngIf="isSelectedRep('All') && (companyApplication == 'Intermediary' && is_access_write)">
                                        <div class="row">
                                            <div class="form-group">
                                                <angular2-multiselect [data]="dropdownListRepAll"
                                                    [(ngModel)]="selectedItemsRepAll"
                                                    [settings]="dropdownSettingsRepAll"
                                                    (onSelect)="onItemSelectRepAll($event)"
                                                    (onDeSelect)="OnItemDeSelectRepAll($event)"
                                                    (onSelectAll)="onSelectAllRepAll($event)"
                                                    (onDeSelectAll)="onDeSelectAllRepAll($event)"
                                                    formControlName="recipient" required id="recipient"
                                                    class="multi-select-int">
                                                </angular2-multiselect>
                                                <div *ngIf="(submitted ||upsiCreateForm.controls.recipient.touched) && upsiCreateForm.controls.recipient.errors?.required"
                                                    class="text text-danger">
                                                    Recipient Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="modeOfSharing">Mode of Sharing <span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select"
                                        (change)="modeOfSharingChanged()" required id="mode_of_sharing"
                                        formControlName="mode_of_sharing"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.mode_of_sharing.errors }">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option value="E-mail">E-mail</option>
                                        <option value="Social media messages">Social media messages</option>
                                        <option value="Phone call">Phone call</option>
                                        <option value="SMS">SMS</option>
                                        <option value="Post">Post</option>
                                        <option value="Physical delivery of documents">Physical delivery of documents
                                        </option>
                                        <option value="Physical conversation">Physical conversation</option>
                                        <option value="Fax">Fax</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <div *ngIf="(submitted || upsiCreateForm.controls.mode_of_sharing.touched) && upsiCreateForm.controls.mode_of_sharing.errors?.required"
                                        class="text text-danger">
                                        Mode of Sharing is required.
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="othersSelect">
                                    <input type="text" class="form-control" id="otherSharing" autocomplete="off"
                                        [(ngModel)]="other_mos" formControlName="others_sharing"
                                        placeholder="Enter Others Mode of Sharing" />
                                    <div class="text text-danger"
                                        *ngIf="upsiCreateForm.controls.others_sharing.errors?.pattern">Special
                                        Charactors Not Allowed!</div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="purposeOfSharing">Legitimate Purpose of Sharing <span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input type="text" class="form-control" autocomplete="off" id="purpose_of_sharing"
                                        formControlName="purpose_of_sharing" placeholder="Enter the Purpose of Sharing"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.purpose_of_sharing.errors }" />

                                    <div *ngIf="(submitted || upsiCreateForm.controls.purpose_of_sharing.touched) && upsiCreateForm.controls.purpose_of_sharing.errors?.required"
                                        class="text text-danger">
                                        Legitimate Purpose of Sharing is required.
                                    </div>
                                    <div class="text text-danger"
                                        *ngIf="upsiCreateForm.controls.purpose_of_sharing.errors?.pattern">Special
                                        Charactors Not Allowed!</div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="natureOfUpsi">Nature of UPSI <span class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <!-- <select class="form-select" class="form-control form-select" (change)="natureOfUpsiChanged()" required id="nature_of_upsi" formControlName="nature_of_upsi" [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.nature_of_upsi.errors }">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option value="Financial results">Financial results</option>
                                        <option value="Dividends">Dividends</option>
                                        <option value="Change in capital structure">Change in capital structure</option>
                                        <option value="Mergers, de-mergers & acquisitions">Mergers, de-mergers & acquisitions</option>
                                        <option value="Delistings">Delistings</option>
                                        <option value="Disposals">Disposals</option>
                                        <option value="Expansion of business an">Expansion of business an</option>
                                        <option value="Changes in key managerial personnel">Changes in key managerial personnel</option>
                                        <option value="Others">Others</option>
                                    </select>  -->
                                    <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" required
                                        [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                        (onDeSelectAll)="onDeSelectAll($event)" formControlName="nature_of_upsi"
                                        required id="nature_of_upsi" class="multi-select-int"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.nature_of_upsi.errors }">
                                    </angular2-multiselect>
                                    <div *ngIf="(submitted || upsiCreateForm.controls.nature_of_upsi.touched) && upsiCreateForm.controls.nature_of_upsi.errors?.required"
                                        class="text text-danger">
                                        Nature of UPSI is required.
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="othersSelectnature">
                                    <input type="text" class="form-control" id="otherNatureOfUpsi" autocomplete="off"
                                        formControlName="others_nature_upsi"
                                        placeholder="Enter the Others Nature of Upsi" />
                                    <div class="text text-danger"
                                        *ngIf="upsiCreateForm.controls.others_nature_upsi.errors?.pattern">Special
                                        Charactors Not Allowed!</div>
                                </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="nameOfSender">Sharing Frequency<span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <select class="form-select" class="form-control form-select" required
                                        id="typeOfSharing" formControlName="type_of_sharing"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.type_of_sharing.errors }">
                                        <option value="" disabled selected>--Choose One --</option>
                                        <option value="One time">One time</option>
                                        <option value="ongoing">ongoing</option>
                                    </select>
                                    <div *ngIf="(submitted || upsiCreateForm.controls.type_of_sharing.touched) && upsiCreateForm.controls.type_of_sharing.errors?.required"
                                        class="text text-danger">
                                        Sharing Frequency is required.
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6 mb-3 confidentiality-btn">
                                <div class="cols check-line1">
                                    <label for="nationality">Confidentiality Agreement entered</label>
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio3" id="radioinline5" value="Yes"
                                                (click)="radioSelect2('Yes')"
                                                formControlName="confidentiality_agreement_entered"
                                                [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.confidentiality_agreement_entered.errors }">
                                            <label for="radioinline5" class="mb-0">Yes</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio3" id="radioinline6" value="No"
                                                (click)="radioSelect2('No')"
                                                formControlName="confidentiality_agreement_entered"
                                                [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.confidentiality_agreement_entered.errors }">
                                            <label for="radioinline6" class="mb-0">No</label>
                                        </div>
                                    </div>
                                    <div *ngIf="(submitted || upsiCreateForm.controls.confidentiality_agreement_entered.touched) && upsiCreateForm.controls.confidentiality_agreement_entered.errors?.required"
                                        class="text text-danger">
                                        Confidentiality Agreement entered is required.
                                    </div>
                                </div>

                                <div class="col-md-12 mb-3" style="margin-top: 1rem;">
                                    <div class="row">
                                        <div class="sellected-option">
                                            <div class="row bottom-field" *ngIf="isSelected2('Yes')">
                                                 <div class="col-md-6 mb-3">
                                                    <label class="custom-file-label" for="attachment">Confidentiality Agreement</label>
                                                    <div class="form-group">
                                                        <input #userPhoto type="file" class="custom-file-input form-control" accept=".pdf"
                                                            id="attachment" required formControlName="confidentiality_agreement_attachment"
                                                            (change)="changeListener($event)" >
                                                        
                                                    </div>

                                                    <div *ngIf="attachmentPath">
                                                        <p class="attachmentPath">{{path}}</p>
                                                        <img src="assets/images/close.png" class="right-image" alt="Close"
                                                            (click)="removeFile()">
                                                    </div>

                                                    <div class="form-group" style="display:none;">
                                                        <input type="text" class="form-control" id="filePath" [(ngModel)]="path"
                                                            placeholder="File Path" formControlName="filePath" required>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mb-3">
                                                    <label for="fromDate">Confidentiality Agreement Date</label>
                                                    <div class="form-group input-group">
                                                        <input class="form-control " placeholder="yyyy-mm-dd" type="date" formControlName="confidentiality_agreement_date">
                                                    </div>
                                              
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sellected-option">
                                            <div class="bottom-field" *ngIf="isSelected2('No')">
                                                <div class="row" style="display:none ;"></div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div> -->
                            <div class="col-md-6 mb-3">
                                <label for="dateOfSharing">Date of Sharing <span
                                        class="manditary-filed">*</span></label>
                                <div class="form-group">
                                    <input class="form-control" #sdate placeholder="yyyy-mm-dd" type="datetime-local"
                                        formControlName="date_of_sharing" (change)="onSelectedDate()"
                                        [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.date_of_sharing.errors }">
                                    <div *ngIf="(submitted || upsiCreateForm.controls.date_of_sharing.touched) && upsiCreateForm.controls.date_of_sharing.errors?.required"
                                        class="text text-danger">
                                        Date of Sharing is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="cols check-line">
                                    <label for="nationality">Will This Information Be Made Public?</label>
                                    <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio5" id="radioinline9" value="Yes"
                                                formControlName="public_Date" (click)="radioSelect3('Yes')">
                                            <label for="radioinline9" class="mb-0">Yes</label>
                                        </div>
                                        <div class="radio radio-primary">
                                            <input type="radio" name="radio5" id="radioinline10" value="No"
                                                formControlName="public_Date" (click)="radioSelect3('No')">
                                            <label for="radioinline10" class="mb-0">No</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="sellected-option">
                                    <div class="bottom-field" *ngIf="isSelected3('Yes')">
                                        <div class="row">
                                            <label for="toDate">Date When Made Public</label>
                                            <div class="form-group">
                                                <input class="form-control " placeholder="yyyy-mm-dd"
                                                    type="date" [(ngModel)]="toDate" formControlName="to_date">
                                                <div class="text text-danger"
                                                    *ngIf="upsiCreateForm.controls.to_date.touched && upsiCreateForm.controls.to_date.invalid">
                                                    Date of Sharing should not be greater than public date</div>

                                            </div>
                                            <!-- <p style="color: grey;font-size: 0.8rem;"><b>Note:</b> After the
                                                Date when made public this UPSI will automatically moved from at
                                                UPSI to No longer UPSI.</p> -->
                                            <p style="color:grey;font-size: 0.8rem;"><b>Note:</b> After the Date when information is made public, this UPSI will automatically be moved to 'No longer UPSI' List.</p>
                                           
                                        </div>
                                    </div>
                                    <div class="bottom-field" *ngIf="isSelected3('No')">
                                        <div class="row" style="display:none ;"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-12" style="display:none;">
                                <h4 class="durationFiled">Shared UPSI Duration</h4>
                                <div class="row">
                                    <div class="col-md-6 mb-3" >
                                        <label for="fromDate">UPSI From Date<span
                                                class="manditary-filed">*</span></label>
                                        <div class="form-group input-group">
                                            <input class="form-control " placeholder="yyyy-mm-dd" [(ngModel)]="fromDate"
                                                type="datetime-local" formControlName="from_date" disabled
                                                [ngClass]="{ 'is-invalid': submitted && upsiCreateForm.controls.from_date.errors }">
                                        </div>
                                        <div *ngIf="(submitted || upsiCreateForm.controls.from_date.touched) && upsiCreateForm.controls.from_date.errors?.required"
                                            class="text text-danger">
                                            UPSI From Date is required.
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 mb-3">
                                        <div class="cols check-line">
                                            <label for="nationality">Will this Information be made public ?</label>
                                            <div class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                                <div class="radio radio-primary">
                                                    <input type="radio" name="radio5" id="radioinline9" value="Yes"
                                                        formControlName="public_Date" (click)="radioSelect3('Yes')">
                                                    <label for="radioinline9" class="mb-0">Yes</label>
                                                </div>
                                                <div class="radio radio-primary">
                                                    <input type="radio" name="radio5" id="radioinline10" value="No"
                                                        formControlName="public_Date" (click)="radioSelect3('No')">
                                                    <label for="radioinline10" class="mb-0">No</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="sellected-option">
                                            <div class="bottom-field" *ngIf="isSelected3('Yes')">
                                                <div class="row">
                                                    <label for="toDate">Date when made public</label>
                                                    <div class="form-group">
                                                        <input class="form-control " placeholder="yyyy-mm-dd"
                                                            type="date" [(ngModel)]="toDate" formControlName="to_date">
                                                        <div class="text text-danger"
                                                            *ngIf="upsiCreateForm.controls.to_date.touched && upsiCreateForm.controls.to_date.invalid">
                                                            Date of Sharing should not be greater than public date</div>

                                                    </div>
                                                    <p style="color: grey;font-size: 0.8rem;"><b>Note:</b> After the
                                                        Date when made public this UPSI will automatically moved from at
                                                        UPSI to No longer UPSI.</p>
                                                   

                                                </div>
                                            </div>
                                            <div class="bottom-field" *ngIf="isSelected3('No')">
                                                <div class="row" style="display:none ;"></div>
                                            </div>

                                        </div>
                                    </div> -->
                                </div>


                                <div class="col-md-6 mb-3">

                                </div>
                            </div>
                            <div class="col-md-6 mb-3" *ngIf="modelAccess.upsiattachment_access == true">
                                <label for="dateOfSharing">UPSI Attachement</label>
                                <div class="form-group">
                                    <input #attachments type="file" class="custom-file-input form-control"
                                        accept=".pdf, image/png, image/jpeg, .xlsx, .docx, .txt, .xls" id="attachment"
                                        formControlName="agreement_attachment" #fileInput
                                        (change)="onFileChanged($event)" multiple="true">
                                    <!-- <input #userPhoto type="file" class="custom-file-input form-control" accept=".pdf" id="attachment" formControlName="fileName" (change)="changeListener($event)" >                             -->
                                    <p class="text text-danger">*Upload Files: .pdf, .png, .jpeg, .xlsx, .docx, .txt,
                                        .xls</p>
                                </div>
                                <div *ngFor="let selected of selectedFileName; let index = index" class="itemArray">
                                    <h3 class="listItemhead">{{ selected }}</h3>
                                    <button (click)="removeSelectedFile(index)" type="button" class="selectedBtn"><img
                                            src="assets/images/close.png" class="closeIcon"></button>
                                </div>
                                <!-- <div *ngFor="let selected of listOfFiles; let index = index" class="itemArray">
                                    <h3 class="listItemhead">{{ selected.name }}</h3>
                                    <button (click)="removeSelectedFile(index)" class="selectedBtn"><img src="assets/images/close.png" class="closeIcon"></button>
                                  </div> -->
                                <!-- <div *ngIf="attachmentPath">
                                    <p class="attachmentPath">{{path}}</p>
                                    <img src="assets/images/close.png"class="right-image" alt="Close" (click)="removeFile()">
                                </div>
                
                                <div class="form-group" style="display:none;">                                
                                    <input type="text" class="form-control" id="filePath" [(ngModel)]="path" placeholder="File Path" formControlName="filePath" >  
                                </div>
                                <div class="form-group" style="display:none;">                                
                                    <textarea rows="3" class="form-control" id="filePath" [(ngModel)]="pathImage" placeholder="File Path Link" formControlName="image" ></textarea>  
                                </div> -->
                            </div>
                            <div class="checkbox" formGroupName="checkboxgroup">
                                <!-- <h4 style="text-decoration: underline;">In connection with my request for this pre-clearance of the trade, I hereby declare & undertake that:</h4> -->
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox" class="checkbox" value="value-1"
                                        formControlName="checkbox" required>
                                    <label for="checkbox1"> I declare that the UPSI was shared only in accordance with
                                        the legitimate purpose policy of the company and only on a need-to-know basis.
                                    </label>
                                    <div *ngIf="submitted && upsiCreateForm.controls.checkboxgroup.get('checkbox').errors?.required"
                                        class="text text-danger mt-1">
                                        Please Accept the Declaration
                                    </div>
                                </div>
                            </div>
                            <div class="submit-btn">
                                <button class="btn btn-primary" [disabled]="isLoading" type="submit"><i class="fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i>
                                    {{button}}</button>
                                <button class="btn btn-warning" type="submit" (click)="cancelUpsi()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->

