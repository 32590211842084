import { Injectable } from '@angular/core';
import { Item } from 'angular2-multiselect-dropdown';
import { BehaviorSubject } from 'rxjs';
import { DpModuleService } from './dp-module.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  Item = [];
  private refreshSource = new BehaviorSubject<Array<any>>(Item['']);
  currentRefresh = this.refreshSource.asObservable();
  
  public listDpLabelModule: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private dpService: DpModuleService) {
    this.dpService.listDpLabelModule(null).subscribe(
      res => {this.listDpLabelModule.next(res.body['data'])
       
      } )
     
   }

  getRefresh(){
       return this.currentRefresh;
  }
  setRefresh(items:[]) {
   
    return this.refreshSource.next(items)
  
  }
}
