<div class="modal-header">
    <h5 class="modal-title">Alert!.. </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <p class="tradealert"><b>Note: </b>Your Application date is {{myDate | date }}. You will be intimated about the approval/ rejection of the request separately.  Please note that, as per the Company's Insider Trading Code, the trade should be executed within {{predaycount}} days of pre-clearance approval date.  You are required to intimate the Company within 2 days of the Trade. </p>
                </div>
               
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
