import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-intimation-alert-popup',
  templateUrl: './intimation-alert-popup.component.html',
  styleUrls: ['./intimation-alert-popup.component.scss']
})
export class IntimationAlertPopupComponent implements OnInit {

  @Input() preClearancTradingAlert;
  @Input() predaycounts;
  myDate: Date;
  predaycount: any;
  //predaycounts: any;
  constructor(public activeModal: NgbActiveModal, public router: Router, private companyService: CompanyService) {
    this.myDate = new Date();
     this.predaycounts = sessionStorage.getItem('predaycount');
   }

  ngOnInit(): void {

    if(Number(this.predaycounts) == 0 || this.predaycounts =='' || this.predaycounts == null){
      this.predaycount = 7;
    }else{
      this.predaycount = this.predaycounts;
    }
    

  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
