import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpsiService } from '../upsi.service';

@Component({
  selector: 'app-view-upsi',
  templateUrl: './view-upsi.component.html',
  styleUrls: ['./view-upsi.component.scss']
})
export class ViewUpsiComponent implements OnInit {
  upsiId: any;
  viewUpsiList: any;
  accessSuccess: string;

  constructor(private activateRouter: ActivatedRoute, private upsiService: UpsiService, public router: Router) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data => {
      this.upsiId = {'id':data['id']};
    })
    if (this.upsiId['id'] !== '') {
      this.upsiService.viewUpsiTransfer(this.upsiId).subscribe(
        data =>{
        this.viewUpsiList = data;
      })

      }
  }

}
