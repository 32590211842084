import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InsiderServiceService } from '../insider-service.service';

@Component({
  selector: 'app-insider-delete',
  templateUrl: './insider-delete.component.html',
  styleUrls: ['./insider-delete.component.scss']
})
export class InsiderDeleteComponent implements OnInit {
  insiderId: any;
  accessSuccess: string;
  @Input() viewInsiderList;
  isLoading = false;
  button = 'Delete';
  insiderReasonDeleteForm: FormGroup;
  getId: any;
  submitted = false;
  constructor(private fb: FormBuilder,private insiderService:InsiderServiceService, private router:Router, private activateRouter:ActivatedRoute, private toastr: ToastrService,
    public activeModal: NgbActiveModal) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

    this.insiderReasonDeleteForm = this.fb.group({
      id: new FormControl(''),
      reasonfordelete : new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')])
    });
   this.getId = this.viewInsiderList.id;
  

   
  }
// deleteFunction(){
//   this.activateRouter.params.subscribe(data =>{
//     this.insiderId = data['id']
//   })
//   if(this.insiderId){
//     this.insiderService.deleteInsider(this.insiderId).subscribe(data =>{
//     // console.log(agenda Details deleted )
//     this.toastr.success("Insider details deleted", "Success!");
//     this.router.navigate(['/insider/insider-list']);
//   })
// }
// }
onSubmit(){
  this.submitted = true;
  this.insiderReasonDeleteForm.value.id = this.getId;
  if(this.insiderReasonDeleteForm.status !== 'INVALID'){
  this.isLoading = true;
      this.button = 'Processing';
  this.insiderService.deleteInsider(this.insiderReasonDeleteForm.value).subscribe(data =>{    
    this.toastr.success("Insider Successfully Deleted");
    this.isLoading = false;
      this.button = 'Delete';
    // this.router.navigate(['/insider/insider-list']);
    window.location.reload();
    
  })
}else{
  if(this.insiderReasonDeleteForm.get('reasonfordelete').value == ''){
    this.toastr.warning("Please Mention the Reason for Deleting Insider.");
  }
}
}


      
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
