<div class="modal-header">
    <h5 class="modal-title">VIEW CUSTOMER DETAILS</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewCustomerList">
                                   
                                     <tr>
                                        <td scope="row">Customer Name</td>
                                        <td scope="row">{{viewCustomerList?.customer_name}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Designation</td>
                                        <td scope="row">{{viewCustomerList?.designation}}</td>                                         
                                     </tr>                                       
                                     <tr>
                                        <td scope="row">Email ID</td>
                                        <td scope="row">{{viewCustomerList?.email}}</td>                                         
                                     </tr> 
                                      
                                     <tr>
                                        <td scope="row">Contact Number</td>
                                        <td scope="row">{{viewCustomerList?.mobileNo}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">ID Proof Type - ID Proof No.</td>
                                        <td scope="row">{{viewCustomerList?.DocumentType}} - {{viewCustomerList?.panNo}}</td>                                         
                                     </tr>
                                       
                                     <tr>
                                        <td scope="row">Address</td>
                                        <td scope="row">{{viewCustomerList?.address}}</td>                                         
                                     </tr> 
                                     <tr>
                                        <td scope="row">Nationality</td>
                                        <td scope="row">{{viewCustomerList?.nationality}}</td>                                         
                                     </tr> 
                                     <tr *ngIf="viewCustomerList?.nationality == 'Others' ">
                                        <td scope="row">Other Nationality</td>
                                        <td scope="row">{{viewCustomerList?.othersnationality}}</td>                                         
                                     </tr> 
                                   
                                    <tr>
                                       <td scope="row" colspan="2" style="font-weight: 700;
                                       text-decoration: underline;">Company Details:</td>
                                    </tr>
                                    <tr>
                                       <td scope="row">Company Name</td>
                                       <td scope="row">{{viewCustomerList?.company_name}}</td>                                         
                                    </tr> 
                                    <tr>
                                       <td scope="row">Company Email ID</td>
                                       <td scope="row">{{viewCustomerList?.company_email}}</td>                                         
                                    </tr>                                 
                                    <tr>
                                       <td scope="row">ISIN Number</td>
                                       <td scope="row">{{viewCustomerList?.isinnumber}}</td>                                         
                                    </tr>                                 
                                    <tr>
                                       <td scope="row">Company PAN No.</td>
                                       <td scope="row">{{viewCustomerList?.company_pan}}</td>                                         
                                    </tr>                                 
                                    <tr>
                                       <td scope="row">Company Contact Number</td>
                                       <td scope="row">{{viewCustomerList?.company_contact_no}}</td>                                         
                                    </tr>                                 
                                    <tr>
                                       <td scope="row">Company Address</td>
                                       <td scope="row">{{viewCustomerList?.company_address}}</td>                                         
                                    </tr>                                 
                               
                            </tbody>
                        </table>

                       
                        <div class="checkbox">
                           
                           <div class="col-sm-12 col-xl-12">
                              <div class="card">                                                     
                                <div class="card-body timeline">
                                  <ngb-accordion [closeOthers]="true">
                                    <ngb-panel id="static-1">
                                      <ng-template ngbPanelTitle>
                                          <span>Timeline of Changes</span>
                                        </ng-template>
                                      <ng-template ngbPanelContent>
                                       <div class="custom-datatable">
                                           <div class="timeline-wrapper"> 
                                               <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                                      <li class="StepProgress-item is-done">
                                                          <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                          <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                          <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                          <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                          <div ><p><span class="bold">Action By (Username):</span> {{items.ActionBy}}</p></div>
                                                          <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                      </li>                                       
                                               </ul>
                                           </div>    
                                           
                                           <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                               <p>No Data Found</p>
                                           </div>
                                        
                                      </div>

                                      </ng-template>
                                    </ngb-panel>
                                                                        
                                  </ngb-accordion>
                                </div>
                              </div>
                            </div>
                          
                        </div>
                        
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
