<div class="container-fluid">   
    <div class="row" *ngIf="is_access_no">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="col-md-6 mb-3">  
                        <h4>Shares</h4>                    
                        <div class="form-group">
                        <input type="text" class="form-control" id="serach" [(ngModel)]="filterString" autocomplete="off" (ngModelChange)="onFilterChange()"  placeholder="Search By Trading Company Name ..." 
                             required >                                                                         
                        </div>
                    </div>
                             
                    <div class="list-btn">                       
                        <a routerLink="/pit-compliance/list-pre-clearance"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>  
                    </div>
                </div>
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable heading">
                        <p><b>Name:</b> {{preClearanceSharesValues[0]?.dpname}}</p>
                        <p><b>Dp ID:</b> {{preClearanceSharesValues[0]?.dpid}}</p>
                        <br>
                        <table class="table">                            
                            <tbody *ngFor="let item of filtered;let i=index">
                                <tr>
                                    <td scope="row" colspan="2"> S.No - {{i+1}} </td>
                                </tr>
                                <tr>
                                    <td scope="row"> Type</td>
                                    <td scope="row">{{item.type}}</td>                                         
                                </tr>
                                <tr *ngIf="item.relativename != ''">
                                    <td scope="row"> Relative Name</td>
                                    <td scope="row">{{item.relativename}}</td>                                         
                                </tr>
                                <tr>
                                    <td scope="row"> Trading company Name</td>
                                    <td scope="row">{{item.tradingcompanyname}}</td>                                         
                                </tr>
                                <tr>
                                    <td scope="row"> Equity Shares</td>
                                    <td scope="row">{{item.equityshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Preference Shares</td>
                                    <td scope="row">{{item.preferenceshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Warrants Sharesn</td>
                                    <td scope="row">{{item.warrantsshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Convertible Shares</td>
                                    <td scope="row">{{item.convertibleshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Non Convertible Shares</td>
                                    <td scope="row">{{item.nonconvertibleshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Rights Entitlements Shares</td>
                                    <td scope="row">{{item.rightentitleshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row"> Others Shares</td>
                                    <td scope="row">{{item.othersshares}}</td>                                         
                                </tr> 
                                <tr>
                                    <td scope="row" colspan="2">
                                        <div class="checkbox">
                           
                                            <div class="col-sm-12 col-xl-12">
                                               <div class="card">                                                     
                                                 <div class="card-body timeline">
                                                   <ngb-accordion [closeOthers]="true">
                                                     <ngb-panel id="static-{{i}}">
                                                       <ng-template ngbPanelTitle>
                                                           <span>Timeline of Changes</span>
                                                         </ng-template>
                                                       <ng-template ngbPanelContent>
                                                        <div class="custom-datatable">
                                                            <div class="timeline-wrapper"> 
                                                                <ul class="StepProgress" *ngFor = "let items of item.timelineActivity"> 
                                                                       <li class="StepProgress-item is-done">
                                                                           <div class="bold time">{{items.created_on | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                                           <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                                           <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                                           <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                                           <div ><p><span class="bold">Action By (Username):</span> {{items.username}}</p></div>
                                                                           <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                                       </li>                                       
                                                                </ul>
                                                            </div>    
                                                            
                                                            <div class="list-btn2" *ngIf="item.timelineActivity?.length =='' ">
                                                                <p>No Data Found</p>
                                                            </div>
                                                         
                                                       </div>
                 
                                                       </ng-template>
                                                     </ngb-panel>
                                                                                         
                                                   </ngb-accordion>
                                                 </div>
                                               </div>
                                             </div>
                                           
                                         </div>
                                    </td>
                                </tr> 
                                
                        </tbody>
                        </table>
                        
                    </div>
                    <div class="list-btn2" *ngIf="filtered?.length =='' ">
                        <p>No Data Found</p>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!is_access_no">
        <h2 style="text-align: center;margin-top: 15%;font-size: 22px;line-height: 1.5;">You do not have access to this page. Kindly contact your Admin/SDD Support to upgrade.</h2>
    </div>
</div>