import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MasterService } from '../../master.service';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-log',
  templateUrl: './user-log.component.html',
  styleUrls: ['./user-log.component.scss']
})
export class UserLogComponent implements OnInit {
  userLogHistory: any;
  public selected = [];
  page = 1;
  pageSize = 20;
  accessSuccess: string;
  filterForm: FormGroup;
  baseUrl = environment.apiUrl;
  constructor(private masterService: MasterService, private router:Router, private modalService: NgbModal,private fb: FormBuilder) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {

    this.filterForm = this.fb.group({
      fromDate: new FormControl(''),
      toDate: new FormControl('', [this.dateRangeValidator])
    });

    this.masterService.userLogHistory(null).subscribe(
      res => {
       
      this.userLogHistory= res?.body['data'];
        
        },
        err => {
            console.log(err);
        }
    );
   
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.filterForm && this.filterForm.get("fromDate").value;
    const to = this.filterForm && this.filterForm.get("toDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
 
  // onDateFilter(e){ 
  //   let toDate = this.filterForm.value.toDate;
  //   let dateOfSharing = this.filterForm.value.fromDate;
  //   this.filterForm.value.toDate = toDate;
  //   this.filterForm.value.dateOfSharing = dateOfSharing;   
  //     //upsi
  //     this.masterService.userLogHistory(this.filterForm.value).subscribe(
  //       res => {          
  //         console.log(res)          
  //       },
  //       err => {
  //         console.log(err);
  //       }
  //     );
      
  // }
  downloadexcelsheet() {
    
    let queryString = '';
    let url = this.baseUrl + 'userlogexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    
    if(this.filterForm.value.fromDate || this.filterForm.value.toDate )  {
      queryString = '&fromDate=' + this.filterForm.value.fromDate + '&toDate=' + this.filterForm.value.toDate;
    
      queryString = queryString + '&authToken='+btoa(tokenStr);
     
      
    } else {
      queryString = queryString + 'authToken='+btoa(tokenStr);
    }
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'userlogexcel.xls');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  
     
  }
  downloadpdfreport(){
    let queryString = '';
        let url = this.baseUrl + 'userlogexcelpdf?';
        let tokenStr = sessionStorage.getItem('access_token');
        if(tokenStr){
          tokenStr = tokenStr.replace('Bearer ', '');
        }
        
        if(this.filterForm.value.fromDate || this.filterForm.value.toDate )  {
          queryString = '&fromDate=' + this.filterForm.value.fromDate + '&toDate=' + this.filterForm.value.toDate;
          queryString = queryString + '&authToken='+btoa(tokenStr);         
          
        }else {
          queryString = queryString + 'authToken='+btoa(tokenStr);
        }
       
          var element = document.createElement('a');
          element.setAttribute('href', url + queryString);
          element.setAttribute('download', 'userlogpdf.pdf');
      
          element.style.display = 'none';
          document.body.appendChild(element);
        
          element.click();
        
          document.body.removeChild(element);
  }
  resetForm(){
    this.filterForm.reset();
  }
  
  onSubmit() {
        this.masterService.userLogHistory(this.filterForm.value).subscribe(
        res => {          
          console.log(res)          
        },
        err => {
          console.log(err);
        });
     
     }
}
