import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UpsiService } from '../upsi.service';

@Component({
  selector: 'app-delete-upsi',
  templateUrl: './delete-upsi.component.html',
  styleUrls: ['./delete-upsi.component.scss']
})
export class DeleteUpsiComponent implements OnInit {
  upsiId: any;
  accessSuccess: string;

  constructor(private upsiService: UpsiService, public router: Router, private activateRouter:ActivatedRoute, private toastr: ToastrService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    // console.log("accessSuccess", this.accessSuccess)
    if(this.accessSuccess !== '1'){
      this.router.navigate(['auth/login']);      
    }
   }

  ngOnInit(): void {
    this.activateRouter.params.subscribe(data =>{
      this.upsiId = {'id':data['id']}
    })
    if(this.upsiId['id']){
      this.upsiService.deleteUpsiTransfer(this.upsiId).subscribe(data =>{
      // console.log(agenda Details deleted )
      this.toastr.success("UPSI Tranfer details deleted", "Success!");
      this.router.navigate(['/upsi/upsi-list']);
    })
  }
  }

}
