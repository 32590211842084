import { Component, OnInit } from '@angular/core';
import { MasterService } from '../../master.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionListComponent } from '../permission-list/permission-list.component';
import { Input } from 'hammerjs';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss']
})
export class RolePermissionComponent implements OnInit {
  roleData:any;
  pageSize = 10;
  page = 1;
  btnstate: boolean=false;
  constructor(private masterService: MasterService, private modalService: NgbModal, private toastr: ToastrService, public router: Router) { }

  ngOnInit(): void {    

    this.masterService.getRoles(null).subscribe(data =>{
      let resp = data.body;
     
      if (resp['status'] == "success") {
        this.roleData = resp['data'];
      
      } else {
        // this.toastr.warning("UPSI details not created", "Alert!");
      }
    })
  }

  openModal(id:any) {
    if (id) {
      let roleid = {'id':id};
      this.masterService.getRoleById(roleid).subscribe(
        data =>{
          const modalRef = this.modalService.open(PermissionListComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.viewRoleList = data.body['data'][0];
          modalRef.result.then((result) => {
            console.log(result);
          }, (reason) => {
          });
      })
  
      }
    
  }

}
