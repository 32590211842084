import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { distinct, from, tap } from 'rxjs';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';
import { PitComplianceService } from '../../pit-compliance.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/customer/customer.service';
import { CompanyService } from 'src/app/master/company/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditReasonComponent } from '../../../master/project/edit-reason/edit-reason.component';

@Component({
  selector: 'app-edit-declaration-of-trade',
  templateUrl: './edit-declaration-of-trade.component.html',
  styleUrls: ['./edit-declaration-of-trade.component.scss']
})
export class EditDeclarationOfTradeComponent implements OnInit {

  isLoading = false;
   button = 'Update';
   dotEditForm: FormGroup;
   dotOldEditForm: FormGroup;
   htmlContent = '';
   selected_sender:any = [];
   selected_receiver:any = [];
   selected_both:any = [];
   dropdownSettings = {};
   dropdownSettings1 = {};
   dropdownSettings2:IDropdownSettings;
   selectedItems = [];
   listDpModule: any;
   senderList = [];
   selectedSenderItems = [];
   selectedRecipentDpItems = [];
   recipientDpList:any = [];
   listinsider: any;
   recipentInsiderList:any = [];
   selectedRecipentInsiderItems = [];
   selectedRecipentbothItems = [];
   recipientBothList = [];
   recipientBothList1 = [];
   dropdownListRep = [];
   dropdownListInsider = [];
   dropdownListRepAll = [];
   twcId: any;
   twcEditId: any;
   recipientLists: any;
   submitted:boolean = false;
   selectedFile = [];
   pathImage: any[];
   selectedFileName: any[] = [];
   removeList = [];
   openTwcAttachment: boolean = false;
   selectedtradingCompanyNameList: any[];
   tradingCompanyNameList: any[];
   companyApplication: any;
   is_access_read = false;
   is_access_write = false;
   is_access_no = false;
   user_roles;
  showButton: boolean;
  editReason: any;
   constructor(private fb: FormBuilder, private router:Router,private companyService: CompanyService, private customerService: CustomerService,private dpService:DpModuleService, private insiderService:InsiderServiceService, private toastr: ToastrService, private pitService: PitComplianceService,
     private activateRouter:ActivatedRoute,private modalService: NgbModal) {
      
      }
     //  editSenderValue(senderName = []){
     //   let selectedItems = [];
     //   this.selectedSenderItems =[];
     //   this.dpService.dpModuleList(null).subscribe(
     //     res => {
     //       this.senderList = [];
     //       this.dropdownListRep = [];
     //       this.listDpModule = res['data']
     //       this.listDpModule.map(({id,email}) =>{
             
     //         this.senderList.push({item_id:id,item_text: email})
     //         if(senderName && senderName.length > 0) {
 
     //           senderName.map(resStr => {
     //             if(email == resStr){ 
     //               selectedItems.push({item_id:id,item_text: email});
     //             }
     //           });        
     //         }
     //         this.selectedSenderItems = selectedItems;
     //       });
     //     })
     
     // }
     editRecipientValue(projectName = []){   
    
      this.selectedRecipentInsiderItems = [];
          let selectedItemsInsider:any = [];
 
          this.insiderService.insiderList(null).subscribe(
           res => {
             this.recipentInsiderList = [];
             // this.selectedRecipentbothItems = []
             
           this.listinsider = res.data;
           // const recipentList2 = this.listinsider.map(res =>res.email);
           //   const recipentList3 = recipentList2.filter(result => result)
           const insiderList = this.listinsider.map(({id,email}) =>({item_id: id,item_text: email}));
           this.dropdownListInsider = [];
           insiderList.map(({item_id,item_text}) =>{
             this.recipentInsiderList.push({item_id: item_id,item_text: item_text});
             if(projectName && projectName.length > 0) {
               projectName.map(resStr => {
                 if(item_text == resStr){ 
                   selectedItemsInsider.push({item_id:item_id,item_text: item_text});
                  
                 }
               });        
             }
            });
             this.selectedRecipentInsiderItems = selectedItemsInsider;
         
             },
             err => {
                 console.log(err);
             }
         );
       
        
       }
       editDpRecipientValue(projectName = []){
     
         let selectedItemsDp:any = [];
         this.selectedRecipentDpItems = [];
         
     
         this.dpService.dpModuleList(null).subscribe(
          res => {
        
            this.recipientDpList = [];
            this.listDpModule = res['data']
            const dpList = this.listDpModule.map(({id,email}) =>({item_id: id,item_text: email}));
        this.dropdownListRep = [];
 
        dpList.map(({item_id,item_text}) =>{
          this.recipientDpList.push({item_id: item_id,item_text: item_text});
          if(projectName && projectName.length > 0) {
            projectName.map(resStr => {
              if(item_text == resStr){ 
                selectedItemsDp.push({item_id:item_id,item_text: item_text});
              }
            });        
          }
         });
          this.selectedRecipentDpItems = selectedItemsDp;
        
          },
          //recipent Dp
            err => {
                console.log(err);
            }
          );
 
 
       
     }
     
       editBothRecipientValue(projectName = []){
               let selectedItemsBoth = [];
          this.selectedRecipentbothItems = [];
       //    this.recipientBothList.map(({item_id,item_text}) =>{
          
       //    if(projectName && projectName.length > 0) {
       //      projectName.map(resStr => {
       //        if(item_text == resStr){ 
       //          selectedItemsBoth.push({item_id:item_id,item_text: item_text});
       //        }
       //      });        
       //    }
       //    this.selectedRecipentbothItems = selectedItemsBoth;
       //  });
 
       this.pitService.dpInsiderrecipient(null).subscribe(
         res => {
           const bothListEmail = res.data;
       
         const bothList = bothListEmail.map(({dpemail}) =>({item_text: dpemail}));      
         bothList.forEach((item, index) => item.item_id = index + 1);
         let selectedLabelDataItems = []
         this.recipientBothList = [];
         bothList.map(({item_id,item_text}) =>{
           this.recipientBothList.push({item_id: item_id,item_text: item_text});
       
           if(projectName && projectName.length > 0) {
            
             projectName?.map(resStr => {
              
               if(item_text == resStr){ 
                 selectedLabelDataItems.push({item_id: item_id,item_text: item_text});
               }
             });
           }
         });
         this.selectedRecipentbothItems = selectedLabelDataItems; 
         
        
           },
           err => {
               console.log(err);
           }
       );
        
     }
     edittradingcompanynameValue(projectName = []){
     
       let selectedItemsComapanyName = [];
       this.selectedtradingCompanyNameList = [];  
       this.customerService.customerTradingAllowedList(null).subscribe(
        res => {
      
          this.tradingCompanyNameList = [];
          let listcompanyList = res.body['data']
          const companyNameList = listcompanyList.map(({id,company_name}) =>({item_id: id,item_text: company_name}));
      this.dropdownListRep = [];
      companyNameList.map(({item_id,item_text}) =>{
        this.tradingCompanyNameList.push({item_id: item_id,item_text: item_text});
        if(projectName && projectName.length > 0) {
          projectName?.map(resStr => {
            if(item_text == resStr){ 
             selectedItemsComapanyName.push({item_id:item_id,item_text: item_text});
            }
          });        
        }
       });
        this.selectedtradingCompanyNameList = selectedItemsComapanyName;
      
        },
        //recipent Dp
          err => {
              console.log(err);
          }
        );
 
 
     
   }
     private dateRangeValidator: ValidatorFn = (): {
       [key: string]: any;
     } | null => {
       let invalid = false;
       const from = this.dotEditForm && this.dotEditForm.get("start_date")?.value;
       const to = this.dotEditForm && this.dotEditForm.get("end_date")?.value;
     
       if (from && to) {
         invalid = new Date(from).valueOf() > new Date(to).valueOf();
        
       }
       return invalid ? { invalidRange: { from, to } } : null;
     };
   ngOnInit(): void {
     this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
     if (this.user_roles.customer_read == '2') {
       this.is_access_write = true;
       this.is_access_read = true;
       this.is_access_no = true;
     } else if (this.user_roles.customer_read == '1') {
       this.is_access_write = false;
       this.is_access_read = true;
       this.is_access_no = true;
     }
     let accessData = sessionStorage.getItem('model_access_controller');    
     let accessModelController = JSON.parse(accessData);
     if(accessModelController.twcattachment_access == true || accessModelController.twcattachment_access == 1){
        this.openTwcAttachment = true;
     }else{
        this.openTwcAttachment = false;
     }
 
     this.activateRouter.params.subscribe(data =>{
       this.twcId = {'id' : data['id']};      
     })
     if(this.twcId['id'] !==''){
       
       this.pitService.declarationofTradeEdit(this.twcId)
       .toPromise().then(result =>{ 
         this.twcEditId = result.data[0].id;
         
         this.selectedButtonRep = result.data[0].recipient_type;
        
         this.dotEditForm?.patchValue(result.data[0]); 
         this.dotOldEditForm?.patchValue(result.data[0]); 
        // const senderLists = result.data[0].sender;
         this.recipientLists = result.data[0].recipient_name;
        this.selectedSenderItems = result.data[0].sender_name;
        if (result.data[0]?.agreement_attachment != '') {
         this.selectedFileName = result.data[0]?.aggreement_attachment;
         //let selectedFileName = result.data[0]?.aggreement_attachment;
         // this.selectedFileName = selectedFileName?.split(',');
       }
       this.editRecipientValue(result.data[0].recipient_name);
       this.editDpRecipientValue(result.data[0].recipient_name);
       this.editBothRecipientValue(result.data[0].recipient_name);
       //this.edittradingcompanynameValue(result.data[0].tradingcompanyname);
 
 
       //this.editSenderValue(result.data[0].sender);
       this.dotEditForm.statusChanges.subscribe(status => {
        const isEqual = this.areObjectsEqual(this.dotEditForm.value, this.dotOldEditForm.value);
        if(!isEqual){
          this.showButton = true;
        }else{
          this.showButton = false;
        }
      });
       })
     this.dotEditForm = this.fb.group({
       start_date: new FormControl(''),
       end_date: new FormControl('', [this.dateRangeValidator]),
       sender_name:new FormControl ('', Validators.required),
       recipient_type:new FormControl ('Insider'),
       recipient_name: new FormControl('', Validators.required),
       //tradingcompanyname: new FormControl(''),
       period: new FormControl(''),
       subject: new FormControl('', Validators.required),
       body_content: new FormControl (''),
       //agreement_attachment_file: new FormControl (''),
       
     });
     this.dotOldEditForm = this.fb.group({
       start_date: new FormControl(''),
       end_date: new FormControl('', [this.dateRangeValidator]),
       sender_name:new FormControl ('', Validators.required),
       recipient_type:new FormControl ('Insider'),
       recipient_name: new FormControl('', Validators.required),
       //tradingcompanyname: new FormControl(''),
       period: new FormControl(''),
       subject: new FormControl('', Validators.required),
       body_content: new FormControl (''),
       //agreement_attachment_file: new FormControl (''),
       
     });
 
     //multi select
     this.dropdownSettings = {
         singleSelection: false,
         idField: 'item_id',
         textField: 'item_text',
         selectAllText: 'Select All',
         unSelectAllText: 'UnSelect All',
         itemsShowLimit: 2,
         allowSearchFilter: true
       };
       this.dropdownSettings1 = {
         singleSelection: false,
         idField: 'item_id',
         textField: 'item_text',
         selectAllText: 'Select All',
         unSelectAllText: 'UnSelect All',
         itemsShowLimit: 2,
         allowSearchFilter: true
       };
       this.selectedItems = [];
       this.dropdownListRepAll = [];
 
       this.companyService.CompanyList(null).subscribe(
         res => {
           if (res.body['message'] == 'Invalid User.') {
             this.router.navigate(['/auth/login']);
           } else {
             let listCompany = res.body['data']
             this.companyApplication = listCompany[0].customertype;
   
           }
         },
         err => {
           console.log(err);
         }
       );
       //DP Api Call
       this.dpService.dpModuleList(null).subscribe(
         res => {
           this.senderList = [];
           this.recipientDpList = [];
           
           // this.selectedRecipentbothItems = [];
         this.listDpModule = res['data']
         // const senderList2 = this.listDpModule.map(res =>res.email);
         // const senderList3 = senderList2.filter(result => result)
           const insiderList = this.listDpModule.map(({id,email}) =>({item_id: id,item_text: email}));
       this.dropdownListRep = [];
       insiderList.forEach(result =>this.dropdownListRep.push(result))
        
         let dropdownListSender = [];
         let senderList = this.listDpModule.map(item =>dropdownListSender.push(item.email));
 
     //insiderList.forEach(result =>this.dropdownListRep.push(result))
       from(dropdownListSender)
       .pipe(
         tap(item => item),
         distinct(d => d)
       )
       .subscribe(r => this.senderList.push(r));
         
         
           },
         //recipent Dp
           err => {
               console.log(err);
           }
       );
 
       }
 //  this.selectedRecipentbothItems =  this.recipientBothList;
 this.selectedRecipentbothItems = []
 
   }
   onFileChanged(event: any) {
 
     if (event?.target.files && event?.target.files[0]) {
       var filesAmount = event?.target.files.length;
       for (let i = 0; i < filesAmount; i++) {
         var reader = new FileReader();
         //this.selectedFileName.push(event?.target.files[i].name)
         this.selectedFileName.push(event?.target.files[i].name);
         //this.fileList.push(event?.target.files[i].name);
 
         reader.onload = (event: any) => {
           this.selectedFile.push(event.target.result);
 
 
         };
 
         reader.readAsDataURL(event.target.files[i]);
       }
     }
 
 
   }
   removeSelectedFile(index, item) {
     this.removeList.push(item);
 
     this.selectedFile.splice(index, 1);
     // delete file from FileList
     this.selectedFileName.splice(index, 1);
 
   }
   
   cancelTwcTemplate(){
     this.router.navigate(['/pit-compliance/declaration-of-trade']);
     this.dotEditForm.reset();
     this.submitted = false;
   }

 areObjectsEqual(a: any, b: any): boolean {
    const aString = JSON.stringify(a);
    const bString = JSON.stringify(b);
  
    return aString === bString;
  }
  findChangedKeyValues(objA: any, objB: any): any {
    const resultA: any = {};
    const resultB: any = {};
  
    for (const key in objA) {
      if(key != 'id'){
        if (objA.hasOwnProperty(key) && objB.hasOwnProperty(key)) {
          if (JSON.stringify(objA[key]) !== JSON.stringify(objB[key])) {
            resultA[key] = objA[key];
            resultB[key] = objB[key];
          }
        } else {
          
            resultA[key] = objA[key];
          
        }
      }
    }
  
    for (const key in objB) {
      if (objB.hasOwnProperty(key) && !objA.hasOwnProperty(key)) {
        resultB[key] = objB[key];
      }
    }
  
    return {
      "oldData": resultA,
      "newData": resultB
    };
  }
  openModalEditReason() {  
      const emptyFileds = Object.keys(this.dotEditForm.controls).filter(key =>this.dotEditForm.controls[key].errors !== null)
      if(emptyFileds.length > 1){
        this.toastr.warning("Please fill all Mandatory fields.");
        return false;
      } else if(this.dotEditForm.get('start_date')?.value > this.dotEditForm.get('end_date')?.value ){
        this.toastr.warning("Please add a valid from and To date.");
        return false;
      }else{
        if(this.dotEditForm.get('sender_name')?.value == ''){
          this.toastr.warning("Please fill the Sender Name.");
          return false;
        }else if(this.dotEditForm.get('recipient_name')?.value == ''){
          this.toastr.warning("Please fill the Recipient Name.");
          return false;
        }else if(this.dotEditForm.get('subject')?.value == ''){
          this.toastr.warning("Please fill the Subject.");
          return false;
        }  
        
      }
    const isEqual = this.areObjectsEqual(this.dotEditForm.value, this.dotOldEditForm.value);
    if(!isEqual){
      const modalRef = this.modalService.open(EditReasonComponent,
        {
          scrollable: true,
          windowClass: 'myCustomModalClass',
        });
      modalRef.result.then((result) => {
        // console.log("result", result);
        this.editReason=result;
        if(this.editReason != 'dismiss'){
          this.onSubmit();
        }
      }, (reason) => {
      });
    }
    
  }


   onSubmit(){   
     //this.dotEditForm.value.id = this.twcEditId;
     const changedKeyValues = this.findChangedKeyValues(this.dotOldEditForm.value,this.dotEditForm.value);
        
     let reqObj = {
       data:changedKeyValues,
       id:this.twcEditId,
       editReason:this.editReason
     }

     this.submitted = true;
    
     if(this.dotEditForm.status !== 'INVALID'){
     this.isLoading = true;
       this.button = 'Processing';
     this.pitService.declarationofTradeUpdate(reqObj).subscribe(data =>{   
       this.isLoading = false;
       this.button = 'Update';
       this.toastr.success("Declaration of Trade details Updated", "Success!");
         this.router.navigate(['pit-compliance/declaration-of-trade']);
       
     })
   }else{
    this.toastr.warning("Invalid Field....");
    }
   }
 
 
   //Angular Editor Setting
    config: AngularEditorConfig = {
     editable: true,
     spellcheck: true,
     height: '15rem',
     minHeight: '5rem',
     maxHeight: 'auto',
     width: 'auto',
     minWidth: '0',
     enableToolbar: true,
     showToolbar: true,
     placeholder: 'Enter text here...',
     translate: 'no',
     defaultParagraphSeparator: 'p',
     defaultFontName: 'Arial',
     defaultFontSize: '',
     toolbarHiddenButtons: [
       []
       ],
     customClasses: [
       {
         name: "quote",
         class: "quote",
       },
       {
         name: 'redText',
         class: 'redText'
       },
       {
         name: "titleText",
         class: "titleText",
         tag: "h1",
       },
     ]
   };
 
 // Multi Select
 onItemSelect(item: any,page) {
   
    if(page == "sender"){
     if(!this.selected_sender.includes(item)){
       this.selected_sender.push(item);
     }
   }else if(page == "receiver"){
     if(!this.selected_receiver?.includes(item)){
       this.selected_receiver.push(item);
     }
   }else if(page == "both"){
     if(!this.selected_both?.includes(item)){
       this.selected_both.push(item);
     }
   }
 }
 
 onItemDeSelect(item: any,page) {
  
   if(page == "sender"){
     if(this.selected_sender.includes(item)){
       this.selected_sender.splice(this.selected_sender.indexOf(item), 1);
     }
   }else if(page == "receiver"){
     if(this.selected_receiver.includes(item)){
       this.selected_receiver.splice(this.selected_receiver.indexOf(item), 1);
     }
   }else if(page == "both"){
     if(this.selected_both.includes(item)){
       this.selected_both.splice(this.selected_both.indexOf(item), 1);
     }
   }
 }
 onSelectAll(items: any,page) {
  if(page == "sender"){
     this.selected_sender=items;
   }else if(page == "receiver"){
     this.selected_receiver=items;
   }
   else if(page == "both"){
     this.selected_both=items;
   }
   
 }
 onDeSelectAll(items: any,page) {
  if(page == "sender"){
       this.selected_sender=[];
     }else if(page == "receiver"){
       this.selected_receiver=[];
     }
     else if(page == "both"){
       this.selected_both=[];
     }
 }
 
       //select radio recipient button
       selectedButtonRep: string;        
 
       radioSelectRep(e: string): void {
           this.selectedButtonRep = e;  
           if(this.selectedButtonRep == "Insider"){
             this.selectedRecipentDpItems = [];
             this.selectedRecipentbothItems = []
           }else if(this.selectedButtonRep == "DP"){
             this.selectedRecipentInsiderItems = [];
             this.selectedRecipentbothItems = []
           }else{
             this.selectedRecipentInsiderItems = [];
             this.selectedRecipentDpItems = [];
           }
       }  
       
       isSelectedRep(name: string): boolean {  
       
           if (!this.selectedButtonRep) {   
               return false;  
           }    
       
           return (this.selectedButtonRep === name); 
       } 
   //select radio recipient button
 
}
