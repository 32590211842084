
export var barChartSingle = [
  {
    "name": "Previous Week Total Sharing Count",
    "value": 894
  },
  {
    "name": "Current Week Total Sharing Count",
    "value": 500
  },
  {
    "name": "Difference of Total Sharing Count",
    "value": 720
  },
  // {
  //   "name": "Australia",
  //   "value": 650
  // }
];
export var single = [
  {
    "name": "Germany",
    "value": 8940000
  },
  {
    "name": "USA",
    "value": 5000000
  },
  {
    "name": "France",
    "value": 7200000
  }
];
export var pieChart = [
  // {
  //   name: "Log UPSI Transfer",
  //   value: 10
  // },
  // {
  //   name: "Insider",
  //   value: 8
  // },
  // {
  //   name: "DP",
  //   value: 12
  // },
  // {
  //   name: "Projects",
  //   value: 15
  // },
  
];
export var multiData = [
  {
    "name": "Germany",
    "series": [
      {
        "name": "2010",
        "value": 200
      },
      {
        "name": "2011",
        "value": 500
      },
      {
        "name": "2012",
        "value": 275
      },
      {
        "name": "2013",
        "value": 1000
      },
      {
        "name": "2014",
        "value": 650
      },
      {
        "name": "2015",
        "value": 1900
      },
      {
        "name": "2016",
        "value": 860
      }
    ]
  },

  {
    "name": "USA",
    "series": [
      {
        "name": "2010",
        "value": 100
      },
      {
        "name": "2011",
        "value": 300
      },
      {
        "name": "2012",
        "value": 1350
      },
      {
        "name": "2013",
        "value": 650
      },
      {
        "name": "2014",
        "value": 250
      },
      {
        "name": "2015",
        "value": 775
      },
      {
        "name": "2016",
        "value": 730
      }
    ]
  },

  {
    "name": "France",
    "series": [
      {
        "name": "2010",
        "value": 660
      },
      {
        "name": "2011",
        "value": 900
      },
      {
        "name": "2012",
        "value": 680
      },
      {
        "name": "2013",
        "value": 675
      },
      {
        "name": "2014",
        "value": 1500
      },
      {
        "name": "2015",
        "value": 680
      },
      {
        "name": "2016",
        "value": 690
      }
    ]
  }
];