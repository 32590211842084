import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { DbModuleListComponent } from './db-module-list/db-module-list.component';
import { DbModuleAddComponent } from './db-module-add/db-module-add.component';
import { DbModuleEditComponent } from './db-module-edit/db-module-edit.component';
import { DbModuleDeleteComponent } from './db-module-delete/db-module-delete.component';
import { DeletedDpListComponent } from './deleted-dp-list/deleted-dp-list.component';
import { DbModuleViewComponent } from './db-module-view/db-module-view.component';
import { ProfileEditDpComponent } from './profile-edit-dp/profile-edit-dp.component';

const routes: Routes = [
  {
    path: '',   
    children: [ 
      {
        path: '',
        component: DbModuleListComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "DPs List"
        }
      }, 
      {
        path: 'dp-list',
        component: DbModuleListComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "DPs List"
        }
      },  
      {
        path: 'dp-add',
        component: DbModuleAddComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "New"
        }
      },
      {
        path: 'dp-edit/:id',
        component: DbModuleEditComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "Update"
        }
      },
      {
        path: 'profile-edit/:id',
        component: ProfileEditDpComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "Profile Edit"
        }
      },
      {
        path: 'dp-view/:id',
        component: DbModuleViewComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "View"
        }
      },
      {
        path: 'dp-delete/:id',
        component: DbModuleDeleteComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "Delete"
        }
      },
      {
        path: 'dp-deleted-list',
        component: DeletedDpListComponent,
        data: {
          title: "Designated Persons (DP)",
          breadcrumb: "Deleted DPs List"
        }
      },
   
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class dbRoutingModule { }
