import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { distinct, from, Observable, tap } from 'rxjs';
import { COMPANYDB, CompanyDB } from '../../shared/data/tables/company';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/services/table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import{ GlobalComponent } from '../../auth/authentication';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerService } from '../customer.service';
import { ViewCustomerComponent } from '../view-customer/view-customer.component';
import { DeleteCustomerComponent } from '../delete-customer/delete-customer.component';
import { BulkActionPopupComponent } from '../bulk-action-popup/bulk-action-popup.component';
import { DisclaimerexcelComponent } from 'src/app/master/popup/disclaimerexcel/disclaimerexcel.component';

export interface insider {
  id: number;
  name: string;
  designation: string;
  confidentiality_agreement_entered: string;
  email: string;
}

@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.scss']
})
export class ListCustomerComponent implements OnInit {
  @ViewChild('auto') auto;
  public placeholder = 'Enter the Country Name';
  baseUrl = environment.apiUrl;
  keyword = 'name';
  keyword2 = 'designation';
  keyword3 = 'email';
  keyword4 = 'insiderType';
  keyword5 = 'confidentiality_agreement_entered';
  keyword6 = 'company_name';
  keyword7 = 'company_pan';
  public selected = [];
  page = 1;
  pageSize = 10;
  listinsider: any;
  accessSuccess: string;
  filterString = "";
  nameFilterString = '';
  nameFilterString1= '';
  nameFilterString2= '';
  nameFilterString4= '';
  nameFilterString5= '';
  nameFilterString6= '';
  nameFilterString7= '';
  filterString2 = "";
  filtered;
  filteredItem;
  searchValue: string;
  listinsiders: never[];
  filterDateItem: boolean = false;
  visible: boolean = false;
  visible2: boolean = false;
  visible3: boolean = false;
  visible4: boolean = false;
  visible5: boolean = false;
  selectedValue: any;
  searchValues: any;
  filteredList: any = [];
  filtered2: any = [];
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  insiderId: any;
  viewInsiderList: any;
  hidelist = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":4,"name":"Email ID"},{"id":7,"name":"ID Proof No."},{"id":8,"name":"Contact Number"},{"id":9,"name":"Company Name"},{"id":10,"name":"Company PAN"}];
  selectedhideItems = [];
  selected_contactNo:any;
  selected_address:any;
  show_contact=false;
  show_address=false;
  show_id = true;
  show_name = true;
  show_designation = true;
  show_email = true;
  show_insiderType = false;
  show_confidentiality_agreement_entered = false;
  show_contactNumber = false;
  show_id_proof = false;
  dropdownSettings1:IDropdownSettings;
  insiderNameFilter: any[];
  insiderRwcNameFilter: any[];
  insiderEmailNameFilter: any[];
  insiderTypeNameFilter: any[];
  insiderCaeNameFilter: any[];
  bulkuploadfolder: boolean;
  show_companyname: boolean;
  show_companypan: boolean;
  customerCompanyNameFilter: any[];
  customerCompanyPanFilter: any[];
  visible6: boolean;
  visible7: boolean;
  constructor(private customerService: CustomerService, private router: Router, private modalService: NgbModal, private activateRouter: ActivatedRoute) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
  }


  ngOnInit(): void {
   
    let accessData = sessionStorage.getItem('model_access_controller');    
    let accessModelController = JSON.parse(accessData);  
    if(accessModelController.bulkuploadfolder_access == true || accessModelController.bulkuploadfolder_access == 1){
       this.bulkuploadfolder = true;
    }else{
       this.bulkuploadfolder = false;
    }
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.customer_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.customer_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }
    this.customerService.customerList(null).subscribe(
      res => {
        
          
        if(res.message == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          this.listinsider = res.data
          this.onFilterChange()
          this.filtered = this.listinsider; 
          //name
          let insiderName = this.listinsider.map(res => res.customer_name);
          this.insiderNameFilter = [];
          from(insiderName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderNameFilter.push(r));
          //designation
          let insiderRwcName = this.listinsider.map(res => res.designation);
          this.insiderRwcNameFilter = [];
          from(insiderRwcName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderRwcNameFilter.push(r));
          //email
          let insiderEmailName = this.listinsider.map(res => res.email);
          this.insiderEmailNameFilter = [];
          from(insiderEmailName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderEmailNameFilter.push(r));

          //Insider Type
          let insiderTypeName = this.listinsider.map(res => res.insiderType);
          this.insiderTypeNameFilter = [];
          from(insiderTypeName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderTypeNameFilter.push(r));

          //Confidencial Agreement
          let insiderCaeName = this.listinsider.map(res => res.confidentiality_agreement_entered);
          this.insiderCaeNameFilter = [];
          from(insiderCaeName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderCaeNameFilter.push(r));
          //company name
          let customerCompanyName = this.listinsider.map(res => res.company_name);
          this.customerCompanyNameFilter = [];
          from(customerCompanyName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.customerCompanyNameFilter.push(r));
          //company name
          let customerCompanyPan = this.listinsider.map(res => res.company_pan);
          this.customerCompanyPanFilter = [];
          from(customerCompanyPan)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.customerCompanyPanFilter.push(r));
        }
      },
      err => {
       //console.log(err);
      }
    );
    this.selectedhideItems = [{"id":1,"name":"ID No."},{"id":2,"name":"Name"},{"id":3,"name":"Designation"},{"id":4,"name":"Email ID"}];
  //multiseclector
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };  
  }
//MultiSelect hideShow
onItemSelect(item: any,page) {
   if(page == "Contact Number"){
    if(!this.selected_contactNo.includes(item)){
      this.selected_contactNo.push(item);
    }
  }else if(page == "Address"){
    if(!this.selected_address?.includes(item)){
      this.selected_address.push(item);
    }
  }else if(page == "column"){
    if(item.id == 1){
      this. show_id = true;
    }else if(item.id == 2){
      this.show_name = true;
    }else if(item.id == 3){
      this.show_designation = true;
    }else if(item.id == 4){
      this.show_email = true;
    }else if(item.id == 5){
      this.show_insiderType = true;
    }else if(item.id == 6){
      this.show_confidentiality_agreement_entered = true;
    }else if(item.id == 7){
      this.show_id_proof = true;    
    }else if(item.id == 8){
      this.show_contactNumber = true;      
    }else if(item.id == 9){
      this.show_companyname = true;      
    }else if(item.id == 10){
      this.show_companypan = true;      
    }
  }
}

onItemDeSelect(item: any,page) {

 if(page == "Contact Number"){
    if(this.selected_contactNo.includes(item)){
      this.selected_contactNo.splice(this.selected_contactNo.indexOf(item), 1);
    }
  }else if(page == "Address"){
    if(this.selected_address.includes(item)){
      this.selected_address.splice(this.selected_address.indexOf(item), 1);
    }
  }else if(page == "column"){
    if(item.id == 1){
      this. show_id = false;
    }else if(item.id == 2){
      this.show_name = false;
    }else if(item.id == 3){
      this.show_designation = false;
    }else if(item.id == 4){
      this.show_email = false;
    }else if(item.id == 5){
      this.show_insiderType = false;
    }else if(item.id == 6){
      this.show_confidentiality_agreement_entered = false;
    }else if(item.id == 7){
      this.show_id_proof = false;    
    }else if(item.id == 8){
      this.show_contactNumber = false;   
    }else if(item.id == 9){
      this.show_companyname = false;      
    }else if(item.id == 10){
      this.show_companypan = false;      
    }
  }
  
}
onSelectAll(items: any,page) {
  if(page == "Contact Number"){
    this.selected_contactNo=items;
  }else if(page == "Address"){
    this.selected_address=items;
  }else if(page == "column"){
    this.show_id = true;
    this.show_name = true;
    this.show_designation = true;
    this.show_email = true;
    this.show_insiderType = true;
    this.show_confidentiality_agreement_entered = true; 
    this.show_contactNumber = true;
    this.show_id_proof = true;   
    this.show_companyname = true;  
    this.show_companypan = true;  
  }
  
}
onDeSelectAll(items: any,page) {
 if(page == "Contact Number"){
    this.selected_contactNo=[];
  }else if(page == "Address"){
    this.selected_address=[];
  }else if(page == "column"){
    this.show_id = false;
    this.show_name = false;
    this.show_designation = false;
    this.show_email = false;
    this.show_insiderType = false;
    this.show_confidentiality_agreement_entered = false; 
    this.show_contactNumber = false;
    this.show_id_proof = false;
    this.show_companyname = true;  
    this.show_companypan = true;  
  }
}

  onFilter() {
     
    let queryString = '';
    let url = this.baseUrl + 'customerexcel?';
    let tokenStr = sessionStorage.getItem('access_token');
    if(tokenStr){
      tokenStr = tokenStr.replace('Bearer ', '');
    }
    queryString = 'authToken='+btoa(tokenStr);
    var element = document.createElement('a');
    element.setAttribute('href', url + queryString);
    element.setAttribute('download', 'sample.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    
  } 

  selectEvent(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {      
      return insiderItem.customer_name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })

    this.visible = false;
  }
  selectEvent2(item) {
    // do something with selected item
  
    this.filtered = this.listinsider?.filter(insiderItem => {      
      return insiderItem?.designation.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible2 = false;
  }
  selectEvent3(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.email.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible3 = false;
  }
  selectEvent4(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.insiderType.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible4 = false;
  }
  selectEvent5(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.confidentiality_agreement_entered.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible5 = false;
  }
  selectEvent6(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.company_name.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible6 = false;
  }
  selectEvent7(item) {
    // do something with selected item
    this.filtered = this.listinsider?.filter(insiderItem => {
      
      return insiderItem?.company_pan.toLocaleLowerCase().match(item.toLocaleLowerCase());

    })
    
    this.visible7 = false;
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    // this.auto.close();
  }

  onFocused(e) {
    // do something
  }
  onCleared(e) {
    this.filtered = this.listinsider;
  }

  onBlur(e) {
    this.visible = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  onScrolledEnd() {
    // this.auto.close();
  }

  //2nd set
  onChangeSearch2(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
   // console.log("String: " + search);
   
  }

  onFocused2(e) {
    // do something
  }

  onScrolledEnd2() {
    // this.auto.close();
  }
  //(name, email) => this.isMatch(name) || this.isMatch(email)
  onFilterChange() {
    this.filtered = this.listinsider?.filter(res => {
     
      return res.customer_name.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.email.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase()) || res.designation.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.panNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.DocumentType.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.address.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.mobileNo.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
      || res.Record.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase())
    })

  }
  onFilterChange2() {
    this.filtered2 = this.listinsider?.filter(res => {
      
      return res.customer_name.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase())
    })

  }
  isMatch(item) {
    if (item instanceof Object) {
      return Object.keys(item).some((k) => this.isMatch(item[k]));
    } else {
      return item?.toString().indexOf(this.filterString) > -1
    }

  }
  // search(){
  //   if(this.searchValue == ""){
  //     this.ngOnInit();

  //   }else{
  //     this.listinsiders = this.listinsider.filter(res =>{
  //       return res.name.toLocaleLowerCase().match(this.searchValue)
  //     })
  //   }
  // }

  // key:string;
  // reverse :boolean = false;
  // sort(key){
  //   this.key = key;
  //   this.reverse = !this.reverse;
 
  // }
  //(click)="sort('id')", <i class="fa fa-sort"></i>

  filterData() {
    this.visible = !this.visible;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  filterData2() {
    this.visible2 = !this.visible2;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  filterData3() {
    this.visible3 = !this.visible3;
    this.visible2 = false;
    this.visible = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  filterData4() {
    this.visible4 = !this.visible4;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  filterData5() {
    this.visible5 = !this.visible5;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible6 = false;
    this.visible7 = false;
  }
  filterData6() {
    this.visible6 = !this.visible6;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible7 = false;
  }
  filterData7() {
    this.visible7 = !this.visible7;
    this.visible2 = false;
    this.visible = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
  }

  openModal(id:any) {
    
    if (id) {
      let customerId = {'id' : id};
      this.customerService.viewcustomer(customerId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(ViewCustomerComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewCustomerList = data[0];
        modalRef.result.then(
          (result) => {
            //console.log(result);
          }, (reason) => {
            //console.log(reason);
          });
      })
    }
     
  }


  openModalDelete(id:any) {    
    if (id) {
      let customerId = {'id' : id};
      this.customerService.viewcustomer(customerId).subscribe(
        ({data}) =>{
        const modalRef = this.modalService.open(DeleteCustomerComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
        modalRef.componentInstance.viewcustomerList = data[0];
        modalRef.result.then(
          (result) => {
            //console.log(result);
          }, (reason) => {
           // console.log(reason);
          });
      })
    }
     
  }
  open() {
		const modalRef = this.modalService.open(BulkActionPopupComponent,
    {
      scrollable: true,
      windowClass: 'myCustomModalClass',
      // keyboard: false,
      // backdrop: 'static'
    });
		modalRef.componentInstance.name = 'Bulk Upload';
    modalRef.result.then((result) => {
      //console.log(result);
    }, (reason) => {
    });
	}
  openExcelDisclaimer() {

    const modalRef = this.modalService.open(DisclaimerexcelComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
      });
      modalRef.componentInstance.name = 'Alert';
      modalRef.componentInstance.title = 'Customer';
      modalRef.componentInstance.onSubmitEntry.subscribe((receivedData) => {
      if(receivedData == 'Yes'){
        this.onFilter();
      }
    }, (reason) => {
    });
      
}
}
