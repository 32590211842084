<div class="modal-header">
    <h5 class="modal-title">Please Mention Reason For Edit</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-xl-12">
                                   
                                        <div class="col-md-12 mb-3">
                                            <label for="nameOfInsider">Reason to Edit</label>
                                            <!-- <input type="text" class="form-control"  id="reasonforedit" placeholder="Reason to Edit" [(ngModel)]="reason" autocomplete="off">  -->
                                            <textarea class="form-control" id="reasonforedit" rows="5" [(ngModel)]="reason" autocomplete="off" placeholder="Reason to Edit"></textarea>
                                            <div *ngIf="!isValid" class="text text-danger">
                                                Please mention the Edit Reason
                                            </div>                                      
                                        </div>
                                        <div class="submit-btn">
                                            <button class="btn btn-primary" (click)="closeModal(reason)"> {{button}}</button>   
                                            <!-- <button class="btn btn-warning" type="button" (click)="closeModal('close')">Cancel</button>                     -->
                                         </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="card-footer text-muted">
                    <b>Note:</b> UPSI entered cannot be altered. Therefore, any changes to the Project will NOT affect the UPSI Log already entered in the system.
                  </div> -->
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
