
<div class="modal-header">
    <h5 class="modal-title">Pre Clearance Approve Status </h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
               
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable1" *ngIf="trueConditions">
                        <h4 style="font-size: 19px;">Are you sure want to Re-approve Pre Clearance?</h4>
                        <div class="btn-bottom">
                            <button class="btn btn-primary me-1" value="Approved" #approval (click)="approvalStatus()" [disabled]="isLoading"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>
                            <button class="btn btn-primary me-1"  (click)="closeModal('close')">Cancel</button>
                        </div>
                    </div>

                    <div class="custom-datatable1">
                        <p style="font-size: 1rem;">{{warningmessage}}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div> -->
