import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FullLayoutComponent } from './components/layout/full-layout/full-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import 'hammerjs';
import 'mousetrap';

// services
import { NavService } from "./services/nav.service";
import { ChatService } from "./services/chat.service";
import { CustomizerService } from "./services/customizer.service";
import { TableService } from './services/table.service';
// Directives
import { ToggleFullscreenDirective } from "./directives/fullscreen.directive";
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AnnualConformModuleComponent } from './components/annual-conform-module/annual-conform-module.component';
import { StrengthCheckerComponent } from './components/change-password/strength-checker/strength-checker.component';
import { RejectAnnualReportModuleComponent } from './components/reject-annual-report-module/reject-annual-report-module.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { HelpManualComponent } from './help-manual/help-manual.component';
import { EditProfileComponent } from './components/profile/edit-profile/edit-profile.component';
import { UpdateProfileComponent } from './components/profile/update-profile/update-profile.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    BookmarkComponent,
    RightSidebarComponent,
    ContentLayoutComponent,
    FullLayoutComponent,
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    NgbdSortableHeader,
    BreadcrumbComponent,
    CustomizerComponent,
    ChangePasswordComponent,
    AnnualConformModuleComponent,
    StrengthCheckerComponent,
    RejectAnnualReportModuleComponent,
    HelpManualComponent,
    EditProfileComponent,
    UpdateProfileComponent,
    
   
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    DragulaModule.forRoot(),
    NgbModule,
    GalleryModule,
    ReactiveFormsModule,
    AngularMultiSelectModule
  ],
  exports: [
    LoaderComponent,
    FeatherIconsComponent,
    TranslateModule,
    NgbdSortableHeader
  ],
  providers: [
    NavService,
    ChatService,
    CustomizerService,
    TableService
  ]
})
export class SharedModule { }

