import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyListComponent } from '../add-company-list/add-company-list.component';
import { AddRestrictedCompanyComponent } from '../add-restricted-company/add-restricted-company.component';
import { MasterService } from '../../master.service';
import { DeleteCompanyListComponent } from '../delete-company-list/delete-company-list.component';
import { EditCompanyListComponent } from '../edit-company-list/edit-company-list.component';
import { CustomerService } from 'src/app/customer/customer.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/dp-module/shared-data.service';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';

@Component({
  selector: 'app-listed-company',
  templateUrl: './listed-company.component.html',
  styleUrls: ['./listed-company.component.scss']
})
export class ListedCompanyComponent implements OnInit {
  public active1 = 1;
  public active2 = 3;
  page = 1;
  pageSize = 10;
  allowedCompanyList = [];
  regstrictedCompanyList = [];
  filtered = [];
  filtered2 = [];
  filtered3 = [];
  filterString = "";
  filterString2 = "";
  filterString3 = "";
  restrict_company = [];
  isSelected: boolean;
  allowedcompanyCreateForm: FormGroup;
  dropdownSettings2 = {};
  labelDataList = [];
  labelDataListArr = [];
  labelDataListfilter = [];
  selected_label: any;
  isLoading = false;
  button = 'Apply';
  submitted: boolean = false;
  insiderEmailList: any[];
  selectedinsideremail = [];
  user_roles: any;
  is_access_write: boolean;
  is_access_read: boolean;
  is_access_no: boolean;
  restrictedAllow: boolean;
  constructor(private fb: FormBuilder, private customerService: CustomerService, public router: Router, private toastr: ToastrService, private modalService: NgbModal, private masterService: MasterService,
    private service: SharedDataService, private insiderService: InsiderServiceService) { }

  ngOnInit(): void {

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    
    if(this.user_roles.companylist_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.companylist_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }



    this.customerService.customerList(null).subscribe(
      res => {
        let dropdownListcustomerlist = [];
          
        if(res.message == 'Invalid User.'){
          this.router.navigate(['/auth/login']);
        }else{
          let listCustomer = res.data
          //name
          let customerName = listCustomer.map(item => item);
          customerName.forEach(result =>dropdownListcustomerlist.push(result))
          this.restrict_company = [];
          from(dropdownListcustomerlist)
          .pipe(
          tap(item => item),
          distinct(d => d.company_name)
          )
          .subscribe(r => this.restrict_company.push(r)); 
          this.filtered3 = this.restrict_company
          listCustomer.map(item =>{
            if(item.tradingpermission == 'Allowed'){
                  this.allowedCompanyList.push(item);
                  this.filtered = this.allowedCompanyList;
            }else{
              this.regstrictedCompanyList.push(item);
              this.filtered2 = this.regstrictedCompanyList;
            }
          })


        }
      },
      err => {
        console.log(err);
      }
    );
    this.allowedcompanyCreateForm = this.fb.group({
      permission: new FormControl('',Validators.required),
      companycheckedlist: new FormControl(''),
      groupLabelName: new FormControl(''),
      insideremail: new FormControl('')
    });

    this.dropdownSettings2 = {
      // singleSelection: false,
      // idField: 'item_id',
      // textField: 'item_text',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 2,
      // allowSearchFilter: true,
      singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          enableCheckAll: true,
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          allowSearchFilter: true,
          limitSelection: -1,
          clearSearchFilter: true,
          maxHeight: 197,
          itemsShowLimit: 3,
          closeDropDownOnSelection: false,
          showSelectedItemsAtTop: false,
          defaultOpen: false,
    
    };
    this.service.listDpLabelModule.subscribe(
      res => {
        this.labelDataList = [];
        let labelDataList1 = [];
        this.labelDataListfilter = [];
        if( res != null){
          labelDataList1 = res?.map(({id,dp_labelname}) =>({id,dp_labelname,item_id: id,item_text: dp_labelname}));
        }
  
        from(labelDataList1)
        .pipe(
        tap(item => item),
        distinct(d => d.item_text)
        )
        .subscribe(r => this.labelDataList.push(r));
        from(labelDataList1)
        .pipe(
        tap(item => item),
        distinct(d => d.item_text)
        )
        .subscribe(r => this.labelDataListfilter.push(r));
       
        
      }
    );

//Insider
this.insiderService.insiderList(null).subscribe(
  res => {
    
      
    if(res.message == 'Invalid User.'){
      this.router.navigate(['/auth/login']);
    }else{
      let listinsider = res.data;
      let insiderName = listinsider.map(({email, name,panNo}) =>(name + ' - ' + email + ' - ' + panNo));
      this.insiderEmailList = [];
          from(insiderName)
          .pipe(
          tap(item => item),
          distinct(d => d)
          )
          .subscribe(r => this.insiderEmailList.push(r));
   
    }
  },
  err => {
    console.log(err);
  }
);


  }

  get result() {         
    
    return this.restrict_company?.filter(item => item.checked);      
 }
 isSelectLabel(e, curItem){
   let checkedItemsArr = []
   this.restrict_company.map((item) => {
     if(e.target.checked){
       checkedItemsArr.push(curItem);
     } else if(item && item.checked && item.id !== curItem.id){
       checkedItemsArr.push(item);
     }
   });
   //const checkedLabel = this.label.nativeElement.value;
   if(checkedItemsArr.length > 0){
     this.isSelected = true;
   }else{
     this.isSelected = false;
   }
 }

 onItemSelect1(item: any,page) {
  
  if(page == "label"){
   if(!this.selected_label?.includes(item)){
     this.selected_label?.push(item);
   }
 }
  if(page == "insider"){
   if(!this.selected_label?.includes(item)){
     this.selected_label?.push(item);
     this.restrictedAllow = false;
   }
 }
}

onItemDeSelect1(item: any,page) {

 if(page == "label"){
   if(this.selected_label?.includes(item)){
     this.selected_label.splice(this.selected_label.indexOf(item), 1);
   }
 }
}
onSelectAll1(items: any,page) {
if(page == "label"){
   this.selected_label=items;
 }else if(page == "insider"){
    this.restrictedAllow = false;
  
}
}
onDeSelectAll1(items: any,page) {
if(page == "label"){
     this.selected_label=[];
   }
}

mailConfig(){
  this.allowedcompanyCreateForm.value.companycheckedlist = this.result;
   this.masterService.mailPermissionTradingCompany(this.allowedcompanyCreateForm.value).subscribe(data =>{     
    
   }) 
}
changeItem(e){
  this.restrictedAllow = false;
  
}
 onSubmit(){
  this.submitted = true;
  let selectItem = this.allowedcompanyCreateForm.value.permission;
  if(selectItem == 'Restricted'){   
    if(this.allowedcompanyCreateForm.get('insideremail').value == '' || this.allowedcompanyCreateForm.get('insideremail').value == null){
      this.restrictedAllow = true;
      this.toastr.warning("Please fill the Send email to following Insider.");
      return false;
    }   
  }
  const emptyFileds = Object.keys(this.allowedcompanyCreateForm.controls).filter(key =>this.allowedcompanyCreateForm.controls[key].errors !== null)

   this.allowedcompanyCreateForm.value.companycheckedlist = this.result;
   if(this.allowedcompanyCreateForm.status !== 'INVALID'){
    this.isLoading = true;
      this.button = 'Processing';
   this.masterService.updatePermissionTradingCompany(this.allowedcompanyCreateForm.value).subscribe(data =>{     
     this.toastr?.success("Company Tading Permission added Successfully");
     this.isLoading = false;
     this.button = 'Apply';
     if(data.success = '1'){
        this.mailConfig();
     }
      window.location.reload();
    
   }) 
  }else if(emptyFileds.length > 1){
    this.toastr.warning("Please fill all Mandatory fields.");
    return false;
  }else{
    if(this.allowedcompanyCreateForm.get('permission').value == ''){
      this.toastr.warning("Please fill the Permission of Trading for Company.");
      return false;
    }
    // if(this.allowedcompanyCreateForm.get('groupLabelName').value == ''){
    //   this.toastr.warning("Please fill the Send email to following category.");
    //   return false;
    // }
  }
 }


  onFilterChange() {
    
    this.filtered = this.allowedCompanyList?.filter(res =>{    
     return res.company_name?.toLocaleLowerCase().match(this.filterString.toLocaleLowerCase());
    }) 
   
  }
  onFilterChange2() {
    this.filtered2 = this.regstrictedCompanyList?.filter(res =>{    
     return res.company_name?.toLocaleLowerCase().match(this.filterString2.toLocaleLowerCase());
    }) 
   
  }
  onFilterChange3() {
    
    this.filtered3 = this.restrict_company?.filter(res =>{  
     return res.company_name?.toLocaleLowerCase().match(this.filterString3.toLocaleLowerCase()) || res.tradingpermission?.toLocaleLowerCase().match(this.filterString3.toLocaleLowerCase());
    }) 
   
  }

  openModal() {    
          const modalRef = this.modalService.open(AddCompanyListComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
        
             
          modalRef.componentInstance.title = 'Add Company List';
          modalRef.result.then((result) => {
          }, (reason) => {
          });
    
  }
  openModalRestricted() {    
    const modalRef = this.modalService.open(AddRestrictedCompanyComponent,
      {
        scrollable: true,
        windowClass: 'myCustomModalClass',
        // keyboard: false,
        // backdrop: 'static'
      });
  
       
    modalRef.componentInstance.title = 'Add Company List';
    modalRef.result.then((result) => {
    }, (reason) => {
    });

}
openModalDelete(id:any) {
  if (id) {
    let tradeId = {'id' : id};
    this.masterService.editPermissionTradingCompany(tradeId).subscribe(
      ({data}) =>{
        const modalRef = this.modalService.open(DeleteCompanyListComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
      
           
        modalRef.componentInstance.viewcompanyList = data[0];
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    })

    }
  
}
openModalView(id:any) {
  if (id) {
    let tradeId = {'id' : id};
    this.customerService.viewcustomer(tradeId).subscribe(
      ({data}) =>{
        const modalRef = this.modalService.open(EditCompanyListComponent,
          {
            scrollable: true,
            windowClass: 'myCustomModalClass',
            // keyboard: false,
            // backdrop: 'static'
          });
      
           
        modalRef.componentInstance.viewcompanyList = data[0];
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    })

    }
  
}
}
