<div class="modal-header">
    <h5 class="modal-title">View Mail Template</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" id="datatable">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div class="custom-datatable">
                        <table class="table">                            
                                <tbody *ngIf="viewMailTemplateList">
                                  
                                     <tr>
                                        <td scope="row">Template Name</td>
                                        <td scope="row">{{viewMailTemplateList.templatename}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Subject</td>
                                        <td scope="row">{{viewMailTemplateList.subject}}</td>                                         
                                     </tr>
                                    <tr>                                    
                                     <tr>
                                        <td scope="row" colspan="2">Body Content</td>
                                     </tr> 
                                     <tr>
                                        <td scope="row" colspan="2" [innerHTML]="html"></td>                                         
                                     </tr> 
                                     <!-- <tr>
                                        <td scope="row">Username (Action By)</td>
                                        <td scope="row">{{viewMailTemplateList.createddpname}} - {{viewMailTemplateList.createddpemail}} - {{viewMailTemplateList.createddppanno}}</td>                                         
                                     </tr>
                                     <tr>
                                        <td scope="row">Activity Date</td>
                                        <td scope="row">{{viewMailTemplateList.created_on | date:'dd-MM-yyyy hh:mm:ss'}}</td>                                         
                                     </tr>  -->
                                                                                                      
                               
                            </tbody>
                        </table>

                        <div class="checkbox">
                           
                            <div class="col-sm-12 col-xl-12">
                               <div class="card">                                                     
                                 <div class="card-body timeline">
                                   <ngb-accordion [closeOthers]="true">
                                     <ngb-panel id="static-1">
                                       <ng-template ngbPanelTitle>
                                           <span>Timeline of Changes</span>
                                         </ng-template>
                                       <ng-template ngbPanelContent>
                                        <div class="custom-datatable">
                                            <div class="timeline-wrapper"> 
                                                <ul class="StepProgress" *ngFor = "let items of timelineActivity"> 
                                                       <li class="StepProgress-item is-done">
                                                           <div class="bold time">{{items.ActionOn | date:'dd-MM-yyyy HH:mm:ss'}}</div> 
                                                           <div ><p><span class="bold">Record:</span> {{items.Record}}</p></div>
                                                           <div ><p><span class="bold">Activity:</span> {{items.Activity}}</p></div>
                                                           <div ><p><span class="bold">Reason for Change:</span> {{items.reason}}</p></div>
                                                           <div ><p><span class="bold">Action By (Username):</span> {{items.ActionBy}}</p></div>
                                                           <div ><p><span class="bold">Change Details:</span> {{items.changedetails}}</p></div>
                                                       </li>                                       
                                                </ul>
                                            </div>    
                                            
                                            <div class="list-btn2" *ngIf="timelineActivity?.length =='' ">
                                                <p>No Data Found</p>
                                            </div>
                                         
                                       </div>
 
                                       </ng-template>
                                     </ngb-panel>
                                                                         
                                   </ngb-accordion>
                                 </div>
                               </div>
                             </div>
                           
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
