<div #popup class="model-popup">
    <div class="modal-header">
        <h5 class="modal-title">UPSIs Mail Template</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
     
          <div class="row upsi-mail">
            <h5 style="text-decoration: underline;">Sender Email Template</h5>
            <div [innerHTML]="sendermailTemplate"></div> 
          </div>
          <br /><br />
          <div class="row upsi-mail">
            <h5 style="text-decoration: underline;">Recipient Email Template</h5>
            <div [innerHTML]="recipientmailTemplate"></div> 
          
          </div>
    
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div>
    
</div>