<div class="modal-header">
    <h5 class="modal-title">VIEW UPSI MAIL PREVIEW</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">  

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body custom-datatable noscroll">
                    <div *ngIf="mailLogItem != '' ">
                        <div class="custom-datatable">
                            <table class="table">                            
                                    <tbody *ngIf="mailLogItem">                                  
                                        <tr>
                                        <td scope="row">Sender Mail Id</td>
                                        <td scope="row">{{mailLogItem[0].sender}}</td>                                         
                                        </tr> 
                                        
                                        <tr>
                                            <td scope="row">Recipient Mail ID</td>
                                            <td scope="row">{{mailLogItem[0].recipient}}</td>                                         
                                        </tr>
                                        
                                        <tr>
                                            <td scope="row">Mail Status</td>
                                            <td scope="row">{{mailLogItem[0].mail_status}}</td>                                         
                                        </tr> 
                                        <tr>
                                            <td scope="row">Mail Send Date</td>
                                            <td scope="row">{{mailLogItem[0].created_date | date:'dd-MM-yyyy HH:mm:ss'}}</td>                                         
                                        </tr>     
                                </tbody>
                            </table>
                        </div>

                        <div class="mail-preview" *ngIf="mailLogItem"><br>
                            <h3 style="text-decoration: underline;">Sender Mail Preview</h3>
                            <div class="sender-mail" [innerHTML]=mailLogItem[0].body_content_sender></div><br>
                            <h3 style="text-decoration: underline;">Recipient Mail Preview</h3>
                            <div class="sender-mail" [innerHTML]=mailLogItem[0].body_content_recipient></div>
                        </div>
                    </div>
                    <div class="list-btn2" *ngIf="mailLogItem == ''">
                        <p>No Data Found</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
 </div>
</div>
<div class="modal-footer">
    <p><b>Note:</b> This is a copy of the auto generated email from the Structured Digital Database. Please check the Email settings, and internet connection and cross-verify with the recepient to ensure they have received the intended email.</p>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
