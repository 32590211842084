<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <div class="card">
                <div class="card-header">
                    <h5>View DP List</h5>
                    <a routerLink="/dp/dp-list"><button class="btn btn-primary me-2 " ><i class="fa  fa-arrow-left blink" aria-hidden="true"></i> Back</button></a>
                </div>
                <div class="card-body">
                   <div class="view-model" *ngIf="viewDbList?.data[0]">
                        <div class="row">
 
                            <div class="col-sm-12 col-xl-12">
                                <div class="card">                                  
                                  <div class="card-body">
                                    <ul ngbNav #nav="ngbNav" [(activeId)]="active1"  class="nav-tabs">
                                      <li [ngbNavItem]="1">
                                        <a ngbNavLink>DP Details</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show " id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
 
                                                <div class="col-sm-12 col-xl-12">
                                                    <div class="card">                                                     
                                                      <div class="card-body">
                                                        <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                                          <ngb-panel id="static-1">
                                                            <ng-template ngbPanelTitle>
                                                                <span>DP Basic Details</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="custom-datatable">
                                                                <table class="table">                            
                                                                        <tbody *ngIf="viewDbList?.data[0]">
                                                                            <tr>
                                                                                <td scope="row">Name</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpName}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Desig of DP</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpDesignation}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Email</td>
                                                                                <td scope="row">{{viewDbList?.data[0].email}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Contact Number</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpContactNo}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">PAN Number or Any other identifer authorized by Law</td>
                                                                                <td scope="row">{{viewDbList?.data[0].DocumentTypes}} - {{viewDbList?.data[0].dpPanNo}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Address</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpAddress}}</td>                                         
                                                                             </tr> 
                                                                          
                                                                    </tbody>
                                                                </table>
                                                            </div>
 
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-2">
                                                            <ng-template ngbPanelTitle>
                                                              <span>Historical Details</span>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="custom-datatable">
                                                                <table class="table">                            
                                                                        <tbody *ngIf="viewDbList?.data[0]">
                                                                            <tr>
                                                                                <td scope="row">Educational institution from where the DP has graduated</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpEducationalInstitute}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Name of Past Employers of DP</td>
                                                                                <td scope="row">{{viewDbList?.data[0].dpPastEmp}}</td>                                         
                                                                             </tr> 
                                                                            
                                                                          
                                                                    </tbody>
                                                                </table>
                                                            </div>
 
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-3" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Remarks</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="custom-datatable">
                                                                <table class="table">                            
                                                                        <tbody *ngIf="viewDbList?.data[0]">
                                                                            <tr>
                                                                                <td scope="row">Remarks</td>
                                                                                <td scope="row">{{viewDbList?.data[0].remarks}}</td>                                         
                                                                             </tr> 
                                                                            
                                                                          
                                                                    </tbody>
                                                                </table>
                                                            </div>
 
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static-4" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Confidentiality Agreement</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="custom-datatable">
                                                                <table class="table">                            
                                                                        <tbody *ngIf="viewDbList?.data[0]">
                                                                            <tr>
                                                                                <td scope="row">Confidentiality Agreement</td>
                                                                                <td scope="row">{{viewDbList?.data[0].confidentiality_agreement_entered}}</td>                                         
                                                                             </tr> 
                                                                             <tr *ngIf="viewDbList?.data[0].confidentiality_agreement_entered == 'Yes' ">
                                                                                <td scope="row">Confidentiality Agreement Document</td>
                                                                                <td scope="row"><a href="{{confidencialUrl}}/public/uploads/dp/{{dpId}}/{{viewDbList?.data[0].filePath}}" target="_blank">{{viewDbList?.data[0].confidentiality_agreement_attachment}}</a></td>                                         
                                                                             </tr> 
                                                                             <tr *ngIf="viewDbList?.data[0].confidentiality_agreement_entered == 'Yes' ">
                                                                               <td scope="row">Confidentiality Agreement Date</td>
                                                                               <td scope="row">{{viewDbList?.data[0].confidentiality_agreement_date | date:'dd-MM-yyyy'}}</td>                                         
                                                                            </tr> 
                                                                            
                                                                          
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                          <ngb-panel id="static53" >
                                                            <ng-template ngbPanelTitle>
                                                                <span>Name of Immediate Relative / Person with Whom DP has Material Financial Relationship</span>
                                                              </ng-template>
                                                            <ng-template ngbPanelContent>
                                                             <div class="custom-datatable">
                                                                <table class="table">                            
                                                                        <tbody *ngFor="let items of viewDbList?.data[0].immediateDpRelative">
                                                                            <tr>
                                                                                <td scope="row">Name</td>
                                                                                <td scope="row">{{items.name}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                                <td scope="row">Relationship</td>
                                                                                <td scope="row">{{items.relationship}}</td>                                         
                                                                             </tr> 
                                                                             <tr>
                                                                               <td scope="row">Mobile Number</td>
                                                                               <td scope="row">{{items.phone_no}}</td>                                         
                                                                            </tr> 
                                                                            <tr>
                                                                               <td scope="row">Document Type
                                                                               </td>
                                                                               <td scope="row">{{items.DocumentType}}</td>                                         
                                                                            </tr> 
                                                                            <tr>
                                                                               <td scope="row">Document Number</td>
                                                                               <td scope="row">{{items.pan}}</td>                                         
                                                                            </tr> 
                                                                            
                                                                          
                                                                    </tbody>
                                                                </table>
                                                               
                                                            </div>
                                                            </ng-template>
                                                          </ngb-panel>
                                                        </ngb-accordion>
                                                      </div>
                                                    </div>
                                                  </div>
 
                        
                                         </div> 
                            
                                                
                                                
                                                </div>
                                            </div>
                                      
                                        </ng-template>
                                      </li>
                                      <li [ngbNavItem]="2">
                                        <a ngbNavLink>Annual Confirmation</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                <div class="card-body">
                                                 <div class="custom-datatable">
                                                    <table class="table">                            
                                                            <tbody *ngFor="let item of viewDbList?.data[0].annualConfirmation">
                                                                <tr>
                                                                    <td scope="row">Financial Year</td>
                                                                    <td scope="row">{{item.financialYear}}</td>                                         
                                                                 </tr> 
                                                                 <tr>
                                                                    <td scope="row">Last Confirmed Date</td>
                                                                    <td scope="row">{{item.lastConformedDate}}</td>                                         
                                                                 </tr>                                                               
                                                        </tbody>
                                                    </table>

                                                    <table class="table" *ngIf="viewDbList?.data[0].annualConfirmation == '' ">                            
                                                      <tbody >
                                                          <tr>
                                                              <td scope="row">Financial Year</td>
                                                              <td scope="row">--</td>                                         
                                                           </tr> 
                                                           <tr>
                                                              <td scope="row">Last Confirmed Date</td>
                                                              <td scope="row">--</td>                                         
                                                           </tr> 
                                                  </tbody>
                                              </table>
                                                </div>
 
                                                </div>
                                                </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </li>
                                      <li [ngbNavItem]="3" >
                                        <a ngbNavLink >Access to SDD</a>
                                        <ng-template ngbNavContent>
                                          <div class="row">
                                            <div class="col-sm-12">
                                              <div class="tab-pane fade show active" id="list-home" role="tabpanel"
                                                aria-labelledby="list-home-list">
                                                    <div class="card-body">
                                                       <div class="custom-datatable">
                                                          <table class="table">                            
                                                                  <tbody *ngIf="viewDbList?.data[0]">
                                                                      <tr>
                                                                          <td scope="row">Access to SDD</td>
                                                                          <td scope="row">{{viewDbList?.data[0].accessToSdd}}</td>                                         
                                                                       </tr> 
                                                                       <tr *ngIf="viewDbList?.data[0].accessToSdd == 'Active' ">
                                                                          <td scope="row">User Type</td>
                                                                          <td scope="row">{{viewDbList?.data[0].role}}</td>                                         
                                                                       </tr> 
                                                                       <tr *ngIf="viewDbList?.data[0].accessToSdd == 'Active' ">
                                                                         <td scope="row">Project Access</td>
                                                                         <td scope="row">{{viewDbList?.data[0].project}}</td>                                         
                                                                      </tr> 
                                                                      <tr>
                                                                        <td scope="row">Created By</td>
                                                                        <td scope="row">{{viewDbList?.data[0].created_by}}</td>                                         
                                                                     </tr>
                                                                     <tr>
                                                                        <td scope="row">Created On</td>
                                                                        <td scope="row">{{viewDbList?.data[0].created_on}}</td>                                         
                                                                     </tr>
                                                                    
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </li>
                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                  </div>
                                </div>
                              </div>
 
 
                                                       
 
                            
                        </div>
               
                      </div>
                </div>
            </div>
        </div>
    </div>
 </div>
 <!-- Container-fluid Ends -->
 