import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http:HttpClient) { }
  baseUrl = environment.apiUrl
  
 customerList(userObj:any):Observable<any> {
    // return this.http.get(this.baseUrl + 'upsi/list')
    // const httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   // 'Authorization': this.accessToken
    // });
    return this.http.post(this.baseUrl + 'customer/list', userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
      // observe: 'response'
    });
  }
  addcustomer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "customer/create", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
   }
   viewcustomer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "customer/view", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
   editcustomer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + "customer/edit", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  updatecustomer(userObj:any):Observable<any>{
     return this.http.post(this.baseUrl + "customer/update", userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    })
  }
  deletecustomer(userObj:any):Observable<any>{
    return this.http.post(this.baseUrl + 'customer/delete' , userObj, {
      headers: {'Authorization': sessionStorage.getItem('access_token')}
    });
}
sendJSONToServerCustomer(reqData:any) {
  const httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': this.accessToken
  });
   return this.http.post(this.baseUrl + 'customer/indexexcelimport',reqData, {  
    headers: {'Authorization': sessionStorage.getItem('access_token')},
    observe: 'response'
  });
}
deletedCustomerList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/deletedlist", userObj, {
    headers: {'Authorization': sessionStorage.getItem('access_token')}
  })
 }
 revertCustomer(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/revertcustomer", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')}
 })
}
customerActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/customerActivityHistory", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},
   observe: 'response'
 })
}
viewCustomerActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/customerActivityHistoryView", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},
 })
}
customerModuleDashboardActivity(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/customerActivity", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},
   observe: 'response'
 })
}
customerTradingAllowedList(userObj:any):Observable<any>{
  return this.http.post(this.baseUrl + "customer/customertradingallowedlist", userObj, {
   headers: {'Authorization': sessionStorage.getItem('access_token')},
   observe: 'response'
 })
}
}
