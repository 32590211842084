import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';

@Component({
  selector: 'app-insider-activity-log',
  templateUrl: './insider-activity-log.component.html',
  styleUrls: ['./insider-activity-log.component.scss']
})
export class InsiderActivityLogComponent implements OnInit {

  user_roles: any;
  role_type: any;
  user_access: boolean = false;
  // viewInsiderActivityList: any;
  InsiderId: any;
  constructor(private insiderService:InsiderServiceService, private activateRouter:ActivatedRoute, public activeModal: NgbActiveModal, private http:HttpClient) {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')))
    this.role_type = this.user_roles.roles_name;
    if(this.user_roles.roles_name !== "Admin"){
      this.user_access = false;
    }else{
      this.user_access = true;
    }
   }

  // ngOnInit(): void {
   
  //     this.activateRouter.params.subscribe(data => {
  //       this.InsiderId = data['id']
  //     })
  //     if (this.InsiderId !== '') {
  //       this.insiderService.viewActivityInsider(this.InsiderId).subscribe(
  //         data =>{
  //         this.viewInsiderActivityList = data;
  //      []
  //       })
  
  //       }
      
  // }

  @Input() viewInsiderActivityList;



  ngOnInit() {
    // console.log(this.viewInsiderActivityList);
    
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
