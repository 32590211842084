import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {dbRoutingModule} from '../dp-module/db-routing.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DbModuleAddComponent } from './db-module-add/db-module-add.component';
import { DbModuleDeleteComponent } from './db-module-delete/db-module-delete.component';
import { DbModuleEditComponent } from './db-module-edit/db-module-edit.component';
import { DbModuleViewComponent } from './db-module-view/db-module-view.component';
import { DbModuleListComponent } from './db-module-list/db-module-list.component';
import { DeletedDpListComponent } from './deleted-dp-list/deleted-dp-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PopupModelDpComponent } from './popup-model-dp/popup-model-dp.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BulkActionPopupdpComponent } from './bulk-action-popupdp/bulk-action-popupdp.component';
import { FileDirective } from './bulk-action-popupdp/file.directive';
import { FileDropDirective } from './bulk-action-popupdp/file-drop.directive';
import { LabelPopupComponent } from './label-popup/label-popup.component';
import { RevetDpModuleComponent } from './revet-dp-module/revet-dp-module.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatDialogModule} from '@angular/material/dialog';
import { ErrorDpComponentComponent } from './error-dp-component/error-dp-component.component';
import { DpMailTemplateComponent } from './dp-mail-template/dp-mail-template.component';
import { ProfileEditDpComponent } from './profile-edit-dp/profile-edit-dp.component';
@NgModule({
  declarations: [
    DbModuleAddComponent,
    DbModuleDeleteComponent,
    DbModuleEditComponent,
    DbModuleViewComponent,
    DbModuleListComponent,
    DeletedDpListComponent,
    PopupModelDpComponent,
    BulkActionPopupdpComponent,
    FileDirective,
    FileDropDirective,
    LabelPopupComponent,
    RevetDpModuleComponent,
    ErrorDpComponentComponent,
    DpMailTemplateComponent,
    ProfileEditDpComponent
  ],
  imports: [
    CommonModule,
    dbRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    AutocompleteLibModule,
    NgSelectModule,
    MatDialogModule
  ]
})
export class DpModuleModule { }
