import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { distinct, from, tap } from 'rxjs';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { InsiderServiceService } from 'src/app/insider/insider-service.service';
import { MasterService } from 'src/app/master/master.service';
import { environment } from 'src/environments/environment';
import { UpsiService } from '../upsi.service';
import { UpsiMailTemplateComponent } from '../upsi-mail-template/upsi-mail-template.component';
import { CustomerService } from 'src/app/customer/customer.service';
import * as moment from 'moment';
import { CompanyService } from 'src/app/master/company/company.service';

@Component({
  selector: 'app-edit-upsi',
  templateUrl: './edit-upsi.component.html',
  styleUrls: ['./edit-upsi.component.scss']
})
export class EditUpsiComponent implements OnInit {
  @ViewChild('teams') teams!: ElementRef;
  @ViewChild('sdate') sdate!: ElementRef;
  isLoading = false;
  button = 'Save';
  submitted: boolean = false;
  model: NgbDateStruct;
  model2: NgbDateStruct;
  model3: NgbDateStruct;
  upsiEditForm: FormGroup;
  selectedOthers: any;
  othersSelect: boolean = false;
  othersSelectnature: boolean = false;
  selectedOthersupsi: any;
  listProject = [];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownListRep = [];
  dropdownInsiderRecipient = [];
  dropdownCustomerRecipient = [];
  dropdownDpRecipient = [];
  selectedItemsRep = [];
  dropdownSettingsRep = {};
  dropdownListRepDp = [];
  selectedItemsRepDp = [];
  dropdownSettingsRepDp = {};
  toDate: any;
  projectId = [];
  durationend: any;
  fromDate: any;
  sharingDate: any;
  listinsider: any;
  listDpModule: any;
  upsiId: any;
  attachName: any;
  path: any;
  attachmentPath: boolean;
  image: string;
  accessSuccess: string;
  listSender: any;
  today = new Date();
  todaysDataTime = '';
  minutesToAdd = 15;
  addDate: Date;
  reasonField: boolean = false;
  updateDate: string;
  hrefUrl: string;
  mailUrl: string;
  companyname: string;
  senderNameFilter: any[];
  senderDpNameFilter: any[];
  projectNameFilter: any[];
  dropdownSettingsRepAll = {};
  myDate: any;
  currentDate = new Date();
  dropdownListRepAlls = [];
  dropdownListRepAll = [];
  selectedItemsRepAll = [];
  selectedProjectName = [];
  selectedSenderName: any;
  selectedDpName: any;
  hideDialogBox = true;
  showDuplicateData = false;
  isLoadings = false;
  selectedFile = [];
  pathImage: any[];
  selectedFileName: any[] = [];
  removeList = [];
  selectedItemsRepCustomer = [];
  dropdownListRepCustomer = [];
  selectedCustomerName: any;
  senderCustomerNameFilter = [];
  modelAccess: any;
  is_access_read = false;
  is_access_write = false;
  is_access_no = false;
  user_roles;
  other_mos: any;
  other_nos: any;
  companyApplication: any;
  selectedItemsRepBoth = [];
  dropdownListRepBoth = [];
  projEndDate: string;
  projEndDateVal: boolean;
  projNoEndDateVal: boolean;
  mailPopupModel = environment.mailPopupModel;
  constructor(private datePipe: DatePipe, private fb: FormBuilder, private upsiService: UpsiService, public router: Router, private toastr: ToastrService, private masterService: MasterService, private activateRouter: ActivatedRoute,
    private insiderService: InsiderServiceService, private companyService: CompanyService, private dpService: DpModuleService, private modalService: NgbModal, private customerService: CustomerService) {
    this.accessSuccess = sessionStorage.getItem('access_success');
    this.modelAccess = JSON.parse(sessionStorage.getItem('model_access_controller'));

    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
    if (this.accessSuccess !== '1') {
      this.router.navigate(['auth/login']);
    }
    this.myDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
  }
  editDpRecipientValue(projectName = []) {
    let selectedItemsDp = [];
    this.selectedItemsRepDp = [];


    this.upsiService.dpModuleList(null).subscribe(
      res => {

        this.dropdownListRepDp = [];
        let dropdownListRepDps = [];
        this.dropdownDpRecipient = [];
        this.listDpModule = res['data']

        const dpList = this.listDpModule.map(({ id, email, name, panNo }) => ({ id, itemName: name + ' - ' + email + ' - ' + panNo }));
        this.dropdownListRepDp = [];
        dpList.map(({ id, itemName }) => {

          this.dropdownListRepDp.push({ id: id, itemName: itemName });
          this.dropdownDpRecipient.push({ id: id, itemName: itemName });



          if (projectName && projectName.length > 0) {
            projectName?.map(resStr => {

              let item = itemName?.split(' - ');
              let items = item[1].trim();

              if (items == resStr) {
                selectedItemsDp.push({ id: id, itemName: itemName });
              }
            });
          }
        });

        this.selectedItemsRepDp = selectedItemsDp;
        this.dropdownListRepDp.forEach(result => this.dropdownListRepBoth.push(result));
        this.dropdownListRepDp.forEach(result => this.dropdownListRepAll.push(result));



      },
      //recipent Dp
      err => {
        console.log(err);
      }
    );



  }
  // editBothRecipientValue(projectName = []){    
  //     let selectedItemsDp = [];
  //     this.selectedItemsRepBoth = [];    


  //     //both Api
  //     this.insiderService.dpInsiderrecipient(null).subscribe(
  //       res => {
  //         const bothListEmail = res.data;

  //       const bothList = bothListEmail.map(({dpemail, dpname,panno}) =>({itemName: dpname + ' - ' + dpemail + ' - ' + panno}));      
  //       bothList.forEach((item, index) => item.id = index + 1);

  //       this.dropdownListRepAll = [];
  //       from(bothList)
  //       .pipe(
  //         tap(item => item),
  //         distinct(d => d)
  //       )
  //       .subscribe(r => this.dropdownListRepBoth.push(r));   
  //       this.dropdownListRepBoth.map(({id,itemName}) =>{   
  //         if(projectName && projectName.length > 0) {
  //         //  console.log("projectName", projectName)      
  //         projectName?.map(resStr => {
  //              let item = itemName?.split(' - ');
  //               let items = item[1].trim();
  //              if(items == resStr){ 
  //               selectedItemsDp.push({id:id,itemName: itemName});
  //             }
  //           });        
  //         }
  //       });
  //         this.selectedItemsRepBoth = selectedItemsDp;

  //         },
  //         err => {
  //             console.log(err);
  //         }
  //     );

  // }
  // editAllRecipientValue(projectName = []){    
  //     let selectedItemsDp = [];
  //     this.selectedItemsRepAll = [];    


  //     //both Api
  //     this.insiderService.alldpInsiderrecipient(null).subscribe(
  //       res => {
  //         const bothListEmail = res.data;

  //       const bothList = bothListEmail.map(({dpemail, dpname, panno}) =>({itemName: dpname + ' - ' + dpemail + ' - ' + panno}));      
  //       bothList.forEach((item, index) => item.id = index + 1);

  //       this.selectedItemsRepBoth = [];
  //       from(bothList)
  //       .pipe(
  //         tap(item => item),
  //         distinct(d => d)
  //       )
  //       .subscribe(r => this.dropdownListRepAll.push(r));   
  //       this.dropdownListRepAll.map(({id,itemName}) =>{   
  //         if(projectName && projectName.length > 0) {
  //         //  console.log("projectName", projectName)      
  //         projectName?.map(resStr => {
  //           let item = itemName?.split(' - ');
  //               let items = item[1].trim();

  //              if(items == resStr){ 
  //               selectedItemsDp.push({id:id,itemName: itemName});
  //             }
  //           });        
  //         }
  //       });
  //         this.selectedItemsRepAll = selectedItemsDp;

  //         },
  //         err => {
  //             console.log(err);
  //         }
  //     );

  // }
  editInsiderRecipientValue(projectName = []) {
    let selectedItemsInsider = [];
    this.selectedItemsRep = [];
    this.dropdownListRep = [];
    //Insider Api
    this.upsiService.insiderList(null).subscribe(
      res => {
        this.dropdownListRep = [];
        this.dropdownInsiderRecipient = [];

        let listinsider = res.data;

        const InsiderList = listinsider.map(({ id, email, name, panNo }) => ({ id: id, itemName: name + ' - ' + email + ' - ' + panNo }));
        this.dropdownListRep = [];
        InsiderList.map(({ id, itemName }) => {
          this.dropdownListRep.push({ id: id, itemName: itemName });
          this.dropdownInsiderRecipient.push({ id: id, itemName: itemName });


          if (projectName && projectName.length > 0) {
            // console.log("projectName", projectName)      
            projectName?.map(resStr => {
              let item = itemName?.split(' - ');
              let items = item[1].trim();

              if (items == resStr) {
                selectedItemsInsider.push({ id: id, itemName: itemName });
              }
            });
          }
        });
        this.selectedItemsRep = selectedItemsInsider;

        this.dropdownListRep.forEach(result => this.dropdownListRepBoth.push(result));
        this.dropdownListRepBoth.forEach((item, index) => item.id = index + 1);
        this.dropdownListRep.forEach(result => this.dropdownListRepAll.push(result));
        this.dropdownListRepAll.forEach((item, index) => item.id = index + 1);
      },
      err => {
        console.log(err);
      }
    );

  }
  editCustomerRecipientValue(projectName = []) {
    let selectedItemsCustomer = [];
    this.selectedItemsRepCustomer = [];
    this.dropdownListRepCustomer = [];
    //Insider Api
    this.upsiService.customerList(null).subscribe(
      res => {
        this.dropdownListRepCustomer = [];
        this.dropdownCustomerRecipient = [];

        let listcustomer = res.data;

        const custometList = listcustomer.map(({ id, email, name, panNo }) => ({ id: id, itemName: name + ' - ' + email + ' - ' + panNo }));
        this.dropdownListRepCustomer = [];

        custometList.map(({ id, itemName }) => {

          this.dropdownListRepCustomer.push({ id: id, itemName: itemName });
          this.dropdownCustomerRecipient.push({ id: id, itemName: itemName });

          if (projectName && projectName.length > 0) {
            projectName?.map(resStr => {
              let item = itemName?.split(' - ');
              let items = item[1].trim();

              if (items == resStr) {
                selectedItemsCustomer.push({ id: id, itemName: itemName });
              }
            });
          }
        });
        this.selectedItemsRepCustomer = selectedItemsCustomer;
        this.dropdownListRepCustomer.forEach(result => this.dropdownListRepAll.push(result));
        this.dropdownListRepAll.forEach((item, index) => item.id = index + 1);
      },
      err => {
        console.log(err);
      }
    );

  }
  editProjectName(projectName = []) {
    let selectedProjectNames = [];
    this.upsiService.projectList(null).subscribe(
      res => {

        const listProject = res.data;
        // listProject.map((projectItem) => {

        //   let completedProjectItems = [];
        //   if(projectItem.durationto < this.myDate){            
        //     completedProjectItems.push(projectItem); 

        //   }else{
        //     const splitlistProjects = projectItem;    
        //     this.listProject.push(splitlistProjects);

        //   } 
        // });
        let projectNames = listProject?.map(res => res.project_name)
        this.projectNameFilter = [];
        from(projectNames)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.projectNameFilter.push(r));

        this.projectNameFilter?.map(res => {
          if (res == projectName) {
            this.selectedProjectName = res;

          } else {
            selectedProjectNames.push(res);
          }
        })
      },
      err => {
        console.log(err);
      }
    );
  }
  editDpSender(senderNameItem) {
    this.upsiService.dpModuleList(null).subscribe(
      res => {
        let listDpModule = res.data;
        //Sender
        let senderDpName = listDpModule?.map(res => (res.name + ' - ' + res.email + ' - ' + res.panNo))
        this.senderDpNameFilter = [];
        from(senderDpName)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.senderDpNameFilter.push(r));

        const dpList = listDpModule.map(res => {
          if (res.email == senderNameItem) {
            this.selectedDpName = (res.name + ' - ' + res.email + ' - ' + res.panNo);
          }
        });

      },
      err => {
        console.log(err);
      }
    );
  }
  editInsiderSender(senderNameItem) {
    this.upsiService.insiderList(null).subscribe(
      res => {
        this.dropdownListRep = [];
        const dropdownListRepInsiderlist = [];
        let listinsider = res.data;

        //Sender
        let senderName = listinsider?.map(res => (res.name + ' - ' + res.email + ' - ' + res.panNo))
        this.senderNameFilter = [];
        from(senderName)
          .pipe(
            tap(item => item),
            distinct(d => d)
          )
          .subscribe(r => this.senderNameFilter.push(r));

        listinsider.map(res => {
          if (res.email == senderNameItem) {
            this.selectedSenderName = (res.name + ' - ' + res.email + ' - ' + res.panNo);
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }
  editCustomerSender(senderNameItem) {
    this.upsiService.customerList(null).subscribe(
      res => {
        this.dropdownListRepCustomer = [];
        this.senderCustomerNameFilter = [];
        const dropdownListRepCustomerlist = [];
        let listCustomer = res.data;

        this.senderCustomerNameFilter = listCustomer.map(item => (item.name + ' - ' + item.email + ' - ' + item.panNo));
        const custometList = listCustomer.map(res => {
          if (res.email == senderNameItem) {
            this.selectedCustomerName = (res.name + ' - ' + res.email + ' - ' + res.panNo);
          }
        });

      },
      err => {
        console.log(err);
      }
    );
  }
  ngOnInit(): void {
    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if (this.user_roles.customer_read == '2') {
      this.is_access_write = true;
      this.is_access_read = true;
      this.is_access_no = true;
    } else if (this.user_roles.customer_read == '1') {
      this.is_access_write = false;
      this.is_access_read = true;
      this.is_access_no = true;
    }



    this.hrefUrl = environment.href;
    this.mailUrl = environment.mail;
    this.companyname = environment.companyName;

    this.activateRouter.params.subscribe(data => {
      this.upsiId = { 'id': data['id'] };
    })
    if (this.upsiId['id'] !== '') {
      this.upsiService.editUpsiTransfer(this.upsiId)
        .toPromise().then(result => {
          // var projectName = result.data[0].project_name;
          // console.log('projectName', projectName)
          // this.selectedItem = projectName;
          // this.attachName = result.data[0].fileName;
          // console.log('attachment', this.attachName);
          // this.path = this.attachName.split("/").pop();
          // console.log('path', this.path);
          this.attachmentPath = true;

          this.selectedButton2 = result.data[0].confidentiality_agreement_entered;
          this.selectedButton = result.data[0].value_of_Sender;
          this.selectedButtonRep = result.data[0].recipientValue;
          this.upsiEditForm.patchValue(result.data[0]);
          // selectedItem         
          this.listSender = result.data[0].sender;

          var res = result.data[0].nature_of_upsi;

          this.editDpSender(result.data[0].sender);
          this.editInsiderSender(result.data[0].sender);
          if (this.is_access_write == true) {
            this.editCustomerSender(result.data[0].sender);
          }
          //  if(this.selectedButton == 'Insider'){
          //       this.upsiService.insiderList(null).subscribe(
          //         res => {               
          //         this.listinsider = res.data;                  
          //         this.listinsider.map(res =>{
          //           if(res.email == result.data[0].sender){
          //             this.selectedSenderName = (res.name + ' - ' + res.email + ' - ' + res.panno);
          //           }
          //         });      

          //       //this.selectedSenderName = result.data[0].sender;
          //     },
          //         err => {
          //             console.log(err);
          //         }
          //     );
          //  }else if(this.selectedButton == 'DP'){
          //     this.upsiService.dpModuleList(null).subscribe(
          //       res => {

          //         this.listDpModule = res['data']

          //         const dpList = this.listDpModule.map(res =>{
          //           if(res.email == result.data[0].sender){
          //             this.selectedDpName = (res.dpName + ' - ' + res.email + ' - ' + res.dpPanNo);
          //           }
          //         });

          //    // this.selectedDpName = result.data[0].sender;
          //         },
          //         err => {
          //             console.log(err);
          //         }
          //     );
          //  }else{
          //     this.upsiService.customerList(null).subscribe(
          //       res => {
          //         this.dropdownListRepCustomer = [];

          //       let listcustomer = res.data;  

          //       const custometList = listcustomer.map(res =>{
          //         if(res.email == result.data[0].sender){
          //           this.selectedCustomerName = (res.customer_name + ' - ' + res.email + ' - ' + res.panno);
          //         }
          //       });      

          //     //this.selectedCustomerName = result.data[0].sender;

          //         },
          //         err => {
          //             console.log(err);
          //         }
          //     );
          //  }

          //this.selectedProjectName =  result.data[0].project_name;


          this.editProjectName(result.data[0].project_name);
          this.projectchangeFn(result.data[0].project_name);
          this.toDate = result.data[0].to_date;
          let othermodeofsharing = result.data[0]?.mode_of_sharing;
          if (othermodeofsharing == "Others") {
            this.othersSelect = true;
            this.other_mos = result.data[0]?.others_mos;
          } else {
            this.othersSelect = false;
          }
          let otherNatureofUpsi = result.data[0]?.nature_of_upsi;
          otherNatureofUpsi?.map(item => {
            if (item == "Others") {
              this.othersSelectnature = true;
              this.other_nos = result.data[0]?.others_nos;
            } else {
              this.othersSelectnature = false;
            }
          })


          if (result.data[0].to_date == null || result.data[0].to_date == '' || result.data[0].to_date == '0000-00-00') {
            this.upsiEditForm.get('public_Date').setValue('No')
            this.selectedButton3 = 'No';
          } else {
            this.upsiEditForm.get('public_Date').setValue('Yes')
            this.selectedButton3 = 'Yes';
          }
          //attachment file

          if (result.data[0]?.confidentiality_agreement_attachment != '') {
            let selectedFileName = result.data[0]?.confidentiality_agreement_attachment;
            this.selectedFileName = selectedFileName?.split(',');
          }
          let selectedItems = [];

          res.map(resStr => {
            let filteredItem = this.dropdownList.filter(dropdownItem => dropdownItem.itemName == resStr)[0];
            if (filteredItem && filteredItem.id) {
              selectedItems.push(filteredItem);
            }
          })
          this.selectedItems = selectedItems;
          this.editDpRecipientValue(result.data[0].recipient);
          // this.editBothRecipientValue(result.data[0].recipient)
          this.editInsiderRecipientValue(result.data[0].recipient);
          if (this.is_access_write == true) {
            this.editCustomerRecipientValue(result.data[0].recipient);
          }
          // this.editAllRecipientValue(result.data[0].recipient)
          this.onSelectedDate();
          this.fromDate = result.data[0].date_of_sharing;

        })
      this.upsiEditForm = this.fb.group({
        project_name: new FormControl('', Validators.required),
        value_of_Sender: new FormControl('DP', Validators.required),
        sender: new FormControl('', Validators.required),
        mode_of_sharing: new FormControl('', Validators.required),
        others_sharing: new FormControl('', Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
        recipientValue: new FormControl('DP', Validators.required),
        recipient: new FormControl('', Validators.required),
        purpose_of_sharing: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')]),
        nature_of_upsi: new FormControl('', Validators.required),
        others_nature_upsi: new FormControl('', Validators.pattern('^[a-zA-Z0-9 _.,/()#&-]+$')),
        type_of_sharing: new FormControl('', Validators.required),
        date_of_sharing: new FormControl('', Validators.required),
        delay_time: new FormControl(''),
        from_date: new FormControl('', Validators.required),
        to_date: new FormControl('', [this.dateRangeValidator]),
        // confidentiality_agreement_entered: new FormControl ('No',  Validators.required),
        // confidentiality_agreement_date: new FormControl (''),
        // confidentiality_agreement_attachment: new FormControl (''),
        // filePath: new FormControl (''),
        remarks: new FormControl('', Validators.pattern('^[a-zA-Z\n0-9 _.,/()#&-]+$')),
        public_Date: new FormControl('No'),
        agreement_attachment: new FormControl(),
        checkboxgroup: this.fb.group({
          checkbox: [false, Validators.required]
        })
      });
    }
    this.activateRouter.params.subscribe(data => {
      this.projectId = data['id']
    })
    //Project api
    //     this.masterService.projectList(null).subscribe(
    //       res => {

    //       const listProject = res.body['data']

    //       listProject.map((projectItem) => {

    //         let completedProjectItems = [];

    //         if(projectItem.durationto < this.myDate){            
    //           completedProjectItems.push(projectItem); 

    //         }else{
    //           const splitlistProjects = projectItem;    
    //           this.listProject.push(splitlistProjects);

    //         } 
    //       });


    // let projectName = this.listProject?.map(res =>res.project_name)
    // this.projectNameFilter = [];
    // from(projectName)
    // .pipe(
    // tap(item => item),
    // distinct(d => d)
    // )
    // .subscribe(r => this.projectNameFilter.push(r));

    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     );
    this.dropdownListRepAll = [];
    this.dropdownListRepAlls = [];
    //Insider Api
    //     this.upsiService.insiderList(null).subscribe(
    //       res => {
    //         this.dropdownListRep = [];

    //       const dropdownListRepInsiderlist = [];
    //       this.listinsider = res.data;  

    //       //Sender
    //       let senderName = this.listinsider?.map(res =>(res.name + ' - ' + res.email + ' - ' + res.panno))
    // this.senderNameFilter = [];
    // from(senderName)
    // .pipe(
    // tap(item => item),
    // distinct(d => d)
    // )
    // .subscribe(r => this.senderNameFilter.push(r));
    //       //Sender


    //       // const insiderList = this.listinsider.map(({id,email}) =>({id,itemName: email}));      

    //       // insiderList.forEach(result =>dropdownListRepInsiderlist.push(result))
    //       // from(dropdownListRepInsiderlist)
    //       // .pipe(
    //       //   tap(item => item),
    //       //   distinct(d => d.itemName)
    //       // )
    //       // .subscribe(r => this.dropdownListRep.push(r));   
    //       // // const insiderListAll = this.listinsider.map(item =>({item: item.email}));
    //       // this.dropdownListRep.forEach(result =>this.dropdownListRepAlls.push(result))

    //         },
    //         err => {
    //             console.log(err);
    //         }
    //     );
    //DP Apiinsider
    // this.upsiService.dpModuleList(null).subscribe(
    //   res => {
    //   this.listDpModule = res.data;
    //    //Sender
    //    let senderDpName = this.listDpModule?.map(res =>(res.dpName + ' - ' + res.email + ' - ' + res.dpPanNo))
    //    this.senderDpNameFilter = [];
    //    from(senderDpName)
    //    .pipe(
    //    tap(item => item),
    //    distinct(d => d)
    //    )
    //    .subscribe(r => this.senderDpNameFilter.push(r));

    //          //Sender
    //   const dpList = this.listDpModule.map(({id,email, dpName,dpPanNo}) =>({id,itemName: dpName + ' - ' + email + ' - ' + dpPanNo}));
    //   //this.selectedItemsRepDp = [];

    // // const dropdownListReprecipientlist = [];
    // //   dpList.forEach(result =>dropdownListReprecipientlist.push(result))
    // //   from(dropdownListReprecipientlist)
    // //       .pipe(
    // //         tap(item => item),
    // //         distinct(d => d.itemName)
    // //       )
    // //       .subscribe(r => this.dropdownListRepDp.push(r));
    // //       // const dpListAll = this.listDpModule.map(item =>({item: item.email}));
    // //       this.dropdownListRepDp.forEach(result =>this.dropdownListRepAlls.push(result))

    // //   // const dropdownListRepAllss = this.dropdownListRepAlls.map(({id,itemName}) =>({id,itemName: itemName}));
    // //   this.dropdownListRepAlls.forEach((item, index) => item.id = index + 1);

    //   // from(this.dropdownListRepAlls)
    //   //     .pipe(
    //   //       tap(item => item),
    //   //       distinct(d => d.itemName)
    //   //     )
    //   //     .subscribe(r => this.dropdownListRepAll.push(r));

    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    //Both Api

    // this.insiderService.dpInsiderrecipient(null).subscribe(
    //   res => {
    //     const bothListEmail = res.data;

    //   const bothList = bothListEmail.map(({dpemail}) =>({itemName: dpemail}));      
    //   bothList.forEach((item, index) => item.id = index + 1);

    //   this.dropdownListRepAll = [];
    //   from(bothList)
    //   .pipe(
    //     tap(item => item),
    //     distinct(d => d)
    //   )
    //   .subscribe(r => this.dropdownListRepAll.push(r));   


    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );
    //customer
    // this.upsiService.customerList(null).subscribe(
    //   res => {
    //     this.dropdownListRepCustomer = [];
    //     this.senderCustomerNameFilter = [];
    //   const dropdownListRepCustomerlist = [];
    //   this.listinsider = res.data;  

    // this.senderCustomerNameFilter = this.listinsider.map(item =>(item.customer_name + ' - ' + item.email + ' - ' + item.panno)); 


    //     },
    //     err => {
    //         console.log(err);
    //     }
    // );

    this.companyService.CompanyList(null).subscribe(
      res => {
        if (res.body['message'] == 'Invalid User.') {
          this.router.navigate(['/auth/login']);
        } else {
          let listCompany = res.body['data']
          this.companyApplication = listCompany[0].customertype;
        }
      },
      err => {
        console.log(err);
      }
    );

    // Nature of UPSI Dropdown
    this.dropdownList = [
      { id: 1, itemName: 'Financial results' },
      { id: 2, itemName: 'Dividends' },
      { id: 3, itemName: 'Change in capital structure' },
      { id: 4, itemName: 'Mergers, de-mergers & acquisitions' },
      { id: 5, itemName: 'Delistings' },
      { id: 6, itemName: 'Disposals' },
      { id: 7, itemName: 'Expansion of business' },
      { id: 8, itemName: 'Changes in key managerial personnel' },
      { id: 9, itemName: 'Others' },
    ];
    this.selectedItems = [];
    this.dropdownSettings = {
      text: 'Nature of UPSI',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Nature of UPSI Dropdown

    //Recipient dropdown
    // this.dropdownListRep = [
    //   { id: 1, itemName: 'Recipient 1' },
    //   { id: 2, itemName: 'Recipient 2' },
    //   { id: 3, itemName: 'Recipient 3' },
    //   { id: 4, itemName: 'Recipient 4' },
    //   { id: 5, itemName: 'Recipient 5' },
    //   { id: 6, itemName: 'Recipient 6' },
    //   { id: 7, itemName: 'Recipient 7' },
    //   { id: 8, itemName: 'Recipient 8' },
    //   { id: 9, itemName: 'Recipient 9' },
    // ];
    this.selectedItemsRep = [];
    this.dropdownSettingsRep = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Recipient dropdown
    //Recipient Dp dropdown
    // this.dropdownListRepDp = [
    //   { id: 1, itemName: 'Recipient Dp 1' },
    //   { id: 2, itemName: 'Recipient Dp 2' },
    //   { id: 3, itemName: 'Recipient Dp 3' },
    //   { id: 4, itemName: 'Recipient Dp 4' },
    //   { id: 5, itemName: 'Recipient Dp 5' },
    //   { id: 6, itemName: 'Recipient Dp 6' },
    //   { id: 7, itemName: 'Recipient Dp 7' },
    //   { id: 8, itemName: 'Recipient Dp 8' },
    //   { id: 9, itemName: 'Recipient Dp 9' },
    //   { id: 10, itemName: 'Recipient Dp 10' },
    // ];
    this.selectedItemsRepDp = [];
    this.dropdownSettingsRepDp = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    this.selectedItemsRepAll = [];
    this.dropdownSettingsRepAll = {
      singleSelection: false,
      text: 'Select Recipient',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
      badgeShowLimit: 3
    };
    //Recipient Dp dropdown
  }
  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from2 = this.upsiEditForm && this.upsiEditForm.get("from_date").value;
    const from = moment(from2).format('YYYY-MM-DD');
    const to = this.upsiEditForm && this.upsiEditForm.get("to_date").value;
    if (from && to) {
      if (new Date(from).valueOf() == new Date(to).valueOf()) {
        invalid = false;
      } else if (new Date(from).valueOf() > new Date(to).valueOf()) {
        invalid = true;
      } else {
        invalid = false;
      }
      //invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  onItemSelect(item: any) {

    if (item.itemName == "Others") {
      this.othersSelectnature = true;
    } else {
      this.othersSelectnature = false;
      this.upsiEditForm.get('others_nature_upsi').setValue("");

    }
  }
  OnItemDeSelect(item: any) {

    if (item.itemName == "Others") {
      this.othersSelectnature = false;
      this.upsiEditForm.get('others_nature_upsi').setValue("");
    } else {
      this.othersSelectnature = true;

    }
  }
  onSelectAll(items: any) {

    if (items[8].itemName == "Others") {
      this.othersSelectnature = true;
    } else {
      this.othersSelectnature = false;
      this.upsiEditForm.get('others_nature_upsi').setValue("");

    }
  }
  onDeSelectAll(items: any) {

    this.othersSelectnature = false;
    this.upsiEditForm.get('others_nature_upsi').setValue("");

    // if(items[8].itemName == "Others"){
    //   this.othersSelectnature = false;
    // }else{
    //   this.othersSelectnature = true;
    // }
  }
  // Nature of UPSI Dropdown
  //Recipient dropdown
  onItemSelectRep(item: any) {
    console.log(item);

  }
  OnItemDeSelectRep(item: any) {
    console.log(item);


  }
  onSelectAllRep(items: any) {
    console.log(items);
  }
  onDeSelectAllRep(items: any) {
    console.log(items);
  }
  //Recipient dropdown

  //Recipient Dp dropdown
  onItemSelectRepDp(item: any) {
    console.log(item);

  }
  OnItemDeSelectRepDp(item: any) {
    console.log(item);


  }
  onSelectAllRepDp(items: any) {
    console.log(items);
  }
  onDeSelectAllRepDp(items: any) {
    console.log(items);
  }
  //Recipient Dp dropdown
  //Recipient Dp dropdown
  onItemSelectRepAll(item: any) {
    console.log(item);

  }
  OnItemDeSelectRepAll(item: any) {
    console.log(item);


  }
  onSelectAllRepAll(items: any) {
    console.log(items);
  }
  onDeSelectAllRepAll(items: any) {
    console.log(items);
  }
  //Recipient Dp dropdown


  selectedItem: any;
  i: number;
  //Search Project
  onSelected(item) {

    let item1 = this.listProject.find(i => i.project_name === item);

    if (item1.project_name === item) {
      this.toDate = item1.durationto;

    } else {
      console.log("Error");
    }

  }
  //Noemal
  // onSelected(){
  //   // this.selectedItem = this.teams.nativeElement.value;  

  //   let item1 = this.listProject.find(i => i.project_name === this.teams.nativeElement.value);

  //   if(item1.project_name === this.teams.nativeElement.value){
  //      this.toDate = item1.durationto;

  //   }else{    console.log("Error");
  //   }

  // }
  onSelectedDate() {
    this.fromDate = this.sdate?.nativeElement.value;
    //   
    // let addDate = new Date(this.today.getTime() - this.minutesToAdd*60000);    
    //     let datepicker = this.upsiEditForm.get('date_of_sharing').value;   
    //     let updateDate = this.convert(addDate);    
    //     if(datepicker < updateDate){
    //         this.reasonField = true;
    //     }else{
    //        this.reasonField = false;
    //     }


  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${mnth}-${day}T${hours}:${minutes}`
  }

  modeOfSharingChanged() {
    this.selectedOthers = this.upsiEditForm.get('mode_of_sharing')

    if (this.selectedOthers.value == "Others") {
      this.othersSelect = true;
    } else {
      this.othersSelect = false;
      this.upsiEditForm.get('others_sharing').setValue("");

    }
  }

  projectchangeFn(val) {
    this.masterService.projectList(null).subscribe(
      res => {

        const listProject = res.body['data']
        listProject.map((projectItem) => {
          let completedProjectItems = [];
          if (projectItem.durationto < this.myDate) {
            completedProjectItems.push(projectItem);

          } else {
            const splitlistProjects = projectItem;
            this.listProject.push(splitlistProjects);

          }
        });
        this.listProject?.map(res => {

          if (res.project_name == val) {
            if (res.durationto == '' || res.durationto == null) {
              this.projEndDate = 'Not Provided';
              this.projEndDateVal = false;
              this.projNoEndDateVal = true;

            } else {
              this.projEndDate = res.durationto;
              this.projEndDateVal = true;
              this.projNoEndDateVal = false;

            }

          }

        })


      },
      err => {
        console.log(err);
      }
    );

  }

  //  natureOfUpsiChanged() {
  //     this.selectedOthersupsi = this.upsiEditForm.get('nature_of_upsi')
  //     // console.log("Hii", this.selectedOthersupsi)
  //     if(this.selectedOthersupsi.value == "Others"){
  //       this.othersSelectnature = true;
  //     }else{
  //       this.othersSelectnature = false;
  //     }
  //   }
  // onDateSelect(event) {
  //   let year = event.year;
  //   let month = event.month <= 9 ? '0' + event.month : event.month;;
  //   let day = event.day <= 9 ? '0' + event.day : event.day;;
  //   let finalDate = year + "-" + month + "-" + day;
  //   console.log("Hi", finalDate)
  // } 

  //select radio button
  selectedButton: string;

  radioSelect(e: string): void {
    this.selectedButton = e;
    if (this.selectedButton == 'Insider') {
      this.selectedDpName = [];
      this.selectedItemsRepCustomer = [];
    } else if (this.selectedButton == 'DP') {
      this.selectedSenderName = [];
      this.selectedCustomerName = [];
    } else if (this.selectedButton == 'Customer') {
      this.selectedSenderName = [];
      this.selectedDpName = [];
    }
  }

  isSelected(name: string): boolean {

    if (!this.selectedButton) {
      return false;
    }

    return (this.selectedButton === name);
  }
  //select radio recipient button
  selectedButtonRep: string = "Insider";

  radioSelectRep(e: string): void {
    this.selectedButtonRep = e;
    if (this.selectedButtonRep == 'Insider') {
      this.selectedItemsRepDp = [];
      this.selectedItemsRepCustomer = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'DP') {
      this.selectedItemsRep = [];
      this.selectedItemsRepCustomer = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'Customer') {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepBoth = [];
    } else if (this.selectedButtonRep == 'Both') {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepAll = [];
      this.selectedItemsRepCustomer = [];
    } else {
      this.selectedItemsRep = [];
      this.selectedItemsRepDp = [];
      this.selectedItemsRepCustomer = [];
      this.selectedItemsRepBoth = [];
    }
  }

  isSelectedRep(name: string): boolean {

    if (!this.selectedButtonRep) {
      return false;
    }

    return (this.selectedButtonRep === name);
  }
  //select radio recipient button
  //COnfidential date
  selectedButton2: string = "No";

  radioSelect2(e: string): void {
    this.selectedButton2 = e;
  }

  isSelected2(name: string): boolean {

    if (!this.selectedButton2) {
      return false;
    }

    return (this.selectedButton2 === name);
  }

  // Attachment file code
  //Todate
  selectedButton3: string;

  radioSelect3(e: string): void {
    this.selectedButton3 = e;

    if (this.selectedButton3 !== "Yes") {
      this.upsiEditForm.get('to_date').setValue("");
    }
  }

  isSelected3(name: string): boolean {

    if (!this.selectedButton3) {
      return false;
    }

    return (this.selectedButton3 === name);
  }




  base64Output: string;

  filePath() {
    if (this.upsiEditForm.get('confidentiality_agreement_attachment').value != '') {
      this.attachName = this.upsiEditForm.get('confidentiality_agreement_attachment').value;

      this.path = this.attachName.split("\\").pop();

    }
    this.attachmentPath = true;
  }
  removeFile() {
    this.upsiEditForm.get('confidentiality_agreement_attachment').setValue("");
    this.path = "";
    // this.attachName = "";
    // this.userPhoto.nativeElement.value = null;
    this.attachmentPath = false;

  }


  // changeListener($event) : void {   
  //   this.readThis($event.target);
  //   this.filePath()
  // }

  // readThis(inputValue: any): void {
  //   var file:File = inputValue.files[0];
  //   var myReader:FileReader = new FileReader(); 
  //   myReader.readAsDataURL(file);
  //   myReader.onloadend = (e) => {
  //     this.image = btoa(myReader.result.toString());    
  //     this.upsiEditForm.value.image=myReader.result;
  //   }
  // }
  getCurrentDate() {
    // let todaysDataTime = formatDate(this.today, 'hh:mm:ss a', 'en-US', '+0530');
    // let addDate = new Date(this.today.getTime() + this.minutesToAdd*60000);
    // console.log("addDate", this.addDate )
    // let datepicker = this.upsiEditForm.get('date_of_sharing').value
    // if(addDate < datepicker ){
    //     this.reasonField = true;
    // }else{
    //    this.reasonField = true;
    // }
  }
  onFileChanged(event: any) {

    if (event?.target.files && event?.target.files[0]) {
      var filesAmount = event?.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        //this.selectedFileName.push(event?.target.files[i].name)
        this.selectedFileName.push(event?.target.files[i].name);
        //this.fileList.push(event?.target.files[i].name);

        reader.onload = (event: any) => {
          this.selectedFile.push(event.target.result);


        };

        reader.readAsDataURL(event.target.files[i]);
      }
    }


  }
  removeSelectedFile(index, item) {
    this.removeList.push(item);

    this.selectedFile.splice(index, 1);
    // delete file from FileList
    this.selectedFileName.splice(index, 1);

  }
  onBlur(): void {
    console.log('Focus Is Lost for this Element');

    // var div = document.getElementsByClassName("autocomplete-container active");


    // while (div.length) {

    //   div[0].classList.remove("active");

    // }

    // document.getElementById('suggestions').classList.remove('is-visible');
    // document.getElementById('suggestions').classList.add('is-hidden');
    // document.getElementById('suggestions-history').classList.remove('is-hidden');
    // document.getElementById('suggestions-history').classList.add('is-visible');


  }
  //role
  selectEvent(item) {
    if (item == '') {
    }
  }

  onChangeSearch(search: string) {
  }

  onFocused(e) {
    // do something
    // var div = document.getElementsByClassName("autocomplete-container");


    // while (div.length) {

    //   div[0].classList.add("active");

    // }

    // document.getElementById('suggestions').classList.remove('is-hidden');
    // document.getElementById('suggestions').classList.add('is-visible');
    // document.getElementById('suggestions-history').classList.remove('is-visible');
    // document.getElementById('suggestions-history').classList.add('is-hidden');

  }
  mailConfifDunction(valId) {
    this.upsiEditForm.value.upsiId = valId;
    this.upsiService.mailUpsiTransfer(this.upsiEditForm.value).subscribe(data => {
      if (this.mailPopupModel == 'LSTEST') {
        if (data.sendermailTemplate != '' && data.recipientmailTemplate != '') {
          const modalRef = this.modalService.open(UpsiMailTemplateComponent,
            {
              scrollable: true,
              windowClass: 'myCustomModalClass',
              // keyboard: false,
              // backdrop: 'static'
            });
          modalRef.componentInstance.viewUpsimailTemplate = data;
          modalRef.result.then(
            (result) => {
              console.log(result);
            }, (reason) => {
              console.log(reason);
            });
        }
      }

    });

  }

  cancelUpsi() {
    this.router.navigate(['/upsi/upsi-list']);
    this.upsiEditForm.reset();
    this.submitted = false;
  }
  // onSubmitData() {
  //   this.upsiEditForm.value.hrefLink = this.hrefUrl; 
  //   this.upsiEditForm.value.mailId  = this.mailUrl;
  //   this.upsiEditForm.value.companyName = this.companyname;
  //   if(this.upsiEditForm.get('public_Date').value == 'Yes'){     
  //     if(this.upsiEditForm.get('to_date').value == ''){   
  //       this.upsiEditForm.get('to_date').value.error ;
  //       alert( "Please provide your Date when made public!" );
  //       return false;
  //     }
  //   }
  //   this.submitted = true;
  //   if (this.upsiEditForm.status !== 'INVALID') {
  //     this.isLoading = true;
  //   this.button = 'Processing';
  //     this.upsiService.updateUpsiTransfer(this.upsiId, this.upsiEditForm.value).subscribe(data => {
  //       this.isLoading = false;
  //     this.button = 'Update';
  //     this.toastr.success("UPSIs Successfully updated");
  //     this.router.navigate(['/upsi/upsi-list']);

  //       // if (this.upsiEditForm.valid) {
  //       //   this.toastr.success("UPSIs Successfully updated");

  //       // } else {
  //       //   this.toastr.warning("UPSI details not updated", "Alert!");
  //       // }
  //     })
  //   }else{
  //     if(this.upsiEditForm.get('project_name').value == ''){
  //       this.toastr.warning("Please fill the Project Name.");
  //     }else if(this.upsiEditForm.get('sender').value == ''){
  //       this.toastr.warning("Please fill the Name of Sender.");
  //     }
  //     else if(this.upsiEditForm.get('recipient').value == ''){
  //       this.toastr.warning("Please fill the Name of Recipient.");
  //     }
  //     else if(this.upsiEditForm.get('mode_of_sharing').value == ''){
  //       this.toastr.warning("Please fill the Mode of Sharing.");
  //     }
  //     else if(this.upsiEditForm.get('purpose_of_sharing').value == ''){
  //       this.toastr.warning("Please fill the Purpose of Sharing.");
  //     }
  //     else if(this.upsiEditForm.get('nature_of_upsi').value == ''){
  //       this.toastr.warning("Please fill the Nature of UPSI.");
  //     }
  //     else if(this.upsiEditForm.get('type_of_sharing').value == ''){
  //       this.toastr.warning("Please fill the Type of sharing.");
  //     }
  //     else if(this.upsiEditForm.get('nature_of_upsi').value == ''){
  //       this.toastr.warning("Please fill the Sender Name.");
  //     }
  //     else if(this.upsiEditForm.get('date_of_sharing').value == ''){
  //       this.toastr.warning("Please fill the Date of Sharing.");
  //     }
  //     else if(this.upsiEditForm.get('from_date').value == ''){
  //       this.toastr.warning("Please fill the From Date.");
  //     }
  //     else if(this.upsiEditForm.get('to_date').value == ''){
  //       this.toastr.warning("Please fill the Date when made public.");
  //     }

  //   }
  // }
  duplicateUpsi() {
    this.hideDialogBox = false;
    this.showDuplicateData = true;
  }
  closeModal() {
    this.router.navigate(['/upsi/upsi-list']);
    this.hideDialogBox = true;
    this.showDuplicateData = false;
  }

  onSubmit() {
    let regex = new RegExp('^[a-zA-Z\n0-9 _.,-/()#&]+$');
    let remarks = this.upsiEditForm.get('remarks').value;
    let pos = this.upsiEditForm.get('purpose_of_sharing').value;
    let othermos = this.upsiEditForm.get('others_sharing').value;
    let othernos = this.upsiEditForm.get('others_nature_upsi').value;

    if (remarks != '') {
      if (!regex.test(remarks)) {
        this.toastr.warning("UPSI Details Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (pos != '') {
      if (!regex.test(pos)) {
        this.toastr.warning("Legitimate Purpose of Sharing Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (othernos != '') {
      if (!regex.test(othernos)) {
        this.toastr.warning("Nature of UPSI  Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (othermos != '') {
      if (!regex.test(othermos)) {
        this.toastr.warning("Mode of Sharing Special Charactor Not Allowed!");
        // this.messageValid = 'Please Enter the Valid PAN.';
        return false;
      }
    }
    if (this.upsiEditForm.get('recipient').value !== '') {

      let recipientVal = this.upsiEditForm.get('recipient').value;
      let senderVal = this.upsiEditForm.get('sender').value;
      if (recipientVal.length > 0) {
        for (let i = 0; i < recipientVal.length; i++) {
          if (recipientVal[i].itemName == senderVal) {
            this.toastr.warning("Sender and Recipient Can not be same.");
            return false;
          }
        }
      }

    }
    let dateofSharing = this.upsiEditForm.get('date_of_sharing').value;
    if (this.projEndDate < this.datePipe.transform(dateofSharing, 'yyyy-MM-dd')) {
      this.toastr.warning("Date of Sharing should not be greater than Project end date.");
      return false;
    }
    this.upsiEditForm.value.agreement_attachment = this.selectedFile;
    this.upsiEditForm.value.attachmentfilename = this.selectedFileName;
    this.upsiEditForm.value.attachmentRemoveList = this.removeList;
    this.upsiEditForm.value.id = this.upsiId['id'];
    this.upsiEditForm.value.hrefLink = this.hrefUrl;
    this.upsiEditForm.value.mailId = this.mailUrl;
    this.upsiEditForm.value.companyName = this.companyname;
    if (this.upsiEditForm.get('public_Date').value == 'Yes') {
      if (this.upsiEditForm.get('to_date').value == '' || this.upsiEditForm.get('to_date').value == undefined) {
        // this.upsiEditForm.get('to_date').value.error ;
        this.toastr.warning("Please provide your Date when made public!");
        return false;
      }
    }
    const emptyFileds = Object.keys(this.upsiEditForm.controls).filter(key => this.upsiEditForm.controls[key].errors !== null)
    this.submitted = true;
    if (this.upsiEditForm.status !== 'INVALID') {
      this.isLoading = true;
      this.button = 'Processing';
      this.upsiService.updateUpsiTransfer(this.upsiEditForm.value).subscribe(data => {
        this.toastr.success("UPSIs Successfully created");
        this.isLoading = false;
        this.button = 'Save';
        let upsiId = data.upsiId;
        this.mailConfifDunction(upsiId);
        this.router.navigate(['/upsi/upsi-list']);
        // if (this.upsiEditForm.valid) {
        //   this.toastr.success("UPSI details created", "Success!");
        //   this.router.navigate(['/upsi/upsi-list']);
        // } else {
        //   this.toastr.warning("UPSI details not created", "Alert!");
        // }
      })
      return false;
    } else if (emptyFileds.length > 1) {
      this.toastr.warning("Please fill all Mandatory fields.");
      return false;
    } else {
      if (this.upsiEditForm.get('project_name').value == '') {
        this.toastr.warning("Please fill the Project Name.");
        return false;
      } else if (this.upsiEditForm.get('sender').value == '') {
        this.toastr.warning("Please fill the Name of Sender.");
        return false;
      }
      else if (this.upsiEditForm.get('recipient').value == '') {
        this.toastr.warning("Please fill the Name of Recipient.");
        return false;
      }
      else if (this.upsiEditForm.get('mode_of_sharing').value == '') {
        this.toastr.warning("Please fill the Mode of Sharing.");
        return false;
      }
      else if (this.upsiEditForm.get('purpose_of_sharing').value == '') {
        this.toastr.warning("Please fill the Legitimate Purpose of Sharing.");
        return false;
      }
      else if (this.upsiEditForm.get('nature_of_upsi').value == '') {
        this.toastr.warning("Please fill the Nature of UPSI.");
        return false;
      }
      else if (this.upsiEditForm.get('type_of_sharing').value == '') {
        this.toastr.warning("Please fill the Type of sharing.");
        return false;
      }
      else if (this.upsiEditForm.get('date_of_sharing').value == '') {
        this.toastr.warning("Please fill the Date of Sharing.");
        return false;
      }
      else if (this.upsiEditForm.get('from_date').value == '') {
        this.toastr.warning("Please fill the From Date.");
        return false;
      } else if (this.upsiEditForm.get('checkboxgroup').get('checkbox').value == false) {
        this.toastr.warning("Please Accept the Declaration.");
        return false;
      } else if (this.upsiEditForm.get('from_date').value > this.upsiEditForm.get('to_date').value) {
        this.toastr.warning("Date of Sharing should not be greater than public date.");
        return false;
      }


    }
  }
  //  onSubmitData(){
  //   this.onSubmit();
  //   this.mailConfifDunction();
  //  }
}
