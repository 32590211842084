import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DpModuleService } from 'src/app/dp-module/dp-module.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  listDpModule: any;
  accessUser: string;
  profileDetails: any;
  confidencialUrl: any;
  profileId: any;
  is_access_read=false;
  is_access_write=false;
  is_access_no=false;
  user_roles;
  timelineActivity: any;
  public active2 = 1;
  constructor(private dpService:DpModuleService, public router: Router,) { 
    this.confidencialUrl = environment.url
  }

  ngOnInit(): void {

    this.user_roles = JSON.parse(atob(sessionStorage.getItem('sdd_access_roles')));
    if(this.user_roles.dpname_read == '2'){
      this.is_access_write=true;
      this.is_access_read=true;
      this.is_access_no=true;
    }else if(this.user_roles.dpname_read == '1'){
      this.is_access_write=false;
      this.is_access_read=true;
      this.is_access_no=true;
    }

    this.accessUser = sessionStorage.getItem('access_user');
    this.dpService.getDPSelfRecord(null).subscribe(
      res => {
           
          // this.listDpModule = res['data'];
          this.profileDetails = res['data'];
          this.timelineActivity = this.profileDetails.timelineActivity; 

          //Dp name
          // let dpName = this.listDpModule.map(res =>{
          //   if(this.accessUser == res.email){
          //     this.profileDetails = res;
          //     this.profileId = this.profileDetails.id;
             
          //   }
          // })
            
      });
  }
  cancelProfile(){
    this.router.navigate(['/dashboard']);
  }
}
