import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-view-form-c-and-form-d',
  templateUrl: './view-form-c-and-form-d.component.html',
  styleUrls: ['./view-form-c-and-form-d.component.scss']
})
export class ViewFormCAndFormDComponent implements OnInit {
  formId: any;
  viewFormList: any;
  @Input() viewTradingFormList;
  confidencialUrl: any;
  formType: any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.formType = this.viewTradingFormList.formTypes;
   
    
  }
  
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
